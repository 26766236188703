import { Language } from '@t5s/shared/gql';
import { RecursiveStringCast } from '@t5s/shared/types';
import { I18nKey } from './i18n-key';
import { CastToI18n, i18nKeyObjFromLang } from './i18n-keys';
import { I18nString } from './i18n-string.interface';
import { translate } from './i18n-translate';

export class I18n<T extends object> {
  readonly keys: CastToI18n<RecursiveStringCast<T>>;

  constructor(private readonly languages: { [key in Language]: RecursiveStringCast<T> }) {
    this.keys = i18nKeyObjFromLang<RecursiveStringCast<T>>(languages[Language.EN]);
  }

  get key() {
    return this.keys;
  }

  translate(
    language: Language | undefined | null | { language?: Language | undefined },
    key: I18nString | I18nKey | undefined | null,
  ): string {
    if (!language) {
      return '';
    }

    if (typeof language === 'object') {
      const lang = language.language;
      if (!lang) {
        return '';
      }

      return translate(key, this.languages, lang) || '';
    }

    return translate(key, this.languages, language) || '';
  }
}
