import { Injectable } from '@angular/core';
import { App, AppInfo } from '@capacitor/app';
import { defer, from, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

/** https://capacitorjs.com/docs/apis/app#getinfo */
@Injectable()
export class ApplicationInfoService {
  getInfo(): Observable<AppInfo | undefined> {
    return defer(() => {
      try {
        // this throws on WEB
        const appInfo = App.getInfo();
        return from(appInfo).pipe(catchError(() => of(undefined)));
      } catch (e: unknown) {
        return of(undefined);
      }
    });
  }
}
