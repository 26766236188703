import { environment } from './environments';

export enum BuildEnvironment {
  PRD = 'PRD',
  DEV = 'DEV',
}

export enum RuntimeEnvironment {
  PRD = 'PRD',
  CI = 'CI',
  DEV = 'DEV',
}

function isRunningUnderWebViewLocalhost(input: string = window.location.origin) {
  const url = new URL(input);
  return url.hostname === 'localhost' && url.port === '';
}

function runtimeEnvironmentFromBaseUrl(input: string = window.location.origin): RuntimeEnvironment {
  const url = new URL(input);

  if (url.hostname.includes('tapeapp.com')) {
    return RuntimeEnvironment.PRD;
  }

  if (environment.production && isRunningUnderWebViewLocalhost(input)) {
    return RuntimeEnvironment.PRD;
  }

  if (url.hostname === 'localhost' && url.port === '8081') {
    return RuntimeEnvironment.CI;
  }

  if (url.hostname.startsWith('tape__ci')) {
    return RuntimeEnvironment.CI;
  }

  return RuntimeEnvironment.DEV;
}

export const BUILD_ENV = environment.production ? BuildEnvironment.PRD : BuildEnvironment.DEV;
export const RUNTIME_ENV = runtimeEnvironmentFromBaseUrl();
export const WEB_VIEW_LOCALHOST = isRunningUnderWebViewLocalhost();
