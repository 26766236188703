import { createReducer, on } from '@ngrx/store';
import { DeviceInfoValObj } from '@t5s/mobile-client/value-object/device';
import { DeviceActions } from './device.actions';

export const deviceStateKey = 'device';
export interface DeviceState {
  info?: DeviceInfoValObj;
}

const initialState: DeviceState = {};

export const deviceReducer = createReducer(
  initialState,
  on(DeviceActions.setDeviceInfo, (state, { info }) => ({ ...state, info })),
);
