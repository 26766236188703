import { px } from './units.util';

export const calc = (expression: string) => `calc(${expression})`;

export const min = (...inputs: string[]) => `min(${inputs.join(', ')})`;
export const max = (...inputs: string[]) => `max(${inputs.join(', ')})`;

export const minmax = (min: string, max: string) => `minmax(${min}, ${max})`;
export const url = (...url: Array<string | undefined>) =>
  url
    .filter((url) => !!url)
    .map((url) => `url(${String(url)})`)
    .join(', ');

export const repeat = (length: number | 'auto-fill' | 'auto-fit', percentage: string) =>
  `repeat(${length}, ${percentage})`;

export const rotate = (degree: number): string => `rotate(${degree}deg)`;

export const padding = (top: number, right: number, bottom?: number, left?: number) =>
  `${px(top)} ${px(right)} ${px(bottom ?? top)} ${px(left ?? right)}`;

export const margin = (top: number, right: number, bottom?: number, left?: number) =>
  `${px(top)} ${px(right)} ${px(bottom ?? top)} ${px(left ?? right)}`;

export const boxShadow = (size: number, color: string) => `0 0 0 ${px(size)} ${color}`;
export const boxShadowInset = (size: number, color: string) => `0 0 0 ${px(size)} ${color} inset`;

export function svgUrl(data: string, { encoding }: { encoding?: 'base64' | 'utf8' } = { encoding: 'base64' }): string {
  if (encoding === 'utf8') {
    return `data:image/svg+xml;utf8,${encodeURIComponent(data)}`;
  }

  return `data:image/svg+xml;base64,${btoa(data)}`;
}

export function variable<T extends string = string>(variable: string, fallback?: T): T {
  if (fallback) {
    return `var(${variable}, ${String(fallback)})` as T;
  }
  return `var(${variable})` as T;
}

export function important(str: string | number) {
  return `${str} !important`;
}

export function scale(str: string | number) {
  return `scale(${str})`;
}

export function scaleX(str: string | number) {
  return `scaleX(${str})`;
}

export function scaleY(str: string | number) {
  return `scaleY(${str})`;
}

export function translateX(str: string | number) {
  return `translateX(${typeof str === 'number' ? px(str) : str})`;
}

export function translateY(str: string | number) {
  return `translateY(${typeof str === 'number' ? px(str) : str})`;
}
