import gql from 'graphql-tag';
import {
  MultiRelationSplitByDtoNoNestingFragment,
  MultiRelationSplitBySectionDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const MultiRelationSplitByDtoFragment = gql`
  fragment MultiRelationSplitByDto on MultiRelationSplitByDto {
    ...MultiRelationSplitByDtoNoNesting
    sections {
      ...MultiRelationSplitBySectionDtoNoNesting
      blabItem {
        id
        title
      }
    }
  }
  ${MultiRelationSplitByDtoNoNestingFragment}
  ${MultiRelationSplitBySectionDtoNoNestingFragment}
`;
