import { Provider } from '@angular/core';
import { introspectionResultData } from '@t5s/shared/gql';
import { ApolloErrorPolicy, ApolloFetchPolicy } from '@t5s/shared/gql-config';
import { dataIdFromObject } from '@t5s/shared/gql-services';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { InMemoryCache, InMemoryCacheConfig, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClientOptions, DefaultOptions } from 'apollo-client';
import { ApolloLink } from 'apollo-link';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: introspectionResultData,
});

const errorPolicy = ApolloErrorPolicy.NONE;

const defaultOptions: DefaultOptions = {
  query: {
    fetchPolicy: ApolloFetchPolicy.NO_CACHE,
    errorPolicy,
  },
  watchQuery: {
    fetchPolicy: ApolloFetchPolicy.NO_CACHE,
    errorPolicy,
  },
  mutate: {
    fetchPolicy: ApolloFetchPolicy.NO_CACHE,
    errorPolicy,
  },
};

const cacheOptions: InMemoryCacheConfig = {
  fragmentMatcher,
  freezeResults: true,
  dataIdFromObject,
};
const cache = new InMemoryCache(cacheOptions);

const apolloOptionsFactory = (link: ApolloLink): ApolloClientOptions<any> => {
  return {
    link,
    cache,
    defaultOptions,
    assumeImmutableResults: true,
    queryDeduplication: false,
  };
};

export const APOLLO_OPTIONS_PROVIDER: Provider = {
  provide: APOLLO_OPTIONS,
  useFactory: apolloOptionsFactory,
  deps: [ApolloLink],
};
