import gql from 'graphql-tag';
import { WorkspaceActivityReplyDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { ReactionDtoFragment } from '../core';
import { UserProfilePreviewDtoFragment } from '../user-profile';

export const WorkspaceActivityReplyDtoFragment = gql`
  fragment WorkspaceActivityReplyDtoFragment on WorkspaceActivityReplyDto {
    ...WorkspaceActivityReplyDtoNoNesting
    user {
      ...UserProfilePreviewDtoFragment
    }
    reactions {
      ...ReactionDtoFragment
    }
  }

  ${WorkspaceActivityReplyDtoNoNestingFragment}
  ${UserProfilePreviewDtoFragment}
  ${ReactionDtoFragment}
`;
