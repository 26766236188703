import { CustomEntityIconDto } from '@t5s/shared/value-object/entity-icon';

export const UploadCustomEntityIconUploadFieldName = 'file';

export interface UploadCustomEntityIconInputDto {
  url?: string;
}

export interface UploadCustomEntityIconResultDto {
  icon?: CustomEntityIconDto;
  uploadProgress?: number;
  errored?: boolean;
}
