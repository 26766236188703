import { NgModule } from '@angular/core';
import { ViewCommonModule } from '@t5s/mobile-client/ui/view/common';
import { DebugConsoleStorageKeyDetailsViewComponent } from './debug-console-storage-key-details-view.component';
import { DebugConsoleViewComponent } from './debug-console-view.component';

const components = [DebugConsoleViewComponent, DebugConsoleStorageKeyDetailsViewComponent];

@NgModule({
  imports: [ViewCommonModule],
  declarations: components,
  exports: components,
})
export class DebugConsoleViewModule {}
