import { NgModule } from '@angular/core';
import { NetworkReconnectedObservable } from '@t5s/mobile-client/provider-token/network-status';
import { RefreshTriggerObservable } from '@t5s/mobile-client/provider-token/refresh-trigger';
import { ApplicationStateService, ServiceApplicationStateModule } from '@t5s/mobile-client/service/application-state';
import { filterOnlyFalse, filterOnlyTrue } from '@t5s/shared/util/rxjs';
import { merge } from 'rxjs';
import { filter, first, map, switchMap, withLatestFrom } from 'rxjs/operators';

@NgModule({
  imports: [ServiceApplicationStateModule],
  providers: [
    {
      provide: RefreshTriggerObservable,
      useFactory: (
        { appState$ }: ApplicationStateService,
        networkReconneced$: NetworkReconnectedObservable,
      ): RefreshTriggerObservable => {
        const appIsActive$ = appState$.pipe(map(({ isActive }) => isActive));

        const appActive$ = appIsActive$.pipe(filterOnlyTrue());
        const appInactive$ = appIsActive$.pipe(filterOnlyFalse());

        // source #1 for refreshes: app has been returned to foreground by user
        const appReturnedToForeground$ = appInactive$.pipe(switchMap(() => appActive$.pipe(first())));

        // source #2: app network is reconnected while app is active
        const networkReconnectedWhileAppIsActive$ = networkReconneced$.pipe(
          withLatestFrom(appIsActive$),
          filter(([_, appIsActive]) => appIsActive),
        );

        return merge(appReturnedToForeground$, networkReconnectedWhileAppIsActive$);
      },
      deps: [ApplicationStateService, NetworkReconnectedObservable],
    },
  ],
})
export class RefreshTriggerProviderModule {}
