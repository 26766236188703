import gql from 'graphql-tag';
import { ThumbnailDtoNoNestingFragment, WorkspaceUserDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const WorkspaceUserDtoFragment = gql`
  fragment WorkspaceUserDtoFragment on WorkspaceUserDto {
    ...WorkspaceUserDtoNoNesting
    profilePicture {
      ...ThumbnailDtoNoNesting
    }
  }
  ${WorkspaceUserDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
