import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { GqlActiveUserBlabItemService } from '@t5s/shared/gql-services';
import { map, mergeMap } from 'rxjs/operators';
import { BlabItemDisplayActions } from './blab-item-display.actions';

@Injectable()
export class BlabItemDisplayFollowEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly gqlActiveUserBlabItemService: GqlActiveUserBlabItemService,
  ) {}

  readonly followBlabItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.followBlabItem),
      mergeMap(({ blabItemId }) =>
        this.gqlActiveUserBlabItemService.followBlabItem({ blabItemId }).pipe(
          map((activeUserBlabItemInfo) =>
            BlabItemDisplayActions.followBlabItemSuccess({ blabItemId, activeUserBlabItemInfo }),
          ),
          catchErrorDispatch(BlabItemDisplayActions.followBlabItemException),
        ),
      ),
    ),
  );

  readonly unfollowBlabItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.unfollowBlabItem),
      mergeMap(({ blabItemId }) =>
        this.gqlActiveUserBlabItemService.unfollowBlabItem({ blabItemId }).pipe(
          map((activeUserBlabItemInfo) =>
            BlabItemDisplayActions.unfollowBlabItemSuccess({ blabItemId, activeUserBlabItemInfo }),
          ),
          catchErrorDispatch(BlabItemDisplayActions.unfollowBlabItemException),
        ),
      ),
    ),
  );
}
