import { CdkPortalOutlet } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ClientFontService } from '@t5s/client/service/font';
import { ComponentStyle } from '@t5s/client/ui/style/common';
import { UntilDestroy, untilDestroy } from '@t5s/client/util/component-until-destroy';
import { getRouterEventStreams } from '@t5s/client/util/router';
import { BottomSheetController } from '@t5s/mobile-client/business-logic/bottom-sheet';
import { DebugConsoleRootPageComponent } from '@t5s/mobile-client/page/modal/debug-console';
import { SplashScreenService } from '@t5s/mobile-client/service/splash-screen';
import { debugOpenModalOpen } from '@t5s/mobile-client/util/debug-console';
import { from, merge } from 'rxjs';
import { debounceTime, first, switchMap, takeUntil, tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 't5s-root-page',
  template: `
    <ion-app>
      <t5s-root-view>
        <ion-router-outlet [swipeGesture]="false"></ion-router-outlet>

        <ng-container root-sheet-outlet>
          <t5s-emoji-picker-page></t5s-emoji-picker-page>
          <t5s-device-media-page></t5s-device-media-page>

          <ng-template [cdkPortalOutlet]></ng-template>
        </ng-container>
      </t5s-root-view>
    </ion-app>
  `,
  styles: [ComponentStyle.HostSpread],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RootPage implements AfterViewInit {
  @ViewChild(CdkPortalOutlet) outlet!: CdkPortalOutlet;

  constructor(
    readonly modalController: ModalController,
    readonly elementRef: ElementRef,
    readonly splashScreen: SplashScreenService,
    readonly router: Router,
    readonly fontService: ClientFontService,
    readonly bottomSheetController: BottomSheetController,
    @Inject(DOCUMENT) readonly document: Document,
  ) {
    const { navigationStart$, navigationEnd$, navigationCancel$ } = getRouterEventStreams(router);

    const navigationFinish$ = merge(navigationEnd$, navigationCancel$);

    // Hide in-app splash screen upon router stabilize
    navigationStart$
      .pipe(
        switchMap(() => navigationFinish$.pipe(takeUntil(navigationStart$))),
        debounceTime(50),
        first(),
        untilDestroy(this),
        tap(() => splashScreen.hide()),
      )
      .subscribe();

    // Register debug console modal
    debugOpenModalOpen(this.elementRef.nativeElement, document)
      .pipe(
        switchMap(() =>
          from(
            this.modalController
              .create({
                component: DebugConsoleRootPageComponent,
                showBackdrop: false,
              })
              .then((modal) => modal.present()),
          ),
        ),
      )
      .subscribe();
  }

  ngAfterViewInit() {
    if (this.outlet) {
      this.bottomSheetController.registerPortalOutlet(this.outlet);
    }
  }
}
