import { Language } from '@t5s/shared/gql';
import { substituteHandlebars } from '@t5s/shared/util/handlebar';
import { I18nKey } from './i18n-key';
import { i18nKeyDelimiter } from './i18n-keys';
import { I18nString } from './i18n-string.interface';

export function getI18nTemplate(
  fullPath: I18nKey,
  languagesDict: { [key in Language]: any } | undefined,
  language: Language,
): string | undefined {
  if (!languagesDict || !fullPath) {
    return undefined;
  }

  // Start recursion with full language object
  let recursionObj: any = languagesDict[language];

  for (const key of fullPath.split(i18nKeyDelimiter)) {
    // Check if wrong path was provided
    if (typeof recursionObj !== 'object') {
      return undefined;
    }

    // Recurse into the language object
    recursionObj = recursionObj[key];
  }

  if (typeof recursionObj !== 'string') {
    return undefined;
  }

  const template = recursionObj;
  return template;
}

export function translate(
  i18nInput: I18nString | I18nKey | undefined | null,
  languagesDict: any,
  language: Language,
): string | undefined {
  if (i18nInput === undefined || i18nInput === null) {
    return undefined;
  }

  const fullPath = typeof i18nInput === 'string' ? i18nInput : i18nInput.key;
  const params = typeof i18nInput === 'string' ? undefined : i18nInput.params;

  // Get template for I18nKey
  const template = getI18nTemplate(fullPath, languagesDict, language);

  // Check if template could be found
  if (!template) {
    return undefined;
  }

  // Substitute variables
  return substituteHandlebars(template, params);
}
