import {
  WorkflowRunCreatedSubscriptionUpdate,
  WorkflowRunLogsAppendedSubscriptionUpdate,
  WorkflowRunUpdatedSubscriptionUpdate,
} from '@t5s/shared/gql';
import { isDto } from './dto.type-guard';

export function isWorkflowRunCreatedSubscriptionUpdate(
  candidate?: any,
): candidate is WorkflowRunCreatedSubscriptionUpdate {
  return isDto<WorkflowRunCreatedSubscriptionUpdate>(candidate, 'WorkflowRunCreatedSubscriptionUpdate');
}

export function isWorkflowRunLogsAppendedSubscriptionUpdate(
  candidate?: any,
): candidate is WorkflowRunLogsAppendedSubscriptionUpdate {
  return isDto<WorkflowRunLogsAppendedSubscriptionUpdate>(candidate, 'WorkflowRunLogsAppendedSubscriptionUpdate');
}

export function isWorkflowRunUpdatedSubscriptionUpdate(
  candidate?: any,
): candidate is WorkflowRunUpdatedSubscriptionUpdate {
  return isDto<WorkflowRunUpdatedSubscriptionUpdate>(candidate, 'WorkflowRunUpdatedSubscriptionUpdate');
}
