import { createReducer, on } from '@ngrx/store';
import { BottomSheetPosition } from '@t5s/mobile-client/value-object/bottom-sheet';
import { RootActions } from './root.actions';

export const rootStateKey = 'root';
export interface RootState {
  modalOpen: boolean;
  sheetPosition: BottomSheetPosition;
}

const initialState: RootState = {
  modalOpen: false,
  sheetPosition: BottomSheetPosition.IS_BOTTOM,
};

export const rootReducer = createReducer(
  initialState,
  on(RootActions.setModalOpen, (state, { modalOpen }) => ({ ...state, modalOpen })),
  on(RootActions.setSheetPosition, (state, { sheetPosition }) => ({ ...state, sheetPosition })),
);
