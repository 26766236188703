import gql from 'graphql-tag';
import {
  BlabDefPreviewDtoNoNestingFragment,
  WorkspacePreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const BlabDefPreviewDtoFragment = gql`
  fragment BlabDefPreviewDtoFragment on BlabDefPreviewDto {
    ...BlabDefPreviewDtoNoNesting
    workspace {
      ...WorkspacePreviewDtoNoNesting
    }
  }
  ${BlabDefPreviewDtoNoNestingFragment}
  ${WorkspacePreviewDtoNoNestingFragment}
`;
