import {
  GetNotificationsInput,
  MarkNotificationAsReadInput,
  MarkNotificationAsUnreadInput,
  NotificationConnection,
  NotificationDto,
  NotificationUnionType,
  Void,
} from '@t5s/shared/gql';
import { getNotificationUnionType } from '@t5s/shared/gql-util/notification';
import { isNotNullish } from '@t5s/shared/util/object';
import { filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationConnectionFragmentV2, NotificationDtoFragmentV2 } from '../../fragments/notification-v2';
import {
  markAllNotificationsAsRead,
  markNotificationAsRead,
  markNotificationAsUnread,
} from '../../generated/graphql-mutations';
import { getNotifications, getNotificationsV2 } from '../../generated/graphql-queries';
import { subscribeToUserNotifications } from '../../generated/graphql-subscriptions';
import { GqlService } from '../../gql-service';

export class GqlNotificationService extends GqlService {
  /** A more lightweight version of `getNotificationConnection` that uses dedicated fragments. */
  getNotificationConnectionV3(first: number, input?: GetNotificationsInput, after?: string) {
    const query$ = this.apollo.defaultQuery<NotificationConnection>(
      getNotificationsV2(first, input, after, [NotificationConnectionFragmentV2]),
    );

    return query$.pipe(
      map((connection) => {
        const { pageInfo, edges } = connection;
        const transformedEdges = edges
          .map((edge) => {
            const node = getNotificationUnionType(edge.node);

            if (!node) {
              return undefined;
            }

            return { ...edge, node };
          })
          .filter(isNotNullish);

        return { edges: transformedEdges, pageInfo };
      }),
    );
  }

  /** A more lightweight version of `getNotificationConnection` that uses dedicated fragments. */
  getNotificationConnectionV2(first: number, input?: GetNotificationsInput, after?: string) {
    const query$ = this.apollo.defaultQuery<NotificationConnection>(
      getNotifications(first, input, after, [NotificationConnectionFragmentV2]),
    );

    return query$.pipe(
      map((connection) => {
        const { pageInfo, edges } = connection;
        const transformedEdges = edges
          .map((edge) => {
            const node = getNotificationUnionType(edge.node);

            if (!node) {
              return undefined;
            }

            return { ...edge, node };
          })
          .filter(isNotNullish);

        return { edges: transformedEdges, pageInfo };
      }),
    );
  }

  subscribeToUserNotificationsV2(input: GetNotificationsInput): Observable<NotificationUnionType> {
    const subscription$ = this.apollo.defaultSubscribe<NotificationDto>(
      subscribeToUserNotifications(input, [NotificationDtoFragmentV2]),
    );

    return subscription$.pipe(map(getNotificationUnionType), filterOnlyPresent());
  }

  markNotificationAsRead(input: MarkNotificationAsReadInput): Observable<{}> {
    return this.apollo.defaultMutate<NotificationDto>(markNotificationAsRead(input));
  }

  markNotificationAsUnread(input: MarkNotificationAsUnreadInput): Observable<{}> {
    return this.apollo.defaultMutate<NotificationDto>(markNotificationAsUnread(input));
  }

  markAllNotificationsAsRead(): Observable<Void> {
    return this.apollo.defaultMutate(markAllNotificationsAsRead());
  }
}
