import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ServiceBrowserModule } from '@t5s/mobile-client/service/browser';
import {
  GqlActiveUserBlabItemService,
  GqlBlabItemActivityReactionService,
  GqlBlabItemActivityReplyService,
  GqlBlabItemActivityService,
  GqlBlabItemCommentService,
  GqlBlabItemIncomingRelationService,
  GqlBlabItemLiveUpdateService,
  GqlBlabItemService,
  GqlUserSearchService,
  SharedApolloClient,
} from '@t5s/shared/gql-services';
import { BlabItemActivityCommentEffects } from './blab-item-activity-comment.effects';
import { BlabItemDisplayActivityEffects } from './blab-item-display-activity.effects';
import { BlabItemDisplayFollowEffects } from './blab-item-display-follow.effects';
import { BlabItemDisplayHydrationEffects } from './blab-item-display-hydration.effects';
import { BlabItemDisplayLiveSubscriptionEffects } from './blab-item-display-live-subscription.effects';
import { BlabItemDisplayMentionUserEffects } from './blab-item-display-mention-user.effects';
import { BlabItemDisplayEffects } from './blab-item-display.effects';
import { blabItemDisplayReducer, blabItemDisplayStateKey } from './blab-item-display.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(blabItemDisplayStateKey, blabItemDisplayReducer),
    EffectsModule.forFeature([
      BlabItemDisplayEffects,
      BlabItemDisplayHydrationEffects,
      BlabItemDisplayLiveSubscriptionEffects,
      BlabItemDisplayFollowEffects,
      BlabItemDisplayActivityEffects,
      BlabItemActivityCommentEffects,
      BlabItemDisplayMentionUserEffects,
    ]),
    ServiceBrowserModule,
  ],
  providers: [
    {
      provide: GqlBlabItemService,
      useFactory: (apollo: SharedApolloClient) => new GqlBlabItemService(apollo),
      deps: [SharedApolloClient],
    },
    {
      provide: GqlBlabItemActivityService,
      useFactory: (apollo: SharedApolloClient) => new GqlBlabItemActivityService(apollo),
      deps: [SharedApolloClient],
    },
    {
      provide: GqlBlabItemCommentService,
      useFactory: (apollo: SharedApolloClient) => new GqlBlabItemCommentService(apollo),
      deps: [SharedApolloClient],
    },
    {
      provide: GqlBlabItemIncomingRelationService,
      useFactory: (apollo: SharedApolloClient) => new GqlBlabItemIncomingRelationService(apollo),
      deps: [SharedApolloClient],
    },
    {
      provide: GqlActiveUserBlabItemService,
      useFactory: (apollo: SharedApolloClient) => new GqlActiveUserBlabItemService(apollo),
      deps: [SharedApolloClient],
    },
    {
      provide: GqlBlabItemLiveUpdateService,
      useFactory: (apollo: SharedApolloClient) => new GqlBlabItemLiveUpdateService(apollo),
      deps: [SharedApolloClient],
    },
    {
      provide: GqlBlabItemActivityReactionService,
      useFactory: (apollo: SharedApolloClient) => new GqlBlabItemActivityReactionService(apollo),
      deps: [SharedApolloClient],
    },
    {
      provide: GqlBlabItemActivityReplyService,
      useFactory: (apollo: SharedApolloClient) => new GqlBlabItemActivityReplyService(apollo),
      deps: [SharedApolloClient],
    },
    {
      provide: GqlUserSearchService,
      useFactory: (apollo: SharedApolloClient) => new GqlUserSearchService(apollo),
      deps: [SharedApolloClient],
    },
  ],
})
export class BlabItemDisplayBusinessLogicModule {}
