import gql from 'graphql-tag';
import {
  BlabViewPreviewDtoNoNestingFragment,
  RelationFlimDefBlabDefSearchResultBlabDefPreviewDtoNoNestingFragment,
  WorkspacePreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const RelationFlimDefBlabDefSearchResultFragment = gql`
  fragment RelationFlimDefBlabDefSearchResult on RelationFlimDefBlabDefSearchResultDto {
    blabDef {
      ...RelationFlimDefBlabDefSearchResultBlabDefPreviewDtoNoNesting
      blabViews {
        ...BlabViewPreviewDtoNoNesting
      }
      workspace {
        ...WorkspacePreviewDtoNoNesting
      }
    }
  }
  ${RelationFlimDefBlabDefSearchResultBlabDefPreviewDtoNoNestingFragment}
  ${WorkspacePreviewDtoNoNestingFragment}
  ${BlabViewPreviewDtoNoNestingFragment}
`;
