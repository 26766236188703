import { NgModule } from '@angular/core';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { KeyboardAnimatedSpacerComponent } from './keyboard-animated-spacer.component';
import { KeyboardSpacerComponent } from './keyboard-spacer.component';

const components = [KeyboardSpacerComponent, KeyboardAnimatedSpacerComponent];

@NgModule({
  imports: [ComponentCommonModule],
  declarations: components,
  exports: components,
})
export class KeyboardComponentModule {}
