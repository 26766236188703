export const colorEnLangClient = {
  BLUE: 'Blue',
  BROWN: 'Brown',
  DEFAULT: 'Default',
  GRAY: 'Grey',
  GREEN: 'Green',
  ORANGE: 'Orange',
  PINK: 'Pink',
  PURPLE: 'Purple',
  RED: 'Red',
  YELLOW: 'Yellow',
};
