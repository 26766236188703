import { Injectable } from '@angular/core';
import { defer, EMPTY, from } from 'rxjs';

// const IS_ACTIVE = BUILD_ENV === BuildEnvironment.PRD && RUNTIME_ENV === RuntimeEnvironment.PRD;
const IS_ACTIVE = false; // TODO:
const Intercom = {} as any; // TODO:
type IntercomUserUpdateOptions = any; // TODO:

/* eslint-disable no-console */
// https://www.npmjs.com/package/@capacitor-community/intercom
@Injectable()
export class IntercomService {
  private readonly intercom: typeof Intercom;

  constructor() {
    this.intercom = Intercom;
  }

  logEvent(options: { name: string; data?: Object }) {
    if (!IS_ACTIVE) {
      console.log(`[Intercom logEvent] ${options.name} ${options.data ? JSON.stringify(options.data) : ''}`);
      return EMPTY;
    }

    return defer(() => from(this.intercom.logEvent(options)));
  }

  logout() {
    if (!IS_ACTIVE) {
      return EMPTY;
    }

    return defer(() => from(this.intercom.logout()));
  }

  hideMessenger() {
    if (!IS_ACTIVE) {
      return EMPTY;
    }

    return defer(() => from(this.intercom.hideMessenger()));
  }

  registerIdentifiedUser(options: { userId?: string; email?: string }) {
    if (!IS_ACTIVE) {
      console.log(`[Intercom registerIdentifiedUser] ${options.email ?? ''} (${options.userId ?? ''})`);
      return EMPTY;
    }

    return defer(() => from(this.intercom.registerIdentifiedUser(options)));
  }

  registerUnidentifiedUser() {
    if (!IS_ACTIVE) {
      return EMPTY;
    }

    return defer(() => from(this.intercom.registerUnidentifiedUser()));
  }

  setBottomPadding(options: { value: string }) {
    if (!IS_ACTIVE) {
      return EMPTY;
    }

    return defer(() => from(this.intercom.setBottomPadding(options)));
  }

  updateUser(options: IntercomUserUpdateOptions) {
    if (!IS_ACTIVE) {
      console.log(`[Intercom updateUser] ${JSON.stringify(options)}`);
      return EMPTY;
    }

    return defer(() => from(this.intercom.updateUser(options)));
  }
}
