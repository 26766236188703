import { parseInteger, parseIntegerOrThrow } from '@t5s/shared/util/number';
import { getHours, getMinutes, isValid, setHours, setMilliseconds, setMinutes, setSeconds } from 'date-fns';

export function getHoursMinutesFromTimeStr(time: string): { hours: number; minutes: number; seconds?: number } {
  const [hours, minutes, seconds] = time.split(':');
  return { hours: parseIntegerOrThrow(hours), minutes: parseIntegerOrThrow(minutes), seconds: parseInteger(seconds) };
}

export function getTimeStrFromDate(date: Date): string;
export function getTimeStrFromDate(date: Date | undefined): string | undefined;
export function getTimeStrFromDate(date: Date | undefined) {
  if (!date || !isValid(date)) {
    return undefined;
  }

  const hours = `${getHours(date)}`.padStart(2, '0');
  const minutes = `${getMinutes(date)}`.padStart(2, '0');
  return `${hours}:${minutes}`;
}

export function setTime(
  date: Date,
  { time, hours, minutes, seconds }: { time?: string; hours?: number; minutes?: number; seconds?: number },
): Date {
  hours = time ? getHoursMinutesFromTimeStr(time).hours : hours;
  minutes = time ? getHoursMinutesFromTimeStr(time).minutes : minutes;
  seconds = (time ? getHoursMinutesFromTimeStr(time).seconds : seconds) || 0;

  if (hours === undefined) {
    throw new Error(`No hours provided!`);
  }

  if (minutes === undefined) {
    throw new Error(`No minutes provided!`);
  }

  date = setHours(date, hours);
  date = setMinutes(date, minutes);
  date = setSeconds(date, seconds);
  return date;
}

export function removeTime(date: Date): Date {
  return setMilliseconds(setTime(date, { hours: 0, minutes: 0, seconds: 0 }), 0);
}

export function removeSecondsMilliseconds(date: Date): Date {
  return setMilliseconds(setSeconds(date, 0), 0);
}

export function removeMilliseconds(date: Date): Date {
  return setMilliseconds(date, 0);
}
