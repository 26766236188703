import { Language } from '@t5s/shared/gql';
import { DateFnsLocale, dateFnsLocales } from '@t5s/shared/util/date';

const { de, enUS } = dateFnsLocales;

export function getDateFnsLocale({ language }: { language: Language }): DateFnsLocale {
  switch (language) {
    case Language.EN: {
      return enUS;
    }

    case Language.DE: {
      return de;
    }
  }
}
