import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { formatReminderDate, formatTime, getDateTimeFormats, isApplicableI18n, normalizeDate } from '../common';

export function formatReminderDateTime(i18n: SharedI18n, date?: string | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  const dateTimeFormats = getDateTimeFormats(i18n);
  const timeFormat = dateTimeFormats.time.short;

  const formattedDate = formatReminderDate(i18n, date);
  const formattedTime = formatTime(date, timeFormat, i18n);

  return `${formattedDate} · ${formattedTime}`;
}
