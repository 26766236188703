import { Injectable } from '@angular/core';
import { StorageService } from '@t5s/mobile-client/service/storage';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

const STATE_HYDRATION_PERSISTANCE_KEY_PREFIX = `stateHydrationPersistance`;

@Injectable()
export class StateHydrationService<T extends {} = {}> {
  constructor(private readonly storageService: StorageService) {}

  /** Persist current application state. */
  persistState(stateKey: string, state: T): Observable<void> {
    const key = `${STATE_HYDRATION_PERSISTANCE_KEY_PREFIX}_${stateKey}`;
    let value: string;

    try {
      value = JSON.stringify(state);
      return this.storageService.set({ key, value });
    } catch (e: any) {
      // throw new StateSerializationException();
      return of(undefined);
    }
  }

  /** Retrieves persisted application state. */
  retrieveState(stateKey: string): Observable<T | null> {
    const key = `${STATE_HYDRATION_PERSISTANCE_KEY_PREFIX}_${stateKey}`;

    return this.storageService.get({ key }).pipe(
      map(({ value }) => {
        if (!value) {
          return null;
        }

        let state: any | undefined;
        try {
          state = JSON.parse(value);
        } catch (e: any) {
          // throw new StateParsingException();
          return of(undefined);
        }

        return state;
      }),
    );
  }

  /** Clears persisted application state. */
  clearState(stateKey: string): Observable<void> {
    const key = `${STATE_HYDRATION_PERSISTANCE_KEY_PREFIX}_${stateKey}`;
    try {
      return this.storageService.remove({ key });
    } catch (e) {
      return of(undefined);
    }
  }
}
