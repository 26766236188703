import gql from 'graphql-tag';
import { FocusDueDateSectionDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const FocusDueDateSectionsDtoFragment = gql`
  fragment FocusDueDateSectionsDtoFragment on FocusDueDateSectionsDto {
    dueDate {
      ...FocusDueDateSectionDtoNoNesting
    }
    noDueDate {
      ...FocusDueDateSectionDtoNoNesting
    }
  }
  ${FocusDueDateSectionDtoNoNestingFragment}
`;
