import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { isSameYear, isThisWeek, isToday, isTomorrow, isYesterday } from '@t5s/shared/util/date';
import { DatetimeI18n } from '../../../datetime.i18n';
import { ApplicableI18n, formatDateViaLanguage, getDateTimeFormats, isApplicableI18n, normalizeDate } from '../common';

function _formatStrReducedDate(date: Date, i18n: ApplicableI18n): string {
  let format: string;
  const dateTimeFormats = getDateTimeFormats(i18n);

  const now = new Date(Date.now());

  if (isToday(date)) {
    format = dateTimeFormats.time.short;
  } else if (isThisWeek(date)) {
    const { weekdayAbbr } = dateTimeFormats.date;
    const { short } = dateTimeFormats.time;
    format = `${weekdayAbbr} ${short}`;
  } else if (isSameYear(date, now)) {
    const { omitYear } = dateTimeFormats.date;
    const { short } = dateTimeFormats.time;
    format = `${omitYear}, ${short}`;
  } else {
    const { full } = dateTimeFormats.date;
    const { short } = dateTimeFormats.time;
    format = `${full}, ${short}`;
  }

  return formatDateViaLanguage(date, format, i18n);
}

function reducedDateI18nStr(date: Date, i18n: ApplicableI18n): string {
  const dateTimeFormats = getDateTimeFormats(i18n);
  const time = formatDateViaLanguage(date, dateTimeFormats.time.short, i18n);

  if (isYesterday(date)) {
    return DatetimeI18n.translate(i18n, { key: DatetimeI18n.key.yesterdayTime, params: { time } });
  } else if (isTomorrow(date)) {
    return DatetimeI18n.translate(i18n, { key: DatetimeI18n.key.tomorrowTime, params: { time } });
  } else if (isToday(date)) {
    return time;
  } else {
    return _formatStrReducedDate(date, i18n);
  }
}

export function formatReducedDateTime(i18n: SharedI18n, date: string | Date | undefined): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  return reducedDateI18nStr(date, i18n);
}
