import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { InputComponent } from './input.component';
import { PrefixedInputComponent } from './prefixed-input.component';

const components = [InputComponent, PrefixedInputComponent];

@NgModule({
  imports: [ComponentCommonModule, FormsModule],
  declarations: components,
  exports: components,
})
export class InputComponentModule {}
