import gql from 'graphql-tag';
import { ConvertMultiRelationFlimToSingleRelationFlimResultDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../../flim-def';

export const ConvertMultiRelationFlimToSingleRelationFlimResultDtoFragment = gql`
  fragment ConvertMultiRelationFlimToSingleRelationFlimResultDtoFragment on ConvertMultiRelationFlimToSingleRelationFlimResultDto {
    ...ConvertMultiRelationFlimToSingleRelationFlimResultDtoNoNesting

    flimDef {
      ...FullFlimDef
    }
  }
  ${ConvertMultiRelationFlimToSingleRelationFlimResultDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
