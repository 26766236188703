import { createAction, props } from '@ngrx/store';
import { createErrorAction } from '@t5s/client/util/store';
import { DeviceMediaType, ImageOptions, Photo } from '@t5s/mobile-client/value-object/device-media';
import { BottomSheetPosition } from '@t5s/mobile-client/value-object/bottom-sheet';

const PREFIX = '[mobile-client-device-media]';

export const DeviceMediaActions = {
  reset: createAction(`${PREFIX} reset`),

  // Sheet
  open: createAction(`${PREFIX} open`),
  close: createAction(`${PREFIX} close`),
  picked: createAction(`${PREFIX} picked`, props<{ deviceMedia: DeviceMediaType }>()),
  setPosition: createAction(`${PREFIX} set position`, props<{ position: BottomSheetPosition }>()),

  // Photo
  getPhoto: createAction(`${PREFIX} get photo`, props<{ options: ImageOptions }>()),
  getPhotoSuccess: createAction(`${PREFIX} get photo success`, props<{ photo: Photo }>()),
  getPhotoCancel: createAction(`${PREFIX} get photo cancel`),
  getPhotoDenied: createAction(`${PREFIX} get photo denied`),
  getPhotoException: createErrorAction(`${PREFIX} get photo exception`),

  // Camera
  getCamera: createAction(`${PREFIX} get camera`, props<{ options: ImageOptions }>()),
  getCameraSuccess: createAction(`${PREFIX} get camera success`, props<{ camera: Photo }>()),
  getCameraCancel: createAction(`${PREFIX} get camera cancel`),
  getCameraDenied: createAction(`${PREFIX} get camera denied`),
  getCameraException: createErrorAction(`${PREFIX} get camera exception`),

  // Document
  getDocument: createAction(`${PREFIX} get document`),
  getDocumentSuccess: createAction(`${PREFIX} get document success`, props<{ document: { files: File[] } }>()),
  getDocumentCancel: createAction(`${PREFIX} get document cancel`),
  getDocumentException: createErrorAction(`${PREFIX} get document exception`),
};
