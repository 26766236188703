import { NgIf, NgIfContext } from '@angular/common';
import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroy } from '@t5s/client/util/component-until-destroy';
import { UserFeatureAccessLevel } from '@t5s/shared/gql';
import { canAccessFeatureAccessLevel } from '@t5s/shared/util/feature-access-level';
import { RuntimeFeatureAccessLevelObservable } from '../runtime-feature-access-level.observable';
@UntilDestroy()
@Directive({
  selector: `[featureAccessLevelDev]`,
})
export class FeatureAccessLevelDevDirective extends NgIf implements OnInit {
  @Input() featureAccessLevelDev: any;
  @Input('featureAccessLevelDevElse') set featureAccessLevelDevElse(templateRef: TemplateRef<NgIfContext> | null) {
    super.ngIfElse = templateRef;
  }

  constructor(
    readonly featureAccessLevel$: RuntimeFeatureAccessLevelObservable,
    private readonly changeRef: ChangeDetectorRef,
    _templateRef: TemplateRef<any>,
    _viewContainer: ViewContainerRef,
  ) {
    super(_viewContainer, _templateRef);
  }

  ngOnInit() {
    this.featureAccessLevel$.pipe(untilDestroy(this)).subscribe((featureAccessLevel) => {
      const canAccess = canAccessFeatureAccessLevel(featureAccessLevel, UserFeatureAccessLevel.DEV);
      this.ngIf = canAccess;
      this.changeRef.detectChanges();
    });
  }
}
