/* eslint-disable no-console */
import { isInputtableElement } from '@t5s/client/util/element';
import { ConsoleStatement, ConsoleStatementLevel } from '@t5s/mobile-client/value-object/debug-console';
import { filterOnlyTrue } from '@t5s/shared/util/rxjs';
import { fromEvent, merge, Observable, race, timer } from 'rxjs';
import { filter, first, mapTo, skip, switchMap, throttleTime } from 'rxjs/operators';

const MAX_SPAN_MS = 4000;
const NUM_ADDITIONAL_CLICKS = 7;
const PIXEL_Y_THRESHOLD = 60;

const DEBUG_CONSOLE_OPEN_SHORTCUT = 'd';

export function debugOpenModalOpen(el: Element, doc: Document): Observable<unknown> {
  const click$ = fromEvent<TouchEvent>(el, 'touchstart', { passive: true });

  const debugOpenSpamClick$ = click$.pipe(
    filter(({ touches }) => touches && touches[0]?.clientY < PIXEL_Y_THRESHOLD),
    throttleTime(MAX_SPAN_MS),
    switchMap(() =>
      race(click$.pipe(skip(NUM_ADDITIONAL_CLICKS), mapTo(true)), timer(MAX_SPAN_MS).pipe(mapTo(false))).pipe(first()),
    ),
    filterOnlyTrue(),
  );

  const debugOpenShortcut$ = fromEvent<KeyboardEvent>(doc, 'keyup', { passive: true }).pipe(
    filter(({ key }) => key === DEBUG_CONSOLE_OPEN_SHORTCUT),
    filter(({ target }) => !isInputtableElement(target)),
  );

  return merge(debugOpenSpamClick$, debugOpenShortcut$);
}

function getConsoleStatement(level: ConsoleStatementLevel, data: any[]): ConsoleStatement {
  let content = '';
  try {
    content = data
      .map((data) => {
        if (typeof data === 'object') {
          if (typeof data?.toString === 'function') {
            const stringRepresentation: unknown = data.toString();

            if (typeof stringRepresentation === 'string' && stringRepresentation !== '[object Object]') {
              return data.toString();
            }
          }

          return JSON.stringify(data);
        }
        return data;
      })
      .join(' ');
  } catch (err: unknown) {}

  return {
    level,
    content,
    createdAt: new Date().toISOString(),
  };
}

export function shimConsoleLog(cb: (statement: ConsoleStatement) => void) {
  (console as any).stdlog = console.log.bind(console);
  (console as any).stdinfo = console.info.bind(console);
  (console as any).stdwarn = console.warn.bind(console);
  (console as any).stderror = console.error.bind(console);

  console.log = (...data: any[]) => {
    cb(getConsoleStatement(ConsoleStatementLevel.LOG, data));
    (console as any).stdlog(...data);
  };

  console.info = (...data: any[]) => {
    cb(getConsoleStatement(ConsoleStatementLevel.INFO, data));
    (console as any).stdinfo(...data);
  };

  console.warn = (...data: any[]) => {
    cb(getConsoleStatement(ConsoleStatementLevel.WARN, data));
    (console as any).stdwarn(...data);
  };

  console.error = (...data: any[]) => {
    cb(getConsoleStatement(ConsoleStatementLevel.ERROR, data));
    (console as any).stderror(...data);
  };
}
