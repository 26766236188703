import { Language } from '@t5s/shared/gql';
import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { formatDateViaLanguage, isApplicableI18n, normalizeDate, shouldAbbreviateMonth } from '../common';

export function formatCalendarMonthDateShort(i18n: SharedI18n, date?: string | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  const shouldAbbreviate = shouldAbbreviateMonth(i18n, date);

  let dateFormat: string;
  switch (i18n.language) {
    case Language.EN: {
      dateFormat = shouldAbbreviate ? 'MMM ’yy' : 'MMMM ’yy';
      break;
    }

    case Language.DE: {
      dateFormat = shouldAbbreviate ? 'MMM yy' : 'MMMM yy';
      // patch this to match ISO behavior whatever
      return formatDateViaLanguage(date, dateFormat, i18n).replace('Juni', 'Jun.').replace('Juli', 'Jul.');
    }
  }

  return formatDateViaLanguage(date, dateFormat, i18n);
}
