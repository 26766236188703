import { Observable, of, throwError } from 'rxjs';
import { delay, mergeMap, retryWhen } from 'rxjs/operators';

export function delayedRetry<T>(delayMs: number, maxRetries: number) {
  let retries = maxRetries;

  return (src: Observable<T>) =>
    src.pipe(
      retryWhen((errors: Observable<T>) =>
        errors.pipe(
          delay(delayMs),
          mergeMap((error) => (retries-- > 0 ? of(error) : throwError('Retries exceeded'))),
        ),
      ),
    );
}
