import gql from 'graphql-tag';
import {
  LinkPreviewDtoNoNestingFragment,
  MultiLinkFlimValEntryDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
} from '../../../../generated/graphql-fragments';

export const MultiLinkFlimValEntryFragment = gql`
  fragment BlabItemsOverviewMultiLinkFlimValEntryFragment on MultiLinkFlimValEntryDto {
    ...MultiLinkFlimValEntryDtoNoNesting
    linkPreview {
      ...LinkPreviewDtoNoNesting
      previewImage {
        ...ThumbnailDtoNoNesting
      }
    }
  }
  ${MultiLinkFlimValEntryDtoNoNestingFragment}
  ${LinkPreviewDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
