import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { ApplicableI18n } from './applicable-i18n.interface';

export function isApplicableI18n(i18n: SharedI18n): i18n is ApplicableI18n {
  const { language, dateFormat, timeFormat } = i18n;

  if (!language || !dateFormat || !timeFormat) {
    return false;
  }

  return true;
}
