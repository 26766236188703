import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import {
  KeyboardHeightObservable,
  KeyboardPositionObservable,
  KeyboardStateObservable,
} from '@t5s/mobile-client/provider-token/keyboard';
import { KeyboardService, ServiceKeyboardModule } from '@t5s/mobile-client/service/keyboard';
import { KeyboardPosition } from '@t5s/mobile-client/value-object/keyboard';
import { distinctUntilChangedSerialize } from '@t5s/shared/util/rxjs';
import { map, scan, share, shareReplay } from 'rxjs/operators';
import { KeyboardEffects } from './keyboard.effects';

@NgModule({
  imports: [EffectsModule.forFeature([KeyboardEffects]), ServiceKeyboardModule],
  providers: [
    {
      provide: KeyboardStateObservable,
      useFactory: (keyboardService: KeyboardService): KeyboardStateObservable =>
        keyboardService.state$.pipe(
          scan(
            (state, newState) => ({
              keyboardPosition: newState.keyboardPosition || state.keyboardPosition,
              keyboardHeight: newState.keyboardHeight || state.keyboardHeight,
            }),
            {
              keyboardPosition: KeyboardPosition.DID_HIDE,
              keyboardHeight: 0,
            },
          ),
          shareReplay(1),
        ),
      deps: [KeyboardService],
    },
    {
      provide: KeyboardPositionObservable,
      useFactory: (keyboardState$: KeyboardStateObservable): KeyboardPositionObservable =>
        keyboardState$.pipe(
          map(({ keyboardPosition }) => ({ keyboardPosition })),
          distinctUntilChangedSerialize(),
          share(),
        ),
      deps: [KeyboardStateObservable],
    },
    {
      provide: KeyboardHeightObservable,
      useFactory: (keyboardState$: KeyboardStateObservable): KeyboardHeightObservable =>
        keyboardState$.pipe(
          map(({ keyboardHeight }) => ({ keyboardHeight })),
          distinctUntilChangedSerialize(),
          share(),
        ),
      deps: [KeyboardStateObservable],
    },
  ],
})
export class KeyboardBusinessLogicModule {}
