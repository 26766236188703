/* eslint-disable @typescript-eslint/no-misused-promises */
import { Injectable } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { UntilDestroy, untilDestroy } from '@t5s/client/util/component-until-destroy';
import { merge, Subject } from 'rxjs';
import { debounceTime, filter, mapTo, scan, shareReplay, startWith } from 'rxjs/operators';

const HIDE_DEBOUNCE = 100;

@UntilDestroy()
@Injectable()
export class SplashScreenService {
  private readonly hide$$ = new Subject<undefined>();
  private readonly hide$ = this.hide$$.asObservable().pipe(mapTo(-1));
  private readonly show$$ = new Subject<undefined>();
  private readonly show$ = this.show$$.asObservable().pipe(mapTo(1));

  private readonly count$ = merge(this.hide$, this.show$).pipe(
    startWith(1), // initial value is 1, as app is born with splash screen
    scan((acc, curr) => acc + curr),
    shareReplay(1),
  );

  constructor() {
    const shouldShow$ = this.count$.pipe(filter((count) => count > 0));
    const shouldHide$ = this.count$.pipe(
      debounceTime(HIDE_DEBOUNCE),
      filter((count) => count === 0),
    );

    const shouldThrow$ = this.count$.pipe(filter((count) => count < 0));

    shouldShow$
      .pipe(untilDestroy(this))
      .subscribe(() => SplashScreen.show({ autoHide: false, fadeInDuration: 200, fadeOutDuration: 200 }));

    shouldHide$.pipe(untilDestroy(this)).subscribe(() => SplashScreen.hide({ fadeOutDuration: 200 }));

    shouldThrow$.pipe(untilDestroy(this)).subscribe(() => {
      // throw new Error(
      //   'Negative splash screen vote count. Make sure to call show before calling hide and sync the calls.',
      // );
    });
  }

  show() {
    this.show$$.next(undefined);
  }

  hide() {
    this.hide$$.next(undefined);
  }
}
