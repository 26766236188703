import { Timezone } from '@t5s/shared/gql';
import { utcToZonedTime } from '@t5s/shared/util/timezone';
import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { formatDateNoLocale, normalizeDate } from '../common';

/** Used for CSV and XLSX exports. */
export function formatExportDateTime(
  i18n: SharedI18n,
  date: string | Date | undefined,
  timezone?: Timezone,
): string | undefined {
  if (!date) {
    return undefined;
  }

  date = normalizeDate(date);

  // if a timezone is provided, travel to that timezone first
  if (timezone) {
    date = utcToZonedTime(date, timezone);
  }

  const formattedDate = formatDateNoLocale(date, 'yyyy-MM-dd HH:mm');

  return `${formattedDate}`;
}
