export interface UserRemovePushSilentNotificationPayload {
  /** Thread identifier to detemine which notifications should be removed client side.
   *
   * NOTE: DO not change this property name without altering the respective native code in iOS and Android projects. This will be a breaking change!
   * */
  removeThreadId: string;
}

export function isSilentRemovePushNotificationPayload(
  payload: unknown | undefined | null,
): payload is UserRemovePushSilentNotificationPayload {
  return (payload as any)?.removeThreadId !== undefined; // TODO: use dedicated type property?
}
