import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AppInfo } from '@capacitor/app';
import { ConnectionStatus } from '@capacitor/network';
import {
  border,
  ComponentStyle,
  establishStackingContext,
  firstChild,
  flexCenter,
  flexColumn,
  lastChild,
  padding,
  px,
  spread,
  textSelect,
  verticalScroll,
} from '@t5s/client/ui/style/common';
import { RuntimeFeatureAccessLevelObservable } from '@t5s/client/util/feature-access-level';
import { tss } from '@t5s/client/util/tss';
import { BUILD_ENV, RuntimeEnvironment, RUNTIME_ENV, WEB_VIEW_LOCALHOST } from '@t5s/mobile-client/env';
import {
  ActiveUserIdObservable,
  ActiveUserSessionCookieObservable,
} from '@t5s/mobile-client/provider-token/active-user';
import { ApplicationInfoObservable } from '@t5s/mobile-client/provider-token/application-info';
import { DeviceObservable } from '@t5s/mobile-client/provider-token/device';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
import { NetworkStatusObservable } from '@t5s/mobile-client/provider-token/network-status';
import {
  FcmTokenObservable,
  ReceivedPushNotificationsObservable,
} from '@t5s/mobile-client/provider-token/push-notification';
import { SafeAreaDimensionsObservable } from '@t5s/mobile-client/provider-token/safe-area';
import { UrlObservable } from '@t5s/mobile-client/provider-token/url';
import { ViewportDimensionsObservable } from '@t5s/mobile-client/provider-token/viewport-dimensions';
import { ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { ViewComponent, ViewState } from '@t5s/mobile-client/ui/view/common';
import { ConsoleStatement } from '@t5s/mobile-client/value-object/debug-console';
import { DeviceInfoValObj } from '@t5s/mobile-client/value-object/device';
import { PushNotificationSchema } from '@t5s/mobile-client/value-object/push-notification';
import { SafeAreaDimensions } from '@t5s/mobile-client/value-object/safe-area';
import { ViewportDimensions } from '@t5s/mobile-client/value-object/viewport-dimensions';
import { AuthenticateWithCredentialsInput, UserFeatureAccessLevel } from '@t5s/shared/gql';
import { formatDate, parseISO } from '@t5s/shared/util/date';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface DebugConsoleViewState extends ViewState {
  safeArea: SafeAreaDimensions;
  viewport: ViewportDimensions;
  url: string;
  version?: string;
  activeUserId: number | undefined;
  sessionCookie: string | undefined;
  device: DeviceInfoValObj | undefined;
  network: ConnectionStatus | undefined;
  featureAccessLevel: UserFeatureAccessLevel | undefined;
  location: string;
  storageKeys?: string[];
  logs?: ConsoleStatement[];
  fcmToken?: string;
  notifications?: PushNotificationSchema[];
  appInfo: AppInfo;
}

@Component({
  selector: 't5s-debug-console-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostSpread],
  template: `
    <ng-container *ngIf="state$ | push as state">
      <div data-cy="t5s-mc-debug-console-view" [class]="containerClass$ | push">
        <!-- Header -->
        <div [class]="headerClass">
          <t5s-text [font]="Font.black18px"> Developer Info</t5s-text>

          <t5s-header-link [class]="headerBtnClass" [font]="Font.regular16px" (linkClick)="closeClick.emit()">
            Close
          </t5s-header-link>
        </div>

        <!-- Body -->
        <div [class]="bodyClass$ | push">
          <!-- Version -->
          <div [class]="segmentClass">
            <t5s-text [class]="segmentHeaderClass" [font]="Font.black18px">Version</t5s-text>
            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Mobile-client build version </t5s-text>
              <t5s-text [font]="Font.regular14px" [fgColor]="Color.darker"> {{ state.version }} </t5s-text>
            </div>

            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Native-app name </t5s-text>
              <t5s-text [font]="Font.regular14px" [fgColor]="Color.darker"> {{ state.appInfo?.name }} </t5s-text>
            </div>

            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Native-app ID </t5s-text>
              <t5s-text [font]="Font.regular14px" [fgColor]="Color.darker"> {{ state.appInfo?.id }} </t5s-text>
            </div>

            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Native-app build version </t5s-text>
              <t5s-text [font]="Font.regular14px" [fgColor]="Color.darker"> {{ state.appInfo?.build }} </t5s-text>
            </div>

            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Native-app build name </t5s-text>
              <t5s-text [font]="Font.regular14px" [fgColor]="Color.darker"> {{ state.appInfo?.version }} </t5s-text>
            </div>
          </div>

          <!-- Console -->
          <div [class]="segmentClass">
            <t5s-text [class]="segmentHeaderClass" [font]="Font.black18px">Console</t5s-text>
            <div #consoleContainer [class]="consoleContainerClass">
              <pre>{{ getConsoleStatements(state) }}</pre>
            </div>
          </div>

          <!-- Environment -->
          <div [class]="segmentClass">
            <t5s-text [class]="segmentHeaderClass" [font]="Font.black18px">Environment</t5s-text>
            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Build environment </t5s-text>
              <t5s-text [font]="Font.regular14px" [fgColor]="Color.darker"> {{ buildEnv }} </t5s-text>
            </div>

            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Runtime environment </t5s-text>
              <t5s-text [font]="Font.regular14px" [fgColor]="Color.darker"> {{ runtimeEnv }} </t5s-text>
            </div>

            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Running on WebView localhost </t5s-text>
              <t5s-text [font]="Font.regular14px" [fgColor]="Color.darker"> {{ webViewLocalhost }} </t5s-text>
            </div>

            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Location </t5s-text>
              <t5s-text [font]="Font.regular14px" [fgColor]="Color.darker"> {{ state.location }} </t5s-text>
            </div>

            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Viewport dimensions </t5s-text>
              <t5s-text [font]="Font.regular14px" [fgColor]="Color.darker">
                <pre>{{ prettyJson(state.viewport) }}</pre>
              </t5s-text>
            </div>

            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Safe Area </t5s-text>
              <t5s-text [font]="Font.regular14px" [fgColor]="Color.darker">
                <pre>{{ prettyJson(state.safeArea) }}</pre>
              </t5s-text>
            </div>

            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Device Info </t5s-text>
              <t5s-text [font]="Font.regular14px" [fgColor]="Color.darker">
                <pre>{{ prettyJson(state.device) }}</pre>
              </t5s-text>
            </div>

            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Network Status </t5s-text>
              <t5s-text [font]="Font.regular14px" [fgColor]="Color.darker">
                <pre>{{ prettyJson(state.network) }}</pre>
              </t5s-text>
            </div>
          </div>

          <!-- Push Notifications -->
          <div [class]="segmentClass">
            <t5s-text [class]="segmentHeaderClass" [font]="Font.black18px">Push Notifications</t5s-text>
            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> FCM Token </t5s-text>
              <t5s-text (click)="emitFcmTokenClick()" [font]="Font.regular14px" [fgColor]="Color.darker">
                {{ state.fcmToken || 'No FCM token available.' }}
              </t5s-text>
            </div>

            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Received notifications </t5s-text>
              <div [class]="nestedConsoleContainerClass">
                <pre>{{ getReceivedPushNotifications(state) }}</pre>
              </div>
            </div>
          </div>

          <!-- Login -->
          <ng-container *ngIf="runtimeEnv !== RuntimeEnvironment.PRD">
            <div [class]="segmentClass">
              <!-- Header -->
              <div [class]="segmentHeaderClass">
                <t5s-text [font]="Font.black18px">Login</t5s-text>
              </div>

              <div [class]="segmentBodyClass">
                <ng-container *ngFor="let user of users">
                  <t5s-link
                    [style.margin-bottom.px]="8"
                    [font]="Font.regular14px"
                    (linkClick)="userLoginClick.emit(user)"
                  >
                    {{ user.credentials.email }}
                  </t5s-link>
                </ng-container>
              </div>
              <t5s-divider [height]="1" [bgColor]="Color.light"></t5s-divider>
            </div>
          </ng-container>

          <!-- User -->
          <div [class]="segmentClass">
            <t5s-text [class]="segmentHeaderClass" [font]="Font.black18px">User</t5s-text>

            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Active User ID </t5s-text>
              <t5s-text [font]="Font.regular14px" [fgColor]="Color.darker"> {{ state.activeUserId }} </t5s-text>
            </div>

            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Session cookie </t5s-text>
              <t5s-text [font]="Font.regular14px" [fgColor]="Color.darker"> {{ state.sessionCookie }} </t5s-text>
            </div>

            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Feature access level </t5s-text>
              <t5s-text [font]="Font.regular14px" [fgColor]="Color.darker"> {{ state.featureAccessLevel }} </t5s-text>
            </div>

            <div [class]="valueContainerClass">
              <t5s-text [font]="Font.medium14px"> Internationalization </t5s-text>
              <t5s-text [font]="Font.regular14px" [fgColor]="Color.darker">
                <pre>{{ prettyJson(state.i18n) }}</pre>
              </t5s-text>
            </div>
          </div>

          <!-- Storage -->
          <div [class]="segmentClass">
            <!-- Header -->
            <div [class]="segmentHeaderClass">
              <t5s-text [font]="Font.black18px">Storage</t5s-text>
            </div>

            <div [class]="segmentBodyClass">
              <ng-container *ngFor="let storageKey of state.storageKeys">
                <t5s-link
                  [style.margin-bottom.px]="8"
                  [font]="Font.regular14px"
                  (linkClick)="storageKeyClick.emit({ key: storageKey })"
                >
                  {{ storageKey }}
                </t5s-link>
              </ng-container>
            </div>
            <t5s-divider [height]="1" [bgColor]="Color.light"></t5s-divider>
          </div>

          <!-- Sandbox -->
          <div [class]="segmentClass">
            <!-- Header -->
            <div [class]="segmentHeaderClass">
              <t5s-text [font]="Font.black18px">Sandbox</t5s-text>
            </div>

            <div [class]="segmentBodyClass">
              <t5s-link (linkClick)="performanceLabClick.emit()"> Performance Lab </t5s-link>
            </div>
          </div>

          <t5s-divider [height]="13"></t5s-divider>
        </div>
      </div>
    </ng-container>
  `,
})
export class DebugConsoleViewComponent extends ViewComponent<DebugConsoleViewState> {
  readonly buildEnv = BUILD_ENV;
  readonly runtimeEnv = RUNTIME_ENV;
  readonly webViewLocalhost = WEB_VIEW_LOCALHOST;
  readonly RuntimeEnvironment = RuntimeEnvironment;

  readonly users: AuthenticateWithCredentialsInput[] = [
    {
      orgId: 1,
      credentials: {
        email: 'default@tape.dev',
        password: 'tape',
      },
    },
    {
      orgId: 1,
      credentials: {
        email: 'tim@tapeapp.com',
        password: 'tape',
      },
    },
    {
      orgId: 1,
      credentials: {
        email: 'ben@tapeapp.com',
        password: 'tape',
      },
    },
    {
      orgId: 1,
      credentials: {
        email: 'karl.braun@tape.dev',
        password: 'tape',
      },
    },
  ];

  constructor(
    readonly i18nObservable$: I18nObjectObservable,
    readonly safeArea$: SafeAreaDimensionsObservable,
    readonly url$: UrlObservable,
    readonly activeUserId$: ActiveUserIdObservable,
    readonly sessionCookie$: ActiveUserSessionCookieObservable,
    readonly device$: DeviceObservable,
    readonly network$: NetworkStatusObservable,
    readonly featureAccessLevel$: RuntimeFeatureAccessLevelObservable,
    readonly fcmToken$: FcmTokenObservable,
    readonly receivedPushNotifications$: ReceivedPushNotificationsObservable,
    readonly viewportDimensions$: ViewportDimensionsObservable,
    readonly appInfo$: ApplicationInfoObservable,
  ) {
    super(i18nObservable$);

    this.set({
      location: window.location.toString(),
    });

    this.connect(safeArea$.pipe(map((safeArea) => ({ safeArea }))));
    this.connect(viewportDimensions$.pipe(map((viewport) => ({ viewport }))));
    this.connect(url$.pipe(map((url) => ({ url }))));
    this.connect(activeUserId$.pipe(map((activeUserId) => ({ activeUserId }))));
    this.connect(sessionCookie$.pipe(map((sessionCookie) => ({ sessionCookie }))));
    this.connect(device$.pipe(map((device) => ({ device }))));
    this.connect(network$.pipe(map((network) => ({ network }))));
    this.connect(featureAccessLevel$.pipe(map((featureAccessLevel) => ({ featureAccessLevel }))));
    this.connect(fcmToken$);
    this.connect(receivedPushNotifications$.pipe(map((notifications) => ({ notifications }))));
    this.connect(appInfo$);
  }

  @Input() set version(version: string | Observable<string>) {
    this.setProperty('version', version);
  }

  @Input() set storageKeys(storageKeys: string[] | Observable<string[]>) {
    this.setProperty('storageKeys', storageKeys);
  }

  @Input() set logs(logs: string[] | Observable<string[]>) {
    this.setProperty('logs', logs);
  }

  @Output() closeClick = new EventEmitter<never>();
  @Output() storageKeyClick = new EventEmitter<{ key: string }>();
  @Output() fcmTokenClick = new EventEmitter<{ fcmToken: string }>();
  @Output() userLoginClick = new EventEmitter<AuthenticateWithCredentialsInput>();
  @Output() performanceLabClick = new EventEmitter<never>();

  @ViewChild('consoleContainer') consoleContainer?: ElementRef<HTMLDivElement>;

  readonly containerClass$ = this.state$.pipe(
    map(({ safeArea: { top } }) =>
      tss({
        ...spread,
        ...flexColumn,
        paddingTop: px(top),
        backgroundColor: ThemeColorVar.lightest,
        ...establishStackingContext,
      }),
    ),
  );

  readonly headerClass = tss({
    ...flexCenter,
    position: 'relative',
    padding: padding(11, 15),
    borderBottom: border(1, 'solid', ThemeColorVar.light),
    flex: 0,
    zIndex: 1,
  });

  readonly bodyClass$ = this.state$.pipe(
    map(({ safeArea: { bottom } }) =>
      tss({
        flex: 1,
        position: 'relative',
        backgroundColor: ThemeColorVar.lighter,
        minHeight: px(0),
        marginTop: px(-1),
        paddingBottom: px(bottom),
        ...verticalScroll,
        zIndex: 0,
      }),
    ),
  );

  readonly headerBtnClass = tss({
    position: 'absolute',
    top: px(13),
    left: px(15),
  });

  readonly segmentClass = tss({
    backgroundColor: ThemeColorVar.lightest,
    marginBottom: px(13),
  });

  readonly segmentHeaderClass = tss({
    padding: padding(9, 13),
    borderTop: border(1, 'solid', ThemeColorVar.light),
    borderBottom: border(1, 'solid', ThemeColorVar.light),
    backgroundColor: ThemeColorVar.lightest,
    position: 'sticky',
    top: px(-1),
  });

  readonly segmentBodyClass = tss({
    padding: padding(9, 13),
  });

  readonly valueContainerClass = tss({
    borderBottom: border(1, 'solid', ThemeColorVar.light),
    padding: padding(9, 13),
    minHeight: px(36),
    ...firstChild({
      marginBottom: px(2),
    }),
    ...lastChild({
      fontFamily: 'monospace ',
      ...textSelect,
      overflowX: 'auto',
      whiteSpace: 'nowrap',
    }),
  });

  readonly consoleContainerClass = tss({
    borderBottom: border(1, 'solid', ThemeColorVar.light),
    padding: padding(9, 13),
    minHeight: px(75),
    maxHeight: px(150),
    fontFamily: 'monospace ',
    ...verticalScroll,
    overflowX: 'auto',
    ...textSelect,
  });

  readonly nestedConsoleContainerClass = tss({
    minHeight: px(75),
    maxHeight: px(150),
    fontFamily: 'monospace ',
    ...verticalScroll,
    overflowX: 'auto',
    ...textSelect,
  });

  getConsoleStatements(state: DebugConsoleViewState) {
    return (state.logs ?? [])
      .map(({ level, content, createdAt }) => `[${formatDate(parseISO(createdAt), 'HH:mm:ss')}] ${content}`)
      .join('\n');
  }

  getReceivedPushNotifications(state: DebugConsoleViewState) {
    return (state.notifications ?? [])
      .map(
        ({ recvAt, ...notification }) =>
          `[${formatDate(parseISO(recvAt), 'HH:mm:ss')}] ${notification.title} ${JSON.stringify(notification)}`,
      )
      .join('\n');
  }

  prettyJson(json: object) {
    if (!json) {
      return '';
    }

    const largestEntryNumChars = Math.max(...Object.keys(json).map((key) => key.length), 0) + 1;

    return (
      Object.entries(json)
        .filter(([_, value]) => typeof value !== 'object')
        // eslint-disable-next-line sonarjs/no-nested-template-literals
        .map(([key, value]) => `${`${key}:`.padEnd(largestEntryNumChars, ' ')} ${value}`)
        .join('\n')
    );
  }

  scrollConsoleToBottom() {
    const el = this.consoleContainer?.nativeElement;
    if (el) {
      el.scrollTop = el.scrollHeight;
    }
  }

  emitFcmTokenClick() {
    const { fcmToken } = this.get();
    if (fcmToken) {
      this.fcmTokenClick.emit({ fcmToken });
    }
  }
}
