import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  border,
  exactHeight,
  fullHeight,
  fullWidth,
  hairlineBorder,
  HAIRLINE_WIDTH_PX,
} from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent } from '@t5s/mobile-client/ui/component/common';
import { ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface HairlineDividerState {
  vertical: boolean;
  bgColor: string;
}

@Component({
  selector: 't5s-hairline-divider',
  template: `<div [class]="dividerClass$ | push"> </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HairlineDividerComponent extends RxComponent<HairlineDividerState> {
  constructor() {
    super();
    this.set({
      vertical: false,
      bgColor: ThemeColorVar.dark,
    });
  }

  @Input() set vertical(vertical: boolean | Observable<boolean>) {
    this.setProperty('vertical', vertical);
  }

  @Input() set bgColor(bgColor: string | Observable<string>) {
    this.setProperty('bgColor', bgColor);
  }

  readonly dividerClass$ = this.state$.pipe(
    map(({ vertical, bgColor }) => {
      if (vertical) {
        return tss({
          ...fullHeight,
          borderLeft: border(1, 'solid', bgColor),
        });
      }
      return tss({
        ...fullWidth,
        borderTop: hairlineBorder(bgColor),
        ...exactHeight(HAIRLINE_WIDTH_PX + 0.03), // Not rendered otherwise in Safari
      });
    }),
  );
}
