import gql from 'graphql-tag';
import { FullUserProfileDtoFragment } from '../user-profile';

export const UserSearchResultFragment = gql`
  fragment UserSearchResult on UserSearchResultDto {
    type
    user {
      ...FullUserProfileDto
    }
  }
  ${FullUserProfileDtoFragment}
`;
