import gql from 'graphql-tag';
import { BlabItemActivityReplyDtoFragment } from './blab-item-activity-reply-dto.fragment';

export const BlabItemActivityReplySubscriptionUpdateFragment = gql`
  fragment BlabItemActivityReplySubscriptionUpdateFragment on BlabItemActivityReplySubscriptionUpdate {
    ... on BlabItemActivityReplyDto {
      ...BlabItemActivityReplyDtoFragment
    }

    ... on BlabItemActivityReplyDeletedDto {
      id
    }
  }
  ${BlabItemActivityReplyDtoFragment}
`;
