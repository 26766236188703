import gql from 'graphql-tag';
import { WorkspaceBlabDefDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const WorkspaceBlabDefConnectionFragment = gql`
  fragment WorkspaceBlabDefConnectionFragment on WorkspaceBlabDefConnection {
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...WorkspaceBlabDefDtoNoNesting
      }
    }
  }
  ${WorkspaceBlabDefDtoNoNestingFragment}
`;
