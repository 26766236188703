import gql from 'graphql-tag';
import { AudioRoomDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { AudioRoomAuthorFragment } from './audio-room-author.fragment';
import { AudioRoomParticipantDtoFragment } from './audio-room-participant.dto.fragment';

export const AudioRoomDtoFragment = gql`
  fragment AudioRoomDtoFragment on AudioRoomDto {
    ...AudioRoomDtoNoNesting
    participants {
      ...AudioRoomParticipantDtoFragment
    }
    author {
      ...AudioRoomAuthorFragment
    }
  }
  ${AudioRoomDtoNoNestingFragment}
  ${AudioRoomParticipantDtoFragment}
  ${AudioRoomAuthorFragment}
`;
