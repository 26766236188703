interface HtmlToTextOptions {}

function _htmlToText(html: string) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

export function htmlToText(input: string): string;
export function htmlToText(input: string, opts: HtmlToTextOptions): string;
export function htmlToText(input: string | undefined, opts: HtmlToTextOptions = {}): string | undefined {
  if (input === undefined) {
    return undefined;
  }

  return _htmlToText(input);
}

export function htmlToTextTrimmed(input: string): string;
export function htmlToTextTrimmed(input: string, opts: HtmlToTextOptions): string;
export function htmlToTextTrimmed(input: string | undefined, opts: HtmlToTextOptions = {}): string | undefined {
  if (input === undefined) {
    return undefined;
  }

  const text = _htmlToText(input);
  return text.trim();
}
