import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { notLastChild, px } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';

@Component({
  selector: 't5s-flex-row',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FlexRowComponent {
  constructor(private readonly elRef: ElementRef<HTMLElement>) {
    elRef.nativeElement.style.setProperty('display', 'flex');
    elRef.nativeElement.style.setProperty('flex-direction', 'row');
    elRef.nativeElement.style.setProperty('width', '100%');
  }

  @Input() set gap(gap: number) {
    this.elRef.nativeElement.classList.add(tss({ ...notLastChild({ marginRight: px(gap) }) }));
  }
}
