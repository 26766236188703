import gql from 'graphql-tag';
import { UserProfileDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const UserFollowersConnectionFragment = gql`
  fragment UserFollowersConnectionFragment on UserFollowersConnection {
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...UserProfileDtoNoNesting
      }
    }
  }
  ${UserProfileDtoNoNestingFragment}
`;
