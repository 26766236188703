import { Injectable } from '@angular/core';
import {
  EntityIconGraphicData,
  EntityIconGraphicsData,
  EntityIconPickerEmojiSettings,
  EntityIconPickerEmojisMetadata,
  EntityIconPickerGraphicsMetadata,
  EntityIconPickerGraphicsSettings,
  LoadEntityIconPickerEmojisMetadataInput,
  LoadEntityIconPickerGraphicsMetadataInput,
} from '@t5s/client/value-object/entity-icon';
import { Observable } from 'rxjs';

@Injectable()
export abstract class EntityIconAbstractService {
  abstract loadGraphicData(): Observable<EntityIconGraphicsData>;
  abstract loadGraphicMetadata(
    input: LoadEntityIconPickerGraphicsMetadataInput,
  ): Observable<EntityIconPickerGraphicsMetadata>;

  // Get graphic data by id
  abstract getGraphicDataById(id: string): Observable<EntityIconGraphicData | undefined>;

  // Load graphic settings
  abstract loadGraphicSettings(): EntityIconPickerGraphicsSettings;

  // Save graphic settings
  abstract saveGraphicSettings(settings: EntityIconPickerGraphicsSettings): void;

  // Load emoji data
  abstract loadEmojiMetadata(
    input: LoadEntityIconPickerEmojisMetadataInput,
  ): Observable<EntityIconPickerEmojisMetadata>;

  // Load emoji settings
  abstract loadEmojiSettings(): EntityIconPickerEmojiSettings;

  // Save emoji settings
  abstract saveEmojiSettings(settings: EntityIconPickerEmojiSettings): void;
}
