import {
  AddBlabItemActivityReplyInput,
  AddBlabItemActivityReplyReactionInput,
  BlabItemActivityReplyAttachmentDto,
  BlabItemActivityReplyConnection,
  BlabItemActivityReplyDto,
  BlabItemActivityReplySubscriptionUpdate,
  GetBlabItemActivityRepliesInput,
  RemoveBlabItemActivityReplyInput,
  RemoveBlabItemActivityReplyReactionInput,
  Upload,
  UploadBlabItemActivityReplyAttachmentInput,
  Void,
} from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import {
  BlabItemActivityReplyAttachmentFragment,
  BlabItemActivityReplyConnectionFragment,
  BlabItemActivityReplyDtoFragment,
  BlabItemActivityReplySubscriptionUpdateFragment,
} from '../../fragments/blab-item-activity';
import {
  addBlabItemActivityReply,
  addBlabItemActivityReplyReaction,
  removeBlabItemActivityReply,
  removeBlabItemActivityReplyReaction,
  uploadBlabItemActivityReplyAttachment,
} from '../../generated/graphql-mutations';
import { getBlabItemActivityReplies } from '../../generated/graphql-queries';
import { subscribeToBlabItemActivityReplies } from '../../generated/graphql-subscriptions';
import { GqlService } from '../../gql-service';

export class GqlBlabItemActivityReplyService extends GqlService {
  getBlabItemActivityReplyConnection(
    first: number,
    input?: GetBlabItemActivityRepliesInput,
    after?: string,
  ): Observable<BlabItemActivityReplyConnection> {
    return this.apollo.defaultQuery(
      getBlabItemActivityReplies(first, input, after, [BlabItemActivityReplyConnectionFragment]),
    );
  }

  addBlabItemActivityReply(input: AddBlabItemActivityReplyInput): Observable<BlabItemActivityReplyDto> {
    return this.apollo.defaultMutate(addBlabItemActivityReply(input, [BlabItemActivityReplyDtoFragment]));
  }

  removeBlabItemActivityReply(input: RemoveBlabItemActivityReplyInput): Observable<Void> {
    return this.apollo.defaultMutate(removeBlabItemActivityReply(input));
  }

  addBlabItemActivityReplyReaction(input: AddBlabItemActivityReplyReactionInput): Observable<BlabItemActivityReplyDto> {
    return this.apollo.defaultMutate(addBlabItemActivityReplyReaction(input, [BlabItemActivityReplyDtoFragment]));
  }

  uploadBlabItemActivityReplyAttachment(
    input: UploadBlabItemActivityReplyAttachmentInput,
    attachment: Upload,
  ): Observable<BlabItemActivityReplyAttachmentDto> {
    return this.apollo.defaultMutateFileUpload(
      uploadBlabItemActivityReplyAttachment(input, attachment, [BlabItemActivityReplyAttachmentFragment]),
    );
  }

  removeBlabItemActivityReplyReaction(
    input: RemoveBlabItemActivityReplyReactionInput,
  ): Observable<BlabItemActivityReplyDto> {
    return this.apollo.defaultMutate(removeBlabItemActivityReplyReaction(input, [BlabItemActivityReplyDtoFragment]));
  }

  subscribeToBlabItemActivityReplies(activityId: number): Observable<BlabItemActivityReplySubscriptionUpdate> {
    return this.apollo.defaultSubscribe(
      subscribeToBlabItemActivityReplies(activityId, [BlabItemActivityReplySubscriptionUpdateFragment]),
    );
  }
}
