import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { injectGlobalTss } from '@t5s/client/util/tss';
import { BuildEnvironment, BUILD_ENV } from '@t5s/mobile-client/env';
import { mobileClientGlobalStyles } from '@t5s/mobile-client/ui/style/common';
import { MobileClientRootModule } from './app/root.module';

// Comment to bump version

if (BUILD_ENV === BuildEnvironment.PRD) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(MobileClientRootModule, { ngZone: 'zone.js' })
  // eslint-disable-next-line no-console
  .catch((err) => console.error(err));

injectGlobalTss(mobileClientGlobalStyles);
