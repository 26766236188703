import gql from 'graphql-tag';
import { BlabItemActivityReplyDtoFragment } from '../blab-item-activity';
import { NotificationActivityDtoFragment } from '../notification/notification-activity-dto.fragment';
import { BlabItemActivityUnionTypeFragment } from './blab-item-activity-union-type.fragment';

export const BlabItemNotificationActivityDtoFragmentV2 = gql`
  fragment BlabItemNotificationActivityDtoFragmentV2 on BlabItemNotificationActivityDto {
    ...NotificationActivityDtoFragment

    activity {
      ...NotificationBlabItemActivityUnionTypeFragmentV2
    }

    activityReply {
      ...BlabItemActivityReplyDtoFragment
    }
  }
  ${NotificationActivityDtoFragment}
  ${BlabItemActivityUnionTypeFragment}
  ${BlabItemActivityReplyDtoFragment}
`;
