import { IonNav } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { selectSlice } from '@rx-angular/state/selections';
import { map, startWith } from 'rxjs/operators';
import { Page } from './page';

export interface PushablePageState {
  entered: boolean;
}

export abstract class PushablePage<T extends PushablePageState = PushablePageState> extends Page<T> {
  abstract ionNav: IonNav;

  readonly visible$ = this.select(
    selectSlice(['entered']),
    map(({ entered }) => entered),
    startWith(false),
  );

  ionViewWillEnter() {
    super.ionViewWillEnter();
    this.set({ entered: true } as T);

    setTimeout(() => {
      // markDirty(this); // TODO:
    });
  }

  ionViewDidLeave() {
    super.ionViewDidLeave();
    this.set({ entered: false } as T);
  }

  popPage(opts?: NavigationOptions | null) {
    // check whether this nav can actually go back
    return this.ionNav.canGoBack().then((canGoBack) => {
      if (canGoBack) {
        return this.ionNav.pop(opts);
      }

      return undefined;
    });
  }

  popToRoot(opts?: NavigationOptions | null) {
    return this.ionNav.popToRoot(opts);
  }
}
