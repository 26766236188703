import { I18nTranslation } from '@t5s/mobile-client/i18n/common';
import { Language } from '@t5s/shared/gql';

const languages = {
  [Language.DE]: {
    networkException: 'Verbindung mit Tape fehlgeschlagen.',
    genericException: 'Es ist ein Fehler aufgetreten.',
  },
  [Language.EN]: {
    networkException: 'Connection could not be established.',
    genericException: 'An error occurred.',
  },
} as const;

export const ExceptionToastI18n = new I18nTranslation<typeof languages.EN>(languages);
