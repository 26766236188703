import {
  ActiveUserBlabItemInfoDto,
  ActiveUserUserInfoDto,
  BlabDefSettingsDto,
  FlimType,
  FlimValUnionType,
  GqlObj,
  OrganizationSettingsDto,
  UserFollowInfoDto,
  UserStatusDto,
  WorkspaceInvitationDto,
  WorkspaceSettingsDto,
  WorkspaceUserDto,
} from '@t5s/shared/gql';
import { defaultDataIdFromObject } from 'apollo-cache-inmemory';

function isFlimValDto(flimValCandidate: GqlObj<FlimValUnionType>): flimValCandidate is GqlObj<FlimValUnionType> {
  const { type: flimType, __typename } = flimValCandidate;
  switch (flimType) {
    case FlimType.SINGLE_TEXT:
      return __typename === 'SingleTextFlimValDto';
    case FlimType.MULTI_TEXT:
      return __typename === 'MultiTextFlimValDto';
    case FlimType.SINGLE_CATEGORY:
      return __typename === 'SingleCategoryFlimValDto';
    case FlimType.MULTI_CATEGORY:
      return __typename === 'MultiCategoryFlimValDto';
    case FlimType.SINGLE_RELATION:
      return __typename === 'SingleRelationFlimValDto';
    case FlimType.MULTI_RELATION:
      return __typename === 'MultiRelationFlimValDto';
    case FlimType.SINGLE_ATTACHMENT:
      return __typename === 'SingleAttachmentFlimValDto';
    case FlimType.MULTI_ATTACHMENT:
      return __typename === 'MultiAttachmentFlimValDto';
    case FlimType.SINGLE_USER:
      return __typename === 'SingleUserFlimValDto';
    case FlimType.MULTI_USER:
      return __typename === 'MultiUserFlimValDto';
    case FlimType.SINGLE_DATE:
      return __typename === 'SingleDateFlimValDto';
    case FlimType.RANGE_DATE:
      return __typename === 'RangeDateFlimValDto';
    case FlimType.CHECKLIST:
      return __typename === 'ChecklistFlimValDto';
    case FlimType.MULTI_LINK:
      return __typename === 'MultiLinkFlimValDto';
    case FlimType.CREATED_AT:
      return __typename === 'CreatedAtFlimValDto';
    case FlimType.CREATED_BY:
      return __typename === 'CreatedByFlimValDto';
    case FlimType.LAST_MODIFIED_AT:
      return __typename === 'LastModifiedAtFlimValDto';
    case FlimType.LAST_MODIFIED_BY:
      return __typename === 'LastModifiedByFlimValDto';
    case FlimType.NUMBER:
      return __typename === 'NumberFlimValDto';
    case FlimType.STATUS:
      return __typename === 'StatusFlimValDto';
    case FlimType.CALCULATION:
      return __typename === 'CalculationFlimValDto';
    case FlimType.MULTI_EMAIL:
      return __typename === 'MultiEmailFlimValDto';
    case FlimType.MULTI_PHONE:
      return __typename === 'MultiPhoneFlimValDto';
    case FlimType.MULTI_IMAGE:
      return __typename === 'MultiImageFlimValDto';
    case FlimType.SINGLE_LOCATION:
      return __typename === 'SingleLocationFlimValDto';
    case FlimType.UNIQUE_ID:
      return __typename === 'UniqueIdFlimValDto';
  }
}

export const dataIdFromObject = (obj: GqlObj): string | null => {
  if (isFlimValDto(obj)) {
    return `FlimVal:${obj.blabItemId}/${obj.flimDefId}`;
  }

  switch (obj.__typename) {
    case `ActiveUserBlabItemInfoDto`: {
      const dto = obj as ActiveUserBlabItemInfoDto;
      return `ActiveUserBlabItemInfoDto:${dto.userId}${dto.blabItemId}`;
    }
    case `ActiveUserUserInfoDto`: {
      const dto = obj as ActiveUserUserInfoDto;
      return `ActiveUserUserInfoDto:${dto.userId}${dto.targetUserId}`;
    }
    case `UserFollowInfoDto`: {
      const dto = obj as UserFollowInfoDto;
      return `UserFollowInfoDto:${dto.userId}`;
    }
    case `UserStatusDto`: {
      const dto = obj as UserStatusDto;
      return `UserStatusDto:${dto.userId}`;
    }
    case `OrganizationSettingsDto`: {
      const dto = obj as OrganizationSettingsDto;
      return `OrganizationSettingsDto:${dto.organizationId}`;
    }
    case `WorkspaceSettingsDto`: {
      const dto = obj as WorkspaceSettingsDto;
      return `WorkspaceSettingsDto:${dto.workspaceId}`;
    }
    case `WorkspaceUserDto`: {
      const dto = obj as WorkspaceUserDto;
      return `WorkspaceUserDto:${dto.userId}`;
    }
    case `WorkspaceInvitationDto`: {
      const dto = obj as WorkspaceInvitationDto;
      return `WorkspaceInvitationDto:${dto.organizationInvitationId}`;
    }
    case `BlabDefSettingsDto`: {
      const dto = obj as BlabDefSettingsDto;
      return `BlabDefSettingsDto:${dto.blabDefId}`;
    }

    default:
      return defaultDataIdFromObject(obj);
  }
};
