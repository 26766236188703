export const HANDLEBAR_REGEX = /\{\{([^}]+)\}\}/g;

export function getVarnameFromHandlebarStr(str: string): string {
  return str.slice(2, -2).trim();
}

export function substituteHandlebars(str: string, params?: { [key: string]: string | number | undefined }): string {
  // If not parameters are provided, return the string
  if (!params) {
    return str;
  }

  // Substitute handlebars with parameters
  return str.replace(HANDLEBAR_REGEX, (match) => {
    // Remove the wrapping curly braces, trim surrounding whitespace
    match = getVarnameFromHandlebarStr(match);

    // Get the value
    const val = params[match];

    // Replace
    if (val === undefined || val === null) {
      return '';
    }

    return `${val}`;
  });
}
