import { NgModule } from '@angular/core';
import { ViewCommonModule } from '@t5s/mobile-client/ui/view/common';
import { RootViewComponent } from './root-view.component';

const components = [RootViewComponent];

@NgModule({
  imports: [ViewCommonModule],
  declarations: components,
  exports: components,
})
export class RootViewModule {}
