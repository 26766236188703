import gql from 'graphql-tag';
import {
  ActiveUserMultiCategorySplitBySectionDtoNoNestingFragment,
  MultiCategoryOptionDefDtoNoNestingFragment,
} from '../../../../generated/graphql-fragments';

export const ActiveUserMultiCategorySplitBySectionDtoFragment = gql`
  fragment ActiveUserMultiCategorySplitBySectionDto on ActiveUserMultiCategorySplitBySectionDto {
    ...ActiveUserMultiCategorySplitBySectionDtoNoNesting
    multiCategoryOptionDef {
      ...MultiCategoryOptionDefDtoNoNesting
    }
  }
  ${ActiveUserMultiCategorySplitBySectionDtoNoNestingFragment}
  ${MultiCategoryOptionDefDtoNoNestingFragment}
`;
