import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import {
  FcmTokenObservable,
  ReceivedPushNotificationsObservable,
} from '@t5s/mobile-client/provider-token/push-notification';
import { GqlPushNotificationSetupService, SharedApolloClient } from '@t5s/shared/gql-services';
import { map } from 'rxjs/operators';
import { PushNotificationEffects } from './push-notification.effects';
import { pushNotificationReducer, pushNotificationStateKey } from './push-notification.reducer';
import { selectPushNotificationFcmToken, selectPushNotificationsReceived } from './push-notification.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(pushNotificationStateKey, pushNotificationReducer),
    EffectsModule.forFeature([PushNotificationEffects]),
  ],
  providers: [
    {
      provide: FcmTokenObservable,
      useFactory: (store$: Store) =>
        store$.select(selectPushNotificationFcmToken).pipe(map((fcmToken) => ({ fcmToken }))),
      deps: [Store],
    },
    {
      provide: ReceivedPushNotificationsObservable,
      useFactory: (store$: Store) => store$.select(selectPushNotificationsReceived),
      deps: [Store],
    },
    {
      provide: GqlPushNotificationSetupService,
      useFactory: (apollo: SharedApolloClient) => new GqlPushNotificationSetupService(apollo),
      deps: [SharedApolloClient],
    },
  ],
})
export class PushNotificationBusinessLogicModule {}
