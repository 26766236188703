import gql from 'graphql-tag';
import { BlabItemPreviewDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const MinimalIncomingBlabItemsConnection = gql`
  fragment IncomingBlabItemsConnectionFragment on IncomingBlabItemsConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        ...BlabItemPreviewDtoNoNesting
        blabDef {
          id
          blabName
          itemName
          icon
          iconv2
          itemIcon
          slug
          workspace {
            id
            name
          }
        }
      }
    }
  }
  ${BlabItemPreviewDtoNoNestingFragment}
`;
