import gql from 'graphql-tag';
import {
  SingleCategoryFlimDefDtoNoNestingFragment,
  SingleCategoryOptionDefDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const SingleCategoryFlimDefDtoFragment = gql`
  fragment SingleCategoryFlimDefDtoFragment on SingleCategoryFlimDefDto {
    ...SingleCategoryFlimDefDtoNoNesting
    categoryOptionDefs {
      ...SingleCategoryOptionDefDtoNoNesting
    }
  }
  ${SingleCategoryFlimDefDtoNoNestingFragment}
  ${SingleCategoryOptionDefDtoNoNestingFragment}
`;
