import gql from 'graphql-tag';
import { SingleUserFlimValDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { UserProfilePreviewDtoOmitOrgFragment } from '../../user-profile';

export const SingleUserFlimValFragment = gql`
  fragment SingleUserFlimVal on SingleUserFlimValDto {
    ...SingleUserFlimValDtoNoNesting
    user {
      ...UserProfilePreviewDtoOmitOrgFragment
    }
  }
  ${SingleUserFlimValDtoNoNestingFragment}
  ${UserProfilePreviewDtoOmitOrgFragment}
`;
