import gql from 'graphql-tag';
import {
  OrganizationSmtpSettingsDtoNoNestingFragment,
  OrganizationSmtpSettingsRejectionDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const OrganizationSmtpSettingsResultUnionTypeFragment = gql`
  fragment OrganizationSmtpSettingsResultUnion on OrganizationSmtpSettingsResultUnion {
    ... on OrganizationSmtpSettingsDto {
      ...OrganizationSmtpSettingsDtoNoNesting
    }

    ... on OrganizationSmtpSettingsRejectionDto {
      ...OrganizationSmtpSettingsRejectionDtoNoNesting
    }
  }
  ${OrganizationSmtpSettingsDtoNoNestingFragment}
  ${OrganizationSmtpSettingsRejectionDtoNoNestingFragment}
`;
