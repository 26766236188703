import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ServiceStateHydrationModule } from '@t5s/mobile-client/service/state-hydration';
import { AllNotificationsEffects } from './all-notifications.effects';
import { allNotificationsReducer, allNotificationStateKey } from './all-notifications.reducer';

@NgModule({
  imports: [
    ServiceStateHydrationModule,
    StoreModule.forFeature(allNotificationStateKey, allNotificationsReducer),
    EffectsModule.forFeature([AllNotificationsEffects]),
  ],
})
export class AllNotificationsModule {}
