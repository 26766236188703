import { Injectable } from '@angular/core';
import { AlertOptions, ConfirmOptions, ConfirmResult, Dialog, PromptOptions, PromptResult } from '@capacitor/dialog';
import { defer, from, Observable } from 'rxjs';

@Injectable()
export class DialogService {
  alert(options: AlertOptions): Observable<void> {
    return defer(() => from(Dialog.alert(options)));
  }

  prompt(options: PromptOptions): Observable<PromptResult> {
    return defer(() => from(Dialog.prompt(options)));
  }

  confirm(options: ConfirmOptions): Observable<ConfirmResult> {
    return defer(() => from(Dialog.confirm(options)));
  }
}
