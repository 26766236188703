import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { AnimationController, DomController } from '@ionic/angular';
import { Animation } from '@ionic/core';
import {
  boxShadow,
  establishStackingContext,
  exactHeight,
  exactSize,
  exactWidth,
  flexCenterHorizontal,
  fontWeight,
  getFontStyle,
  padding,
  px,
  spread,
} from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { Icon } from '@t5s/mobile-client/asset';
import { IconComponent } from '@t5s/mobile-client/ui/component/asset';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface NavigationBarButtonState {
  icon: Icon;
  iconActive: Icon;
  active: boolean;
  badgeAmount: number;
}

const MAX_AMOUNT_BUBBLE = 99;

@Component({
  selector: 't5s-navigation-bar-button',
  template: `
    <button #navigationBarBtn [class]="btnClass" (click)="btnClick.emit()" (t5sLongpress)="btnLongpress.emit()">
      <div [class]="iconContainerClass$ | push">
        <!-- Inactive icon -->
        <t5s-icon #icon [class]="iconClass" [size]="26" [icon]="icon$" [fgColor]="Color.darker"></t5s-icon>

        <!-- Active icon -->
        <t5s-icon
          #iconActive
          [class]="iconClass"
          [size]="26"
          [style.visibility]="'hidden'"
          [icon]="iconActive$"
          [fgColor]="Color.primary"
        ></t5s-icon>
      </div>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationBarButtonComponent extends RxComponent<NavigationBarButtonState> implements AfterViewInit {
  animation?: Animation;

  @ViewChild('navigationBarBtn') btnRef?: ElementRef<HTMLButtonElement>;
  @ViewChild('icon') iconRef?: IconComponent;
  @ViewChild('iconActive') activeIconRef?: IconComponent;

  constructor(
    elRef: ElementRef<HTMLElement>,
    private readonly animationCtrl: AnimationController,
    private readonly domCtrl: DomController,
  ) {
    super();

    this.set({
      badgeAmount: 0,
    });

    this.hold(this.select(selectSlice(['active'])), ({ active }) => {
      this.domCtrl.write(() => {
        const icon = this.iconRef?.elRef.nativeElement;
        const activeIcon = this.activeIconRef?.elRef.nativeElement;

        if (!icon || !activeIcon) {
          return;
        }

        if (active) {
          icon.style.setProperty('visibility', 'hidden');
          activeIcon.style.setProperty('visibility', 'visible');
        } else {
          icon.style.setProperty('visibility', 'visible');
          activeIcon.style.setProperty('visibility', 'hidden');
        }
      });
    });
  }

  @Input() set icon(icon: Icon | Observable<Icon>) {
    this.setProperty('icon', icon);
  }

  @Input() set iconActive(iconActive: Icon | Observable<Icon>) {
    this.setProperty('iconActive', iconActive);
  }

  @Input() set active(active: boolean | Observable<boolean>) {
    this.setProperty('active', active);
  }

  @Input() set badgeAmount(badgeAmount: number | Observable<number>) {
    this.setProperty('badgeAmount', badgeAmount);
  }

  @Output() btnClick = new EventEmitter<never>();
  @Output() btnLongpress = new EventEmitter<never>();

  readonly icon$ = this.select(
    selectSlice(['icon']),
    map(({ icon }) => icon),
  );

  readonly iconActive$ = this.select(
    selectSlice(['iconActive']),
    map(({ iconActive }) => iconActive),
  );

  readonly btnClass = tss({
    ...spread,
    padding: padding(11.5, 0, 11.5, 0),
    ...flexCenterHorizontal,
  });

  readonly iconClass = tss({
    position: 'absolute',
    top: px(0),
    left: px(0),
  });

  readonly iconContainerClass$ = this.select(
    selectSlice(['badgeAmount']),
    map(({ badgeAmount: amount }) => {
      return tss({
        ...establishStackingContext,
        ...exactSize(26),
        position: 'relative',
        '::before': {
          zIndex: 1,
          color: ThemeColorVar.lightest,
          backgroundColor: ThemeColorVar.primary,
          display: amount > 0 ? 'block' : 'none',
          borderRadius: px(16 / 2),
          ...getFontStyle({
            fontSize: 12,
            lineHeight: 12,
            fontWeight: fontWeight.regular,
          }),
          ...exactHeight(16),
          ...(amount > 9 ? {} : { ...exactWidth(16) }),
          boxShadow: boxShadow(2.25, ThemeColorVar.lightest),
          padding: padding(2, 4.5, 2, 4.5),
          position: 'absolute',
          top: px(-5),
          left: px(15),
          // eslint-disable-next-line sonarjs/no-nested-template-literals
          content: `"${amount > MAX_AMOUNT_BUBBLE ? `${MAX_AMOUNT_BUBBLE}+` : amount}"`,
        },
      });
    }),
  );

  ngAfterViewInit() {
    // if (this.iconContainerRef) {
    //   this.animation = this.animationCtrl
    //     .create('navigation-bar-button-wiggle-animation')
    //     .addElement(this.iconContainerRef.nativeElement)
    //     .keyframes([
    //       { offset: 0, transform: 'scale(1)' },
    //       { offset: 0.5, transform: 'scale(0.8)' },
    //       { offset: 0.9, transform: 'scale(1.02)' },
    //       { offset: 1, transform: 'scale(1)' },
    //     ])
    //     .duration(500);
    // }

    const btnEl = this.btnRef?.nativeElement;
    if (btnEl) {
      this.hold(fromEvent(btnEl, 'touchstart'), () => {
        // this.animation?.progressStart(true, 0).play({ sync: true });
      });
    }
  }
}
