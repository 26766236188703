import gql from 'graphql-tag';
import { BlabItemFocusEntryFragment } from './blab-item-focus-entry.fragment';
import { ChecklistFocusEntryFragment } from './checklist-focus-entry.fragment';
import { ReminderFocusEntryFragment } from './reminder-focus-entry.fragment';

export const FocusEntryUnionTypeFragment = gql`
  fragment FocusEntryUnionTypeFragment on FocusEntryUnionType {
    ... on ReminderFocusEntryDto {
      ...ReminderFocusEntryFragment
    }
    ... on ChecklistFocusEntryDto {
      ...ChecklistFocusEntryFragment
    }
    ... on BlabItemFocusEntryDto {
      ...BlabItemFocusEntryFragment
    }
  }
  ${ReminderFocusEntryFragment}
  ${BlabItemFocusEntryFragment}
  ${ChecklistFocusEntryFragment}
`;
