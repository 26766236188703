import { NgModule } from '@angular/core';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { FooterLinkComponent } from './footer-link.component';
import { FullWidthLinkComponent } from './full-width-link.component';
import { HeaderLinkComponent } from './header-link.component';
import { LinkComponent } from './link.component';
import { SingleLineHeaderLinkComponent } from './single-line-header-link.component';
import { SingleLineLinkComponent } from './single-line-link.component';

const components = [
  LinkComponent,
  SingleLineLinkComponent,
  FullWidthLinkComponent,
  HeaderLinkComponent,
  FooterLinkComponent,
  SingleLineHeaderLinkComponent,
];

@NgModule({
  imports: [ComponentCommonModule],
  declarations: components,
  exports: components,
})
export class LinkComponentModule {}
