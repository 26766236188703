import { BlabItemActivitySubscriptionUpdate, BlabItemActivityUnionType } from '@t5s/shared/gql';
import { isBlabItemActivityDeletedDto } from './blab-item-activity-deleted.type-guard';

export function updateIsBlabItemActivityUnionType(
  candidate: BlabItemActivitySubscriptionUpdate,
): candidate is BlabItemActivityUnionType {
  if (isBlabItemActivityDeletedDto(candidate)) {
    return false;
  }

  return true;
}
