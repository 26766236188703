import gql from 'graphql-tag';
import { ConvertSingleTextFlimToMultiTextFlimResultDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../../flim-def';

export const ConvertSingleTextFlimToMultiTextFlimResultDtoFragment = gql`
  fragment ConvertSingleTextFlimToMultiTextFlimResultDtoFragment on ConvertSingleTextFlimToMultiTextFlimResultDto {
    ...ConvertSingleTextFlimToMultiTextFlimResultDtoNoNesting

    flimDef {
      ...FullFlimDef
    }
  }
  ${ConvertSingleTextFlimToMultiTextFlimResultDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
