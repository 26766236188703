import gql from 'graphql-tag';
import {
  CalculationSplitByDtoNoNestingFragment,
  CalculationSplitBySectionDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const CalculationSplitByDtoFragment = gql`
  fragment CalculationSplitByDto on CalculationSplitByDto {
    ...CalculationSplitByDtoNoNesting
    sections {
      ...CalculationSplitBySectionDtoNoNesting
    }
  }
  ${CalculationSplitByDtoNoNestingFragment}
  ${CalculationSplitBySectionDtoNoNestingFragment}
`;
