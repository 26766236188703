type Unit = 'bytes' | 'kB' | 'MB' | 'GB' | 'TB' | 'PB';
type UnitPrecisionMap = {
  [u in Unit]: number;
};

const DefaultPrecisionMap: UnitPrecisionMap = {
  bytes: 0,
  kB: 0,
  MB: 1,
  GB: 1,
  TB: 2,
  PB: 2,
} as const;

const units: Unit[] = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];

export function transformFilesize(input: number | { size: number }, precision?: number): string {
  let bytes: number;
  if (typeof input === 'number') {
    bytes = input;
  } else {
    bytes = input.size;
  }

  return _transform(bytes, precision);
}

function _transform(bytes: number = 0, precision: number | UnitPrecisionMap = DefaultPrecisionMap): string {
  if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';

  let unitIndex = 0;

  while (bytes >= 1000) {
    bytes /= 1000;
    unitIndex++;
  }

  const unit = units[unitIndex];

  if (typeof precision === 'number') {
    return `${bytes.toFixed(+precision)} ${unit}`;
  }
  return `${bytes.toFixed(precision[unit])} ${unit}`;
}
