import { NgModule } from '@angular/core';
import { PlatformObservable } from '@t5s/mobile-client/provider-token/device';
import { SuppressLongpressGesture } from 'capacitor-suppress-longpress-gesture';
import { defer, from } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';

@NgModule({})
export class DisableLongpressIosBusinessLogicModule {
  constructor(private readonly platform$: PlatformObservable) {
    this.platform$
      .pipe(
        first(),
        filter(({ platform }) => platform === 'ios'),
        switchMap(() => defer(() => from(SuppressLongpressGesture.activateService()))),
      )
      .subscribe();
  }
}
