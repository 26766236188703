import { Injectable } from '@angular/core';
import { Clipboard, ReadResult, WriteOptions } from '@capacitor/clipboard';
import { defer, from, Observable } from 'rxjs';

@Injectable()
export class ClipboardService {
  read(): Observable<ReadResult> {
    return defer(() => from(Clipboard.read()));
  }

  write(opts: WriteOptions): Observable<void> {
    return defer(() => from(Clipboard.write(opts)));
  }
}
