import gql from 'graphql-tag';
import { MultiRelationFlimValDtoNoNestingFragment } from '../../../../generated/graphql-fragments';
import { BlabItemReferenceFragment } from './blab-item-reference.fragment';

export const MultiRelationFlimValFragment = gql`
  fragment BlabItemsOverviewMultiRelationFlimVal on MultiRelationFlimValDto {
    ...MultiRelationFlimValDtoNoNesting
    blabItemReferences {
      ...BlabItemsOverviewBlabItemReference
    }
  }
  ${MultiRelationFlimValDtoNoNestingFragment}
  ${BlabItemReferenceFragment}
`;
