import gql from 'graphql-tag';
import { ActiveUserSingleUserSplitBySectionDtoNoNestingFragment } from '../../../../generated/graphql-fragments';

export const ActiveUserSingleUserSplitBySectionDtoFragment = gql`
  fragment ActiveUserSingleUserSplitBySectionDto on ActiveUserSingleUserSplitBySectionDto {
    ...ActiveUserSingleUserSplitBySectionDtoNoNesting
    user {
      id
      primaryName
      profilePicture {
        id
        small
      }
    }
  }
  ${ActiveUserSingleUserSplitBySectionDtoNoNestingFragment}
`;
