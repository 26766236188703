/* eslint-disable @typescript-eslint/no-floating-promises */
import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { AnimationController } from '@ionic/angular';
import { Animation } from '@ionic/core';
import { px } from '@t5s/client/ui/style/common';
import { PlatformObservable } from '@t5s/mobile-client/provider-token/device';
import { KeyboardStateObservable } from '@t5s/mobile-client/provider-token/keyboard';
import {
  KEYBOARD_ANDROID_EASING_FUNC_HIDE,
  KEYBOARD_ANDROID_EASING_FUNC_SHOW,
  KEYBOARD_ANDROID_SPEED_HIDE_MS,
  KEYBOARD_ANDROID_SPEED_SHOW_MS,
  KEYBOARD_IOS_EASING_FUNC_HIDE,
  KEYBOARD_IOS_EASING_FUNC_SHOW,
  KEYBOARD_IOS_SPEED_HIDE_MS,
  KEYBOARD_IOS_SPEED_SHOW_MS,
} from '@t5s/mobile-client/readonly-constant/keyboard';
import { RxComponent } from '@t5s/mobile-client/ui/component/common';
import { KeyboardPosition, KeyboardState } from '@t5s/mobile-client/value-object/keyboard';
import { Observable } from 'rxjs';

interface KeyboardAnimatedSpacerState extends KeyboardState {
  platform: 'android' | 'ios' | 'web';
  bottomDistance?: number;
}

@Component({
  selector: 't5s-keyboard-animated-spacer',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyboardAnimatedSpacerComponent extends RxComponent<KeyboardAnimatedSpacerState> {
  private showAnimation?: Animation;
  private hideAnimation?: Animation;

  constructor(
    private readonly elRef: ElementRef<HTMLElement>,
    private readonly animationCtrl: AnimationController,
    keyboardState$: KeyboardStateObservable,
    platform$: PlatformObservable,
  ) {
    super();
    this.connect(platform$);
    this.connect(keyboardState$);

    this.hold(this.state$, ({ keyboardPosition }) => {
      switch (keyboardPosition) {
        case KeyboardPosition.WILL_SHOW:
          this.playWillShowAnimation();
          break;

        case KeyboardPosition.DID_SHOW:
          this.setSpacerToKeyboardHeight();
          break;

        case KeyboardPosition.WILL_HIDE:
          this.playWillHideAnimation();
          break;

        case KeyboardPosition.DID_HIDE:
          this.setSpacerToKeyboardHeight();
          break;
      }
    });
  }

  @Input() set bottomDistance(bottomDistance: number | Observable<number>) {
    this.setProperty('bottomDistance', bottomDistance);
  }

  private setSpacerToKeyboardHeight() {
    this.destroyObjects();
    const { keyboardPosition, keyboardHeight, bottomDistance } = this.get();
    this.elRef.nativeElement.style.setProperty(
      'height',
      px(keyboardPosition === KeyboardPosition.DID_SHOW ? keyboardHeight - (bottomDistance ?? 0) : 0),
    );
  }

  private playWillShowAnimation() {
    const { platform } = this.get();
    if (platform === 'android') {
      this.playWillShowAnimationAndroid();
    } else {
      this.playWillShowAnimationIos();
    }
  }

  private playWillShowAnimationIos() {
    const { keyboardHeight, bottomDistance } = this.get();
    const height = keyboardHeight - (bottomDistance ?? 0);
    const delay = 0; // TODO: correctly calculate based on easing function
    const duration = KEYBOARD_IOS_SPEED_SHOW_MS - delay;

    this.showAnimation = this.animationCtrl
      .create('t5s-keyboard-spacer-show-ios')
      .addElement(this.elRef.nativeElement)
      .duration(duration)
      .delay(delay)
      .fromTo('height', px(0), px(height))
      .easing(KEYBOARD_IOS_EASING_FUNC_SHOW);

    this.showAnimation.progressStart().play();
  }

  private playWillShowAnimationAndroid() {
    const { keyboardHeight, bottomDistance } = this.get();
    const height = keyboardHeight - (bottomDistance ?? 0);
    const delay = 0; // TODO: correctly calculate based on easing function
    const duration = KEYBOARD_ANDROID_SPEED_SHOW_MS - delay;

    this.showAnimation = this.animationCtrl
      .create('t5s-keyboard-spacer-show-android')
      .addElement(this.elRef.nativeElement)
      .duration(duration)
      .delay(delay)
      .fromTo('height', px(0), px(height))
      .easing(KEYBOARD_ANDROID_EASING_FUNC_SHOW);

    this.showAnimation.progressStart().play();
  }

  private playWillHideAnimation() {
    const { platform } = this.get();
    if (platform === 'android') {
      this.playWillHideAnimationAndroid();
    } else if (platform === 'ios') {
      this.playWillHideAnimationIos();
    }
  }

  private playWillHideAnimationIos() {
    this.hideAnimation = this.animationCtrl
      .create('t5s-keyboard-spacer-hide-ios')
      .addElement(this.elRef.nativeElement)
      .duration(KEYBOARD_IOS_SPEED_HIDE_MS)
      .fromTo('height', px(this.elRef.nativeElement.clientHeight), px(0))
      .easing(KEYBOARD_IOS_EASING_FUNC_HIDE);

    this.hideAnimation.progressStart().play();
  }

  private playWillHideAnimationAndroid() {
    this.hideAnimation = this.animationCtrl
      .create('t5s-keyboard-spacer-hide-android')
      .addElement(this.elRef.nativeElement)
      .duration(KEYBOARD_ANDROID_SPEED_HIDE_MS)
      .fromTo('height', px(this.elRef.nativeElement.clientHeight), px(0))
      .easing(KEYBOARD_ANDROID_EASING_FUNC_HIDE);

    this.hideAnimation.progressStart().play();
  }

  private destroyObjects() {
    this.showAnimation?.destroy();
    this.hideAnimation?.destroy();
  }
}
