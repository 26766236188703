import gql from 'graphql-tag';
import { UserProfilePreviewDtoFragment } from '../user-profile';
import { WorkspaceActivityDtoFragment } from './workspace-activity-dto.fragment';

export const WorkspaceUserAddedActivityFragment = gql`
  fragment WorkspaceUserAddedActivityFragment on WorkspaceUserAddedActivityDto {
    ...WorkspaceActivityDtoFragment
    targetUser {
      ...UserProfilePreviewDtoFragment
    }
    targetUserId
    role
  }
  ${WorkspaceActivityDtoFragment}
  ${UserProfilePreviewDtoFragment}
`;
