import gql from 'graphql-tag';
import {
  ReminderDtoNoNestingFragment,
  ReminderFocusEntryDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const ReminderFocusEntryFragment = gql`
  fragment ReminderFocusEntryFragment on ReminderFocusEntryDto {
    ...ReminderFocusEntryDtoNoNesting
    reminder {
      ...ReminderDtoNoNesting
      referencedBlabItem {
        id
        title
        blabDef {
          id
          icon
          iconv2
          itemIcon
          blabName
          itemName
        }
      }
    }
  }
  ${ReminderFocusEntryDtoNoNestingFragment}
  ${ReminderDtoNoNestingFragment}
`;
