import gql from 'graphql-tag';
import {
  WorkflowDefRevisionDtoNoNestingFragment,
  GlobalWorkflowRunDetailDtoNoNestingFragment,
  BlabDefPreviewDtoNoNestingFragment,
  WorkflowDefPreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { WorkflowRunLogEventUnionFragment } from './workflow-run-log-event-union.fragment';

export const GlobalWorkflowRunDetailDtoFragment = gql`
  fragment GlobalWorkflowRunDetailDtoFragment on GlobalWorkflowRunDetailDto {
    ...GlobalWorkflowRunDetailDtoNoNesting

    workflowDefRevision {
      ...WorkflowDefRevisionDtoNoNesting
    }

    blabDef {
      ...BlabDefPreviewDtoNoNesting
    }
    workflowDef {
      ...WorkflowDefPreviewDtoNoNesting
    }

    logs {
      ...WorkflowRunLogEventUnionFragment
    }
  }

  ${GlobalWorkflowRunDetailDtoNoNestingFragment}
  ${WorkflowRunLogEventUnionFragment}
  ${WorkflowDefRevisionDtoNoNestingFragment}
  ${BlabDefPreviewDtoNoNestingFragment}
  ${WorkflowDefPreviewDtoNoNestingFragment}
`;
