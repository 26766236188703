import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

const TOUCH_MOVE_THRESHOLD_PX_X = 14;
const TOUCH_MOVE_THRESHOLD_PX_Y = 14;

interface FromTouchEventOpts {
  touches: TouchList;
  thresholdX?: number;
  thresholdY?: number;
}

function touchEventsWithinThreshold({
  touches,
  changedTouches,
  ...opts
}: {
  touches: TouchList;
  changedTouches: TouchList;
  thresholdX?: number;
  thresholdY?: number;
}): boolean {
  const thresholdX = opts.thresholdX ?? TOUCH_MOVE_THRESHOLD_PX_X;
  const thresholdY = opts.thresholdY ?? TOUCH_MOVE_THRESHOLD_PX_Y;

  const startTouch = touches[0];
  const endTouch = changedTouches[0];

  const startX = startTouch?.clientX;
  const startY = startTouch?.clientY;

  const endX = endTouch?.clientX;
  const endY = endTouch?.clientY;

  if (startX === undefined || startY === undefined || endX === undefined || endY === undefined) {
    return false;
  }

  if (Math.abs(startX - endX) >= thresholdX) {
    return false;
  }

  if (Math.abs(startY - endY) >= thresholdY) {
    return false;
  }

  return true;
}

export function fromTouchmoveEventWithinhreshold(el: Element, { touches, ...opts }: FromTouchEventOpts) {
  return fromEvent<TouchEvent>(el, 'touchmove', { passive: true }).pipe(
    filter(({ changedTouches }) => touchEventsWithinThreshold({ touches, changedTouches, ...opts })),
  );
}

export function fromTouchmoveEventOutsideThreshold(el: Element, { touches, ...opts }: FromTouchEventOpts) {
  return fromEvent<TouchEvent>(el, 'touchmove', { passive: true }).pipe(
    filter(({ changedTouches }) => !touchEventsWithinThreshold({ touches, changedTouches, ...opts })),
  );
}

export function fromTouchendEventWithinThreshold(el: Element, { touches, ...opts }: FromTouchEventOpts) {
  return fromEvent<TouchEvent>(el, 'touchend', { passive: true }).pipe(
    filter(({ changedTouches }) => touchEventsWithinThreshold({ touches, changedTouches, ...opts })),
  );
}

export function fromTouchendEventOutsideThreshold(el: Element, { touches, ...opts }: FromTouchEventOpts) {
  return fromEvent<TouchEvent>(el, 'touchend', { passive: true }).pipe(
    filter(({ changedTouches }) => !touchEventsWithinThreshold({ touches, changedTouches, ...opts })),
  );
}
