import {
  SubscribeToExportBlabDefDataJobFailureEvent,
  SubscribeToExportBlabDefDataJobSuccessEvent,
} from '@t5s/shared/gql';
import { isDto } from './dto.type-guard';

export function isSubscribeToExportBlabDefDataJobSuccessEvent(
  candidate?: any,
): candidate is SubscribeToExportBlabDefDataJobSuccessEvent {
  return isDto<SubscribeToExportBlabDefDataJobSuccessEvent>(candidate, 'SubscribeToExportBlabDefDataJobSuccessEvent');
}

export function isSubscribeToExportBlabDefDataJobFailureEvent(
  candidate?: any,
): candidate is SubscribeToExportBlabDefDataJobFailureEvent {
  return isDto<SubscribeToExportBlabDefDataJobFailureEvent>(candidate, 'SubscribeToExportBlabDefDataJobFailureEvent');
}
