import { Injectable } from '@angular/core';
import { Share, ShareOptions, ShareResult } from '@capacitor/share';
import { from, Observable } from 'rxjs';

@Injectable()
export class ShareService {
  /**
   * Options:
   * * Each platform uses a different set of fields, but you should supply them all.
   *
   * Returns:
   * * activityType - Identifier of the app that received the share action. Can be an empty string in some cases. On web it will be undefined.
   */
  share(options: ShareOptions): Observable<ShareResult> {
    return from(Share.share(options));
  }
}
