import gql from 'graphql-tag';
import {
  CalculationFlimStatResultDtoNoNestingFragment,
  ChecklistFlimStatResultDtoNoNestingFragment,
  CreatedAtFlimStatResultDtoNoNestingFragment,
  CreatedByFlimStatResultDtoNoNestingFragment,
  LastModifiedAtFlimStatResultDtoNoNestingFragment,
  LastModifiedByFlimStatResultDtoNoNestingFragment,
  MultiAttachmentFlimStatResultDtoNoNestingFragment,
  MultiCategoryFlimStatResultDtoNoNestingFragment,
  MultiEmailFlimStatResultDtoNoNestingFragment,
  MultiImageFlimStatResultDtoNoNestingFragment,
  MultiLinkFlimStatResultDtoNoNestingFragment,
  MultiPhoneFlimStatResultDtoNoNestingFragment,
  MultiRelationFlimStatResultDtoNoNestingFragment,
  MultiTextFlimStatResultDtoNoNestingFragment,
  MultiUserFlimStatResultDtoNoNestingFragment,
  NumberFlimStatResultDtoNoNestingFragment,
  RangeDateFlimStatResultDtoNoNestingFragment,
  SingleAttachmentFlimStatResultDtoNoNestingFragment,
  SingleCategoryFlimStatResultDtoNoNestingFragment,
  SingleDateFlimStatResultDtoNoNestingFragment,
  SingleLocationFlimStatResultDtoNoNestingFragment,
  SingleRelationFlimStatResultDtoNoNestingFragment,
  SingleTextFlimStatResultDtoNoNestingFragment,
  SingleUserFlimStatResultDtoNoNestingFragment,
  StatusFlimStatResultDtoNoNestingFragment,
  UniqueIdFlimStatResultDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const FlimStatResultUnionTypeFragment = gql`
  fragment FlimStatResultUnionType on FlimStatResultUnionType {
    ... on SingleTextFlimStatResultDto {
      ...SingleTextFlimStatResultDtoNoNesting
    }
    ... on MultiTextFlimStatResultDto {
      ...MultiTextFlimStatResultDtoNoNesting
    }
    ... on SingleCategoryFlimStatResultDto {
      ...SingleCategoryFlimStatResultDtoNoNesting
    }
    ... on MultiCategoryFlimStatResultDto {
      ...MultiCategoryFlimStatResultDtoNoNesting
    }
    ... on SingleUserFlimStatResultDto {
      ...SingleUserFlimStatResultDtoNoNesting
    }
    ... on MultiUserFlimStatResultDto {
      ...MultiUserFlimStatResultDtoNoNesting
    }
    ... on SingleAttachmentFlimStatResultDto {
      ...SingleAttachmentFlimStatResultDtoNoNesting
    }
    ... on MultiAttachmentFlimStatResultDto {
      ...MultiAttachmentFlimStatResultDtoNoNesting
    }
    ... on SingleRelationFlimStatResultDto {
      ...SingleRelationFlimStatResultDtoNoNesting
    }
    ... on MultiRelationFlimStatResultDto {
      ...MultiRelationFlimStatResultDtoNoNesting
    }
    ... on SingleDateFlimStatResultDto {
      ...SingleDateFlimStatResultDtoNoNesting
    }
    ... on RangeDateFlimStatResultDto {
      ...RangeDateFlimStatResultDtoNoNesting
    }
    ... on CreatedAtFlimStatResultDto {
      ...CreatedAtFlimStatResultDtoNoNesting
    }
    ... on CreatedByFlimStatResultDto {
      ...CreatedByFlimStatResultDtoNoNesting
    }
    ... on LastModifiedAtFlimStatResultDto {
      ...LastModifiedAtFlimStatResultDtoNoNesting
    }
    ... on LastModifiedByFlimStatResultDto {
      ...LastModifiedByFlimStatResultDtoNoNesting
    }
    ... on NumberFlimStatResultDto {
      ...NumberFlimStatResultDtoNoNesting
    }
    ... on ChecklistFlimStatResultDto {
      ...ChecklistFlimStatResultDtoNoNesting
    }
    ... on MultiLinkFlimStatResultDto {
      ...MultiLinkFlimStatResultDtoNoNesting
    }
    ... on StatusFlimStatResultDto {
      ...StatusFlimStatResultDtoNoNesting
    }
    ... on CalculationFlimStatResultDto {
      ...CalculationFlimStatResultDtoNoNesting
    }
    ... on MultiEmailFlimStatResultDto {
      ...MultiEmailFlimStatResultDtoNoNesting
    }
    ... on MultiPhoneFlimStatResultDto {
      ...MultiPhoneFlimStatResultDtoNoNesting
    }
    ... on MultiImageFlimStatResultDto {
      ...MultiImageFlimStatResultDtoNoNesting
    }
    ... on SingleLocationFlimStatResultDto {
      ...SingleLocationFlimStatResultDtoNoNesting
    }
    ... on UniqueIdFlimStatResultDto {
      ...UniqueIdFlimStatResultDtoNoNesting
    }
  }
  ${SingleTextFlimStatResultDtoNoNestingFragment}
  ${MultiTextFlimStatResultDtoNoNestingFragment}
  ${SingleCategoryFlimStatResultDtoNoNestingFragment}
  ${MultiCategoryFlimStatResultDtoNoNestingFragment}
  ${SingleUserFlimStatResultDtoNoNestingFragment}
  ${MultiUserFlimStatResultDtoNoNestingFragment}
  ${SingleAttachmentFlimStatResultDtoNoNestingFragment}
  ${MultiAttachmentFlimStatResultDtoNoNestingFragment}
  ${SingleRelationFlimStatResultDtoNoNestingFragment}
  ${MultiRelationFlimStatResultDtoNoNestingFragment}
  ${ChecklistFlimStatResultDtoNoNestingFragment}
  ${CreatedAtFlimStatResultDtoNoNestingFragment}
  ${CreatedByFlimStatResultDtoNoNestingFragment}
  ${LastModifiedAtFlimStatResultDtoNoNestingFragment}
  ${LastModifiedByFlimStatResultDtoNoNestingFragment}
  ${StatusFlimStatResultDtoNoNestingFragment}
  ${SingleDateFlimStatResultDtoNoNestingFragment}
  ${RangeDateFlimStatResultDtoNoNestingFragment}
  ${NumberFlimStatResultDtoNoNestingFragment}
  ${MultiLinkFlimStatResultDtoNoNestingFragment}
  ${MultiEmailFlimStatResultDtoNoNestingFragment}
  ${MultiPhoneFlimStatResultDtoNoNestingFragment}
  ${MultiImageFlimStatResultDtoNoNestingFragment}
  ${SingleLocationFlimStatResultDtoNoNestingFragment}
  ${UniqueIdFlimStatResultDtoNoNestingFragment}
  ${CalculationFlimStatResultDtoNoNestingFragment}
`;
