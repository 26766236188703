import gql from 'graphql-tag';
import {
  BlabDefPreviewDtoNoNestingFragment,
  BlabItemPreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const BlabItemSearchResultFragment = gql`
  fragment BlabItemSearchResult on BlabItemSearchResultDto {
    blabItem {
      ...BlabItemPreviewDtoNoNesting
      blabDef {
        ...BlabDefPreviewDtoNoNesting
        workspace {
          id
          name
          slug
        }
      }
    }
  }
  ${BlabItemPreviewDtoNoNestingFragment}
  ${BlabDefPreviewDtoNoNestingFragment}
`;
