import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ComponentStyle, FontSet, getFontStyle, px } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent } from '@t5s/mobile-client/ui/component/common';
import { font, ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface DelimiterState {
  font: FontSet;
  lineHeight?: number;
  fgColor: string;
  padding: number;
}

@Component({
  selector: 't5s-delimiter',
  template: `<div [class]="delimiterClass$ | push">·</div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostFitContent],
})
export class DelimiterComponent extends RxComponent<DelimiterState> {
  constructor() {
    super();
    this.set({
      padding: 4,
      fgColor: ThemeColorVar.darker,
      font: font.regular14px,
      lineHeight: font.regular14px.lineHeight,
    });
  }

  @Input() set font(font: FontSet | Observable<FontSet>) {
    this.setProperty('font', font);
  }

  @Input() set lineHeight(lineHeight: number | Observable<number>) {
    this.setProperty('lineHeight', lineHeight);
  }

  @Input() set fgColor(fgColor: string | Observable<string>) {
    this.setProperty('fgColor', fgColor);
  }

  @Input() set padding(padding: number | Observable<number>) {
    this.setProperty('padding', padding);
  }

  readonly delimiterClass$ = this.state$.pipe(
    map(({ font, lineHeight, fgColor, padding }) =>
      tss({
        color: fgColor,
        ...getFontStyle({
          ...font,
          lineHeight: lineHeight ?? font.lineHeight,
        }),
        marginLeft: px(padding),
        marginRight: px(padding),
      }),
    ),
  );
}
