import gql from 'graphql-tag';
import { UserActivityUnionTypeFragment } from './user-activity-union-type.fragment';

export const UserActivityConnectionFragment = gql`
  fragment UserActivityConnectionFragment on UserActivityConnection {
    edges {
      cursor
      node {
        ...UserActivityUnionTypeFragment
      }
    }
  }
  ${UserActivityUnionTypeFragment}
`;
