import gql from 'graphql-tag';
import { PageInfoFragment } from '../core/page-info.fragment';
import { BlabItemActivityReplyDtoFragment } from './blab-item-activity-reply-dto.fragment';

export const BlabItemActivityReplyConnectionFragment = gql`
  fragment BlabItemActivityReplyConnectionFragment on BlabItemActivityReplyConnection {
    edges {
      cursor
      node {
        ...BlabItemActivityReplyDtoFragment
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
  ${BlabItemActivityReplyDtoFragment}
  ${PageInfoFragment}
`;
