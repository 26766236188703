import gql from 'graphql-tag';
import {
  MultiCategoryFlimDefDtoNoNestingFragment,
  MultiCategoryOptionDefDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const MultiCategoryFlimDefDtoFragment = gql`
  fragment MultiCategoryFlimDefDtoFragment on MultiCategoryFlimDefDto {
    ...MultiCategoryFlimDefDtoNoNesting
    categoryOptionDefs {
      ...MultiCategoryOptionDefDtoNoNesting
    }
  }
  ${MultiCategoryFlimDefDtoNoNestingFragment}
  ${MultiCategoryOptionDefDtoNoNestingFragment}
`;
