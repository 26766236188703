import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, Output } from '@angular/core';
import { hasElementInPath } from '@t5s/client/util/element';
import { DeviceObservable } from '@t5s/mobile-client/provider-token/device';
import { fromEvent, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[t5sOutsideClick]',
  exportAs: 't5sOutsideClick',
})
export class OutsideClickDirective {
  constructor(@Inject(DOCUMENT) readonly _document: Document, readonly hostEl: ElementRef, device$: DeviceObservable) {
    this.t5sOutsideClick = device$.pipe(
      switchMap(({ isTouch }) =>
        fromEvent(_document, isTouch ? 'touchstart' : 'mousedown', { passive: true }).pipe(
          filter((event) => !hasElementInPath(event, hostEl.nativeElement)),
        ),
      ),
    );
  }

  @Output() t5sOutsideClick: Observable<unknown>;
}
