import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { PushNotificationActions } from '@t5s/mobile-client/business-logic/push-notification';
import { GqlNotificationService } from '@t5s/shared/gql-services';
import { debounceTime, filter, map, mergeMap, takeUntil } from 'rxjs/operators';
import { AllNotificationsActions } from './all';
import { DirectNotificationsActions } from './direct';
import { NotificationActions } from './notification.actions';

@Injectable()
export class NotificationEffects {
  constructor(private readonly actions$: Actions, private readonly notificationService: GqlNotificationService) {}

  readonly loadSyncAllNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.loadSyncNotifications),
      map(AllNotificationsActions.loadSyncNotifications),
    ),
  );

  readonly loadSyncDirectNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.loadSyncNotifications),
      map(DirectNotificationsActions.loadSyncNotifications),
    ),
  );

  readonly remotePushReceivedRefreshNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PushNotificationActions.remotePushNotificationReceivedAppInBackground),
      debounceTime(1000),
      map(NotificationActions.loadSyncNotifications),
    ),
  );

  readonly markNotificationAsUnread = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.markNotificationAsUnread),
      mergeMap(({ notification }) => {
        const { id: notificationId } = notification;

        const markAsRead$ = this.actions$.pipe(
          ofType(NotificationActions.markNotificationAsRead),
          filter(({ notification }) => notification.id === notificationId),
        );

        return this.notificationService.markNotificationAsUnread({ notificationId }).pipe(
          map(() => NotificationActions.markNotificationAsUnreadSuccess({ notificationId })),
          catchErrorDispatch(NotificationActions.markNotificationAsUnreadError, { notificationId }),
          takeUntil(markAsRead$),
        );
      }),
    ),
  );

  readonly markNotificationAsRead = createEffect(() =>
    this.actions$.pipe(
      ofType(NotificationActions.markNotificationAsRead),
      mergeMap(({ notification }) => {
        const { id: notificationId } = notification;
        const markAsUnread$ = this.actions$.pipe(
          ofType(NotificationActions.markNotificationAsUnread),
          filter(({ notification }) => notification.id === notificationId),
        );

        return this.notificationService.markNotificationAsRead({ notificationId }).pipe(
          map(() => NotificationActions.markNotificationAsReadSuccess({ notificationId })),
          catchErrorDispatch(NotificationActions.markNotificationAsReadError, { notificationId }),
          takeUntil(markAsUnread$),
        );
      }),
    ),
  );
}
