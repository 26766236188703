import gql from 'graphql-tag';
import {
  ChatDialogDtoNoNestingFragment,
  ChatGroupDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
  UserProfileDtoNoNestingFragment,
  UserProfilePreviewDtoNoNestingFragment,
  UserStatusDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const ChatConversationFragment = gql`
  fragment Conversation on ConversationDto {
    conversationId
    numUnreadActivities
    userReadUntil
    ... on ChatGroupDto {
      ...ChatGroupDtoNoNesting
      lastChatActivity {
        id
        type
      }
      thumbnail {
        ...ThumbnailDtoNoNesting
      }
    }
    ... on ChatDialogDto {
      ...ChatDialogDtoNoNesting
      user {
        ...UserProfileDtoNoNesting
        profilePicture {
          ...ThumbnailDtoNoNesting
        }
        status {
          ...UserStatusDtoNoNesting
        }
      }
    }
    lastChatActivity {
      id
      type
      createdAt
      ... on ChatMessageActivityDto {
        content
        unformatted
        user {
          ...UserProfilePreviewDtoNoNesting
        }
        attachments {
          id
        }
        linkPreview {
          id
        }
      }
      ... on ChatGroupLeaveActivityDto {
        user {
          ...UserProfilePreviewDtoNoNesting
        }
      }
      ... on ChatGroupJoinActivityDto {
        user {
          ...UserProfilePreviewDtoNoNesting
        }
        targetUserId
        targetUser {
          ...UserProfilePreviewDtoNoNesting
        }
      }
      ... on ChatGroupNameChangeActivityDto {
        content
        user {
          ...UserProfilePreviewDtoNoNesting
        }
      }
      ... on ChatGroupThumbnailChangeActivityDto {
        user {
          ...UserProfilePreviewDtoNoNesting
        }
      }
      ... on ChatVideoCallActivityDto {
        user {
          ...UserProfilePreviewDtoNoNesting
        }
      }
    }
  }
  ${ChatGroupDtoNoNestingFragment}
  ${ChatDialogDtoNoNestingFragment}
  ${UserProfileDtoNoNestingFragment}
  ${UserProfilePreviewDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
  ${UserStatusDtoNoNestingFragment}
`;
