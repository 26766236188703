import gql from 'graphql-tag';
import { SingleAttachmentFlimValDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FlimValAttachmentFragment } from '../../flim-val-attachment';

export const SingleAttachmentFlimValFragment = gql`
  fragment SingleAttachmentFlimVal on SingleAttachmentFlimValDto {
    ...SingleAttachmentFlimValDtoNoNesting
    attachment {
      ...FlimValAttachmentFragment
    }
  }
  ${SingleAttachmentFlimValDtoNoNestingFragment}
  ${FlimValAttachmentFragment}
`;
