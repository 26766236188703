import { Filetype, FiletypeInfo, filetypeInfos } from '@t5s/client/readonly-constant/file';
import { getExtensionFromFilename } from './file-extension.utils';

export function getFiletypeFromFile(file?: File): Filetype {
  return getFiletypeFromFilename(file?.name);
}

export function getFiletypeFromFilename(filename?: string): Filetype {
  const extension = getExtensionFromFilename(filename);
  const fileTypeInfo = extension ? filetypeInfos.find((info) => info.extensions.includes(extension)) : undefined;
  const fileType = fileTypeInfo?.type;
  return fileType ?? Filetype.FALLBACK;
}

export function getFiletypeFromExtension(extension?: string): Filetype {
  const fileTypeInfo = extension ? filetypeInfos.find((info) => info.extensions.includes(extension)) : undefined;
  const fileType = fileTypeInfo?.type;
  return fileType ?? Filetype.FALLBACK;
}

export function getFiletypeInfoFromFile(file?: File): FiletypeInfo {
  const filetype = getFiletypeFromFile(file);
  return filetypeInfos.find((info) => info.type === filetype) as FiletypeInfo;
}

export function getFiletypeInfoFromExtension(extension?: string): FiletypeInfo {
  const filetype = getFiletypeFromExtension(extension);
  return filetypeInfos.find((info) => info.type === filetype) as FiletypeInfo;
}

export function isViewableFiletype(filetype: Filetype): boolean {
  return [Filetype.IMAGE, Filetype.VIDEO, Filetype.PDF].includes(filetype);
}
