import { NgModule } from '@angular/core';
import { ApplicationStateObservable } from '@t5s/mobile-client/provider-token/state';
import { ApplicationStateService, ServiceApplicationStateModule } from '@t5s/mobile-client/service/application-state';

@NgModule({
  imports: [ServiceApplicationStateModule],
  providers: [
    {
      provide: ApplicationStateObservable,
      useFactory: (service: ApplicationStateService): ApplicationStateObservable => service.appState$,
      deps: [ApplicationStateService],
    },
  ],
})
export class ApplicationStateProviderModule {}
