import gql from 'graphql-tag';
import { UserProfilePreviewDtoFragment } from '../user-profile/user-profile-preview-dto.fragment';
import { BlabItemActivityDtoFragment } from './blab-item-activity-dto.fragment';

export const BlabItemInvitationCreatedActivityFragment = gql`
  fragment BlabItemInvitationCreatedActivityFragment on BlabItemInvitationCreatedActivityDto {
    ...BlabItemActivityDtoFragment

    targetUserId
    targetUser {
      ...UserProfilePreviewDtoFragment
    }
    invitationText
  }

  ${BlabItemActivityDtoFragment}
  ${UserProfilePreviewDtoFragment}
`;
