import gql from 'graphql-tag';
import { BlabItemPreviewDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const MinimalIncomingRelationFlimDefPreviewDtoFragment = gql`
  fragment IncomingRelationFlimDefPreviewDtoFragment on IncomingRelationFlimDefPreviewDto {
    referencingFlimDef {
      id
      name
      type
      blabDefId
      blabDef {
        id
        slug
        blabName
        itemName
        icon
        iconv2
        itemIcon
        workspace {
          id
          slug
        }
      }
    }
    numReferencingBlabItems
    referencingBlabItems {
      edges {
        cursor
        node {
          ...BlabItemPreviewDtoNoNesting
          blabDef {
            id
            blabName
            itemName
            icon
            iconv2
            itemIcon
            workspace {
              id
              name
            }
          }
        }
      }
    }
  }
  ${BlabItemPreviewDtoNoNestingFragment}
`;

export const MinimalIncomingRelationFlimDefPreviewDtoNoBlabItemsFragment = gql`
  fragment MinimalIncomingRelationFlimDefPreviewDtoNoBlabItemsFragment on IncomingRelationFlimDefPreviewDto {
    referencingFlimDef {
      id
      name
      type
      blabDefId
      blabDef {
        id
        slug
        blabName
        itemName
        icon
        iconv2
        itemIcon
        workspace {
          id
          slug
        }
      }
    }
    numReferencingBlabItems
  }
`;
