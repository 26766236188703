import gql from 'graphql-tag';
import { UserProfilePreviewDtoFragment } from '../user-profile/user-profile-preview-dto.fragment';
import { BlabDefActivityDtoFragment } from './blab-def-activity-dto.fragment';

export const BlabDefInvitationCreatedActivityFragment = gql`
  fragment BlabDefInvitationCreatedActivityFragment on BlabDefInvitationCreatedActivityDto {
    ...BlabDefActivityDtoFragment

    targetUserId
    targetUser {
      ...UserProfilePreviewDtoFragment
    }
    invitationText
  }

  ${BlabDefActivityDtoFragment}
  ${UserProfilePreviewDtoFragment}
`;
