import { GetOrganizationProfileBySlugResult } from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import { OrganizationProfileFragment } from '../../fragments/organization';
import { getOrganizationProfileBySlug } from '../../generated/graphql-queries';
import { GqlService } from '../../gql-service';

export class GqlOrganizationProfileService extends GqlService {
  getOrganizationProfileBySlug(slug: string): Observable<GetOrganizationProfileBySlugResult> {
    return this.apollo.defaultQuery(getOrganizationProfileBySlug(slug, [OrganizationProfileFragment]));
  }
}
