import { BlabItemDisplayBlabItemDetail } from '@t5s/mobile-client/value-object/blab-item-display';
import { FlimValUnionType } from '@t5s/shared/gql';
import { replaceWhere } from '@t5s/shared/util/array';

export function getBlabItemAfterLiveUpdate(
  blabItem: BlabItemDisplayBlabItemDetail,
  flimValUpdate: FlimValUnionType,
): BlabItemDisplayBlabItemDetail {
  const { flimDefId } = flimValUpdate;

  let { flimVals } = blabItem;

  if (!flimVals) {
    return blabItem;
  }

  flimVals = replaceWhere(flimVals, (flimVal) => flimVal.flimDefId === flimDefId, flimValUpdate);

  return { ...blabItem, flimVals };
}
