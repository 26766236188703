import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { GlobalStyleService } from '@t5s/client/service/global-style';
import {
  getCssVariableForThemeColor,
  mobileClientDefaultTheme,
  ThemeColorObject,
} from '@t5s/mobile-client/ui/style/theme';
import { tap } from 'rxjs/operators';
import { ApplicationThemeActions } from './application-theme.actions';

@Injectable()
export class ApplicationThemeEffects implements OnInitEffects {
  constructor(private readonly actions$: Actions, private readonly globalStyleService: GlobalStyleService) {}

  readonly setApplicationThemeGlobalStyles$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ApplicationThemeActions.initApplicationTheme, ApplicationThemeActions.setApplicationTheme),
        tap(({ theme }) => {
          this.globalStyleService.setStyle('color', theme.color.darkest);

          for (const color of Object.entries(theme.color)) {
            const [key, value] = color;
            this.globalStyleService.setStyle(getCssVariableForThemeColor(key as keyof ThemeColorObject), value);
          }
        }),
      ),
    { dispatch: false },
  );

  ngrxOnInitEffects(): Action {
    // Initialize application theme on startup
    return ApplicationThemeActions.initApplicationTheme({ theme: mobileClientDefaultTheme });
  }
}
