import { createReducer, on } from '@ngrx/store';
import { BottomSheetPosition } from '@t5s/mobile-client/value-object/bottom-sheet';
import { ReactionDto } from '@t5s/shared/gql';
import { ReactionDetailsActions } from './reaction-details.actions';

export const reactionDetailsStateKey = 'reactionDetails';
export interface ReactionDetailsState {
  position: BottomSheetPosition;
  reactions?: ReactionDto[];
}

const initialState: ReactionDetailsState = {
  position: BottomSheetPosition.IS_BOTTOM,
};

export const reactionDetailsReducer = createReducer(
  initialState,

  on(ReactionDetailsActions.setPosition, (state, { position }) => {
    if (position === BottomSheetPosition.IS_BOTTOM) {
      return { ...state, position, searchQuery: '', searchMode: false };
    }
    return { ...state, position };
  }),
  on(ReactionDetailsActions.open, (state, { reactions }) => {
    return { ...state, reactions };
  }),
  on(ReactionDetailsActions.close, (state) => {
    return { ...state, reactions: [] };
  }),
);
