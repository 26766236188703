import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { px } from '@t5s/client/ui/style/common';
import { KeyboardStateObservable } from '@t5s/mobile-client/provider-token/keyboard';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { KeyboardPosition, KeyboardState } from '@t5s/mobile-client/value-object/keyboard';
import { Observable } from 'rxjs';

interface KeyboardSpacerState extends KeyboardState {
  bottomDistance?: number;
}

@Component({
  selector: 't5s-keyboard-spacer',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyboardSpacerComponent extends RxComponent<KeyboardSpacerState> {
  constructor(private readonly elRef: ElementRef<HTMLElement>, keyboardState$: KeyboardStateObservable) {
    super();
    this.connect(keyboardState$);

    this.hold(
      this.select(selectSlice(['keyboardPosition', 'keyboardHeight'])),
      ({ keyboardPosition, keyboardHeight }) => {
        const { bottomDistance } = this.get();

        if (keyboardPosition === KeyboardPosition.DID_HIDE || keyboardPosition === KeyboardPosition.WILL_HIDE) {
          this.elRef.nativeElement.style.setProperty('height', px(0));
          this.elRef.nativeElement.style.setProperty('min-height', px(0));
          this.elRef.nativeElement.style.setProperty('max-height', px(0));
        } else {
          const _keyboardHeight = Math.max(0, keyboardHeight - (bottomDistance ?? 0));
          this.elRef.nativeElement.style.setProperty('height', px(_keyboardHeight));
          this.elRef.nativeElement.style.setProperty('min-height', px(_keyboardHeight));
          this.elRef.nativeElement.style.setProperty('max-height', px(_keyboardHeight));
        }
      },
    );
  }

  @Input() set bottomDistance(bottomDistance: number | Observable<number>) {
    this.setProperty('bottomDistance', bottomDistance);
  }
}
