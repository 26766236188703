import gql from 'graphql-tag';
import {
  FlimValAttachmentDtoNoNestingFragment,
  SingleAttachmentFlimValDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
} from '../../../../generated/graphql-fragments';

export const SingleAttachmentFlimValFragment = gql`
  fragment BlabItemsOverviewSingleAttachmentFlimVal on SingleAttachmentFlimValDto {
    ...SingleAttachmentFlimValDtoNoNesting
    attachment {
      ...FlimValAttachmentDtoNoNesting
      thumbnail {
        ...ThumbnailDtoNoNesting
      }
    }
  }
  ${SingleAttachmentFlimValDtoNoNestingFragment}
  ${FlimValAttachmentDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
