import { fontFamily } from '../constants';

export const fontFaceStyles = `
  @font-face {
    font-family: "iawriter-mono";
    src: url(https://tape-static.s3.eu-central-1.amazonaws.com/font/iawriter-mono/v1/iawriter-mono-regular.woff)
    format("woff");
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: "iawriter-mono";
    src: url(https://tape-static.s3.eu-central-1.amazonaws.com/font/iawriter-mono/v1/iawriter-mono-italic.woff)
    format("woff");
    font-style: italic;
    font-weight: 400;
  }

  @font-face {
    font-family: "iawriter-mono";
    src: url(https://tape-static.s3.eu-central-1.amazonaws.com/font/iawriter-mono/v1/iawriter-mono-bold.woff)
    format("woff");
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: "iawriter-mono";
    src: url(https://tape-static.s3.eu-central-1.amazonaws.com/font/iawriter-mono/v1/iawriter-mono-bold-italic.woff)
    format("woff");
    font-style: italic;
    font-weight: 600;
  }


  html {
    font-family: ${fontFamily.default};
    -webkit-font-smoothing: auto;
  }
`;

/**
 *
  @font-face {
    font-family: "Noto Color Emoji";
    src: url(https://tape-static.s3.eu-central-1.amazonaws.com/font/noto-color-emoji/v1/NotoColorEmoji.ttf)
    format("truetype");
  }
 */

/**
   * NOTE: in 11/2024 this property led to issues with most recent chrome version and messed up all numbers in Tape:
    font-variant-emoji: emoji;
   */
