import { TimeFormat } from '@t5s/shared/gql';
import { dateFnsLocales, formatDate as __formatDate } from '@t5s/shared/util/date';

function getTimeLocale(i18n: { timeFormat: TimeFormat }) {
  const { timeFormat } = i18n;

  if (timeFormat === TimeFormat.HOURS_24) {
    return dateFnsLocales.de;
  }

  return dateFnsLocales.enUS;
}

export function formatTime(time: Date, format: string, i18n: { timeFormat: TimeFormat }) {
  const locale = getTimeLocale(i18n);
  return __formatDate(time, format, { locale });
}
