import gql from 'graphql-tag';
import { MultiLinkFlimValEntryDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { LinkPreviewDtoFragment } from '../../link-preview';
export const MultiLinkFlimValEntryFragment = gql`
  fragment MultiLinkFlimValEntryFragment on MultiLinkFlimValEntryDto {
    ...MultiLinkFlimValEntryDtoNoNesting
    linkPreview {
      ...LinkPreviewDtoFragment
    }
  }
  ${MultiLinkFlimValEntryDtoNoNestingFragment}
  ${LinkPreviewDtoFragment}
`;
