import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EmojiData, EmojiSearch, EmojiService } from '@t5s/client/util/emoji-mart';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { RootActions } from '@t5s/mobile-client/business-logic/root';
import { HardwareBackButtonObservable } from '@t5s/mobile-client/provider-token/hardware-back-button';
import { MAX_NUM_EMOJI_SEARCH_RESULTS } from '@t5s/mobile-client/readonly-constant/emoji-picker';
import { KeyboardService } from '@t5s/mobile-client/service/keyboard';
import { StateHydrationService } from '@t5s/mobile-client/service/state-hydration';
import { throttleTimeEmitInstantlyWithTrailing } from '@t5s/shared/util/rxjs';
import { of } from 'rxjs';
import { debounceTime, first, map, switchMap } from 'rxjs/operators';
import { EmojiPickerState } from './emoij-picker.state';
import { EmojiPickerActions } from './emoji-picker.actions';
import { emojiPickerStateKey } from './emoji-picker.reducer';
import {
  selectEmojiPickerFeatureStateForPersistance,
  selectEmojiPickerFrequentlyUsedEmojis,
  selectEmojiPickerPosition,
  selectEmojiPickerSearchQuery,
} from './emoji-picker.selectors';

@Injectable()
export class EmojiPickerEffects {
  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly keyboardService: KeyboardService,
    private readonly backButton$: HardwareBackButtonObservable,
    private readonly stateHydrationService: StateHydrationService<EmojiPickerState>,
    private readonly emojiSearchService: EmojiSearch,
    private readonly emojiService: EmojiService,
  ) {}

  readonly setSheetOpen$ = createEffect(() =>
    this.store$.select(selectEmojiPickerPosition).pipe(
      map((sheetPosition) => {
        return RootActions.setSheetPosition({ sheetPosition });
      }),
    ),
  );

  // TODO: only fire if emoji picker is open
  readonly androidGoBack$ = createEffect(() => this.backButton$.pipe(map(() => EmojiPickerActions.close())));

  // Search
  readonly emojiSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmojiPickerActions.enterSearchMode, EmojiPickerActions.setSearchQuery),
      switchMap(() =>
        this.store$.select(selectEmojiPickerSearchQuery).pipe(
          throttleTimeEmitInstantlyWithTrailing(150),
          switchMap((searchQuery) => {
            // Search
            if (searchQuery) {
              return of(this.emojiSearchService.search(searchQuery, undefined, MAX_NUM_EMOJI_SEARCH_RESULTS) ?? []);
            }

            // Return frequently searched
            return this.store$.select(selectEmojiPickerFrequentlyUsedEmojis).pipe(
              first(),
              map(
                (ids) =>
                  ids
                    .slice(0, MAX_NUM_EMOJI_SEARCH_RESULTS)
                    .map((id) => this.emojiService.getData(id))
                    .filter((data) => !!data) as EmojiData[],
              ),
            );
          }),
          map((emojiData) =>
            emojiData.map(({ id, native, colons }) => ({ id, emoji: native as string, colons: colons as string })),
          ),
          map((searchResults) => EmojiPickerActions.searchEmojisSuccess({ searchResults })),
        ),
      ),
    ),
  );

  // Hydration
  readonly persistState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          EmojiPickerActions.hydrateStateSuccess,
          EmojiPickerActions.hydrateStateNotAvailable,
          EmojiPickerActions.hydrateStateException,
        ),
        switchMap(() => this.store$.select(selectEmojiPickerFeatureStateForPersistance)),
        debounceTime(1000),
        switchMap((state) => this.stateHydrationService.persistState(emojiPickerStateKey, state)),
      ),
    { dispatch: false },
  );

  readonly hydrateState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmojiPickerActions.hydrateState),
      switchMap(() =>
        this.stateHydrationService.retrieveState(emojiPickerStateKey).pipe(
          map((state) => {
            if (!state) {
              return EmojiPickerActions.hydrateStateNotAvailable();
            }

            return EmojiPickerActions.hydrateStateSuccess({ state });
          }),
          catchErrorDispatch(EmojiPickerActions.hydrateStateException),
        ),
      ),
    ),
  );

  readonly hydrateStateExceptionClear$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EmojiPickerActions.hydrateStateException),
        switchMap(() => this.stateHydrationService.clearState(emojiPickerStateKey)),
      ),
    { dispatch: false },
  );

  ngrxOnInitEffects(): Action {
    return EmojiPickerActions.hydrateState();
  }
}
