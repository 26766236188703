import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ComponentStyle,
  flexCenterHorizontal,
  fullWidth,
  getFontStyle,
  padding,
  px,
} from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { FontSet } from '@t5s/mobile-client/ui/style/theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface FullWidthTextButtonState {
  fgColor: string;
  bgColor: string;
  font: FontSet;
}

@Component({
  selector: 't5s-full-width-text-button',
  template: `<button
    [class]="buttonClass$ | push"
    [t5sTouchActive]="'opacity: 0.9'"
    (t5sPressDisableLongpress)="btnClick.emit()"
  >
    <ng-content></ng-content>
  </button>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostFullWidth],
})
export class FullWidthTextButtonComponent extends RxComponent<FullWidthTextButtonState> {
  constructor() {
    super();
    this.set({
      bgColor: 'transparent',
    });
  }

  @Input() set font(font: FontSet | Observable<FontSet>) {
    this.setProperty('font', font);
  }

  @Input() set fgColor(fgColor: string | Observable<string>) {
    this.setProperty('fgColor', fgColor);
  }

  @Input() set bgColor(bgColor: string | Observable<string>) {
    this.setProperty('bgColor', bgColor);
  }

  @Output() btnClick = new EventEmitter<{ event: MouseEvent }>();

  readonly buttonClass$ = this.select(selectSlice(['font', 'fgColor', 'bgColor'])).pipe(
    map(({ font, fgColor, bgColor }) =>
      tss({
        ...fullWidth,
        color: fgColor,
        backgroundColor: bgColor,
        ...getFontStyle(font),
        padding: padding(16.5, 26),
        borderRadius: px(1000),
        ...flexCenterHorizontal,
      }),
    ),
  );
}
