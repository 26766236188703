import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { boxShadow, ComponentStyle, exactSize, firstChild, px, rounded, setAlpha } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { UserProfileDto, UserProfilePreviewDto } from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getFallbackGraphic } from './avatar-fallback.utils';

type AvatarDto = UserProfileDto | UserProfilePreviewDto;

interface AvatarState {
  user: AvatarDto;
  size: number;
  imgUrlErrored?: boolean;
}

@Component({
  selector: 't5s-avatar',
  template: `
    <!-- Fallback -->
    <ng-container *ngIf="showFallback$ | push; else thumbnail">
      <t5s-graphic [graphic]="fallbackGraphic$" [size]="size$"></t5s-graphic>
    </ng-container>

    <!-- Thumbnail -->
    <ng-template #thumbnail>
      <div [class]="thumbnailClass$ | push"><img [src]="imgUrl$ | push" (error)="set({ imgUrlErrored: true })" /></div>
    </ng-template>

    <div [class]="shadowClass$ | push"></div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostFitContentPositionRelative],
})
export class AvatarComponent extends RxComponent<AvatarState> {
  constructor() {
    super();
    this.set({
      imgUrlErrored: false,
    });
  }

  @Input() set size(size: number | Observable<number>) {
    this.setProperty('size', size);
  }

  @Input() set user(user: AvatarDto | Observable<AvatarDto>) {
    this.setProperty('user', user);
  }

  readonly size$ = this.select(selectSlice(['size'])).pipe(map(({ size }) => size));

  readonly imgUrl$ = this.select(selectSlice(['size', 'user'])).pipe(
    map(({ size, user }) => this.getImgUrl({ size, user })),
  );

  readonly showFallback$ = this.select(selectSlice(['size', 'user', 'imgUrlErrored'])).pipe(
    map(({ size, user, imgUrlErrored }) => !this.getImgUrl({ size, user }) || imgUrlErrored),
  );

  readonly fallbackGraphic$ = this.select(selectSlice(['user'])).pipe(map(({ user }) => getFallbackGraphic(user)));

  readonly thumbnailClass$ = this.size$.pipe(
    map((size) =>
      tss({
        ...exactSize(size),
        ...rounded,
        overflow: 'hidden',
        backgroundColor: ThemeColorVar.lightest,
        ...firstChild({
          ...exactSize(size),
        }),
      }),
    ),
  );

  readonly shadowClass$ = this.size$.pipe(
    map((size) =>
      tss({
        position: 'absolute',
        top: px(0),
        left: px(0),
        boxShadow: `inset ${boxShadow(1, setAlpha('#000000', 4))}, inset ${boxShadow(2, setAlpha('#000000', 3))}`,
        ...exactSize(size),
        ...rounded,
      }),
    ),
  );

  private getImgUrl({ size, user }: AvatarState): string | undefined {
    if (!user?.profilePicture) {
      return undefined;
    }

    const { profilePicture } = user;
    const { small, medium, large } = profilePicture;

    if (size > 400 && large) {
      return large;
    }

    if (size > 200 && medium) {
      return medium;
    }

    return small ?? medium ?? large;
  }
}
