import {
  CreateMultiPhoneFlimValEntryNestedCreationInput,
  CreateMultiPhoneFlimValInput,
  MultiPhoneFlimValDto,
  MultiPhoneFlimValEntryDto,
} from '@t5s/shared/gql';

function getMultiPhoneCreateNestedEntryInputFromDto(
  multiPhoneEntry: MultiPhoneFlimValEntryDto,
): CreateMultiPhoneFlimValEntryNestedCreationInput | undefined {
  const { position, phoneNumber, phoneNumberType } = multiPhoneEntry;

  if (!phoneNumber || !phoneNumberType) {
    return undefined;
  }

  return { position, phoneNumber, phoneNumberType };
}

export function getMultiPhoneCreateInputFromDto(multiPhoneFlimVal: MultiPhoneFlimValDto): CreateMultiPhoneFlimValInput {
  const { flimDefId } = multiPhoneFlimVal;

  const multiPhoneEntryDtos = multiPhoneFlimVal.multiPhoneEntries ?? [];
  const multiPhoneEntries = multiPhoneEntryDtos
    .map(getMultiPhoneCreateNestedEntryInputFromDto)
    .filter((entry): entry is CreateMultiPhoneFlimValEntryNestedCreationInput => entry !== undefined);

  return { flimDefId, multiPhoneEntries };
}
