import { createFeatureSelector, createSelector } from '@ngrx/store';
import { lastCursorFromNotifications } from '../common';
import { filterSupportedNotifications } from '../common/notification-supported.util';
import {
  adapter,
  DirectNotificationsState,
  directNotificationStateKey,
  preprocessStateForSerialization,
} from './direct-notifications.reducer';

const selectDirectNotificationsFeatureState =
  createFeatureSelector<DirectNotificationsState>(directNotificationStateKey);

export const selectDirectNotificationsFeatureStateForPersistance = createSelector(
  selectDirectNotificationsFeatureState,
  preprocessStateForSerialization,
);

const { selectAll } = adapter.getSelectors();
const _selectDirectNotifications = createSelector(selectDirectNotificationsFeatureState, selectAll);
export const selectDirectNotifications = createSelector(_selectDirectNotifications, filterSupportedNotifications);

export const selectDirectNotificationsInitialized = createSelector(
  selectDirectNotificationsFeatureState,
  ({ initialized }) => initialized,
);

export const selectDirectNotificationsLoading = createSelector(
  selectDirectNotificationsFeatureState,
  ({ isLoading, hydrated }) => isLoading && !hydrated,
);

export const selectDirectNotificationsReloading = createSelector(
  selectDirectNotificationsFeatureState,
  ({ isReplaceLoading }) => isReplaceLoading,
);

export const selectDirectNotificationsReloadingException = createSelector(
  selectDirectNotificationsFeatureState,
  ({ loadReplaceException }) => loadReplaceException,
);

export const selectDirectNotificationsLoadingMore = createSelector(
  selectDirectNotificationsFeatureState,
  ({ isLoadingMore }) => isLoadingMore,
);

const selectHasNextPage = createSelector(
  selectDirectNotificationsFeatureState,
  ({ pageInfo }) => pageInfo?.hasNextPage,
);

const selectNotificationCursor = createSelector(_selectDirectNotifications, lastCursorFromNotifications);

export const selectLoadMoreDirectNotificationsInput = createSelector(
  selectHasNextPage,
  selectNotificationCursor,
  (hasNextPage, cursor): { cursor: string } | undefined => {
    if (!hasNextPage) {
      return undefined;
    }

    if (!cursor) {
      return undefined;
    }

    return { cursor };
  },
);
