import gql from 'graphql-tag';
import { OrganizationInvitationDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { UserProfilePreviewDtoFragment } from '../user-profile';

export const OrganizationInvitationDtoFragment = gql`
  fragment OrganizationInvitationDtoFragment on OrganizationInvitationDto {
    ...OrganizationInvitationDtoNoNesting
    user {
      ...UserProfilePreviewDtoFragment
    }
    author {
      ...UserProfilePreviewDtoFragment
    }
    approvedOrDeniedBy {
      ...UserProfilePreviewDtoFragment
    }
  }
  ${OrganizationInvitationDtoNoNestingFragment}
  ${UserProfilePreviewDtoFragment}
`;

export const OrganizationInvitationsConnectionFragment = gql`
  fragment OrganizationInvitationsConnectionFragment on OrganizationInvitationsConnection {
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...OrganizationInvitationDtoFragment
      }
    }
  }
  ${OrganizationInvitationDtoFragment}
`;
