import gql from 'graphql-tag';
import {
  ActiveUserCalendarSubscriptionDtoNoNestingFragment,
  BlabDefPreviewDtoNoNestingFragment,
  BlabItemCalendarEventDtoNoNestingFragment,
  ChecklistEntryCalendarEventDtoNoNestingFragment,
  ReminderCalendarEventDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const CalendarEventUnionTypeFragment = gql`
  fragment CalendarEventUnionType on CalendarEventUnionType {
    ... on BlabItemCalendarEventDto {
      ...BlabItemCalendarEventDtoNoNesting
      blabDef {
        ...BlabDefPreviewDtoNoNesting
      }
      calendarSubscription {
        ...ActiveUserCalendarSubscriptionDtoNoNesting
      }
    }
    ... on ReminderCalendarEventDto {
      ...ReminderCalendarEventDtoNoNesting
      calendarSubscription {
        ...ActiveUserCalendarSubscriptionDtoNoNesting
      }
    }
    ... on ChecklistEntryCalendarEventDto {
      ...ChecklistEntryCalendarEventDtoNoNesting
      calendarSubscription {
        ...ActiveUserCalendarSubscriptionDtoNoNesting
      }
    }
  }
  ${ReminderCalendarEventDtoNoNestingFragment}
  ${BlabItemCalendarEventDtoNoNestingFragment}
  ${BlabDefPreviewDtoNoNestingFragment}
  ${ChecklistEntryCalendarEventDtoNoNestingFragment}
  ${ActiveUserCalendarSubscriptionDtoNoNestingFragment}
`;
