import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { exactHeight, exactWidth, fitContent } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { GraphicInterface } from '@t5s/mobile-client/asset';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { isObservable, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

interface GraphicState {
  graphic: GraphicInterface | string;
  width: number;
  height: number;
}

type GraphicData = string;

@Component({
  selector: 't5s-graphic',
  template: `<div [class]="graphicClass$ | push" [innerHTML]="svgHtml$ | push"> </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphicComponent extends RxComponent<GraphicState> {
  constructor(private readonly sanitizer: DomSanitizer) {
    super();
  }

  @Input() set graphic(
    graphic: GraphicInterface | GraphicData | Observable<GraphicInterface> | Observable<GraphicData>,
  ) {
    this.setProperty('graphic', graphic);
  }

  @Input() set width(width: number | Observable<number>) {
    this.setProperty('width', width);
  }

  @Input() set height(height: number | Observable<number>) {
    this.setProperty('height', height);
  }

  @Input() set size(size: number | Observable<number>) {
    if (isObservable(size)) {
      this.connect(size.pipe(map((size) => ({ width: size, height: size } as any))));
    } else {
      this.set({ width: size, height: size });
    }
  }

  readonly graphicData$ = this.select(selectSlice(['graphic'])).pipe(
    map(({ graphic }) => (graphic ? (graphic as GraphicInterface)?.data ?? graphic : undefined)),
    filterOnlyPresent(),
    distinctUntilChanged(),
  );

  readonly svgHtml$ = this.graphicData$.pipe(map((data) => this.sanitizer.bypassSecurityTrustHtml(data)));

  readonly graphicClass$ = this.select(selectSlice(['width', 'height'])).pipe(
    map(({ width, height }) =>
      tss({
        ...fitContent,
        overflow: 'hidden',
        svg: {
          ...exactHeight(height),
          ...exactWidth(width),
        },
      }),
    ),
  );
}
