import { BlabItemActivityReplyEdge, BlabItemActivityUnionType, PageInfo } from '@t5s/shared/gql';
import { BlabItemActivityReplyInputAttachmentValobj } from './blab-item-activity-reply-input-attachments.valobj';

export enum BlabItemActivityThreadActivityLoadingState {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  RELOADING = 'RELOADING',
  EXCEPTION = 'EXCEPTION',
}

export enum BlabItemActivityThreadRepliesLoadingState {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  RELOADING = 'RELOADING',
  EXCEPTION = 'EXCEPTION',
}

interface BlabItemActivityThreadBlabItemActivityState {
  replies: BlabItemActivityReplyEdge[];
  pageInfo: PageInfo;
}

export interface BlabItemActivityThreadReplyInputState {
  content?: string;
  attachments?: BlabItemActivityReplyInputAttachmentValobj[];
}

export interface BlabItemActivityThreadStateModel {
  blabItemActivityId: number;

  blabItemActivity?: BlabItemActivityUnionType;
  blabItemActivityLoadingState: BlabItemActivityThreadActivityLoadingState;

  activityReplies?: BlabItemActivityThreadBlabItemActivityState;
  activityRepliesLoadingState: BlabItemActivityThreadRepliesLoadingState;

  replyInput?: BlabItemActivityThreadReplyInputState;
}
