import {
  GetIncomingBlabItemsInput,
  GetIncomingRelationFlimDefsInput,
  IncomingBlabItemsConnection,
  IncomingRelationFlimDefPreviewDto,
} from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import {
  MinimalIncomingBlabItemsConnection,
  MinimalIncomingRelationFlimDefPreviewDtoFragment,
  MinimalIncomingRelationFlimDefPreviewDtoNoBlabItemsFragment,
} from '../../fragments/blab-item-incoming-relation';
import { getIncomingBlabItems, getIncomingRelationFlimDefs } from '../../generated/graphql-queries';
import { GqlService } from '../../gql-service';

export class GqlBlabItemIncomingRelationService extends GqlService {
  getIncomingRelationFlimDefs(
    input: GetIncomingRelationFlimDefsInput,
  ): Observable<IncomingRelationFlimDefPreviewDto[]> {
    return this.apollo.defaultQuery(
      getIncomingRelationFlimDefs(input, [MinimalIncomingRelationFlimDefPreviewDtoFragment]),
    );
  }

  getIncomingRelationFlimDefsOmitBlabItems(
    input: GetIncomingRelationFlimDefsInput,
  ): Observable<IncomingRelationFlimDefPreviewDto[]> {
    return this.apollo.defaultQuery(
      getIncomingRelationFlimDefs(input, [MinimalIncomingRelationFlimDefPreviewDtoNoBlabItemsFragment]),
    );
  }

  getIncomingBlabItems(
    first: number,
    input?: GetIncomingBlabItemsInput,
    after?: string,
  ): Observable<IncomingBlabItemsConnection> {
    return this.apollo.defaultQuery(getIncomingBlabItems(first, input, after, [MinimalIncomingBlabItemsConnection]));
  }
}
