import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { FilePicker } from '@capawesome/capacitor-file-picker';
import { fileFromBase64Data, filesFromInputEvent } from '@t5s/client/util/file';
import { PlatformObservable } from '@t5s/mobile-client/provider-token/device';
import { ApplicationStateObservable } from '@t5s/mobile-client/provider-token/state';
import { FilesystemService } from '@t5s/mobile-client/service/filesystem';
import { FilePickerOptions } from '@t5s/mobile-client/value-object/file-picker';
import { EMPTY, Observable, defer, from, fromEvent, merge, of } from 'rxjs';
import { debounceTime, filter, first, map, mapTo, share, skip, switchMap } from 'rxjs/operators';

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
// https://capacitorjs.com/docs/apis/filesystem

@Injectable()
export class FilePickerService {
  private readonly filePickerEl: HTMLInputElement;
  private readonly filePicked$: Observable<{ files: File[] }>;

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly platform$: PlatformObservable,
    private readonly appState$: ApplicationStateObservable,
    private readonly fileService: FilesystemService,
  ) {
    const { body } = this.document;
    const filePickerEl = this.document.createElement('input');
    filePickerEl.setAttribute('style', 'opacity: 0; width: 0; height: 0');
    filePickerEl.setAttribute('type', 'file');

    this.filePicked$ = fromEvent<InputEvent>(filePickerEl, 'change').pipe(
      map((event) => ({ files: filesFromInputEvent(event) })),
      share(),
    );

    body.appendChild(filePickerEl);

    this.filePickerEl = filePickerEl;
  }

  pickFile(options?: FilePickerOptions) {
    return this.platform$.pipe(
      first(),
      switchMap(({ platform }) => {
        if (platform === 'android') {
          return this.pickFileAndroid(options);
        } else if (platform === 'ios') {
          return this.pickFileIos(options);
        }

        return of(undefined);
      }),
    );
  }

  private pickFileAndroid(options?: FilePickerOptions) {
    if (options?.multiple) {
      this.filePickerEl.setAttribute('multiple', '');
    } else {
      this.filePickerEl.removeAttribute('multiple');
    }

    const cancel$ = this.appState$.pipe(
      skip(1), // Behavior subject
      filter(({ isActive }) => isActive),
      debounceTime(0), // Give the filePicked observable a chance to emit
      mapTo(undefined),
    );

    this.filePickerEl.click();
    const result$ = merge(this.filePicked$, cancel$).pipe(first());
    return result$;
  }

  private pickFileIos(options?: FilePickerOptions) {
    const cancel$ = this.appState$.pipe(
      skip(1), // Behavior subject
      filter(({ isActive }) => isActive),
      debounceTime(0), // Give the filePicked observable a chance to emit
      mapTo(undefined),
    );

    // Capacitor plugin does not support multiple option
    const picked$ = defer(() =>
      from(
        FilePicker.pickFiles({
          types: options?.fileTypes,
        }),
      ).pipe(
        switchMap(({ files }) => {
          const [{ mimeType, name, path }] = files;
          if (!path) {
            return EMPTY;
          }

          return this.fileService.readFile({ path }).pipe(
            map((result) => result.data),
            filter((data): data is string => typeof data === 'string'),
            map((data) => ({ files: [fileFromBase64Data(data, name, mimeType)] })),
          );
        }),
      ),
    );

    const result$ = merge(picked$, cancel$).pipe(first());
    return result$;
  }
}
