import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  getChatConversationAddLocalChatActivityUponSend,
  getChatConversationAfterChatActivityReactionUpdate,
  getChatConversationAfterChatConversationActivityLiveUpdate,
  getChatConversationAfterChatConversationActivityLoadMoreSuccess,
  getChatConversationAfterChatConversationActivityLoadSuccess,
  getChatConversationAfterChatMessageDeleted,
  getChatConversationDiscardLocalChatActivity,
  getChatConversationReplaceLocalChatActivityUponSendSuccess,
  getChatConversationRetrySendSetLocalChatActivity,
  getChatConversationSetLocalChatActivityTerminalException,
  getChatMessageInputStateAddAttachments,
  getChatMessageInputStateAttachmentUploadException,
  getChatMessageInputStateAttachmentUploadStart,
  getChatMessageInputStateAttachmentUploadSuccess,
  getChatMessageInputStateRemoveAttachment,
  sortChatActivityEdges,
} from '@t5s/mobile-client/util/chat-conversation';
import {
  ChatActivityLoadingState,
  ChatConversationClientModel,
  ChatConversationLoadingState,
  ChatConversationLocalMessageActivityStatus,
} from '@t5s/mobile-client/value-object/chat-conversation';
import { ChatConversationActions } from './chat-conversation.actions';
import { ChatConversationState } from './chat-conversation.state';

export const chatConversationStateKey = 'chatConversation';

const adapter = createEntityAdapter<ChatConversationClientModel>({
  selectId: (conversation) => conversation.conversationId,
});

const { selectAll, selectEntities } = adapter.getSelectors();

const MAX_NUM_PERSISTED_CHAT_CONVERSATIONS = 30;
const MAX_NUM_PERSISTED_CHAT_ACTIVITES_PER_CONVERSATION = 50;

const initialState: ChatConversationState = adapter.getInitialState();

export const preprocessStateForSerialization = (state: ChatConversationState) => {
  let chatConversations = selectAll(state)
    .filter((blabItem) => blabItem.loadingState === ChatConversationLoadingState.LOADED)
    .slice(0, MAX_NUM_PERSISTED_CHAT_CONVERSATIONS);

  chatConversations = chatConversations.map((conversation) => {
    let { localMessages } = conversation;
    let { chatActivity } = conversation;

    // (1) map all local messages to errored state before serialization;
    // this ensures that upon hydration (app relaunch) they are marked as errored
    // and require user interaction
    localMessages = localMessages.map((localMessage) => ({
      ...localMessage,
      status: ChatConversationLocalMessageActivityStatus.ERRORED,
    }));

    // (2) ensure only a certain amount of activities is persisted per conversation
    if (chatActivity) {
      let { chatActivities } = chatActivity;

      if (chatActivities?.length > MAX_NUM_PERSISTED_CHAT_ACTIVITES_PER_CONVERSATION) {
        const startIndex = chatActivities.length - MAX_NUM_PERSISTED_CHAT_ACTIVITES_PER_CONVERSATION;
        chatActivities = [...chatActivities].sort(sortChatActivityEdges).slice(startIndex, chatActivities.length - 1);
      }

      chatActivity = { ...chatActivity, chatActivities };
    }

    return { ...conversation, localMessages, chatActivity };
  });

  state = adapter.setAll(chatConversations, state);
  return state;
};

export const chatConversationReducer = createReducer(
  initialState,
  on(ChatConversationActions.reset, () => ({ ...initialState })),

  on(ChatConversationActions.hydrateStateSuccess, (state, { state: hydratedState }) => {
    const { entities, ids } = hydratedState;
    return { ...state, entities, ids };
  }),

  on(ChatConversationActions.loadConversation, (state, { conversationId }) => {
    const chatConversationState = selectEntities(state)[conversationId];

    if (!chatConversationState) {
      return adapter.addOne(
        {
          conversationId,
          loadingState: ChatConversationLoadingState.LOADING,
          chatActivityLoadingState: ChatActivityLoadingState.LOADING,
          messageInput: {},
          localMessages: [],
        },
        state,
      );
    }

    return adapter.upsertOne({ ...chatConversationState, loadingState: ChatConversationLoadingState.LOADING }, state);
  }),
  on(ChatConversationActions.setConversation, (state, { conversationId, conversation }) => {
    const chatConversationState = selectEntities(state)[conversationId];

    if (!chatConversationState) {
      return adapter.addOne(
        {
          conversationId,
          conversation,
          loadingState: ChatConversationLoadingState.LOADING,
          chatActivityLoadingState: ChatActivityLoadingState.LOADING,
          messageInput: {},
          localMessages: [],
        },
        state,
      );
    }

    return adapter.upsertOne({ ...chatConversationState, conversation }, state);
  }),

  on(ChatConversationActions.loadConversationSuccess, (state, { conversationId, conversation }) =>
    adapter.updateOne(
      {
        id: conversationId,
        changes: {
          conversation,
          loadingState: ChatConversationLoadingState.LOADED,
        },
      },
      state,
    ),
  ),

  on(ChatConversationActions.loadConversationChatActivitySuccess, (state, { conversationId, connection }) => {
    const chatConversationState = selectEntities(state)[conversationId];

    if (!chatConversationState) {
      return state;
    }

    return adapter.upsertOne(
      getChatConversationAfterChatConversationActivityLoadSuccess(chatConversationState, connection),
      state,
    );
  }),
  on(ChatConversationActions.loadMoreConversationChatActivitySuccess, (state, { conversationId, connection }) => {
    const chatConversationState = selectEntities(state)[conversationId];

    if (!chatConversationState) {
      return state;
    }

    return adapter.upsertOne(
      getChatConversationAfterChatConversationActivityLoadMoreSuccess(chatConversationState, connection),
      state,
    );
  }),

  // subscription update
  on(ChatConversationActions.conversationUpdate, (state, { conversationId, update }) => {
    const chatConversationState = selectEntities(state)[conversationId];

    if (!chatConversationState) {
      return state;
    }

    return adapter.upsertOne(
      getChatConversationAfterChatConversationActivityLiveUpdate(chatConversationState, update),
      state,
    );
  }),

  // Message input
  on(ChatConversationActions.setMessageInputContent, (state, { conversationId, content }) => {
    const chatConversationState = selectEntities(state)[conversationId];

    if (!chatConversationState) {
      return state;
    }

    const { messageInput = {} } = chatConversationState;

    return adapter.updateOne(
      {
        id: conversationId,
        changes: {
          messageInput: {
            ...messageInput,
            content,
          },
        },
      },
      state,
    );
  }),
  on(ChatConversationActions.sendMessage, (state, { localId, conversationId, content, activeUser }) => {
    const chatConversationState = selectEntities(state)[conversationId];

    if (!chatConversationState) {
      return state;
    }

    return adapter.upsertOne(
      getChatConversationAddLocalChatActivityUponSend(chatConversationState, { content, localId, activeUser }),
      state,
    );
  }),
  on(ChatConversationActions.sendMessageSuccess, (state, { conversationId, localId, activity }) => {
    const chatConversationState = selectEntities(state)[conversationId];

    if (!chatConversationState) {
      return state;
    }

    return adapter.upsertOne(
      getChatConversationReplaceLocalChatActivityUponSendSuccess(chatConversationState, { localId }, activity),
      state,
    );
  }),
  on(ChatConversationActions.sendMessageTerminalException, (state, { conversationId, localId }) => {
    const chatConversationState = selectEntities(state)[conversationId];

    if (!chatConversationState) {
      return state;
    }

    return adapter.upsertOne(
      getChatConversationSetLocalChatActivityTerminalException(chatConversationState, { localId }),
      state,
    );
  }),
  on(ChatConversationActions.retrySendMessage, (state, { conversationId, localId }) => {
    const chatConversationState = selectEntities(state)[conversationId];

    if (!chatConversationState) {
      return state;
    }

    return adapter.upsertOne(
      getChatConversationRetrySendSetLocalChatActivity(chatConversationState, { localId }),
      state,
    );
  }),
  on(ChatConversationActions.discardLocalErroredMessage, (state, { conversationId, localId }) => {
    const chatConversationState = selectEntities(state)[conversationId];

    if (!chatConversationState) {
      return state;
    }

    return adapter.upsertOne(getChatConversationDiscardLocalChatActivity(chatConversationState, { localId }), state);
  }),

  on(ChatConversationActions.addAttachmentFiles, (state, { conversationId, files }) => {
    const chatConversationState = selectEntities(state)[conversationId];

    if (!chatConversationState) {
      return state;
    }

    const { messageInput = {} } = chatConversationState;

    return adapter.updateOne(
      {
        id: conversationId,
        changes: {
          messageInput: getChatMessageInputStateAddAttachments(messageInput, files),
        },
      },
      state,
    );
  }),
  on(ChatConversationActions.uploadFileException, (state, { conversationId, attachment }) => {
    const chatConversationState = selectEntities(state)[conversationId];

    if (!chatConversationState) {
      return state;
    }

    const { messageInput } = chatConversationState;

    if (!messageInput) {
      return state;
    }

    return adapter.updateOne(
      {
        id: conversationId,
        changes: {
          messageInput: getChatMessageInputStateAttachmentUploadException(messageInput, attachment),
        },
      },
      state,
    );
  }),
  on(ChatConversationActions.removeAttachment, (state, { conversationId, attachmentId }) => {
    const chatConversationState = selectEntities(state)[conversationId];

    if (!chatConversationState) {
      return state;
    }

    const { messageInput } = chatConversationState;

    if (!messageInput) {
      return state;
    }

    return adapter.updateOne(
      {
        id: conversationId,
        changes: {
          messageInput: getChatMessageInputStateRemoveAttachment(messageInput, attachmentId),
        },
      },
      state,
    );
  }),
  on(ChatConversationActions.uploadFile, (state, { conversationId, attachment }) => {
    const chatConversationState = selectEntities(state)[conversationId];

    if (!chatConversationState) {
      return state;
    }

    const { messageInput } = chatConversationState;

    if (!messageInput) {
      return state;
    }

    return adapter.updateOne(
      {
        id: conversationId,
        changes: {
          messageInput: getChatMessageInputStateAttachmentUploadStart(messageInput, attachment),
        },
      },
      state,
    );
  }),

  on(ChatConversationActions.uploadFileSuccess, (state, { conversationId, attachment, persistedAttachment }) => {
    const chatConversationState = selectEntities(state)[conversationId];

    if (!chatConversationState) {
      return state;
    }

    const { messageInput } = chatConversationState;

    if (!messageInput) {
      return state;
    }

    return adapter.updateOne(
      {
        id: conversationId,
        changes: {
          messageInput: getChatMessageInputStateAttachmentUploadSuccess(messageInput, attachment, persistedAttachment),
        },
      },
      state,
    );
  }),

  // chat activity reactions
  on(
    ChatConversationActions.addChatActivityReactionSuccess,
    ChatConversationActions.removeChatActivityReactionSuccess,
    (state, { activity }) => {
      const blabItemState = selectEntities(state)[activity.conversationId];

      if (!blabItemState) {
        return state;
      }

      const changes = getChatConversationAfterChatActivityReactionUpdate(blabItemState, activity);

      return adapter.updateOne({ id: activity.conversationId, changes }, state);
    },
  ),

  // message deletion
  on(ChatConversationActions.deleteChatMessageSuccess, (state, { conversationId, id }) => {
    const chatConversationState = selectEntities(state)[conversationId];

    if (!chatConversationState) {
      return state;
    }

    return adapter.upsertOne(getChatConversationAfterChatMessageDeleted(chatConversationState, { id }), state);
  }),

  // Mention user
  on(ChatConversationActions.searchMentionUserSuccess, (state, { userSearchResults }) => ({
    ...state,
    userSearchResults,
  })),
  on(ChatConversationActions.clearMentionUserSearchResults, (state) => ({
    ...state,
    userSearchResults: [],
  })),
);
