import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PlatformAndroidObservable } from '@t5s/mobile-client/provider-token/device';
import { KeyboardService } from '@t5s/mobile-client/service/keyboard';
import { StatusBarService } from '@t5s/mobile-client/service/status-bar';
import { BottomSheetPosition } from '@t5s/mobile-client/value-object/bottom-sheet';
import { debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import { selectSheetOpen, selectSheetPosition } from './root.selectors';

@Injectable()
export class RootEffects {
  constructor(
    private readonly store$: Store,
    private readonly android$: PlatformAndroidObservable,
    private readonly statusBarService: StatusBarService,
    private readonly keyboardService: KeyboardService,
  ) {}

  readonly androidDarkStatusBar$ = createEffect(
    () =>
      this.android$.pipe(
        switchMap(() =>
          this.store$.select(selectSheetOpen).pipe(
            filter((sheetOpen) => sheetOpen),
            switchMap(() => {
              return this.statusBarService.setStyleDark();
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  readonly androidLightStatusBar$ = createEffect(
    () =>
      this.android$.pipe(
        switchMap(() =>
          this.store$.select(selectSheetOpen).pipe(
            debounceTime(100),
            filter((sheetOpen) => !sheetOpen),
            switchMap(() => {
              return this.statusBarService.setStyleLight();
            }),
          ),
        ),
      ),
    { dispatch: false },
  );

  readonly bottomSheetWillBeTopCloseKeyboard$ = createEffect(
    () =>
      this.store$.select(selectSheetPosition).pipe(
        distinctUntilChanged(),
        filter((sheetPosition) =>
          [BottomSheetPosition.WILL_BE_BOTTOM, BottomSheetPosition.WILL_BE_DOCKED].includes(sheetPosition),
        ),
        tap(() => {
          return this.keyboardService.hide();
        }),
      ),
    { dispatch: false },
  );
}
