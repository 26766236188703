import { init } from '@elastic/apm-rum';
import { WEB_VIEW_LOCALHOST, RUNTIME_ENV } from '@t5s/mobile-client/env';
import { FirstParameter } from '@t5s/shared/types';

export type ApmConfig = FirstParameter<typeof init>;

const serverUrl: string = WEB_VIEW_LOCALHOST ? 'https://apm.tapeapp.com/rum/' : '/apm';

export const apmConfig: ApmConfig = {
  serviceName: 'mobile-client',
  serverUrl,
  active: true,
  instrument: true,
  disableInstrumentations: ['fetch', 'xmlhttprequest', 'eventtarget', 'history'],
  environment: RUNTIME_ENV.toString(),
  flushInterval: 2000,
};
