import gql from 'graphql-tag';
import {
  CreditInvoiceItemDtoNoNestingFragment,
  DiscountInvoiceItemDtoNoNestingFragment,
  LicenseInvoiceItemDtoNoNestingFragment,
  ProrationInvoiceItemDtoNoNestingFragment,
  SubscriptionInvoicePreviewDtoNoNestingFragment,
  TaxInvoiceItemDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const SubscriptionInvoicePreviewFragment = gql`
  fragment SubscriptionInvoicePreviewFragment on SubscriptionInvoicePreviewDto {
    ...SubscriptionInvoicePreviewDtoNoNesting
    items {
      ... on LicenseInvoiceItemDto {
        ...LicenseInvoiceItemDtoNoNesting
      }
      ... on ProrationInvoiceItemDto {
        ...ProrationInvoiceItemDtoNoNesting
      }
      ... on DiscountInvoiceItemDto {
        ...DiscountInvoiceItemDtoNoNesting
      }
      ... on TaxInvoiceItemDto {
        ...TaxInvoiceItemDtoNoNesting
      }
      ... on CreditInvoiceItemDto {
        ...CreditInvoiceItemDtoNoNesting
      }
    }
  }
  ${SubscriptionInvoicePreviewDtoNoNestingFragment}
  ${LicenseInvoiceItemDtoNoNestingFragment}
  ${ProrationInvoiceItemDtoNoNestingFragment}
  ${DiscountInvoiceItemDtoNoNestingFragment}
  ${TaxInvoiceItemDtoNoNestingFragment}
  ${CreditInvoiceItemDtoNoNestingFragment}
`;
