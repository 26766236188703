import type {
  ApolloClient,
  ApolloQueryResult,
  MutationOptions,
  OperationVariables,
  QueryOptions,
  SubscriptionOptions,
} from 'apollo-client';
import { FetchResult } from 'apollo-link';
import { DocumentNode, SelectionSetNode } from 'graphql';
import { EMPTY, Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { catchAndRethrowGqlError } from './error';

const defaultAlias = 'defaultAlias';
interface DefaultQueryResult<T> {
  [defaultAlias]: T;
}

const extractDefaultDataOrThrow = map((response: FetchResult<DefaultQueryResult<any>>) => {
  if (!response.data) {
    throw new Error('No data returned for query/mutation/subscription.');
  }
  return response.data[defaultAlias];
});

const checkSubscriptionDataForNullVals = mergeMap((data: any) => {
  if (data === null) {
    // eslint-disable-next-line no-console
    console.warn(
      'Null received by subscription. This usually happens when using GQL subscriptions w/o the WebsocketLink.',
    );
    return EMPTY;
  }
  return of(data);
});

export abstract class SharedApolloClient {
  abstract getClient(): ApolloClient<any>;

  abstract query<T = any, TVariables = OperationVariables>(
    options: QueryOptions<TVariables>,
  ): Observable<ApolloQueryResult<T>>;

  abstract watchQuery<T = any, TVariables = OperationVariables>(
    options: QueryOptions<TVariables>,
  ): Observable<ApolloQueryResult<T>>;

  abstract mutate<T, TVariables>(
    options: MutationOptions<T, TVariables>,
  ): Observable<FetchResult<T, Record<string, any>, Record<string, any>>>;

  abstract subscribe<T = any, TVariables = OperationVariables>(
    options: SubscriptionOptions<TVariables>,
  ): Observable<FetchResult<T>>;

  readFragment<T = any>(...params: Parameters<typeof ApolloClient.prototype.readFragment>): T | null {
    return this.getClient().readFragment(...params);
  }

  /** Executes a convenient query using the default alias. */
  defaultQuery<T = unknown, V = OperationVariables>(options: QueryOptions<V>): Observable<T> {
    this.preprocessGqlAst(options.query);
    return this.query<DefaultQueryResult<T>, V>(options).pipe(extractDefaultDataOrThrow, catchAndRethrowGqlError());
  }

  /** Executes a convenient query using the default alias. */
  defaultWatchQuery<T = unknown, V = OperationVariables>(options: QueryOptions<V>): Observable<T> {
    this.preprocessGqlAst(options.query);
    return this.watchQuery<DefaultQueryResult<T>, V>(options).pipe(
      extractDefaultDataOrThrow,
      catchAndRethrowGqlError(),
    );
  }

  /** Executes a convenient mutation using the default alias. */
  defaultMutate<T = unknown, V = OperationVariables>(
    options: MutationOptions<DefaultQueryResult<T>, V>,
  ): Observable<T> {
    this.preprocessGqlAst(options.mutation);

    return this.mutate<DefaultQueryResult<T>, V>(options).pipe(extractDefaultDataOrThrow, catchAndRethrowGqlError());
  }

  /** Executes a convenient mutation including file blobs. */
  defaultMutateFileUpload<T = unknown, V = OperationVariables>(
    options: MutationOptions<DefaultQueryResult<T>, V>,
  ): Observable<T> {
    return this.defaultMutate({ ...options, context: { useMultipart: true } }).pipe(catchAndRethrowGqlError());
  }

  /** Executes a convenient query using the default alias. */
  defaultSubscribe<T = unknown, V = OperationVariables>(options: SubscriptionOptions<V>): Observable<T> {
    this.preprocessGqlAst(options.query);
    return this.subscribe<DefaultQueryResult<T>, V>(options).pipe(
      extractDefaultDataOrThrow,
      checkSubscriptionDataForNullVals,
      catchAndRethrowGqlError(),
    );
  }

  /** Preprocesses the GQL AST for default queries / mutations. */
  private preprocessGqlAst(nodes: DocumentNode) {
    const selectionSet = (nodes.definitions[0] as { selectionSet: SelectionSetNode }).selectionSet;

    const selection = selectionSet.selections[0] as {
      alias?: { value: string; kind: string };
      name: { value: string };
    };

    if (!selection.alias || selection.alias.value === defaultAlias) {
      selection.alias = { kind: 'Name', value: defaultAlias };
    } else {
      throw Error(
        `Alias provided for default query/mutation '${selection.name.value}': value: ${selection.alias.value}`,
      );
    }
  }
}
