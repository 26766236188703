import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { APOLLO_LINK_PROVIDER } from './apollo-link.provider';
import { HTTP_LINK_PROVIDER } from './http-link.provider';

/** Generates all apollo link dependencies. */
@NgModule({
  imports: [HttpClientModule, HttpLinkModule],
  providers: [HTTP_LINK_PROVIDER, APOLLO_LINK_PROVIDER],
})
export class ApolloLinkModule {}
