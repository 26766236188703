import gql from 'graphql-tag';
import {
  FlimValImageDtoNoNestingFragment,
  MultiImageFlimValDtoNoNestingFragment,
  MultiImageFlimValFileReferenceDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
} from '../../../../generated/graphql-fragments';

export const MultiImageFlimValFragment = gql`
  fragment BlabItemsOverviewMultiImageFlimVal on MultiImageFlimValDto {
    ...MultiImageFlimValDtoNoNesting
    imageReferences {
      ...MultiImageFlimValFileReferenceDtoNoNesting
      image {
        ...FlimValImageDtoNoNesting
        thumbnail {
          ...ThumbnailDtoNoNesting
        }
      }
    }
  }
  ${MultiImageFlimValDtoNoNestingFragment}
  ${MultiImageFlimValFileReferenceDtoNoNestingFragment}
  ${FlimValImageDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
