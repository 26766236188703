import gql from 'graphql-tag';
import {
  BlabReferenceBlabDefPreviewDtoNoNestingFragment,
  BlabReferenceDtoNoNestingFragment,
  SingleRelationFlimDefDtoNoNestingFragment,
  WorkspacePreviewDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const SingleRelationFlimDefDtoFragment = gql`
  fragment SingleRelationFlimDefDtoFragment on SingleRelationFlimDefDto {
    ...SingleRelationFlimDefDtoNoNesting
    blabReferences {
      ...BlabReferenceDtoNoNesting
      blabView {
        id
        blabDefId
        name
        layout
        position
        isDefault
      }
      blabDef {
        ...BlabReferenceBlabDefPreviewDtoNoNesting
        blabViews {
          id
          blabDefId
          name
          layout
          position
          isDefault
        }
        workspace {
          ...WorkspacePreviewDtoNoNesting
        }
      }
    }
  }
  ${SingleRelationFlimDefDtoNoNestingFragment}
  ${BlabReferenceDtoNoNestingFragment}
  ${BlabReferenceBlabDefPreviewDtoNoNestingFragment}
  ${WorkspacePreviewDtoNoNestingFragment}
`;
