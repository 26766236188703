import {
  SubscribeToImportBlabDefDataJobFailureEvent,
  SubscribeToImportBlabDefDataJobSuccessEvent,
} from '@t5s/shared/gql';
import { isDto } from './dto.type-guard';

export function isSubscribeToImportBlabDefDataJobSuccessEvent(
  candidate?: any,
): candidate is SubscribeToImportBlabDefDataJobSuccessEvent {
  return isDto<SubscribeToImportBlabDefDataJobSuccessEvent>(candidate, 'SubscribeToImportBlabDefDataJobSuccessEvent');
}

export function isSubscribeToImportBlabDefDataJobFailureEvent(
  candidate?: any,
): candidate is SubscribeToImportBlabDefDataJobFailureEvent {
  return isDto<SubscribeToImportBlabDefDataJobFailureEvent>(candidate, 'SubscribeToImportBlabDefDataJobFailureEvent');
}
