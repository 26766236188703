import gql from 'graphql-tag';
import {
  WorkflowCenterWorkflowActionPreviewNoNestingFragment,
  WorkflowDefDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { UserProfilePreviewDtoFragment } from '../user-profile/user-profile-preview-dto.fragment';

export const WorkflowDefFragment = gql`
  fragment WorkflowDefDtoFragment on WorkflowDefDto {
    ...WorkflowDefDtoNoNesting
    createdByUser {
      ...UserProfilePreviewDtoFragment
    }
    lastModifiedByUser {
      ...UserProfilePreviewDtoFragment
    }
    actions {
      ...WorkflowCenterWorkflowActionPreviewNoNesting
    }
  }
  ${WorkflowDefDtoNoNestingFragment}
  ${UserProfilePreviewDtoFragment}
  ${WorkflowCenterWorkflowActionPreviewNoNestingFragment}
`;
