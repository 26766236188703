import { NgModule } from '@angular/core';
import { NetworkStatusObservable } from '@t5s/mobile-client/provider-token/network-status';
import {
  PollingEndTriggerObservable,
  PollingStartTriggerObservable,
} from '@t5s/mobile-client/provider-token/polling-trigger';
import { ApplicationStateService, ServiceApplicationStateModule } from '@t5s/mobile-client/service/application-state';
import { combineLatest } from 'rxjs';
import { filter, mapTo } from 'rxjs/operators';

@NgModule({
  imports: [ServiceApplicationStateModule],
  providers: [
    {
      provide: PollingStartTriggerObservable,
      useFactory: (
        { appState$ }: ApplicationStateService,
        status$: NetworkStatusObservable,
      ): PollingStartTriggerObservable =>
        combineLatest([status$, appState$]).pipe(
          filter(([status, { isActive }]) => !!status?.connected && isActive),
          mapTo(true),
        ),
      deps: [ApplicationStateService, NetworkStatusObservable],
    },
    {
      provide: PollingEndTriggerObservable,
      useFactory: (
        { appState$ }: ApplicationStateService,
        status$: NetworkStatusObservable,
      ): PollingEndTriggerObservable =>
        combineLatest([status$, appState$]).pipe(
          filter(([status, { isActive }]) => !(!!status?.connected && isActive)),
          mapTo(false),
        ),
      deps: [ApplicationStateService, NetworkStatusObservable],
    },
  ],
})
export class PollingTriggerProviderModule {}
