import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { flexRow, fullWidth, getFontStyle, px } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { IconInterface } from '@t5s/mobile-client/asset';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { font, ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface BottomSheerIconTextMenuItemState {
  icon?: IconInterface;
  text?: string;

  fgColor?: string;
  iconFgColor?: string;
}

@Component({
  selector: 't5s-bottom-sheet-icon-text-menu-item',
  template: `
    <button
      [class]="containerClass"
      [t5sTouchActive]="'background: rgba(0, 0, 0, 0.07)'"
      (t5sPressDisableLongpress)="menuItemClick.emit()"
    >
      <!-- Icon -->
      <t5s-icon [style.margin-right.px]="16" [fgColor]="iconFgColor$" [icon]="icon$" [size]="24"></t5s-icon>

      <!-- Text -->
      <div [class]="textContainerClass$ | push">
        {{ text$ | push }}
      </div>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomSheetIconTextMenuItemComponent extends RxComponent<BottomSheerIconTextMenuItemState> {
  constructor() {
    super();

    this.set({
      fgColor: ThemeColorVar.darkest,
      iconFgColor: ThemeColorVar.darker,
    });
  }

  @Input() set icon(icon: IconInterface | Observable<IconInterface>) {
    this.setProperty('icon', icon);
  }

  @Input() set text(text: string | Observable<string>) {
    this.setProperty('text', text);
  }

  @Input() set fgColor(fgColor: string | Observable<string>) {
    this.setProperty('fgColor', fgColor);
  }

  @Input() set iconFgColor(iconFgColor: string | Observable<string>) {
    this.setProperty('iconFgColor', iconFgColor);
  }

  @Output() menuItemClick = new EventEmitter<never>();

  readonly containerClass = tss({
    ...fullWidth,
    ...flexRow,
    alignItems: 'center',
    height: px(56),
    paddingLeft: px(16),
    paddingRight: px(16),
  });

  readonly iconFgColor$ = this.select(
    selectSlice(['iconFgColor']),
    map(({ iconFgColor }) => iconFgColor),
  );

  readonly textContainerClass$ = this.state$.pipe(
    map(({ fgColor }) => tss({ color: fgColor ?? ThemeColorVar.darkest, ...getFontStyle(font['regular17px']) })),
  );

  readonly icon$ = this.select(
    selectSlice(['icon']),
    map(({ icon }) => icon),
  );

  readonly text$ = this.select(
    selectSlice(['text']),
    map(({ text }) => text),
  );
}
