import {
  SubscribeToBulkFollowUnfollowBlabItemsFailureEvent,
  SubscribeToBulkFollowUnfollowBlabItemsSuccessEvent,
} from '@t5s/shared/gql';
import { isDto } from './dto.type-guard';

export function isSubscribeToBulkFollowUnfollowBlabItemsSuccessEvent(
  candidate?: any,
): candidate is SubscribeToBulkFollowUnfollowBlabItemsSuccessEvent {
  return isDto<SubscribeToBulkFollowUnfollowBlabItemsSuccessEvent>(
    candidate,
    'SubscribeToBulkFollowUnfollowBlabItemsSuccessEvent',
  );
}

export function isSubscribeToBulkFollowUnfollowBlabItemsFailureEvent(
  candidate?: any,
): candidate is SubscribeToBulkFollowUnfollowBlabItemsFailureEvent {
  return isDto<SubscribeToBulkFollowUnfollowBlabItemsFailureEvent>(
    candidate,
    'SubscribeToUpdateBlabItemsFlimValFailureEvent',
  );
}
