import { CdkScrollable } from '@angular/cdk/scrolling';
import { isSameNode } from './element';

function getElScrollBottom({ scrollTop, scrollHeight, clientHeight }: Element) {
  const scrollOffset = scrollHeight - scrollTop;

  return Math.abs(scrollOffset - clientHeight);
}

/** Determines whether an element is fully scrolled (vertically). Threshold in pixel can be provided (default 1px). */
export function elScrolledToBottom(element: Element, threshold = 1): boolean {
  const scrollDiff = getElScrollBottom(element);

  return scrollDiff < threshold;
}

function getElScrollLeft({ scrollLeft, scrollWidth, clientWidth }: Element) {
  const scrollOffset = scrollWidth - scrollLeft;

  return Math.abs(scrollOffset - clientWidth);
}

/** Determines whether an element is fully scrolled (horizontally). Threshold in pixel can be provided (default 1px). */
export function elScrolledToRight(element: Element, threshold = 1): boolean {
  const scrollDiff = getElScrollLeft(element);

  return scrollDiff < threshold;
}

/** Determines whether an element is fully scrolled (horizontally). Threshold in pixel can be provided (default 1px). */
export function elScrolledToLeft({ scrollLeft }: Element, threshold = 1): boolean {
  return scrollLeft < threshold;
}

/** Determines whether an element is actually vertically scrollable (has overflow and thereby scrollbar) */
export function elScrollable({ scrollHeight, clientHeight }: Element): boolean {
  return scrollHeight > clientHeight;
}

/** Determines whether an element is actually horizontally scrollable (has overflow and thereby scrollbar) */
export function elScrollableH({ scrollWidth, clientWidth }: Element): boolean {
  return scrollWidth > clientWidth;
}

/** Extracts scrollTop property from a DOM mouse event. */
export function scrollTopFromEvent(event: Event): number | undefined {
  const target = event.target as Element | null;

  return target?.scrollTop;
}

/** Extracts scrollLeft property from a DOM mouse event. */
export function scrollLeftFromEvent(event: Event): number | undefined {
  const target = event.target as Element | null;

  return target?.scrollLeft;
}

/** Extracts scrollBottom from a DOM mouse event. */
export function scrollBottomFromEvent(event: Event): number | undefined {
  const target = event.target as Element | null;

  return target ? getElScrollBottom(target) : undefined;
}

/** Defensive scroll top with max. browser compatibility. */
export function scrollElToTop(element: Element, { behavior }: { behavior?: 'auto' | 'smooth' } = {}) {
  try {
    element.scrollTo({ top: 0, behavior });
  } catch (error: unknown) {
    element.scrollTop = 0;
  }
}

export function scrollElToTopCancelMomentumScroll(
  element: HTMLElement,
  { behavior }: { behavior?: 'auto' | 'smooth' } = {},
) {
  // Disable scrolling to cancel current momentum scroll on iOS
  element.style.setProperty('overflow', 'hidden');
  scrollElToTop(element, { behavior });
  // Re-enable scrolling again
  element.style.setProperty('overflow', 'auto');
}

/** Scrolls a scrollign container to its bottom (vertically). */
export function scrollElToBottom(element: Element) {
  element.scrollTop = element.scrollHeight;
}

/**
 * Given an array of CDK scrollable ancestors, get the one contained by all others
 * @deprecated
 */
export function getClosestCdkScrollAnscestor(ancestors: CdkScrollable[]): HTMLElement | undefined {
  // Choose the ancestor that is included by all other ancestors -> closest one
  let ref: HTMLElement | undefined;
  const ancestorEls = ancestors.map((a) => a.getElementRef().nativeElement);
  for (const ancestorEl of ancestorEls) {
    let parentEl: Node | null = ancestorEl;
    let numAncestorsMatched = 0;

    while (numAncestorsMatched < ancestorEls.length - 1 && !!parentEl) {
      parentEl = parentEl.parentNode;
      if (ancestorEls.find((el) => isSameNode(parentEl, el))) {
        numAncestorsMatched++;
      }
    }

    if (numAncestorsMatched === ancestorEls.length - 1) {
      ref = ancestorEl;
      break;
    }
  }

  ref = ref ?? ancestorEls[ancestorEls.length - 1];
  return ref;
}

export function getClosestScrollAncestor(el: HTMLElement): HTMLElement | undefined {
  let parentEl: Node | null | undefined = el;
  while (parentEl) {
    if (parentEl instanceof HTMLElement && parentEl.dataset.cdkScrollable) {
      return parentEl;
    }
    parentEl = parentEl.parentNode;
  }

  return undefined;
}
