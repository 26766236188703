import { BlabItemDisplayClientModel } from '@t5s/mobile-client/value-object/blab-item-display';
import { BlabItemActivitySubscriptionUpdate, BlabItemActivityUnionType } from '@t5s/shared/gql';
import { TypeGuards } from '@t5s/shared/gql-type-guards/common';
import { replaceWhere } from '@t5s/shared/util/array';

export function getBlabItemAfterBlabItemActivityLiveUpdate(
  blabItem: BlabItemDisplayClientModel,
  update: BlabItemActivitySubscriptionUpdate,
): BlabItemDisplayClientModel {
  const { blabItemActivity } = blabItem;

  if (!blabItemActivity) {
    return blabItem;
  }

  let { blabItemActivities } = blabItemActivity;

  if (TypeGuards.isBlabItemActivityDeletedDto(update)) {
    blabItemActivities = blabItemActivities.filter((reply) => reply.node.id !== update.id);
    return { ...blabItem, blabItemActivity: { ...blabItemActivity, blabItemActivities } };
  }

  const activityToUpsert = update as BlabItemActivityUnionType;
  const activityEdgeToUpsert = { node: activityToUpsert, cursor: '' };
  if (blabItemActivities.find((reply) => reply.node.id === activityToUpsert.id)) {
    blabItemActivities = replaceWhere(
      blabItemActivities,
      (reply) => reply.node.id === activityToUpsert.id,
      activityEdgeToUpsert,
    );
  } else {
    blabItemActivities = [...blabItemActivities, activityEdgeToUpsert];
  }

  return { ...blabItem, blabItemActivity: { ...blabItemActivity, blabItemActivities } };
}
