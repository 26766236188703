import gql from 'graphql-tag';
import {
  BlabDefNotificationDtoNoNestingFragment,
  BlabDefPreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { BlabDefNotificationActivityDtoFragment } from './blab-def-notification-activity-dto.fragment';

export const BlabDefNotificationDtoFragment = gql`
  fragment BlabDefNotificationDtoFragment on BlabDefNotificationDto {
    ...BlabDefNotificationDtoNoNesting
    blabDefId
    blabDef {
      ...BlabDefPreviewDtoNoNesting
    }
    activities(first: 1) {
      ...BlabDefNotificationActivityDtoFragment
    }
  }
  ${BlabDefPreviewDtoNoNestingFragment}
  ${BlabDefNotificationDtoNoNestingFragment}
  ${BlabDefNotificationActivityDtoFragment}
`;
