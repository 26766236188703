import gql from 'graphql-tag';
import {
  CreatedByFlimValDtoNoNestingFragment,
  WorkflowDefPreviewDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';
import { UserProfilePreviewDtoFragment } from '../../user-profile';

export const CreatedByFlimValFragment = gql`
  fragment CreatedByFlimVal on CreatedByFlimValDto {
    ...CreatedByFlimValDtoNoNesting
    createdByUser {
      ...UserProfilePreviewDtoFragment
    }
    createdByWorkflowDef {
      ...WorkflowDefPreviewDtoNoNesting
    }
  }
  ${CreatedByFlimValDtoNoNestingFragment}
  ${UserProfilePreviewDtoFragment}
  ${WorkflowDefPreviewDtoNoNestingFragment}
`;
