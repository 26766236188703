import { Injectable, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectSlice } from '@rx-angular/state/selections';
import { selectActiveOutlet } from '@t5s/mobile-client/business-logic/logged-in-root';
import { selectModalOpen } from '@t5s/mobile-client/business-logic/root';
import { NavigationBarItem } from '@t5s/mobile-client/value-object/logged-in-root';
import { Observable } from 'rxjs';
import { map, startWith, switchMap } from 'rxjs/operators';
import { selectDeviceInfo } from '@t5s/mobile-client/business-logic/device';
import { KeyboardActions } from '@t5s/mobile-client/business-logic/keyboard';
import { PushablePage, PushablePageState } from './pushable-page';

export interface LoggedInPushablePageState extends PushablePageState {
  platform: 'android' | 'ios' | 'web';
  outlet: NavigationBarItem;
  outletActive: boolean;
  modalOpen: boolean;
}

@Injectable()
export abstract class LoggedInPushablePage<
  T extends LoggedInPushablePageState = LoggedInPushablePageState,
> extends PushablePage<T> {
  private readonly _store$: Store; // Do not occupy store$ namespace because then children cannot do "private readonly store$"

  constructor(store$: Store) {
    super();
    this._store$ = store$;

    // Platform
    this.connect(store$.select(selectDeviceInfo).pipe(map((info) => ({ platform: info?.platform } as T))));

    // Active outlet
    this.hold(
      this.select(
        selectSlice(['outlet']),
        switchMap(({ outlet }) =>
          store$.select(selectActiveOutlet).pipe(map((activeOutlet) => activeOutlet === outlet)),
        ),
      ),
      (outletActive) => {
        this.set({ outletActive } as T);
      },
    );

    // Modal open
    this.connect(store$.select(selectModalOpen).pipe(map((modalOpen) => ({ modalOpen } as T))));
  }

  @Input() set outlet(outlet: NavigationBarItem | Observable<NavigationBarItem>) {
    this.setProperty('outlet', outlet as any);
  }

  readonly visible$ = this.select(
    selectSlice(['platform', 'entered', 'outletActive', 'modalOpen']),
    map(
      ({ platform, entered, outletActive, modalOpen }) =>
        entered && outletActive && (platform !== 'android' || !modalOpen),
    ),
    startWith(false),
  );

  ionViewWillEnter() {
    super.ionViewWillEnter();
    this._store$.dispatch(KeyboardActions.hide());
  }

  ionViewWillLeave() {
    super.ionViewWillLeave();
    this._store$.dispatch(KeyboardActions.hide());
  }
}
