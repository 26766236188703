export enum ConsoleStatementLevel {
  LOG,
  INFO,
  WARN,
  ERROR,
}

export interface ConsoleStatement {
  level: ConsoleStatementLevel;
  content: string;
  createdAt: string;
}
