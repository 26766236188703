import gql from 'graphql-tag';
import {
  BlabDefPreviewDtoNoNestingFragment,
  CategoryFlimDefRevisionPreviewDtoNoNestingFragment,
  CategoryOptionDefRevisionPreviewDtoNoNestingFragment,
  FlimDefRevisionPreviewDtoNoNestingFragment,
  NumberFlimDefRevisionPreviewDtoNoNestingFragment,
  StatusFlimDefRevisionPreviewDtoNoNestingFragment,
  StatusOptionDefRevisionPreviewDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const FlimDefRevisionPreviewUnionTypeFragment = gql`
  fragment FlimDefRevisionPreviewUnionTypeFragment on FlimDefRevisionPreviewUnionType {
    ... on FlimDefRevisionPreviewDto {
      ...FlimDefRevisionPreviewDtoNoNesting
      blabDef {
        ...BlabDefPreviewDtoNoNesting
      }
    }
    ... on CategoryFlimDefRevisionPreviewDto {
      ...CategoryFlimDefRevisionPreviewDtoNoNesting
      categoryOptionDefs {
        ...CategoryOptionDefRevisionPreviewDtoNoNesting
      }
    }
    ... on StatusFlimDefRevisionPreviewDto {
      ...StatusFlimDefRevisionPreviewDtoNoNesting
      statusOptionDefs {
        ...StatusOptionDefRevisionPreviewDtoNoNesting
      }
    }

    ... on NumberFlimDefRevisionPreviewDto {
      ...NumberFlimDefRevisionPreviewDtoNoNesting
    }
  }
  ${CategoryOptionDefRevisionPreviewDtoNoNestingFragment}
  ${FlimDefRevisionPreviewDtoNoNestingFragment}
  ${CategoryFlimDefRevisionPreviewDtoNoNestingFragment}
  ${StatusFlimDefRevisionPreviewDtoNoNestingFragment}
  ${BlabDefPreviewDtoNoNestingFragment}
  ${NumberFlimDefRevisionPreviewDtoNoNestingFragment}
  ${StatusOptionDefRevisionPreviewDtoNoNestingFragment}
`;
