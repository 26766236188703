import {
  EmojiCategories,
  EMOJI_PICKER_EMOJI_MARGIN,
  EMOJI_PICKER_PADDING_PX,
  NUM_EMOJIS_PER_ROW,
} from '@t5s/mobile-client/readonly-constant/emoji-picker';
import { EmojiPickerCategory, EmojiPickerRow } from '@t5s/mobile-client/value-object/emoji-picker';

export interface CategoryIndex {
  index: number;
  category: EmojiPickerCategory;
}

export function getStaticEmojiRows(): {
  categoryIndices: CategoryIndex[];
  rows: EmojiPickerRow[];
} {
  const categoryIndices: CategoryIndex[] = [];
  const rows: EmojiPickerRow[] = [];
  let id = 0;

  for (const category of EmojiCategories) {
    rows.push({
      id,
      category: category.id as EmojiPickerCategory,
    });
    categoryIndices.push({
      index: id,
      category: category.id as EmojiPickerCategory,
    });
    id++;

    const emojis = category.emojis ?? [];

    for (let i = 0; i < Math.ceil(emojis.length / NUM_EMOJIS_PER_ROW); i++) {
      rows.push({
        id,
        emojis: emojis.slice(i * NUM_EMOJIS_PER_ROW, (i + 1) * NUM_EMOJIS_PER_ROW),
      });
      id++;
    }
  }

  // These are for bottom padding e.g. to cover safe-area bottom on iOs
  rows.push({ id });
  id++;
  rows.push({ id });

  return { rows, categoryIndices };
}

export function getFrequentlyUsedEmojiRows(emojis: string[]): {
  categoryIndices: CategoryIndex[];
  rows: EmojiPickerRow[];
} {
  if (emojis.length === 0) {
    return { rows: [], categoryIndices: [] };
  }

  const rows: EmojiPickerRow[] = [];
  let id = 0;

  rows.push({
    id,
    category: EmojiPickerCategory.FREQUENT,
  });
  id++;

  for (let i = 0; i < Math.ceil(emojis.length / NUM_EMOJIS_PER_ROW); i++) {
    rows.push({
      id,
      emojis: emojis.slice(i * NUM_EMOJIS_PER_ROW, (i + 1) * NUM_EMOJIS_PER_ROW),
    });
    id++;
  }

  return { rows, categoryIndices: [{ index: 0, category: EmojiPickerCategory.FREQUENT }] };
}

export function getEmojiBtnSize(width: number): number {
  return (
    (width - 2 * EMOJI_PICKER_PADDING_PX - (NUM_EMOJIS_PER_ROW - 1) * EMOJI_PICKER_EMOJI_MARGIN) / NUM_EMOJIS_PER_ROW
  );
}

export function getNumFrequentlyUsedEmojiRows(numEmojis: number): number {
  return Math.ceil(numEmojis / NUM_EMOJIS_PER_ROW) + 1;
}
