import gql from 'graphql-tag';
import { BlabDefSearchResultFragment } from '../blab-def-search';
import { BlabItemSearchResultFragment } from '../blab-item-search';
import { WorkspaceSearchResultFragment } from '../workspace-search';
import { UserSearchResultFragment } from './user-search-result.fragment';

export const GlobalSearchResultFragment = gql`
  fragment GlobalSearchResult on SearchResultDto {
    type
    ... on BlabDefSearchResultDto {
      ...BlabDefSearchResult
    }
    ... on BlabItemSearchResultDto {
      ...BlabItemSearchResult
    }
    ... on UserSearchResultDto {
      ...UserSearchResult
    }
    ... on WorkspaceSearchResultDto {
      ...WorkspaceSearchResult
    }
  }
  ${BlabDefSearchResultFragment}
  ${BlabItemSearchResultFragment}
  ${UserSearchResultFragment}
  ${WorkspaceSearchResultFragment}
`;
