import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ComponentStyle,
  eachChild,
  exactHeight,
  exactWidth,
  firstChild,
  flex1Vertical,
  flexCenter,
  flexCenterHorizontal,
  flexCenterVertical,
  flexRow,
  fullWidth,
  getFontStyle,
  notLastChild,
  px,
  spread,
} from '@t5s/client/ui/style/common';
import { EmojiService } from '@t5s/client/util/emoji-mart';
import { tss } from '@t5s/client/util/tss';
import { I18nObject } from '@t5s/mobile-client/i18n/common';
import { EmojiPickerI18n } from '@t5s/mobile-client/i18n/emoji-picker';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
import { ViewportDimensionsObservable } from '@t5s/mobile-client/provider-token/viewport-dimensions';
import { EMOJI_PICKER_EMOJI_MARGIN, EMOJI_PICKER_PADDING_PX } from '@t5s/mobile-client/readonly-constant/emoji-picker';
import { BottomSheetComponent } from '@t5s/mobile-client/ui/component/bottom-sheet';
import { selectSlice } from '@t5s/mobile-client/ui/component/common';
import { ScrollContainerComponent } from '@t5s/mobile-client/ui/component/scroll-container';
import { font, ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { ViewComponent, ViewState } from '@t5s/mobile-client/ui/view/common';
import {
  getEmojiBtnSize,
  getFrequentlyUsedEmojiRows,
  getNumFrequentlyUsedEmojiRows,
  getStaticEmojiRows,
} from '@t5s/mobile-client/util/emoji-picker';
import {
  EmojiData,
  EmojiPickerCategory,
  EmojiPickerPosition,
  EmojiSearchResult,
} from '@t5s/mobile-client/value-object/emoji-picker';
import { throttleTimeEmitInstantlyWithTrailing } from '@t5s/shared/util/rxjs';
import { EMPTY, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';

// https://material.angular.io/cdk/scrolling/overview

export interface EmojiPickerViewState extends ViewState {
  position: EmojiPickerPosition;
  scrolled: boolean;
  scrolledIndex: number;
  width: number;

  searchMode: boolean;
  searchQuery?: string;
  searchResults?: EmojiSearchResult[];

  frequentlyUsedEmojis: string[];
}

@Component({
  selector: 't5s-emoji-picker-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostSpread],
  template: `
    <t5s-bottom-sheet [gestureDisabled]="gestureDisabled$" (positionChange)="set($event)">
      <t5s-flex-column>
        <!-- Search header -->
        <t5s-emoji-picker-search-header
          [position]="position$"
          [searchQuery]="searchQuery$"
          [searchMode]="searchMode$"
          (searchQueryChange)="searchQueryChange.emit($event)"
          (searchInputFocused)="searchModeChange.emit({ searchMode: true })"
          (cancelSearchClick)="searchModeChange.emit({ searchMode: false })"
          (clearSearchClick)="clearSearchClick.emit()"
        ></t5s-emoji-picker-search-header>

        <div [class]="contentWrapperClass">
          <!-- All emojis container -->
          <ng-container *ngIf="i18n$ | push as i18n">
            <ng-container *ngIf="emojiBtnSize$ | push as emojiBtnSize">
              <ng-container *ngIf="Math.min(28, emojiBtnSize * 0.6) as emojiSize">
                <cdk-virtual-scroll-viewport
                  [class]="scrollContainerClass$ | push"
                  [style.opacity]="(searchMode$ | push) ? 0 : 1"
                  [style.overflow-y]="(canScroll$ | push) === true ? 'auto' : 'hidden'"
                  [itemSize]="emojiBtnSize"
                  (scrolledIndexChange)="set({ scrolledIndex: $event, scrolled: $event > 0 })"
                >
                  <ng-container *cdkVirtualFor="let row of emojiRows$ | push; trackBy: trackById">
                    <div>
                      <ng-container *ngIf="row.emojis; else categoryLabel">
                        <ng-container *ngFor="let id of row.emojis">
                          <ngx-emoji
                            class="emoji"
                            [t5sTouchActive]="'background: rgba(0, 0, 0, 0.07)'"
                            (t5sPressDisableLongpress)="emitEmojiClick({ id: id })"
                            [emoji]="id"
                            [size]="emojiSize"
                            [isNative]="true"
                          ></ngx-emoji>
                        </ng-container>
                      </ng-container>

                      <ng-template #categoryLabel>
                        <ng-container *ngIf="row.category as category">
                          <div class="category">{{ getCategoryLabel(i18n, category) }}</div>
                        </ng-container>
                      </ng-template>
                    </div>
                  </ng-container>
                </cdk-virtual-scroll-viewport>
              </ng-container>
            </ng-container>

            <ng-container *ngIf="searchMode$ | push; else navigationBar">
              <div [class]="searchResultsContainerClass">
                <!-- Emoji search container -->
                <t5s-scroll-container
                  [scrollY]="canScroll$"
                  [bgColor]="Color.lightest"
                  [splitBgColor]="[Color.lightest, Color.lightest]"
                  (scrollTop$)="set({ scrolled: ($event || 0) > 0 })"
                >
                  <ng-container *ngIf="searchResults$ | push as searchResults">
                    <ng-container *ngIf="searchResults.length > 0; else searchPlaceholder">
                      <!-- Search results -->
                      <ng-container *ngFor="let searchResult of searchResults; trackBy: trackById">
                        <t5s-emoji-picker-search-result
                          [searchResult]="searchResult"
                          (emojiClick)="emitEmojiClick({ id: $event.id })"
                        ></t5s-emoji-picker-search-result>
                      </ng-container>

                      <t5s-divider [height]="75"></t5s-divider>
                    </ng-container>

                    <!-- Search placeholder -->
                    <ng-template #searchPlaceholder>
                      <t5s-placeholder-light>
                        {{ I18n.translate(i18n, I18n.key.search.notFoundPlaceholder) }}
                      </t5s-placeholder-light>
                    </ng-template>
                  </ng-container>
                </t5s-scroll-container>
              </div>
            </ng-container>

            <ng-template #navigationBar>
              <!-- Category navigation bar -->
              <div [class]="navigationBarContainerClass">
                <t5s-emoji-picker-category-navigation-bar
                  [selected]="selectedCategory$"
                  (categoryClick)="scrollToCategory($event)"
                ></t5s-emoji-picker-category-navigation-bar>
              </div>
            </ng-template>
          </ng-container>
        </div>
      </t5s-flex-column>
    </t5s-bottom-sheet>
  `,
})
export class EmojiPickerViewComponent extends ViewComponent<EmojiPickerViewState> implements AfterViewInit {
  readonly I18n = EmojiPickerI18n;
  readonly Math = Math;
  readonly staticEmojiRows = getStaticEmojiRows();

  constructor(
    i18n$: I18nObjectObservable,
    viewport$: ViewportDimensionsObservable,
    private readonly emojiService: EmojiService,
  ) {
    super(i18n$);
    this.set({ scrolled: false, frequentlyUsedEmojis: [], scrolledIndex: 0 });
    this.connect(viewport$);

    // Set render range on docked and viewport size on top
    this.hold(this.select(selectSlice(['position'])), ({ position }) => {
      if (position === EmojiPickerPosition.IS_TOP) {
        this.scrollContainer?.checkViewportSize();
      } else if (position === EmojiPickerPosition.IS_DOCKED) {
        this.scrollContainer?.setRenderedRange({ start: 0, end: 15 });
      } else if (position === EmojiPickerPosition.IS_BOTTOM) {
        this.scrollContainer?.scrollToIndex(0);
      }
    });

    // Reset scroll on searchMode change
    this.hold(this.select(selectSlice(['searchMode']), debounceTime(300)), ({ searchMode }) => {
      this.set({ scrolled: false });
      if (searchMode) {
        this.scrollContainer?.scrollToIndex(0);
      } else {
        this.searchResultsScrollContainer?.scrollToTop(0);
      }
    });

    // Reset scroll on search results change
    this.hold(
      this.select(
        selectSlice(['searchResults']),
        switchMap(() => this.searchResultsScrollContainer?.scrollToTop(0) ?? EMPTY),
      ),
    );
  }

  @ViewChild(BottomSheetComponent) sheet?: BottomSheetComponent;
  @ViewChild(CdkVirtualScrollViewport) scrollContainer?: CdkVirtualScrollViewport;
  @ViewChild(ScrollContainerComponent) searchResultsScrollContainer?: ScrollContainerComponent;

  @Input() set searchQuery(searchQuery: string | Observable<string>) {
    this.setProperty('searchQuery', searchQuery);
  }

  @Input() set searchMode(searchMode: boolean | Observable<boolean>) {
    this.setProperty('searchMode', searchMode);
  }

  @Input() set searchResults(searchResults: EmojiSearchResult[] | Observable<EmojiSearchResult[]>) {
    this.setProperty('searchResults', searchResults);
  }

  @Input() set frequentlyUsedEmojis(frequentlyUsedEmojis: string[] | Observable<string[]>) {
    this.setProperty('frequentlyUsedEmojis', frequentlyUsedEmojis);
  }

  @Output() emojiClick = new EventEmitter<EmojiData>();
  @Output() positionChange: Observable<{ position: EmojiPickerPosition }> = this.select(selectSlice(['position']));
  @Output() searchQueryChange = new EventEmitter<{ searchQuery: string }>();
  @Output() searchModeChange = new EventEmitter<{ searchMode: boolean }>();
  @Output() clearSearchClick = new EventEmitter<never>();

  readonly position$ = this.select(
    selectSlice(['position']),
    map(({ position }) => position),
  );

  readonly searchQuery$ = this.select(
    selectSlice(['searchQuery']),
    map(({ searchQuery }) => searchQuery),
  );

  readonly searchMode$ = this.select(
    selectSlice(['searchMode']),
    map(({ searchMode }) => searchMode),
  );

  readonly searchResults$ = this.select(
    selectSlice(['searchResults']),
    map(({ searchResults }) => searchResults),
  );

  readonly emojiRows$ = this.select(
    selectSlice(['frequentlyUsedEmojis']),
    map(({ frequentlyUsedEmojis }) => {
      const rows = [];
      rows.push(...getFrequentlyUsedEmojiRows(frequentlyUsedEmojis).rows);
      rows.push(...this.staticEmojiRows.rows);
      return rows;
    }),
  );

  readonly emojiBtnSize$ = this.select(
    selectSlice(['width']),
    map(({ width }) => getEmojiBtnSize(width)),
  );

  readonly selectedCategory$ = this.select(
    selectSlice(['frequentlyUsedEmojis']),
    map(({ frequentlyUsedEmojis }) => frequentlyUsedEmojis.length),
    distinctUntilChanged(),
    map((numFreqEmojis) => this.getCategoryIndices(numFreqEmojis)),
    switchMap((indices) =>
      this.select(
        selectSlice(['scrolledIndex']),
        throttleTimeEmitInstantlyWithTrailing(250),
        map(({ scrolledIndex }) => {
          let pos = indices.findIndex(({ index }) => index - 1 >= scrolledIndex);
          pos = pos === -1 ? indices.length : pos;
          return indices[Math.max(0, pos - 1)].category;
        }),
      ),
    ),
  );

  // For maximum performance no extra component for rows, otherwise iOS not as smooth on scrolling
  readonly scrollContainerClass$ = this.emojiBtnSize$.pipe(
    map((emojiBtnSize) =>
      tss({
        ...spread,
        overflowX: 'hidden',
        ...firstChild({
          paddingLeft: px(EMOJI_PICKER_PADDING_PX),
          paddingRight: px(EMOJI_PICKER_PADDING_PX),
          ...eachChild({
            ...flexRow,
            ...fullWidth,
            ...exactHeight(Math.floor(emojiBtnSize)),
            '.emoji': {
              ...exactHeight(Math.floor(emojiBtnSize)),
              ...exactWidth(emojiBtnSize),
              borderRadius: px(10),
              ...flexCenter,
            },
            '.category': {
              ...getFontStyle(font.regular17px),
              color: ThemeColorVar.darker,
              ...flexCenterVertical,
              paddingLeft: px(12), // 24
            },
          }),
          ...notLastChild({
            '.emoji': {
              marginRight: px(EMOJI_PICKER_EMOJI_MARGIN),
            },
          }),
        }),
      }),
    ),
  );

  readonly btnSize$ = this.select(
    selectSlice(['width']),
    map(({ width }) => getEmojiBtnSize(width)),
  );

  readonly canScroll$ = this.select(
    selectSlice(['position']),
    map(({ position }) => position === EmojiPickerPosition.IS_TOP),
    distinctUntilChanged(),
  );

  readonly gestureDisabled$ = this.select(
    selectSlice(['scrolled']),
    map(({ scrolled }) => scrolled),
    distinctUntilChanged(),
  );

  readonly contentWrapperClass = tss({
    ...flex1Vertical,
    position: 'relative',
    marginTop: px(1),
  });

  readonly searchResultsContainerClass = tss({
    position: 'absolute',
    ...spread,
    top: px(0),
    left: px(0),
    backgroundColor: ThemeColorVar.lightest,
  });

  readonly navigationBarContainerClass = tss({
    position: 'absolute',
    bottom: px(24),
    left: px(0),
    pointerEvents: 'none',
    ...fullWidth,
    ...flexCenterHorizontal,
    ...firstChild({
      pointerEvents: 'initial',
    }),
  });

  emitEmojiClick({ id }: { id: string }) {
    const data = this.emojiService.getData(id);
    if (data?.native && data.id) {
      this.emojiClick.emit({ id: data.id, emoji: data.native });
      this.close();
    }
  }

  getCategoryLabel(i18n: I18nObject, category: string) {
    return this.I18n.translate(i18n, (this.I18n.key.category as any)[category]);
  }

  private getCategoryIndices(numFrequentlyUsedEmojis: number) {
    const indexOffset = getNumFrequentlyUsedEmojiRows(numFrequentlyUsedEmojis);

    return [
      {
        category: EmojiPickerCategory.FREQUENT,
        index: 0,
      },
      ...this.staticEmojiRows.categoryIndices.map((catInfo) => ({
        ...catInfo,
        index: catInfo.index + indexOffset,
      })),
    ];
  }

  scrollToCategory({ category }: { category: EmojiPickerCategory }) {
    if (category === EmojiPickerCategory.FREQUENT) {
      this.scrollContainer?.scrollToIndex(0);
      return;
    }

    const categoryInfo = this.staticEmojiRows.categoryIndices.find((info) => info.category === category);
    if (!categoryInfo) {
      return;
    }

    const { frequentlyUsedEmojis } = this.get();
    const indexOffset = getNumFrequentlyUsedEmojiRows(frequentlyUsedEmojis.length);

    this.scrollContainer?.scrollToIndex(categoryInfo.index + indexOffset + 1);
  }

  open() {
    this.sheet?.open();
  }

  expand() {
    this.sheet?.expand();
  }

  close() {
    this.sheet?.close();
  }

  ngAfterViewInit() {
    this.scrollContainer?.setRenderedRange({ start: 0, end: 5 });
  }
}
