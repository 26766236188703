import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { formatDate, formatTime, getDateTimeFormats, isApplicableI18n, normalizeDate } from '../common';

export function formatInputDateTimeRange(
  i18n: SharedI18n,
  dateLeft: string | Date | undefined,
  dateRight: string | Date | undefined,
): string | undefined {
  if (!dateLeft || !dateRight || !isApplicableI18n(i18n)) {
    return undefined;
  }

  dateLeft = normalizeDate(dateLeft);
  dateRight = normalizeDate(dateRight);

  const dateTimeFormats = getDateTimeFormats(i18n);
  const dateFormat = dateTimeFormats.date.short;
  const timeFormat = dateTimeFormats.time.normalized;

  const formattedDateLeft = formatDate(dateLeft, dateFormat, i18n);
  const formattedTimeLeft = formatTime(dateLeft, timeFormat, i18n);
  const formattedDateRight = formatDate(dateRight, dateFormat, i18n);
  const formattedTimeRight = formatTime(dateRight, timeFormat, i18n);

  const formattedLeft = `${formattedDateLeft}, ${formattedTimeLeft}`;
  const formattedRight = `${formattedDateRight}, ${formattedTimeRight}`;

  return `${formattedLeft} - ${formattedRight}`;
}
