import gql from 'graphql-tag';
import {
  BlabItemPreviewDtoNoNestingFragment,
  WorkflowDefPreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { ReactionDtoFragment } from '../core';
import { UserProfilePreviewDtoFragment } from '../user-profile';

/**
 * Fragment containing all common blabItem activity properties of the BlabItemActivityDto interface
 * This fragment is used as a baseline for the activity-type specific fragments
 */
export const BlabItemActivityDtoFragment = gql`
  fragment BlabItemActivityDtoFragment on BlabItemActivityDto {
    id
    type
    createdAt
    author {
      ...UserProfilePreviewDtoFragment
    }
    authorId
    authorWorkflowDefId
    authorWorkflowDef {
      ...WorkflowDefPreviewDtoNoNesting
    }
    reactions {
      ...ReactionDtoFragment
    }
    numReplies
    blabItemId
    blabItem {
      ...BlabItemPreviewDtoNoNesting
    }
  }
  ${BlabItemPreviewDtoNoNestingFragment}
  ${UserProfilePreviewDtoFragment}
  ${ReactionDtoFragment}
  ${WorkflowDefPreviewDtoNoNestingFragment}
`;
