import gql from 'graphql-tag';
import { BlabDefDtoNoNestingFragment, WorkspaceDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { LightFlimDefFragment } from '../flim-def';

export const LightBlabDefDtoFragment = gql`
  fragment LightBlabDefDto on BlabDefDto {
    ...BlabDefDtoNoNesting
    workspace {
      ...WorkspaceDtoNoNesting
    }
    flimDefs {
      ...LightFlimDef
    }
  }
  ${BlabDefDtoNoNestingFragment}
  ${LightFlimDefFragment}
  ${WorkspaceDtoNoNestingFragment}
`;
