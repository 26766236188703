import gql from 'graphql-tag';
import {
  WorkflowDefBlabDefLookupDtoNoNestingFragment,
  WorkflowDefLookupBlabDefRelationDtoNoNestingFragment,
  WorkflowDefWorkspaceLookupDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

import { FullFlimDefFragment } from '../flim-def/full-flim-def.fragment';

export const BlabDefRelationsWithFlimDefsDtoFragment = gql`
  fragment BlabDefRelationsWithFlimDefsDtoFragment on BlabDefRelationsWithFlimDefsDto {
    workspaces {
      ...WorkflowDefWorkspaceLookupDtoNoNesting
    }
    flimDefs {
      ...FullFlimDef
    }
    blabDefRelations {
      ...WorkflowDefLookupBlabDefRelationDtoNoNesting
    }
    blabDefs {
      ...WorkflowDefBlabDefLookupDtoNoNesting
    }
  }
  ${WorkflowDefLookupBlabDefRelationDtoNoNestingFragment}
  ${WorkflowDefBlabDefLookupDtoNoNestingFragment}
  ${WorkflowDefWorkspaceLookupDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
