import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import {
  NetworkIsConnectedObservable,
  NetworkOfflineObservable,
  NetworkOnlineObservable,
  NetworkReconnectedObservable,
  NetworkStatusObservable,
} from '@t5s/mobile-client/provider-token/network-status';
import { ServiceNetworkModule } from '@t5s/mobile-client/service/network';
import { filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { NetworkStatusEffects } from './network-status.effects';
import { networkStatusReducer, networkStatusStateKey } from './network-status.reducer';
import { selectNetworkStatus } from './network-status.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(networkStatusStateKey, networkStatusReducer),
    EffectsModule.forFeature([NetworkStatusEffects]),
    ServiceNetworkModule,
  ],
  providers: [
    {
      provide: NetworkStatusObservable,
      useFactory: (store$: Store) => store$.select(selectNetworkStatus),
      deps: [Store],
    },
    {
      provide: NetworkIsConnectedObservable,
      useFactory: (networkStatus$: NetworkStatusObservable): NetworkIsConnectedObservable =>
        networkStatus$.pipe(
          filterOnlyPresent(),
          map(({ connected }) => connected),
        ),
      deps: [NetworkStatusObservable],
    },
    {
      provide: NetworkOnlineObservable,
      useFactory: (networkStatus$: NetworkStatusObservable): NetworkOnlineObservable =>
        networkStatus$.pipe(
          filterOnlyPresent(),
          filter(({ connected }) => connected === true),
        ),
      deps: [NetworkStatusObservable],
    },
    {
      provide: NetworkOfflineObservable,
      useFactory: (networkStatus$: NetworkStatusObservable): NetworkOfflineObservable =>
        networkStatus$.pipe(
          filterOnlyPresent(),
          filter(({ connected }) => connected === false),
        ),
      deps: [NetworkStatusObservable],
    },
    {
      provide: NetworkReconnectedObservable,
      useFactory: (
        networkOnline$: NetworkOnlineObservable,
        networkOffline$: NetworkOfflineObservable,
      ): NetworkReconnectedObservable => networkOffline$.pipe(switchMap(() => networkOnline$.pipe(first()))),
      deps: [NetworkOnlineObservable, NetworkOfflineObservable],
    },
  ],
})
export class BusinessLogicNetworkStatusModule {}
