import { NgModule } from '@angular/core';
import { EmojiModule } from '@t5s/client/util/emoji-mart';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { TextComponentModule } from '@t5s/mobile-client/ui/component/text';
import { AssetComponentModule } from '@t5s/mobile-client/ui/component/asset';
import { FormsModule } from '@angular/forms';
import { LinkComponentModule } from '@t5s/mobile-client/ui/component/link';
import { DividerComponentModule } from '@t5s/mobile-client/ui/component/divider';
import { EmojiPickerSearchHeaderComponent } from './emoji-picker-search-header.component';
import { EmojiPickerSearchResultComponent } from './emoji-picker-search-result.component';
import { EmojiPickerCategoryNavigationBarComponent } from './emoji-picker-category-navigation-bar.component';

const components = [
  EmojiPickerSearchHeaderComponent,
  EmojiPickerSearchResultComponent,
  EmojiPickerCategoryNavigationBarComponent,
  EmojiPickerCategoryNavigationBarComponent,
];

@NgModule({
  imports: [
    ComponentCommonModule,
    FormsModule,
    LinkComponentModule,
    EmojiModule,
    TextComponentModule,
    DividerComponentModule,
    AssetComponentModule,
  ],
  declarations: components,
  exports: components,
})
export class EmojiPickerComponentModule {}
