import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { BrowserService } from '@t5s/mobile-client/service/browser';
import { BlabItemDisplayBlabItemDetail } from '@t5s/mobile-client/value-object/blab-item-display';
import {
  GqlActiveUserBlabItemService,
  GqlBlabItemActivityService,
  GqlBlabItemIncomingRelationService,
  GqlBlabItemService,
} from '@t5s/shared/gql-services';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { isGqlClientErrorItemUnavailable } from '@t5s/client/util/error';
import { BlabItemDisplayActions } from './blab-item-display.actions';

const BLAB_ITEM_ACTIVTIY_PAGE_SIZE = 20;
const BLAB_ITEM_INCOMING_FLIM_DEFS_PAGE_SIZE = 20;

@Injectable()
export class BlabItemDisplayEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly blabItemService: GqlBlabItemService,
    private readonly gqlBlabItemActivityService: GqlBlabItemActivityService,
    private readonly gqlIncomingRelationService: GqlBlabItemIncomingRelationService,
    private readonly gqlActiveUserBlabItemService: GqlActiveUserBlabItemService,
    private readonly browserService: BrowserService,
  ) {}

  readonly loadBlabItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.loadBlabItem),
      mergeMap(({ blabItemId }) =>
        this.blabItemService.getBlabItem(blabItemId).pipe(
          map((blabItem) =>
            BlabItemDisplayActions.loadBlabItemSuccess({
              blabItemId,
              blabItem: blabItem as BlabItemDisplayBlabItemDetail,
            }),
          ),
          catchError((error) => {
            if (isGqlClientErrorItemUnavailable(error)) {
              return of(BlabItemDisplayActions.loadBlabItemRejectedNotAvailable({ blabItemId }));
            }

            return of(BlabItemDisplayActions.loadBlabItemException({ error, blabItemId }));
          }),
        ),
      ),
    ),
  );

  readonly reloadBlabItemTitle$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.reloadBlabItemTitle),
      mergeMap(({ blabItemId }) =>
        this.blabItemService.getBlabItem(blabItemId).pipe(
          map((blabItem) =>
            BlabItemDisplayActions.reloadBlabItemTitleSuccess({
              blabItemId,
              blabItem,
            }),
          ),
          catchErrorDispatch(BlabItemDisplayActions.reloadBlabItemTitleException),
        ),
      ),
    ),
  );

  readonly loadBlabItemLoadActiveUserBlabItemInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.loadBlabItem),
      map(BlabItemDisplayActions.loadActiveUserBlabItemInfo),
    ),
  );

  readonly watchBlabItemInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.loadActiveUserBlabItemInfo),
      switchMap(({ blabItemId }) =>
        this.gqlActiveUserBlabItemService.watchActiveUserBlabItemInfo({ blabItemId }).pipe(
          map((activeUserBlabItemInfo) =>
            BlabItemDisplayActions.loadActiveUserBlabItemInfoSuccess({ blabItemId, activeUserBlabItemInfo }),
          ),
          catchError((error) => of(BlabItemDisplayActions.loadActiveUserBlabItemInfoException({ error, blabItemId }))),
        ),
      ),
    ),
  );

  /** Load activities while loading blabItem (parallel) */
  readonly loadBlabItemLoadSuccessBlabItemActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.loadBlabItem),
      switchMap((res) => [
        BlabItemDisplayActions.loadBlabItemActivities(res),
        BlabItemDisplayActions.loadIncomingRelationFlimDefs(res),
      ]),
    ),
  );

  readonly loadBlabItemActivities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.loadBlabItemActivities),
      mergeMap(({ blabItemId }) =>
        this.gqlBlabItemActivityService
          .getBlabItemActivityConnection(BLAB_ITEM_ACTIVTIY_PAGE_SIZE, { blabItemId }, undefined)
          .pipe(
            map(({ edges: blabItemActivities, pageInfo }) =>
              BlabItemDisplayActions.loadBlabItemActivitiesSuccess({ blabItemId, blabItemActivities, pageInfo }),
            ),
            catchError((error) => of(BlabItemDisplayActions.loadBlabItemActivitiesException({ error, blabItemId }))),
          ),
      ),
    ),
  );

  readonly getIncomingRelationFlimDefs$ = createEffect(() =>
    this.actions$.pipe(ofType(BlabItemDisplayActions.loadIncomingRelationFlimDefs)).pipe(
      switchMap(({ blabItemId }) =>
        this.gqlIncomingRelationService
          .getIncomingRelationFlimDefsOmitBlabItems({ blabItemId, first: BLAB_ITEM_INCOMING_FLIM_DEFS_PAGE_SIZE })
          .pipe(
            map((incomingRelationFlimDefs) =>
              BlabItemDisplayActions.loadIncomingRelationFlimDefsSuccess({ blabItemId, incomingRelationFlimDefs }),
            ),
            catchError((error) =>
              of(BlabItemDisplayActions.loadIncomingRelationFlimDefsException({ error, blabItemId })),
            ),
          ),
      ),
    ),
  );

  readonly openUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BlabItemDisplayActions.openUrl),
        switchMap(({ url }) => this.browserService.open({ url })),
      ),
    { dispatch: false },
  );
}
