import gql from 'graphql-tag';
import {
  BlabDefPreviewDtoNoNestingFragment,
  WorkflowCenterWorkflowActionPreviewNoNestingFragment,
  WorkflowCenterSearchWorkflowPreviewDtoNoNestingFragment,
  WorkspacePreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { UserProfilePreviewDtoFragment } from '../user-profile';

export const WorkflowCenterSearchWorkflowPreviewDtoFragment = gql`
  fragment WorkflowCenterSearchWorkflowPreviewDtoFragment on WorkflowCenterSearchWorkflowPreviewDto {
    ...WorkflowCenterSearchWorkflowPreviewDtoNoNesting
    createdByUser {
      ...UserProfilePreviewDtoFragment
    }
    lastModifiedByUser {
      ...UserProfilePreviewDtoFragment
    }
    actions {
      ...WorkflowCenterWorkflowActionPreviewNoNesting
    }
    blabDef {
      ...BlabDefPreviewDtoNoNesting
      workspace {
        ...WorkspacePreviewDtoNoNesting
      }
    }
  }
  ${UserProfilePreviewDtoFragment}
  ${WorkflowCenterSearchWorkflowPreviewDtoNoNestingFragment}
  ${WorkflowCenterWorkflowActionPreviewNoNestingFragment}
  ${BlabDefPreviewDtoNoNestingFragment}
  ${WorkspacePreviewDtoNoNestingFragment}
`;
