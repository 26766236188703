import { formatDateDistanceStrict, differenceInSeconds, subMinutes, differenceInDays } from '@t5s/shared/util/date';
import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { isApplicableI18n, normalizeDate } from '../common';
import { getDateFnsLocale } from '../common/date-fns-locale';

export function formatDatetimeDistance(i18n: SharedI18n, date?: string | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  const now = new Date(Date.now());

  const locale = getDateFnsLocale(i18n);

  let roundingMethod: 'ceil' | 'floor' = 'ceil';
  let unit: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year' | undefined;

  // ensure the smallest unit is "1 minute", even if the date is slightly in the future
  if (differenceInSeconds(now, date) <= 0) {
    date = subMinutes(now, 1);
    unit = 'minute';
  }

  if (differenceInSeconds(now, date) === 0) {
    date = subMinutes(date, 1);
    unit = 'minute';
  }

  if (differenceInSeconds(now, date) < 60) {
    unit = 'minute';
  }

  // special case for 30 days
  if (differenceInDays(now, date) === 30) {
    unit = 'day';
  }

  // start to use floor for months and years...

  if (differenceInDays(now, date) > 30) {
    roundingMethod = 'floor';
  }

  return formatDateDistanceStrict(date, now, { locale, unit, roundingMethod, addSuffix: true });
}
