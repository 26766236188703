function fromMilliseconds(ms: number) {
  return (n: number) => {
    return Math.round(n / ms);
  };
}

const seconds = fromMilliseconds(1000);
const minutes = fromMilliseconds(60000);
const hours = fromMilliseconds(60 * 60000);
const days = fromMilliseconds(24 * 60 * 60000);
const weeks = fromMilliseconds(7 * 24 * 60 * 60000);
const months = fromMilliseconds(4 * 7 * 24 * 60 * 60000);
const years = fromMilliseconds(12 * 4 * 7 * 24 * 60 * 60000);

export const TimeFromMs = {
  seconds,
  minutes,
  hours,
  days,
  weeks,
  months,
  years,
};
