import { createAction, props } from '@ngrx/store';
import { createErrorAction } from '@t5s/client/util/store';
import { PushNotificationSchema } from '@t5s/mobile-client/value-object/push-notification';

const PREFIX = '[mobile-client-push-notification]';

export const PushNotificationActions = {
  reset: createAction(`${PREFIX} reset`),
  register: createAction(`${PREFIX} register`),
  registerSuccess: createAction(`${PREFIX} register success`, props<{ fcmToken: string }>()),
  registerError: createErrorAction(`${PREFIX} register error`),
  pushNotificationReceived: createAction(
    `${PREFIX} push notification received`,
    props<{ notification: PushNotificationSchema }>(),
  ),
  pushNotificationActionPerformed: createAction(
    `${PREFIX} push notification action performed`,
    props<{ actionId: string; notification: unknown }>(),
  ),

  remotePushNotificationReceived: createAction(`${PREFIX} remote (silent) push notification received`),
  remotePushNotificationReceivedAppInBackground: createAction(
    `${PREFIX} remote (silent) push notification received while app is in background`,
  ),

  reportTokenSuccess: createAction(`${PREFIX} reported token to server successfully`),
  reportTokenException: createErrorAction(`${PREFIX} reporting token to server failed with exception`),

  clearAllPushNotificationsSuccess: createAction(
    `${PREFIX} cleared all local pending push notifications from device (this resets badge on iOS)`,
  ),
};
