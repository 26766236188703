import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { addSeconds } from 'date-fns';
import { DatetimeI18n } from '../../../datetime.i18n';
import { formatDate, formatTime, getDateTimeFormats, isApplicableI18n, normalizeDate } from '../common';
import { getTimeFormats } from '../common/formats/time-formats';

export function formatWorkflowRunLogDateTime(i18n: SharedI18n, date?: string | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  // round milliseconds, as they are not displayed; clear ms and add a second or not
  const roundedMsToSeconds = Math.round(date.getMilliseconds() / 1000); // will be either 0 or 1
  date.setMilliseconds(0);
  date = addSeconds(date, roundedMsToSeconds);

  const dateTimeFormats = getDateTimeFormats(i18n);

  const dateFormat = dateTimeFormats.date.short;
  const timeFormat = getTimeFormats(i18n).workflowRunLog;

  // format date and time
  const formattedDate = formatDate(date, dateFormat, i18n);
  const formattedTime = formatTime(date, timeFormat, i18n);

  const dateTimeDividerStr = DatetimeI18n.translate(i18n, DatetimeI18n.key.at);

  // compose final string
  return `${formattedDate} ${dateTimeDividerStr} ${formattedTime}`;
}
