
  /* eslint-disable */
  import { getFragmentNames, importFragments } from '../utils';
  import { DocumentNode } from 'graphql';
  import gql from 'graphql-tag';
  import {
    ActiveBillingSubscriptionDtoNoNestingFragment,
  ActiveUserApiKeyDtoNoNestingFragment,
  ActiveUserAutoSaveBlabViewDtoNoNestingFragment,
  ActiveUserBlabDefFollowInfoDtoNoNestingFragment,
  ActiveUserBlabDefInfoDtoNoNestingFragment,
  ActiveUserBlabItemInfoDtoNoNestingFragment,
  ActiveUserCalendarSettingsDtoNoNestingFragment,
  ActiveUserCalendarStaticSharesDtoNoNestingFragment,
  ActiveUserCalendarSubscriptionDtoNoNestingFragment,
  ActiveUserDtoNoNestingFragment,
  ActiveUserGlobalWorkflowRunsFilteringSettingsDtoNoNestingFragment,
  ActiveUserNotificationSettingsDtoNoNestingFragment,
  ActiveUserOnboardingTasksDtoNoNestingFragment,
  ActiveUserProfileDtoNoNestingFragment,
  ActiveUserQuickAddBlabDefDtoNoNestingFragment,
  ActiveUserStatusDtoNoNestingFragment,
  ActiveUserUserInfoDtoNoNestingFragment,
  ActiveUserWorkflowDefInfoDtoNoNestingFragment,
  ActiveUserWorkspaceInfoDtoNoNestingFragment,
  ArchiveAllCompletedRemindersResultDtoNoNestingFragment,
  AudioRoomConsumerOptionsNoNestingFragment,
  AudioRoomDtoNoNestingFragment,
  AudioRoomProducerOptionsNoNestingFragment,
  BillingCustomerDtoNoNestingFragment,
  BlabDefActivityReplyDtoNoNestingFragment,
  BlabDefDataImportFileDtoNoNestingFragment,
  BlabDefDtoNoNestingFragment,
  BlabDefSettingsDtoNoNestingFragment,
  BlabDefWebhookDtoNoNestingFragment,
  BlabItemActivityReplyAttachmentDtoNoNestingFragment,
  BlabItemActivityReplyDtoNoNestingFragment,
  BlabItemCommentActivityDtoNoNestingFragment,
  BlabItemCommentAttachmentDtoNoNestingFragment,
  BlabItemDetailDtoNoNestingFragment,
  BlabItemFocusEntryDtoNoNestingFragment,
  BlabItemTemplateDtoNoNestingFragment,
  BlabItemViewDtoNoNestingFragment,
  BlabViewDtoNoNestingFragment,
  BlabViewPreviewDtoNoNestingFragment,
  BulkFollowBlabItemsResponseNoNestingFragment,
  CalculationFlimDefDtoNoNestingFragment,
  CancelWorkflowRunsResultNoNestingFragment,
  ChangeUserPasswordResultDtoNoNestingFragment,
  ChatDialogDtoNoNestingFragment,
  ChatGroupDtoNoNestingFragment,
  ChatMessageActivityDtoNoNestingFragment,
  ChatMessageAttachmentDtoNoNestingFragment,
  ChecklistFlimDefDtoNoNestingFragment,
  ChecklistFlimValEntryDtoNoNestingFragment,
  ChecklistFocusEntryDtoNoNestingFragment,
  ConvertMultiAttachmentFlimToSingleAttachmentFlimResultDtoNoNestingFragment,
  ConvertMultiCategoryFlimToSingleCategoryFlimResultDtoNoNestingFragment,
  ConvertMultiRelationFlimToSingleRelationFlimResultDtoNoNestingFragment,
  ConvertMultiTextFlimToSingleTextFlimResultDtoNoNestingFragment,
  ConvertMultiUserFlimToSingleUserFlimResultDtoNoNestingFragment,
  ConvertRangeDateFlimToSingleDateFlimResultDtoNoNestingFragment,
  ConvertSingleAttachmentFlimToMultiAttachmentFlimResultDtoNoNestingFragment,
  ConvertSingleCategoryFlimToMultiCategoryFlimResultDtoNoNestingFragment,
  ConvertSingleCategoryFlimToSingleTextFlimResultDtoNoNestingFragment,
  ConvertSingleCategoryFlimToStatusFlimResultDtoNoNestingFragment,
  ConvertSingleDateFlimToRangeDateFlimResultDtoNoNestingFragment,
  ConvertSingleRelationFlimToMultiRelationFlimResultDtoNoNestingFragment,
  ConvertSingleTextFlimToMultiLinkFlimResultDtoNoNestingFragment,
  ConvertSingleTextFlimToMultiTextFlimResultDtoNoNestingFragment,
  ConvertSingleTextFlimToNumberFlimResultDtoNoNestingFragment,
  ConvertSingleTextFlimToSingleCategoryFlimResultDtoNoNestingFragment,
  ConvertSingleUserFlimToMultiUserFlimResultDtoNoNestingFragment,
  CopyOrganizationInvitationLinkResultDtoNoNestingFragment,
  CreatedAtFlimDefDtoNoNestingFragment,
  CreatedByFlimDefDtoNoNestingFragment,
  DeleteBlabItemsInfoNoNestingFragment,
  DeleteOrganizationWorkspaceResultDtoNoNestingFragment,
  DuplicateBlabDefResultDtoNoNestingFragment,
  DuplicateSharedWorkspaceResultDtoNoNestingFragment,
  EmptyUserTrashResultDtoNoNestingFragment,
  ExportBlabDefDataResultDtoNoNestingFragment,
  FlimValAttachmentDtoNoNestingFragment,
  FlimValImageDtoNoNestingFragment,
  FocusSectionDtoNoNestingFragment,
  FocusSettingsDtoNoNestingFragment,
  GenerateWorkflowDefCodeResultDtoNoNestingFragment,
  ImportBlabDefDataToExistingBlabDefResultDtoNoNestingFragment,
  ImportBlabDefDataToNewBlabDefResultDtoNoNestingFragment,
  JoinAudioRoomResultDtoNoNestingFragment,
  LastModifiedAtFlimDefDtoNoNestingFragment,
  LastModifiedByFlimDefDtoNoNestingFragment,
  LinkPreviewDtoNoNestingFragment,
  ManualRunsTriggeredDtoNoNestingFragment,
  MarkAllNotificationsAsReadResultDtoNoNestingFragment,
  MicrosoftUserAuthenticationRequiredDtoNoNestingFragment,
  MoveAllFocusSectionEntriesResultDtoNoNestingFragment,
  MultiAttachmentFlimDefDtoNoNestingFragment,
  MultiAttachmentFlimValDtoNoNestingFragment,
  MultiCategoryFlimDefDtoNoNestingFragment,
  MultiCategoryFlimValDtoNoNestingFragment,
  MultiEmailFlimDefDtoNoNestingFragment,
  MultiEmailFlimValEntryDtoNoNestingFragment,
  MultiImageFlimDefDtoNoNestingFragment,
  MultiImageFlimValDtoNoNestingFragment,
  MultiLinkFlimDefDtoNoNestingFragment,
  MultiLinkFlimValEntryDtoNoNestingFragment,
  MultiPhoneFlimDefDtoNoNestingFragment,
  MultiPhoneFlimValEntryDtoNoNestingFragment,
  MultiRelationFlimDefDtoNoNestingFragment,
  MultiRelationFlimValDtoNoNestingFragment,
  MultiTextFlimDefDtoNoNestingFragment,
  MultiTextFlimValDtoNoNestingFragment,
  MultiUserFlimDefDtoNoNestingFragment,
  MultiUserFlimValDtoNoNestingFragment,
  NotificationDtoNoNestingFragment,
  NumberFlimDefDtoNoNestingFragment,
  NumberFlimValDtoNoNestingFragment,
  OrganizationActivityReplyDtoNoNestingFragment,
  OrganizationInvitationDtoNoNestingFragment,
  OrganizationProfileEditDtoNoNestingFragment,
  OrganizationSettingsDtoNoNestingFragment,
  OrganizationSignupVerificationSessionDtoNoNestingFragment,
  OrganizationSmtpSettingsDtoNoNestingFragment,
  OrganizationUserDtoNoNestingFragment,
  OrganizationWorkspaceDtoNoNestingFragment,
  PerformChangeUserEmailResultNoNestingFragment,
  RangeDateFlimDefDtoNoNestingFragment,
  RangeDateFlimValDtoNoNestingFragment,
  ReminderDtoNoNestingFragment,
  ReminderFocusEntryDtoNoNestingFragment,
  ReportActiveUserAudioRoomPresenceDtoNoNestingFragment,
  SingleAttachmentFlimDefDtoNoNestingFragment,
  SingleAttachmentFlimValDtoNoNestingFragment,
  SingleCategoryFlimDefDtoNoNestingFragment,
  SingleCategoryFlimValDtoNoNestingFragment,
  SingleDateFlimDefDtoNoNestingFragment,
  SingleDateFlimValDtoNoNestingFragment,
  SingleLocationFlimDefDtoNoNestingFragment,
  SingleLocationFlimValDtoNoNestingFragment,
  SingleRelationFlimDefDtoNoNestingFragment,
  SingleRelationFlimValDtoNoNestingFragment,
  SingleTextFlimDefDtoNoNestingFragment,
  SingleTextFlimValDtoNoNestingFragment,
  SingleUserFlimDefDtoNoNestingFragment,
  SingleUserFlimValDtoNoNestingFragment,
  SpreadsheetImportFlimDefCadidatesDtoNoNestingFragment,
  StatusFlimDefDtoNoNestingFragment,
  StatusFlimValDtoNoNestingFragment,
  SubscriptionInvoiceDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
  UniqueIdFlimDefDtoNoNestingFragment,
  UpdateBlabDefWebhookResultDtoNoNestingFragment,
  UpdateBlabItemsFlimValResponseNoNestingFragment,
  UpdateOrganizationSlugResultDtoNoNestingFragment,
  UpdateWorkflowCenterWebhookResultDtoNoNestingFragment,
  UpdateWorkspaceSlugResultDtoNoNestingFragment,
  UserActivityReplyDtoNoNestingFragment,
  UserPostActivityDtoNoNestingFragment,
  UserPostAttachmentDtoNoNestingFragment,
  UserSessionDtoNoNestingFragment,
  UserSettingsDtoNoNestingFragment,
  UserSettingsMicrosoftIntegrationStatusDtoNoNestingFragment,
  ValidateOrganizationSignupVerificationCodeResponseNoNestingFragment,
  VoidNoNestingFragment,
  WorkflowCenterWebhookDtoNoNestingFragment,
  WorkflowDefDtoNoNestingFragment,
  WorkspaceActivityReplyDtoNoNestingFragment,
  WorkspaceBlabDefDtoNoNestingFragment,
  WorkspaceDtoNoNestingFragment,
  WorkspaceSettingsDtoNoNestingFragment,
  WorkspaceShareSettingsDtoNoNestingFragment,
  WorkspaceTemplateDtoNoNestingFragment,
  _DateArrivedJobDtoNoNestingFragment,
  _DatePeriodicJobDtoNoNestingFragment,
  _testJobQueueDtoNoNestingFragment,
  _testTimeMockDtoNoNestingFragment
  } from './graphql-fragments'
  import {
    AcceptOrganizationInvitationInput,
  ActivateActiveUserCalendarSubscriptionInput,
  ActivateBlabItemNotificationsInput,
  ActivateOrganizationUserInput,
  ActivateUserNotificationsInput,
  AddActiveUserCalendarSubscriptionInput,
  AddActiveUserQuickAddBlabDefInput,
  AddBlabDefActivityReactionInput,
  AddBlabDefActivityReplyReactionInput,
  AddBlabDefsFromWorkspaceTemplateInput,
  AddBlabItemActivityReactionInput,
  AddBlabItemActivityReminderInput,
  AddBlabItemActivityReplyInput,
  AddBlabItemActivityReplyReactionInput,
  AddBlabItemCommentInput,
  AddBlabItemReminderInput,
  AddChatActivityReactionInput,
  AddDeviceRegistrationTokenInput,
  AddOrganizationActivityReactionInput,
  AddOrganizationActivityReplyInput,
  AddOrganizationActivityReplyReactionInput,
  AddPaymentMethodInput,
  AddReminderFocusEntryInput,
  AddReminderInput,
  AddSearchHistoryItemBlabDefInput,
  AddSearchHistoryItemBlabItemInput,
  AddSearchHistoryItemQueryInput,
  AddSearchHistoryItemUserInput,
  AddSearchHistoryItemWorkspaceInput,
  AddUserActivityReactionInput,
  AddUserActivityReplyInput,
  AddUserActivityReplyReactionInput,
  AddUserPostInput,
  AddUserToChatGroupInput,
  AddWebPushSubscriptionInput,
  AddWorkspaceActivityReactionInput,
  AddWorkspaceActivityReplyReactionInput,
  AddWorkspaceFromWorkspaceTemplateInput,
  AddWorkspaceMembershipsInput,
  AddWorkspaceTemplateInput,
  ApproveOrganizationInvitationInput,
  ArchiveBlabDefInput,
  ArchiveOrganizationWorkspaceInput,
  AuthenticateWithCredentialsInput,
  AuthenticateWithFindMyOrgsTokenOptionsInput,
  BulkFollowBlabItemsInput,
  BulkUnfollowBlabItemsInput,
  CancelBillingSubscriptionInput,
  CancelWorkflowRunsOfWorkflowDefInput,
  ChangeActiveUserFeatureAccessInput,
  ChangeBillingSubscriptionBillingPeriodInput,
  ChangeBillingSubscriptionPlanInput,
  ChangeOrganizationUserRoleInput,
  ChangePasswordWithForgotPasswordTokenOptionsInput,
  ChangeUserPasswordInput,
  ChangeWorkspaceMembershipRoleInput,
  ChangeWorkspaceUserExplicitRoleInput,
  CollapseFocusSectionInput,
  ConnectAudioRoomTransportInput,
  ConvertMultiAttachmentFlimToSingleAttachmentFlimInput,
  ConvertMultiCategoryFlimToSingleCategoryFlimInput,
  ConvertMultiRelationFlimToSingleRelationFlimInput,
  ConvertMultiTextFlimToSingleTextFlimInput,
  ConvertMultiUserFlimToSingleUserFlimInput,
  ConvertRangeDateFlimToSingleDateFlimInput,
  ConvertSingleAttachmentFlimToMultiAttachmentFlimInput,
  ConvertSingleCategoryFlimToMultiCategoryFlimInput,
  ConvertSingleCategoryFlimToSingleTextFlimInput,
  ConvertSingleCategoryFlimToStatusFlimInput,
  ConvertSingleDateFlimToRangeDateFlimInput,
  ConvertSingleRelationFlimToMultiRelationFlimInput,
  ConvertSingleTextFlimToMultiLinkFlimInput,
  ConvertSingleTextFlimToMultiTextFlimInput,
  ConvertSingleTextFlimToNumberFlimInput,
  ConvertSingleTextFlimToSingleCategoryFlimInput,
  ConvertSingleUserFlimToMultiUserFlimInput,
  CopyOrganizationInvitationLinkInput,
  CreateAudioRoomInput,
  CreateBillingSubscriptionInput,
  CreateBlabDefInput,
  CreateBlabDefWebhookInput,
  CreateBlabItemInput,
  CreateBlabViewInput,
  CreateCalculationFlimDefInput,
  CreateCallableWorkflowDefInput,
  CreateChatGroupInput,
  CreateChecklistFlimDefInput,
  CreateChecklistFlimValEntryInput,
  CreateCreatedAtFlimDefInput,
  CreateCreatedByFlimDefInput,
  CreateEmptyWorkflowDefInput,
  CreateFlimValAttachmentInput,
  CreateFlimValImageInput,
  CreateFocusSectionInput,
  CreateLastModifiedAtFlimDefInput,
  CreateLastModifiedByFlimDefInput,
  CreateLinkPreviewInput,
  CreateMultiAttachmentFlimDefInput,
  CreateMultiCategoryFlimDefInput,
  CreateMultiEmailFlimDefInput,
  CreateMultiEmailFlimValEntryInput,
  CreateMultiImageFlimDefInput,
  CreateMultiLinkFlimDefInput,
  CreateMultiLinkFlimValEntryInput,
  CreateMultiPhoneFlimDefInput,
  CreateMultiPhoneFlimValEntryInput,
  CreateMultiRelationFlimDefInput,
  CreateMultiTextFlimDefInput,
  CreateMultiUserFlimDefInput,
  CreateNumberFlimDefInput,
  CreateOrganizationAuthenticationProviderInput,
  CreateOrganizationInvitationsInput,
  CreateOrganizationSmtpSettingsInput,
  CreateOrganizationWorkspaceInput,
  CreateRangeDateFlimDefInput,
  CreateSingleAttachmentFlimDefInput,
  CreateSingleCategoryFlimDefInput,
  CreateSingleDateFlimDefInput,
  CreateSingleLocationFlimDefInput,
  CreateSingleRelationFlimDefInput,
  CreateSingleTextFlimDefInput,
  CreateSingleUserFlimDefInput,
  CreateStatusFlimDefInput,
  CreateUniqueIdFlimDefInput,
  CreateWorkflowCenterWebhookInput,
  CreateWorkflowDefInput,
  DeactivateActiveUserCalendarSubscriptionInput,
  DeactivateBlabItemNotificationsInput,
  DeactivateOrganizationUserInput,
  DeactivateUserNotificationsInput,
  DeleteBlabDefInput,
  DeleteBlabDefWebhookInput,
  DeleteBlabItemCommentInput,
  DeleteBlabItemInput,
  DeleteBlabItemTemplateInput,
  DeleteBlabItemsInput,
  DeleteBlabViewInput,
  DeleteChatMessageInput,
  DeleteChecklistFlimValEntryInput,
  DeleteFlimDefInput,
  DeleteFlimValAttachmentInput,
  DeleteFlimValImageInput,
  DeleteMultiEmailFlimValEntryInput,
  DeleteMultiLinkFlimValEntryInput,
  DeleteMultiPhoneFlimValEntryInput,
  DeleteOrganizationWorkspaceInput,
  DeleteUserPostInput,
  DeleteWorkflowCenterWebhookInput,
  DeleteWorkflowDefInput,
  DenyOrganizationInvitationInput,
  DestroyAudioRoomInput,
  DisableActiveUserBlabDefInboundEmailInput,
  DisableBlabDefInboundPublicEmailInput,
  DisableWorkspaceShareLinkDuplicationInput,
  DisableWorkspaceShareLinkInput,
  DuplicateBlabDefInput,
  DuplicateBlabViewInput,
  DuplicateSharedWorkspaceInput,
  DuplicateWorkflowDefInput,
  DuplicateWorkspaceInput,
  EditBlabItemActivityReplyInput,
  EditBlabItemCommentInput,
  EditIncompleteReminderInput,
  EditOrganizationActivityReplyInput,
  EditUserActivityReplyInput,
  EditUserPostInput,
  EnableActiveUserBlabDefInboundEmailInput,
  EnableBlabDefInboundPublicEmailInput,
  EnableWorkspaceShareLinkDuplicationInput,
  EnableWorkspaceShareLinkInput,
  ExpandFocusSectionInput,
  ExportBlabDefDataInput,
  FindMyOrgsInput,
  FollowBlabDefInput,
  FollowBlabItemInput,
  FollowUserInput,
  FollowWorkflowDefInput,
  GainAdminAccessToOrganizationWorkspaceInput,
  GenerateWorkflowDefCodeInput,
  GenerateWorkspaceShareLinkTokenInput,
  ImportBlabDefDataToExistingBlabDefInput,
  ImportBlabDefDataToNewBlabDefInput,
  ImportSpreadsheetInput,
  InitializeAudioRoomConsumersInput,
  InitializeAudioRoomProducerInput,
  JoinAudioRoomInput,
  LeaveAudioRoomInput,
  LeaveChatGroupInput,
  MarkChecklistFocusEntryCompletedInput,
  MarkChecklistFocusEntryIncompleteInput,
  MarkConversationAsReadInput,
  MarkNotificationAsReadInput,
  MarkNotificationAsUnreadInput,
  MarkReminderFocusEntryCompletedInput,
  MarkReminderFocusEntryIncompleteInput,
  MoveActiveUserQuickAddBlabDefInput,
  MoveAllFocusSectionEntriesInput,
  MoveBlabDefToWorkspaceInput,
  MoveFocusEntryInput,
  MuteActiveUserInAudioRoomInput,
  MuteConversationInput,
  NotifyOfInboundEmailInput,
  PauseWorkflowDefInput,
  PerformChangeUserEmailInput,
  PerformOrganizationSetupForIntentionInput,
  PerformOrganizationSignupInput,
  PostChatMessageInput,
  PublishWorkspaceTemplateInput,
  ReactivateBillingSubscriptionRenewalInput,
  RemoveActiveUserCalendarSubscriptionInput,
  RemoveActiveUserQuickAddBlabDefInput,
  RemoveAllDefaultBlabItemTemplatesInput,
  RemoveBlabDefActivityReactionInput,
  RemoveBlabDefActivityReplyReactionInput,
  RemoveBlabItemActivityReactionInput,
  RemoveBlabItemActivityReplyInput,
  RemoveBlabItemActivityReplyReactionInput,
  RemoveChatActivityReactionInput,
  RemoveFocusSectionInput,
  RemoveFromUserTrashInput,
  RemoveNewsfeedActivityInput,
  RemoveOrganizationActivityReactionInput,
  RemoveOrganizationActivityReplyInput,
  RemoveOrganizationActivityReplyReactionInput,
  RemoveOrganizationAuthenticationProviderInput,
  RemoveOrganizationSmtpSettingsInput,
  RemovePaymentMethodInput,
  RemoveReminderInput,
  RemoveUserActivityReactionInput,
  RemoveUserActivityReplyInput,
  RemoveUserActivityReplyReactionInput,
  RemoveUserSessionOptionsInput,
  RemoveWorkspaceActivityReactionInput,
  RemoveWorkspaceActivityReplyReactionInput,
  RemoveWorkspaceMembershipInput,
  RemoveWorkspaceTemplateInput,
  RemoveWorkspaceUserExplicitRoleInput,
  RenameBlabViewInput,
  ReportActiveUserAudioPresenceInput,
  ReportActiveUserPresenceInput,
  RequestBlabDefWebhookVerificationInput,
  RequestChangeUserEmailVerficiationInput,
  RequestForgotPasswordEmailInput,
  RequestOrganizationSignupVerificationInput,
  RequestWorkflowCenterWebhookVerificationInput,
  ResendOrganizationInvitationInput,
  ResetPrivateUrlShareUrlInput,
  ResetPublicUrlShareUrlInput,
  RestoreFromUserTrashInput,
  RetryInvoicePaymentInput,
  RevertBlabItemRevisionInput,
  RevokeOrganizationInvitationInput,
  RunWorkflowManuallyInput,
  SelectBlabViewInput,
  SetActiveUserDoNotDisturbInput,
  SetActiveUserIncognitoInput,
  SetActiveUserMetadataInput,
  SetActiveUserStatusInput,
  SetActiveUserWorkflowDefLastViewedAtInput,
  SetBlabDefInboundEmailFieldMappingsInput,
  SetDefaultOrganizationSmtpSettingsInput,
  SetDefaultPaymentMethodInput,
  SetReminderStatusInput,
  SetUserClientAppMetaDataInput,
  SetWorkspaceSharePermissionsInput,
  SimulateWorkflowInput,
  StartChatVideoCallInput,
  StartOrContinueDialogInput,
  UnfollowBlabDefInput,
  UnfollowBlabItemInput,
  UnfollowUserInput,
  UnfollowWorkflowDefInput,
  UnmuteActiveUserInAudioRoomInput,
  UnmuteConversationInput,
  UnpauseWorkflowDefInput,
  UnsupportedPlatformRequestMagicLinkInput,
  UpdateActiveUserBlabDefInfoInput,
  UpdateActiveUserCalendarSettingsInput,
  UpdateActiveUserCalendarSubscriptionInput,
  UpdateActiveUserNotificationSettingsInput,
  UpdateActiveUserProfileInput,
  UpdateActiveUserWorkspaceInfoLastSelectedBlabDefInput,
  UpdateBillingCustomerInput,
  UpdateBlabDefInput,
  UpdateBlabDefSettingsInput,
  UpdateBlabDefWebhookInput,
  UpdateBlabItemFocusEntryDueAtInput,
  UpdateBlabItemFocusEntryStatusInput,
  UpdateBlabItemFocusSectionInput,
  UpdateBlabItemTemplateInput,
  UpdateBlabItemTemplateTitleInput,
  UpdateBlabItemViewPositionInput,
  UpdateBlabItemsFlimValInput,
  UpdateBlabViewInput,
  UpdateBlabViewPositionInput,
  UpdateBlabViewSettingsInput,
  UpdateBlabViewSplitByInput,
  UpdateCalculationFlimDefInput,
  UpdateChatGroupInfoInput,
  UpdateChecklistFlimDefInput,
  UpdateChecklistFlimValEntryFocusSectionInput,
  UpdateChecklistFlimValEntryInput,
  UpdateChecklistFocusEntryDescriptionInput,
  UpdateChecklistFocusEntryDueAtInput,
  UpdateChecklistFocusEntryTitleInput,
  UpdateCreatedAtFlimDefInput,
  UpdateCreatedByFlimDefInput,
  UpdateFlimValAttachmentInput,
  UpdateFlimValImageInput,
  UpdateFocusEntrySectionInput,
  UpdateFocusSectionNameInput,
  UpdateFocusSectionPositionInput,
  UpdateFocusSettingsInput,
  UpdateLastModifiedAtFlimDefInput,
  UpdateLastModifiedByFlimDefInput,
  UpdateMultiAttachmentFlimDefInput,
  UpdateMultiAttachmentFlimValFileReferenceInput,
  UpdateMultiCategoryFlimDefInput,
  UpdateMultiEmailFlimDefInput,
  UpdateMultiEmailFlimValEntryInput,
  UpdateMultiImageFlimDefInput,
  UpdateMultiImageFlimValFileReferenceInput,
  UpdateMultiLinkFlimDefInput,
  UpdateMultiPhoneFlimDefInput,
  UpdateMultiPhoneFlimValEntryInput,
  UpdateMultiRelationFlimDefInput,
  UpdateMultiTextFlimDefInput,
  UpdateMultiUserFlimDefInput,
  UpdateNumberFlimDefInput,
  UpdateOauthIntegrationAccessTokenInput,
  UpdateOauthIntegrationTokenExpirationInput,
  UpdateOrganizationAuthenticationProviderInput,
  UpdateOrganizationProfileInput,
  UpdateOrganizationSettingsInput,
  UpdateOrganizationSlugInput,
  UpdateOrganizationSmtpSettingsInput,
  UpdateOrganizationUserInfoInput,
  UpdateOrganizationWorkspacePosition,
  UpdatePrivateUrlShareInput,
  UpdatePublicUrlShareInput,
  UpdateRangeDateFlimDefInput,
  UpdateReminderFocusEntryDescriptionInput,
  UpdateReminderFocusEntryDueAtInput,
  UpdateReminderFocusEntryTitleInput,
  UpdateReminderFocusSectionInput,
  UpdateSingleAttachmentFlimDefInput,
  UpdateSingleCategoryFlimDefInput,
  UpdateSingleDateFlimDefInput,
  UpdateSingleLocationFlimDefInput,
  UpdateSingleRelationFlimDefInput,
  UpdateSingleTextFlimDefInput,
  UpdateSingleUserFlimDefInput,
  UpdateStatusFlimDefInput,
  UpdateUniqueIdFlimDefInput,
  UpdateUserSettingsInput,
  UpdateWorkflowCenterWebhookInput,
  UpdateWorkflowDefInput,
  UpdateWorkspaceBlabDefPositionInput,
  UpdateWorkspaceSettingsInput,
  UpdateWorkspaceSlugInput,
  UpdateWorkspaceTemplateInfoInput,
  Upload,
  UploadBlabItemActivityReplyAttachmentInput,
  UploadBlabItemCommentAttachmentInput,
  UploadChatMessageAttachmentInput,
  UploadUserPostAttachmentInput,
  UpsertActiveUserAutoSaveBlabViewInput,
  UpsertActiveUserGlobalWorkflowRunsFilteringSettingsInput,
  UpsertMultiAttachmentFlimValInput,
  UpsertMultiCategoryFlimValInput,
  UpsertMultiImageFlimValInput,
  UpsertMultiRelationFlimValInput,
  UpsertMultiTextFlimValInput,
  UpsertMultiUserFlimValInput,
  UpsertNumberFlimValInput,
  UpsertRangeDateFlimValInput,
  UpsertSingleAttachmentFlimValInput,
  UpsertSingleCategoryFlimValInput,
  UpsertSingleDateFlimValInput,
  UpsertSingleLocationFlimValInput,
  UpsertSingleRelationFlimValInput,
  UpsertSingleTextFlimValInput,
  UpsertSingleUserFlimValInput,
  UpsertStatusFlimValInput,
  ValidateOrganizationSignupVerificationCodeInput,
  _RemoveDateArrivedJobsInput,
  _RemoveDatePeriodicJobsInput,
  _SetScheduleAtOfDateArrivedJobsInput,
  _SetScheduleAtOfDatePeriodicJobsInput,
  _TestJobQueueExecuteImmediatelyInput,
  _TestJobQueueTriggerInput,
  _TestTimeMockFreezeInput,
  _TestTimeMockTravelInput
  } from '@t5s/shared/gql'

  
export function _testJobQueueTrigger(input?: _TestJobQueueTriggerInput, fragments: DocumentNode[]= [_testJobQueueDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation _testJobQueueTrigger($input: _TestJobQueueTriggerInput) {
        _testJobQueueTrigger (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function _testJobQueueExecuteImmediately(input?: _TestJobQueueExecuteImmediatelyInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation _testJobQueueExecuteImmediately($input: _TestJobQueueExecuteImmediatelyInput) {
        _testJobQueueExecuteImmediately (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function _updateOauthIntegrationTokenExpiration(input?: UpdateOauthIntegrationTokenExpirationInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation _updateOauthIntegrationTokenExpiration($input: UpdateOauthIntegrationTokenExpirationInput) {
        _updateOauthIntegrationTokenExpiration (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function _updateOauthIntegrationAccessToken(input?: UpdateOauthIntegrationAccessTokenInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation _updateOauthIntegrationAccessToken($input: UpdateOauthIntegrationAccessTokenInput) {
        _updateOauthIntegrationAccessToken (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function enablePermissionV2ForOrganization(fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation enablePermissionV2ForOrganization {
        enablePermissionV2ForOrganization  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function disablePermissionV2ForOrganization(fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation disablePermissionV2ForOrganization {
        disablePermissionV2ForOrganization  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function _testTimeMockFreeze(input?: _TestTimeMockFreezeInput, fragments: DocumentNode[]= [_testTimeMockDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation _testTimeMockFreeze($input: _TestTimeMockFreezeInput) {
        _testTimeMockFreeze (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function _testTimeMockReset(fragments: DocumentNode[]= [_testTimeMockDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation _testTimeMockReset {
        _testTimeMockReset  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function _testTimeMockTravel(input: _TestTimeMockTravelInput, fragments: DocumentNode[]= [_testTimeMockDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation _testTimeMockTravel($input: _TestTimeMockTravelInput!) {
        _testTimeMockTravel (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function _removeDateArrivedJobs(input: _RemoveDateArrivedJobsInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation _removeDateArrivedJobs($input: _RemoveDateArrivedJobsInput!) {
        _removeDateArrivedJobs (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function _setScheduleAtOfDateArrivedJob(input: _SetScheduleAtOfDateArrivedJobsInput, fragments: DocumentNode[]= [_DateArrivedJobDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation _setScheduleAtOfDateArrivedJob($input: _SetScheduleAtOfDateArrivedJobsInput!) {
        _setScheduleAtOfDateArrivedJob (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function _removeDatePeriodicJobs(input: _RemoveDatePeriodicJobsInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation _removeDatePeriodicJobs($input: _RemoveDatePeriodicJobsInput!) {
        _removeDatePeriodicJobs (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function _setScheduleAtOfDatePeriodicJob(input: _SetScheduleAtOfDatePeriodicJobsInput, fragments: DocumentNode[]= [_DatePeriodicJobDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation _setScheduleAtOfDatePeriodicJob($input: _SetScheduleAtOfDatePeriodicJobsInput!) {
        _setScheduleAtOfDatePeriodicJob (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function _triggerOrganizationThrottlingScan(fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation _triggerOrganizationThrottlingScan {
        _triggerOrganizationThrottlingScan  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function _triggerOrganizationReportActionUsage(fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation _triggerOrganizationReportActionUsage {
        _triggerOrganizationReportActionUsage  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function resetActiveUserApiKey(fragments: DocumentNode[]= [ActiveUserApiKeyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation resetActiveUserApiKey {
        resetActiveUserApiKey  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function setActiveUserMetadata(input: SetActiveUserMetadataInput, fragments: DocumentNode[]= [ActiveUserDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation setActiveUserMetadata($input: SetActiveUserMetadataInput!) {
        setActiveUserMetadata (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function reportCompletedSetup(fragments: DocumentNode[]= [ActiveUserDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation reportCompletedSetup {
        reportCompletedSetup  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function reportCompletedWorkspaceSetup(fragments: DocumentNode[]= [ActiveUserDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation reportCompletedWorkspaceSetup {
        reportCompletedWorkspaceSetup  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function updateActiveUserBlabDefInfo(input: UpdateActiveUserBlabDefInfoInput, fragments: DocumentNode[]= [ActiveUserBlabDefInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateActiveUserBlabDefInfo($input: UpdateActiveUserBlabDefInfoInput!) {
        updateActiveUserBlabDefInfo (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateActiveUserCalendarSettings(input: UpdateActiveUserCalendarSettingsInput, fragments: DocumentNode[]= [ActiveUserCalendarSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateActiveUserCalendarSettings($input: UpdateActiveUserCalendarSettingsInput!) {
        updateActiveUserCalendarSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function resetPrivateUrlShareUrl(input: ResetPrivateUrlShareUrlInput, fragments: DocumentNode[]= [ActiveUserCalendarStaticSharesDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation resetPrivateUrlShareUrl($input: ResetPrivateUrlShareUrlInput!) {
        resetPrivateUrlShareUrl (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updatePrivateUrlShare(input: UpdatePrivateUrlShareInput, fragments: DocumentNode[]= [ActiveUserCalendarStaticSharesDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updatePrivateUrlShare($input: UpdatePrivateUrlShareInput!) {
        updatePrivateUrlShare (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function resetPublicUrlShareUrl(input: ResetPublicUrlShareUrlInput, fragments: DocumentNode[]= [ActiveUserCalendarStaticSharesDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation resetPublicUrlShareUrl($input: ResetPublicUrlShareUrlInput!) {
        resetPublicUrlShareUrl (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updatePublicUrlShare(input: UpdatePublicUrlShareInput, fragments: DocumentNode[]= [ActiveUserCalendarStaticSharesDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updatePublicUrlShare($input: UpdatePublicUrlShareInput!) {
        updatePublicUrlShare (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function activateActiveUserCalendarSubscription(input: ActivateActiveUserCalendarSubscriptionInput, fragments: DocumentNode[]= [ActiveUserCalendarSubscriptionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation activateActiveUserCalendarSubscription($input: ActivateActiveUserCalendarSubscriptionInput!) {
        activateActiveUserCalendarSubscription (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deactivateActiveUserCalendarSubscription(input: DeactivateActiveUserCalendarSubscriptionInput, fragments: DocumentNode[]= [ActiveUserCalendarSubscriptionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deactivateActiveUserCalendarSubscription($input: DeactivateActiveUserCalendarSubscriptionInput!) {
        deactivateActiveUserCalendarSubscription (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addActiveUserCalendarSubscription(input: AddActiveUserCalendarSubscriptionInput, fragments: DocumentNode[]= [ActiveUserCalendarSubscriptionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addActiveUserCalendarSubscription($input: AddActiveUserCalendarSubscriptionInput!) {
        addActiveUserCalendarSubscription (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeActiveUserCalendarSubscription(input?: RemoveActiveUserCalendarSubscriptionInput, fragments: DocumentNode[]= [ActiveUserCalendarSubscriptionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeActiveUserCalendarSubscription($input: RemoveActiveUserCalendarSubscriptionInput) {
        removeActiveUserCalendarSubscription (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateActiveUserCalendarSubscription(input?: UpdateActiveUserCalendarSubscriptionInput, fragments: DocumentNode[]= [ActiveUserCalendarSubscriptionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateActiveUserCalendarSubscription($input: UpdateActiveUserCalendarSubscriptionInput) {
        updateActiveUserCalendarSubscription (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function changeActiveUserFeatureAccess(input: ChangeActiveUserFeatureAccessInput, fragments: DocumentNode[]= [ActiveUserDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation changeActiveUserFeatureAccess($input: ChangeActiveUserFeatureAccessInput!) {
        changeActiveUserFeatureAccess (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateActiveUserNotificationSettings(input: UpdateActiveUserNotificationSettingsInput, fragments: DocumentNode[]= [ActiveUserNotificationSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateActiveUserNotificationSettings($input: UpdateActiveUserNotificationSettingsInput!) {
        updateActiveUserNotificationSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function unsubscribeActiveUserEmailNotifications(jwt: string, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation unsubscribeActiveUserEmailNotifications($jwt: String!) {
        unsubscribeActiveUserEmailNotifications (jwt: $jwt) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { jwt }
  }
}

export function dismissActiveUserOnboardingTasks(fragments: DocumentNode[]= [ActiveUserOnboardingTasksDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation dismissActiveUserOnboardingTasks {
        dismissActiveUserOnboardingTasks  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function addUserPost(input: AddUserPostInput, fragments: DocumentNode[]= [UserPostActivityDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addUserPost($input: AddUserPostInput!) {
        addUserPost (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function editUserPost(input: EditUserPostInput, fragments: DocumentNode[]= [UserPostActivityDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation editUserPost($input: EditUserPostInput!) {
        editUserPost (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteUserPost(input: DeleteUserPostInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteUserPost($input: DeleteUserPostInput!) {
        deleteUserPost (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function uploadUserPostAttachment(input: UploadUserPostAttachmentInput, attachment: Upload, fragments: DocumentNode[]= [UserPostAttachmentDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation uploadUserPostAttachment($input: UploadUserPostAttachmentInput!, $attachment: Upload!) {
        uploadUserPostAttachment (input: $input, attachment: $attachment) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input, attachment }
  }
}

export function reportActiveUserPresence(input: ReportActiveUserPresenceInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation reportActiveUserPresence($input: ReportActiveUserPresenceInput!) {
        reportActiveUserPresence (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateActiveUserProfile(input: UpdateActiveUserProfileInput, profilePicture?: Upload, fragments: DocumentNode[]= [ActiveUserProfileDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateActiveUserProfile($input: UpdateActiveUserProfileInput!, $profilePicture: Upload) {
        updateActiveUserProfile (input: $input, profilePicture: $profilePicture) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input, profilePicture }
  }
}

export function updateActiveUserProfilePicture(profilePicture: Upload, fragments: DocumentNode[]= [ActiveUserProfileDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateActiveUserProfilePicture($profilePicture: Upload!) {
        updateActiveUserProfilePicture (profilePicture: $profilePicture) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { profilePicture }
  }
}

export function addActiveUserQuickAddBlabDef(input: AddActiveUserQuickAddBlabDefInput, fragments: DocumentNode[]= [ActiveUserQuickAddBlabDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addActiveUserQuickAddBlabDef($input: AddActiveUserQuickAddBlabDefInput!) {
        addActiveUserQuickAddBlabDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeActiveUserQuickAddBlabDef(input: RemoveActiveUserQuickAddBlabDefInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeActiveUserQuickAddBlabDef($input: RemoveActiveUserQuickAddBlabDefInput!) {
        removeActiveUserQuickAddBlabDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function moveActiveUserQuickAddBlabDef(input: MoveActiveUserQuickAddBlabDefInput, fragments: DocumentNode[]= [ActiveUserQuickAddBlabDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation moveActiveUserQuickAddBlabDef($input: MoveActiveUserQuickAddBlabDefInput!) {
        moveActiveUserQuickAddBlabDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function setActiveUserIncognito(input: SetActiveUserIncognitoInput, fragments: DocumentNode[]= [ActiveUserStatusDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation setActiveUserIncognito($input: SetActiveUserIncognitoInput!) {
        setActiveUserIncognito (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function clearActiveUserIncognito(fragments: DocumentNode[]= [ActiveUserStatusDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation clearActiveUserIncognito {
        clearActiveUserIncognito  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function setActiveUserDoNotDisturb(input: SetActiveUserDoNotDisturbInput, fragments: DocumentNode[]= [ActiveUserStatusDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation setActiveUserDoNotDisturb($input: SetActiveUserDoNotDisturbInput!) {
        setActiveUserDoNotDisturb (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function clearActiveUserDoNotDisturb(fragments: DocumentNode[]= [ActiveUserStatusDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation clearActiveUserDoNotDisturb {
        clearActiveUserDoNotDisturb  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function setActiveUserStatus(input: SetActiveUserStatusInput, fragments: DocumentNode[]= [ActiveUserStatusDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation setActiveUserStatus($input: SetActiveUserStatusInput!) {
        setActiveUserStatus (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function clearActiveUserStatus(fragments: DocumentNode[]= [ActiveUserStatusDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation clearActiveUserStatus {
        clearActiveUserStatus  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function setActiveUserWorkflowDefLastViewedAt(input?: SetActiveUserWorkflowDefLastViewedAtInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation setActiveUserWorkflowDefLastViewedAt($input: SetActiveUserWorkflowDefLastViewedAtInput) {
        setActiveUserWorkflowDefLastViewedAt (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateActiveUserWorkspaceInfoLastSelectedBlabDef(input: UpdateActiveUserWorkspaceInfoLastSelectedBlabDefInput, fragments: DocumentNode[]= [ActiveUserWorkspaceInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateActiveUserWorkspaceInfoLastSelectedBlabDef($input: UpdateActiveUserWorkspaceInfoLastSelectedBlabDefInput!) {
        updateActiveUserWorkspaceInfoLastSelectedBlabDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createAudioRoom(input: CreateAudioRoomInput, fragments: DocumentNode[]= [AudioRoomDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createAudioRoom($input: CreateAudioRoomInput!) {
        createAudioRoom (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function destroyAudioRoom(input: DestroyAudioRoomInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation destroyAudioRoom($input: DestroyAudioRoomInput!) {
        destroyAudioRoom (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function leaveAudioRoom(input: LeaveAudioRoomInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation leaveAudioRoom($input: LeaveAudioRoomInput!) {
        leaveAudioRoom (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function joinAudioRoom(input: JoinAudioRoomInput, fragments: DocumentNode[]= [JoinAudioRoomResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation joinAudioRoom($input: JoinAudioRoomInput!) {
        joinAudioRoom (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function connectAudioRoomTransport(input: ConnectAudioRoomTransportInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation connectAudioRoomTransport($input: ConnectAudioRoomTransportInput!) {
        connectAudioRoomTransport (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function initializeAudioRoomProducer(input: InitializeAudioRoomProducerInput, fragments: DocumentNode[]= [AudioRoomProducerOptionsNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation initializeAudioRoomProducer($input: InitializeAudioRoomProducerInput!) {
        initializeAudioRoomProducer (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function initializeAudioRoomConsumers(input: InitializeAudioRoomConsumersInput, fragments: DocumentNode[]= [AudioRoomConsumerOptionsNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation initializeAudioRoomConsumers($input: InitializeAudioRoomConsumersInput!) {
        initializeAudioRoomConsumers (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function muteActiveUserInAudioRoom(input: MuteActiveUserInAudioRoomInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation muteActiveUserInAudioRoom($input: MuteActiveUserInAudioRoomInput!) {
        muteActiveUserInAudioRoom (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function unmuteActiveUserInAudioRoom(input: UnmuteActiveUserInAudioRoomInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation unmuteActiveUserInAudioRoom($input: UnmuteActiveUserInAudioRoomInput!) {
        unmuteActiveUserInAudioRoom (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function reportActiveUserAudioRoomPresence(input: ReportActiveUserAudioPresenceInput, fragments: DocumentNode[]= [ReportActiveUserAudioRoomPresenceDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation reportActiveUserAudioRoomPresence($input: ReportActiveUserAudioPresenceInput!) {
        reportActiveUserAudioRoomPresence (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateBillingCustomer(input: UpdateBillingCustomerInput, fragments: DocumentNode[]= [BillingCustomerDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateBillingCustomer($input: UpdateBillingCustomerInput!) {
        updateBillingCustomer (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createBillingSubscription(input: CreateBillingSubscriptionInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createBillingSubscription($input: CreateBillingSubscriptionInput!) {
        createBillingSubscription (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function changeBillingSubscriptionBillingPeriod(input: ChangeBillingSubscriptionBillingPeriodInput, fragments: DocumentNode[]= [ActiveBillingSubscriptionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation changeBillingSubscriptionBillingPeriod($input: ChangeBillingSubscriptionBillingPeriodInput!) {
        changeBillingSubscriptionBillingPeriod (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function changeBillingSubscriptionPlan(input: ChangeBillingSubscriptionPlanInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation changeBillingSubscriptionPlan($input: ChangeBillingSubscriptionPlanInput!) {
        changeBillingSubscriptionPlan (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function cancelBillingSubscription(input: CancelBillingSubscriptionInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation cancelBillingSubscription($input: CancelBillingSubscriptionInput!) {
        cancelBillingSubscription (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function reactivateBillingSubscriptionRenewal(input?: ReactivateBillingSubscriptionRenewalInput, fragments: DocumentNode[]= [ActiveBillingSubscriptionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation reactivateBillingSubscriptionRenewal($input: ReactivateBillingSubscriptionRenewalInput) {
        reactivateBillingSubscriptionRenewal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addPaymentMethod(input: AddPaymentMethodInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addPaymentMethod($input: AddPaymentMethodInput!) {
        addPaymentMethod (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removePaymentMethod(input: RemovePaymentMethodInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removePaymentMethod($input: RemovePaymentMethodInput!) {
        removePaymentMethod (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function setDefaultPaymentMethod(input: SetDefaultPaymentMethodInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation setDefaultPaymentMethod($input: SetDefaultPaymentMethodInput!) {
        setDefaultPaymentMethod (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function retryInvoicePayment(input: RetryInvoicePaymentInput, fragments: DocumentNode[]= [SubscriptionInvoiceDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation retryInvoicePayment($input: RetryInvoicePaymentInput!) {
        retryInvoicePayment (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createBlabDef(input: CreateBlabDefInput, fragments: DocumentNode[]= [BlabDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createBlabDef($input: CreateBlabDefInput!) {
        createBlabDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateBlabDef(input: UpdateBlabDefInput, fragments: DocumentNode[]= [BlabDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateBlabDef($input: UpdateBlabDefInput!) {
        updateBlabDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteBlabDef(input: DeleteBlabDefInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteBlabDef($input: DeleteBlabDefInput!) {
        deleteBlabDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function archiveBlabDef(input: ArchiveBlabDefInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation archiveBlabDef($input: ArchiveBlabDefInput!) {
        archiveBlabDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addBlabDefActivityReaction(input: AddBlabDefActivityReactionInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addBlabDefActivityReaction($input: AddBlabDefActivityReactionInput!) {
        addBlabDefActivityReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeBlabDefActivityReaction(input: RemoveBlabDefActivityReactionInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeBlabDefActivityReaction($input: RemoveBlabDefActivityReactionInput!) {
        removeBlabDefActivityReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addBlabDefActivityReplyReaction(input: AddBlabDefActivityReplyReactionInput, fragments: DocumentNode[]= [BlabDefActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addBlabDefActivityReplyReaction($input: AddBlabDefActivityReplyReactionInput!) {
        addBlabDefActivityReplyReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeBlabDefActivityReplyReaction(input: RemoveBlabDefActivityReplyReactionInput, fragments: DocumentNode[]= [BlabDefActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeBlabDefActivityReplyReaction($input: RemoveBlabDefActivityReplyReactionInput!) {
        removeBlabDefActivityReplyReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function exportBlabDefData(input: ExportBlabDefDataInput, fragments: DocumentNode[]= [ExportBlabDefDataResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation exportBlabDefData($input: ExportBlabDefDataInput!) {
        exportBlabDefData (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function importBlabDefDataToExistingBlabDef(input: ImportBlabDefDataToExistingBlabDefInput, fragments: DocumentNode[]= [ImportBlabDefDataToExistingBlabDefResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation importBlabDefDataToExistingBlabDef($input: ImportBlabDefDataToExistingBlabDefInput!) {
        importBlabDefDataToExistingBlabDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function uploadBlabDefDataImportFile(file: Upload, fragments: DocumentNode[]= [BlabDefDataImportFileDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation uploadBlabDefDataImportFile($file: Upload!) {
        uploadBlabDefDataImportFile (file: $file) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { file }
  }
}

export function importBlabDefDataToNewBlabDef(input: ImportBlabDefDataToNewBlabDefInput, fragments: DocumentNode[]= [ImportBlabDefDataToNewBlabDefResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation importBlabDefDataToNewBlabDef($input: ImportBlabDefDataToNewBlabDefInput!) {
        importBlabDefDataToNewBlabDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createBlabDefWebhook(input: CreateBlabDefWebhookInput, fragments: DocumentNode[]= [BlabDefWebhookDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createBlabDefWebhook($input: CreateBlabDefWebhookInput!) {
        createBlabDefWebhook (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateBlabDefWebhook(input: UpdateBlabDefWebhookInput, fragments: DocumentNode[]= [UpdateBlabDefWebhookResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateBlabDefWebhook($input: UpdateBlabDefWebhookInput!) {
        updateBlabDefWebhook (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteBlabDefWebhook(input: DeleteBlabDefWebhookInput, fragments: DocumentNode[]= [BlabDefWebhookDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteBlabDefWebhook($input: DeleteBlabDefWebhookInput!) {
        deleteBlabDefWebhook (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function requestBlabDefWebhookVerification(input: RequestBlabDefWebhookVerificationInput, fragments: DocumentNode[]= [BlabDefWebhookDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation requestBlabDefWebhookVerification($input: RequestBlabDefWebhookVerificationInput!) {
        requestBlabDefWebhookVerification (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function duplicateBlabDef(input: DuplicateBlabDefInput, fragments: DocumentNode[]= [BlabDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation duplicateBlabDef($input: DuplicateBlabDefInput!) {
        duplicateBlabDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function duplicateBlabDefV2(input: DuplicateBlabDefInput, fragments: DocumentNode[]= [DuplicateBlabDefResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation duplicateBlabDefV2($input: DuplicateBlabDefInput!) {
        duplicateBlabDefV2 (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function followBlabDef(input: FollowBlabDefInput, fragments: DocumentNode[]= [ActiveUserBlabDefFollowInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation followBlabDef($input: FollowBlabDefInput!) {
        followBlabDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function unfollowBlabDef(input: UnfollowBlabDefInput, fragments: DocumentNode[]= [ActiveUserBlabDefFollowInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation unfollowBlabDef($input: UnfollowBlabDefInput!) {
        unfollowBlabDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function enableBlabDefInboundPublicEmail(input: EnableBlabDefInboundPublicEmailInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation enableBlabDefInboundPublicEmail($input: EnableBlabDefInboundPublicEmailInput!) {
        enableBlabDefInboundPublicEmail (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function disableBlabDefInboundPublicEmail(input: DisableBlabDefInboundPublicEmailInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation disableBlabDefInboundPublicEmail($input: DisableBlabDefInboundPublicEmailInput!) {
        disableBlabDefInboundPublicEmail (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function enableActiveUserBlabDefInboundEmail(input: EnableActiveUserBlabDefInboundEmailInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation enableActiveUserBlabDefInboundEmail($input: EnableActiveUserBlabDefInboundEmailInput!) {
        enableActiveUserBlabDefInboundEmail (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function disableActiveUserBlabDefInboundEmail(input: DisableActiveUserBlabDefInboundEmailInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation disableActiveUserBlabDefInboundEmail($input: DisableActiveUserBlabDefInboundEmailInput!) {
        disableActiveUserBlabDefInboundEmail (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function setBlabDefInboundEmailFieldMappings(input: SetBlabDefInboundEmailFieldMappingsInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation setBlabDefInboundEmailFieldMappings($input: SetBlabDefInboundEmailFieldMappingsInput!) {
        setBlabDefInboundEmailFieldMappings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function moveBlabDefToWorkspace(input?: MoveBlabDefToWorkspaceInput, fragments: DocumentNode[]= [BlabDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation moveBlabDefToWorkspace($input: MoveBlabDefToWorkspaceInput) {
        moveBlabDefToWorkspace (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateBlabDefSettings(input: UpdateBlabDefSettingsInput, fragments: DocumentNode[]= [BlabDefSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateBlabDefSettings($input: UpdateBlabDefSettingsInput!) {
        updateBlabDefSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createBlabItem(input: CreateBlabItemInput, fragments: DocumentNode[]= [BlabItemDetailDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createBlabItem($input: CreateBlabItemInput!) {
        createBlabItem (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteBlabItem(input: DeleteBlabItemInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteBlabItem($input: DeleteBlabItemInput!) {
        deleteBlabItem (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteBlabItems(input: DeleteBlabItemsInput, fragments: DocumentNode[]= [DeleteBlabItemsInfoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteBlabItems($input: DeleteBlabItemsInput!) {
        deleteBlabItems (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateBlabItemViewPosition(input: UpdateBlabItemViewPositionInput, fragments: DocumentNode[]= [BlabItemViewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateBlabItemViewPosition($input: UpdateBlabItemViewPositionInput!) {
        updateBlabItemViewPosition (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateBlabItemsFlimVal(input: UpdateBlabItemsFlimValInput, fragments: DocumentNode[]= [UpdateBlabItemsFlimValResponseNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateBlabItemsFlimVal($input: UpdateBlabItemsFlimValInput!) {
        updateBlabItemsFlimVal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addBlabItemActivityReaction(input: AddBlabItemActivityReactionInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addBlabItemActivityReaction($input: AddBlabItemActivityReactionInput!) {
        addBlabItemActivityReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeBlabItemActivityReaction(input: RemoveBlabItemActivityReactionInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeBlabItemActivityReaction($input: RemoveBlabItemActivityReactionInput!) {
        removeBlabItemActivityReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function uploadBlabItemActivityReplyAttachment(input: UploadBlabItemActivityReplyAttachmentInput, attachment: Upload, fragments: DocumentNode[]= [BlabItemActivityReplyAttachmentDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation uploadBlabItemActivityReplyAttachment($input: UploadBlabItemActivityReplyAttachmentInput!, $attachment: Upload!) {
        uploadBlabItemActivityReplyAttachment (input: $input, attachment: $attachment) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input, attachment }
  }
}

export function addBlabItemActivityReplyReaction(input: AddBlabItemActivityReplyReactionInput, fragments: DocumentNode[]= [BlabItemActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addBlabItemActivityReplyReaction($input: AddBlabItemActivityReplyReactionInput!) {
        addBlabItemActivityReplyReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeBlabItemActivityReplyReaction(input: RemoveBlabItemActivityReplyReactionInput, fragments: DocumentNode[]= [BlabItemActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeBlabItemActivityReplyReaction($input: RemoveBlabItemActivityReplyReactionInput!) {
        removeBlabItemActivityReplyReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addBlabItemActivityReply(input?: AddBlabItemActivityReplyInput, fragments: DocumentNode[]= [BlabItemActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addBlabItemActivityReply($input: AddBlabItemActivityReplyInput) {
        addBlabItemActivityReply (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function editBlabItemActivityReply(input?: EditBlabItemActivityReplyInput, fragments: DocumentNode[]= [BlabItemActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation editBlabItemActivityReply($input: EditBlabItemActivityReplyInput) {
        editBlabItemActivityReply (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeBlabItemActivityReply(input?: RemoveBlabItemActivityReplyInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeBlabItemActivityReply($input: RemoveBlabItemActivityReplyInput) {
        removeBlabItemActivityReply (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addBlabItemComment(input: AddBlabItemCommentInput, fragments: DocumentNode[]= [BlabItemCommentActivityDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addBlabItemComment($input: AddBlabItemCommentInput!) {
        addBlabItemComment (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function editBlabItemComment(input: EditBlabItemCommentInput, fragments: DocumentNode[]= [BlabItemCommentActivityDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation editBlabItemComment($input: EditBlabItemCommentInput!) {
        editBlabItemComment (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteBlabItemComment(input: DeleteBlabItemCommentInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteBlabItemComment($input: DeleteBlabItemCommentInput!) {
        deleteBlabItemComment (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function uploadBlabItemCommentAttachment(input: UploadBlabItemCommentAttachmentInput, attachment: Upload, fragments: DocumentNode[]= [BlabItemCommentAttachmentDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation uploadBlabItemCommentAttachment($input: UploadBlabItemCommentAttachmentInput!, $attachment: Upload!) {
        uploadBlabItemCommentAttachment (input: $input, attachment: $attachment) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input, attachment }
  }
}

export function updateBlabItemFocusSection(input: UpdateBlabItemFocusSectionInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateBlabItemFocusSection($input: UpdateBlabItemFocusSectionInput!) {
        updateBlabItemFocusSection (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateChecklistFlimValEntryFocusSection(input: UpdateChecklistFlimValEntryFocusSectionInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateChecklistFlimValEntryFocusSection($input: UpdateChecklistFlimValEntryFocusSectionInput!) {
        updateChecklistFlimValEntryFocusSection (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function followBlabItem(input: FollowBlabItemInput, fragments: DocumentNode[]= [ActiveUserBlabItemInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation followBlabItem($input: FollowBlabItemInput!) {
        followBlabItem (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function unfollowBlabItem(input: UnfollowBlabItemInput, fragments: DocumentNode[]= [ActiveUserBlabItemInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation unfollowBlabItem($input: UnfollowBlabItemInput!) {
        unfollowBlabItem (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function bulkFollowBlabItems(input: BulkFollowBlabItemsInput, fragments: DocumentNode[]= [BulkFollowBlabItemsResponseNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation bulkFollowBlabItems($input: BulkFollowBlabItemsInput!) {
        bulkFollowBlabItems (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function bulkUnfollowBlabItems(input: BulkUnfollowBlabItemsInput, fragments: DocumentNode[]= [BulkFollowBlabItemsResponseNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation bulkUnfollowBlabItems($input: BulkUnfollowBlabItemsInput!) {
        bulkUnfollowBlabItems (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function activateBlabItemNotifications(input: ActivateBlabItemNotificationsInput, fragments: DocumentNode[]= [ActiveUserBlabItemInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation activateBlabItemNotifications($input: ActivateBlabItemNotificationsInput!) {
        activateBlabItemNotifications (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deactivateBlabItemNotifications(input: DeactivateBlabItemNotificationsInput, fragments: DocumentNode[]= [ActiveUserBlabItemInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deactivateBlabItemNotifications($input: DeactivateBlabItemNotificationsInput!) {
        deactivateBlabItemNotifications (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addBlabItemReminder(input: AddBlabItemReminderInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addBlabItemReminder($input: AddBlabItemReminderInput!) {
        addBlabItemReminder (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addBlabItemActivityReminder(input: AddBlabItemActivityReminderInput, fragments: DocumentNode[]= [ReminderDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addBlabItemActivityReminder($input: AddBlabItemActivityReminderInput!) {
        addBlabItemActivityReminder (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function revertBlabItemRevision(input: RevertBlabItemRevisionInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation revertBlabItemRevision($input: RevertBlabItemRevisionInput!) {
        revertBlabItemRevision (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateBlabItemTemplate(input: UpdateBlabItemTemplateInput, fragments: DocumentNode[]= [BlabItemTemplateDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateBlabItemTemplate($input: UpdateBlabItemTemplateInput!) {
        updateBlabItemTemplate (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateBlabItemTemplateTitle(input: UpdateBlabItemTemplateTitleInput, fragments: DocumentNode[]= [BlabItemTemplateDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateBlabItemTemplateTitle($input: UpdateBlabItemTemplateTitleInput!) {
        updateBlabItemTemplateTitle (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteBlabItemTemplate(input: DeleteBlabItemTemplateInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteBlabItemTemplate($input: DeleteBlabItemTemplateInput!) {
        deleteBlabItemTemplate (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeAllDefaultBlabItemTemplates(input: RemoveAllDefaultBlabItemTemplatesInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeAllDefaultBlabItemTemplates($input: RemoveAllDefaultBlabItemTemplatesInput!) {
        removeAllDefaultBlabItemTemplates (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function upsertActiveUserAutoSaveBlabView(input: UpsertActiveUserAutoSaveBlabViewInput, fragments: DocumentNode[]= [ActiveUserAutoSaveBlabViewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertActiveUserAutoSaveBlabView($input: UpsertActiveUserAutoSaveBlabViewInput!) {
        upsertActiveUserAutoSaveBlabView (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createBlabView(input: CreateBlabViewInput, fragments: DocumentNode[]= [BlabViewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createBlabView($input: CreateBlabViewInput!) {
        createBlabView (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function renameBlabView(input: RenameBlabViewInput, fragments: DocumentNode[]= [BlabViewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation renameBlabView($input: RenameBlabViewInput!) {
        renameBlabView (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteBlabView(input: DeleteBlabViewInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteBlabView($input: DeleteBlabViewInput!) {
        deleteBlabView (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function duplicateBlabView(input: DuplicateBlabViewInput, fragments: DocumentNode[]= [BlabViewPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation duplicateBlabView($input: DuplicateBlabViewInput!) {
        duplicateBlabView (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateBlabViewPosition(input: UpdateBlabViewPositionInput, fragments: DocumentNode[]= [BlabViewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateBlabViewPosition($input: UpdateBlabViewPositionInput!) {
        updateBlabViewPosition (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateBlabView(input: UpdateBlabViewInput, fragments: DocumentNode[]= [BlabViewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateBlabView($input: UpdateBlabViewInput!) {
        updateBlabView (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateBlabViewSettings(input: UpdateBlabViewSettingsInput, fragments: DocumentNode[]= [BlabViewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateBlabViewSettings($input: UpdateBlabViewSettingsInput!) {
        updateBlabViewSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function selectBlabView(input?: SelectBlabViewInput, fragments: DocumentNode[]= [BlabViewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation selectBlabView($input: SelectBlabViewInput) {
        selectBlabView (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateBlabViewSplitBy(input?: UpdateBlabViewSplitByInput, fragments: DocumentNode[]= [BlabViewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateBlabViewSplitBy($input: UpdateBlabViewSplitByInput) {
        updateBlabViewSplitBy (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function startChatVideoCall(input?: StartChatVideoCallInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation startChatVideoCall($input: StartChatVideoCallInput) {
        startChatVideoCall (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function findMyOrgs(input: FindMyOrgsInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation findMyOrgs($input: FindMyOrgsInput!) {
        findMyOrgs (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function authenticateWithFindMyOrgsToken(userId: number, token: string, input?: AuthenticateWithFindMyOrgsTokenOptionsInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation authenticateWithFindMyOrgsToken($userId: Int!, $token: String!, $input: AuthenticateWithFindMyOrgsTokenOptionsInput) {
        authenticateWithFindMyOrgsToken (userId: $userId, token: $token, input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { userId, token, input }
  }
}

export function createFlimValAttachment(input: CreateFlimValAttachmentInput, attachment: Upload, fragments: DocumentNode[]= [FlimValAttachmentDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createFlimValAttachment($input: CreateFlimValAttachmentInput!, $attachment: Upload!) {
        createFlimValAttachment (input: $input, attachment: $attachment) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input, attachment }
  }
}

export function updateFlimValAttachment(input: UpdateFlimValAttachmentInput, fragments: DocumentNode[]= [FlimValAttachmentDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateFlimValAttachment($input: UpdateFlimValAttachmentInput!) {
        updateFlimValAttachment (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteFlimValAttachment(input: DeleteFlimValAttachmentInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteFlimValAttachment($input: DeleteFlimValAttachmentInput!) {
        deleteFlimValAttachment (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createFlimValImage(input: CreateFlimValImageInput, image: Upload, fragments: DocumentNode[]= [FlimValImageDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createFlimValImage($input: CreateFlimValImageInput!, $image: Upload!) {
        createFlimValImage (input: $input, image: $image) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input, image }
  }
}

export function updateFlimValImage(input: UpdateFlimValImageInput, fragments: DocumentNode[]= [FlimValImageDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateFlimValImage($input: UpdateFlimValImageInput!) {
        updateFlimValImage (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteFlimValImage(input: DeleteFlimValImageInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteFlimValImage($input: DeleteFlimValImageInput!) {
        deleteFlimValImage (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createFocusSection(input: CreateFocusSectionInput, fragments: DocumentNode[]= [FocusSectionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createFocusSection($input: CreateFocusSectionInput!) {
        createFocusSection (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateFocusSectionName(input: UpdateFocusSectionNameInput, fragments: DocumentNode[]= [FocusSectionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateFocusSectionName($input: UpdateFocusSectionNameInput!) {
        updateFocusSectionName (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateFocusSectionPosition(input: UpdateFocusSectionPositionInput, fragments: DocumentNode[]= [FocusSectionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateFocusSectionPosition($input: UpdateFocusSectionPositionInput!) {
        updateFocusSectionPosition (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeFocusSection(input: RemoveFocusSectionInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeFocusSection($input: RemoveFocusSectionInput!) {
        removeFocusSection (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function expandFocusSection(input: ExpandFocusSectionInput, fragments: DocumentNode[]= [FocusSectionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation expandFocusSection($input: ExpandFocusSectionInput!) {
        expandFocusSection (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function collapseFocusSection(input: CollapseFocusSectionInput, fragments: DocumentNode[]= [FocusSectionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation collapseFocusSection($input: CollapseFocusSectionInput!) {
        collapseFocusSection (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function moveAllFocusSectionEntries(input: MoveAllFocusSectionEntriesInput, fragments: DocumentNode[]= [MoveAllFocusSectionEntriesResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation moveAllFocusSectionEntries($input: MoveAllFocusSectionEntriesInput!) {
        moveAllFocusSectionEntries (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateFocusSettings(input: UpdateFocusSettingsInput, fragments: DocumentNode[]= [FocusSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateFocusSettings($input: UpdateFocusSettingsInput!) {
        updateFocusSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function requestForgotPasswordEmail(input: RequestForgotPasswordEmailInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation requestForgotPasswordEmail($input: RequestForgotPasswordEmailInput!) {
        requestForgotPasswordEmail (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function changePasswordWithForgotPasswordToken(token: string, password: string, input?: ChangePasswordWithForgotPasswordTokenOptionsInput, fragments: DocumentNode[]= [UserSessionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation changePasswordWithForgotPasswordToken($token: String!, $password: String!, $input: ChangePasswordWithForgotPasswordTokenOptionsInput) {
        changePasswordWithForgotPasswordToken (token: $token, password: $password, input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { token, password, input }
  }
}

export function addSearchHistoryItemQuery(input: AddSearchHistoryItemQueryInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addSearchHistoryItemQuery($input: AddSearchHistoryItemQueryInput!) {
        addSearchHistoryItemQuery (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addSearchHistoryItemBlabDef(input: AddSearchHistoryItemBlabDefInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addSearchHistoryItemBlabDef($input: AddSearchHistoryItemBlabDefInput!) {
        addSearchHistoryItemBlabDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addSearchHistoryItemBlabItem(input: AddSearchHistoryItemBlabItemInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addSearchHistoryItemBlabItem($input: AddSearchHistoryItemBlabItemInput!) {
        addSearchHistoryItemBlabItem (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addSearchHistoryItemWorkspace(input: AddSearchHistoryItemWorkspaceInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addSearchHistoryItemWorkspace($input: AddSearchHistoryItemWorkspaceInput!) {
        addSearchHistoryItemWorkspace (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addSearchHistoryItemUser(input: AddSearchHistoryItemUserInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addSearchHistoryItemUser($input: AddSearchHistoryItemUserInput!) {
        addSearchHistoryItemUser (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeSearchHistoryItem(id: number, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeSearchHistoryItem($id: Int!) {
        removeSearchHistoryItem (id: $id) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { id }
  }
}

export function clearSearchHistory(fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation clearSearchHistory {
        clearSearchHistory  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function notifyOfInboundEmail(input: NotifyOfInboundEmailInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation notifyOfInboundEmail($input: NotifyOfInboundEmailInput!) {
        notifyOfInboundEmail (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createLinkPreview(input: CreateLinkPreviewInput, fragments: DocumentNode[]= [LinkPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createLinkPreview($input: CreateLinkPreviewInput!) {
        createLinkPreview (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeNewsfeedActivity(input: RemoveNewsfeedActivityInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeNewsfeedActivity($input: RemoveNewsfeedActivityInput!) {
        removeNewsfeedActivity (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function markAllNotificationsAsRead(fragments: DocumentNode[]= [MarkAllNotificationsAsReadResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation markAllNotificationsAsRead {
        markAllNotificationsAsRead  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function markNotificationAsRead(input: MarkNotificationAsReadInput, fragments: DocumentNode[]= [NotificationDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation markNotificationAsRead($input: MarkNotificationAsReadInput!) {
        markNotificationAsRead (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function markNotificationAsUnread(input: MarkNotificationAsUnreadInput, fragments: DocumentNode[]= [NotificationDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation markNotificationAsUnread($input: MarkNotificationAsUnreadInput!) {
        markNotificationAsUnread (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addOrganizationActivityReaction(input: AddOrganizationActivityReactionInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addOrganizationActivityReaction($input: AddOrganizationActivityReactionInput!) {
        addOrganizationActivityReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeOrganizationActivityReaction(input: RemoveOrganizationActivityReactionInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeOrganizationActivityReaction($input: RemoveOrganizationActivityReactionInput!) {
        removeOrganizationActivityReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addOrganizationActivityReplyReaction(input: AddOrganizationActivityReplyReactionInput, fragments: DocumentNode[]= [OrganizationActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addOrganizationActivityReplyReaction($input: AddOrganizationActivityReplyReactionInput!) {
        addOrganizationActivityReplyReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeOrganizationActivityReplyReaction(input: RemoveOrganizationActivityReplyReactionInput, fragments: DocumentNode[]= [OrganizationActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeOrganizationActivityReplyReaction($input: RemoveOrganizationActivityReplyReactionInput!) {
        removeOrganizationActivityReplyReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addOrganizationActivityReply(input?: AddOrganizationActivityReplyInput, fragments: DocumentNode[]= [OrganizationActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addOrganizationActivityReply($input: AddOrganizationActivityReplyInput) {
        addOrganizationActivityReply (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function editOrganizationActivityReply(input?: EditOrganizationActivityReplyInput, fragments: DocumentNode[]= [OrganizationActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation editOrganizationActivityReply($input: EditOrganizationActivityReplyInput) {
        editOrganizationActivityReply (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeOrganizationActivityReply(input?: RemoveOrganizationActivityReplyInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeOrganizationActivityReply($input: RemoveOrganizationActivityReplyInput) {
        removeOrganizationActivityReply (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createOrganizationInvitations(input: CreateOrganizationInvitationsInput, fragments: DocumentNode[]= [OrganizationInvitationDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createOrganizationInvitations($input: CreateOrganizationInvitationsInput!) {
        createOrganizationInvitations (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function approveOrganizationInvitation(input: ApproveOrganizationInvitationInput, fragments: DocumentNode[]= [OrganizationInvitationDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation approveOrganizationInvitation($input: ApproveOrganizationInvitationInput!) {
        approveOrganizationInvitation (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function denyOrganizationInvitation(input: DenyOrganizationInvitationInput, fragments: DocumentNode[]= [OrganizationInvitationDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation denyOrganizationInvitation($input: DenyOrganizationInvitationInput!) {
        denyOrganizationInvitation (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function resendOrganizationInvitation(input: ResendOrganizationInvitationInput, fragments: DocumentNode[]= [OrganizationInvitationDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation resendOrganizationInvitation($input: ResendOrganizationInvitationInput!) {
        resendOrganizationInvitation (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function copyOrganizationInvitationLink(input: CopyOrganizationInvitationLinkInput, fragments: DocumentNode[]= [CopyOrganizationInvitationLinkResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation copyOrganizationInvitationLink($input: CopyOrganizationInvitationLinkInput!) {
        copyOrganizationInvitationLink (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function revokeOrganizationInvitation(input: RevokeOrganizationInvitationInput, fragments: DocumentNode[]= [OrganizationInvitationDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation revokeOrganizationInvitation($input: RevokeOrganizationInvitationInput!) {
        revokeOrganizationInvitation (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function acceptOrganizationInvitation(token: string, input: AcceptOrganizationInvitationInput, fragments: DocumentNode[]= [UserSessionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation acceptOrganizationInvitation($token: String!, $input: AcceptOrganizationInvitationInput!) {
        acceptOrganizationInvitation (token: $token, input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { token, input }
  }
}

export function updateOrganizationProfile(input: UpdateOrganizationProfileInput, fragments: DocumentNode[]= [OrganizationProfileEditDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateOrganizationProfile($input: UpdateOrganizationProfileInput!) {
        updateOrganizationProfile (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateOrganizationSlug(input: UpdateOrganizationSlugInput, fragments: DocumentNode[]= [UpdateOrganizationSlugResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateOrganizationSlug($input: UpdateOrganizationSlugInput!) {
        updateOrganizationSlug (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function uploadOrganizationLogo(logo: Upload, fragments: DocumentNode[]= [ThumbnailDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation uploadOrganizationLogo($logo: Upload!) {
        uploadOrganizationLogo (logo: $logo) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { logo }
  }
}

export function createOrganizationAuthenticationProvider(input: CreateOrganizationAuthenticationProviderInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createOrganizationAuthenticationProvider($input: CreateOrganizationAuthenticationProviderInput!) {
        createOrganizationAuthenticationProvider (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateOrganizationAuthenticationProvider(input: UpdateOrganizationAuthenticationProviderInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateOrganizationAuthenticationProvider($input: UpdateOrganizationAuthenticationProviderInput!) {
        updateOrganizationAuthenticationProvider (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeOrganizationAuthenticationProvider(input: RemoveOrganizationAuthenticationProviderInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeOrganizationAuthenticationProvider($input: RemoveOrganizationAuthenticationProviderInput!) {
        removeOrganizationAuthenticationProvider (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateOrganizationSettings(input: UpdateOrganizationSettingsInput, fragments: DocumentNode[]= [OrganizationSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateOrganizationSettings($input: UpdateOrganizationSettingsInput!) {
        updateOrganizationSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createOrganizationSmtpSettings(input: CreateOrganizationSmtpSettingsInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createOrganizationSmtpSettings($input: CreateOrganizationSmtpSettingsInput!) {
        createOrganizationSmtpSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateOrganizationSmtpSettings(input: UpdateOrganizationSmtpSettingsInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateOrganizationSmtpSettings($input: UpdateOrganizationSmtpSettingsInput!) {
        updateOrganizationSmtpSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function setDefaultOrganizationSmtpSettings(input: SetDefaultOrganizationSmtpSettingsInput, fragments: DocumentNode[]= [OrganizationSmtpSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation setDefaultOrganizationSmtpSettings($input: SetDefaultOrganizationSmtpSettingsInput!) {
        setDefaultOrganizationSmtpSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeOrganizationSmtpSettings(input: RemoveOrganizationSmtpSettingsInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeOrganizationSmtpSettings($input: RemoveOrganizationSmtpSettingsInput!) {
        removeOrganizationSmtpSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function performOrganizationSetupForIntention(input: PerformOrganizationSetupForIntentionInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation performOrganizationSetupForIntention($input: PerformOrganizationSetupForIntentionInput!) {
        performOrganizationSetupForIntention (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function requestOrganizationSignupVerification(input: RequestOrganizationSignupVerificationInput, fragments: DocumentNode[]= [OrganizationSignupVerificationSessionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation requestOrganizationSignupVerification($input: RequestOrganizationSignupVerificationInput!) {
        requestOrganizationSignupVerification (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function validateOrganizationSignupVerificationCode(input: ValidateOrganizationSignupVerificationCodeInput, fragments: DocumentNode[]= [ValidateOrganizationSignupVerificationCodeResponseNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation validateOrganizationSignupVerificationCode($input: ValidateOrganizationSignupVerificationCodeInput!) {
        validateOrganizationSignupVerificationCode (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function performOrganizationSignup(input: PerformOrganizationSignupInput, fragments: DocumentNode[]= [UserSessionDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation performOrganizationSignup($input: PerformOrganizationSignupInput!) {
        performOrganizationSignup (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function changeOrganizationUserRole(input: ChangeOrganizationUserRoleInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation changeOrganizationUserRole($input: ChangeOrganizationUserRoleInput!) {
        changeOrganizationUserRole (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateOrganizationUserInfo(input: UpdateOrganizationUserInfoInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateOrganizationUserInfo($input: UpdateOrganizationUserInfoInput!) {
        updateOrganizationUserInfo (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function activateOrganizationUser(input: ActivateOrganizationUserInput, fragments: DocumentNode[]= [OrganizationUserDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation activateOrganizationUser($input: ActivateOrganizationUserInput!) {
        activateOrganizationUser (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deactivateOrganizationUser(input: DeactivateOrganizationUserInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deactivateOrganizationUser($input: DeactivateOrganizationUserInput!) {
        deactivateOrganizationUser (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createOrganizationWorkspace(input: CreateOrganizationWorkspaceInput, fragments: DocumentNode[]= [OrganizationWorkspaceDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createOrganizationWorkspace($input: CreateOrganizationWorkspaceInput!) {
        createOrganizationWorkspace (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function gainAdminAccessToOrganizationWorkspace(input: GainAdminAccessToOrganizationWorkspaceInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation gainAdminAccessToOrganizationWorkspace($input: GainAdminAccessToOrganizationWorkspaceInput!) {
        gainAdminAccessToOrganizationWorkspace (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function archiveOrganizationWorkspace(input: ArchiveOrganizationWorkspaceInput, fragments: DocumentNode[]= [OrganizationWorkspaceDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation archiveOrganizationWorkspace($input: ArchiveOrganizationWorkspaceInput!) {
        archiveOrganizationWorkspace (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteOrganizationWorkspace(input: DeleteOrganizationWorkspaceInput, fragments: DocumentNode[]= [DeleteOrganizationWorkspaceResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteOrganizationWorkspace($input: DeleteOrganizationWorkspaceInput!) {
        deleteOrganizationWorkspace (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateOrganizationWorkspacePosition(input: UpdateOrganizationWorkspacePosition, fragments: DocumentNode[]= [OrganizationWorkspaceDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateOrganizationWorkspacePosition($input: UpdateOrganizationWorkspacePosition!) {
        updateOrganizationWorkspacePosition (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addReminder(input: AddReminderInput, fragments: DocumentNode[]= [ReminderDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addReminder($input: AddReminderInput!) {
        addReminder (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function editIncompleteReminder(input: EditIncompleteReminderInput, fragments: DocumentNode[]= [ReminderDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation editIncompleteReminder($input: EditIncompleteReminderInput!) {
        editIncompleteReminder (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeReminder(input: RemoveReminderInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeReminder($input: RemoveReminderInput!) {
        removeReminder (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function setReminderStatus(input: SetReminderStatusInput, fragments: DocumentNode[]= [ReminderDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation setReminderStatus($input: SetReminderStatusInput!) {
        setReminderStatus (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function archiveAllCompletedReminders(fragments: DocumentNode[]= [ArchiveAllCompletedRemindersResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation archiveAllCompletedReminders {
        archiveAllCompletedReminders  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function updateReminderFocusSection(input: UpdateReminderFocusSectionInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateReminderFocusSection($input: UpdateReminderFocusSectionInput!) {
        updateReminderFocusSection (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function getSpreadsheetImportFlimCandidates(table: Upload, fragments: DocumentNode[]= [SpreadsheetImportFlimDefCadidatesDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation getSpreadsheetImportFlimCandidates($table: Upload!) {
        getSpreadsheetImportFlimCandidates (table: $table) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { table }
  }
}

export function importSpreadsheetAsNewBlabDef(input: ImportSpreadsheetInput, fragments: DocumentNode[]= [BlabDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation importSpreadsheetAsNewBlabDef($input: ImportSpreadsheetInput!) {
        importSpreadsheetAsNewBlabDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function unsupportedPlatformRequestMagicLink(input?: UnsupportedPlatformRequestMagicLinkInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation unsupportedPlatformRequestMagicLink($input: UnsupportedPlatformRequestMagicLinkInput) {
        unsupportedPlatformRequestMagicLink (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addUserActivityReaction(input: AddUserActivityReactionInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addUserActivityReaction($input: AddUserActivityReactionInput!) {
        addUserActivityReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeUserActivityReaction(input: RemoveUserActivityReactionInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeUserActivityReaction($input: RemoveUserActivityReactionInput!) {
        removeUserActivityReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addUserActivityReplyReaction(input: AddUserActivityReplyReactionInput, fragments: DocumentNode[]= [UserActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addUserActivityReplyReaction($input: AddUserActivityReplyReactionInput!) {
        addUserActivityReplyReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeUserActivityReplyReaction(input: RemoveUserActivityReplyReactionInput, fragments: DocumentNode[]= [UserActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeUserActivityReplyReaction($input: RemoveUserActivityReplyReactionInput!) {
        removeUserActivityReplyReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addUserActivityReply(input?: AddUserActivityReplyInput, fragments: DocumentNode[]= [UserActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addUserActivityReply($input: AddUserActivityReplyInput) {
        addUserActivityReply (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function editUserActivityReply(input?: EditUserActivityReplyInput, fragments: DocumentNode[]= [UserActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation editUserActivityReply($input: EditUserActivityReplyInput) {
        editUserActivityReply (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeUserActivityReply(input?: RemoveUserActivityReplyInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeUserActivityReply($input: RemoveUserActivityReplyInput) {
        removeUserActivityReply (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function setUserClientAppMetaData(input?: SetUserClientAppMetaDataInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation setUserClientAppMetaData($input: SetUserClientAppMetaDataInput) {
        setUserClientAppMetaData (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function followUser(input: FollowUserInput, fragments: DocumentNode[]= [ActiveUserUserInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation followUser($input: FollowUserInput!) {
        followUser (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function unfollowUser(input: UnfollowUserInput, fragments: DocumentNode[]= [ActiveUserUserInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation unfollowUser($input: UnfollowUserInput!) {
        unfollowUser (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function authenticateWithCredentials(input: AuthenticateWithCredentialsInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation authenticateWithCredentials($input: AuthenticateWithCredentialsInput!) {
        authenticateWithCredentials (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function activateUserNotifications(input: ActivateUserNotificationsInput, fragments: DocumentNode[]= [ActiveUserUserInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation activateUserNotifications($input: ActivateUserNotificationsInput!) {
        activateUserNotifications (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deactivateUserNotifications(input: DeactivateUserNotificationsInput, fragments: DocumentNode[]= [ActiveUserUserInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deactivateUserNotifications($input: DeactivateUserNotificationsInput!) {
        deactivateUserNotifications (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addDeviceRegistrationToken(input: AddDeviceRegistrationTokenInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addDeviceRegistrationToken($input: AddDeviceRegistrationTokenInput!) {
        addDeviceRegistrationToken (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addWebPushSubscription(input: AddWebPushSubscriptionInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addWebPushSubscription($input: AddWebPushSubscriptionInput!) {
        addWebPushSubscription (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeUserSession(userId: number, options?: RemoveUserSessionOptionsInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeUserSession($userId: Int!, $options: RemoveUserSessionOptionsInput) {
        removeUserSession (userId: $userId, options: $options) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { userId, options }
  }
}

export function requestChangeUserEmailVerification(input: RequestChangeUserEmailVerficiationInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation requestChangeUserEmailVerification($input: RequestChangeUserEmailVerficiationInput!) {
        requestChangeUserEmailVerification (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function performChangeUserEmail(input: PerformChangeUserEmailInput, fragments: DocumentNode[]= [PerformChangeUserEmailResultNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation performChangeUserEmail($input: PerformChangeUserEmailInput!) {
        performChangeUserEmail (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function connectMicrosoftIntegration(fragments: DocumentNode[]= [MicrosoftUserAuthenticationRequiredDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation connectMicrosoftIntegration {
        connectMicrosoftIntegration  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function disconnectMicrosoftIntegration(fragments: DocumentNode[]= [UserSettingsMicrosoftIntegrationStatusDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation disconnectMicrosoftIntegration {
        disconnectMicrosoftIntegration  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function changeUserPassword(input: ChangeUserPasswordInput, fragments: DocumentNode[]= [ChangeUserPasswordResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation changeUserPassword($input: ChangeUserPasswordInput!) {
        changeUserPassword (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateUserSettings(input: UpdateUserSettingsInput, fragments: DocumentNode[]= [UserSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateUserSettings($input: UpdateUserSettingsInput!) {
        updateUserSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function restoreFromUserTrash(input: RestoreFromUserTrashInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation restoreFromUserTrash($input: RestoreFromUserTrashInput!) {
        restoreFromUserTrash (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeFromUserTrash(input: RemoveFromUserTrashInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeFromUserTrash($input: RemoveFromUserTrashInput!) {
        removeFromUserTrash (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function emptyUserTrash(fragments: DocumentNode[]= [EmptyUserTrashResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation emptyUserTrash {
        emptyUserTrash  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: {  }
  }
}

export function upsertActiveUserGlobalWorkflowRunsFilteringSettings(input?: UpsertActiveUserGlobalWorkflowRunsFilteringSettingsInput, fragments: DocumentNode[]= [ActiveUserGlobalWorkflowRunsFilteringSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertActiveUserGlobalWorkflowRunsFilteringSettings($input: UpsertActiveUserGlobalWorkflowRunsFilteringSettingsInput) {
        upsertActiveUserGlobalWorkflowRunsFilteringSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function generateWorkflowDefCode(input: GenerateWorkflowDefCodeInput, fragments: DocumentNode[]= [GenerateWorkflowDefCodeResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation generateWorkflowDefCode($input: GenerateWorkflowDefCodeInput!) {
        generateWorkflowDefCode (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createWorkflowDef(input: CreateWorkflowDefInput, fragments: DocumentNode[]= [WorkflowDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createWorkflowDef($input: CreateWorkflowDefInput!) {
        createWorkflowDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function duplicateWorkflowDef(input: DuplicateWorkflowDefInput, fragments: DocumentNode[]= [WorkflowDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation duplicateWorkflowDef($input: DuplicateWorkflowDefInput!) {
        duplicateWorkflowDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createEmptyWorkflowDef(input?: CreateEmptyWorkflowDefInput, fragments: DocumentNode[]= [WorkflowDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createEmptyWorkflowDef($input: CreateEmptyWorkflowDefInput) {
        createEmptyWorkflowDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function pauseWorkflowDef(input?: PauseWorkflowDefInput, fragments: DocumentNode[]= [WorkflowDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation pauseWorkflowDef($input: PauseWorkflowDefInput) {
        pauseWorkflowDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function unpauseWorkflowDef(input?: UnpauseWorkflowDefInput, fragments: DocumentNode[]= [WorkflowDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation unpauseWorkflowDef($input: UnpauseWorkflowDefInput) {
        unpauseWorkflowDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateWorkflowDef(input: UpdateWorkflowDefInput, fragments: DocumentNode[]= [WorkflowDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateWorkflowDef($input: UpdateWorkflowDefInput!) {
        updateWorkflowDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteWorkflowDef(input: DeleteWorkflowDefInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteWorkflowDef($input: DeleteWorkflowDefInput!) {
        deleteWorkflowDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createCallableWorkflowDef(input: CreateCallableWorkflowDefInput, fragments: DocumentNode[]= [WorkflowDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createCallableWorkflowDef($input: CreateCallableWorkflowDefInput!) {
        createCallableWorkflowDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function runWorkflowManually(input?: RunWorkflowManuallyInput, fragments: DocumentNode[]= [ManualRunsTriggeredDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation runWorkflowManually($input: RunWorkflowManuallyInput) {
        runWorkflowManually (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function cancelWorkflowRunsOfWorkflowDef(input?: CancelWorkflowRunsOfWorkflowDefInput, fragments: DocumentNode[]= [CancelWorkflowRunsResultNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation cancelWorkflowRunsOfWorkflowDef($input: CancelWorkflowRunsOfWorkflowDefInput) {
        cancelWorkflowRunsOfWorkflowDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function simulateWorkflow(input: SimulateWorkflowInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation simulateWorkflow($input: SimulateWorkflowInput!) {
        simulateWorkflow (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createWorkflowCenterWebhook(input: CreateWorkflowCenterWebhookInput, fragments: DocumentNode[]= [WorkflowCenterWebhookDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createWorkflowCenterWebhook($input: CreateWorkflowCenterWebhookInput!) {
        createWorkflowCenterWebhook (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateWorkflowCenterWebhook(input: UpdateWorkflowCenterWebhookInput, fragments: DocumentNode[]= [UpdateWorkflowCenterWebhookResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateWorkflowCenterWebhook($input: UpdateWorkflowCenterWebhookInput!) {
        updateWorkflowCenterWebhook (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteWorkflowCenterWebhook(input: DeleteWorkflowCenterWebhookInput, fragments: DocumentNode[]= [WorkflowCenterWebhookDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteWorkflowCenterWebhook($input: DeleteWorkflowCenterWebhookInput!) {
        deleteWorkflowCenterWebhook (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function requestWorkflowCenterWebhookVerification(input: RequestWorkflowCenterWebhookVerificationInput, fragments: DocumentNode[]= [WorkflowCenterWebhookDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation requestWorkflowCenterWebhookVerification($input: RequestWorkflowCenterWebhookVerificationInput!) {
        requestWorkflowCenterWebhookVerification (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function followWorkflowDef(input: FollowWorkflowDefInput, fragments: DocumentNode[]= [ActiveUserWorkflowDefInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation followWorkflowDef($input: FollowWorkflowDefInput!) {
        followWorkflowDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function unfollowWorkflowDef(input: UnfollowWorkflowDefInput, fragments: DocumentNode[]= [ActiveUserWorkflowDefInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation unfollowWorkflowDef($input: UnfollowWorkflowDefInput!) {
        unfollowWorkflowDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addWorkspaceActivityReaction(input: AddWorkspaceActivityReactionInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addWorkspaceActivityReaction($input: AddWorkspaceActivityReactionInput!) {
        addWorkspaceActivityReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeWorkspaceActivityReaction(input: RemoveWorkspaceActivityReactionInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeWorkspaceActivityReaction($input: RemoveWorkspaceActivityReactionInput!) {
        removeWorkspaceActivityReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addWorkspaceActivityReplyReaction(input: AddWorkspaceActivityReplyReactionInput, fragments: DocumentNode[]= [WorkspaceActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addWorkspaceActivityReplyReaction($input: AddWorkspaceActivityReplyReactionInput!) {
        addWorkspaceActivityReplyReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeWorkspaceActivityReplyReaction(input: RemoveWorkspaceActivityReplyReactionInput, fragments: DocumentNode[]= [WorkspaceActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeWorkspaceActivityReplyReaction($input: RemoveWorkspaceActivityReplyReactionInput!) {
        removeWorkspaceActivityReplyReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateWorkspaceBlabDefPosition(input: UpdateWorkspaceBlabDefPositionInput, fragments: DocumentNode[]= [WorkspaceBlabDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateWorkspaceBlabDefPosition($input: UpdateWorkspaceBlabDefPositionInput!) {
        updateWorkspaceBlabDefPosition (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function duplicateWorkspace(input: DuplicateWorkspaceInput, fragments: DocumentNode[]= [WorkspaceDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation duplicateWorkspace($input: DuplicateWorkspaceInput!) {
        duplicateWorkspace (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeWorkspaceMembership(input: RemoveWorkspaceMembershipInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeWorkspaceMembership($input: RemoveWorkspaceMembershipInput!) {
        removeWorkspaceMembership (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function changeWorkspaceMembershipRole(input: ChangeWorkspaceMembershipRoleInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation changeWorkspaceMembershipRole($input: ChangeWorkspaceMembershipRoleInput!) {
        changeWorkspaceMembershipRole (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addWorkspaceMemberships(input?: AddWorkspaceMembershipsInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addWorkspaceMemberships($input: AddWorkspaceMembershipsInput) {
        addWorkspaceMemberships (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateWorkspaceSettings(input: UpdateWorkspaceSettingsInput, fragments: DocumentNode[]= [WorkspaceSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateWorkspaceSettings($input: UpdateWorkspaceSettingsInput!) {
        updateWorkspaceSettings (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateWorkspaceSlug(input: UpdateWorkspaceSlugInput, fragments: DocumentNode[]= [UpdateWorkspaceSlugResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateWorkspaceSlug($input: UpdateWorkspaceSlugInput!) {
        updateWorkspaceSlug (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function enableWorkspaceShareLink(input: EnableWorkspaceShareLinkInput, fragments: DocumentNode[]= [WorkspaceShareSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation enableWorkspaceShareLink($input: EnableWorkspaceShareLinkInput!) {
        enableWorkspaceShareLink (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function disableWorkspaceShareLink(input: DisableWorkspaceShareLinkInput, fragments: DocumentNode[]= [WorkspaceShareSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation disableWorkspaceShareLink($input: DisableWorkspaceShareLinkInput!) {
        disableWorkspaceShareLink (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function enableWorkspaceShareLinkDuplication(input: EnableWorkspaceShareLinkDuplicationInput, fragments: DocumentNode[]= [WorkspaceShareSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation enableWorkspaceShareLinkDuplication($input: EnableWorkspaceShareLinkDuplicationInput!) {
        enableWorkspaceShareLinkDuplication (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function disableWorkspaceShareLinkDuplication(input: DisableWorkspaceShareLinkDuplicationInput, fragments: DocumentNode[]= [WorkspaceShareSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation disableWorkspaceShareLinkDuplication($input: DisableWorkspaceShareLinkDuplicationInput!) {
        disableWorkspaceShareLinkDuplication (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function generateWorkspaceShareLinkToken(input: GenerateWorkspaceShareLinkTokenInput, fragments: DocumentNode[]= [WorkspaceShareSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation generateWorkspaceShareLinkToken($input: GenerateWorkspaceShareLinkTokenInput!) {
        generateWorkspaceShareLinkToken (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function setWorkspaceSharePermissions(input: SetWorkspaceSharePermissionsInput, fragments: DocumentNode[]= [WorkspaceShareSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation setWorkspaceSharePermissions($input: SetWorkspaceSharePermissionsInput!) {
        setWorkspaceSharePermissions (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function changeWorkspaceUserExplicitRole(input: ChangeWorkspaceUserExplicitRoleInput, fragments: DocumentNode[]= [WorkspaceShareSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation changeWorkspaceUserExplicitRole($input: ChangeWorkspaceUserExplicitRoleInput!) {
        changeWorkspaceUserExplicitRole (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeWorkspaceUserExplicitRole(input: RemoveWorkspaceUserExplicitRoleInput, fragments: DocumentNode[]= [WorkspaceShareSettingsDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeWorkspaceUserExplicitRole($input: RemoveWorkspaceUserExplicitRoleInput!) {
        removeWorkspaceUserExplicitRole (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function duplicateSharedWorkspace(input?: DuplicateSharedWorkspaceInput, fragments: DocumentNode[]= [DuplicateSharedWorkspaceResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation duplicateSharedWorkspace($input: DuplicateSharedWorkspaceInput) {
        duplicateSharedWorkspace (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addWorkspaceTemplate(input: AddWorkspaceTemplateInput, fragments: DocumentNode[]= [WorkspaceDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addWorkspaceTemplate($input: AddWorkspaceTemplateInput!) {
        addWorkspaceTemplate (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addWorkspaceFromWorkspaceTemplate(input: AddWorkspaceFromWorkspaceTemplateInput, fragments: DocumentNode[]= [WorkspaceDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addWorkspaceFromWorkspaceTemplate($input: AddWorkspaceFromWorkspaceTemplateInput!) {
        addWorkspaceFromWorkspaceTemplate (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addBlabDefsFromWorkspaceTemplate(input: AddBlabDefsFromWorkspaceTemplateInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addBlabDefsFromWorkspaceTemplate($input: AddBlabDefsFromWorkspaceTemplateInput!) {
        addBlabDefsFromWorkspaceTemplate (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function publishWorkspaceAsTemplate(input: PublishWorkspaceTemplateInput, fragments: DocumentNode[]= [WorkspaceTemplateDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation publishWorkspaceAsTemplate($input: PublishWorkspaceTemplateInput!) {
        publishWorkspaceAsTemplate (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateWorkspaceTemplateInfo(input: UpdateWorkspaceTemplateInfoInput, fragments: DocumentNode[]= [WorkspaceTemplateDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateWorkspaceTemplateInfo($input: UpdateWorkspaceTemplateInfoInput!) {
        updateWorkspaceTemplateInfo (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function uploadWorkspaceTemplatePreviewImage(upload: Upload, fragments: DocumentNode[]= [ThumbnailDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation uploadWorkspaceTemplatePreviewImage($upload: Upload!) {
        uploadWorkspaceTemplatePreviewImage (upload: $upload) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { upload }
  }
}

export function uploadWorkspaceTemplateTitleImage(upload: Upload, fragments: DocumentNode[]= [ThumbnailDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation uploadWorkspaceTemplateTitleImage($upload: Upload!) {
        uploadWorkspaceTemplateTitleImage (upload: $upload) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { upload }
  }
}

export function removeWorkspaceTemplate(input: RemoveWorkspaceTemplateInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeWorkspaceTemplate($input: RemoveWorkspaceTemplateInput!) {
        removeWorkspaceTemplate (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteFlimDef(input: DeleteFlimDefInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteFlimDef($input: DeleteFlimDefInput!) {
        deleteFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addChatActivityReaction(input: AddChatActivityReactionInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addChatActivityReaction($input: AddChatActivityReactionInput!) {
        addChatActivityReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function removeChatActivityReaction(input: RemoveChatActivityReactionInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation removeChatActivityReaction($input: RemoveChatActivityReactionInput!) {
        removeChatActivityReaction (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function postChatMessage(input: PostChatMessageInput, fragments: DocumentNode[]= [ChatMessageActivityDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation postChatMessage($input: PostChatMessageInput!) {
        postChatMessage (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteChatMessage(input: DeleteChatMessageInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteChatMessage($input: DeleteChatMessageInput!) {
        deleteChatMessage (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function uploadChatMessageAttachment(input: UploadChatMessageAttachmentInput, attachment: Upload, fragments: DocumentNode[]= [ChatMessageAttachmentDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation uploadChatMessageAttachment($input: UploadChatMessageAttachmentInput!, $attachment: Upload!) {
        uploadChatMessageAttachment (input: $input, attachment: $attachment) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input, attachment }
  }
}

export function startOrContinueDialog(input: StartOrContinueDialogInput, fragments: DocumentNode[]= [ChatDialogDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation startOrContinueDialog($input: StartOrContinueDialogInput!) {
        startOrContinueDialog (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createChatGroup(input: CreateChatGroupInput, logo?: Upload, fragments: DocumentNode[]= [ChatGroupDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createChatGroup($input: CreateChatGroupInput!, $logo: Upload) {
        createChatGroup (input: $input, logo: $logo) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input, logo }
  }
}

export function leaveChatGroup(input?: LeaveChatGroupInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation leaveChatGroup($input: LeaveChatGroupInput) {
        leaveChatGroup (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addUserToChatGroup(input: AddUserToChatGroupInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addUserToChatGroup($input: AddUserToChatGroupInput!) {
        addUserToChatGroup (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateChatGroupInfo(input: UpdateChatGroupInfoInput, logo?: Upload, fragments: DocumentNode[]= [ChatGroupDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateChatGroupInfo($input: UpdateChatGroupInfoInput!, $logo: Upload) {
        updateChatGroupInfo (input: $input, logo: $logo) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input, logo }
  }
}

export function muteConversation(input: MuteConversationInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation muteConversation($input: MuteConversationInput!) {
        muteConversation (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function unmuteConversation(input: UnmuteConversationInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation unmuteConversation($input: UnmuteConversationInput!) {
        unmuteConversation (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function markConversationAsRead(input: MarkConversationAsReadInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation markConversationAsRead($input: MarkConversationAsReadInput!) {
        markConversationAsRead (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function convertMultiAttachmentFlimToSingleAttachmentFlim(input: ConvertMultiAttachmentFlimToSingleAttachmentFlimInput, fragments: DocumentNode[]= [ConvertMultiAttachmentFlimToSingleAttachmentFlimResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation convertMultiAttachmentFlimToSingleAttachmentFlim($input: ConvertMultiAttachmentFlimToSingleAttachmentFlimInput!) {
        convertMultiAttachmentFlimToSingleAttachmentFlim (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function convertSingleAttachmentFlimToMultiAttachmentFlim(input: ConvertSingleAttachmentFlimToMultiAttachmentFlimInput, fragments: DocumentNode[]= [ConvertSingleAttachmentFlimToMultiAttachmentFlimResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation convertSingleAttachmentFlimToMultiAttachmentFlim($input: ConvertSingleAttachmentFlimToMultiAttachmentFlimInput!) {
        convertSingleAttachmentFlimToMultiAttachmentFlim (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function convertMultiCategoryFlimToSingleCategoryFlim(input: ConvertMultiCategoryFlimToSingleCategoryFlimInput, fragments: DocumentNode[]= [ConvertMultiCategoryFlimToSingleCategoryFlimResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation convertMultiCategoryFlimToSingleCategoryFlim($input: ConvertMultiCategoryFlimToSingleCategoryFlimInput!) {
        convertMultiCategoryFlimToSingleCategoryFlim (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function convertSingleCategoryFlimToStatusFlim(input: ConvertSingleCategoryFlimToStatusFlimInput, fragments: DocumentNode[]= [ConvertSingleCategoryFlimToStatusFlimResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation convertSingleCategoryFlimToStatusFlim($input: ConvertSingleCategoryFlimToStatusFlimInput!) {
        convertSingleCategoryFlimToStatusFlim (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function convertSingleCategoryFlimToSingleTextFlim(input: ConvertSingleCategoryFlimToSingleTextFlimInput, fragments: DocumentNode[]= [ConvertSingleCategoryFlimToSingleTextFlimResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation convertSingleCategoryFlimToSingleTextFlim($input: ConvertSingleCategoryFlimToSingleTextFlimInput!) {
        convertSingleCategoryFlimToSingleTextFlim (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function convertSingleCategoryFlimToMultiCategoryFlim(input: ConvertSingleCategoryFlimToMultiCategoryFlimInput, fragments: DocumentNode[]= [ConvertSingleCategoryFlimToMultiCategoryFlimResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation convertSingleCategoryFlimToMultiCategoryFlim($input: ConvertSingleCategoryFlimToMultiCategoryFlimInput!) {
        convertSingleCategoryFlimToMultiCategoryFlim (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function convertRangeDateFlimToSingleDateFlim(input: ConvertRangeDateFlimToSingleDateFlimInput, fragments: DocumentNode[]= [ConvertRangeDateFlimToSingleDateFlimResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation convertRangeDateFlimToSingleDateFlim($input: ConvertRangeDateFlimToSingleDateFlimInput!) {
        convertRangeDateFlimToSingleDateFlim (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function convertSingleDateFlimToRangeDateFlim(input: ConvertSingleDateFlimToRangeDateFlimInput, fragments: DocumentNode[]= [ConvertSingleDateFlimToRangeDateFlimResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation convertSingleDateFlimToRangeDateFlim($input: ConvertSingleDateFlimToRangeDateFlimInput!) {
        convertSingleDateFlimToRangeDateFlim (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function convertMultiRelationFlimToSingleRelationFlim(input: ConvertMultiRelationFlimToSingleRelationFlimInput, fragments: DocumentNode[]= [ConvertMultiRelationFlimToSingleRelationFlimResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation convertMultiRelationFlimToSingleRelationFlim($input: ConvertMultiRelationFlimToSingleRelationFlimInput!) {
        convertMultiRelationFlimToSingleRelationFlim (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function convertSingleRelationFlimToMultiRelationFlim(input: ConvertSingleRelationFlimToMultiRelationFlimInput, fragments: DocumentNode[]= [ConvertSingleRelationFlimToMultiRelationFlimResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation convertSingleRelationFlimToMultiRelationFlim($input: ConvertSingleRelationFlimToMultiRelationFlimInput!) {
        convertSingleRelationFlimToMultiRelationFlim (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function convertMultiTextFlimToSingleTextFlim(input: ConvertMultiTextFlimToSingleTextFlimInput, fragments: DocumentNode[]= [ConvertMultiTextFlimToSingleTextFlimResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation convertMultiTextFlimToSingleTextFlim($input: ConvertMultiTextFlimToSingleTextFlimInput!) {
        convertMultiTextFlimToSingleTextFlim (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function convertSingleTextFlimToMultiTextFlim(input: ConvertSingleTextFlimToMultiTextFlimInput, fragments: DocumentNode[]= [ConvertSingleTextFlimToMultiTextFlimResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation convertSingleTextFlimToMultiTextFlim($input: ConvertSingleTextFlimToMultiTextFlimInput!) {
        convertSingleTextFlimToMultiTextFlim (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function convertSingleTextFlimToSingleCategoryFlim(input: ConvertSingleTextFlimToSingleCategoryFlimInput, fragments: DocumentNode[]= [ConvertSingleTextFlimToSingleCategoryFlimResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation convertSingleTextFlimToSingleCategoryFlim($input: ConvertSingleTextFlimToSingleCategoryFlimInput!) {
        convertSingleTextFlimToSingleCategoryFlim (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function convertSingleTextFlimToNumberFlim(input: ConvertSingleTextFlimToNumberFlimInput, fragments: DocumentNode[]= [ConvertSingleTextFlimToNumberFlimResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation convertSingleTextFlimToNumberFlim($input: ConvertSingleTextFlimToNumberFlimInput!) {
        convertSingleTextFlimToNumberFlim (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function convertSingleTextFlimToMultiLinkFlim(input: ConvertSingleTextFlimToMultiLinkFlimInput, fragments: DocumentNode[]= [ConvertSingleTextFlimToMultiLinkFlimResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation convertSingleTextFlimToMultiLinkFlim($input: ConvertSingleTextFlimToMultiLinkFlimInput!) {
        convertSingleTextFlimToMultiLinkFlim (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function convertMultiUserFlimToSingleUserFlim(input: ConvertMultiUserFlimToSingleUserFlimInput, fragments: DocumentNode[]= [ConvertMultiUserFlimToSingleUserFlimResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation convertMultiUserFlimToSingleUserFlim($input: ConvertMultiUserFlimToSingleUserFlimInput!) {
        convertMultiUserFlimToSingleUserFlim (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function convertSingleUserFlimToMultiUserFlim(input: ConvertSingleUserFlimToMultiUserFlimInput, fragments: DocumentNode[]= [ConvertSingleUserFlimToMultiUserFlimResultDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation convertSingleUserFlimToMultiUserFlim($input: ConvertSingleUserFlimToMultiUserFlimInput!) {
        convertSingleUserFlimToMultiUserFlim (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateBlabItemFocusEntryStatus(input: UpdateBlabItemFocusEntryStatusInput, fragments: DocumentNode[]= [BlabItemFocusEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateBlabItemFocusEntryStatus($input: UpdateBlabItemFocusEntryStatusInput!) {
        updateBlabItemFocusEntryStatus (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateBlabItemFocusEntryDueAt(input: UpdateBlabItemFocusEntryDueAtInput, fragments: DocumentNode[]= [BlabItemFocusEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateBlabItemFocusEntryDueAt($input: UpdateBlabItemFocusEntryDueAtInput!) {
        updateBlabItemFocusEntryDueAt (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateChecklistFocusEntryTitle(input: UpdateChecklistFocusEntryTitleInput, fragments: DocumentNode[]= [ChecklistFocusEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateChecklistFocusEntryTitle($input: UpdateChecklistFocusEntryTitleInput!) {
        updateChecklistFocusEntryTitle (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateChecklistFocusEntryDescription(input: UpdateChecklistFocusEntryDescriptionInput, fragments: DocumentNode[]= [ChecklistFocusEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateChecklistFocusEntryDescription($input: UpdateChecklistFocusEntryDescriptionInput!) {
        updateChecklistFocusEntryDescription (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function markChecklistFocusEntryCompleted(input: MarkChecklistFocusEntryCompletedInput, fragments: DocumentNode[]= [ChecklistFocusEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation markChecklistFocusEntryCompleted($input: MarkChecklistFocusEntryCompletedInput!) {
        markChecklistFocusEntryCompleted (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function markChecklistFocusEntryIncomplete(input: MarkChecklistFocusEntryIncompleteInput, fragments: DocumentNode[]= [ChecklistFocusEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation markChecklistFocusEntryIncomplete($input: MarkChecklistFocusEntryIncompleteInput!) {
        markChecklistFocusEntryIncomplete (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateChecklistFocusEntryDueAt(input: UpdateChecklistFocusEntryDueAtInput, fragments: DocumentNode[]= [ChecklistFocusEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateChecklistFocusEntryDueAt($input: UpdateChecklistFocusEntryDueAtInput!) {
        updateChecklistFocusEntryDueAt (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function moveFocusEntry(input: MoveFocusEntryInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation moveFocusEntry($input: MoveFocusEntryInput!) {
        moveFocusEntry (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateFocusEntrySection(input: UpdateFocusEntrySectionInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateFocusEntrySection($input: UpdateFocusEntrySectionInput!) {
        updateFocusEntrySection (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function addReminderFocusEntry(input: AddReminderFocusEntryInput, fragments: DocumentNode[]= [ReminderFocusEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation addReminderFocusEntry($input: AddReminderFocusEntryInput!) {
        addReminderFocusEntry (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateReminderFocusEntryTitle(input: UpdateReminderFocusEntryTitleInput, fragments: DocumentNode[]= [ReminderFocusEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateReminderFocusEntryTitle($input: UpdateReminderFocusEntryTitleInput!) {
        updateReminderFocusEntryTitle (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateReminderFocusEntryDescription(input: UpdateReminderFocusEntryDescriptionInput, fragments: DocumentNode[]= [ReminderFocusEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateReminderFocusEntryDescription($input: UpdateReminderFocusEntryDescriptionInput!) {
        updateReminderFocusEntryDescription (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function markReminderFocusEntryCompleted(input: MarkReminderFocusEntryCompletedInput, fragments: DocumentNode[]= [ReminderFocusEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation markReminderFocusEntryCompleted($input: MarkReminderFocusEntryCompletedInput!) {
        markReminderFocusEntryCompleted (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function markReminderFocusEntryIncomplete(input: MarkReminderFocusEntryIncompleteInput, fragments: DocumentNode[]= [ReminderFocusEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation markReminderFocusEntryIncomplete($input: MarkReminderFocusEntryIncompleteInput!) {
        markReminderFocusEntryIncomplete (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateReminderFocusEntryDueAt(input: UpdateReminderFocusEntryDueAtInput, fragments: DocumentNode[]= [ReminderFocusEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateReminderFocusEntryDueAt($input: UpdateReminderFocusEntryDueAtInput!) {
        updateReminderFocusEntryDueAt (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createMultiAttachmentFlimDef(input: CreateMultiAttachmentFlimDefInput, fragments: DocumentNode[]= [MultiAttachmentFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createMultiAttachmentFlimDef($input: CreateMultiAttachmentFlimDefInput!) {
        createMultiAttachmentFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateMultiAttachmentFlimDef(input: UpdateMultiAttachmentFlimDefInput, fragments: DocumentNode[]= [MultiAttachmentFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateMultiAttachmentFlimDef($input: UpdateMultiAttachmentFlimDefInput!) {
        updateMultiAttachmentFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createSingleAttachmentFlimDef(input: CreateSingleAttachmentFlimDefInput, fragments: DocumentNode[]= [SingleAttachmentFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createSingleAttachmentFlimDef($input: CreateSingleAttachmentFlimDefInput!) {
        createSingleAttachmentFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateSingleAttachmentFlimDef(input: UpdateSingleAttachmentFlimDefInput, fragments: DocumentNode[]= [SingleAttachmentFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateSingleAttachmentFlimDef($input: UpdateSingleAttachmentFlimDefInput!) {
        updateSingleAttachmentFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createCalculationFlimDef(input: CreateCalculationFlimDefInput, fragments: DocumentNode[]= [CalculationFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createCalculationFlimDef($input: CreateCalculationFlimDefInput!) {
        createCalculationFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateCalculationFlimDef(input: UpdateCalculationFlimDefInput, fragments: DocumentNode[]= [CalculationFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateCalculationFlimDef($input: UpdateCalculationFlimDefInput!) {
        updateCalculationFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createMultiCategoryFlimDef(input: CreateMultiCategoryFlimDefInput, fragments: DocumentNode[]= [MultiCategoryFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createMultiCategoryFlimDef($input: CreateMultiCategoryFlimDefInput!) {
        createMultiCategoryFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateMultiCategoryFlimDef(input?: UpdateMultiCategoryFlimDefInput, fragments: DocumentNode[]= [MultiCategoryFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateMultiCategoryFlimDef($input: UpdateMultiCategoryFlimDefInput) {
        updateMultiCategoryFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createSingleCategoryFlimDef(input: CreateSingleCategoryFlimDefInput, fragments: DocumentNode[]= [SingleCategoryFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createSingleCategoryFlimDef($input: CreateSingleCategoryFlimDefInput!) {
        createSingleCategoryFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateSingleCategoryFlimDef(input?: UpdateSingleCategoryFlimDefInput, fragments: DocumentNode[]= [SingleCategoryFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateSingleCategoryFlimDef($input: UpdateSingleCategoryFlimDefInput) {
        updateSingleCategoryFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createChecklistFlimDef(input: CreateChecklistFlimDefInput, fragments: DocumentNode[]= [ChecklistFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createChecklistFlimDef($input: CreateChecklistFlimDefInput!) {
        createChecklistFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateChecklistFlimDef(input: UpdateChecklistFlimDefInput, fragments: DocumentNode[]= [ChecklistFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateChecklistFlimDef($input: UpdateChecklistFlimDefInput!) {
        updateChecklistFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createCreatedAtFlimDef(input: CreateCreatedAtFlimDefInput, fragments: DocumentNode[]= [CreatedAtFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createCreatedAtFlimDef($input: CreateCreatedAtFlimDefInput!) {
        createCreatedAtFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateCreatedAtFlimDef(input: UpdateCreatedAtFlimDefInput, fragments: DocumentNode[]= [CreatedAtFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateCreatedAtFlimDef($input: UpdateCreatedAtFlimDefInput!) {
        updateCreatedAtFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createCreatedByFlimDef(input: CreateCreatedByFlimDefInput, fragments: DocumentNode[]= [CreatedByFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createCreatedByFlimDef($input: CreateCreatedByFlimDefInput!) {
        createCreatedByFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateCreatedByFlimDef(input: UpdateCreatedByFlimDefInput, fragments: DocumentNode[]= [CreatedByFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateCreatedByFlimDef($input: UpdateCreatedByFlimDefInput!) {
        updateCreatedByFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createRangeDateFlimDef(input: CreateRangeDateFlimDefInput, fragments: DocumentNode[]= [RangeDateFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createRangeDateFlimDef($input: CreateRangeDateFlimDefInput!) {
        createRangeDateFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateRangeDateFlimDef(input: UpdateRangeDateFlimDefInput, fragments: DocumentNode[]= [RangeDateFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateRangeDateFlimDef($input: UpdateRangeDateFlimDefInput!) {
        updateRangeDateFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createSingleDateFlimDef(input: CreateSingleDateFlimDefInput, fragments: DocumentNode[]= [SingleDateFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createSingleDateFlimDef($input: CreateSingleDateFlimDefInput!) {
        createSingleDateFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateSingleDateFlimDef(input: UpdateSingleDateFlimDefInput, fragments: DocumentNode[]= [SingleDateFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateSingleDateFlimDef($input: UpdateSingleDateFlimDefInput!) {
        updateSingleDateFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createMultiEmailFlimDef(input: CreateMultiEmailFlimDefInput, fragments: DocumentNode[]= [MultiEmailFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createMultiEmailFlimDef($input: CreateMultiEmailFlimDefInput!) {
        createMultiEmailFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateMultiEmailFlimDef(input: UpdateMultiEmailFlimDefInput, fragments: DocumentNode[]= [MultiEmailFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateMultiEmailFlimDef($input: UpdateMultiEmailFlimDefInput!) {
        updateMultiEmailFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createMultiImageFlimDef(input: CreateMultiImageFlimDefInput, fragments: DocumentNode[]= [MultiImageFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createMultiImageFlimDef($input: CreateMultiImageFlimDefInput!) {
        createMultiImageFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateMultiImageFlimDef(input: UpdateMultiImageFlimDefInput, fragments: DocumentNode[]= [MultiImageFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateMultiImageFlimDef($input: UpdateMultiImageFlimDefInput!) {
        updateMultiImageFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createLastModifiedAtFlimDef(input: CreateLastModifiedAtFlimDefInput, fragments: DocumentNode[]= [LastModifiedAtFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createLastModifiedAtFlimDef($input: CreateLastModifiedAtFlimDefInput!) {
        createLastModifiedAtFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateLastModifiedAtFlimDef(input: UpdateLastModifiedAtFlimDefInput, fragments: DocumentNode[]= [LastModifiedAtFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateLastModifiedAtFlimDef($input: UpdateLastModifiedAtFlimDefInput!) {
        updateLastModifiedAtFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createLastModifiedByFlimDef(input: CreateLastModifiedByFlimDefInput, fragments: DocumentNode[]= [LastModifiedByFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createLastModifiedByFlimDef($input: CreateLastModifiedByFlimDefInput!) {
        createLastModifiedByFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateLastModifiedByFlimDef(input: UpdateLastModifiedByFlimDefInput, fragments: DocumentNode[]= [LastModifiedByFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateLastModifiedByFlimDef($input: UpdateLastModifiedByFlimDefInput!) {
        updateLastModifiedByFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createMultiLinkFlimDef(input: CreateMultiLinkFlimDefInput, fragments: DocumentNode[]= [MultiLinkFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createMultiLinkFlimDef($input: CreateMultiLinkFlimDefInput!) {
        createMultiLinkFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateMultiLinkFlimDef(input: UpdateMultiLinkFlimDefInput, fragments: DocumentNode[]= [MultiLinkFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateMultiLinkFlimDef($input: UpdateMultiLinkFlimDefInput!) {
        updateMultiLinkFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createSingleLocationFlimDef(input: CreateSingleLocationFlimDefInput, fragments: DocumentNode[]= [SingleLocationFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createSingleLocationFlimDef($input: CreateSingleLocationFlimDefInput!) {
        createSingleLocationFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateSingleLocationFlimDef(input: UpdateSingleLocationFlimDefInput, fragments: DocumentNode[]= [SingleLocationFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateSingleLocationFlimDef($input: UpdateSingleLocationFlimDefInput!) {
        updateSingleLocationFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createNumberFlimDef(input: CreateNumberFlimDefInput, fragments: DocumentNode[]= [NumberFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createNumberFlimDef($input: CreateNumberFlimDefInput!) {
        createNumberFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateNumberFlimDef(input: UpdateNumberFlimDefInput, fragments: DocumentNode[]= [NumberFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateNumberFlimDef($input: UpdateNumberFlimDefInput!) {
        updateNumberFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createMultiPhoneFlimDef(input: CreateMultiPhoneFlimDefInput, fragments: DocumentNode[]= [MultiPhoneFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createMultiPhoneFlimDef($input: CreateMultiPhoneFlimDefInput!) {
        createMultiPhoneFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateMultiPhoneFlimDef(input: UpdateMultiPhoneFlimDefInput, fragments: DocumentNode[]= [MultiPhoneFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateMultiPhoneFlimDef($input: UpdateMultiPhoneFlimDefInput!) {
        updateMultiPhoneFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createMultiRelationFlimDef(input: CreateMultiRelationFlimDefInput, fragments: DocumentNode[]= [MultiRelationFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createMultiRelationFlimDef($input: CreateMultiRelationFlimDefInput!) {
        createMultiRelationFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateMultiRelationFlimDef(input: UpdateMultiRelationFlimDefInput, fragments: DocumentNode[]= [MultiRelationFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateMultiRelationFlimDef($input: UpdateMultiRelationFlimDefInput!) {
        updateMultiRelationFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createSingleRelationFlimDef(input: CreateSingleRelationFlimDefInput, fragments: DocumentNode[]= [SingleRelationFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createSingleRelationFlimDef($input: CreateSingleRelationFlimDefInput!) {
        createSingleRelationFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateSingleRelationFlimDef(input: UpdateSingleRelationFlimDefInput, fragments: DocumentNode[]= [SingleRelationFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateSingleRelationFlimDef($input: UpdateSingleRelationFlimDefInput!) {
        updateSingleRelationFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createStatusFlimDef(input: CreateStatusFlimDefInput, fragments: DocumentNode[]= [StatusFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createStatusFlimDef($input: CreateStatusFlimDefInput!) {
        createStatusFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateStatusFlimDef(input?: UpdateStatusFlimDefInput, fragments: DocumentNode[]= [StatusFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateStatusFlimDef($input: UpdateStatusFlimDefInput) {
        updateStatusFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createMultiTextFlimDef(input: CreateMultiTextFlimDefInput, fragments: DocumentNode[]= [MultiTextFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createMultiTextFlimDef($input: CreateMultiTextFlimDefInput!) {
        createMultiTextFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateMultiTextFlimDef(input: UpdateMultiTextFlimDefInput, fragments: DocumentNode[]= [MultiTextFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateMultiTextFlimDef($input: UpdateMultiTextFlimDefInput!) {
        updateMultiTextFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createSingleTextFlimDef(input: CreateSingleTextFlimDefInput, fragments: DocumentNode[]= [SingleTextFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createSingleTextFlimDef($input: CreateSingleTextFlimDefInput!) {
        createSingleTextFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateSingleTextFlimDef(input: UpdateSingleTextFlimDefInput, fragments: DocumentNode[]= [SingleTextFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateSingleTextFlimDef($input: UpdateSingleTextFlimDefInput!) {
        updateSingleTextFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createUniqueIdFlimDef(input: CreateUniqueIdFlimDefInput, fragments: DocumentNode[]= [UniqueIdFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createUniqueIdFlimDef($input: CreateUniqueIdFlimDefInput!) {
        createUniqueIdFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateUniqueIdFlimDef(input: UpdateUniqueIdFlimDefInput, fragments: DocumentNode[]= [UniqueIdFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateUniqueIdFlimDef($input: UpdateUniqueIdFlimDefInput!) {
        updateUniqueIdFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createMultiUserFlimDef(input: CreateMultiUserFlimDefInput, fragments: DocumentNode[]= [MultiUserFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createMultiUserFlimDef($input: CreateMultiUserFlimDefInput!) {
        createMultiUserFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateMultiUserFlimDef(input: UpdateMultiUserFlimDefInput, fragments: DocumentNode[]= [MultiUserFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateMultiUserFlimDef($input: UpdateMultiUserFlimDefInput!) {
        updateMultiUserFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createSingleUserFlimDef(input: CreateSingleUserFlimDefInput, fragments: DocumentNode[]= [SingleUserFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createSingleUserFlimDef($input: CreateSingleUserFlimDefInput!) {
        createSingleUserFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateSingleUserFlimDef(input: UpdateSingleUserFlimDefInput, fragments: DocumentNode[]= [SingleUserFlimDefDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateSingleUserFlimDef($input: UpdateSingleUserFlimDefInput!) {
        updateSingleUserFlimDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function upsertMultiAttachmentFlimVal(input: UpsertMultiAttachmentFlimValInput, fragments: DocumentNode[]= [MultiAttachmentFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertMultiAttachmentFlimVal($input: UpsertMultiAttachmentFlimValInput!) {
        upsertMultiAttachmentFlimVal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateMultiAttachmentFlimValFileReference(input: UpdateMultiAttachmentFlimValFileReferenceInput, fragments: DocumentNode[]= [MultiAttachmentFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateMultiAttachmentFlimValFileReference($input: UpdateMultiAttachmentFlimValFileReferenceInput!) {
        updateMultiAttachmentFlimValFileReference (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function upsertSingleAttachmentFlimVal(input: UpsertSingleAttachmentFlimValInput, fragments: DocumentNode[]= [SingleAttachmentFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertSingleAttachmentFlimVal($input: UpsertSingleAttachmentFlimValInput!) {
        upsertSingleAttachmentFlimVal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function upsertMultiCategoryFlimVal(input: UpsertMultiCategoryFlimValInput, fragments: DocumentNode[]= [MultiCategoryFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertMultiCategoryFlimVal($input: UpsertMultiCategoryFlimValInput!) {
        upsertMultiCategoryFlimVal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function upsertSingleCategoryFlimVal(input: UpsertSingleCategoryFlimValInput, fragments: DocumentNode[]= [SingleCategoryFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertSingleCategoryFlimVal($input: UpsertSingleCategoryFlimValInput!) {
        upsertSingleCategoryFlimVal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createChecklistFlimValEntry(input: CreateChecklistFlimValEntryInput, fragments: DocumentNode[]= [ChecklistFlimValEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createChecklistFlimValEntry($input: CreateChecklistFlimValEntryInput!) {
        createChecklistFlimValEntry (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateChecklistFlimValEntry(input: UpdateChecklistFlimValEntryInput, fragments: DocumentNode[]= [ChecklistFlimValEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateChecklistFlimValEntry($input: UpdateChecklistFlimValEntryInput!) {
        updateChecklistFlimValEntry (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteChecklistFlimValEntry(input: DeleteChecklistFlimValEntryInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteChecklistFlimValEntry($input: DeleteChecklistFlimValEntryInput!) {
        deleteChecklistFlimValEntry (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function upsertRangeDateFlimVal(input: UpsertRangeDateFlimValInput, fragments: DocumentNode[]= [RangeDateFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertRangeDateFlimVal($input: UpsertRangeDateFlimValInput!) {
        upsertRangeDateFlimVal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function upsertSingleDateFlimVal(input: UpsertSingleDateFlimValInput, fragments: DocumentNode[]= [SingleDateFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertSingleDateFlimVal($input: UpsertSingleDateFlimValInput!) {
        upsertSingleDateFlimVal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createMultiEmailFlimValEntry(input: CreateMultiEmailFlimValEntryInput, fragments: DocumentNode[]= [MultiEmailFlimValEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createMultiEmailFlimValEntry($input: CreateMultiEmailFlimValEntryInput!) {
        createMultiEmailFlimValEntry (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateMultiEmailFlimValEntry(input: UpdateMultiEmailFlimValEntryInput, fragments: DocumentNode[]= [MultiEmailFlimValEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateMultiEmailFlimValEntry($input: UpdateMultiEmailFlimValEntryInput!) {
        updateMultiEmailFlimValEntry (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteMultiEmailFlimValEntry(input: DeleteMultiEmailFlimValEntryInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteMultiEmailFlimValEntry($input: DeleteMultiEmailFlimValEntryInput!) {
        deleteMultiEmailFlimValEntry (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function upsertMultiImageFlimVal(input: UpsertMultiImageFlimValInput, fragments: DocumentNode[]= [MultiImageFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertMultiImageFlimVal($input: UpsertMultiImageFlimValInput!) {
        upsertMultiImageFlimVal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateMultiImageFlimValFileReference(input: UpdateMultiImageFlimValFileReferenceInput, fragments: DocumentNode[]= [MultiImageFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateMultiImageFlimValFileReference($input: UpdateMultiImageFlimValFileReferenceInput!) {
        updateMultiImageFlimValFileReference (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createMultiLinkFlimValEntry(input: CreateMultiLinkFlimValEntryInput, fragments: DocumentNode[]= [MultiLinkFlimValEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createMultiLinkFlimValEntry($input: CreateMultiLinkFlimValEntryInput!) {
        createMultiLinkFlimValEntry (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteMultiLinkFlimValEntry(input: DeleteMultiLinkFlimValEntryInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteMultiLinkFlimValEntry($input: DeleteMultiLinkFlimValEntryInput!) {
        deleteMultiLinkFlimValEntry (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function upsertSingleLocationFlimVal(input: UpsertSingleLocationFlimValInput, fragments: DocumentNode[]= [SingleLocationFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertSingleLocationFlimVal($input: UpsertSingleLocationFlimValInput!) {
        upsertSingleLocationFlimVal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function upsertNumberFlimVal(input: UpsertNumberFlimValInput, fragments: DocumentNode[]= [NumberFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertNumberFlimVal($input: UpsertNumberFlimValInput!) {
        upsertNumberFlimVal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function createMultiPhoneFlimValEntry(input: CreateMultiPhoneFlimValEntryInput, fragments: DocumentNode[]= [MultiPhoneFlimValEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation createMultiPhoneFlimValEntry($input: CreateMultiPhoneFlimValEntryInput!) {
        createMultiPhoneFlimValEntry (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function updateMultiPhoneFlimValEntry(input: UpdateMultiPhoneFlimValEntryInput, fragments: DocumentNode[]= [MultiPhoneFlimValEntryDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation updateMultiPhoneFlimValEntry($input: UpdateMultiPhoneFlimValEntryInput!) {
        updateMultiPhoneFlimValEntry (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function deleteMultiPhoneFlimValEntry(input: DeleteMultiPhoneFlimValEntryInput, fragments: DocumentNode[]= [VoidNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation deleteMultiPhoneFlimValEntry($input: DeleteMultiPhoneFlimValEntryInput!) {
        deleteMultiPhoneFlimValEntry (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function upsertMultiRelationFlimVal(input: UpsertMultiRelationFlimValInput, fragments: DocumentNode[]= [MultiRelationFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertMultiRelationFlimVal($input: UpsertMultiRelationFlimValInput!) {
        upsertMultiRelationFlimVal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function upsertSingleRelationFlimVal(input: UpsertSingleRelationFlimValInput, fragments: DocumentNode[]= [SingleRelationFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertSingleRelationFlimVal($input: UpsertSingleRelationFlimValInput!) {
        upsertSingleRelationFlimVal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function upsertStatusFlimVal(input: UpsertStatusFlimValInput, fragments: DocumentNode[]= [StatusFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertStatusFlimVal($input: UpsertStatusFlimValInput!) {
        upsertStatusFlimVal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function upsertMultiTextFlimVal(input: UpsertMultiTextFlimValInput, fragments: DocumentNode[]= [MultiTextFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertMultiTextFlimVal($input: UpsertMultiTextFlimValInput!) {
        upsertMultiTextFlimVal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function upsertSingleTextFlimVal(input: UpsertSingleTextFlimValInput, fragments: DocumentNode[]= [SingleTextFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertSingleTextFlimVal($input: UpsertSingleTextFlimValInput!) {
        upsertSingleTextFlimVal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function upsertMultiUserFlimVal(input: UpsertMultiUserFlimValInput, fragments: DocumentNode[]= [MultiUserFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertMultiUserFlimVal($input: UpsertMultiUserFlimValInput!) {
        upsertMultiUserFlimVal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

export function upsertSingleUserFlimVal(input: UpsertSingleUserFlimValInput, fragments: DocumentNode[]= [SingleUserFlimValDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      mutation upsertSingleUserFlimVal($input: UpsertSingleUserFlimValInput!) {
        upsertSingleUserFlimVal (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    mutation: func,
    variables: { input }
  }
}

  