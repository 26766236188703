import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { htmlToText } from '@t5s/client/util/html-to-text';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { BlabItemActivityThreadI18n } from '@t5s/mobile-client/i18n/blab-item-activity-thread';
import { ClipboardService } from '@t5s/mobile-client/service/clipboard';
import { GqlBlabItemActivityReplyService } from '@t5s/shared/gql-services';
import { filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { DialogService } from '@t5s/mobile-client/service/dialog';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
import { attachmentFileValidForUpload } from '@t5s/client/util/file';
import {
  attachmentReadyForUpload,
  attachmentUploadedCompletely,
} from '@t5s/mobile-client/util/blab-item-activity-thread';
import { selectBlabItemActivityThreadDict } from './blab-item-activity-thread.selectors';
import { BlabItemActivityThreadActions } from './blab-item-activity-thread.actions';

const NUM_ALLOWED_ATTACHMENTS = 4;

@Injectable()
export class BlabItemActivityThreadReplyEffects {
  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly blabItemActivityReplyService: GqlBlabItemActivityReplyService,
    private readonly clipboardService: ClipboardService,
    private readonly i18n$: I18nObjectObservable,
    private readonly dialogService: DialogService,
  ) {}

  readonly sendBlabItemActivityReply$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.sendBlabItemActivityReply),
      concatLatestFrom(() => this.store$.select(selectBlabItemActivityThreadDict)),
      mergeMap(([{ activityId }, dict]) => {
        const blabItemState = dict[activityId];

        if (!blabItemState) {
          return [];
        }

        const { replyInput = {} } = blabItemState;
        const { content = '' } = replyInput;
        const uploadedAttachments = replyInput.attachments?.filter(attachmentUploadedCompletely) ?? [];
        const attachmentIds = uploadedAttachments.map((att) => att.attachment.id);

        return this.blabItemActivityReplyService
          .addBlabItemActivityReply({
            activityId,
            content,
            attachmentIds,
          })
          .pipe(
            map((reply) =>
              BlabItemActivityThreadActions.sendBlabItemActivityReplySuccess({ activityId, reply, content }),
            ),
            catchErrorDispatch(BlabItemActivityThreadActions.sendBlabItemActivityReplyException),
          );
      }),
    ),
  );

  readonly fileAddedTriggerUploads$ = createEffect(() =>
    this.actions$.pipe(ofType(BlabItemActivityThreadActions.addAttachmentFiles)).pipe(
      mergeMap(({ activityId }) =>
        this.store$.select(selectBlabItemActivityThreadDict).pipe(
          switchMap((dict) => {
            const blabItemActivityThreadState = dict[activityId];
            if (!blabItemActivityThreadState) {
              return [];
            }

            const { replyInput = {} } = blabItemActivityThreadState;
            const attachmentsReadyForUpload = replyInput.attachments?.filter(attachmentReadyForUpload) ?? [];

            return attachmentsReadyForUpload.map((attachment) =>
              BlabItemActivityThreadActions.uploadFile({ activityId, attachment }),
            );
          }),
        ),
      ),
    ),
  );

  readonly invalidFileAdded$ = createEffect(() =>
    this.actions$.pipe(ofType(BlabItemActivityThreadActions.addAttachmentFiles)).pipe(
      map(({ files }) => files.filter((file) => !attachmentFileValidForUpload(file))),
      filter((invalidFiles) => invalidFiles.length > 0),
      map((files) => BlabItemActivityThreadActions.invalidFileSizeFileAttached({ files })),
    ),
  );

  readonly tooManyFileAdded$ = createEffect(() =>
    this.actions$.pipe(ofType(BlabItemActivityThreadActions.addAttachmentFiles)).pipe(
      filter(({ files }) => files.length > NUM_ALLOWED_ATTACHMENTS),
      map(({ files }) => BlabItemActivityThreadActions.tooManyFileAttached({ files })),
    ),
  );

  readonly uploadAttachmentFile$ = createEffect(() =>
    this.actions$.pipe(ofType(BlabItemActivityThreadActions.uploadFile)).pipe(
      mergeMap(({ activityId, attachment }) =>
        this.blabItemActivityReplyService.uploadBlabItemActivityReplyAttachment({}, attachment.file).pipe(
          map((persistedAttachment) =>
            BlabItemActivityThreadActions.uploadFileSuccess({ activityId, attachment, persistedAttachment }),
          ),
          catchErrorDispatch(BlabItemActivityThreadActions.uploadFileException, { attachment }),
        ),
      ),
    ),
  );

  readonly confirmDeleteBlabItemActivityReply$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.confirmDeleteBlabItemActivityReply),
      concatLatestFrom(() => this.i18n$),
      switchMap(([{ blabItemId, activityId, replyId }, { i18n }]) => {
        const message = BlabItemActivityThreadI18n.translate(
          i18n,
          BlabItemActivityThreadI18n.key.confirmDeleteActivityReplyDialog.message,
        );
        const title = BlabItemActivityThreadI18n.translate(
          i18n,
          BlabItemActivityThreadI18n.key.confirmDeleteActivityReplyDialog.title,
        );
        const cancelButtonTitle = BlabItemActivityThreadI18n.translate(
          i18n,
          BlabItemActivityThreadI18n.key.confirmDeleteActivityReplyDialog.cancelButtonTitle,
        );
        const okButtonTitle = BlabItemActivityThreadI18n.translate(
          i18n,
          BlabItemActivityThreadI18n.key.confirmDeleteActivityReplyDialog.okButtonTitle,
        );

        return this.dialogService.confirm({ message, title, cancelButtonTitle, okButtonTitle }).pipe(
          filter(({ value }) => value),
          map(() => BlabItemActivityThreadActions.deleteBlabItemActivityReply({ blabItemId, activityId, replyId })),
        );
      }),
    ),
  );

  readonly deleteBlabItemActivityReply$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.deleteBlabItemActivityReply),
      switchMap(({ activityId, blabItemId, replyId }) =>
        this.blabItemActivityReplyService
          .removeBlabItemActivityReply({ activityReplyId: replyId })
          .pipe(
            map(() =>
              BlabItemActivityThreadActions.deleteBlabItemActivityReplySuccess({ activityId, blabItemId, replyId }),
            ),
          ),
      ),
    ),
  );

  readonly copyReplyContent$ = createEffect(
    () =>
      this.actions$.pipe(ofType(BlabItemActivityThreadActions.copyReplyContent)).pipe(
        map(({ activityReply }) => {
          return htmlToText(activityReply?.content ?? '');
        }),
        filterOnlyPresent(),
        switchMap((textContent) => this.clipboardService.write({ string: textContent })),
      ),
    { dispatch: false },
  );
}
