import gql from 'graphql-tag';
import { MultiAttachmentFlimDefDtoFragment, SingleAttachmentFlimDefDtoFragment } from './attachment';
import { CalculationFlimDefDtoFragment } from './calculation';
import { MultiCategoryFlimDefDtoFragment, SingleCategoryFlimDefDtoFragment } from './category';
import { ChecklistFlimDefDtoFragment } from './checklist';
import { CreatedAtFlimDefDtoFragment } from './created-at';
import { RangeDateFlimDefDtoFragment, SingleDateFlimDefDtoFragment } from './date';
import { LastModifiedAtFlimDefDtoFragment } from './last-modified-at';
import { LastModifiedByFlimDefDtoFragment } from './last-modified-by';
import { MultiLinkFlimDefDtoFragment } from './multi-link';
import { MultiEmailFlimDefDtoFragment } from './email';
import { MultiPhoneFlimDefDtoFragment } from './phone';
import { NumberFlimDefDtoFragment } from './number/number-flim-def.fragment';
import { MultiRelationFlimDefDtoFragment, SingleRelationFlimDefDtoFragment } from './relation';
import { StatusFlimDefDtoFragment } from './status/status-flim-def.fragment';
import { MultiTextFlimDefDtoFragment, SingleTextFlimDefDtoFragment } from './text';
import { MultiUserFlimDefDtoFragment, SingleUserFlimDefDtoFragment } from './user';
import { MultiImageFlimDefDtoFragment } from './image';
import { SingleLocationFlimDefDtoFragment } from './location';
import { UniqueIdFlimDefDtoFragment } from './unique-id';
import { CreatedByFlimDefDtoFragment } from './created-by';

export const FullFlimDefFragment = gql`
  fragment FullFlimDef on FlimDefDto {
    ... on SingleTextFlimDefDto {
      ...SingleTextFlimDefDtoFragment
    }
    ... on MultiTextFlimDefDto {
      ...MultiTextFlimDefDtoFragment
    }

    ... on SingleCategoryFlimDefDto {
      ...SingleCategoryFlimDefDtoFragment
    }
    ... on MultiCategoryFlimDefDto {
      ...MultiCategoryFlimDefDtoFragment
    }

    ... on SingleRelationFlimDefDto {
      ...SingleRelationFlimDefDtoFragment
    }
    ... on MultiRelationFlimDefDto {
      ...MultiRelationFlimDefDtoFragment
    }

    ... on SingleAttachmentFlimDefDto {
      ...SingleAttachmentFlimDefDtoFragment
    }
    ... on MultiAttachmentFlimDefDto {
      ...MultiAttachmentFlimDefDtoFragment
    }

    ... on SingleUserFlimDefDto {
      ...SingleUserFlimDefDtoFragment
    }
    ... on MultiUserFlimDefDto {
      ...MultiUserFlimDefDtoFragment
    }

    ... on SingleDateFlimDefDto {
      ...SingleDateFlimDefDtoFragment
    }
    ... on RangeDateFlimDefDto {
      ...RangeDateFlimDefDtoFragment
    }

    ... on ChecklistFlimDefDto {
      ...ChecklistFlimDefDtoFragment
    }

    ... on MultiLinkFlimDefDto {
      ...MultiLinkFlimDefDtoFragment
    }

    ... on CreatedAtFlimDefDto {
      ...CreatedAtFlimDefDtoFragment
    }

    ... on CreatedByFlimDefDto {
      ...CreatedByFlimDefDtoFragment
    }

    ... on LastModifiedAtFlimDefDto {
      ...LastModifiedAtFlimDefDtoFragment
    }

    ... on LastModifiedByFlimDefDto {
      ...LastModifiedByFlimDefDtoFragment
    }

    ... on NumberFlimDefDto {
      ...NumberFlimDefDtoFragment
    }

    ... on StatusFlimDefDto {
      ...StatusFlimDefDtoFragment
    }

    ... on CalculationFlimDefDto {
      ...CalculationFlimDefDtoFragment
    }

    ... on MultiEmailFlimDefDto {
      ...MultiEmailFlimDefDtoFragment
    }

    ... on MultiPhoneFlimDefDto {
      ...MultiPhoneFlimDefDtoFragment
    }

    ... on MultiImageFlimDefDto {
      ...MultiImageFlimDefDtoFragment
    }

    ... on SingleLocationFlimDefDto {
      ...SingleLocationFlimDefDtoFragment
    }

    ... on UniqueIdFlimDefDto {
      ...UniqueIdFlimDefDtoFragment
    }
  }

  ${SingleTextFlimDefDtoFragment}
  ${MultiTextFlimDefDtoFragment}

  ${SingleCategoryFlimDefDtoFragment}
  ${MultiCategoryFlimDefDtoFragment}

  ${SingleRelationFlimDefDtoFragment}
  ${MultiRelationFlimDefDtoFragment}

  ${SingleAttachmentFlimDefDtoFragment}
  ${MultiAttachmentFlimDefDtoFragment}

  ${SingleUserFlimDefDtoFragment}
  ${MultiUserFlimDefDtoFragment}

  ${SingleDateFlimDefDtoFragment}
  ${RangeDateFlimDefDtoFragment}

  ${ChecklistFlimDefDtoFragment}

  ${CreatedAtFlimDefDtoFragment}

  ${CreatedByFlimDefDtoFragment}

  ${MultiLinkFlimDefDtoFragment}

  ${NumberFlimDefDtoFragment}

  ${LastModifiedAtFlimDefDtoFragment}

  ${LastModifiedByFlimDefDtoFragment}

  ${StatusFlimDefDtoFragment}

  ${CalculationFlimDefDtoFragment}

  ${MultiEmailFlimDefDtoFragment}

  ${MultiPhoneFlimDefDtoFragment}

  ${MultiImageFlimDefDtoFragment}

  ${SingleLocationFlimDefDtoFragment}

  ${UniqueIdFlimDefDtoFragment}
`;
