import { IsolatedPosition } from './isolated-position';
import { pickNth, pickRandom } from './pick-strategies';

/**
 * Encapsulate IsolatedPosition class in a static class that handles an instance with static configuration
 * This is useful for validation as it is ensured that the same alphabet is always used
 */
const IsolatedPositionAlphabet = {
  start: '\x20',
  end: '\x7E',
};

const IsolatedPositionPickStrategy = {
  random: pickRandom,
  partitioning: pickNth,
};

export const StaticIsolatedPosition = new IsolatedPosition(IsolatedPositionAlphabet, IsolatedPositionPickStrategy);

export const LowestPosition = IsolatedPositionAlphabet.start;
