import gql from 'graphql-tag';
import {
  UserPostAttachmentDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const UserPostAttachmentFragment = gql`
  fragment UserPostAttachmentFragment on UserPostAttachmentDto {
    ...UserPostAttachmentDtoNoNesting
    thumbnail {
      ...ThumbnailDtoNoNesting
    }
  }
  ${UserPostAttachmentDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
