import { PushNotificationPayloadType } from '../push-notification-payload-type.enum';

export interface BlabItemPushNotificationPayloadValObj {
  type: PushNotificationPayloadType.BLAB_ITEM_PUSH_NOTIFICATION;
  notificationId: number;
  blabItemId: number;
  blabItemActivityId: number;
}

export function isBlabItemPushNotificationPayload(
  payload: unknown | undefined | null,
): payload is BlabItemPushNotificationPayloadValObj {
  return (payload as any)?.type === PushNotificationPayloadType.BLAB_ITEM_PUSH_NOTIFICATION;
}
