import gql from 'graphql-tag';
import { FocusEntryUnionTypeFragment } from './focus-entry-union-type.fragment';

export const FocusEntrySubscriptionUpdateUnionTypeFragment = gql`
  fragment FocusEntrySubscriptionUpdateUnionTypeFragment on FocusEntrySubscriptionUpdateUnionType {
    ... on FocusEntryAddedSubscriptionEvent {
      focusEntry {
        ...FocusEntryUnionTypeFragment
      }
    }
    ... on FocusEntryUpdatedSubscriptionEvent {
      focusEntry {
        ...FocusEntryUnionTypeFragment
      }
    }
    ... on FocusEntryRemovedSubscriptionEvent {
      id
    }
  }
  ${FocusEntryUnionTypeFragment}
`;
