import gql from 'graphql-tag';
import {
  SubscribeToOauthIntegrationAuthenticationStatusFailureEventNoNestingFragment,
  SubscribeToOauthIntegrationAuthenticationStatusSuccessEventNoNestingFragment,
} from '../../generated/graphql-fragments';

export const OauthIntegrationAuthenticationStatusSubscriptionEventUnionTypeFragment = gql`
  fragment OauthIntegrationAuthenticationStatusSubscriptionEventUnionTypeFragment on SubscribeToOauthIntegrationAuthenticationStatusResult {
    ... on SubscribeToOauthIntegrationAuthenticationStatusFailureEvent {
      ...SubscribeToOauthIntegrationAuthenticationStatusFailureEventNoNesting
    }
    ... on SubscribeToOauthIntegrationAuthenticationStatusSuccessEvent {
      ...SubscribeToOauthIntegrationAuthenticationStatusSuccessEventNoNesting
    }
  }
  ${SubscribeToOauthIntegrationAuthenticationStatusFailureEventNoNestingFragment}
  ${SubscribeToOauthIntegrationAuthenticationStatusSuccessEventNoNestingFragment}
`;
