import gql from 'graphql-tag';
import { ThumbnailDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const UserProfilePreviewDtoFragment = gql`
  fragment UserProfilePreviewDtoFragment on UserProfilePreviewDto {
    id
    email
    primaryName
    secondaryName
    jobDescription
    profilePicture {
      ...ThumbnailDtoNoNesting
    }
    organization {
      id
      slug
    }
  }
  ${ThumbnailDtoNoNestingFragment}
`;
