import { SCROLL_ANCHOR_SELECTOR } from '@t5s/mobile-client/readonly-constant/scroll-anchor';

export function getFirstVisibleScrollAnchor(rootEl: HTMLElement): Element | undefined {
  const children = rootEl.querySelectorAll(SCROLL_ANCHOR_SELECTOR);

  for (let i = 0; i < children.length; i++) {
    const child = children.item(i);
    const rect = child.getBoundingClientRect();
    if (rect.top > 0) {
      return child;
    }
  }

  return undefined;
}
