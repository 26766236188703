import { NgModule } from '@angular/core';
import { ActiveUserIdObservable } from '@t5s/mobile-client/provider-token/active-user';
import { LoggedInRefreshTriggerObservable } from '@t5s/mobile-client/provider-token/logged-in-refresh-trigger';
import { RefreshTriggerObservable } from '@t5s/mobile-client/provider-token/refresh-trigger';
import { filter, mapTo, withLatestFrom } from 'rxjs/operators';

@NgModule({
  providers: [
    {
      provide: LoggedInRefreshTriggerObservable,
      useFactory: (
        start$: RefreshTriggerObservable,
        activeUserId$: ActiveUserIdObservable,
      ): LoggedInRefreshTriggerObservable =>
        start$.pipe(
          withLatestFrom(activeUserId$),
          filter(([_, activeUserId]) => activeUserId !== undefined && activeUserId !== null),
          mapTo(true),
        ),
      deps: [RefreshTriggerObservable, ActiveUserIdObservable],
    },
  ],
})
export class LoggedInRefreshTriggerProviderModule {}
