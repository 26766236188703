import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NavigationSideEffect } from '@t5s/client/util/router';
import { AllNotificationsActions } from './all';
import { DirectNotificationsActions } from './direct';

@Injectable()
export class NotificationNavigation extends NavigationSideEffect {
  constructor(private readonly store$: Store) {
    super();
  }

  onEnter() {
    setTimeout(() => {
      this.store$.dispatch(AllNotificationsActions.loadSyncNotifications());
      this.store$.dispatch(AllNotificationsActions.subscribeToNotifications());
    }, 500);

    setTimeout(() => {
      this.store$.dispatch(DirectNotificationsActions.loadSyncNotifications());
      this.store$.dispatch(DirectNotificationsActions.subscribeToNotifications());
    }, 750);
  }
}
