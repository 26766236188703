import { ClassProvider, FactoryProvider, PLATFORM_ID } from '@angular/core';
import { BrowserWindowRef } from './browser-window-ref';
import { windowFactory } from './window-factory';
import { WindowRef } from './window-ref';
import { WINDOW } from './window.token';

/* Create a injectable provider for the WindowRef token that uses the BrowserWindowRef class. */
const browserWindowProvider: ClassProvider = {
  provide: WindowRef,
  useClass: BrowserWindowRef,
};

/* Create an injectable provider that uses the windowFactory function for returning the native window object. */
const windowProvider: FactoryProvider = {
  provide: WINDOW,
  useFactory: windowFactory,
  deps: [WindowRef, PLATFORM_ID],
};

/* Create an array of providers. */
export const WINDOW_PROVIDERS = [browserWindowProvider, windowProvider];
