import { Injectable } from '@angular/core';
import { Haptics, ImpactOptions, ImpactStyle, NotificationOptions, VibrateOptions } from '@capacitor/haptics';
import { defer, from } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { PlatformObservable } from '@t5s/mobile-client/provider-token/device';

@Injectable()
export class HapticsService {
  constructor(private readonly platform$: PlatformObservable) {}

  /**
   * Vibrate the device
   */
  vibrate(options?: VibrateOptions | undefined) {
    return defer(() => from(Haptics.vibrate(options)));
  }

  longpressImpact() {
    return this.platform$.pipe(
      first(),
      switchMap(({ platform }) =>
        platform === 'ios' ? this.impact({ style: ImpactStyle.Medium }) : this.impact({ style: ImpactStyle.Light }),
      ),
    );
  }

  /**
   * Trigger a haptics "notification" feedback
   */
  notification(options?: NotificationOptions | undefined) {
    return defer(() => from(Haptics.notification(options)));
  }

  /**
   * Trigger a haptics "impact" feedback
   */
  impact(options?: ImpactOptions | undefined) {
    return defer(() => from(Haptics.impact(options)));
  }
}
