import {
  CreateBlabItemInput,
  CreateMultiAttachmentFlimValInput,
  CreateMultiCategoryFlimValInput,
  CreateMultiEmailFlimValInput,
  CreateMultiLinkFlimValInput,
  CreateMultiPhoneFlimValInput,
  CreateMultiImageFlimValInput,
  CreateMultiRelationFlimValInput,
  CreateMultiTextFlimValInput,
  CreateMultiUserFlimValInput,
  CreateNumberFlimValInput,
  CreateRangeDateFlimValInput,
  CreateSingleAttachmentFlimValInput,
  CreateSingleCategoryFlimValInput,
  CreateSingleDateFlimValInput,
  CreateSingleRelationFlimValInput,
  CreateSingleTextFlimValInput,
  CreateSingleUserFlimValInput,
  CreateStatusFlimValInput,
  FlimType,
  FlimValDto,
  CreateSingleLocationFlimValInput,
} from '@t5s/shared/gql';
import { getChecklistCreateInputFromDto } from './create-inputs';
import { getMultiEmailCreateInputFromDto } from './create-inputs/email';
import { getMultiLinkCreateInputFromDto } from './create-inputs/link';
import { getMultiPhoneCreateInputFromDto } from './create-inputs/phone';
import {
  getChecklistFlimVals,
  getMultiEmailFlimVals,
  getMultiLinkFlimVals,
  getMultiPhoneFlimVals,
  getMultiImageFlimVals,
  getSingleLocationFlimVals,
} from './flim-val-of-type';
import { getUpsertFlimValInputsOfType } from './flim-val-upsert-input';
import { getMultiImageCreateInputFromDto } from './image';
import { getSingleLocationCreateInputFromDto } from './location';

export function getCreateBlabItemInput(blabDefId: number, flimVals: FlimValDto[]): CreateBlabItemInput {
  const singleTextFlimVals: CreateSingleTextFlimValInput[] = getUpsertFlimValInputsOfType(
    flimVals,
    FlimType.SINGLE_TEXT,
  );
  const multiTextFlimVals: CreateMultiTextFlimValInput[] = getUpsertFlimValInputsOfType(flimVals, FlimType.MULTI_TEXT);
  const singleCategoryFlimVals: CreateSingleCategoryFlimValInput[] = getUpsertFlimValInputsOfType(
    flimVals,
    FlimType.SINGLE_CATEGORY,
  );
  const multiCategoryFlimVals: CreateMultiCategoryFlimValInput[] = getUpsertFlimValInputsOfType(
    flimVals,
    FlimType.MULTI_CATEGORY,
  );
  const singleRelationFlimVals: CreateSingleRelationFlimValInput[] =
    getUpsertFlimValInputsOfType<CreateSingleRelationFlimValInput>(flimVals, FlimType.SINGLE_RELATION).filter(
      (flimVal) => !!flimVal.referencedBlabItemId,
    );
  const multiRelationFlimVals: CreateMultiRelationFlimValInput[] = getUpsertFlimValInputsOfType(
    flimVals,
    FlimType.MULTI_RELATION,
  );

  const singleAttachmentFlimVals: CreateSingleAttachmentFlimValInput[] = getUpsertFlimValInputsOfType(
    flimVals,
    FlimType.SINGLE_ATTACHMENT,
  );

  const multiAttachmentFlimVals: CreateMultiAttachmentFlimValInput[] = getUpsertFlimValInputsOfType(
    flimVals,
    FlimType.MULTI_ATTACHMENT,
  );

  const singleUserFlimVals: CreateSingleUserFlimValInput[] = getUpsertFlimValInputsOfType(
    flimVals,
    FlimType.SINGLE_USER,
  );

  const multiUserFlimVals: CreateMultiUserFlimValInput[] = getUpsertFlimValInputsOfType(flimVals, FlimType.MULTI_USER);

  const singleDateFlimVals: CreateSingleDateFlimValInput[] = getUpsertFlimValInputsOfType(
    flimVals,
    FlimType.SINGLE_DATE,
  );

  const rangeDateFlimVals: CreateRangeDateFlimValInput[] = getUpsertFlimValInputsOfType(flimVals, FlimType.RANGE_DATE);

  const checklistFlimVals = getChecklistFlimVals(flimVals).map(getChecklistCreateInputFromDto);

  const multiLinkFlimVals: CreateMultiLinkFlimValInput[] =
    getMultiLinkFlimVals(flimVals).map(getMultiLinkCreateInputFromDto);

  const numberFlimVals: CreateNumberFlimValInput[] = getUpsertFlimValInputsOfType(flimVals, FlimType.NUMBER);

  const statusFlimVals: CreateStatusFlimValInput[] = getUpsertFlimValInputsOfType(flimVals, FlimType.STATUS);

  const multiEmailFlimVals: CreateMultiEmailFlimValInput[] = getMultiEmailFlimVals(flimVals).map(
    getMultiEmailCreateInputFromDto,
  );

  const multiPhoneFlimVals: CreateMultiPhoneFlimValInput[] = getMultiPhoneFlimVals(flimVals).map(
    getMultiPhoneCreateInputFromDto,
  );

  const multiImageFlimVals: CreateMultiImageFlimValInput[] = getMultiImageFlimVals(flimVals).map(
    getMultiImageCreateInputFromDto,
  );

  const singleLocationFlimVals: CreateSingleLocationFlimValInput[] = getSingleLocationFlimVals(flimVals).map(
    getSingleLocationCreateInputFromDto,
  );

  return {
    blabDefId,
    isTemplate: undefined,
    singleTextFlimVals,
    multiTextFlimVals,
    singleCategoryFlimVals,
    multiCategoryFlimVals,
    multiRelationFlimVals,
    singleRelationFlimVals,
    singleAttachmentFlimVals,
    multiAttachmentFlimVals,
    singleUserFlimVals,
    multiUserFlimVals,
    singleDateFlimVals,
    rangeDateFlimVals,
    checklistFlimVals,
    multiLinkFlimVals,
    numberFlimVals,
    statusFlimVals,
    multiEmailFlimVals,
    multiPhoneFlimVals,
    multiImageFlimVals,
    singleLocationFlimVals,
  };
}
