import { NgModule } from '@angular/core';
import { EmojiModule } from '@t5s/client/util/emoji-mart';
import { AssetComponentModule } from '@t5s/mobile-client/ui/component/asset';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { BottomSheetComponent } from './bottom-sheet.component';
import { BottomSheetBottomSpacerComponent } from './bottom-spacer/bottom-spacer.component';
import { BottomSheetEmojiBarComponent } from './emoji-bar';
import { BottomSheetFullWidthButtonComponent } from './full-width-button';
import { BottomSheetIconTextMenuItemComponent } from './icon-text-menu-item';

const components = [
  BottomSheetComponent,
  BottomSheetEmojiBarComponent,
  BottomSheetFullWidthButtonComponent,
  BottomSheetIconTextMenuItemComponent,
  BottomSheetBottomSpacerComponent,
];

@NgModule({
  imports: [ComponentCommonModule, EmojiModule, AssetComponentModule],
  declarations: components,
  exports: components,
})
export class BottomSheetComponentModule {}
