export enum Filetype {
  ACCDB = 'ACCDB',
  AI = 'AI',
  AUDIO = 'AUDIO',
  CSV = 'CSV',
  FALLBACK = 'FALLBACK',
  DOCX = 'DOCX',
  GDOC = 'GDOC',
  GFORM = 'GFORM',
  GSHEET = 'GSHEET',
  GSLIDES = 'GSLIDES',
  HTML = 'HTML',
  IMAGE = 'IMAGE',
  INDD = 'INDD',
  KEY = 'KEY',
  NUMBERS = 'NUMBERS',
  OD = 'OD',
  PAGES = 'PAGES',
  PDF = 'PDF',
  PPTX = 'PPTX',
  PSD = 'PSD',
  RTF = 'RTF',
  TXT = 'TXT',
  VIDEO = 'VIDEO',
  XLSX = 'XLSX',
  ZIP = 'ZIP',
}

export interface FiletypeInfo {
  type: Filetype;
  extensions: string[];
}

export const filetypeInfos: FiletypeInfo[] = [
  {
    type: Filetype.ACCDB,
    extensions: ['accdb'],
  },
  {
    type: Filetype.AI,
    extensions: ['ai'],
  },
  {
    type: Filetype.AUDIO,
    extensions: [
      'aa',
      'aac',
      'aax',
      'act',
      'aiff',
      'alac',
      'amr',
      'ape',
      'au',
      'awb',
      'dct',
      'dss',
      'dvf',
      'flac',
      'gsm',
      'iklax',
      'ivs',
      'm4a',
      'm4b',
      'm4p',
      'mmf',
      'mp3',
      'mpc',
      'msv',
      'nmf',
      'nsf',
      'ogg',
      'opus',
      'ra',
      'raw',
      'rf64',
      'sln',
      'tta',
      'voc',
      'vox',
      'wav',
      'wma',
      'wv',
      '8svx',
      'cda',
    ],
  },
  {
    type: Filetype.CSV,
    extensions: ['csv'],
  },
  {
    type: Filetype.FALLBACK,
    extensions: ['document'],
  },
  {
    type: Filetype.DOCX,
    extensions: ['doc', 'docm', 'docx', 'dot', 'dotm', 'dotx', 'odt', 'xps'],
  },
  {
    type: Filetype.GDOC,
    extensions: ['gdoc'],
  },
  {
    type: Filetype.GFORM,
    extensions: ['gform'],
  },
  {
    type: Filetype.GSHEET,
    extensions: ['gsheet'],
  },
  {
    type: Filetype.GSLIDES,
    extensions: ['gslides'],
  },
  {
    type: Filetype.HTML,
    extensions: ['html'],
  },
  {
    type: Filetype.IMAGE,
    extensions: ['png', 'gif', 'jpg', 'jpeg', 'bmp', 'tiff', 'webp', 'svg'],
  },
  {
    type: Filetype.INDD,
    extensions: ['indd'],
  },
  {
    type: Filetype.KEY,
    extensions: ['key'],
  },
  {
    type: Filetype.NUMBERS,
    extensions: ['numbers'],
  },
  {
    type: Filetype.OD,
    extensions: ['od', 'ot', 'odt', 'ods', 'odp', 'odg', 'odf'],
  },
  {
    type: Filetype.PAGES,
    extensions: ['pages'],
  },
  {
    type: Filetype.PDF,
    extensions: ['pdf'],
  },
  {
    type: Filetype.PPTX,
    extensions: ['pptx'],
  },
  {
    type: Filetype.PSD,
    extensions: ['psd'],
  },
  {
    type: Filetype.RTF,
    extensions: ['rtf'],
  },
  {
    type: Filetype.TXT,
    extensions: ['txt'],
  },
  {
    type: Filetype.VIDEO,
    extensions: [
      'webm',
      'mkv',
      'flv',
      'vob',
      'ogv',
      'drc',
      'gif',
      'gifv',
      'mng',
      'avi',
      'MTS',
      'M2TS',
      'TS',
      'mov',
      'qt',
      'wmv',
      'yuv',
      'rm',
      'rmvb',
      'asf',
      'amv',
      'mp4',
      'm4p',
      'm4v',
      'mpg',
      'mp2',
      'mpeg',
      'mpe',
      'mpv',
      'mpg',
      'mpeg',
      'm2v',
      'm4v',
      'svi',
      '3gp',
      '3g2',
      'mxf',
      'roq',
      'nsv',
      'flv',
      'f4v',
      'f4p',
      'f4a',
      'f4b',
    ],
  },
  {
    type: Filetype.XLSX,
    extensions: ['ods', 'xls', 'xlsx', 'xlt', 'xltm', 'xltx', 'xlw'],
  },
  {
    type: Filetype.ZIP,
    extensions: ['zip', 'tar', '7z', 'rar', 'iso', 'bz2', 'gz', 'tgz', 'zipx'],
  },
];
