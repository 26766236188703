import {
  FocusEntryAddedSubscriptionEvent,
  FocusEntryRemovedSubscriptionEvent,
  FocusEntrySubscriptionUpdateUnionType,
  FocusEntryUpdatedSubscriptionEvent,
} from '@t5s/shared/gql';
import { isDto } from './dto.type-guard';

export function isFocusEntryAddedSubscriptionEvent(
  candidate: FocusEntrySubscriptionUpdateUnionType,
): candidate is FocusEntryAddedSubscriptionEvent {
  return isDto<FocusEntrySubscriptionUpdateUnionType>(candidate, 'FocusEntryAddedSubscriptionEvent');
}

export function isFocusEntryUpdatedSubscriptionEvent(
  candidate: FocusEntrySubscriptionUpdateUnionType,
): candidate is FocusEntryUpdatedSubscriptionEvent {
  return isDto<FocusEntrySubscriptionUpdateUnionType>(candidate, 'FocusEntryUpdatedSubscriptionEvent');
}

export function isFocusEntryRemovedSubscriptionEvent(
  candidate: FocusEntrySubscriptionUpdateUnionType,
): candidate is FocusEntryRemovedSubscriptionEvent {
  return isDto<FocusEntrySubscriptionUpdateUnionType>(candidate, 'FocusEntryRemovedSubscriptionEvent');
}
