import { PushNotificationPayloadType } from '../push-notification-payload-type.enum';

export interface ChatPushNotificationPayloadValObj {
  type: PushNotificationPayloadType.CHAT_PUSH_NOTIFICATION;
  conversationId: number;
  chatActivityId: number;
}

export function isChatPushNotificationPayload(
  payload: unknown | undefined | null,
): payload is ChatPushNotificationPayloadValObj {
  return (payload as any)?.type === PushNotificationPayloadType.CHAT_PUSH_NOTIFICATION;
}
