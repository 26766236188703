import { Directive, ElementRef } from '@angular/core';
import { px } from '@t5s/client/ui/style/common';
import { RxDirective } from '@t5s/client/util/rx';
import { SafeAreaDimensionsObservable } from '@t5s/mobile-client/provider-token/safe-area';

@Directive({
  selector: '[t5sSafeAreaBottomSpacer]',
  exportAs: 't5sSafeAreaBottomSpacer',
})
export class SafeAreaBottomSpacerDirective extends RxDirective {
  constructor({ nativeElement }: ElementRef<HTMLElement>, safeArea$: SafeAreaDimensionsObservable) {
    super();
    this.hold(safeArea$, ({ bottom }) => {
      nativeElement.style.setProperty('height', px(bottom));
      nativeElement.style.setProperty('min-height', px(bottom));
      nativeElement.style.setProperty('max-height', px(bottom));
    });
  }
}
