import { colorEnLangClient } from './color/color.en';
import { countryEnLangClient } from './country/country.en';
import { languageEnLangClient } from './language/language.en';
import { stateEnLangClient } from './state/state.en';
import { TimezoneEnLangClient } from './timezone/timezone.en';

export const coreEnLangClient = {
  calendar: {
    dayIndex: `Day {{index}}/{{duration}}`,
    from: `From {{time}}`,
    until: `Until {{time}}`,
  },
  cancel: `Cancel`,
  color: colorEnLangClient,
  confirmationUnsavedChanges: {
    confirmButtonLabel: `Discard`,
    message: `Leaving this window will cancel any of your unsaved changes`,
    rejectButtonLabel: `Cancel`,
    title: `Discard changes?`,
  },
  country: countryEnLangClient,
  dropdown: {
    noSearchMatch: 'No match for "{{searchText}}"',
  },
  language: languageEnLangClient,
  state: stateEnLangClient,
  timeStamps: {
    hourDelta: `{{delta}}h`,
    minuteDelta: `{{delta}}m`,
    noDate: `No Date`,
    now: `Now`,
    past: `Overdue`,
    secondDelta: `{{delta}}s`,
    today: `Today`,
    tomorrow: `Tomorrow`,
    tomorrowTime: `Tomorrow {{time}}`,
    yesterday: `Yesterday`,
    yesterdayTime: `Yesterday {{time}}`,
  },
  timezone: TimezoneEnLangClient,
  userStatus: {
    offline: 'away',
    offline_snoozed: 'notifications paused',
    online: 'active',
    online_snoozed: 'notifications paused',
  },
};
