import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoggedInRootState, loggedInRootStateKey } from './logged-in-root.reducer';

const selectLoggedInRootFeatureState = createFeatureSelector<LoggedInRootState>(loggedInRootStateKey);

export const selectActiveOutlet = createSelector(selectLoggedInRootFeatureState, (state) => state.active);
export const selectHydratedActiveOutlet = createSelector(
  selectLoggedInRootFeatureState,
  (state) => state.hydratedActive,
);
export const selectClientAppBubbles = createSelector(selectLoggedInRootFeatureState, (state) => state.bubbles);
