export enum ThemeColorCssVariableName {
  LIGHTEST = '--t5s-theme-color-lightest',
  LIGHTER = '--t5s-theme-color-lighter',
  MEDIUMLIGHT = '--t5s-theme-color-mediumlight',
  LIGHT = '--t5s-theme-color-light',
  LIGHT68 = '--t5s-theme-color-light68',
  DARK = '--t5s-theme-color-dark',
  DARKER = '--t5s-theme-color-darker',
  DARKEST = '--t5s-theme-color-darkest',
  PRIMARY = '--t5s-theme-color-primary',
  PRIMARY10 = '--t5s-theme-color-primary10',
  PRIMARY20 = '--t5s-theme-color-primary20',
  PRIMARYDARK10 = '--t5s-theme-color-primarydark10',
  PRIMARYDARK20 = '--t5s-theme-color-primarydark20',
  DANGER = '--t5s-theme-color-danger',
  DANGER10 = '--t5s-theme-color-danger10',
  DANGER20 = '--t5s-theme-color-danger20',
  SUCCESS = '--t5s-theme-color-success',
  GRAYBDC1C9 = '--t5s-theme-color-grayBDC1C9',
  GRAY9197A3 = '--t5s-theme-color-gray9197A3',
}

export enum ThemeColorVar {
  lightest = 'var(--t5s-theme-color-lightest)',
  lighter = 'var(--t5s-theme-color-lighter)',
  mediumlight = 'var(--t5s-theme-color-mediumlight)',
  light = 'var(--t5s-theme-color-light)',
  light68 = 'var(--t5s-theme-color-light68)',
  dark = 'var(--t5s-theme-color-dark)',
  darker = 'var(--t5s-theme-color-darker)',
  darkest = 'var(--t5s-theme-color-darkest)',
  primary = 'var(--t5s-theme-color-primary)',
  primary10 = 'var(--t5s-theme-color-primary10)',
  primary20 = 'var(--t5s-theme-color-primary20)',
  primarydark10 = 'var(--t5s-theme-color-primarydark10)',
  primarydark20 = 'var(--t5s-theme-color-primarydark20)',
  danger = 'var(--t5s-theme-color-danger)',
  danger10 = 'var(--t5s-theme-color-danger10)',
  danger20 = 'var(--t5s-theme-color-danger20)',
  success = 'var(--t5s-theme-color-success)',
  grayBDC1C9 = 'var(--t5s-theme-color-grayBDC1C9)',
  gray9197A3 = 'var(--t5s-theme-color-gray9197A3)',
}
