import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { formatDateViaLanguage, getDateTimeFormats, isApplicableI18n, normalizeDate } from '../common';

export function formatShortDate(i18n: SharedI18n, date?: string | number | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  const dateTimeFormats = getDateTimeFormats(i18n);

  return formatDateViaLanguage(date, dateTimeFormats.date.short, i18n);
}
