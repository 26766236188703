import gql from 'graphql-tag';
import { UserProfileDtoNoNestingFragment, UserSessionDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const UserAuthenticationResultLightFragment = gql`
  fragment UserAuthenticationResultLightFragment on AuthenticateWithCredentialsResult {
    ... on UserSessionDto {
      ...UserSessionDtoNoNesting
      user {
        ...UserProfileDtoNoNesting
      }
    }
    ... on AuthenticateWithCredentialsRejectionDto {
      reason
    }
  }
  ${UserSessionDtoNoNestingFragment}
  ${UserProfileDtoNoNestingFragment}
`;
