import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { formatDateViaLanguage, getDateTimeFormats, isApplicableI18n, normalizeDate } from '../common';

export function formatTableDateRange(
  i18n: SharedI18n,
  dateLeft: string | Date | undefined,
  dateRight: string | Date | undefined,
): string | undefined {
  if (!dateLeft || !dateRight || !isApplicableI18n(i18n)) {
    return undefined;
  }

  dateLeft = normalizeDate(dateLeft);
  dateRight = normalizeDate(dateRight);

  const dateTimeFormats = getDateTimeFormats(i18n);

  const formattedLeft = formatDateViaLanguage(dateLeft, dateTimeFormats.date.short, i18n);
  const formattedRight = formatDateViaLanguage(dateRight, dateTimeFormats.date.short, i18n);

  return `${formattedLeft} – ${formattedRight}`;
}
