import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { formatTime, isApplicableI18n, normalizeDate } from '../common';
import { getTimeFormats } from '../common/formats/time-formats';

export function formatWorkflowRunLogTime(i18n: SharedI18n, date?: string | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  const timeFormat = getTimeFormats(i18n).workflowRunLog;

  const formattedTime = formatTime(date, timeFormat, i18n);

  return `${formattedTime}`;
}
