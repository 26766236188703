import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { FileOpenerService } from '@t5s/mobile-client/service/file-opener';
import { ShareService } from '@t5s/mobile-client/service/share';
import { merge } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { FileAttachmentPreviewActions } from './file-attachment-preview.actions';

@Injectable()
export class FileAttachmentPreviewEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly shareService: ShareService,
    private readonly fileOpenerService: FileOpenerService,
  ) {}

  readonly share$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileAttachmentPreviewActions.shareFile),
      switchMap(({ options }) => {
        return this.shareService.share(options).pipe(map(() => FileAttachmentPreviewActions.shareFileSuccess()));
      }),
    ),
  );

  readonly openFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileAttachmentPreviewActions.openFile),
      switchMap(({ url, options }) => {
        const { retrievedFile$, openedFileDismissal$ } = this.fileOpenerService.openFile(url, options);

        return merge(
          retrievedFile$.pipe(map(FileAttachmentPreviewActions.openFileRetrievedFile)),
          openedFileDismissal$.pipe(map(FileAttachmentPreviewActions.openFileSuccess)),
        );
      }),
    ),
  );

  readonly showOpenFileWith$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FileAttachmentPreviewActions.showOpenFileWith),
      switchMap(({ url, options }) => {
        return this.fileOpenerService.showOpenWithDialog(url, options).pipe(
          map(() => FileAttachmentPreviewActions.showOpenFileWithSuccess()),
          catchErrorDispatch(FileAttachmentPreviewActions.showOpenFileWithException),
        );
      }),
    ),
  );

  // ngrxOnInitEffects() {
  //   return FileAttachmentPreviewActions.openFile({
  //     // options: {
  //     //   url: 'https://file-examples-com.github.io/uploads/2017/10/file_example_JPG_100kB.jpg',
  //     //   mimetype: 'image/jpeg',
  //     // },
  //     options: {
  //       url: 'https://file-examples-com.github.io/uploads/2017/10/file_example_PNG_500kB.png',
  //       mimetype: 'image/png',
  //     },

  //     // options: {
  //     //   url: 'https://file-examples-com.github.io/uploads/2017/10/file-sample_150kB.pdf',
  //     //   mimetype: 'application/pdf',
  //     // },
  //     // options: {
  //     //   url: 'https://file-examples-com.github.io/uploads/2017/02/file_example_XLS_10.xls',
  //     //   mimetype: 'application/msexcel',
  //     // },
  //   });
  // }
}
