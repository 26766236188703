import { NgModule } from '@angular/core';
import { FileAttachmentPreviewBusinessLogicModule } from '@t5s/mobile-client/business-logic/file-attachment-preview';
import { PageCommonModule } from '@t5s/mobile-client/page/common';
import { ServiceScreenOrientationModule } from '@t5s/mobile-client/service/screen-orientation';
import { FileAttachmentPreviewViewModule } from '@t5s/mobile-client/ui/view/file-attachment-preview';
import { FileAttachmentPreviewPageComponent } from './file-attachment-preview-page.component';

@NgModule({
  imports: [
    PageCommonModule,
    FileAttachmentPreviewViewModule,
    ServiceScreenOrientationModule,
    FileAttachmentPreviewBusinessLogicModule,
  ],
  declarations: [FileAttachmentPreviewPageComponent],
})
export class FileAttachmentPreviewPageModule {}
