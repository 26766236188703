import gql from 'graphql-tag';
import {
  ActiveUserGlobalWorkflowRunsFilteringSettingsDtoNoNestingFragment,
  BlabDefPreviewDtoNoNestingFragment,
  WorkflowDefPreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const ActiveUserGlobalWorkflowRunsFilteringSettingsDtoFragment = gql`
  fragment ActiveUserGlobalWorkflowRunsFilteringSettingsDtoFragment on ActiveUserGlobalWorkflowRunsFilteringSettingsDto {
    ...ActiveUserGlobalWorkflowRunsFilteringSettingsDtoNoNesting

    blabDefs {
      ...BlabDefPreviewDtoNoNesting
    }
    workflowDefs {
      ...WorkflowDefPreviewDtoNoNesting
    }
  }

  ${ActiveUserGlobalWorkflowRunsFilteringSettingsDtoNoNestingFragment}
  ${BlabDefPreviewDtoNoNestingFragment}
  ${WorkflowDefPreviewDtoNoNestingFragment}
`;
