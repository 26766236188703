import { ChatActivityType, ChatMessageAttachmentDto, UserProfileDto } from '@t5s/shared/gql';

export enum ChatConversationLocalMessageActivityStatus {
  PENDING = 'PENDING', // ready to send or currently being sent
  RETRYING = 'RETRYING', // user triggered a manual retry. this state is similar to PENDING
  ERRORED = 'ERRORED', // sending failed, user interaction requried
}

export interface ChatConversationLocalMessageActivityValObj {
  localId: string;
  type: ChatActivityType.MESSAGE;
  createdAt: Date;
  user?: UserProfileDto;
  userId?: number;
  conversationId: number;
  unformatted?: string;
  content: string;
  attachments?: ChatMessageAttachmentDto[];

  status: ChatConversationLocalMessageActivityStatus;
}
