import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { ActiveUserIdObservable } from '@t5s/mobile-client/provider-token/active-user';
import { PlatformObservable } from '@t5s/mobile-client/provider-token/device';
import { ApplicationStateObservable } from '@t5s/mobile-client/provider-token/state';
import { ApplicationBadgeService } from '@t5s/mobile-client/service/application-badge';
import { PushNotificationService } from '@t5s/mobile-client/service/push-notification';
import { UserClientAppBubbleType } from '@t5s/shared/gql';
import { GqlPushNotificationSetupService, GqlUserClientAppService } from '@t5s/shared/gql-services';
import { filterOnlyPresent, filterOnlyTrue } from '@t5s/shared/util/rxjs';
import { EMPTY } from 'rxjs';
import { catchError, distinctUntilChanged, filter, first, map, switchMap } from 'rxjs/operators';
import { PushNotificationActions } from './push-notification.actions';

@Injectable()
export class PushNotificationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly pushNotificationService: PushNotificationService,
    private readonly platform$: PlatformObservable,
    private readonly gqlPushNotificationService: GqlPushNotificationSetupService,
    private readonly activeUserId$: ActiveUserIdObservable,
    private readonly appState$: ApplicationStateObservable,
    private readonly userClientAppService: GqlUserClientAppService,
    private readonly appBadgeService: ApplicationBadgeService,
  ) {}

  readonly requestPermissions$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PushNotificationActions.register),
        switchMap(() => this.platform$),
        filter(({ platform }) => platform === 'ios' || platform === 'android'),
        switchMap(() => this.pushNotificationService.requestPermissions()),
        filter((status) => status.receive === 'granted'),
        switchMap(() => this.pushNotificationService.register()),
      ),
    {
      dispatch: false,
    },
  );

  readonly registerSuccess$ = createEffect(() =>
    this.pushNotificationService.registration$.pipe(
      map(({ value: fcmToken }) => PushNotificationActions.registerSuccess({ fcmToken })),
    ),
  );

  readonly registrationError$ = createEffect(() =>
    this.pushNotificationService.registrationError$.pipe(
      map((error) => PushNotificationActions.registerError({ error })),
    ),
  );

  /** Reports token to the server once active user context is available, and registration was successful. */
  readonly registerSuccessReportTokenToServer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PushNotificationActions.registerSuccess),
      switchMap(({ fcmToken }) =>
        this.activeUserId$.pipe(
          filterOnlyPresent(),
          distinctUntilChanged(),
          switchMap(() =>
            this.gqlPushNotificationService.addDeviceRegistrationToken({ token: fcmToken }).pipe(
              map(() => PushNotificationActions.reportTokenSuccess()),
              catchErrorDispatch(PushNotificationActions.reportTokenException),
            ),
          ),
        ),
      ),
    ),
  );

  readonly receivePush$ = createEffect(() =>
    this.pushNotificationService.pushNotificationReceived$.pipe(
      map((notification) =>
        PushNotificationActions.pushNotificationReceived({
          notification: { ...notification, recvAt: new Date().toISOString() },
        }),
      ),
    ),
  );

  readonly pushNotificationActionPerformed$ = createEffect(() =>
    this.pushNotificationService.pushNotificationActionPerformed$.pipe(
      map(({ actionId, notification }) =>
        PushNotificationActions.pushNotificationActionPerformed({
          actionId,
          notification,
        }),
      ),
    ),
  );

  readonly remotePushNotificationReceived$ = createEffect(() =>
    this.pushNotificationService.remotePushNotificationReceived$.pipe(
      map(() => PushNotificationActions.remotePushNotificationReceived()),
    ),
  );

  readonly remotePushNotificationReceivedAppInBackground$ = createEffect(() =>
    this.pushNotificationService.remotePushNotificationReceived$.pipe(
      concatLatestFrom(() => this.appState$),
      filter(([_, { isActive }]) => !isActive),
      map(() => PushNotificationActions.remotePushNotificationReceivedAppInBackground()),
    ),
  );

  readonly appInForegroundClearAllPushNotificationsMaintainBadge$ = createEffect(() =>
    this.appState$.pipe(
      map((state) => state.isActive),
      distinctUntilChanged(),
      filterOnlyTrue(),
      switchMap(() => this.pushNotificationService.removeAllDeliveredNotifications()),
      map(PushNotificationActions.clearAllPushNotificationsSuccess),
    ),
  );

  /** We need to sync app badge again, if push notifications are cleared. The clearing sets badge to 0, which is not desired in all cases. */
  readonly clearAllPushNotificationsSuccessSyncAppBadgeWithBubblesSetAppBadge$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PushNotificationActions.clearAllPushNotificationsSuccess),
        switchMap(() => this.activeUserId$.pipe(filterOnlyPresent(), first())),
        switchMap(() => this.userClientAppService.getUserClientAppBubbles()),
        map((appBubbles) => {
          const { amount: notificationBubbleAmount = 0 } =
            appBubbles.find((bubble) => bubble.type === UserClientAppBubbleType.NOTIFICATION) ?? {};
          const { amount: chatBubbleAmount = 0 } =
            appBubbles.find((bubble) => bubble.type === UserClientAppBubbleType.CHAT) ?? {};

          const appBadgeAmount = notificationBubbleAmount + chatBubbleAmount;

          return { appBadgeAmount };
        }),
        switchMap(({ appBadgeAmount }) => this.appBadgeService.setBadge(appBadgeAmount).pipe(catchError(() => EMPTY))),
      ),
    { dispatch: false },
  );

  ngrxOnInitEffects() {
    return PushNotificationActions.register();
  }
}
