import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { GqlBlabItemActivityReactionService } from '@t5s/shared/gql-services';
import { map, switchMap } from 'rxjs/operators';
import { BlabItemDisplayActions } from './blab-item-display.actions';

@Injectable()
export class BlabItemDisplayActivityEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly gqlActivityService: GqlBlabItemActivityReactionService,
  ) {}

  readonly addBlabItemActivityReaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.addBlabItemActivityReaction),
      switchMap(({ emoji, activityId }) =>
        this.gqlActivityService.addBlabItemActivityReaction({ emoji, activityId }).pipe(
          map((activity) => BlabItemDisplayActions.addBlabItemActivityReactionSuccess({ activity, emoji })),
          catchErrorDispatch(BlabItemDisplayActions.addBlabItemActivityReactionException),
        ),
      ),
    ),
  );

  readonly removeBlabItemActivityReaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.removeBlabItemActivityReaction),
      switchMap(({ emoji, activityId }) =>
        this.gqlActivityService.removeBlabItemActivityReaction({ emoji, activityId }).pipe(
          map((activity) => BlabItemDisplayActions.removeBlabItemActivityReactionSuccess({ activity, emoji })),
          catchErrorDispatch(BlabItemDisplayActions.removeBlabItemActivityReactionException),
        ),
      ),
    ),
  );
}
