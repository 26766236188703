import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ComponentStyle } from '@t5s/client/ui/style/common';
import {
  ReactionDetailsActions,
  selectReactionDetailsReactions,
} from '@t5s/mobile-client/business-logic/reaction-details';
import { Page } from '@t5s/mobile-client/page/common';
import { ReactionDetailsViewComponent } from '@t5s/mobile-client/ui/view/reaction-details';
import { BottomSheetPosition } from '@t5s/mobile-client/value-object/bottom-sheet';

@Component({
  selector: 't5s-reaction-details-page',
  template: `<t5s-reaction-details-view
    [reactions]="reactions$"
    (positionChange)="positionChange($event)"
  ></t5s-reaction-details-view>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostSpread],
})
export class ReactionDetailsPageComponent extends Page {
  constructor(private readonly store$: Store, actions$: Actions) {
    super();

    // Open
    this.hold(actions$.pipe(ofType(ReactionDetailsActions.open)), () => {
      this.reactionDetails?.open();
    });

    // Close
    this.hold(actions$.pipe(ofType(ReactionDetailsActions.close)), () => {
      this.reactionDetails?.close();
    });
  }

  readonly reactions$ = this.store$.select(selectReactionDetailsReactions);

  @ViewChild(ReactionDetailsViewComponent) reactionDetails?: ReactionDetailsViewComponent;

  positionChange({ position }: { position: BottomSheetPosition }) {
    this.store$.dispatch(ReactionDetailsActions.setPosition({ position }));
  }
}
