import { ConversationDto } from '@t5s/shared/gql';
import { IsolatedPositionable } from '@t5s/shared/util/isolated-position';

type TrackByResult = string | number | null | undefined;

/** Function to track elements via their id. Used in anglar templates, i.e. together with the `*ngFor` directive. */
export function trackById<ElementType extends { id?: number | string }>(
  _: number,
  element: ElementType,
): TrackByResult {
  return element ? element.id : null;
}

/** Function to track elements via their id. Used in anglar templates, i.e. together with the `*ngFor` directive. */
export function trackByPosition<ElementType extends IsolatedPositionable>(
  _: number,
  element: ElementType,
): TrackByResult {
  return element ? element.position : null;
}

export function trackByLabel<ElementType extends { label: string }>(_: number, element: ElementType): TrackByResult {
  return element ? element.label : null;
}

export function trackByBlabItemId<ElementType extends { blabItemId: number }>(
  _: number,
  element: ElementType,
): TrackByResult {
  return element ? element.blabItemId : null;
}

export function trackByUserId<ElementType extends { userId: number }>(_: number, element: ElementType): TrackByResult {
  return element ? element.userId : null;
}

export function trackByBlabDefId<ElementType extends { blabDefId: number }>(
  _: number,
  element: ElementType,
): TrackByResult {
  return element ? element.blabDefId : null;
}

export function trackByIndex(index: number, _element: any): TrackByResult {
  return index;
}

export function trackByIdentity<T extends number | string>(_: number, element?: T): TrackByResult {
  return element;
}

export function trackFilter(_: number, filter: { id: number }): TrackByResult {
  return filter.id;
}

export function trackEdge(_: number, edge?: { cursor: string }): TrackByResult {
  return edge?.cursor;
}

export function trackByConversationId(_: number, { conversationId }: ConversationDto): TrackByResult {
  return conversationId;
}

export function trackByEmoji(_: number, { emoji }: { emoji: string }): TrackByResult {
  return emoji;
}
