import { createFeatureSelector, createSelector } from '@ngrx/store';
import { compareAsc } from '@t5s/shared/util/date';
import { DebugConsoleState, debugConsoleStateKey } from './debug-console.reducer';

const selectDebugConsoleFeatureState = createFeatureSelector<DebugConsoleState>(debugConsoleStateKey);

export const selectDebugConsole = createSelector(selectDebugConsoleFeatureState, (state) => state);

// Version
export const selectDebugConsoleClientBuildVersion = createSelector(
  selectDebugConsoleFeatureState,
  (state) => state.clientBuildVersion,
);

// Storage
export const selectDebugConsoleStorageKeys = createSelector(
  selectDebugConsoleFeatureState,
  (state) => state.storageKeys,
);

export const selectDebugConsoleSelectedStorageKey = createSelector(
  selectDebugConsoleFeatureState,
  (state) => state.selectedStorageKey,
);

export const selectDebugConsoleSelectedStorageKeyValue = createSelector(
  selectDebugConsoleFeatureState,
  (state) => state.selectedStorageKeyValue,
);

// Logs
export const selectDebugConsoleLogs = createSelector(selectDebugConsoleFeatureState, (state) =>
  [...state.logs].sort(({ createdAt: date1 }, { createdAt: date2 }) => compareAsc(date1, date2)),
);
