import gql from 'graphql-tag';
import { AudioRoomActivityDtoFragment } from '../audio-room-activity/audio-room-activity-dto.fragment';
import { FullUserProfileDtoFragment } from '../user-profile';

export const AudioRoomNotificationActivityDtoFragment = gql`
  fragment AudioRoomNotificationActivityDtoFragment on AudioRoomNotificationActivityDto {
    direct
    notificationId
    activity {
      ...AudioRoomActivityDtoFragment
    }
  }
  ${FullUserProfileDtoFragment}
  ${AudioRoomActivityDtoFragment}
`;
