import {
  EntityIconColor,
  GRAPHIC_ENTITY_EMOJI_BASE_URL as EmojiBaseUrl,
  GRAPHIC_ENTITY_ICON_BASE_URL as IconBaseUrl,
} from '@t5s/shared/readonly-constant/entity-icon';
import { EntityIconType, GraphicEntityIconDto } from '@t5s/shared/value-object/entity-icon';

export function getEntityIconGraphicDataUrl(): string {
  return `${IconBaseUrl}/all.json`;
}

export function getEntityIconGraphicMetadataUrl({ language = 'EN' }: { language?: 'DE' | 'EN' }): string {
  return `${IconBaseUrl}/metadata/${language.toLowerCase()}.json`;
}

export function getEntityIconEmojiMetadataUrl({ language = 'EN' }: { language?: 'DE' | 'EN' }): string {
  return `${EmojiBaseUrl}/metadata/${language.toLowerCase()}.json`;
}

export function getEntityIconGraphicUrl({ id, color }: { id: string; color?: EntityIconColor }): string {
  const colorStr = color ? `_${color.toLowerCase()}` : '';
  return `${IconBaseUrl}/svg/${id}${colorStr}.svg`;
}

export function getGraphicEntityIconById({
  id,
  color,
}: {
  id: string;
  color?: EntityIconColor | null;
}): GraphicEntityIconDto {
  return {
    type: EntityIconType.GRAPHIC,
    id,
    color: color ?? undefined,
  };
}
