import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { ClientSideLiveSubscriptionException } from '@t5s/client/value-object/live-subscription-context';
import { ActiveUserIdObservable } from '@t5s/mobile-client/provider-token/active-user';
import {
  LoggedInSubscriptionEndTriggerObservable,
  LoggedInSubscriptionStartTriggerObservable,
} from '@t5s/mobile-client/provider-token/logged-in-subscription-trigger';
import { NetworkReconnectedObservable } from '@t5s/mobile-client/provider-token/network-status';
import { ApplicationStateObservable } from '@t5s/mobile-client/provider-token/state';
import { StateHydrationService } from '@t5s/mobile-client/service/state-hydration';
import { GetNotificationsInput } from '@t5s/shared/gql';
import { GqlNotificationService } from '@t5s/shared/gql-services';
import { filterOnlyPresent, throttleTimeEmitInstantly } from '@t5s/shared/util/rxjs';
import { EMPTY, merge, Observable } from 'rxjs';
import { debounceTime, filter, first, map, mergeMap, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { NUM_NOTIFICATIONS_TO_LOAD } from '../common';
import { DirectNotificationsActions } from './direct-notifications.actions';
import { DirectNotificationsState, directNotificationStateKey } from './direct-notifications.reducer';
import {
  selectDirectNotificationsFeatureStateForPersistance,
  selectLoadMoreDirectNotificationsInput,
} from './direct-notifications.selectors';

const DIRECT_NOTIFICATIONS_INPUT: GetNotificationsInput = { onlyDirect: true, onlyUnread: false };
@Injectable()
export class DirectNotificationsEffects {
  readonly hydrationPersistanceKey$: Observable<string>;

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly notificationService: GqlNotificationService,
    private readonly stateHydrationService: StateHydrationService<DirectNotificationsState>,
    private readonly appState$: ApplicationStateObservable,
    private readonly activeUserId$: ActiveUserIdObservable,
    private readonly networkReconnected$: NetworkReconnectedObservable,
    private readonly subscriptionStartTrigger$: LoggedInSubscriptionStartTriggerObservable,
    private readonly subscriptionEndTrigger$: LoggedInSubscriptionEndTriggerObservable,
  ) {
    this.hydrationPersistanceKey$ = activeUserId$.pipe(
      filterOnlyPresent(),
      map((activeUserId) => `${directNotificationStateKey}_${activeUserId}`),
    );
  }

  readonly connectionReestablishedSync$ = createEffect(() =>
    this.networkReconnected$.pipe(
      switchMap(() => this.activeUserId$.pipe(filterOnlyPresent())),
      map(DirectNotificationsActions.loadSyncNotifications),
    ),
  );

  readonly loadNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DirectNotificationsActions.loadSyncNotifications),
      switchMap(() =>
        this.notificationService
          .getNotificationConnectionV2(NUM_NOTIFICATIONS_TO_LOAD, DIRECT_NOTIFICATIONS_INPUT)
          .pipe(
            map((res) => DirectNotificationsActions.loadSyncNotificationsSuccess(res)),
            catchErrorDispatch(DirectNotificationsActions.loadSyncNotificationsException),
          ),
      ),
    ),
  );

  readonly loadReplaceNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DirectNotificationsActions.loadReplaceNotifications),
      switchMap(() =>
        this.notificationService
          .getNotificationConnectionV2(NUM_NOTIFICATIONS_TO_LOAD, DIRECT_NOTIFICATIONS_INPUT)
          .pipe(
            map((res) => DirectNotificationsActions.loadReplaceNotificationsSuccess(res)),
            catchErrorDispatch(DirectNotificationsActions.loadReplaceNotificationsException),
          ),
      ),
    ),
  );

  readonly requestLoadMoreNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DirectNotificationsActions.requestLoadMoreNotifications),
      switchMap(() => this.store$.select(selectLoadMoreDirectNotificationsInput).pipe(first(), filterOnlyPresent())),
      map(({ cursor }) => DirectNotificationsActions.loadMoreNotifications({ cursor })),
    ),
  );

  readonly loadMoreNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DirectNotificationsActions.loadMoreNotifications),
      throttleTimeEmitInstantly(1000),
      switchMap(({ cursor }) =>
        this.notificationService.getNotificationConnectionV2(NUM_NOTIFICATIONS_TO_LOAD, undefined, cursor).pipe(
          map(({ edges, pageInfo }) => DirectNotificationsActions.loadMoreNotificationsSuccess({ edges, pageInfo })),
          catchErrorDispatch(DirectNotificationsActions.loadMoreNotificationsException),
        ),
      ),
    ),
  );

  readonly subscribeToAllNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DirectNotificationsActions.subscribeToNotifications),
      mergeMap(() => {
        const imperativeUnsubscribe$ = EMPTY;

        const shouldSubscribe$ = this.subscriptionStartTrigger$.pipe(startWith(true), debounceTime(100));
        const shouldUnsubscribe$ = merge(imperativeUnsubscribe$, this.subscriptionEndTrigger$);

        return shouldSubscribe$.pipe(
          switchMap(() =>
            this.notificationService.subscribeToUserNotificationsV2(DIRECT_NOTIFICATIONS_INPUT).pipe(
              map((notification) => DirectNotificationsActions.receiveNotifcationViaSubscription({ notification })),
              startWith(DirectNotificationsActions.subscribeToNotificationsSuccess()),
              catchErrorDispatch(DirectNotificationsActions.subscribeToNotificationsError),
              takeUntil(shouldUnsubscribe$),
            ),
          ),
        );
      }),
    ),
  );

  private readonly REESTABLISHMENT_DEBOUNCE = 3000;

  /** Reestablish bubble subscription in case it errored along the way, after a time debounce. */
  readonly reestablishBubbleSubscriptionOnError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DirectNotificationsActions.subscribeToNotificationsError),
      filter(({ error }) => !(error instanceof ClientSideLiveSubscriptionException)),
      debounceTime(this.REESTABLISHMENT_DEBOUNCE),
      concatLatestFrom(() => this.activeUserId$),
      filter(([, activeUserId]) => activeUserId !== undefined),
      map(DirectNotificationsActions.subscribeToNotifications),
    ),
  );

  readonly persistState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          DirectNotificationsActions.hydrateStateSuccess,
          DirectNotificationsActions.hydrateStateNotAvailable,
          DirectNotificationsActions.hydrateStateException,
        ),
        switchMap(() => this.store$.select(selectDirectNotificationsFeatureStateForPersistance)),
        concatLatestFrom(() => this.hydrationPersistanceKey$),
        debounceTime(5000),
        switchMap(([state, hydrationPersistanceKey]) =>
          this.stateHydrationService.persistState(hydrationPersistanceKey, state),
        ),
      ),
    { dispatch: false },
  );

  readonly hydrateState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DirectNotificationsActions.hydrateState),
      concatLatestFrom(() => this.hydrationPersistanceKey$),
      switchMap(([_, hydrationPersistanceKey]) =>
        this.stateHydrationService.retrieveState(hydrationPersistanceKey).pipe(
          map((state) => {
            if (!state) {
              return DirectNotificationsActions.hydrateStateNotAvailable();
            }

            return DirectNotificationsActions.hydrateStateSuccess({ state });
          }),
          catchErrorDispatch(DirectNotificationsActions.hydrateStateException),
        ),
      ),
    ),
  );

  readonly hydrateStateExceptionClear$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DirectNotificationsActions.hydrateStateException),
        concatLatestFrom(() => this.hydrationPersistanceKey$),
        switchMap(([_, hydrationPersistanceKey]) => this.stateHydrationService.clearState(hydrationPersistanceKey)),
      ),
    { dispatch: false },
  );

  readonly appStateActiveLoadNotifications$ = createEffect(() =>
    this.appState$.pipe(
      concatLatestFrom(() => this.activeUserId$.pipe(filterOnlyPresent())),
      filter(([state]) => state.isActive),
      map(DirectNotificationsActions.loadSyncNotifications),
    ),
  );

  ngrxOnInitEffects(): Action {
    return DirectNotificationsActions.hydrateState();
  }
}
