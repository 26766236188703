import gql from 'graphql-tag';
import { NotificationBlabDefActivityUnionTypeFragment } from '../blab-def-activity/blab-def-activity-union-type.fragment';

export const BlabDefNotificationActivityDtoFragment = gql`
  fragment BlabDefNotificationActivityDtoFragment on BlabDefNotificationActivityDto {
    direct
    notificationId
    activity {
      ...NotificationBlabDefActivityUnionTypeFragment
    }
  }
  ${NotificationBlabDefActivityUnionTypeFragment}
`;
