import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, Output, ViewChild } from '@angular/core';
import { DomController } from '@ionic/angular';
import {
  border,
  calc,
  ComponentStyle,
  fullWidth,
  percent,
  px,
  rgba,
  spread,
  variable,
} from '@t5s/client/ui/style/common';
import { scrollTopFromEvent } from '@t5s/client/util/element';
import { tss } from '@t5s/client/util/tss';
import { PlatformObservable } from '@t5s/mobile-client/provider-token/device';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { getFirstVisibleScrollAnchor } from '@t5s/mobile-client/util/scroll-anchor';
import { throttleTimeEmitInstantlyWithTrailing } from '@t5s/shared/util/rxjs';
import { defer, from, fromEvent, Observable, of, Subject } from 'rxjs';
import { debounceTime, filter, first, map, shareReplay, switchMap, tap } from 'rxjs/operators';

interface ScrollContainerState {
  platform: 'android' | 'ios' | 'web';

  scrollEl: HTMLDivElement;
  contentEl: HTMLDivElement;

  bgColor: string;
  splitBgColor: string[] | null;

  scrollX: boolean;
  scrollY: boolean;
  noScrollbar: boolean;
  noBounceForce: boolean;

  initialScrollTop?: number;

  pinToBottom: boolean;
  pinToTop: boolean;

  isScrolledTo: 'top' | 'bottom' | null;

  anchorEl?: Element;
  anchorTop?: number;
}

const SCROLLED_TO_THRESHOLD_PX = 50;
const INACTIVITY_HIDE_THRESHOLD_MS = 500;
const SCROLLBAR_COLOR = '--t5s-scrollbar-container-scrollbar-color';
const SCROLLBAR_DISPLAY = '--t5s-scrollbar-display';

// Based on: https://github.com/ionic-team/ionic-framework/blob/7315e0157b917d2e3586c64f8082026827812943/core/src/components/content/content.tsx
// We copy paste the component because we cannot style the -webkit-scrollbar with ion-content shadow DOM unfortunately
@Component({
  selector: 't5s-scroll-container',
  template: `
    <div #scrollEl cdkScrollable t5sVerticalScrollPosMaintain [class]="containerClass$ | push">
      <div #contentEl [class]="contentClass$ | push">
        <ng-content></ng-content>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostSpread],
})
export class ScrollContainerComponent extends RxComponent<ScrollContainerState> implements AfterViewInit {
  constructor(
    platform$: PlatformObservable,
    private readonly domCtrl: DomController,
    private readonly elRef: ElementRef<HTMLElement>,
  ) {
    super();
    this.set({
      scrollX: false,
      scrollY: false,
      noScrollbar: false,
      pinToBottom: false,
      pinToTop: false,
      noBounceForce: false,
      isScrolledTo: 'top',
      bgColor: 'transparent',
      splitBgColor: null,
    });
    this.connect(platform$);

    // Show/ hide scrollbar on activity/ inactivity
    this.hold(
      this.select(
        selectSlice(['platform', 'scrollEl', 'scrollX', 'scrollY', 'noScrollbar']),
        filter(
          ({ platform, scrollX, scrollY, noScrollbar }) =>
            (platform === 'android' || platform === 'web') && !noScrollbar && (scrollX || scrollY),
        ),
        switchMap(() =>
          // TODO: improve performance here
          this.scroll$.pipe(
            tap(() => this.showScrollbar()),
            debounceTime(INACTIVITY_HIDE_THRESHOLD_MS),
            tap(() => this.hideScrollbar()),
          ),
        ),
      ),
    );

    // Determine anchor
    this.connect(
      this.select(
        selectSlice(['contentEl', 'scrollEl']),
        switchMap(({ contentEl }) =>
          this.scrollTop$.pipe(
            debounceTime(200),
            map(() => {
              const anchorEl = getFirstVisibleScrollAnchor(contentEl);
              if (!anchorEl) {
                return { anchorEl: undefined, anchorTop: undefined };
              }
              const contentTop = contentEl.getBoundingClientRect().top;
              const _anchorTop = anchorEl.getBoundingClientRect().top;
              const anchorTop = _anchorTop - contentTop;

              return { anchorEl, anchorTop };
            }),
          ),
        ),
      ),
    );

    // Maintain scroll anchor in viewport on content resize
    this.hold(this.contentResized$$, () => {
      const { pinToTop, isScrolledTo, anchorEl, anchorTop, scrollEl, contentEl } = this.get();
      // If pinned to top, do not maintain scroll anchor
      if (pinToTop && isScrolledTo === 'top') {
        return;
      }

      if (!anchorEl || !scrollEl || !contentEl || anchorTop === undefined) {
        return;
      }

      const contentTop = contentEl.getBoundingClientRect().top;
      const _anchorTop = anchorEl.getBoundingClientRect().top;
      const newAnchorTop = _anchorTop - contentTop;
      const diff = newAnchorTop - anchorTop;

      if (diff !== 0) {
        scrollEl.scrollTop += diff;
      }
    });
  }

  @ViewChild('scrollEl') private readonly scrollElRef?: ElementRef<HTMLDivElement>;
  @ViewChild('contentEl') private readonly contentRef?: ElementRef<HTMLDivElement>;

  // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error, @typescript-eslint/ban-ts-comment
  // @ts-ignore
  private readonly contentResizeObserver = new ResizeObserver(() => this.contentResized$$.next(undefined));
  private contentResized$$ = new Subject<undefined>();

  private get scrollEl() {
    return this.scrollElRef?.nativeElement;
  }

  @Input() set scrollX(scrollX: boolean | Observable<boolean>) {
    this.setProperty('scrollX', scrollX);
  }

  @Input() set scrollY(scrollY: boolean | Observable<boolean>) {
    this.setProperty('scrollY', scrollY);
  }

  @Input() set initialScrollTop(initialScrollTop: number | Observable<number>) {
    this.setProperty('initialScrollTop', initialScrollTop);
  }

  @Input() set noScrollbar(noScrollbar: boolean | Observable<boolean>) {
    this.setProperty('noScrollbar', noScrollbar);
  }

  @Input() set noBounceForce(noBounceForce: boolean | Observable<boolean>) {
    this.setProperty('noBounceForce', noBounceForce);
  }

  @Input() set pinToBottom(pinToBottom: boolean | Observable<boolean>) {
    this.setProperty('pinToBottom', pinToBottom);
  }

  @Input() set pinToTop(pinToTop: boolean | Observable<boolean>) {
    this.setProperty('pinToTop', pinToTop);
  }

  @Input() set bgColor(bgColor: string | Observable<string>) {
    this.setProperty('bgColor', bgColor);
  }

  @Input() set splitBgColor(splitBgColor: string[] | Observable<string[]>) {
    this.setProperty('splitBgColor', splitBgColor);
  }

  readonly scrollEl$ = this.select(selectSlice(['scrollEl']), first(), shareReplay(1));
  @Output() scroll$ = this.scrollEl$.pipe(
    switchMap(({ scrollEl }) => fromEvent<Event>(scrollEl, 'scroll', { passive: true })),
  );

  @Output() scrollTop$ = this.scroll$.pipe(map((event) => scrollTopFromEvent(event)));
  @Output() contentResized = this.contentResized$$.asObservable();
  @Output() isScrolledToChange = this.select(selectSlice(['isScrolledTo']));

  readonly containerClass$ = this.select(
    selectSlice(['scrollX', 'scrollY', 'noScrollbar', 'bgColor', 'splitBgColor', 'platform']),
    map(({ scrollX, scrollY, noScrollbar, bgColor, splitBgColor, platform }) =>
      tss({
        ...spread,
        overflowX: scrollX ? ('overlay' as any) : 'hidden',
        overscrollBehaviorX: scrollX ? 'contain' : 'auto',

        overflowY: scrollY ? ('overlay' as any) : 'hidden',
        overscrollBehaviorY: scrollY ? 'contain' : 'auto',

        overflowAnchor: 'none',
        background: splitBgColor
          ? `linear-gradient(to bottom, ${splitBgColor[0]} 0%, ${splitBgColor[0]} 50%, ${splitBgColor[1]} 50%, ${splitBgColor[1]} 100%)`
          : bgColor,
        position: 'relative',
        WebkitOverflowScrolling: 'touch',
        willChange: 'scroll-position',
        touchAction: scrollX && scrollY ? 'auto' : scrollX ? 'pan-x' : scrollY ? 'pan-y' : 'none',
        scrollbarWidth: noScrollbar ? 'none' : undefined,
        '::-webkit-scrollbar': {
          display: noScrollbar ? 'none' : variable(SCROLLBAR_DISPLAY),
          backgroundColor: platform !== 'ios' ? 'transparent' : undefined,
          width: platform !== 'ios' ? px(noScrollbar ? 0 : 6) : undefined,
        },
        '::-webkit-scrollbar-thumb': {
          backgroundColor: variable(SCROLLBAR_COLOR, rgba(0, 0, 0, 0)),
          backgroundClip: 'padding-box',
          borderRight: border(3, 'solid', 'transparent'),
        },
      }),
    ),
  );

  readonly contentClass$ = this.select(
    selectSlice(['platform', 'bgColor', 'scrollX', 'scrollY', 'noBounceForce']),
    map(({ platform, bgColor, scrollX, scrollY, noBounceForce }) => {
      const bounceForce = platform === 'ios' && !noBounceForce ? 2 : 0;

      return tss({
        ...fullWidth,
        minHeight: scrollY ? calc(`${percent(100)} + ${px(bounceForce)}`) : undefined, // Make ios bounceable
        minWidth: scrollX ? calc(`${percent(100)} + ${px(bounceForce)}`) : undefined, // Make ios bounceable
        backgroundColor: bgColor,
      });
    }),
  );

  hideScrollbar() {
    this.domCtrl.write(() => {
      const { platform } = this.get();
      if (platform === 'ios') {
        // TODO: hide under iOS
      } else {
        this.elRef.nativeElement.style.removeProperty(SCROLLBAR_COLOR);
      }
    });
  }

  showScrollbar() {
    this.domCtrl.write(() => {
      const { platform } = this.get();
      if (platform === 'ios') {
        // TODO: Show under iOS
      } else {
        this.elRef.nativeElement.style.setProperty(SCROLLBAR_COLOR, rgba(0, 0, 0, 0.35));
      }
    });
  }

  stopScrolling() {
    const { scrollX, scrollY } = this.get();

    if (scrollX) {
      this.scrollEl?.style.setProperty('overflow-x', 'hidden');
    }

    if (scrollY) {
      this.scrollEl?.style.setProperty('overflow-Y', 'hidden');
    }

    setTimeout(() => {
      if (scrollX) {
        this.scrollEl?.style.setProperty('overflow-x', 'overlay');
      }

      if (scrollY) {
        this.scrollEl?.style.setProperty('overflow-Y', 'overlay');
      }
    }, 0);
  }

  get scrollLeft() {
    return this.scrollEl?.scrollLeft ?? 0;
  }

  set scrollLeft(scrollLeft: number) {
    if (this.scrollEl) {
      this.scrollEl.scrollLeft = scrollLeft;
    }
  }

  get scrollTop() {
    return this.scrollEl?.scrollTop ?? 0;
  }

  set scrollTop(scrollTop: number) {
    if (this.scrollEl) {
      this.scrollEl.scrollTop = scrollTop;
    }
  }

  get scrollWidth() {
    return this.scrollEl?.scrollWidth ?? 0;
  }

  get clientHeight() {
    return this.scrollEl?.clientHeight ?? 0;
  }

  get clientWidth() {
    return this.scrollEl?.clientWidth ?? 0;
  }

  /**
   * Scroll to the top of the component.
   *
   * @param duration The amount of time to take scrolling to the top. Defaults to `0`.
   */
  scrollToTop(duration = 300): Observable<unknown> {
    return this.scrollToPoint(undefined, 0, duration).pipe(
      // Important for pinToTop as user wants this to be scrolled top but might not result in actual scrolling if the container is not scrollable
      tap(() => {
        if (this.get().pinToTop) {
          this.set({ isScrolledTo: 'top' });
        }
      }),
    );
  }

  /**
   * Scroll to the bottom of the component.
   *
   * @param duration The amount of time to take scrolling to the bottom. Defaults to `0`.
   */
  scrollToBottom(duration = 0): Observable<unknown> {
    return this.scrollEl$.pipe(
      switchMap(({ scrollEl }) => {
        const y = scrollEl.scrollHeight - scrollEl.clientHeight;
        return this.scrollToPoint(undefined, y, duration);
      }),
      // Important for pinToBottom as user wants this to be scrolled bottom but might not result in actual scrolling if the container is not scrollable
      tap(() => {
        if (this.get().pinToBottom) {
          this.set({ isScrolledTo: 'bottom' });
        }
      }),
    );
  }

  /**
   * Scroll by a specified X/Y distance in the component.
   *
   * @param x The amount to scroll by on the horizontal axis.
   * @param y The amount to scroll by on the vertical axis.
   * @param duration The amount of time to take scrolling by that amount.
   */
  scrollByPoint(x: number, y: number, duration: number): Observable<unknown> {
    return this.scrollEl$.pipe(
      switchMap(({ scrollEl }) => this.scrollToPoint(x + scrollEl.scrollLeft, y + scrollEl.scrollTop, duration)),
    );
  }

  scrollToPoint(x: number | undefined | null, y: number | undefined | null, duration = 300): Observable<unknown> {
    this.stopScrolling();

    return this.scrollEl$.pipe(
      switchMap(({ scrollEl: el }) => {
        if (duration < 32) {
          if (y != null) {
            el.scrollTop = y;
          }
          if (x != null) {
            el.scrollLeft = x;
          }
          return of(undefined);
        }

        let resolve!: () => void;
        let startTime = 0;
        // eslint-disable-next-line no-promise-executor-return
        const promise = new Promise<void>((r) => (resolve = r));
        const fromY = el.scrollTop;
        const fromX = el.scrollLeft;

        const deltaY = y != null ? y - fromY : 0;
        const deltaX = x != null ? x - fromX : 0;

        // Scroll loop
        const step = (timeStamp: number) => {
          const linearTime = Math.min(1, (timeStamp - startTime) / duration) - 1;
          const easedT = Math.pow(linearTime, 3) + 1;

          if (deltaY !== 0) {
            el.scrollTop = Math.floor(easedT * deltaY + fromY);
          }
          if (deltaX !== 0) {
            el.scrollLeft = Math.floor(easedT * deltaX + fromX);
          }

          if (easedT < 1) {
            // Do not use DomController here
            // Must use nativeRaf in order to fire in the next frame
            requestAnimationFrame(step);
          } else {
            resolve();
          }
        };

        // Chill out for a frame first
        requestAnimationFrame((ts) => {
          startTime = ts;
          step(ts);
        });

        return defer(() => from(promise));
      }),
    );
  }

  ngAfterViewInit() {
    const scrollEl = this.scrollElRef?.nativeElement;
    const contentEl = this.contentRef?.nativeElement;

    if (!scrollEl || !contentEl) {
      throw new Error(`Missing scrollEl/ contentEl!`);
    }

    this.set({ scrollEl, contentEl });

    // Resize observer for content
    this.contentResizeObserver.observe(contentEl);

    // Check for initial scrollTop
    const { initialScrollTop } = this.get() ?? {};
    if (initialScrollTop) {
      const height = scrollEl.clientHeight;
      contentEl.style.setProperty('min-height', px(initialScrollTop + height));
      scrollEl.scrollTop = initialScrollTop;
      contentEl.style.removeProperty('min-height');
    }

    // Is scrolled to
    this.hold(
      this.scrollTop$.pipe(
        throttleTimeEmitInstantlyWithTrailing(150),
        tap(() => {
          const scrollEl = this.scrollElRef?.nativeElement;
          if (scrollEl) {
            // Discard iOS rubberband negative scroll
            const scrollTop = Math.max(0, scrollEl.scrollTop);

            // Scrolled to bottom
            if (scrollTop + scrollEl.offsetHeight + SCROLLED_TO_THRESHOLD_PX >= scrollEl.scrollHeight) {
              this.set({ isScrolledTo: 'bottom' });
            } else if (scrollTop <= SCROLLED_TO_THRESHOLD_PX) {
              this.set({ isScrolledTo: 'top' });
            } else {
              this.set({ isScrolledTo: null });
            }
          }
        }),
      ),
    );

    // Pin-to-bottom
    this.hold(
      this.select(
        selectSlice(['pinToBottom', 'isScrolledTo']),
        switchMap(({ pinToBottom, isScrolledTo }) => {
          return !pinToBottom || isScrolledTo !== 'bottom'
            ? of(undefined)
            : this.contentResized$$.pipe(switchMap(() => this.scrollToBottom(0)));
        }),
      ),
    );
  }
}
