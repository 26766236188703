import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServiceHapticsModule } from '@t5s/mobile-client/service/haptics';
import { DefaultLongpressDirective } from './default-longpress.directive';
import { DefaultPressDisableLongpressDirective } from './default-press-disable-longpress.directive';
import { DefaultPressDirective } from './default-press.directive';
import { LongpressDirective } from './longpress.directive';
import { PressDisableLongpressDirective } from './press-disable-longpress.directive';

const directives = [
  DefaultPressDirective,
  LongpressDirective,
  DefaultLongpressDirective,
  PressDisableLongpressDirective,
  DefaultPressDisableLongpressDirective,
];

@NgModule({
  imports: [CommonModule, ServiceHapticsModule],
  declarations: directives,
  exports: directives,
})
export class LongpressDirectiveModule {}
