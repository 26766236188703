import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EmojiPickerPosition } from '@t5s/mobile-client/value-object/emoji-picker';
import { EMPTY, merge } from 'rxjs';
import { filter, first, map, mapTo, switchMap } from 'rxjs/operators';
import { selectEmojiPickerPosition } from './emoji-picker.selectors';
import { EmojiPickerActions } from './emoji-picker.actions';

export function openEmojiPicker(store$: Store, actions$: Actions) {
  return store$.select(selectEmojiPickerPosition).pipe(
    first(),
    switchMap((position) => {
      if (position !== EmojiPickerPosition.IS_BOTTOM) {
        return EMPTY;
      }
      store$.dispatch(EmojiPickerActions.open());

      const picked$ = actions$.pipe(
        ofType(EmojiPickerActions.picked),
        map(({ emoji }) => ({ emoji })),
      );

      const closed$ = store$.select(selectEmojiPickerPosition).pipe(
        filter((position) => position === EmojiPickerPosition.IS_BOTTOM),
        mapTo(undefined),
      );

      return merge(picked$, closed$).pipe(first());
    }),
  );
}
