import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { exactSize, flexCenter, flexRow, fullWidth, px, rounded } from '@t5s/client/ui/style/common';
import { EmojiService } from '@t5s/client/util/emoji-mart';
import { tss } from '@t5s/client/util/tss';
import { iconAddReaction } from '@t5s/mobile-client/asset';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { EmojiData } from '@t5s/mobile-client/value-object/emoji-picker';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface BottomSheetEmojiBarState {
  emojis?: string[];
}

@Component({
  selector: 't5s-bottom-sheet-emoji-bar',
  template: `
    <div [class]="containerClass">
      <!-- frequently used emojis x5 -->
      <ng-container *ngFor="let emoji of emojis$ | push">
        <button
          [class]="singleButtonClass"
          [t5sTouchActive]="'background: rgba(0, 0, 0, 0.07)'"
          (t5sPressDisableLongpress)="emitEmojiClick({ emoji: emoji })"
        >
          <ngx-emoji [emoji]="emoji" [isNative]="true" [size]="24"></ngx-emoji>
        </button>
      </ng-container>

      <!-- Add reaction button -->
      <button
        [class]="singleButtonClass"
        [t5sTouchActive]="'background: rgba(0, 0, 0, 0.07)'"
        (t5sPressDisableLongpress)="addReactionClick.emit()"
      >
        <t5s-icon [size]="27" [icon]="iconAddReaction" [fgColor]="Color.darker"></t5s-icon>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomSheetEmojiBarComponent extends RxComponent<BottomSheetEmojiBarState> {
  readonly iconAddReaction = iconAddReaction;

  constructor(private readonly emojiService: EmojiService) {
    super();
  }

  @Input() set emojis(emojis: string[] | Observable<string[]>) {
    this.setProperty('emojis', emojis);
  }

  @Output() emojiClick = new EventEmitter<EmojiData>();
  @Output() addReactionClick = new EventEmitter();

  readonly containerClass = tss({
    ...fullWidth,
    ...flexRow,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: px(16),
    paddingRight: px(16),
  });

  readonly singleButtonClass = tss({
    padding: px(10),
    ...rounded,
    backgroundColor: ThemeColorVar.lighter,
    ...exactSize(47),
    ...flexCenter,
  });

  readonly emojis$ = this.select(
    selectSlice(['emojis']),
    map(({ emojis }) => emojis?.slice(0, 5)),
  );

  emitEmojiClick({ emoji }: { emoji: string }) {
    const data = this.emojiService.getData(emoji);

    if (data?.native && data.id) {
      this.emojiClick.emit({ id: data.id, emoji: data.native });
    }
  }
}
