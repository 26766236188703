import { Injectable } from '@angular/core';
import {
  Camera,
  CameraPluginPermissions,
  CameraResultType,
  CameraSource,
  ImageOptions,
  PermissionStatus,
  Photo,
} from '@capacitor/camera';
import { defer, from, Observable } from 'rxjs';

@Injectable()
export class CameraService {
  checkPermissions(): Observable<PermissionStatus> {
    return defer(() => from(Camera.checkPermissions()));
  }

  requestPermissions(permissions?: CameraPluginPermissions | undefined): Observable<PermissionStatus> {
    return defer(() => from(Camera.requestPermissions(permissions)));
  }

  private async _getPhoto(options: ImageOptions): Promise<Photo | 'cancel' | 'denied'> {
    const permission = await Camera.checkPermissions();

    if (
      permission.photos === 'prompt' ||
      permission.photos === 'prompt-with-rationale' ||
      permission.photos === 'limited'
    ) {
      // Prompt user for permission
      const prompt = await Camera.requestPermissions({ permissions: ['photos'] });
      if (prompt.photos !== 'granted' && prompt.photos !== 'limited') {
        // User denied
        return 'denied';
      }
    }

    // User denied permanently
    if (permission.photos === 'denied') {
      return 'denied';
    }

    // Get photo via photo
    try {
      const photo = await Camera.getPhoto(options);
      return photo;
    } catch (err: unknown) {}

    return 'cancel';
  }

  getPhoto(options: Omit<ImageOptions, 'source' | 'resultType'>): Observable<Photo | 'cancel' | 'denied'> {
    return defer(() =>
      from(this._getPhoto({ ...options, resultType: CameraResultType.Base64, source: CameraSource.Photos })),
    );
  }

  private async _getCamera(options: ImageOptions): Promise<Photo | 'cancel' | 'denied'> {
    const permission = await Camera.checkPermissions();

    if (permission.camera === 'prompt' || permission.camera === 'prompt-with-rationale') {
      // Prompt user for permission
      const prompt = await Camera.requestPermissions({ permissions: ['camera'] });
      if (prompt.camera !== 'granted' && prompt.camera !== 'limited') {
        // User denied
        return 'denied';
      }
    }

    // User denied permanently
    if (permission.camera === 'denied') {
      return 'denied';
    }

    // Get photo via camera
    try {
      const photo = await Camera.getPhoto(options);
      return photo;
    } catch (err: unknown) {}

    return 'cancel';
  }

  getCamera(options: Omit<ImageOptions, 'source' | 'resultType'>): Observable<Photo | 'cancel' | 'denied'> {
    return defer(() =>
      from(this._getCamera({ ...options, resultType: CameraResultType.Base64, source: CameraSource.Camera })),
    );
  }
}
