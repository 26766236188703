import { Injectable } from '@angular/core';
import { SharedApolloClient } from '@t5s/shared/gql-services';
import { Apollo } from 'apollo-angular';
import {
  ApolloQueryResult,
  MutationOptions,
  OperationVariables,
  QueryOptions,
  SubscriptionOptions,
} from 'apollo-client';
import { Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';

/** Wrapper service for shared apollo client, enables client-relates error logging operations. */
@Injectable()
export class ApolloClientService extends SharedApolloClient {
  constructor(private readonly apollo: Apollo) {
    super();
  }

  getClient() {
    return this.apollo.getClient();
  }

  query<T = { [key: string]: any }, V = OperationVariables>(
    options: QueryOptions<V>,
  ): Observable<ApolloQueryResult<T>> {
    return this.apollo.watchQuery<T, V>(options).valueChanges.pipe(
      filter((res) => res.data !== undefined),
      first(),
    );
  }

  watchQuery<T = { [key: string]: any }, V = OperationVariables>(
    options: QueryOptions<V>,
  ): Observable<ApolloQueryResult<T>> {
    return this.apollo
      .watchQuery<T, V>({ ...options, fetchPolicy: 'cache-and-network' })
      .valueChanges.pipe(filter((res) => res.data !== undefined));
  }

  mutate<T = { [key: string]: any }, V = OperationVariables>(options: MutationOptions<T, V>) {
    return this.apollo.mutate<T, V>(options);
  }

  subscribe<T = { [key: string]: any }, V = OperationVariables>(options: SubscriptionOptions<V>) {
    return this.apollo.subscribe<T, V>(options);
  }
}
