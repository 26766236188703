/** Higher order function to create an enum type guard.
 *
 * Usage:
 *
 * `const isSomeEnum = enumTypeGuard(SomeEnum);`
 *
 * `if(isSomeEnum(value)) ...`
 *
 */
export const enumTypeGuard =
  <T extends { [key: string]: string }>(e: T) =>
  (token: any): token is T[keyof T] =>
    Object.values(e).includes(token as T[keyof T]);
