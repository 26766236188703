import gql from 'graphql-tag';
import {
  BlabItemPreviewDtoNoNestingFragment,
  BlabItemRevisionActivityDtoNoNestingFragment,
  ChecklistFlimValRevisionDtoNoNestingFragment,
  IncomingRelationRevisionDtoNoNestingFragment,
  MultiAttachmentFlimValRevisionDtoNoNestingFragment,
  MultiImageFlimValRevisionDtoNoNestingFragment,
  MultiCategoryFlimValRevisionDtoNoNestingFragment,
  MultiLinkFlimValRevisionDtoNoNestingFragment,
  MultiRelationFlimValRevisionDtoNoNestingFragment,
  MultiTextFlimValRevisionDtoNoNestingFragment,
  MultiUserFlimValRevisionDtoNoNestingFragment,
  NumberFlimValRevisionDtoNoNestingFragment,
  RangeDateFlimValRevisionDtoNoNestingFragment,
  SingleAttachmentFlimValRevisionDtoNoNestingFragment,
  SingleCategoryFlimValRevisionDtoNoNestingFragment,
  SingleDateFlimValRevisionDtoNoNestingFragment,
  SingleRelationFlimValRevisionDtoNoNestingFragment,
  SingleTextFlimValRevisionDtoNoNestingFragment,
  SingleUserFlimValRevisionDtoNoNestingFragment,
  StatusFlimValRevisionDtoNoNestingFragment,
  MultiEmailFlimValRevisionDtoNoNestingFragment,
  MultiPhoneFlimValRevisionDtoNoNestingFragment,
  SingleLocationFlimValRevisionDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { BlabItemActivityDtoFragment } from '../blab-item-activity/blab-item-activity-dto.fragment';
import { FlimValAttachmentFragment } from '../flim-val-attachment';
import { FlimValImageFragment } from '../flim-val-image';
import { UserProfilePreviewDtoFragment } from '../user-profile';
import { FlimValRevisionDtoFragment } from './flim-val-revision/flim-val-revision-dto.fragment';

export const BlabItemRevisionActivityFragment = gql`
  fragment BlabItemRevisionActivityFragment on BlabItemRevisionActivityDto {
    ...BlabItemActivityDtoFragment
    ...BlabItemRevisionActivityDtoNoNesting
    revisions {
      ... on SingleTextFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...SingleTextFlimValRevisionDtoNoNesting
      }
      ... on MultiTextFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...MultiTextFlimValRevisionDtoNoNesting
      }
      ... on SingleCategoryFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...SingleCategoryFlimValRevisionDtoNoNesting
      }
      ... on MultiCategoryFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...MultiCategoryFlimValRevisionDtoNoNesting
      }
      ... on SingleRelationFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...SingleRelationFlimValRevisionDtoNoNesting
        prevReferencedBlabItem {
          ...BlabItemPreviewDtoNoNesting
        }
        referencedBlabItem {
          ...BlabItemPreviewDtoNoNesting
        }
      }
      ... on MultiRelationFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...MultiRelationFlimValRevisionDtoNoNesting
        prevReferencedBlabItems {
          ...BlabItemPreviewDtoNoNesting
        }
        referencedBlabItems {
          ...BlabItemPreviewDtoNoNesting
        }
      }
      ... on IncomingRelationRevisionDto {
        ...IncomingRelationRevisionDtoNoNesting
        referencingBlabItem {
          id
          title
        }
        referencingFlimDef {
          id
          name
          blabDefId
          blabDef {
            id
            blabName
            itemName
            icon
            iconv2
            itemIcon
          }
        }
      }
      ... on SingleAttachmentFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...SingleAttachmentFlimValRevisionDtoNoNesting
        prevAttachment {
          ...FlimValAttachmentFragment
        }
        attachment {
          ...FlimValAttachmentFragment
        }
      }
      ... on MultiAttachmentFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...MultiAttachmentFlimValRevisionDtoNoNesting
        prevAttachments {
          ...FlimValAttachmentFragment
        }
        attachments {
          ...FlimValAttachmentFragment
        }
      }
      ... on MultiImageFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...MultiImageFlimValRevisionDtoNoNesting
        prevImages {
          ...FlimValImageFragment
        }
        images {
          ...FlimValImageFragment
        }
      }
      ... on SingleUserFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...SingleUserFlimValRevisionDtoNoNesting
        prevUser {
          id
          primaryName
        }
        user {
          id
          primaryName
        }
      }
      ... on MultiUserFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...MultiUserFlimValRevisionDtoNoNesting
        prevUsers {
          id
          primaryName
        }
        users {
          id
          primaryName
        }
      }
      ... on SingleDateFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...SingleDateFlimValRevisionDtoNoNesting
      }
      ... on RangeDateFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...RangeDateFlimValRevisionDtoNoNesting
      }
      ... on ChecklistFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...ChecklistFlimValRevisionDtoNoNesting
        prevChecklistEntry {
          id
          title
        }
        checklistEntry {
          id
          title
        }
        prevAssigneeUser {
          ...UserProfilePreviewDtoFragment
        }
        assigneeUser {
          ...UserProfilePreviewDtoFragment
        }
      }
      ... on MultiLinkFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...MultiLinkFlimValRevisionDtoNoNesting
        prevMultiLinkEntry {
          id
          linkPreview {
            id
            title
            url
          }
        }
        multiLinkEntry {
          id
          linkPreview {
            id
            title
            url
          }
        }
      }
      ... on NumberFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...NumberFlimValRevisionDtoNoNesting
      }
      ... on StatusFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...StatusFlimValRevisionDtoNoNesting
      }
      ... on MultiEmailFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...MultiEmailFlimValRevisionDtoNoNesting
      }
      ... on MultiPhoneFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...MultiPhoneFlimValRevisionDtoNoNesting
      }
      ... on SingleLocationFlimValRevisionDto {
        ...FlimValRevisionDtoFragment
        ...SingleLocationFlimValRevisionDtoNoNesting
      }
    }
  }
  ${BlabItemActivityDtoFragment}
  ${BlabItemRevisionActivityDtoNoNestingFragment},
  ${FlimValRevisionDtoFragment}
  ${SingleTextFlimValRevisionDtoNoNestingFragment}
  ${NumberFlimValRevisionDtoNoNestingFragment}
  ${MultiTextFlimValRevisionDtoNoNestingFragment}
  ${SingleCategoryFlimValRevisionDtoNoNestingFragment}
  ${MultiCategoryFlimValRevisionDtoNoNestingFragment}
  ${SingleRelationFlimValRevisionDtoNoNestingFragment}
  ${MultiRelationFlimValRevisionDtoNoNestingFragment}
  ${SingleAttachmentFlimValRevisionDtoNoNestingFragment}
  ${MultiAttachmentFlimValRevisionDtoNoNestingFragment}
  ${MultiImageFlimValRevisionDtoNoNestingFragment}
  ${SingleUserFlimValRevisionDtoNoNestingFragment}
  ${SingleDateFlimValRevisionDtoNoNestingFragment}
  ${RangeDateFlimValRevisionDtoNoNestingFragment}
  ${MultiUserFlimValRevisionDtoNoNestingFragment}
  ${IncomingRelationRevisionDtoNoNestingFragment}
  ${BlabItemPreviewDtoNoNestingFragment}
  ${FlimValAttachmentFragment}
  ${FlimValImageFragment}
  ${ChecklistFlimValRevisionDtoNoNestingFragment}
  ${MultiLinkFlimValRevisionDtoNoNestingFragment}
  ${UserProfilePreviewDtoFragment}
  ${StatusFlimValRevisionDtoNoNestingFragment}
  ${MultiEmailFlimValRevisionDtoNoNestingFragment}
  ${MultiPhoneFlimValRevisionDtoNoNestingFragment}
  ${SingleLocationFlimValRevisionDtoNoNestingFragment}
`;
