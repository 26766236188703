import { DateFormat, Language } from '@t5s/shared/gql';
import { ApplicableI18n } from '../applicable-i18n.interface';

export interface DateFormats {
  full: string;
  fullNonAbbr: string;
  omitYear: string;
  omitYearNonAbbr: string;
  weekday: string;
  weekdayAbbr: string;
  short: string;
  badge: string;
  badgeOmitYear: string;
  calendar: string;
  calendarMonthNonAbbr: string;
}

export const DateFormatsDict: { [key in Language]: { [key in DateFormat]: DateFormats } } = {
  [Language.EN]: {
    [DateFormat.SLASH_MM_DD_YYYY]: {
      full: 'MMM d, yyyy',
      fullNonAbbr: 'MMMM d, yyyy',
      omitYear: 'MMM d',
      omitYearNonAbbr: 'MMMM d',
      weekday: `EEEE`,
      weekdayAbbr: `EEE`,
      short: 'MM/dd/yyyy',
      badge: `EEEE, MMMM d, yyyy`,
      badgeOmitYear: `EEEE, MMMM d`,
      calendar: `EEE, MMM d, yyyy`,
      calendarMonthNonAbbr: `EEE, MMMM d, yyyy`,
    },
    [DateFormat.SLASH_DD_MM_YYYY]: {
      full: 'MMM d, yyyy',
      fullNonAbbr: 'MMMM d, yyyy',
      omitYear: 'MMM d',
      omitYearNonAbbr: 'MMMM d',
      weekday: `EEEE`,
      weekdayAbbr: `EEE`,
      short: 'dd/MM/yyyy',
      badge: `EEEE, MMMM d, yyyy`,
      badgeOmitYear: `EEEE, MMMM d`,
      calendar: `EEE, MMM d, yyyy`,
      calendarMonthNonAbbr: `EEE, MMMM d, yyyy`,
    },
    [DateFormat.DOT_DD_MM_YYYY]: {
      full: 'd. MMM yyyy',
      fullNonAbbr: 'd. MMMM yyyy',
      omitYear: 'd. MMM',
      omitYearNonAbbr: 'd. MMMM',
      weekday: `EEEE`,
      weekdayAbbr: `EEE`,
      short: 'dd.MM.yyyy',
      badge: `EEEE, d. MMMM yyyy`,
      badgeOmitYear: `EEEE, d. MMMM`,
      calendar: `EEE, d. MMM, yyyy`,
      calendarMonthNonAbbr: `EEE, d. MMMM, yyyy`,
    },
  },
  [Language.DE]: {
    [DateFormat.SLASH_MM_DD_YYYY]: {
      full: 'MMM d, yyyy',
      fullNonAbbr: 'MMMM d, yyyy',
      omitYear: 'MMM d',
      omitYearNonAbbr: 'MMMM d',
      weekday: `EEEE`,
      weekdayAbbr: `EE`,
      short: 'MM/dd/yyyy',
      badge: `EEEE, MMMM d, yyyy`,
      badgeOmitYear: `EEEE, MMMM d`,
      calendar: `EEEEEE, MMM d, yyyy`,
      calendarMonthNonAbbr: `EEEEEE, MMMM d, yyyy`,
    },
    [DateFormat.SLASH_DD_MM_YYYY]: {
      full: 'MMM d, yyyy',
      fullNonAbbr: 'MMMM d, yyyy',
      omitYear: 'MMM d',
      omitYearNonAbbr: 'MMMM d',
      weekday: `EEEE`,
      weekdayAbbr: `EEE`,
      short: 'dd/MM/yyyy',
      badge: `EEEE, MMMM d, yyyy`,
      badgeOmitYear: `EEEE, MMMM d`,
      calendar: `EEEEEE, MMM d, yyyy`,
      calendarMonthNonAbbr: `EEE, MMMM d, yyyy`,
    },
    [DateFormat.DOT_DD_MM_YYYY]: {
      full: 'd. MMM yyyy',
      fullNonAbbr: 'd. MMMM yyyy',
      omitYear: 'd. MMM',
      omitYearNonAbbr: 'd. MMMM',
      weekday: `EEEE`,
      weekdayAbbr: `EE`,
      short: 'dd.MM.yyyy',
      badge: `EEEE, d. MMMM yyyy`,
      badgeOmitYear: `EEEE, d. MMMM`,
      calendar: `EEEEEE, d. MMM yyyy`,
      calendarMonthNonAbbr: `EEEEEE, d. MMMM yyyy`,
    },
  },
};

export function getDateFormats({
  language = Language.EN,
  dateFormat = DateFormat.SLASH_MM_DD_YYYY,
}: Partial<ApplicableI18n>): DateFormats {
  return DateFormatsDict[language][dateFormat];
}
