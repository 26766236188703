import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { GqlUserSearchService } from '@t5s/shared/gql-services';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { BlabItemActivityThreadActions } from './blab-item-activity-thread.actions';

@Injectable()
export class BlabItemActivityThreadMentionUserEffects {
  constructor(private readonly actions$: Actions, private readonly userSearchService: GqlUserSearchService) {}

  readonly searchMentionUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.searchMentionUser),
      debounceTime(150),
      switchMap(({ searchQuery }) =>
        this.userSearchService.searchUsers({ query: searchQuery }).pipe(
          map((userSearchResults) =>
            BlabItemActivityThreadActions.searchMentionUserSuccess({
              searchQuery,
              userSearchResults: userSearchResults.map(({ user }) => user),
            }),
          ),
          catchErrorDispatch(BlabItemActivityThreadActions.searchMentionUserException),
          takeUntil(this.actions$.pipe(ofType(BlabItemActivityThreadActions.clearMentionUserSearchResults))),
        ),
      ),
    ),
  );
}
