import { ChatConversationClientModel } from '@t5s/mobile-client/value-object/chat-conversation';
import { ChatActivityDto, ChatMessageActivityDto, SingleConversationSubscriptionUpdate } from '@t5s/shared/gql';
import { TypeGuards } from '@t5s/shared/gql-type-guards/common';
import { upsertActivityIntoChatActivities } from './chat-activity.utils';

export function getChatConversationAfterChatConversationActivityLiveUpdate(
  chatConversation: ChatConversationClientModel,
  update: SingleConversationSubscriptionUpdate,
): ChatConversationClientModel {
  const { chatActivity } = chatConversation;
  let { localMessages } = chatConversation;

  if (!chatActivity) {
    return chatConversation;
  }

  let { chatActivities } = chatActivity;

  if (TypeGuards.isChatActivityDeletedDto(update)) {
    chatActivities = chatActivities.filter((reply) => reply.node.id !== update.id);
    return { ...chatConversation, chatActivity: { ...chatActivity, chatActivities } };
  }

  const activityToUpsert = update as ChatActivityDto;
  chatActivities = upsertActivityIntoChatActivities(activityToUpsert, chatActivities);

  // the live update may contain a message that is still local on this client, remove in that case
  // this happens if the live update comes in faster than mutation result (wtf)
  const { clientSideId } = activityToUpsert as ChatMessageActivityDto;

  if (clientSideId) {
    // remove local activity, as this update obviously contained a real activity for that UID
    localMessages = localMessages.filter((localMessage) => localMessage.localId !== clientSideId);
  }

  return { ...chatConversation, chatActivity: { ...chatActivity, chatActivities }, localMessages };
}
