import { AddChatActivityReactionInput, ChatActivityDto, RemoveChatActivityReactionInput } from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import { ChatActivityFragment } from '../../fragments/chat';
import { addChatActivityReaction, removeChatActivityReaction } from '../../generated/graphql-mutations';
import { GqlService } from '../../gql-service';

export class GqlChatActivityReactionService extends GqlService {
  addChatActivityReaction(input: AddChatActivityReactionInput): Observable<ChatActivityDto> {
    return this.apollo.defaultMutate(addChatActivityReaction(input, [ChatActivityFragment]));
  }

  removeChatActivityReaction(input: RemoveChatActivityReactionInput): Observable<ChatActivityDto> {
    return this.apollo.defaultMutate(removeChatActivityReaction(input, [ChatActivityFragment]));
  }
}
