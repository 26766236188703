import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ScrollAnchorDirective } from './scroll-anchor.directive';

const directives = [ScrollAnchorDirective];

@NgModule({
  imports: [CommonModule],
  declarations: directives,
  exports: directives,
})
export class ScrollAnchorDirectiveModule {}
