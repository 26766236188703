import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DefaultTouchActiveDirective } from './default-touch-active.directive';
import { TouchActiveDirective } from './touch-active.directive';

const directives = [TouchActiveDirective, DefaultTouchActiveDirective];

@NgModule({
  imports: [CommonModule],
  declarations: directives,
  exports: directives,
})
export class TouchActiveDirectiveModule {}
