import gql from 'graphql-tag';
import { RemoveWorkspaceMembershipRejectedNoNestingFragment } from '../../generated/graphql-fragments';

export const RemoveWorkspaceMembershipResultFragment = gql`
  fragment RemoveWorkspaceMembershipResultFragment on RemoveWorkspaceMembershipResult {
    ... on RemoveWorkspaceMembershipSuccess {
      void {
        _void
      }
    }
    ... on RemoveWorkspaceMembershipRejected {
      ...RemoveWorkspaceMembershipRejectedNoNesting
    }
  }
  ${RemoveWorkspaceMembershipRejectedNoNestingFragment}
`;
