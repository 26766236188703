import { parseIntegerOrThrow } from '@t5s/shared/util/number';
import {
  PushNotificationData,
  PushNotificationPayloadType,
  PushNotificationPayloadUnionType,
} from '@t5s/shared/value-object/push-notification';

export function pushDataFromNotificationPayload(payload: PushNotificationPayloadUnionType): PushNotificationData {
  const { type } = payload;

  // if type is not available, there is no runtime safety for this push notification's payload
  if (!type) {
    return {};
  }

  switch (payload.type) {
    case PushNotificationPayloadType.CHAT_PUSH_NOTIFICATION: {
      const { chatActivityId, conversationId } = payload;
      return {
        type,
        conversationId: `${conversationId}`,
        chatActivityId: `${chatActivityId}`,
      };
    }

    case PushNotificationPayloadType.BLAB_ITEM_PUSH_NOTIFICATION: {
      const { notificationId, blabItemId, blabItemActivityId } = payload;
      return {
        type,
        notificationId: `${notificationId}`,
        blabItemId: `${blabItemId}`,
        blabItemActivityId: `${blabItemActivityId}`,
      };
    }

    case PushNotificationPayloadType.BLAB_ITEM_ACTIVITY_THREAD_PUSH_NOTIFICATION: {
      const { notificationId, blabItemId, blabItemActivityThreadId, replyId } = payload;
      return {
        type,
        notificationId: `${notificationId}`,
        blabItemId: `${blabItemId}`,
        blabItemActivityThreadId: `${blabItemActivityThreadId}`,
        replyId: `${replyId}`,
      };
    }

    case PushNotificationPayloadType.WORKFLOW_DEF_PUSH_NOTIFICATION: {
      const { workflowDefId } = payload;
      return {
        type,
        workflowDefId: `${workflowDefId}`,
      };
    }

    case PushNotificationPayloadType.BLAB_DEF_PUSH_NOTIFICATION: {
      const { blabDefId } = payload;
      return {
        type,
        blabDefId: `${blabDefId}`,
      };
    }

    case PushNotificationPayloadType.USER_GROUP_PUSH_NOTIFICATION: {
      const { userGroupId } = payload;
      return {
        type,
        userGroupId: `${userGroupId}`,
      };
    }

    default: {
      throw new Error(`pushDataFromNotificationPayload not implemented for push notification type ${type}`);
    }
  }
}

export function pushNotificationPayloadFromData(
  data?: PushNotificationData,
): PushNotificationPayloadUnionType | undefined {
  if (!data) {
    return undefined;
  }

  const { type } = data as { type: PushNotificationPayloadType };

  // push notifciation contained payload, but the type property was not present;
  // no type safety at runtime -> discard
  if (!type) {
    return undefined;
  }

  switch (type) {
    case PushNotificationPayloadType.CHAT_PUSH_NOTIFICATION: {
      const { conversationId, chatActivityId } = data;
      return {
        type,
        conversationId: parseIntegerOrThrow(conversationId),
        chatActivityId: parseIntegerOrThrow(chatActivityId),
      };
    }

    case PushNotificationPayloadType.BLAB_ITEM_PUSH_NOTIFICATION: {
      const { notificationId, blabItemId, blabItemActivityId } = data;
      return {
        type,
        notificationId: parseIntegerOrThrow(notificationId),
        blabItemId: parseIntegerOrThrow(blabItemId),
        blabItemActivityId: parseIntegerOrThrow(blabItemActivityId),
      };
    }

    case PushNotificationPayloadType.BLAB_ITEM_ACTIVITY_THREAD_PUSH_NOTIFICATION: {
      const { notificationId, blabItemId, blabItemActivityThreadId, replyId } = data;
      return {
        type,
        notificationId: parseIntegerOrThrow(notificationId),
        blabItemId: parseIntegerOrThrow(blabItemId),
        blabItemActivityThreadId: parseIntegerOrThrow(blabItemActivityThreadId),
        replyId: parseIntegerOrThrow(replyId),
      };
    }

    case PushNotificationPayloadType.WORKFLOW_DEF_PUSH_NOTIFICATION: {
      const { notificationId, workflowDefId } = data;
      return {
        type,
        notificationId: parseIntegerOrThrow(notificationId),
        workflowDefId: parseIntegerOrThrow(workflowDefId),
      };
    }

    case PushNotificationPayloadType.BLAB_DEF_PUSH_NOTIFICATION: {
      const { notificationId, blabDefId } = data;
      return {
        type,
        notificationId: parseIntegerOrThrow(notificationId),
        blabDefId: parseIntegerOrThrow(blabDefId),
      };
    }

    case PushNotificationPayloadType.USER_GROUP_PUSH_NOTIFICATION: {
      const { notificationId, userGroupId } = data;
      return {
        type,
        notificationId: parseIntegerOrThrow(notificationId),
        userGroupId: parseIntegerOrThrow(userGroupId),
      };
    }

    default: {
      return undefined;
    }
  }
}
