import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { formatReminderDate as _formatReminderDate, isApplicableI18n, normalizeDate } from '../common';

export function formatReminderDate(i18n: SharedI18n, date?: string | number | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  return _formatReminderDate(i18n, date);
}
