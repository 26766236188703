import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { ActiveUserSessionActions } from '@t5s/mobile-client/business-logic/active-user-session';
import { ROUTE_FRAGMENT_LOGGED_IN } from '@t5s/mobile-client/readonly-constant/logged-in';
import { StorageService } from '@t5s/mobile-client/service/storage';
import { shimConsoleLog } from '@t5s/mobile-client/util/debug-console';
import { GqlLoginService } from '@t5s/shared/gql-services';
import { TypeGuards } from '@t5s/shared/gql-type-guards/common';
import { map, switchMap } from 'rxjs/operators';
import { DebugConsoleActions } from './debug-console.actions';

@Injectable()
export class DebugConsoleEffects {
  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly storageService: StorageService,
    private readonly http: HttpClient,
    private readonly loginService: GqlLoginService,
    private readonly router: Router,
  ) {
    shimConsoleLog((statement) => {
      this.store$.dispatch(DebugConsoleActions.addConsoleStatement({ statement }));
    });
  }

  // Client build version
  readonly loadClientBuildVersion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DebugConsoleActions.loadClientBuildVersion),
      switchMap(() =>
        this.http.get<{ version: string }>('/assets/version.json', {
          headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
        }),
      ),
      map((res) =>
        typeof res === 'object' && res.version ? res : { version: 'could-not-load-version-invalid-format' },
      ),
      map(({ version }) => DebugConsoleActions.loadClientBuildVersionSuccess({ clientBuildVersion: version })),
    ),
  );

  // Storage
  readonly readStorageKeys$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DebugConsoleActions.readStorageKeys),
      switchMap(() => this.storageService.keys()),
      map((keys) => DebugConsoleActions.readStorageKeysSuccess(keys)),
    ),
  );

  readonly loadStorageKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DebugConsoleActions.loadStorageKey),
      switchMap(({ key }) =>
        this.storageService
          .get({ key })
          .pipe(map(({ value }) => DebugConsoleActions.loadStorageKeySuccess({ key, value: value ?? undefined }))),
      ),
    ),
  );

  readonly deleteStorageKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DebugConsoleActions.deleteStorageKey),
      switchMap(({ key }) =>
        this.storageService.remove({ key }).pipe(map(() => DebugConsoleActions.deleteStorageKeySuccess({ key }))),
      ),
    ),
  );

  // Login
  readonly performUserLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DebugConsoleActions.performUserLogin),
      switchMap(({ input }) =>
        this.loginService.authenticateWithCredentials(input.orgId, input.credentials).pipe(
          map((result) => {
            if (TypeGuards.isUserSessionDto(result)) {
              return DebugConsoleActions.performUserLoginSuccess({ session: result });
            }
            return DebugConsoleActions.performUserLoginRejected({ reason: result.reason });
          }),
          catchErrorDispatch(DebugConsoleActions.performUserLoginError),
        ),
      ),
    ),
  );

  readonly navigateToLoggedIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DebugConsoleActions.performUserLoginSuccess),
        map(({ session }) =>
          this.store$.dispatch(ActiveUserSessionActions.setActiveUserSessions({ sessions: [session] })),
        ),
        switchMap(() => this.router.navigate([ROUTE_FRAGMENT_LOGGED_IN])),
      ),
    { dispatch: false },
  );
}
