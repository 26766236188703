import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofErrorType } from '@t5s/client/util/store';
import { ApmService } from '@t5s/mobile-client/service/apm';
import { tap } from 'rxjs/operators';

@Injectable()
export class ExceptionLogEffects {
  constructor(private readonly actions$: Actions, private readonly apm: ApmService) {}

  readonly logExceptionsToApm$ = createEffect(
    () =>
      this.actions$.pipe(
        ofErrorType(),
        tap(({ error }) => {
          this.apm.handleError(error);
        }),
      ),
    { dispatch: false },
  );
}
