import gql from 'graphql-tag';
import { OrganizationUserDtoFragment } from './organization-user.fragment';

export const OrganizationUsersConnectionFragment = gql`
  fragment OrganizationUsersConnectionFragment on OrganizationUsersConnection {
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...OrganizationUserDtoFragment
      }
    }
  }
  ${OrganizationUserDtoFragment}
`;
