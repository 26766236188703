import gql from 'graphql-tag';
import { WorkflowRunLogsAppendedSubscriptionUpdateNoNestingFragment } from '../../../generated/graphql-fragments';
import { WorkflowRunLogEventUnionFragment } from '../workflow-run-log-event-union.fragment';

export const WorkflowRunLogsAppendedSubscriptionUpdateFragment = gql`
  fragment WorkflowRunLogsAppendedSubscriptionUpdateFragment on WorkflowRunLogsAppendedSubscriptionUpdate {
    ...WorkflowRunLogsAppendedSubscriptionUpdateNoNesting

    logs {
      ...WorkflowRunLogEventUnionFragment
    }
  }
  ${WorkflowRunLogsAppendedSubscriptionUpdateNoNestingFragment}
  ${WorkflowRunLogEventUnionFragment}
`;
