import gql from 'graphql-tag';
import { WorkspaceFragment } from '../workspace';
import { DuplicateSharedWorkspaceResultDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const DuplicateSharedWorkspaceResultDtoFragment = gql`
  fragment DuplicateSharedWorkspaceResultDtoFragment on DuplicateSharedWorkspaceResultDto {
    ...DuplicateSharedWorkspaceResultDtoNoNesting
    workspace {
      ...WorkspaceFragment
    }
  }
  ${DuplicateSharedWorkspaceResultDtoNoNestingFragment}
  ${WorkspaceFragment}
`;
