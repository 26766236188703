import gql from 'graphql-tag';
import { ConvertSingleTextFlimToSingleCategoryFlimResultDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../../flim-def';

export const ConvertSingleTextFlimToSingleCategoryFlimResultDtoFragment = gql`
  fragment ConvertSingleTextFlimToSingleCategoryFlimResultDtoFragment on ConvertSingleTextFlimToSingleCategoryFlimResultDto {
    ...ConvertSingleTextFlimToSingleCategoryFlimResultDtoNoNesting

    flimDef {
      ...FullFlimDef
    }
  }
  ${ConvertSingleTextFlimToSingleCategoryFlimResultDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
