import { NgModule } from '@angular/core';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { DelimiterComponent } from './delimiter.component';

const components = [DelimiterComponent];

@NgModule({
  imports: [ComponentCommonModule],
  declarations: components,
  exports: components,
})
export class DelimiterComponentModule {}
