import gql from 'graphql-tag';
import {
  OrganizationProfileEditDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const OrganizationProfileEditDtoFragment = gql`
  fragment OrganizationProfileEditDtoFragment on OrganizationProfileEditDto {
    ...OrganizationProfileEditDtoNoNesting
    logo {
      ...ThumbnailDtoNoNesting
    }
  }
  ${OrganizationProfileEditDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
