import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { EmojiModule } from '@t5s/client/util/emoji-mart';
import { BottomSheetComponentModule } from '@t5s/mobile-client/ui/component/bottom-sheet';
import { EmojiPickerComponentModule } from '@t5s/mobile-client/ui/component/emoji-picker';
import { ViewCommonModule } from '@t5s/mobile-client/ui/view/common';
import { EmojiPickerViewComponent } from './emoji-picker-view.component';

const components = [EmojiPickerViewComponent];

@NgModule({
  imports: [ViewCommonModule, ScrollingModule, EmojiModule, EmojiPickerComponentModule, BottomSheetComponentModule],
  declarations: components,
  exports: components,
})
export class EmojiPickerViewModule {}
