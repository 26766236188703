import { NgModule } from '@angular/core';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { GraphicComponent } from './graphic.component';
import { IconComponent } from './icon.component';

const components = [IconComponent, GraphicComponent];

@NgModule({
  imports: [ComponentCommonModule],
  declarations: components,
  exports: components,
})
export class AssetComponentModule {}
