import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getCanSafelyNavigate$ } from '@t5s/client/util/router';
import { ChatActions } from '@t5s/mobile-client/business-logic/chat';
import { ChatConversationActions } from '@t5s/mobile-client/business-logic/chat-conversation';
import { ROUTE_FRAGMENT_CHAT, ROUTE_FRAGMENT_LOGGED_IN } from '@t5s/mobile-client/readonly-constant/logged-in';
import { PushNotificationService } from '@t5s/mobile-client/service/push-notification';
import { pushNotificationPayloadFromData } from '@t5s/shared/util/push-notification';
import { isChatPushNotificationPayload } from '@t5s/shared/value-object/push-notification';
import { from } from 'rxjs';
import { delay, first, map, mergeMap, switchMap } from 'rxjs/operators';
import { ChatPushNotificationActions } from './chat-push-notification.actions';

@Injectable()
export class ChatPushNotificationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly pushNotificationService: PushNotificationService,
    private readonly router: Router,
  ) {}

  readonly pushActionPerformed$ = createEffect(() =>
    this.pushNotificationService.pushNotificationActionPerformed$.pipe(
      mergeMap(({ notification }) => {
        const { data } = notification;
        const payload = pushNotificationPayloadFromData(data);

        if (isChatPushNotificationPayload(payload)) {
          return [ChatPushNotificationActions.chatPushNotificationOpened({ payload })];
        }

        return [];
      }),
    ),
  );

  readonly chatPushNotificationOpenedNavigate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatPushNotificationActions.chatPushNotificationOpened),
      switchMap(({ payload }) => {
        const { conversationId, chatActivityId } = payload;

        // check and wait for router to finalize current navigations, this led to bugs in the past so keep it comin'
        const canSafelyProceedWithNavigation$ = getCanSafelyNavigate$(this.router).pipe(first());

        return canSafelyProceedWithNavigation$.pipe(
          switchMap(() =>
            from(this.router.navigate([ROUTE_FRAGMENT_LOGGED_IN, ROUTE_FRAGMENT_CHAT])).pipe(
              delay(250),
              map(() => ChatActions.accessConversation({ conversationId, chatActivityId })),
            ),
          ),
        );
      }),
    ),
  );

  readonly chatPushNotificationOpenedLoadConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatPushNotificationActions.chatPushNotificationOpened),
      map(({ payload }) => {
        const { conversationId } = payload;
        return ChatConversationActions.loadConversationChatActivity({ conversationId });
      }),
    ),
  );
}
