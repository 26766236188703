import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { GqlUserSearchService } from '@t5s/shared/gql-services';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { ChatConversationActions } from './chat-conversation.actions';

@Injectable()
export class ChatConversationMentionUserEffects {
  constructor(private readonly actions$: Actions, private readonly userSearchService: GqlUserSearchService) {}

  readonly searchMentionUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatConversationActions.searchMentionUser),
      debounceTime(150),
      switchMap(({ searchQuery }) =>
        this.userSearchService.searchUsers({ query: searchQuery }).pipe(
          map((userSearchResults) =>
            ChatConversationActions.searchMentionUserSuccess({
              searchQuery,
              userSearchResults: userSearchResults.map(({ user }) => user),
            }),
          ),
          catchErrorDispatch(ChatConversationActions.searchMentionUserException),
          takeUntil(this.actions$.pipe(ofType(ChatConversationActions.clearMentionUserSearchResults))),
        ),
      ),
    ),
  );
}
