import { NgModule } from '@angular/core';
import { ServiceStatusBarModule, StatusBarService } from '@t5s/mobile-client/service/status-bar';

@NgModule({
  imports: [ServiceStatusBarModule],
})
export class ApplicationStatusBarBusinessLogicModule {
  constructor(private readonly statusBar: StatusBarService) {
    // Set webView to overlay so we control the status bar background (android only)
    this.statusBar.setStyleLight().subscribe();
  }
}
