import gql from 'graphql-tag';
import { BlabDefDtoNoNestingFragment, WorkspaceDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../flim-def';

export const FullBlabDefDtoFragment = gql`
  fragment FullBlabDefDto on BlabDefDto {
    ...BlabDefDtoNoNesting
    workspace {
      ...WorkspaceDtoNoNesting
    }
    flimDefs {
      ...FullFlimDef
    }
  }
  ${BlabDefDtoNoNestingFragment}
  ${FullFlimDefFragment}
  ${WorkspaceDtoNoNestingFragment}
`;
