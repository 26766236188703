import gql from 'graphql-tag';
import { UserProfilePreviewDtoOmitOrgFragment } from '../user-profile';

export const BlabDefDataImportToNewBlabDefConfigPreviewLookupDtoFragment = gql`
  fragment BlabDefDataImportToNewBlabDefConfigPreviewLookupDto on BlabDefDataImportToNewBlabDefConfigPreviewLookupDto {
    users {
      ...UserProfilePreviewDtoOmitOrgFragment
    }
  }

  ${UserProfilePreviewDtoOmitOrgFragment}
`;
