import gql from 'graphql-tag';
import {
  SingleUserSplitByDtoNoNestingFragment,
  SingleUserSplitBySectionDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const SingleUserSplitByDtoFragment = gql`
  fragment SingleUserSplitByDto on SingleUserSplitByDto {
    ...SingleUserSplitByDtoNoNesting
    sections {
      ...SingleUserSplitBySectionDtoNoNesting
      user {
        id
        primaryName
        profilePicture {
          id
          small
        }
      }
    }
  }
  ${SingleUserSplitByDtoNoNestingFragment}
  ${SingleUserSplitBySectionDtoNoNestingFragment}
`;
