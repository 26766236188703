import gql from 'graphql-tag';
import { OrganizationInvitationSignupUserInfoDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const OrganizationInvitationSignupUserInfoDtoFragment = gql`
  fragment OrganizationInvitationSignupUserInfoDtoFragment on OrganizationInvitationSignupUserInfoDto {
    ...OrganizationInvitationSignupUserInfoDtoNoNesting
    organization {
      id
      name
      slug
    }
  }
  ${OrganizationInvitationSignupUserInfoDtoNoNestingFragment}
`;
