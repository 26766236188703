import gql from 'graphql-tag';
import {
  ActiveUserBlabItemInfoDtoNoNestingFragment,
  ReminderDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const AddBlabItemReminderResultDtoFragment = gql`
  fragment AddBlabItemReminderResultDtoFragment on AddBlabItemReminderResultDto {
    reminder {
      ...ReminderDtoNoNesting
    }
    blabItemInfo {
      ...ActiveUserBlabItemInfoDtoNoNesting
    }
  }

  ${ReminderDtoNoNestingFragment}
  ${ActiveUserBlabItemInfoDtoNoNestingFragment}
`;
