export interface FontSet {
  fontSize: number;
  fontWeight: number;
  lineHeight: number;
}

export const fontWeight = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  black: 900,
} as const;

export enum FontFamilyName {
  ROBOTO = '"Roboto"',
  ROBOTO_MONO = '"Roboto Mono"',
  NOTO_COLOR_EMOJI = '"Noto Color Emoji"',
  APPLE_COLOR_EMOJI = '"Apple Color Emoji"',
}

export const fontFamily = {
  default: `${FontFamilyName.ROBOTO}, ${FontFamilyName.APPLE_COLOR_EMOJI}`,
  mono: FontFamilyName.ROBOTO_MONO,
} as const;

export const font = {
  regular12px: {
    fontSize: 12,
    lineHeight: 14,
    fontWeight: fontWeight.regular,
  },
  regular14px: {
    fontSize: 14,
    lineHeight: 16,
    fontWeight: fontWeight.regular,
  },
  regular16px: {
    fontSize: 16,
    lineHeight: 19,
    fontWeight: fontWeight.regular,
  },
  regular17px: {
    fontSize: 17,
    lineHeight: 20,
    fontWeight: fontWeight.regular,
  },
  regular19px: {
    fontSize: 19,
    lineHeight: 22,
    fontWeight: fontWeight.regular,
  },
  medium12px: {
    fontSize: 12,
    lineHeight: 14,
    fontWeight: fontWeight.medium,
  },
  medium14px: {
    fontSize: 14,
    lineHeight: 16,
    fontWeight: fontWeight.medium,
  },
  medium16px: {
    fontSize: 16,
    lineHeight: 19,
    fontWeight: fontWeight.medium,
  },
  bold14px: {
    fontSize: 14,
    lineHeight: 16,
    fontWeight: fontWeight.bold,
  },
  bold16px: {
    fontSize: 16,
    lineHeight: 19,
    fontWeight: fontWeight.bold,
  },
  black17px: {
    fontSize: 17,
    lineHeight: 20,
    fontWeight: fontWeight.black,
  },
  black18px: {
    fontSize: 18,
    lineHeight: 21,
    fontWeight: fontWeight.black,
  },
  black19px: {
    fontSize: 19,
    lineHeight: 23,
    fontWeight: fontWeight.black,
  },
  black23px: {
    fontSize: 23,
    lineHeight: 28,
    fontWeight: fontWeight.black,
  },
  black30px: {
    fontSize: 30,
    lineHeight: 36,
    fontWeight: fontWeight.black,
  },
} as const;
