import gql from 'graphql-tag';
import { ConvertMultiTextFlimToSingleTextFlimResultDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../../flim-def';

export const ConvertMultiTextFlimToSingleTextFlimResultDtoFragment = gql`
  fragment ConvertMultiTextFlimToSingleTextFlimResultDtoFragment on ConvertMultiTextFlimToSingleTextFlimResultDto {
    ...ConvertMultiTextFlimToSingleTextFlimResultDtoNoNesting

    flimDef {
      ...FullFlimDef
    }
  }
  ${ConvertMultiTextFlimToSingleTextFlimResultDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
