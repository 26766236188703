import gql from 'graphql-tag';
import { ConvertSingleTextFlimToNumberFlimResultDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../../flim-def';

export const ConvertSingleTextFlimToNumberFlimResultDtoFragment = gql`
  fragment ConvertSingleTextFlimToNumberFlimResultDtoFragment on ConvertSingleTextFlimToNumberFlimResultDto {
    ...ConvertSingleTextFlimToNumberFlimResultDtoNoNesting

    flimDef {
      ...FullFlimDef
    }
  }
  ${ConvertSingleTextFlimToNumberFlimResultDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
