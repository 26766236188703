import gql from 'graphql-tag';
import { LastModifiedByFlimValDtoNoNestingFragment } from '../../../../generated/graphql-fragments';
import { UserProfilePreviewDtoFragment } from '../../../user-profile';
import { BlabItemsOverviewWorkflowDefPreviewFragment } from '../blab-items-overview-workflow-def-preview.fragment';

export const LastModifiedByFlimValFragment = gql`
  fragment BlabItemsOverviewLastModifiedByFlimVal on LastModifiedByFlimValDto {
    ...LastModifiedByFlimValDtoNoNesting
    lastModifiedByUser {
      ...UserProfilePreviewDtoFragment
    }
    lastModifiedByWorkflowDef {
      ...BlabItemsOverviewWorkflowDefPreview
    }
  }
  ${LastModifiedByFlimValDtoNoNestingFragment}
  ${UserProfilePreviewDtoFragment}
  ${BlabItemsOverviewWorkflowDefPreviewFragment}
`;
