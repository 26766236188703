import { I18nKey } from './i18n-key';
import { enLang, LangObjType } from './language-objects';

interface RecursiveObject {
  [key: string]: RecursiveObject | string;
}

export type CastToI18n<O> = { [P in keyof O]: O[P] extends string ? I18nKey : CastToI18n<O[P]> };
export type I18nKeyObject<lang = LangObjType> = CastToI18n<lang>;

export const i18nKeyDelimiter = '.';

export function i18nKeyObjFromLang<lang>(langObj: RecursiveObject, parentPaths: string[] = []): I18nKeyObject<lang> {
  const objCopy: { [key: string]: any } = {};

  Object.keys(langObj).forEach((key) => {
    if (typeof langObj[key] === 'string') {
      objCopy[key] = [...parentPaths, key].join(i18nKeyDelimiter);
    } else {
      objCopy[key] = i18nKeyObjFromLang(langObj[key] as RecursiveObject, [...parentPaths, key]);
    }
  });

  return objCopy as I18nKeyObject<lang>;
}

export const I18nKeys: I18nKeyObject = i18nKeyObjFromLang(enLang);
