export abstract class IdValidation {
  static readonly minimum = 1;
  static readonly maximum = 2147483627;
}

export function isValidId(input: unknown): boolean {
  return (
    typeof input === 'number' &&
    input >= IdValidation.minimum &&
    input <= IdValidation.maximum &&
    Number.isInteger(input)
  );
}
