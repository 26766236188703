import { SharedI18n } from '@t5s/shared/value-object/i18n';
import {
  formatDateViaLanguage,
  getDateTimeFormats,
  isApplicableI18n,
  normalizeDate,
  shouldAbbreviateMonth,
} from '../common';

/** e.g. `Mar 1, 2020 · 9:01 AM` */
export function formatFullDateTime(i18n: SharedI18n, date?: string | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  const dateTimeFormats = getDateTimeFormats(i18n);

  const shouldAbbreviate = shouldAbbreviateMonth(i18n, date);

  const dateFormat = shouldAbbreviate ? dateTimeFormats.date.full : dateTimeFormats.date.fullNonAbbr;
  const { short } = dateTimeFormats.time;
  const format = `${dateFormat} · ${short}`;

  return formatDateViaLanguage(date, format, i18n);
}
