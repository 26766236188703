import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  GqlChatActivityReactionService,
  GqlChatActivityService,
  GqlChatConversationService,
  SharedApolloClient,
} from '@t5s/shared/gql-services';
import { ChatHydrationEffects } from './chat-hydration.effects';
import { ChatEffects } from './chat.effects';
import { ChatNavigation } from './chat.navigation';
import { chatReducer, chatStateKey } from './chat.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(chatStateKey, chatReducer),
    EffectsModule.forFeature([ChatEffects, ChatHydrationEffects]),
  ],
  providers: [
    ChatNavigation,
    {
      provide: GqlChatConversationService,
      useFactory: (apollo: SharedApolloClient) => new GqlChatConversationService(apollo),
      deps: [SharedApolloClient],
    },
    {
      provide: GqlChatActivityService,
      useFactory: (apollo: SharedApolloClient) => new GqlChatActivityService(apollo),
      deps: [SharedApolloClient],
    },
    {
      provide: GqlChatActivityReactionService,
      useFactory: (apollo: SharedApolloClient) => new GqlChatActivityReactionService(apollo),
      deps: [SharedApolloClient],
    },
  ],
})
export class ChatBusinessLogicModule {}
