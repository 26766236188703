import gql from 'graphql-tag';
import {
  ActiveUserSingleCategorySplitBySectionDtoNoNestingFragment,
  SingleCategoryOptionDefDtoNoNestingFragment,
} from '../../../../generated/graphql-fragments';

export const ActiveUserSingleCategorySplitBySectionDtoFragment = gql`
  fragment ActiveUserSingleCategorySplitBySectionDto on ActiveUserSingleCategorySplitBySectionDto {
    ...ActiveUserSingleCategorySplitBySectionDtoNoNesting
    singleCategoryOptionDef {
      ...SingleCategoryOptionDefDtoNoNesting
    }
  }
  ${ActiveUserSingleCategorySplitBySectionDtoNoNestingFragment}
  ${SingleCategoryOptionDefDtoNoNestingFragment}
`;
