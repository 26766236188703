import gql from 'graphql-tag';
import {
  SearchHistoryItemQueryDtoNoNestingFragment,
  SearchHistoryItemResultDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { GlobalSearchResultFragment } from '../global-search';

export const GlobalSearchHistoryItemFragment = gql`
  fragment GlobalSearchHistoryItem on SearchHistoryItemUnionType {
    ... on SearchHistoryItemQueryDto {
      ...SearchHistoryItemQueryDtoNoNesting
    }
    ... on SearchHistoryItemResultDto {
      ...SearchHistoryItemResultDtoNoNesting
      result {
        ...GlobalSearchResult
      }
    }
  }
  ${SearchHistoryItemQueryDtoNoNestingFragment}
  ${SearchHistoryItemResultDtoNoNestingFragment}
  ${GlobalSearchResultFragment}
`;
