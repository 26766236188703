import gql from 'graphql-tag';
import {
  RangeDateSplitByDtoNoNestingFragment,
  RangeDateSplitBySectionDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const RangeDateSplitByDtoFragment = gql`
  fragment RangeDateSplitByDto on RangeDateSplitByDto {
    ...RangeDateSplitByDtoNoNesting
    sections {
      ...RangeDateSplitBySectionDtoNoNesting
    }
  }
  ${RangeDateSplitByDtoNoNestingFragment}
  ${RangeDateSplitBySectionDtoNoNestingFragment}
`;
