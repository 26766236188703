import { I18nTranslation } from '@t5s/mobile-client/i18n/common';
import { Language } from '@t5s/shared/gql';

const languages = {
  [Language.DE]: {
    blabItemActivity: {
      sectionHeadline: 'Aktivitäten',
    },
    incomingRelations: {
      sectionHeadline: 'Verbunden',
      records: 'Einträge',
      record: 'Eintrag',
    },
    blabItem: {
      follow: 'Folgen',
      following: 'Folge ich',
    },
    commentInput: {
      attachmentInvalidSizeToast: 'Eine oder mehrere Dateien überschreiten das Limit von 100 MB.',
      placeholder: 'Kommentar schreiben',
      sendBtnLabel: 'Senden',
    },
    flimVal: {
      relation: {
        blabItemBreadcrumb: '{{ itemName }} in {{ workspaceName }}',
      },
    },
    networkExceptionPlaceholder: {
      title: 'Eintrag kann im Moment nicht geladen werden',
      subtitle: 'Bitte versuche es später erneut.',
    },
    notAvailablePlaceholder: {
      title: 'Inhalt ist in {{activeUserOrganizationName}} nicht verfügbar',
      subtitle:
        'Du bist aktuell mit {{activeUserEmail}} angemeldet. Möglicherweise musst du dich mit einer anderen E-Mail-Adresse anmelden oder den Workspace-Admin kontaktieren, um Zugriff zu erhalten.',
    },
    activityOptionsBottomSheet: {
      cancelBtnLabel: 'Abbrechen',
      copyTextBtnLabel: 'Text kopieren',
      deleteCommentBtnLabel: 'Kommentar löschen',
      replyInThreadBtnLabel: 'In Thread antworten ',
    },
    confirmDeleteBlabItemCommentDialog: {
      title: 'Kommentar löschen?',
      message:
        'Dies kann nicht rückgängig gemacht werden und Antworten auf diesen Kommentar werden ebenfalls entfernt.',
      cancelButtonTitle: 'Abbrechen',
      okButtonTitle: 'Löschen',
    },
  },
  [Language.EN]: {
    blabItemActivity: {
      sectionHeadline: 'Activity',
    },
    incomingRelations: {
      sectionHeadline: 'Related',
      records: 'Records',
      record: 'Record',
    },
    blabItem: {
      follow: 'Follow',
      following: 'Following',
    },
    commentInput: {
      attachmentInvalidSizeToast: 'One or more files exceed the 100 MB limit.',
      placeholder: 'Start a comment',
      sendBtnLabel: 'Send',
    },
    flimVal: {
      relation: {
        blabItemBreadcrumb: '{{ itemName }} in {{ workspaceName }}',
      },
    },
    networkExceptionPlaceholder: {
      title: 'Record isn’t loading right now',
      subtitle: 'Please try again later.',
    },
    notAvailablePlaceholder: {
      title: 'Content is not available in {{activeUserOrganizationName}}',
      subtitle:
        'You’re currently signed in as {{activeUserEmail}}. You might need to sign in with a different email, or contact the workspace admin to gain access.',
    },
    activityOptionsBottomSheet: {
      cancelBtnLabel: 'Cancel',
      copyTextBtnLabel: 'Copy Text',
      deleteCommentBtnLabel: 'Delete Comment',
      replyInThreadBtnLabel: 'Reply in Thread',
    },
    confirmDeleteBlabItemCommentDialog: {
      title: 'Delete comment?',
      message:
        'Are you sure you want to delete this comment? This can’t be undone and replies to this comment will also be removed.',
      cancelButtonTitle: 'Cancel',
      okButtonTitle: 'Delete',
    },
  },
} as const;

export const BlabItemDisplayI18n = new I18nTranslation<typeof languages.EN>(languages);
