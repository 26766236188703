import gql from 'graphql-tag';
import { ConvertSingleUserFlimToMultiUserFlimResultDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../../flim-def';

export const ConvertSingleUserFlimToMultiUserFlimResultDtoFragment = gql`
  fragment ConvertSingleUserFlimToMultiUserFlimResultDtoFragment on ConvertSingleUserFlimToMultiUserFlimResultDto {
    ...ConvertSingleUserFlimToMultiUserFlimResultDtoNoNesting

    flimDef {
      ...FullFlimDef
    }
  }
  ${ConvertSingleUserFlimToMultiUserFlimResultDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
