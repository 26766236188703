import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ServiceStatusBarModule } from '@t5s/mobile-client/service/status-bar';
import { BottomSheetController } from './bottom-sheet.controller';

@NgModule({
  imports: [EffectsModule.forFeature([]), ServiceStatusBarModule],
  providers: [BottomSheetController],
})
export class BottomSheetBusinessLogicModule {}
