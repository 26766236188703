import gql from 'graphql-tag';
import { UserActivityReplyDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { ReactionDtoFragment } from '../core';
import { UserProfilePreviewDtoFragment } from '../user-profile';

export const UserActivityReplyDtoFragment = gql`
  fragment UserActivityReplyDtoFragment on UserActivityReplyDto {
    ...UserActivityReplyDtoNoNesting
    user {
      ...UserProfilePreviewDtoFragment
    }
    reactions {
      ...ReactionDtoFragment
    }
  }
  ${UserActivityReplyDtoNoNestingFragment}
  ${UserProfilePreviewDtoFragment}
  ${ReactionDtoFragment}
`;
