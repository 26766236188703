import gql from 'graphql-tag';
import { BlabItemNotificationDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { BlabItemNotificationActivityDtoFragmentV2 } from './blab-item-notification-activity-dto.fragment';

export const BlabItemNotificationDtoFragmentV2 = gql`
  fragment BlabItemNotificationDtoFragmentV2 on BlabItemNotificationDto {
    ...BlabItemNotificationDtoNoNesting
    blabItem {
      id
      title
      blabDef {
        id
        blabName
        itemName
        icon
        iconv2
        itemIcon
        workspace {
          id
          name
        }
      }
    }
    activities(first: 1) {
      ...BlabItemNotificationActivityDtoFragmentV2
    }
  }
  ${BlabItemNotificationDtoNoNestingFragment}
  ${BlabItemNotificationActivityDtoFragmentV2}
`;
