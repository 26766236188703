import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { EmojiPickerPosition, FrequentlyUsedEmoji } from '@t5s/mobile-client/value-object/emoji-picker';
import {
  DEFAULT_FREQUENTLY_USED_EMOJI_IDS,
  MAX_NUM_FREQUENTLY_USED_EMOJIS,
  MAX_NUM_PERSISTED_FREQUENTLY_USED_EMOJIS,
} from '@t5s/mobile-client/readonly-constant/emoji-picker';
import { EmojiPickerState } from './emoij-picker.state';
import { EmojiPickerActions } from './emoji-picker.actions';

export const emojiPickerStateKey = 'emojiPicker';

const adapter = createEntityAdapter<FrequentlyUsedEmoji>({
  selectId: (entity) => entity.id,
  sortComparer: (entity1, entity2) => entity2.timesUsed - entity1.timesUsed,
});

export const preprocessStateForSerialization = (state: EmojiPickerState) => {
  const emojis = selectAll(state).slice(0, MAX_NUM_PERSISTED_FREQUENTLY_USED_EMOJIS);
  state = adapter.setAll(emojis, state);
  return state;
};

export const { selectAll, selectEntities, selectIds } = adapter.getSelectors();

const initialState: EmojiPickerState = adapter.getInitialState({
  position: EmojiPickerPosition.IS_BOTTOM,
  searchQuery: '',
  searchMode: false,
  searchResults: [],
});

export const emojiPickerReducer = createReducer(
  initialState,

  // State hydration
  on(EmojiPickerActions.hydrateStateNotAvailable, (state) => {
    return adapter.addMany(
      DEFAULT_FREQUENTLY_USED_EMOJI_IDS.map((id) => ({ id, timesUsed: 0 })),
      state,
    );
  }),
  on(EmojiPickerActions.hydrateStateSuccess, (state, { state: hydratedState }) => {
    const { entities, ids } = hydratedState;
    if (ids.length === 0) {
      return adapter.addMany(
        DEFAULT_FREQUENTLY_USED_EMOJI_IDS.map((id) => ({ id, timesUsed: 0 })),
        { ...state, hydrated: true },
      );
    }
    return { ...state, entities, ids, hydrated: true };
  }),

  on(EmojiPickerActions.setPosition, (state, { position }) => {
    if (position === EmojiPickerPosition.IS_BOTTOM) {
      return { ...state, position, searchQuery: '', searchMode: false };
    }
    return { ...state, position };
  }),
  on(EmojiPickerActions.picked, (state, { id }) => {
    const entities = selectEntities(state);
    const entitiyIds = selectIds(state) as string[];
    const frequentlyUsed = entities[id];

    // Emoji not part of frequently used yet
    if (!frequentlyUsed) {
      let removedState;
      if (entitiyIds.length >= MAX_NUM_FREQUENTLY_USED_EMOJIS) {
        // Max num emojis reached, remove the last ones
        removedState = adapter.removeMany(entitiyIds.slice(MAX_NUM_FREQUENTLY_USED_EMOJIS), state);
      }

      return adapter.addOne(
        {
          id,
          timesUsed: 1,
        },
        removedState ?? state,
      );
    }

    return adapter.upsertOne(
      {
        id,
        timesUsed: frequentlyUsed.timesUsed + 1,
      },
      state,
    );
  }),

  // Search
  on(EmojiPickerActions.setSearchQuery, (state, { searchQuery }) => ({ ...state, searchQuery })),
  on(EmojiPickerActions.enterSearchMode, (state) => ({ ...state, searchMode: true })),
  on(EmojiPickerActions.leaveSearchMode, (state) => ({ ...state, searchMode: false, searchQuery: '' })),
  on(EmojiPickerActions.searchEmojisSuccess, (state, { searchResults }) => ({ ...state, searchResults })),
);
