import { I18nTranslation } from '@t5s/mobile-client/i18n/common';
import { Language } from '@t5s/shared/gql';

const languages = {
  [Language.DE]: {
    menu: {
      camera: 'Kamera',
      photo: 'Gallerie',
      document: 'Dokument',
    },
    permissionDeniedDialog: {
      camera: {
        title: '„Tape“ Kamerazugriff geben',
        message:
          'Bitte ändere dies in den Einstellungen deines Geräts. Nach dem Schieberegler mit der Bezeichnung „Kamera“ suchen.',
        cancelButtonTitle: 'Abbrechen',
        okButtonTitle: 'Gehe zu Einstellungen',
      },
      photo: {
        title: '„Tape“ Zugriff auf Fotos geben',
        message:
          'Bitte ändere dies in den Einstellungen deines Geräts. Nach dem Schieberegler mit der Bezeichnung „Fotos“ suchen.',
        cancelButtonTitle: 'Abbrechen',
        okButtonTitle: 'Gehe zu Einstellungen',
      },
    },
  },
  [Language.EN]: {
    menu: {
      camera: 'Camera',
      photo: 'Gallery',
      document: 'Document',
    },
    permissionDeniedDialog: {
      camera: {
        title: 'Give “Tape” camera access',
        message: 'You’ll need to change this in your device’s settings. Look for the toggle labeled “Camera.”',
        cancelButtonTitle: 'Cancel',
        okButtonTitle: 'Go to Settings',
      },
      photo: {
        title: 'Give “Tape” access to photos',
        message: 'You’ll need to change this in your device’s settings. Look for the toggle labeled “Photos.”',
        cancelButtonTitle: 'Cancel',
        okButtonTitle: 'Go to Settings',
      },
    },
  },
} as const;

export const DeviceMediaI18n = new I18nTranslation<typeof languages.EN>(languages);
