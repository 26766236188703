import { NgModule } from '@angular/core';
import {
  SubscriptionEndTriggerObservable,
  SubscriptionStartTriggerObservable,
} from '@t5s/mobile-client/provider-token/subscription-trigger';
import { ApplicationStateService, ServiceApplicationStateModule } from '@t5s/mobile-client/service/application-state';
import { debounceTime, filter, mapTo } from 'rxjs/operators';

@NgModule({
  imports: [ServiceApplicationStateModule],
  providers: [
    {
      provide: SubscriptionStartTriggerObservable,
      useFactory: ({ appState$ }: ApplicationStateService): SubscriptionStartTriggerObservable =>
        appState$.pipe(
          filter(({ isActive }) => isActive === true),
          // all subscriptions should be reestablished immediately once the app is in foreground again
          mapTo(true),
        ),
      deps: [ApplicationStateService],
    },
    {
      provide: SubscriptionEndTriggerObservable,
      useFactory: ({ appState$ }: ApplicationStateService): SubscriptionEndTriggerObservable =>
        appState$.pipe(
          filter(({ isActive }) => isActive === false),
          // 5 seconds after the app has been minimized all subs should be closed to save battery
          debounceTime(1000),
          mapTo(false),
        ),
      deps: [ApplicationStateService],
    },
  ],
})
export class SubscriptionTriggerProviderModule {}
