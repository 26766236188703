import gql from 'graphql-tag';
import {
  FlimValAttachmentDtoNoNestingFragment,
  MultiAttachmentFlimValDtoNoNestingFragment,
  MultiAttachmentFlimValFileReferenceDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
} from '../../../../generated/graphql-fragments';

export const MultiAttachmentFlimValFragment = gql`
  fragment BlabItemsOverviewMultiAttachmentFlimVal on MultiAttachmentFlimValDto {
    ...MultiAttachmentFlimValDtoNoNesting
    attachmentReferences {
      ...MultiAttachmentFlimValFileReferenceDtoNoNesting
      attachment {
        ...FlimValAttachmentDtoNoNesting
        thumbnail {
          ...ThumbnailDtoNoNesting
        }
      }
    }
  }
  ${MultiAttachmentFlimValDtoNoNestingFragment}
  ${MultiAttachmentFlimValFileReferenceDtoNoNestingFragment}
  ${FlimValAttachmentDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
