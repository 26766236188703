import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { DEFAULT_TAB, ROUTE_FRAGMENT_LOGGED_IN } from '@t5s/mobile-client/readonly-constant/logged-in';
import { filter, map } from 'rxjs/operators';
import { selectHydratedActiveOutlet } from './logged-in-root.selectors';
import { getOutletRouteFragment } from './logged-in-root.util';

const DEFAULT_REDIRECT_URL = `${ROUTE_FRAGMENT_LOGGED_IN}/${getOutletRouteFragment(DEFAULT_TAB)}`;

@Injectable()
export class LoggedInRootRedirectGuard implements CanActivate {
  constructor(private readonly store$: Store, private readonly router: Router) {}

  canActivate() {
    return this.store$.select(selectHydratedActiveOutlet).pipe(
      // will not be undefined after hydration process succeeded or failed
      filter((hydratedTab) => hydratedTab !== undefined),
      map((hydratedTab) => {
        if (!hydratedTab || hydratedTab === 'none') {
          return this.router.parseUrl(DEFAULT_REDIRECT_URL);
        }

        const tabFragment = getOutletRouteFragment(hydratedTab);
        const url = `${ROUTE_FRAGMENT_LOGGED_IN}/${tabFragment}`;

        return this.router.parseUrl(url);
      }),
    );
  }
}
