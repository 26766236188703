import gql from 'graphql-tag';
import {
  BlabDefPreviewDtoNoNestingFragment,
  WorkflowDefPreviewDtoNoNestingFragment,
  WorkflowPreviewRunActionContextOutputLogEventNoNestingFragment,
  WorkflowRunActionDebugLogEventNoNestingFragment,
  WorkflowRunActionEmailSendAttemptLogEventNoNestingFragment,
  WorkflowRunActionExceptionCaughtLogEventNoNestingFragment,
  WorkflowRunActionExecutionSyntaxErrorEventNoNestingFragment,
  WorkflowRunActionFailedLogEventNoNestingFragment,
  WorkflowRunActionSkippingLogEventNoNestingFragment,
  WorkflowRunActionsSetupFailedLogEventNoNestingFragment,
  WorkflowRunActionStartedLogEventNoNestingFragment,
  WorkflowRunActionSucceededLogEventNoNestingFragment,
  WorkflowRunActionUserLogEventNoNestingFragment,
  WorkflowRunBlabItemCommentCreateActionSucceededLogEventNoNestingFragment,
  WorkflowRunBlabItemCreateActionSucceededLogEventNoNestingFragment,
  WorkflowRunCollectBlabItemsActionSuceededLogEventNoNestingFragment,
  WorkflowRunCollectedBlabItemsCallWorkfowActionLogEventNoNestingFragment,
  WorkflowRunCollectedRecordsCommentCreateSucceededLogEventNoNestingFragment,
  WorkflowRunCollectedRecordsUpdateActionUpdateSucceededLogEventNoNestingFragment,
  WorkflowRunConditionalActionLogEventNoNestingFragment,
  WorkflowRunCreatePdfActionSucceededLogEventNoNestingFragment,
  WorkflowRunCurrentBlabItemCallWorkfowActionLogEventNoNestingFragment,
  WorkflowRunFiltersExecutionFailedLogEventNoNestingFragment,
  WorkflowRunFiltersSetupFailedLogEventNoNestingFragment,
  WorkflowRunFilterUserLogEventNoNestingFragment,
  WorkflowRunForLoopActionBreakExceptionLogEventNoNestingFragment,
  WorkflowRunForLoopActionBreakResultLogEventNoNestingFragment,
  WorkflowRunForLoopActionContinueExceptionLogEventNoNestingFragment,
  WorkflowRunForLoopActionContinueResultLogEventNoNestingFragment,
  WorkflowRunGenerateWeblinkActionSucceededLogEventNoNestingFragment,
  WorkflowRunHttpCallActionSucceededLogEventNoNestingFragment,
  WorkflowRunReferencedRecordsUpdateActionUpdateSucceededLogEventNoNestingFragment,
  WorkspacePreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const WorkflowRunLogEventUnionFragment = gql`
  fragment WorkflowRunLogEventUnionFragment on WorkflowRunLogEventUnion {
    ... on WorkflowRunLogEvent {
      type
      loggedAt
    }

    ... on WorkflowRunActionStartedLogEvent {
      ...WorkflowRunActionStartedLogEventNoNesting
    }

    ... on WorkflowRunActionUserLogEvent {
      ...WorkflowRunActionUserLogEventNoNesting
    }

    ... on WorkflowRunActionExecutionSyntaxErrorEvent {
      ...WorkflowRunActionExecutionSyntaxErrorEventNoNesting
    }

    ... on WorkflowRunActionDebugLogEvent {
      ...WorkflowRunActionDebugLogEventNoNesting
    }

    ... on WorkflowRunActionExceptionCaughtLogEvent {
      ...WorkflowRunActionExceptionCaughtLogEventNoNesting
    }

    ... on WorkflowRunActionFailedLogEvent {
      ...WorkflowRunActionFailedLogEventNoNesting
    }

    ... on WorkflowRunActionSucceededLogEvent {
      ...WorkflowRunActionSucceededLogEventNoNesting
    }

    ... on WorkflowRunActionSkippingLogEvent {
      ...WorkflowRunActionSkippingLogEventNoNesting
    }

    ... on WorkflowRunActionsSetupFailedLogEvent {
      ...WorkflowRunActionsSetupFailedLogEventNoNesting
    }

    ... on WorkflowRunFiltersExecutionFailedLogEvent {
      ...WorkflowRunFiltersExecutionFailedLogEventNoNesting
    }

    ... on WorkflowRunFiltersSetupFailedLogEvent {
      ...WorkflowRunFiltersSetupFailedLogEventNoNesting
    }

    ... on WorkflowRunFilterUserLogEvent {
      ...WorkflowRunFilterUserLogEventNoNesting
    }

    ... on WorkflowRunBlabItemCreateActionSucceededLogEvent {
      ...WorkflowRunBlabItemCreateActionSucceededLogEventNoNesting
    }

    ... on WorkflowRunBlabItemCommentCreateActionSucceededLogEvent {
      ...WorkflowRunBlabItemCommentCreateActionSucceededLogEventNoNesting
    }

    ... on WorkflowRunHttpCallActionSucceededLogEvent {
      ...WorkflowRunHttpCallActionSucceededLogEventNoNesting
    }

    ... on WorkflowRunConditionalActionLogEvent {
      ...WorkflowRunConditionalActionLogEventNoNesting
    }

    ... on WorkflowRunForLoopActionBreakResultLogEvent {
      ...WorkflowRunForLoopActionBreakResultLogEventNoNesting
    }

    ... on WorkflowRunForLoopActionContinueResultLogEvent {
      ...WorkflowRunForLoopActionContinueResultLogEventNoNesting
    }

    ... on WorkflowRunForLoopActionBreakExceptionLogEvent {
      ...WorkflowRunForLoopActionBreakExceptionLogEventNoNesting
    }

    ... on WorkflowRunForLoopActionContinueExceptionLogEvent {
      ...WorkflowRunForLoopActionContinueExceptionLogEventNoNesting
    }

    ... on WorkflowRunCollectedRecordsUpdateActionUpdateSucceededLogEvent {
      ...WorkflowRunCollectedRecordsUpdateActionUpdateSucceededLogEventNoNesting
    }

    ... on WorkflowRunCollectedRecordsCommentCreateSucceededLogEvent {
      ...WorkflowRunCollectedRecordsCommentCreateSucceededLogEventNoNesting
    }

    ... on WorkflowRunReferencedRecordsUpdateActionUpdateSucceededLogEvent {
      ...WorkflowRunReferencedRecordsUpdateActionUpdateSucceededLogEventNoNesting
    }

    # PREVIEW RUNS
    ... on WorkflowPreviewRunActionContextOutputLogEvent {
      ...WorkflowPreviewRunActionContextOutputLogEventNoNesting
    }

    # WORKFLOW CALL
    ... on WorkflowRunCollectedBlabItemsCallWorkfowActionLogEvent {
      ...WorkflowRunCollectedBlabItemsCallWorkfowActionLogEventNoNesting
      calledWorkflowDef {
        ...WorkflowDefPreviewDtoNoNesting
        blabDef {
          ...BlabDefPreviewDtoNoNesting
          workspace {
            ...WorkspacePreviewDtoNoNesting
          }
        }
      }
    }

    ... on WorkflowRunCurrentBlabItemCallWorkfowActionLogEvent {
      ...WorkflowRunCurrentBlabItemCallWorkfowActionLogEventNoNesting
      calledWorkflowDef {
        ...WorkflowDefPreviewDtoNoNesting
        blabDef {
          ...BlabDefPreviewDtoNoNesting
          workspace {
            ...WorkspacePreviewDtoNoNesting
          }
        }
      }
    }

    # SEND EMAIL
    ... on WorkflowRunActionEmailSendAttemptLogEvent {
      ...WorkflowRunActionEmailSendAttemptLogEventNoNesting
      to
    }

    # PDF
    ... on WorkflowRunCreatePdfActionSucceededLogEvent {
      ...WorkflowRunCreatePdfActionSucceededLogEventNoNesting
    }

    # WEBLINK
    ... on WorkflowRunGenerateWeblinkActionSucceededLogEvent {
      ...WorkflowRunGenerateWeblinkActionSucceededLogEventNoNesting
      calledWorkflowDef {
        ...WorkflowDefPreviewDtoNoNesting
        blabDef {
          ...BlabDefPreviewDtoNoNesting
          workspace {
            ...WorkspacePreviewDtoNoNesting
          }
        }
      }
    }

    # GENERIC COLLECT ACTIONS
    ... on WorkflowRunCollectBlabItemsActionSuceededLogEvent {
      ...WorkflowRunCollectBlabItemsActionSuceededLogEventNoNesting
    }
  }
  ${WorkflowRunActionStartedLogEventNoNestingFragment}
  ${WorkflowRunActionUserLogEventNoNestingFragment}
  ${WorkflowRunActionDebugLogEventNoNestingFragment}
  ${WorkflowRunActionExceptionCaughtLogEventNoNestingFragment}
  ${WorkflowRunActionExecutionSyntaxErrorEventNoNestingFragment}
  ${WorkflowRunActionFailedLogEventNoNestingFragment}
  ${WorkflowRunActionSucceededLogEventNoNestingFragment}
  ${WorkflowRunActionSkippingLogEventNoNestingFragment}
  ${WorkflowRunActionsSetupFailedLogEventNoNestingFragment}
  ${WorkflowRunFiltersExecutionFailedLogEventNoNestingFragment}
  ${WorkflowRunFiltersSetupFailedLogEventNoNestingFragment}
  ${WorkflowRunFilterUserLogEventNoNestingFragment}
  ${WorkflowRunBlabItemCreateActionSucceededLogEventNoNestingFragment}
  ${WorkflowRunBlabItemCommentCreateActionSucceededLogEventNoNestingFragment}
  ${WorkflowRunHttpCallActionSucceededLogEventNoNestingFragment}

  ${WorkflowRunCollectedRecordsUpdateActionUpdateSucceededLogEventNoNestingFragment}
  ${WorkflowRunReferencedRecordsUpdateActionUpdateSucceededLogEventNoNestingFragment}

  ${WorkflowRunCollectedRecordsCommentCreateSucceededLogEventNoNestingFragment}

  ${WorkflowRunConditionalActionLogEventNoNestingFragment}
  ${WorkflowRunForLoopActionBreakResultLogEventNoNestingFragment}
  ${WorkflowRunForLoopActionContinueResultLogEventNoNestingFragment}
  ${WorkflowRunForLoopActionBreakExceptionLogEventNoNestingFragment}
  ${WorkflowRunForLoopActionContinueExceptionLogEventNoNestingFragment}

  ${WorkflowPreviewRunActionContextOutputLogEventNoNestingFragment}

  ${WorkflowRunCurrentBlabItemCallWorkfowActionLogEventNoNestingFragment}
  ${WorkflowRunCollectedBlabItemsCallWorkfowActionLogEventNoNestingFragment}

  ${WorkflowDefPreviewDtoNoNestingFragment}
  ${BlabDefPreviewDtoNoNestingFragment}
  ${WorkspacePreviewDtoNoNestingFragment}

  ${WorkflowRunActionEmailSendAttemptLogEventNoNestingFragment}

  ${WorkflowRunCreatePdfActionSucceededLogEventNoNestingFragment}
  ${WorkflowRunGenerateWeblinkActionSucceededLogEventNoNestingFragment}
  ${WorkflowRunCollectBlabItemsActionSuceededLogEventNoNestingFragment}
`;
