import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  firstChild,
  getFontStyle,
  hairlineBorder,
  noSelect,
  nthChild,
  padding,
  rgba,
} from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent } from '@t5s/mobile-client/ui/component/common';
import { font, ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { Observable } from 'rxjs';

function* getIdSequence(): Generator<number, number, number> {
  let intialId = 1;
  while (true) {
    yield intialId++;
  }
}

const sequence = getIdSequence();

interface StubItemState {
  id: number;
}

@Component({
  selector: 't5s-stub-item',
  template: `
    <ng-container *ngIf="state$ | push as state">
      <div [class]="containerClass" [t5sTouchActive]="containerActiveClass">
        <!-- Title -->
        <div> Stub Item No. {{ state.id }} </div>

        <!-- Subtitle -->
        <div> This is a stub item. </div>
      </div>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StubItemComponent extends RxComponent<StubItemState> {
  constructor() {
    super();

    this.set({
      id: sequence.next().value,
    });
  }

  @Input() set id(id: number | Observable<number>) {
    this.setProperty('id', id);
  }

  readonly containerClass = tss({
    padding: padding(10, 16),
    borderTop: hairlineBorder(ThemeColorVar.dark),
    borderBottom: hairlineBorder(ThemeColorVar.dark),
    backgroundColor: ThemeColorVar.lightest,
    cursor: 'pointer',
    ...firstChild({
      ...noSelect,
      ...getFontStyle(font.medium16px),
      color: ThemeColorVar.darkest,
    }),
    ...nthChild(2, {
      ...noSelect,
      ...getFontStyle(font.regular16px),
      color: ThemeColorVar.darker,
    }),
  });

  readonly containerActiveClass = tss({
    backgroundColor: rgba(0, 0, 0, 0.07),
  });
}
