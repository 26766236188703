export const PaginationSize = {
  _5: 5,
  XSMALL: 10,
  _15: 15,
  SMALL: 15,
  _20: 20,
  _30: 30,
  _50: 50,
  SMEDIUM: 30,
  MEDIUM: 50,
  LARGE: 100,
  _100: 100,
  _500: 500,
  PSEUDO_INFINITE: 1000,
} as const;
