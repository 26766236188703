import gql from 'graphql-tag';
import { MultiLinkFlimValDtoNoNestingFragment } from '../../../../generated/graphql-fragments';
import { MultiLinkFlimValEntryFragment } from './multi-link-flim-val-entry.fragment';

export const MultiLinkFlimValFragment = gql`
  fragment BlabItemsOverviewMultiLinkFlimVal on MultiLinkFlimValDto {
    ...MultiLinkFlimValDtoNoNesting
    multiLinkEntries {
      ...BlabItemsOverviewMultiLinkFlimValEntryFragment
    }
  }
  ${MultiLinkFlimValDtoNoNestingFragment}
  ${MultiLinkFlimValEntryFragment}
`;
