import gql from 'graphql-tag';
import { AudioRoomDetailDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { AudioRoomAuthorFragment } from './audio-room-author.fragment';
import { AudioRoomDetailParticipantDtoFragment } from './audio-room-detail-participant.dto.fragment';

export const AudioRoomDetailDtoFragment = gql`
  fragment AudioRoomDetailDtoFragment on AudioRoomDetailDto {
    ...AudioRoomDetailDtoNoNesting
    participants {
      ...AudioRoomDetailParticipantDtoFragment
    }
    author {
      ...AudioRoomAuthorFragment
    }
  }
  ${AudioRoomDetailDtoNoNestingFragment}
  ${AudioRoomDetailParticipantDtoFragment}
  ${AudioRoomAuthorFragment}
`;
