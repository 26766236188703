import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  INSECURE_COOKIE_HEADER_NAME,
  REQUEST_INSECURE_COOKIE_HEADER_NAME,
} from '@t5s/shared/readonly-constant/authentication';
import { Observable } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { ActiveUserSessionActions } from './active-user-session.actions';
import { selectActiveUserSessionCookie } from './active-user-session.selectors';

@Injectable()
export class ActiveUserSessionCookieHttpInterceptor implements HttpInterceptor {
  constructor(private readonly store$: Store) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store$.select(selectActiveUserSessionCookie).pipe(
      first(),
      switchMap((cookie) =>
        next
          .handle(
            req.clone({
              headers: req.headers
                .append(REQUEST_INSECURE_COOKIE_HEADER_NAME, `1`)
                .append(INSECURE_COOKIE_HEADER_NAME, cookie ?? ''),
            }),
          )
          .pipe(
            tap((rsp) => {
              if (rsp instanceof HttpResponse) {
                const cookie = rsp.headers.get(INSECURE_COOKIE_HEADER_NAME);
                if (cookie) {
                  this.store$.dispatch(ActiveUserSessionActions.setActiveUserSessionCookie({ cookie }));
                }
              }
            }),
          ),
      ),
    );
  }
}
