import { NgModule } from '@angular/core';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { DividerComponent } from './divider.component';
import { HairlineDividerComponent } from './hairline-divider.component';

const components = [DividerComponent, HairlineDividerComponent];

@NgModule({
  imports: [ComponentCommonModule],
  declarations: components,
  exports: components,
})
export class DividerComponentModule {}
