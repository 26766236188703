import gql from 'graphql-tag';
import {
  BillingPriceDtoNoNestingFragment,
  OrganizationPlanDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const OrganizationPlanDtoFragment = gql`
  fragment OrganizationPlanDtoFragment on OrganizationPlanDto {
    ...OrganizationPlanDtoNoNesting
    prices {
      ...BillingPriceDtoNoNesting
    }
  }
  ${OrganizationPlanDtoNoNestingFragment}
  ${BillingPriceDtoNoNestingFragment}
`;
