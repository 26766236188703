function getMs(m: number) {
  return (n: number) => Math.round(n * m);
}

const seconds = getMs(1e3);
const minutes = getMs(6e4);
const hours = getMs(36e5);
const days = getMs(864e5);
const weeks = getMs(6048e5);
const months = getMs(26298e5);
const years = getMs(315576e5);

export const MsFromTime = {
  second: seconds(1),
  seconds,

  minute: minutes(1),
  minutes,

  hour: hours(1),
  hours,

  day: days(1),
  days,

  week: weeks(1),
  weeks,

  month: months(1),
  months,

  year: years(1),
  years,
};
