import gql from 'graphql-tag';
import { NewsfeedActivityDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { BlabItemActivityUnionTypeFragment } from '../blab-item-activity';
import { OrganizationActivityUnionTypeFragment } from '../organization-activity';
import { UserActivityUnionTypeFragment } from '../user-activity/user-activity-union-type.fragment';
import { WorkspaceActivityUnionTypeFragment } from '../workspace-activity/workspace-activity-union-type.fragment';

export const NewsfeedActivityFragment = gql`
  fragment NewsfeedActivity on NewsfeedActivityDto {
    ...NewsfeedActivityDtoNoNesting
    blabItemActivity {
      ...BlabItemActivityUnionTypeFragment
    }
    workspaceActivity {
      ...WorkspaceActivityUnionTypeFragment
    }
    organizationActivity {
      ...OrganizationActivityUnionTypeFragment
    }
    userActivity {
      ...UserActivityUnionTypeFragment
    }
  }
  ${BlabItemActivityUnionTypeFragment}
  ${WorkspaceActivityUnionTypeFragment}
  ${OrganizationActivityUnionTypeFragment}
  ${UserActivityUnionTypeFragment}
  ${NewsfeedActivityDtoNoNestingFragment}
`;
