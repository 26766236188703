import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class GlobalStyleService {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  setStyle(key: string, value: string | number) {
    this.document.body.style.setProperty(key, `${value}`);
  }

  setClass(className: string) {
    this.document.body.classList.add(className);
  }

  removeClass(className: string) {
    this.document.body.classList.remove(className);
  }
}
