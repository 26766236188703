import gql from 'graphql-tag';
import { ActiveUserMultiUserSplitBySectionDtoNoNestingFragment } from '../../../../generated/graphql-fragments';

export const ActiveUserMultiUserSplitBySectionDtoFragment = gql`
  fragment ActiveUserMultiUserSplitBySectionDto on ActiveUserMultiUserSplitBySectionDto {
    ...ActiveUserMultiUserSplitBySectionDtoNoNesting
    user {
      id
      primaryName
      profilePicture {
        id
        small
      }
    }
  }
  ${ActiveUserMultiUserSplitBySectionDtoNoNestingFragment}
`;
