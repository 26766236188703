import gql from 'graphql-tag';
import {
  BlabItemRevisionActivityDtoNoNestingFragment,
  ChecklistFlimValRevisionDtoNoNestingFragment,
  MultiAttachmentFlimValRevisionDtoNoNestingFragment,
  MultiUserFlimValRevisionDtoNoNestingFragment,
  SingleAttachmentFlimValRevisionDtoNoNestingFragment,
  SingleUserFlimValRevisionDtoNoNestingFragment,
  UserProfilePreviewDtoNoNestingFragment,
  FlimDefRevisionPreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { NotificationBlabItemActivityDtoFragmentV2 } from './blab-item-activity-dto.fragment';

const NotificationBlabItemRevisionFlimValRevisionDtoFragment = gql`
  fragment NotificationBlabItemRevisionFlimValRevisionDtoFragment on FlimValRevisionDto {
    id
    type
    flimType
    createdAt
    blabItemId
    flimDefId
    flimDef {
      ...FlimDefRevisionPreviewDtoNoNesting
    }
  }
  ${FlimDefRevisionPreviewDtoNoNestingFragment}
`;

export const NotificationBlabItemRevisionActivityFragmentV2 = gql`
  fragment NotificationBlabItemRevisionActivityFragmentV2 on BlabItemRevisionActivityDto {
    ...NotificationBlabItemActivityDtoFragmentV2
    ...BlabItemRevisionActivityDtoNoNesting
    revisions(first: 1) {
      ... on SingleAttachmentFlimValRevisionDto {
        ...NotificationBlabItemRevisionFlimValRevisionDtoFragment
        ...SingleAttachmentFlimValRevisionDtoNoNesting
        prevAttachment {
          id
          name
        }
        attachment {
          id
          name
        }
      }
      ... on MultiAttachmentFlimValRevisionDto {
        ...NotificationBlabItemRevisionFlimValRevisionDtoFragment
        ...MultiAttachmentFlimValRevisionDtoNoNesting
        prevAttachments {
          id
          name
        }
        attachments {
          id
          name
        }
      }
      ... on SingleUserFlimValRevisionDto {
        ...NotificationBlabItemRevisionFlimValRevisionDtoFragment
        ...SingleUserFlimValRevisionDtoNoNesting
        prevUser {
          id
          primaryName
        }
        user {
          id
          primaryName
        }
      }
      ... on MultiUserFlimValRevisionDto {
        ...NotificationBlabItemRevisionFlimValRevisionDtoFragment
        ...MultiUserFlimValRevisionDtoNoNesting
        prevUsers {
          id
          primaryName
        }
        users {
          id
          primaryName
        }
      }
      ... on ChecklistFlimValRevisionDto {
        ...NotificationBlabItemRevisionFlimValRevisionDtoFragment
        ...ChecklistFlimValRevisionDtoNoNesting
        prevChecklistEntry {
          id
          title
        }
        checklistEntry {
          id
          title
        }
        prevAssigneeUser {
          ...UserProfilePreviewDtoNoNesting
        }
        assigneeUser {
          ...UserProfilePreviewDtoNoNesting
        }
      }
    }
  }
  ${NotificationBlabItemActivityDtoFragmentV2}
  ${BlabItemRevisionActivityDtoNoNestingFragment},
  ${NotificationBlabItemRevisionFlimValRevisionDtoFragment}
  ${SingleAttachmentFlimValRevisionDtoNoNestingFragment}
  ${MultiAttachmentFlimValRevisionDtoNoNestingFragment}
  ${SingleUserFlimValRevisionDtoNoNestingFragment}
  ${MultiUserFlimValRevisionDtoNoNestingFragment}
  ${ChecklistFlimValRevisionDtoNoNestingFragment}
  ${UserProfilePreviewDtoNoNestingFragment}
`;
