import gql from 'graphql-tag';
import { ThumbnailDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const ActiveUserOrganizationDtoFragment = gql`
  fragment ActiveUserOrganizationDtoFragment on ActiveUserOrganizationDto {
    id
    slug
    name
    activityLimit
    activityCount
    plan
    numUsers
    logo {
      ...ThumbnailDtoNoNesting
    }
    disableTracking
    billingCustomerId
  }
  ${ThumbnailDtoNoNestingFragment}
`;
