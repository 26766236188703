import gql from 'graphql-tag';
import { UserGroupActivityDtoFragment } from '../user-group-activity/user-group-activity-dto.fragment';

export const UserGroupNotificationActivityDtoFragment = gql`
  fragment UserGroupNotificationActivityDtoFragment on UserGroupNotificationActivityDto {
    direct
    notificationId
    activity {
      ...UserGroupActivityDtoFragment
    }
  }
  ${UserGroupActivityDtoFragment}
`;
