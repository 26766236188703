import gql from 'graphql-tag';
import { ChecklistFlimValEntryDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { UserProfilePreviewDtoOmitOrgFragment } from '../../user-profile';
export const ChecklistFlimValEntryFragment = gql`
  fragment ChecklistFlimValEntryFragment on ChecklistFlimValEntryDto {
    ...ChecklistFlimValEntryDtoNoNesting
    assigneeUser {
      ...UserProfilePreviewDtoOmitOrgFragment
    }
  }
  ${ChecklistFlimValEntryDtoNoNestingFragment}
  ${UserProfilePreviewDtoOmitOrgFragment}
`;
