import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { AnimationController } from '@ionic/angular';
import { Animation } from '@ionic/core';
import {
  disabled,
  exactHeight,
  firstChild,
  flexCenterVertical,
  flexRow,
  fullHeight,
  fullWidth,
  getFontStyle,
  padding,
  placeholder,
  px,
  spread,
} from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { iconClearCircleIos, iconSearchRounded } from '@t5s/mobile-client/asset';
import { EmojiPickerI18n } from '@t5s/mobile-client/i18n/emoji-picker';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
import { I18nState, RxI18nComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { font, ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { EmojiPickerPosition } from '@t5s/mobile-client/value-object/emoji-picker';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

interface EmojiPickerSearchHeaderState extends I18nState {
  position: EmojiPickerPosition;

  searchQuery: string;
  searchMode: boolean;

  cancelBtnDiv: HTMLDivElement;
}

@Component({
  selector: 't5s-emoji-picker-search-header',
  template: `
    <div [class]="containerClass">
      <!-- Search bar -->
      <div [class]="searchBarClass">
        <!-- Input -->
        <input
          #searchInput
          autocapitalize="off"
          autocomplete="off"
          autocorrect="off"
          [ngModel]="searchQuery$ | push"
          [class]="searchInputClass"
          [disabled]="inputDisabled$ | push"
          [placeholder]="inputPlaceholder$ | push"
          (input)="emitSearchInputValueChange($event)"
          (focus)="searchInputFocused.emit()"
        />

        <!-- Clear icon -->
        <button [class]="clearBtnClass$ | push" (t5sPressDisableLongpress)="clearSearchClick.emit(); focusInput()">
          <t5s-icon [icon]="clearCircleIos" [size]="14" [bgColor]="Color.primary" [fgColor]="Color.danger"></t5s-icon>
        </button>

        <!-- Search icon -->
        <t5s-icon [icon]="searchRounded" [size]="20" [fgColor]="Color.darker" [class]="searchIconClass"></t5s-icon>
      </div>

      <!-- Cancel link -->
      <div #cancelBtn [class]="cancelBtnClass">
        <t5s-header-link
          [font]="Font.regular17px"
          [fgColor]="Color.primary"
          (linkClick)="cancelSearchClick.emit(); blurInput()"
        >
          {{ cancelLabel$ | push }}
        </t5s-header-link>
      </div>
    </div>

    <t5s-hairline-divider></t5s-hairline-divider>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiPickerSearchHeaderComponent
  extends RxI18nComponent<EmojiPickerSearchHeaderState>
  implements AfterViewInit, OnDestroy
{
  readonly I18n = EmojiPickerI18n;
  readonly searchRounded = iconSearchRounded;
  readonly clearCircleIos = iconClearCircleIos;

  private animation?: Animation;

  constructor(i18n: I18nObjectObservable, private readonly animationCtrl: AnimationController) {
    super(i18n);
    this.set({ searchQuery: '' });

    this.hold(this.select(selectSlice(['searchMode', 'cancelBtnDiv'])), ({ cancelBtnDiv, searchMode }) => {
      const width = (this.cancelBtnRef?.nativeElement.children[0].clientWidth ?? 50) + 16 + 4;
      const currentWidth = searchMode ? 0 : width;
      const targetWidth = searchMode ? width : 0;

      this.animation?.destroy();
      this.animation = this.animationCtrl
        .create('stub')
        .addElement(cancelBtnDiv)
        .duration(220)
        .keyframes([
          {
            offset: 0,
            minWidth: px(currentWidth),
          },
          {
            offset: 1,
            minWidth: px(targetWidth),
          },
        ]);

      void this.animation.progressStart().play();
    });
  }

  @ViewChild('searchInput') searchInput?: ElementRef<HTMLInputElement>;
  @ViewChild('cancelBtn') cancelBtnRef?: ElementRef<HTMLDivElement>;

  @Input() set position(position: EmojiPickerPosition | Observable<EmojiPickerPosition>) {
    this.setProperty('position', position);
  }

  @Input() set searchQuery(searchQuery: string | Observable<string>) {
    this.setProperty('searchQuery', searchQuery);
  }

  @Input() set searchMode(searchMode: boolean | Observable<boolean>) {
    this.setProperty('searchMode', searchMode);
  }

  @Output() cancelSearchClick = new EventEmitter<never>();
  @Output() clearSearchClick = new EventEmitter<never>();
  @Output() searchInputFocused = new EventEmitter<never>();
  @Output() searchQueryChange = new EventEmitter<{ searchQuery: string }>();

  readonly searchQuery$ = this.select(
    selectSlice(['searchQuery']),
    map(({ searchQuery }) => searchQuery),
  );

  readonly inputDisabled$ = this.select(
    selectSlice(['position']),
    map(({ position }) =>
      [EmojiPickerPosition.WILL_BE_BOTTOM, EmojiPickerPosition.WILL_BE_DOCKED, EmojiPickerPosition.IS_BOTTOM].includes(
        position,
      ),
    ),
  );

  readonly inputPlaceholder$ = this.i18n$.pipe(
    map((i18n) => this.I18n.translate(i18n, this.I18n.key.search.inputPlaceholder)),
  );

  readonly cancelLabel$ = this.i18n$.pipe(map((i18n) => this.I18n.translate(i18n, this.I18n.key.search.cancelLabel)));

  readonly containerClass = tss({
    ...spread,
    ...flexRow,
    ...flexCenterVertical,
    padding: padding(6, 16, 12, 16),
  });

  readonly searchBarClass = tss({
    flex: 1,
    minWidth: px(0),
    ...flexRow,
    overflow: 'hidden',
    position: 'relative',
    borderRadius: px(16),
    backgroundColor: ThemeColorVar.light,
    ...exactHeight(32),
  });

  readonly cancelBtnClass = tss({
    flex: 0,
    overflow: 'hidden',
    position: 'relative',
    ...fullHeight,
    ...firstChild({
      position: 'absolute',
      left: px(16),
      top: px(6.5),
    }),
  });

  readonly searchIconClass = tss({
    position: 'absolute',
    pointerEvents: 'none',
    left: px(6),
    top: px(6),
  });

  readonly clearBtnClass$ = this.select(
    selectSlice(['searchQuery']),
    map(({ searchQuery }) => searchQuery.trim().length > 0),
    distinctUntilChanged(),
    map((hasSearchQuery) =>
      tss({
        flex: 0,
        padding: padding(9, 9, 9, 6),
        visibility: hasSearchQuery ? 'visible' : 'hidden',
      }),
    ),
  );

  readonly searchInputClass = tss({
    flex: 1,
    minWidth: px(0),
    ...fullWidth,
    ...getFontStyle(font.regular16px),
    padding: padding(8, 0, 5, 29),
    backgroundColor: 'transparent',
    color: ThemeColorVar.darkest,
    ...placeholder({
      ...getFontStyle(font.regular16px),
      color: ThemeColorVar.darker,
      opacity: 1,
      ...disabled({
        color: ThemeColorVar.darker,
        opacity: 1,
      }),
    }),
    ...disabled({
      color: ThemeColorVar.darker,
      opacity: 1,
    }),
  });

  focusInput() {
    this.searchInput?.nativeElement.focus();
    setTimeout(() => this.searchInput?.nativeElement.focus(), 0);
    setTimeout(() => this.searchInput?.nativeElement.focus(), 100);
  }

  blurInput() {
    this.searchInput?.nativeElement.blur();
  }

  emitSearchInputValueChange(event: Event) {
    const target = event.target as HTMLInputElement | undefined;
    const value = target?.value ?? '';

    if (value.trim().length === 0 && this.searchInput) {
      this.searchInput.nativeElement.value = '';
    }

    this.searchQueryChange.emit({ searchQuery: value.trim().length === 0 ? '' : value });
  }

  ngAfterViewInit() {
    const cancelBtnDiv = this.cancelBtnRef?.nativeElement;
    if (cancelBtnDiv) {
      this.set({ cancelBtnDiv });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.animation?.destroy();
  }
}
