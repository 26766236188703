import gql from 'graphql-tag';
import {
  ChatVideoCallActivityDtoNoNestingFragment,
  MicrosoftUserAuthenticationRequiredDtoNoNestingFragment,
  VideoCallDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const StartChatVideoCallResultFragment = gql`
  fragment StartChatVideoCallResultFragment on StartChatVideoCallResult {
    ... on ChatVideoCallActivityDto {
      ...ChatVideoCallActivityDtoNoNesting
      videoCall {
        ...VideoCallDtoNoNesting
      }
    }
    ... on MicrosoftUserAuthenticationRequiredDto {
      ...MicrosoftUserAuthenticationRequiredDtoNoNesting
    }
  }
  ${ChatVideoCallActivityDtoNoNestingFragment}
  ${VideoCallDtoNoNestingFragment}
  ${MicrosoftUserAuthenticationRequiredDtoNoNestingFragment}
`;
