import gql from 'graphql-tag';
import { MultiCategorySplitByDtoFragment } from './category/multi-category-split-by.fragment';
import { SingleCategorySplitByDtoFragment } from './category/single-category-split-by.fragment';
import { MultiRelationSplitByDtoFragment } from './relation/multi-relation-split-by.fragment';
import { SingleRelationSplitByDtoFragment } from './relation/single-relation-split-by.fragment';
import { StatusSplitByDtoFragment } from './status/status-split-by.fragment';
import { MultiUserSplitByDtoFragment } from './user/multi-user-split-by.fragment';
import { SingleUserSplitByDtoFragment } from './user/single-user-split-by.fragment';
import { SingleDateSplitByDtoFragment } from './date/single-date-split-by.fragment';
import { RangeDateSplitByDtoFragment } from './date/range-date-split-by.fragment';
import { CalculationSplitByDtoFragment } from './calculation/calculation-split-by.fragment';
import { CreatedAtSplitByDtoFragment } from './created/created-at-split-by.fragment';
import { LastModifiedAtSplitByDtoFragment } from './modified/last-modified-at-split-by.fragment';

export const SplitByUnionTypeFragment = gql`
  fragment SplitByUnionType on SplitByUnionType {
    ... on SingleCategorySplitByDto {
      ...SingleCategorySplitByDto
    }

    ... on MultiCategorySplitByDto {
      ...MultiCategorySplitByDto
    }

    ... on SingleUserSplitByDto {
      ...SingleUserSplitByDto
    }

    ... on MultiUserSplitByDto {
      ...MultiUserSplitByDto
    }

    ... on SingleRelationSplitByDto {
      ...SingleRelationSplitByDto
    }

    ... on MultiRelationSplitByDto {
      ...MultiRelationSplitByDto
    }

    ... on StatusSplitByDto {
      ...StatusSplitByDto
    }

    ... on SingleDateSplitByDto {
      ...SingleDateSplitByDto
    }

    ... on RangeDateSplitByDto {
      ...RangeDateSplitByDto
    }

    ... on CalculationSplitByDto {
      ...CalculationSplitByDto
    }

    ... on CreatedAtSplitByDto {
      ...CreatedAtSplitByDto
    }

    ... on LastModifiedAtSplitByDto {
      ...LastModifiedAtSplitByDto
    }
  }
  ${SingleCategorySplitByDtoFragment}
  ${MultiCategorySplitByDtoFragment}
  ${SingleUserSplitByDtoFragment}
  ${MultiUserSplitByDtoFragment}
  ${SingleRelationSplitByDtoFragment}
  ${MultiRelationSplitByDtoFragment}
  ${StatusSplitByDtoFragment}
  ${SingleDateSplitByDtoFragment}
  ${RangeDateSplitByDtoFragment}
  ${CalculationSplitByDtoFragment}
  ${CreatedAtSplitByDtoFragment}
  ${LastModifiedAtSplitByDtoFragment}
`;
