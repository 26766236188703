import { InjectionToken } from '@angular/core';
import { PickerComponent } from '@ctrl/ngx-emoji-mart';
import { EmojiPickerI18n } from './i18n';

export const EMOJI_PICKER_CONFIG = new InjectionToken<EmojiPickerConfig>('Emoji picker config object');

export interface EmojiPickerConfig {
  i18n?: EmojiPickerI18n;
  color?: string;
  showPreview?: boolean;
  isNative: boolean;
  emojiSize: number;
  showSingleCategory?: boolean;
  perLine?: number;
  autoFocus?: boolean;
}

/** Assigns config values to a picker component instance (mutates component!). */
export function configureEmojiPicker(emojiPicker: PickerComponent, config: EmojiPickerConfig): void {
  const {
    color = '',
    showPreview = true,
    i18n,
    showSingleCategory = false,
    isNative = true,
    autoFocus = true,
    emojiSize,
  } = config;

  emojiPicker.showSingleCategory = showSingleCategory;
  emojiPicker.color = color;
  emojiPicker.showPreview = showPreview;
  emojiPicker.i18n = i18n;
  emojiPicker.isNative = isNative;
  emojiPicker.emojiSize = emojiSize;
  emojiPicker.autoFocus = autoFocus;
}
