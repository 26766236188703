import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  border,
  focus,
  focusWithin,
  fullWidth,
  getFontStyle,
  padding,
  placeholder,
  px,
} from '@t5s/client/ui/style/common';
import { UntilDestroy, untilDestroy } from '@t5s/client/util/component-until-destroy';
import { tss } from '@t5s/client/util/tss';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { font, FontSet, ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { combineLatest, Observable, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';

interface InputState {
  fgColor: string;
  placeholder: string;
  value: string;
  type: InputType;
  autofocus: boolean;
  font: FontSet;
}

type InputType = 'text' | 'password' | 'email';

@UntilDestroy()
@Component({
  selector: 't5s-input',
  template: `<input
    #input
    [class]="inputClass$ | push"
    [type]="type$ | push"
    [placeholder]="placeholder$ | push"
    autocomplete="current-password"
    [ngModel]="value$ | push"
    (input)="emitValueChange($event)"
    (change)="emitValueChange($event)"
    (keydown.enter)="emitEnterPressed($event)"
  />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputComponent extends RxComponent<InputState> implements OnInit, AfterViewInit {
  readonly placeholder$ = this.select(selectSlice(['placeholder'])).pipe(map(({ placeholder }) => placeholder));
  readonly value$ = this.select(selectSlice(['value'])).pipe(map(({ value }) => value));
  readonly type$ = this.select(selectSlice(['type'])).pipe(map(({ type }) => type));
  readonly autofocus$ = this.select(selectSlice(['autofocus'])).pipe(map(({ autofocus }) => autofocus));
  readonly input$$ = new Subject<HTMLInputElement>();

  constructor() {
    super();
    this.set({
      fgColor: ThemeColorVar.primary,
      placeholder: '',
      value: '',
      type: 'text',
      font: font.regular16px,
    });

    combineLatest([this.input$$.asObservable(), this.autofocus$.pipe(filterOnlyPresent())])
      .pipe(first(), untilDestroy(this))
      .subscribe(([input]) => {
        input.focus();
      });
  }

  @ViewChild('input') inputRef?: ElementRef<HTMLInputElement>;

  @Input() set fgColor(fgColor: ThemeColorVar | Observable<ThemeColorVar>) {
    this.setProperty('fgColor', fgColor);
  }

  @Input() set placeholder(placeholder: string | Observable<string>) {
    this.setProperty('placeholder', placeholder);
  }

  @Input() set value(value: string | Observable<string>) {
    this.setProperty('value', value);
  }

  @Input() set type(type: InputType | Observable<InputType>) {
    this.setProperty('type', type);
  }

  @Input() set autofocus(autofocus: boolean | Observable<boolean>) {
    this.setProperty('autofocus', autofocus);
  }

  @Input() set font(font: FontSet | Observable<FontSet>) {
    this.setProperty('font', font);
  }

  @Output() valueChange = new EventEmitter<{ value: string }>();
  @Output() enterPressed = new EventEmitter<{ value: string }>();

  readonly inputClass$ = this.state$.pipe(
    map(({ fgColor, font }) =>
      tss({
        ...fullWidth,
        caretColor: ThemeColorVar.primary,
        borderBottom: border(1, 'solid', ThemeColorVar.dark),
        borderRadius: px(0),
        padding: padding(12, 0),
        ...getFontStyle(font),
        ...focusWithin({
          borderColor: fgColor,
        }),
        ...placeholder({
          color: ThemeColorVar.gray9197A3,
        }),
        ...focus({
          ...placeholder({
            color: ThemeColorVar.grayBDC1C9,
          }),
        }),
      }),
    ),
  );

  focus() {
    this.inputRef?.nativeElement.focus();
  }

  blur() {
    this.inputRef?.nativeElement.blur();
  }

  emitValueChange(event: Event) {
    const target = event.target as HTMLInputElement | undefined;
    const value = target?.value ?? '';

    this.valueChange.emit({ value });
  }

  emitEnterPressed(event: Event) {
    const target = event.target as HTMLInputElement | undefined;
    this.enterPressed.emit({ value: target?.value ?? '' });
  }

  ngOnInit() {
    if (this.inputRef?.nativeElement) {
      this.input$$.next(this.inputRef.nativeElement);
    }
  }

  ngAfterViewInit() {
    if (this.inputRef?.nativeElement) {
      this.input$$.next(this.inputRef.nativeElement);
    }
  }
}
