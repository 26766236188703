import gql from 'graphql-tag';
import {
  SingleCategoryOptionDefDtoNoNestingFragment,
  StatusOptionDefDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const BoardColumnFragment = gql`
  fragment BoardColumnFragment on BlabViewBoardColumnDto {
    blabViewId
    flimDefId
    flimType
    position
    hidden
    ... on BlabViewBoardSingleCategoryColumnDto {
      singleCategoryOptionDefId
      singleCategoryOptionDef {
        ...SingleCategoryOptionDefDtoNoNesting
      }
    }
    ... on BlabViewBoardStatusColumnDto {
      statusOptionDefId
      statusOptionDef {
        ...StatusOptionDefDtoNoNesting
      }
    }
  }
  ${SingleCategoryOptionDefDtoNoNestingFragment}
  ${StatusOptionDefDtoNoNestingFragment}
`;
