import gql from 'graphql-tag';
import {
  BlabDefDeveloperInfoSubscriptionBlabDefWebhookEventDtoNoNestingFragment,
  BlabDefWebhookDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const BlabDefDeveloperInfoSubscriptionEventUnionTypeFragment = gql`
  fragment BlabDefDeveloperInfoSubscriptionEventUnionTypeFragment on BlabDefDeveloperInfoSubscriptionEventUnionType {
    ... on BlabDefDeveloperInfoSubscriptionBlabDefWebhookEventDto {
      ...BlabDefDeveloperInfoSubscriptionBlabDefWebhookEventDtoNoNesting
      blabDefWebhook {
        ...BlabDefWebhookDtoNoNesting
      }
    }
  }
  ${BlabDefDeveloperInfoSubscriptionBlabDefWebhookEventDtoNoNestingFragment}
  ${BlabDefWebhookDtoNoNestingFragment}
`;
