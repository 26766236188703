export const newsfeedEnLangClient = {
  blabDef: {
    breadcrumb: 'App in {{workspace}}',
  },
  blabItem: {
    breadcrumb: '{{blabDef}} in {{workspace}}',
  },
  blabItemActivity: {
    breadcrumb: '{{blabItem}} · {{blabDef}} in {{workspace}}',
  },
  loadMoreReplies: '{{numReplies}} more replies',
  loadMoreReply: '1 more reply',
  organization: {
    breadcrumb: 'Organization',
  },
  toast: {
    followBlabItemSuccess: 'You followed {{entityName}}',
    followUserSuccess: 'You followed {{entityName}}',
    unfollowBlabItemSuccess: 'You unfollowed {{entityName}} ',
    unfollowUserSuccess: 'You unfollowed {{entityName}}',
  },
  user: {
    breadcrumb: 'Member of {{organization}}',
  },
  workspace: {
    breadcrumb: 'Workspace in {{organization}}',
  },
} as const;
