import { Injectable } from '@angular/core';
import {
  ConfigureOptions,
  GetOptions,
  GetResult,
  KeysResult,
  Preferences,
  RemoveOptions,
  SetOptions,
} from '@capacitor/preferences';
import { deserializeValue, serializeValue, validateStorageKey } from '@t5s/mobile-client/util/storage';
import { defer, EMPTY, from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class StorageService {
  /**
   * Configure the storage plugin at runtime.
   * Options that are `undefined` will not be used.
   */
  configure(options: ConfigureOptions): Observable<void> {
    try {
      return defer(() => from(Preferences.configure(options))).pipe(catchError(() => EMPTY));
    } catch (e) {
      return EMPTY;
    }
  }

  /**
   * Get the value from storage of a given key.
   */
  get({ key }: GetOptions): Observable<GetResult> {
    validateStorageKey(key);

    try {
      return defer(() => from(Preferences.get({ key }))).pipe(
        map(({ value }) => ({ value: value ? deserializeValue(value) : value })),
        catchError(() => EMPTY),
      );
    } catch (e) {
      return EMPTY;
    }
  }

  /**
   * Set the value in storage for a given key.
   */
  set({ key, value }: SetOptions): Observable<void> {
    validateStorageKey(key);
    try {
      return defer(() => from(Preferences.set({ key, value: serializeValue(value) }))).pipe(catchError(() => EMPTY));
    } catch (e) {
      return EMPTY;
    }
  }

  /**
   * Remove the value from storage for a given key, if any.
   */
  remove({ key }: RemoveOptions): Observable<void> {
    validateStorageKey(key);

    return defer(() => from(Preferences.remove({ key }))).pipe(catchError(() => EMPTY));
  }

  /**
   * Clear keys and values from Preferences.
   */
  clear(): Observable<void> {
    try {
      return defer(() => from(Preferences.clear())).pipe(catchError(() => EMPTY));
    } catch (e) {
      return EMPTY;
    }
  }

  /**
   * Return the list of known keys in Preferences.
   */
  keys(): Observable<KeysResult> {
    try {
      return defer(() => from(Preferences.keys())).pipe(
        map(({ keys }) => ({ keys })),
        catchError(() => EMPTY),
      );
    } catch (e) {
      return of({ keys: [] });
    }
  }
}
