import { DateFormat, Language, TimeFormat } from '@t5s/shared/gql';
import { dateFnsLocales, formatDate as __formatDate } from '@t5s/shared/util/date';

function getDateLocale({
  dateFormat = DateFormat.SLASH_MM_DD_YYYY,
  timeFormat = TimeFormat.HOURS_12,
}: {
  dateFormat?: DateFormat;
  timeFormat?: TimeFormat;
}) {
  if (timeFormat === TimeFormat.HOURS_24) {
    return dateFnsLocales.de;
  }

  if (dateFormat === DateFormat.DOT_DD_MM_YYYY) {
    return dateFnsLocales.de;
  }

  return dateFnsLocales.enUS;
}

function getDateLocaleFromLanguage({ language }: { language: Language }) {
  if (language === Language.DE) {
    return dateFnsLocales.de;
  }

  return dateFnsLocales.enUS;
}

export function formatDate(date: Date, format: string, i18n: { dateFormat?: DateFormat; timeFormat?: TimeFormat }) {
  const _locale = getDateLocale(i18n);
  return __formatDate(date, format, { locale: _locale });
}

export function formatDateNoLocale(date: Date, format: string) {
  return __formatDate(date, format);
}

export function formatDateViaLanguage(date: Date, format: string, i18n: { language: Language }) {
  const _locale = getDateLocaleFromLanguage(i18n);
  return __formatDate(date, format, { locale: _locale });
}
