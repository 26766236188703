import { BlabItemActivityThreadStateModel } from '@t5s/mobile-client/value-object/blab-item-activity-thread';
import {
  BlabItemActivityReplyDto,
  BlabItemActivityReplySubscriptionUpdate,
  BlabItemActivitySubscriptionUpdate,
} from '@t5s/shared/gql';
import { TypeGuards } from '@t5s/shared/gql-type-guards/common';
import { replaceWhere } from '@t5s/shared/util/array';

/**
 *  Properly handles a reply subscription update and returns the mutated blab item activity thread state.
 */
export function applyBlabItemActivityThreadLiveUpdate(
  blabItemActivityThread: BlabItemActivityThreadStateModel,
  update: BlabItemActivityReplySubscriptionUpdate,
): BlabItemActivityThreadStateModel {
  const { activityReplies } = blabItemActivityThread;

  if (!activityReplies) {
    return blabItemActivityThread;
  }

  let { replies } = activityReplies;

  if (TypeGuards.isBlabItemActivityReplyDeletedDto(update)) {
    replies = replies.filter((reply) => reply.node.id !== update.id);
    return { ...blabItemActivityThread, activityReplies: { ...activityReplies, replies } };
  }

  const replyToUpsert = update as BlabItemActivityReplyDto;
  const replyEdgeToUpsert = { node: replyToUpsert, cursor: '' };
  if (replies.find((reply) => reply.node.id === replyToUpsert.id)) {
    replies = replaceWhere(replies, (reply) => reply.node.id === replyToUpsert.id, replyEdgeToUpsert);
  } else {
    replies = [...replies, replyEdgeToUpsert];
  }

  return { ...blabItemActivityThread, activityReplies: { ...activityReplies, replies } };
}

/**
 *  Properly handles a host activity subscription update and returns the mutated blab item activity thread state.
 */
export function applyBlabItemActivityThreadActivityLiveUpdate(
  blabItemActivityThread: BlabItemActivityThreadStateModel,
  update: BlabItemActivitySubscriptionUpdate,
): BlabItemActivityThreadStateModel {
  const { blabItemActivity } = blabItemActivityThread;

  if (TypeGuards.isBlabItemActivityDeletedDto(update)) {
    return blabItemActivityThread;
  }

  return { ...blabItemActivityThread, blabItemActivity: update };
}
