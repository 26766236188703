import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { ChatConversationPreviewValObj, ChatConversationsLoadingState } from '@t5s/mobile-client/value-object/chat';
import { ConversationDto } from '@t5s/shared/gql';
import { idDescSortComparer } from '@t5s/shared/util/sort';
import { ChatActions } from './chat.actions';
import { ChatState } from './chat.state';

export const chatStateKey = 'chat';

const selectId = (conversation: ConversationDto) => conversation.conversationId;
const sortComparer = (conversation1: ConversationDto, conversation2: ConversationDto) =>
  idDescSortComparer(conversation1.lastChatActivity ?? { id: 1 }, conversation2.lastChatActivity ?? { id: 1 });

const adapter = createEntityAdapter<ChatConversationPreviewValObj>({
  selectId,
  sortComparer,
});

export const { selectAll } = adapter.getSelectors();

const initialState: ChatState = adapter.getInitialState({ loadingState: ChatConversationsLoadingState.INITIAL });

export const chatReducer = createReducer(
  initialState,
  on(ChatActions.reset, () => ({ ...initialState })),

  on(ChatActions.hydrateStateSuccess, (state, { state: hydratedState }) => {
    const { entities, ids } = hydratedState;
    return { ...state, entities, ids };
  }),

  on(ChatActions.loadConversations, (state) => ({ ...state, loadingState: ChatConversationsLoadingState.LOADING })),
  on(ChatActions.loadConversationsException, (state) => ({
    ...state,
    loadingState: ChatConversationsLoadingState.EXCEPTION,
  })),
  on(ChatActions.loadConversationsSuccess, (state, { conversations }) => ({
    ...adapter.setAll(conversations, state),
    loadingState: ChatConversationsLoadingState.LOADED,
  })),

  on(ChatActions.conversationsUpdate, (state, { conversation }) => adapter.upsertOne(conversation, state)),
);
