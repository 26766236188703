import gql from 'graphql-tag';
import {
  BlabDefPreviewDtoNoNestingFragment,
  BlabItemPreviewDtoNoNestingFragment,
  WorkflowDefBlabDefLookupDtoNoNestingFragment,
  WorkflowDefLookupBlabDefRelationDtoNoNestingFragment,
  WorkflowDefLookupIncomingWorkflowDefPreviewDtoNoNestingFragment,
  WorkflowDefLookupWorkflowDefDtoNoNestingFragment,
  WorkflowDefWorkspaceLookupDtoNoNestingFragment,
  WorkflowDefLookupBlabViewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../flim-def/full-flim-def.fragment';
import { UserProfilePreviewDtoFragment } from '../user-profile/user-profile-preview-dto.fragment';
import { WorkflowDefLookupWorkspaceUserDtoFragment } from './workflow-def-lookup-workspace-user-dto.fragment';

export const WorkflowDefLookupDtoFragment = gql`
  fragment WorkflowDefLookupDtoFragment on WorkflowDefLookupDto {
    workflowDefId
    blabDefWithFlimDefsIds

    blabDefs {
      ...WorkflowDefBlabDefLookupDtoNoNesting
    }

    flimDefs {
      ...FullFlimDef
    }

    users {
      ...UserProfilePreviewDtoFragment
    }

    blabItems {
      ...BlabItemPreviewDtoNoNesting
      blabDef {
        ...BlabDefPreviewDtoNoNesting
        workspace {
          ...WorkspacePreviewDtoNoNesting
        }
      }
    }

    blabDefRelations {
      ...WorkflowDefLookupBlabDefRelationDtoNoNesting
    }

    workspaceUsers {
      ...WorkflowDefLookupWorkspaceUserDtoFragment
    }

    workspaces {
      ...WorkflowDefWorkspaceLookupDtoNoNesting
    }

    workflowDefs {
      ...WorkflowDefLookupWorkflowDefDtoNoNesting
    }

    incomingWorkflowDefs {
      ...WorkflowDefLookupIncomingWorkflowDefPreviewDtoNoNesting
      incomingTypes
      blabDef {
        ...BlabDefPreviewDtoNoNesting
      }
    }

    blabViews {
      ...WorkflowDefLookupBlabViewDtoNoNesting
    }
  }
  ${FullFlimDefFragment}
  ${UserProfilePreviewDtoFragment}
  ${BlabItemPreviewDtoNoNestingFragment}
  ${WorkflowDefLookupBlabDefRelationDtoNoNestingFragment}
  ${WorkflowDefLookupWorkflowDefDtoNoNestingFragment}

  ${WorkflowDefBlabDefLookupDtoNoNestingFragment}
  ${WorkflowDefLookupWorkspaceUserDtoFragment}
  ${BlabDefPreviewDtoNoNestingFragment}
  ${WorkflowDefWorkspaceLookupDtoNoNestingFragment}

  ${WorkflowDefLookupIncomingWorkflowDefPreviewDtoNoNestingFragment}
  ${WorkflowDefLookupBlabViewDtoNoNestingFragment}
`;
