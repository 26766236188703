import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@t5s/client/provider/window';

@Injectable()
export class ScreenOrientationService {
  constructor(@Inject(WINDOW) private readonly window: Window) {}

  lock(orientation: 'portrait') {
    if (this.window.screen?.orientation && typeof (this.window.screen?.orientation as any).lock === 'function') {
      try {
        (this.window.screen?.orientation as any).lock(orientation);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('An error occurred while locking screen orientation.');
      }
    }
  }

  unlock() {
    if (this.window.screen?.orientation && typeof (this.window.screen?.orientation as any).unlock === 'function') {
      try {
        return this.window.screen?.orientation.unlock();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('An error occurred while unlocking screen orientation.');
      }
    }
  }
}
