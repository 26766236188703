import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { KeyboardService } from '@t5s/mobile-client/service/keyboard';
import { tap } from 'rxjs/operators';
import { KeyboardActions } from './keyboard.actions';

@Injectable()
export class KeyboardEffects {
  constructor(private readonly actions$: Actions, private readonly keyboardService: KeyboardService) {}

  readonly hide$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(KeyboardActions.hide),
        tap(() => this.keyboardService.hide()),
      ),
    { dispatch: false },
  );
}
