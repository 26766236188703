import { RxDirective } from '@t5s/client/util/rx';
import { trackById, trackByPosition } from '@t5s/client/util/track-by';
import { font, ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';

export abstract class RxComponent<T extends object = {}> extends RxDirective<T> {
  readonly trackById = trackById;
  readonly trackByPosition = trackByPosition;
  readonly ThemeColorVar = ThemeColorVar;
  readonly Color = ThemeColorVar;
  readonly Font = font;
}
