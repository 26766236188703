import {
  BlabItemActivityConnection,
  BlabItemActivityCountsDto,
  BlabItemActivitySubscriptionUpdate,
  BlabItemActivityUnionType,
  GetBlabItemActivityCountsInput,
  GetBlabItemActivityInput,
  GetSingleBlabItemActivityInput,
} from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import {
  BlabItemActivityConnectionFragment,
  BlabItemActivitySubscriptionUpdateFragment,
  BlabItemActivityUnionTypeFragment,
} from '../../fragments/blab-item-activity';
import {
  getBlabItemActivity,
  getBlabItemActivityCounts,
  getSingleBlabItemActivity,
} from '../../generated/graphql-queries';
import { subscribeToBlabItemActivity } from '../../generated/graphql-subscriptions';
import { GqlService } from '../../gql-service';

export class GqlBlabItemActivityService extends GqlService {
  getSingleBlabItemActivity(input: GetSingleBlabItemActivityInput): Observable<BlabItemActivityUnionType> {
    return this.apollo.defaultQuery(getSingleBlabItemActivity(input, [BlabItemActivityUnionTypeFragment]));
  }

  getBlabItemActivityConnection(
    first: number,
    input: GetBlabItemActivityInput | undefined,
    after?: string,
  ): Observable<BlabItemActivityConnection> {
    return this.apollo.defaultQuery(getBlabItemActivity(first, input, after, [BlabItemActivityConnectionFragment]));
  }

  getBlabItemActivityCounts(input: GetBlabItemActivityCountsInput): Observable<BlabItemActivityCountsDto> {
    return this.apollo.defaultQuery(getBlabItemActivityCounts(input));
  }

  subscribeToBlabItemActivity(blabItemId: number): Observable<BlabItemActivitySubscriptionUpdate> {
    return this.apollo.defaultSubscribe(
      subscribeToBlabItemActivity(blabItemId, [BlabItemActivitySubscriptionUpdateFragment]),
    );
  }
}
