import gql from 'graphql-tag';
import { PageInfoNoNestingFragment } from '../../generated/graphql-fragments';
import { FullUserProfileDtoFragment } from '../user-profile';

export const OrgUsersConnectionFullFragment = gql`
  fragment FullOrgUsersConnection on OrgUsersConnection {
    pageInfo {
      ...PageInfoNoNesting
    }
    edges {
      cursor
      node {
        ...FullUserProfileDto
      }
    }
  }
  ${FullUserProfileDtoFragment}
  ${PageInfoNoNestingFragment}
`;
