import gql from 'graphql-tag';
import { ChatGroupDtoNoNestingFragment, ThumbnailDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const ChatGroupSearchResultFragment = gql`
  fragment ChatGroupSearchResult on ChatGroupSearchResultDto {
    type
    group {
      ...ChatGroupDtoNoNesting
      thumbnail {
        ...ThumbnailDtoNoNesting
      }
    }
  }
  ${ChatGroupDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
