import gql from 'graphql-tag';
import { BlabDefDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../flim-def';

const GetBlabDefWithIncomingAndOutgoingBlabDefsResultDtoBlabDefFragment = gql`
  fragment GetBlabDefWithIncomingAndOutgoingBlabDefsResultDtoBlabDefFragment on BlabDefDto {
    ...BlabDefDtoNoNesting
    flimDefs {
      ...FullFlimDef
    }
  }
  ${BlabDefDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;

export const GetBlabDefWithIncomingAndOutgoingBlabDefsResultDtoFragment = gql`
  fragment GetBlabDefWithIncomingAndOutgoingBlabDefsResultDto on GetBlabDefWithIncomingAndOutgoingBlabDefsResultDto {
    blabDef {
      ...GetBlabDefWithIncomingAndOutgoingBlabDefsResultDtoBlabDefFragment
    }
    incomingBlabDefs {
      ...GetBlabDefWithIncomingAndOutgoingBlabDefsResultDtoBlabDefFragment
    }
    outgoingBlabDefs {
      ...GetBlabDefWithIncomingAndOutgoingBlabDefsResultDtoBlabDefFragment
    }
  }
  ${GetBlabDefWithIncomingAndOutgoingBlabDefsResultDtoBlabDefFragment}
`;
