import { Language } from '@t5s/shared/gql';
import { I18n } from '@t5s/shared/i18n/common';

const languages = {
  [Language.DE]: {
    noop: '{{str}}',
    at: 'um',
    hourDelta: `{{delta}} Std.`,
    minuteDelta: `{{delta}} Min.`,
    noDate: `Kein Datum`,
    now: `Jetzt`,
    past: `Überfällig`,
    secondDelta: `{{delta}} Sek.`,
    today: `Heute`,
    tomorrow: `Morgen`,
    tomorrowTime: `Morgen {{time}}`,
    yesterday: `Gestern`,
    yesterdayTime: `Gestern {{time}}`,
    todayAt: `Heute um {{time}}`,
    yesterdayAt: `Gestern um {{time}}`,
    lastWeekdayAt: `Letzten {{weekday}} um {{time}}`,

    weekdays: {
      SUNDAY: `Sonntag`,
      MONDAY: `Montag`,
      TUESDAY: `Dienstag`,
      WEDNESDAY: `Mittwoch`,
      THURSDAY: `Donnerstag`,
      FRIDAY: `Freitag`,
      SATURDAY: `Samstag`,
    },

    weekDaysShort: {
      SUNDAY: `So.`,
      MONDAY: `Mo.`,
      TUESDAY: `Di.`,
      WEDNESDAY: `Mi.`,
      THURSDAY: `Do.`,
      FRIDAY: `Fr.`,
      SATURDAY: `Sa.`,
    },

    monthsShort: {
      JANUARY: `Jan.`,
      FEBRUARY: `Feb.`,
      MARCH: `März`,
      APRIL: `Apr.`,
      MAY: `Mai`,
      JUNE: `Juni`,
      JULY: `Juli`,
      AUGUST: `Aug.`,
      SEPTEMBER: `Sep.`,
      OCTOBER: `Okt.`,
      NOVEMBER: `Nov.`,
      DECEMBER: `Dez.`,
    },

    months: {
      JANUARY: `Januar`,
      FEBRUARY: `Februar`,
      MARCH: `März`,
      APRIL: `April`,
      MAY: `Mai`,
      JUNE: `Juni`,
      JULY: `Juli`,
      AUGUST: `August`,
      SEPTEMBER: `September`,
      OCTOBER: `Oktober`,
      NOVEMBER: `November`,
      DECEMBER: `Dezember`,
    },

    quarters: {
      Q1: `Q1`,
      Q2: `Q2`,
      Q3: `Q3`,
      Q4: `Q4`,
    },

    quartersLong: {
      Q1: `Erstes Quartal`,
      Q2: `Zweites Quartal`,
      Q3: `Drittes Quartal`,
      Q4: `Viertes Quartal`,
    },

    quartersLongWithYear: {
      Q1: `Erstes Quartal {{ year }}`,
      Q2: `Zweites Quartal {{ year }}`,
      Q3: `Drittes Quartal {{ year }}`,
      Q4: `Viertes Quartal {{ year }}`,
    },

    week: 'KW {{ week }}/{{ year }}',
    weekLong: 'Kalenderwoche {{ week }}, {{ year }}',
  },
  [Language.EN]: {
    noop: '{{str}}',
    at: 'at',
    hourDelta: `{{delta}}h`,
    minuteDelta: `{{delta}}m`,
    noDate: `No Date`,
    now: `Now`,
    past: `Overdue`,
    secondDelta: `{{delta}}s`,
    today: `Today`,
    tomorrow: `Tomorrow`,
    tomorrowTime: `Tomorrow {{time}}`,
    yesterday: `Yesterday`,
    yesterdayTime: `Yesterday {{time}}`,
    todayAt: `Today at {{time}}`,
    yesterdayAt: `Yesterday at {{time}}`,
    lastWeekdayAt: `Last {{weekday}} at {{time}}`,

    weekdays: {
      SUNDAY: `Sunday`,
      MONDAY: `Monday`,
      TUESDAY: `Tuesday`,
      WEDNESDAY: `Wednesday`,
      THURSDAY: `Thursday`,
      FRIDAY: `Friday`,
      SATURDAY: `Saturday`,
    },

    weekDaysShort: {
      SUNDAY: `Sun`,
      MONDAY: `Mon`,
      TUESDAY: `Tue`,
      WEDNESDAY: `Wed`,
      THURSDAY: `Thu`,
      FRIDAY: `Fri`,
      SATURDAY: `Sat`,
    },

    monthsShort: {
      JANUARY: `Jan`,
      FEBRUARY: `Feb`,
      MARCH: `Mar`,
      APRIL: `Apr`,
      MAY: `May`,
      JUNE: `Jun`,
      JULY: `Jul`,
      AUGUST: `Aug`,
      SEPTEMBER: `Sep`,
      OCTOBER: `Oct`,
      NOVEMBER: `Nov`,
      DECEMBER: `Dec`,
    },

    months: {
      JANUARY: `January`,
      FEBRUARY: `February`,
      MARCH: `March`,
      APRIL: `April`,
      MAY: `May`,
      JUNE: `June`,
      JULY: `July`,
      AUGUST: `August`,
      SEPTEMBER: `September`,
      OCTOBER: `October`,
      NOVEMBER: `November`,
      DECEMBER: `December`,
    },

    quarters: {
      Q1: `Q1`,
      Q2: `Q2`,
      Q3: `Q3`,
      Q4: `Q4`,
    },

    quartersLong: {
      Q1: `First Quarter`,
      Q2: `Second Quarter`,
      Q3: `Third Quarter`,
      Q4: `Fourth Quarter`,
    },

    quartersLongWithYear: {
      Q1: `First Quarter {{ year }}`,
      Q2: `Second Quarter {{ year }}`,
      Q3: `Third Quarter {{ year }}`,
      Q4: `Fourth Quarter {{ year }}`,
    },

    week: 'Wk {{ week }} ’{{ year }}',
    weekLong: 'Week {{ week }}, {{ year }}',
  },
} as const;

export const DatetimeI18n = new I18n<typeof languages.EN>(languages);
