import gql from 'graphql-tag';
import {
  BlabItemActivityReplyAttachmentDtoNoNestingFragment,
  BlabItemActivityReplyDtoNoNestingFragment,
  WorkflowDefPreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { ReactionDtoFragment } from '../core';
import { LinkPreviewDtoFragment } from '../link-preview';
import { UserProfilePreviewDtoFragment } from '../user-profile';
import { BlabItemActivityReplyAttachmentFragment } from './blab-item-activity-reply-attachment.fragment';

export const BlabItemActivityReplyDtoFragment = gql`
  fragment BlabItemActivityReplyDtoFragment on BlabItemActivityReplyDto {
    ...BlabItemActivityReplyDtoNoNesting

    user {
      ...UserProfilePreviewDtoFragment
    }

    authorWorkflowDef {
      ...WorkflowDefPreviewDtoNoNesting
    }

    reactions {
      ...ReactionDtoFragment
    }

    attachments {
      ...BlabItemActivityReplyAttachmentFragment
    }

    linkPreview {
      ...LinkPreviewDtoFragment
    }
  }
  ${BlabItemActivityReplyDtoNoNestingFragment}
  ${UserProfilePreviewDtoFragment}
  ${WorkflowDefPreviewDtoNoNestingFragment}
  ${ReactionDtoFragment}
  ${BlabItemActivityReplyAttachmentDtoNoNestingFragment}
  ${BlabItemActivityReplyAttachmentFragment}
  ${LinkPreviewDtoFragment}
`;
