import { NgModule } from '@angular/core';
import { LoggedInRootBusinessLogicModule } from '@t5s/mobile-client/business-logic/logged-in-root';
import { PageCommonModule } from '@t5s/mobile-client/page/common';
import { LoggedInRootViewModule } from '@t5s/mobile-client/ui/view/logged-in-root';
import { ReactionDetailsPageModule } from '@t5s/mobile-client/page/logged-in/sheet/reaction-details';
import { LoggedInRootPagePageComponent } from './logged-in-root-page.component';

@NgModule({
  imports: [PageCommonModule, LoggedInRootViewModule, LoggedInRootBusinessLogicModule, ReactionDetailsPageModule],
  declarations: [LoggedInRootPagePageComponent],
  exports: [LoggedInRootPagePageComponent],
})
export class LoggedInRootPageModule {}
