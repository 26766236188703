import gql from 'graphql-tag';
import { ReactionDtoFragment } from '../core';
import { UserProfilePreviewDtoFragment } from '../user-profile';
import { UserActivityReplyDtoFragment } from './user-activity-reply-dto.fragment';

/**
 * Fragment containing all common user activity properties of the UserActivityDto interface
 * This fragment is used as a baseline for the activity-type specific fragments
 */
export const UserActivityDtoFragment = gql`
  fragment UserActivityDtoFragment on UserActivityDto {
    id
    type
    createdAt
    author {
      ...UserProfilePreviewDtoFragment
    }
    authorId
    reactions {
      ...ReactionDtoFragment
    }
    numReplies
    replies(first: 50) {
      cursor
      node {
        ...UserActivityReplyDtoFragment
      }
    }
    userId
    user {
      id
      primaryName
      profilePicture {
        ...ThumbnailDtoNoNesting
      }
      organization {
        id
        name
        slug
      }
    }
  }
  ${UserActivityReplyDtoFragment}
  ${UserProfilePreviewDtoFragment}
  ${ReactionDtoFragment}
`;
