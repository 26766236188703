import gql from 'graphql-tag';
import { ActiveUserSingleRelationSplitBySectionDtoNoNestingFragment } from '../../../../generated/graphql-fragments';

export const ActiveUserSingleRelationSplitBySectionDtoFragment = gql`
  fragment ActiveUserSingleRelationSplitBySectionDto on ActiveUserSingleRelationSplitBySectionDto {
    ...ActiveUserSingleRelationSplitBySectionDtoNoNesting
    blabItem {
      id
      title
      blabDef {
        id
        blabName
        itemName
        icon
        iconv2
        itemIcon
        workspace {
          id
          name
        }
      }
    }
  }
  ${ActiveUserSingleRelationSplitBySectionDtoNoNestingFragment}
`;
