import { Language } from '@t5s/shared/gql';
import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { formatDateViaLanguage, isApplicableI18n, normalizeDate, shouldAbbreviateMonth } from '../common';

export function formatChartGroupSingleDayDateShort(
  i18n: SharedI18n,
  date?: string | number | Date,
): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  const shouldAbbreviate = shouldAbbreviateMonth(i18n, date);
  let dateFormat: string;
  switch (i18n.language) {
    case Language.EN: {
      if (i18n.dateFormat === 'SLASH_MM_DD_YYYY') {
        dateFormat = shouldAbbreviate ? 'MMM d, ’yy' : 'MMMM d, ’yy';
      } else {
        dateFormat = shouldAbbreviate ? 'd MMM ’yy' : 'd MMMM ’yy';
      }
      break;
    }

    case Language.DE: {
      dateFormat = shouldAbbreviate ? 'd. MMM yy' : 'd. MMMM yy';
      // patch this to match ISO behavior whatever
      return formatDateViaLanguage(date, dateFormat, i18n).replace('Juni', 'Jun.').replace('Juli', 'Jul.');
    }
  }

  return formatDateViaLanguage(date, dateFormat, i18n);
}
