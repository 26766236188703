import gql from 'graphql-tag';
import { PageInfoFragment } from '../core/page-info.fragment';
import { BlabItemActivityReplyDtoFragment } from './blab-item-activity-reply-dto.fragment';
import { BlabItemActivityUnionTypeFragment } from './blab-item-activity-union-type.fragment';

export const BlabItemActivityConnectionFragment = gql`
  fragment BlabItemActivityConnectionFragment on BlabItemActivityConnection {
    edges {
      cursor
      node {
        ...BlabItemActivityUnionTypeFragment
        ... on BlabItemActivityDto {
          replies(first: 50) {
            cursor
            node {
              ...BlabItemActivityReplyDtoFragment
            }
          }
        }
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
  ${BlabItemActivityUnionTypeFragment}
  ${PageInfoFragment}
  ${BlabItemActivityReplyDtoFragment}
`;
