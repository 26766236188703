import { NgModule } from '@angular/core';
import { SwiperModule } from 'swiper/angular';
import SwiperCore, { Zoom } from 'swiper/core';

// install Swiper modules
SwiperCore.use([Zoom]);

@NgModule({
  exports: [SwiperModule],
})
export class SwiperComponentModule {}
