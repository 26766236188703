import { createAction, props } from '@ngrx/store';
import { createErrorAction } from '@t5s/client/util/store';
import { ActiveUserDto, UserSessionDto } from '@t5s/shared/gql';

const PREFIX = '[mobile-client-active-user-session]';

export const ActiveUserSessionActions = {
  initialize: createAction(`${PREFIX} init active user session`),
  initializeUserSessionsSuccess: createAction(
    `${PREFIX} initialize user sessions success`,
    props<{ sessions: UserSessionDto[] }>(),
  ),
  initializeUserSessionsError: createErrorAction(`${PREFIX} initialize user sessions error`),

  setActiveUserSessions: createAction(`${PREFIX} set active user session`, props<{ sessions: UserSessionDto[] }>()),

  // Cookie handling
  loadActiveUserSessionCookie: createAction(`${PREFIX} load active user sessions cookie`),
  setActiveUserSessionCookie: createAction(`${PREFIX} set active user sessions cookie`, props<{ cookie: string }>()),

  // Load active user
  loadActiveUser: createAction(`${PREFIX} load active user`),
  loadActiveUserSuccess: createAction(`${PREFIX} load active user success`, props<{ activeUser: ActiveUserDto }>()),
  loadActiveUserUnauthenticatedException: createErrorAction(`${PREFIX} load active user authentication exception`),
  loadActiveUserException: createErrorAction(`${PREFIX} load active user exception`),

  // Logout
  logout: createAction(`${PREFIX} logout`),
  logoutSuccess: createAction(`${PREFIX} logout success`),
  logoutError: createErrorAction(`${PREFIX} logout error`),

  // Force logout
  forceLogout: createAction(`${PREFIX} force logout`),
  forceLogoutSuccess: createAction(`${PREFIX} force logout success`),
};
