import { createAction, props } from '@ngrx/store';
import { createErrorAction } from '@t5s/client/util/store';
import {
  BlabItemCommentInputAttachmentValobj,
  BlabItemDisplayBlabItemDetail,
} from '@t5s/mobile-client/value-object/blab-item-display';
import {
  ActiveUserBlabItemInfoDto,
  BlabItemActivityConnection,
  BlabItemActivityDto,
  BlabItemActivityEdge,
  BlabItemActivitySubscriptionUpdate,
  BlabItemCommentActivityDto,
  BlabItemCommentAttachmentDto,
  BlabItemPreviewDto,
  FlimValUnionType,
  IncomingRelationFlimDefPreviewDto,
  PageInfo,
  UserProfilePreviewDto,
} from '@t5s/shared/gql';
import { BlabItemDisplayHydrationState } from './blab-item-display-hydration.state';

const PREFIX = '[mobile-client-blab-item-display]';

export const BlabItemDisplayActions = {
  reset: createAction(`${PREFIX} reset`),

  // indicate that a blabitem will have to be loaded, important for loading state
  markBlabItemForLoading: createAction(`${PREFIX} mark blabItem for loading`, props<{ blabItemId: number }>()),

  // load blabItem
  loadBlabItem: createAction(`${PREFIX} load blabItem`, props<{ blabItemId: number }>()),
  loadBlabItemSuccess: createAction(
    `${PREFIX} loaded blabItem successfully`,
    props<{ blabItemId: number; blabItem: BlabItemDisplayBlabItemDetail }>(),
  ),
  loadBlabItemException: createErrorAction(
    `${PREFIX} loading blabItem failed with exception`,
    props<{ blabItemId: number }>(),
  ),
  loadBlabItemRejectedNotAvailable: createAction(
    `${PREFIX} loading blabItem rejected, no permission`,
    props<{ blabItemId: number }>(),
  ),

  // reload blabItem title
  reloadBlabItemTitle: createAction(`${PREFIX} reload blabItem title`, props<{ blabItemId: number }>()),
  reloadBlabItemTitleSuccess: createAction(
    `${PREFIX} reloaded blabItem title successfully`,
    props<{ blabItemId: number; blabItem: { title?: string } }>(),
  ),
  reloadBlabItemTitleException: createErrorAction(`${PREFIX} reloading blabItem title failed with exception`),

  // preload blabItem
  preloadBlabItemsWithActivity: createAction(
    `${PREFIX} preload blabItems with activity`,
    props<{ blabItemIds: number[] }>(),
  ),
  preloadBlabItemWithActivitySuccess: createAction(
    `${PREFIX} preloaded a blabItem with activity successfully`,
    props<{
      blabItemId: number;
      blabItem: BlabItemDisplayBlabItemDetail;
      activeUserBlabItemInfo: ActiveUserBlabItemInfoDto;
      blabItemActivity: BlabItemActivityConnection;
    }>(),
  ),
  preloadBlabItemsWithActivityException: createErrorAction(
    `${PREFIX} preloading blabItems with activity failed with exception`,
    props<{ blabItemIds: number[] }>(),
  ),

  // active user blabItem info
  loadActiveUserBlabItemInfo: createAction(`${PREFIX} load active user blabitem info`, props<{ blabItemId: number }>()),
  loadActiveUserBlabItemInfoSuccess: createAction(
    `${PREFIX} loaded active user blabitem info successfully`,
    props<{ blabItemId: number; activeUserBlabItemInfo: ActiveUserBlabItemInfoDto }>(),
  ),
  loadActiveUserBlabItemInfoException: createAction(
    `${PREFIX} loading active user blabitem info failed with exception`,
    props<{ error: any; blabItemId: number }>(),
  ),

  // blab item activities
  loadBlabItemActivities: createAction(`${PREFIX} load blabItem activities`, props<{ blabItemId: number }>()),
  loadBlabItemActivitiesSuccess: createAction(
    `${PREFIX} loaded blabItem activities successfully`,
    props<{ blabItemId: number; blabItemActivities: BlabItemActivityEdge[]; pageInfo: PageInfo }>(),
  ),
  loadBlabItemActivitiesException: createAction(
    `${PREFIX} loading blabItem activities failed with exception`,
    props<{ error: any; blabItemId: number }>(),
  ),

  // incoming relations
  loadIncomingRelationFlimDefs: createAction(
    `${PREFIX} load blabItem incoming relation flimDefs`,
    props<{ blabItemId: number }>(),
  ),
  loadIncomingRelationFlimDefsSuccess: createAction(
    `${PREFIX} loaded blabItem incoming relation flimDefs successfully`,
    props<{ blabItemId: number; incomingRelationFlimDefs: IncomingRelationFlimDefPreviewDto[] }>(),
  ),
  loadIncomingRelationFlimDefsException: createAction(
    `${PREFIX} loading blabItem incoming relation flimDefs failed with exception`,
    props<{ error: any; blabItemId: number }>(),
  ),

  // live update establishement proxy action
  establishLiveSubscriptions: createAction(
    `${PREFIX} Establish all blabItem specific live subscriptions`,
    props<{ blabItemId: number }>(),
  ),
  unsubscribeFromBlabItemLiveSubscriptions: createAction(
    `${PREFIX} Unsubscribe from all blabItem specific live subscriptions`,
    props<{ blabItemId: number }>(),
  ),

  // blab item live updates
  subscribeToBlabItemLiveUpdates: createAction(
    `${PREFIX} Subscribe to item live updates`,
    props<{ blabItemId: number }>(),
  ),
  subscribeToBlabItemLiveUpdatesSuccess: createAction(
    `${PREFIX} Item live update subscription established`,
    props<{ blabItemId: number }>(),
  ),
  subscribeToBlabItemLiveUpdatesException: createErrorAction(
    `${PREFIX} Item live update subscription failed with exception`,
    props<{ blabItemId: number }>(),
  ),
  unsubscribeFromBlabItemLiveUpdates: createAction(
    `${PREFIX} Unsubscribe from item live updates`,
    props<{ blabItemId: number }>(),
  ),
  blabItemLiveUpdate: createAction(
    `${PREFIX} Blab item live update received`,
    props<{ blabItemId: number; flimVal: FlimValUnionType }>(),
  ),

  // blab item activity live subscription
  subscribeToBlabItemActivity: createAction(
    `${PREFIX} Subscribe to blabItem activity for item`,
    props<{ blabItemId: number }>(),
  ),
  subscribeToBlabItemActivitySuccess: createAction(
    `${PREFIX} Blab item activity live update subscription established`,
    props<{ blabItemId: number }>(),
  ),
  subscribeToBlabItemActivityException: createErrorAction(
    `${PREFIX} Subscribing to blabItem activity for item failed`,
    props<{ blabItemId: number }>(),
  ),
  unsubscribeFromBlabItemActivity: createAction(
    `${PREFIX} Unsubscribe from blabItem activity for item`,
    props<{ blabItemId: number }>(),
  ),
  blabItemActivityLiveUpdate: createAction(
    `${PREFIX} BlabItem activity update received`,
    props<{ blabItemId: number; update: BlabItemActivitySubscriptionUpdate }>(),
  ),
  subscribeToActiveUserBlabItemInfo: createAction(
    `${PREFIX} Subscribe to active user blab item info`,
    props<{ blabItemId: number }>(),
  ),
  subscribeToActiveUserBlabItemInfoSuccess: createAction(
    `${PREFIX} Active user blab item info live subscription established`,
    props<{ blabItemId: number }>(),
  ),
  unsubscribeFromActiveUserBlabItemInfo: createAction(
    `${PREFIX} Unsubscribe from active user blab item info`,
    props<{ blabItemId: number }>(),
  ),
  subscribeToActiveUserBlabItemInfoException: createErrorAction(
    `${PREFIX} active user blab item info subscription errored`,
    props<{ blabItemId: number }>(),
  ),
  activeUserBlabItemInfoLiveUpdate: createAction(
    `${PREFIX} active user blab item info live update received`,
    props<{ blabItemInfo: ActiveUserBlabItemInfoDto; blabItemId: number }>(),
  ),

  // active user blab item
  followBlabItem: createAction(`${PREFIX} Follow blab item`, props<{ blabItemId: number }>()),
  followBlabItemSuccess: createAction(
    `${PREFIX} Followed blab item`,
    props<{ blabItemId: number; activeUserBlabItemInfo: ActiveUserBlabItemInfoDto }>(),
  ),
  followBlabItemException: createAction(
    `${PREFIX} Following blab item failed with exception`,
    props<{ blabItemId: number; activeUserBlabItemInfo: ActiveUserBlabItemInfoDto }>(),
  ),

  unfollowBlabItem: createAction(`${PREFIX} Unfollow blab item`, props<{ blabItemId: number }>()),
  unfollowBlabItemSuccess: createAction(
    `${PREFIX} Unfollowed blab item`,
    props<{ blabItemId: number; activeUserBlabItemInfo: ActiveUserBlabItemInfoDto }>(),
  ),
  unfollowBlabItemException: createAction(
    `${PREFIX} Unfollowing blab item failed with exception`,
    props<{ blabItemId: number; activeUserBlabItemInfo: ActiveUserBlabItemInfoDto }>(),
  ),

  // BlabItem activity reaction
  addBlabItemActivityReaction: createAction(
    `${PREFIX} add blab item activity reaction`,
    props<{ emoji: string; activityId: number }>(),
  ),
  addBlabItemActivityReactionSuccess: createAction(
    `${PREFIX} add blab item activity reaction success`,
    props<{ emoji: string; activity: BlabItemActivityDto }>(),
  ),
  addBlabItemActivityReactionException: createErrorAction(`${PREFIX} add blab item activity reaction exception`),

  removeBlabItemActivityReaction: createAction(
    `${PREFIX} remove blab item activity reaction`,
    props<{ emoji: string; activityId: number }>(),
  ),
  removeBlabItemActivityReactionSuccess: createAction(
    `${PREFIX} remove blab item activity reaction success`,
    props<{ emoji: string; activity: BlabItemActivityDto }>(),
  ),
  removeBlabItemActivityReactionException: createErrorAction(`${PREFIX} remove blab item activity reaction exception`),

  // Comment input
  setCommentInputContent: createAction(
    `${PREFIX} set comment input content`,
    props<{ blabItemId: number; content: string }>(),
  ),
  sendBlabItemComment: createAction(
    `${PREFIX} send blab item comment`,
    props<{ blabItemId: number; content: string }>(),
  ),
  sendBlabItemCommentSuccess: createAction(
    `${PREFIX} send blab item comment success`,
    props<{ activity: BlabItemCommentActivityDto; blabItemId: number; content: string }>(),
  ),
  sendBlabItemCommentException: createErrorAction(
    `${PREFIX} send blab item comment exception`,
    props<{ blabItemId: number; content: string }>(),
  ),
  addAttachmentFiles: createAction(
    `${PREFIX} Add comment file attachments`,
    props<{ blabItemId: number; files: File[] }>(),
  ),
  removeAttachment: createAction(
    `${PREFIX} Remove file attachment`,
    props<{ blabItemId: number; attachmentId: number }>(),
  ),
  invalidFileSizeFileAttached: createAction(
    `${PREFIX} File with invalid file size had been attached by user`,
    props<{ files: File[] }>(),
  ),
  tooManyFileAttached: createAction(`${PREFIX} Too many files had been attached by user`, props<{ files: File[] }>()),

  uploadFile: createAction(
    `${PREFIX} Upload file attachment`,
    props<{ blabItemId: number; attachment: BlabItemCommentInputAttachmentValobj }>(),
  ),
  uploadFileSuccess: createAction(
    `${PREFIX} Uploaded file attachment successfully`,
    props<{
      blabItemId: number;
      attachment: BlabItemCommentInputAttachmentValobj;
      persistedAttachment: BlabItemCommentAttachmentDto;
    }>(),
  ),
  uploadFileException: createErrorAction(
    `${PREFIX} Uploading file attachment failed`,
    props<{ blabItemId: number; attachment: BlabItemCommentInputAttachmentValobj }>(),
  ),

  // Mention user
  clearMentionUserSearchResults: createAction(`${PREFIX} clear mention user search results`),
  searchMentionUser: createAction(
    `${PREFIX} search mention user`,
    props<{ searchQuery: string; blabItemId: number }>(),
  ),
  searchMentionUserSuccess: createAction(
    `${PREFIX} search mention user success`,
    props<{ searchQuery: string; userSearchResults: UserProfilePreviewDto[] }>(),
  ),
  searchMentionUserException: createErrorAction(`${PREFIX} search mention user`, props<{ searchQuery: string }>()),

  // comment deletion
  confirmDeleteBlabItemComment: createAction(
    `${PREFIX} confirm delete blab item comment`,
    props<{ blabItemId: number; activityId: number }>(),
  ),
  deleteBlabItemComment: createAction(
    `${PREFIX} delete blab item comment`,
    props<{ blabItemId: number; activityId: number }>(),
  ),
  deleteBlabItemCommentSuccess: createAction(
    `${PREFIX} delete blab item comment success`,
    props<{ blabItemId: number; activityId: number }>(),
  ),
  deleteBlabItemCommentException: createErrorAction(
    `${PREFIX} delete blab item comment exception`,
    props<{ blabItemId: number; activityId: number }>(),
  ),

  copyCommentContent: createAction(`${PREFIX} Copy comment content`, props<{ activity: BlabItemCommentActivityDto }>()),

  // State hydration
  hydrateState: createAction(`${PREFIX} Hydrate state`),
  hydrateStateSuccess: createAction(
    `${PREFIX} Hydrate state Success`,
    props<{ state: BlabItemDisplayHydrationState }>(),
  ),
  hydrateStateNotAvailable: createAction(`${PREFIX} Hydration of state not available`),
  hydrateStateException: createAction(`${PREFIX} Hydration of state failed with exception`),

  // navigation
  accessBlabItem: createAction(`${PREFIX} Access blab item`, props<{ blabItemId: number }>()),

  /** Access the incoming relations page for an incoming relation. Optionally provide context to improve UX (avoids loading) */
  accessBlabItemIncomingRelation: createAction(
    `${PREFIX} Access blab item incoming relation`,
    props<{
      blabItemId: number;
      referencingFlimDefId: number;
      blabItem?: BlabItemPreviewDto;
      referencingFlimDef?: IncomingRelationFlimDefPreviewDto;
    }>(),
  ),
  accessBlabItemActivityThread: createAction(
    `${PREFIX} Access blab item activity thread`,
    props<{ blabItemActivityId: number; activityReplyId?: number }>(),
  ),
  openUrl: createAction(`${PREFIX} Open URL`, props<{ url: string }>()),
};
