import gql from 'graphql-tag';
import {
  BlabReferenceBlabDefPreviewDtoNoNestingFragment,
  BlabReferenceDtoNoNestingFragment,
  SingleRelationFlimDefDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const LightSingleRelationFlimDefDtoFragment = gql`
  fragment LightSingleRelationFlimDefDtoFragment on SingleRelationFlimDefDto {
    ...SingleRelationFlimDefDtoNoNesting
    blabReferences {
      ...BlabReferenceDtoNoNesting
      blabDef {
        ...BlabReferenceBlabDefPreviewDtoNoNesting
      }
    }
  }
  ${SingleRelationFlimDefDtoNoNestingFragment}

  ${BlabReferenceDtoNoNestingFragment}
  ${BlabReferenceBlabDefPreviewDtoNoNestingFragment}
`;
