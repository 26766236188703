import gql from 'graphql-tag';
import { UserProfileDtoOmitOrgFragment } from '../user-profile';

export const LightUserSearchResultFragment = gql`
  fragment LightUserSearchResult on UserSearchResultDto {
    type
    user {
      ...UserProfileDtoOmitOrgFragment
    }
  }
  ${UserProfileDtoOmitOrgFragment}
`;
