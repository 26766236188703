import { AuthenticateWithCredentialsResult, UserCredentialsInput } from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import {
  UserAuthenticationResultFragment,
  UserAuthenticationResultLightFragment,
} from '../../fragments/user-authentication';
import { authenticateWithCredentials } from '../../generated/graphql-mutations';
import { GqlService } from '../../gql-service';

export class GqlLoginService extends GqlService {
  authenticateWithCredentials(
    orgId: number,
    credentials: UserCredentialsInput,
    { preserveExistingSessions }: { preserveExistingSessions?: boolean } = {},
  ): Observable<AuthenticateWithCredentialsResult> {
    return this.apollo.defaultMutate(
      authenticateWithCredentials({ orgId, credentials, preserveExistingSessions }, [UserAuthenticationResultFragment]),
    );
  }

  /** Same as `authenticateWithCredentials` but requesting fewer data to reduce server load in e2es. */
  authenticateWithCredentialsLight(
    orgId: number,
    credentials: UserCredentialsInput,
    { preserveExistingSessions }: { preserveExistingSessions?: boolean } = {},
  ): Observable<AuthenticateWithCredentialsResult> {
    return this.apollo.defaultMutate(
      authenticateWithCredentials({ orgId, credentials, preserveExistingSessions }, [
        UserAuthenticationResultLightFragment,
      ]),
    );
  }
}
