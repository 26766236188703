import { BlabItemRevisionType } from '@t5s/shared/gql';

export const blabItemRevisionActivityLangEnClient = {
  [BlabItemRevisionType.SINGLE_TEXT]: {
    created: 'Added {{flimDefName}} "{{value}}"',
    deleted: 'Deleted {{flimDefName}} "{{prevValue}}"',
    updated: 'Changed {{flimDefName}} from "{{prevValue}}" to "{{value}}"',
  },
  [BlabItemRevisionType.MULTI_TEXT]: {
    created: 'Added {{flimDefName}}',
    deleted: 'Deleted {{flimDefName}}',
    updated: 'Changed {{flimDefName}}',
  },
  [BlabItemRevisionType.SINGLE_CATEGORY]: {
    created: 'Set {{flimDefName}} to "{{value}}"',
    deleted: 'Removed {{flimDefName}} "{{prevValue}}"',
    updated: 'Changed {{flimDefName}} from "{{prevValue}}" to "{{value}}"',
  },
  [BlabItemRevisionType.MULTI_CATEGORY]: {
    created: 'Set {{flimDefName}} to "{{value}}"',
    deleted: 'Removed {{flimDefName}} "{{prevValue}}"',
    updatedAdd: 'Added {{flimDefName}} "{{value}}" to "{{prevValue}}"',
    updatedRemove: 'Removed {{flimDefName}} "{{value}}" from "{{prevValue}}"',
  },
  [BlabItemRevisionType.SINGLE_RELATION]: {
    created: 'Added "{{value}}" as {{flimDefName}}',
    deleted: 'Removed "{{prevValue}}" as {{flimDefName}}',
    updated: 'Replaced "{{prevValue}}" with "{{value}}" as {{flimDefName}}',
  },
  [BlabItemRevisionType.MULTI_RELATION]: {
    created: 'Added "{{value}}" as {{flimDefName}}',
    deleted: 'Removed "{{prevValue}}" as {{flimDefName}}',
    updatedAdd: 'Added "{{value}}" to "{{prevValue}}" as {{flimDefName}}',
    updatedRemove: 'Removed "{{value}}" from "{{prevValue}}" as {{flimDefName}}',
  },
  [BlabItemRevisionType.INCOMING_RELATION]: {
    created: 'Added {{value}} as {{flimDefName}} on "{{blabItemTitle}}"',
    deleted: 'Removed {{value}} as {{flimDefName}} on "{{blabItemTitle}}"',
  },
  [BlabItemRevisionType.SINGLE_ATTACHMENT]: {
    created: 'Added file "{{value}}" to {{flimDefName}}',
    deleted: 'Removed file "{{prevValue}}" from {{flimDefName}}',
    updated: 'Replaced file "{{prevValue}}" with file "{{value}}" in {{flimDefName}}',
  },
  [BlabItemRevisionType.MULTI_ATTACHMENT]: {
    created: 'Added file "{{value}}" to {{flimDefName}}',
    deleted: 'Removed file "{{prevValue}}" from {{flimDefName}}',
    updatedAdd: 'Added file "{{value}}" to files "{{prevValue}}" in {{flimDefName}}',
    updatedRemove: 'Removed file "{{value}}" from files "{{prevValue}}" in {{flimDefName}}',
  },
  [BlabItemRevisionType.SINGLE_USER]: {
    created: 'Added "{{value}}" as {{flimDefName}}',
    deleted: 'Removed "{{prevValue}}" as {{flimDefName}}',
    updated: 'Replaced "{{prevValue}}" with "{{value}}" as {{flimDefName}}',
  },
  [BlabItemRevisionType.MULTI_USER]: {
    created: 'Added "{{value}}" as {{flimDefName}}',
    deleted: 'Removed "{{prevValue}}" as {{flimDefName}}',
    updatedAdd: 'Added "{{value}}" to "{{prevValue}}" as {{flimDefName}}',
    updatedRemove: 'Removed "{{value}}" from "{{prevValue}}" as {{flimDefName}}',
  },
  [BlabItemRevisionType.SINGLE_DATE]: {
    created: 'Set {{flimDefName}} to "{{value}}"',
    deleted: 'Removed {{flimDefName}} "{{prevValue}}"',
    updated: 'Changed {{flimDefName}} to "{{value}}"',
  },
  [BlabItemRevisionType.RANGE_DATE]: {
    created: 'Set {{flimDefName}} to "{{value}}"',
    deleted: 'Removed {{flimDefName}} "{{prevValue}}"',
    updated: 'Changed {{flimDefName}} to "{{value}}"',
  },
} as const;
