import gql from 'graphql-tag';
import {
  BlabDefWebhookDtoNoNestingFragment,
  UpdateBlabDefWebhookResultDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const UpdateBlabDefWebhookResultDtoFragment = gql`
  fragment UpdateBlabDefWebhookResultDtoFragment on UpdateBlabDefWebhookResultDto {
    ...UpdateBlabDefWebhookResultDtoNoNesting
    webhook {
      ...BlabDefWebhookDtoNoNesting
    }
  }
  ${UpdateBlabDefWebhookResultDtoNoNestingFragment}
  ${BlabDefWebhookDtoNoNestingFragment}
`;
