import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { isSameYear, isThisWeek, isToday, isTomorrow, isYesterday } from '@t5s/shared/util/date';
import { DatetimeI18n } from '../../../datetime.i18n';
import { ApplicableI18n, formatDate, getDateTimeFormats, isApplicableI18n, normalizeDate } from '../common';

function shortDateI18nStr(date: Date, i18n: ApplicableI18n): string {
  const dateTimeFormats = getDateTimeFormats(i18n);

  const now = new Date(Date.now());

  if (isToday(date)) {
    return formatDate(date, dateTimeFormats.time.short, i18n);
  } else if (isTomorrow(date)) {
    return DatetimeI18n.translate(i18n, DatetimeI18n.key.tomorrow);
  } else if (isYesterday(date)) {
    return DatetimeI18n.translate(i18n, DatetimeI18n.key.yesterday);
  } else if (isThisWeek(date)) {
    return formatDate(date, dateTimeFormats.date.weekday, i18n);
  } else if (isSameYear(date, now)) {
    return formatDate(date, dateTimeFormats.date.omitYear, i18n);
  } else {
    return formatDate(date, dateTimeFormats.date.full, i18n);
  }
}

/** Display a short date:
 * For today: '12:05 pm'
 * For A day in the same week: 'Wednesday'
 * For same year: 'May 05'
 * Otherwise: 'Jan 01, 2021'
 */
export function formatShortDateTime(i18n: SharedI18n, date?: string | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  return shortDateI18nStr(date, i18n);
}
