import gql from 'graphql-tag';
import { BlabDefBreadcrumbFragment } from '../blab-def/blab-def-breadcrumb.fragment';

export const BlabItemBreadcrumbFragment = gql`
  fragment BlabItemBreadcrumbFragment on BlabItemPreviewDto {
    id
    title
    blabDefId
    blabDef {
      ...BlabDefBreadcrumbFragment
    }
  }
  ${BlabDefBreadcrumbFragment}
`;
