import { DateFormat, Language } from '@t5s/shared/gql';
import { isThisYear, isToday, isTomorrow, isYesterday, withinTheNext7Days } from '@t5s/shared/util/date';
import { DatetimeI18n } from '../../../datetime.i18n';
import { ApplicableI18n } from './applicable-i18n.interface';
import { formatDate, formatDateViaLanguage } from './format-date';
import { shouldAbbreviateMonth } from './month-abbreviation';

function getMonthDateFormats(language: Language) {
  switch (language) {
    case Language.DE: {
      return { abbr: 'MMM', nonAbbr: 'MMMM' };
    }
    default: {
      return {
        abbr: 'MMM',
        nonAbbr: 'MMMM',
      };
    }
  }
}

function getMonthDateFormat(date: Date, language: Language): string {
  const shouldAbbreviate = shouldAbbreviateMonth({ language }, date);
  const monthDateFormats = getMonthDateFormats(language);
  return shouldAbbreviate ? `${monthDateFormats.abbr}` : monthDateFormats.nonAbbr;
}

function getDayMonthFormatStr(dateFormat: DateFormat, formattedMonth: string): string {
  switch (dateFormat) {
    case DateFormat.SLASH_MM_DD_YYYY: {
      return `'${formattedMonth}' d, yyyy`;
    }
    case DateFormat.SLASH_DD_MM_YYYY: {
      return `d '${formattedMonth}', yyyy`;
    }
    case DateFormat.DOT_DD_MM_YYYY: {
      return `d. '${formattedMonth}' yyyy`;
    }
  }
}

function getDayMonthFormatStrOmitYear(dateFormat: DateFormat, formattedMonth: string): string {
  switch (dateFormat) {
    case DateFormat.SLASH_MM_DD_YYYY: {
      return `'${formattedMonth}' d`;
    }
    case DateFormat.SLASH_DD_MM_YYYY: {
      return `d '${formattedMonth}'`;
    }
    case DateFormat.DOT_DD_MM_YYYY: {
      return `d. '${formattedMonth}'`;
    }
  }
}

function formatAbbrMonth(date: Date, i18n: ApplicableI18n): string {
  const { language } = i18n;

  const monthDateFormat = getMonthDateFormat(date, language);
  return formatDateViaLanguage(date, monthDateFormat, i18n);
}

function formatDateAbbrMonthOmitYear(i18n: ApplicableI18n, date: Date): string {
  const { dateFormat } = i18n;
  const formattedMonth = formatAbbrMonth(date, i18n);
  const formatStr = getDayMonthFormatStrOmitYear(dateFormat, formattedMonth);
  return formatDateViaLanguage(date, formatStr, i18n);
}

function formatDateAbbrMonth(i18n: ApplicableI18n, date: Date): string {
  const { dateFormat } = i18n;
  const formattedMonth = formatAbbrMonth(date, i18n);
  const formatStr = getDayMonthFormatStr(dateFormat, formattedMonth);
  return formatDate(date, formatStr, i18n);
}

export function formatReminderDate(i18n: ApplicableI18n, date: Date): string | undefined {
  // provide defaults, as time and date format do not matter here
  const { language } = i18n;
  const _i18n = { language };

  if (isYesterday(date)) {
    return DatetimeI18n.translate(i18n, DatetimeI18n.key.yesterday);
  }

  if (isToday(date)) {
    return DatetimeI18n.translate(i18n, DatetimeI18n.key.today);
  }

  if (isTomorrow(date)) {
    return DatetimeI18n.translate(i18n, DatetimeI18n.key.tomorrow);
  }

  let dateFormat: string;

  if (withinTheNext7Days(date)) {
    // within the next 7 days
    dateFormat = 'EEEE';
  } else if (isThisYear(date)) {
    // not within the next 7 days, but within current year
    return formatDateAbbrMonthOmitYear(i18n, date);
  } else {
    return formatDateAbbrMonth(i18n, date);
  }

  return formatDateViaLanguage(date, dateFormat, _i18n);
}
