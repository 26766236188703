import gql from 'graphql-tag';
import {
  BlabReferenceBlabDefPreviewDtoNoNestingFragment,
  BlabReferenceDtoNoNestingFragment,
  MultiRelationFlimDefDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const LightMultiRelationFlimDefDtoFragment = gql`
  fragment LightMultiRelationFlimDefDtoFragment on MultiRelationFlimDefDto {
    ...MultiRelationFlimDefDtoNoNesting
    blabReferences {
      ...BlabReferenceDtoNoNesting
      blabDef {
        ...BlabReferenceBlabDefPreviewDtoNoNesting
      }
    }
  }
  ${MultiRelationFlimDefDtoNoNestingFragment}

  ${BlabReferenceDtoNoNestingFragment}
  ${BlabReferenceBlabDefPreviewDtoNoNestingFragment}
`;
