import gql from 'graphql-tag';
import {
  ActiveBillingSubscriptionDtoNoNestingFragment,
  BillingSubscriptionActionNoNestingFragment,
  IncompleteBillingSubscriptionDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { SubscriptionInvoicePreviewFragment } from './subscription-invoice-preview.fragment';

export const GetBillingSubscriptionResultFragment = gql`
  fragment GetBillingSubscriptionResultFragment on GetBillingSubscriptionResult {
    ... on ActiveBillingSubscriptionDto {
      ...ActiveBillingSubscriptionDtoNoNesting
      requiresAction {
        ...BillingSubscriptionActionNoNesting
      }
      upcomingInvoice {
        ...SubscriptionInvoicePreviewFragment
      }
    }

    ... on IncompleteBillingSubscriptionDto {
      ...IncompleteBillingSubscriptionDtoNoNesting
      requiresAction {
        ...BillingSubscriptionActionNoNesting
      }
    }
  }
  ${ActiveBillingSubscriptionDtoNoNestingFragment}
  ${IncompleteBillingSubscriptionDtoNoNestingFragment}
  ${BillingSubscriptionActionNoNestingFragment}
  ${SubscriptionInvoicePreviewFragment}
`;

export const CreateBillingSubscriptionResultFragment = gql`
  fragment CreateBillingSubscriptionResultFragment on CreateBillingSubscriptionResult {
    ... on ActiveBillingSubscriptionDto {
      ...ActiveBillingSubscriptionDtoNoNesting
      requiresAction {
        ...BillingSubscriptionActionNoNesting
      }
    }

    ... on IncompleteBillingSubscriptionDto {
      ...IncompleteBillingSubscriptionDtoNoNesting
      requiresAction {
        ...BillingSubscriptionActionNoNesting
      }
    }
  }
  ${ActiveBillingSubscriptionDtoNoNestingFragment}
  ${IncompleteBillingSubscriptionDtoNoNestingFragment}
  ${BillingSubscriptionActionNoNestingFragment}
`;

export const ChangeBillingSubscriptionPlanResultFragment = gql`
  fragment ChangeBillingSubscriptionPlanResultFragment on ChangeBillingSubscriptionPlanResult {
    ... on ActiveBillingSubscriptionDto {
      ...ActiveBillingSubscriptionDtoNoNesting
      requiresAction {
        ...BillingSubscriptionActionNoNesting
      }
    }

    ... on IncompleteBillingSubscriptionDto {
      ...IncompleteBillingSubscriptionDtoNoNesting
      requiresAction {
        ...BillingSubscriptionActionNoNesting
      }
    }
  }
  ${ActiveBillingSubscriptionDtoNoNestingFragment}
  ${IncompleteBillingSubscriptionDtoNoNestingFragment}
  ${BillingSubscriptionActionNoNestingFragment}
`;
