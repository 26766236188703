import { NgModule } from '@angular/core';
// eslint-disable-next-line import/no-internal-modules
import { ApolloTestingModule } from 'apollo-angular/testing';
import { ApolloSubscriptionService } from './apollo-subscriptions';
import { SHARED_APOLLO_CLIENT_PROVIDER } from './providers';

/**
 * Data access Testing module that should be imported in client
 * integration tests instead of the `ClientDataAccessApolloGqlModule`. */
@NgModule({
  imports: [ApolloTestingModule],
  providers: [SHARED_APOLLO_CLIENT_PROVIDER, ApolloSubscriptionService],
})
export class ClientDataAccessApolloTestingModule {}
