import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import {
  HardwareBackButtonNoOverlayObservable,
  HardwareBackButtonObservable,
} from '@t5s/mobile-client/provider-token/hardware-back-button';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { RootEffects } from './root.effects';
import { rootReducer, rootStateKey } from './root.reducer';
import { selectOverlayOpen } from './root.selectors';

@NgModule({
  imports: [StoreModule.forFeature(rootStateKey, rootReducer), EffectsModule.forFeature([RootEffects])],
  providers: [
    {
      provide: HardwareBackButtonNoOverlayObservable,
      useFactory: (store$: Store, backButton$: HardwareBackButtonObservable): HardwareBackButtonNoOverlayObservable =>
        store$.select(selectOverlayOpen).pipe(
          filter((overlayOpen) => !overlayOpen),
          switchMap(() =>
            backButton$.pipe(takeUntil(store$.select(selectOverlayOpen).pipe(filter((overlayOpen) => overlayOpen)))),
          ),
        ),
      deps: [Store, HardwareBackButtonObservable],
    },
  ],
})
export class RootBusinessLogicModule {}
