import { Language, TimeFormat } from '@t5s/shared/gql';
import { ApplicableI18n } from '../applicable-i18n.interface';

export interface TimeFormats {
  normalized: string;
  standalone: string;
  short: string;
  workflowRunLog: string;
}

export const TimeFormatsDict: { [key in Language]: { [key in TimeFormat]: TimeFormats } } = {
  [Language.EN]: {
    [TimeFormat.HOURS_12]: { standalone: `h:mm`, normalized: 'h:mm a', short: `h:mm a`, workflowRunLog: `h:mm:ss a` },
    [TimeFormat.HOURS_24]: { standalone: `HH:mm`, normalized: 'HH:mm', short: `HH:mm`, workflowRunLog: `HH:mm:ss` },
  },
  [Language.DE]: {
    [TimeFormat.HOURS_12]: { standalone: `h:mm`, normalized: 'h:mm a', short: `h:mm a`, workflowRunLog: `h:mm:ss a` },
    [TimeFormat.HOURS_24]: {
      standalone: `HH:mm`,
      normalized: 'HH:mm',
      short: `HH:mm 'Uhr'`,
      workflowRunLog: `HH:mm:ss 'Uhr'`,
    },
  },
};

export function getTimeFormats({
  language = Language.EN,
  timeFormat = TimeFormat.HOURS_12,
}: Partial<ApplicableI18n>): TimeFormats {
  return TimeFormatsDict[language][timeFormat];
}
