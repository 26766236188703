import { BottomSheetState } from './bottom-sheet.component.state';
import { THRESHOLD_DELTA_Y, THRESHOLD_VELOCITY } from './bottom-sheet.constants';

export function getSheetHeightDocked(state: Omit<BottomSheetState, 'position'>): number {
  const { dockedHeight } = state;
  return dockedHeight;
}

export function getSheetHeightTop(
  state: Pick<BottomSheetState, 'viewportHeight' | 'safeAreaTop' | 'topDistance'>,
): number {
  const { viewportHeight, safeAreaTop, topDistance } = state;
  return viewportHeight - (safeAreaTop || 30) - topDistance;
}

export function getTopToBottomAnimationPlayTo(
  state: Omit<BottomSheetState, 'position'>,
  { deltaY, velocityY }: { deltaY: number; velocityY: number },
): 0 | 1 {
  if (state.gestureDisabled) {
    return 0;
  }

  const thresholdHeight = Math.min(THRESHOLD_DELTA_Y, getSheetHeightTop(state) - 5);

  if (velocityY > THRESHOLD_VELOCITY) {
    return 1;
  }

  if (velocityY < -THRESHOLD_VELOCITY) {
    return 0;
  }

  if (Math.abs(deltaY) > thresholdHeight) {
    return 1;
  }

  return 0;
}

export function getDockedToTopAnimationPlayTo(state: Pick<BottomSheetState, 'notExpandable'>): 0 | 1 {
  return state.notExpandable ? 0 : 1;
}

export function getDockedToBottomAnimationPlayTo(
  state: Omit<BottomSheetState, 'position'>,
  { deltaY, velocityY }: { deltaY: number; velocityY: number },
): 0 | 1 {
  const thresholdHeight = Math.min(THRESHOLD_DELTA_Y, getSheetHeightDocked(state) - 5);

  if (velocityY > THRESHOLD_VELOCITY) {
    return 1;
  }

  if (velocityY < -THRESHOLD_VELOCITY) {
    return 0;
  }

  if (Math.abs(deltaY) > thresholdHeight) {
    return 1;
  }

  return 0;
}
