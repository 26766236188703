import { createAction, props } from '@ngrx/store';
import { createErrorAction } from '@t5s/client/util/store';
import { NotificationConnectionValObj } from '@t5s/mobile-client/value-object/notification';
import { NotificationUnionType } from '@t5s/shared/gql';
import { DirectNotificationsState } from './direct-notifications.reducer';

const PREFIX = '[mobile-client-notification-direct]';

export const DirectNotificationsActions = {
  // Load notifications (background sync)
  loadSyncNotifications: createAction(`${PREFIX} load notifications`),
  loadSyncNotificationsSuccess: createAction(
    `${PREFIX} load notifications success`,
    props<NotificationConnectionValObj>(),
  ),
  loadSyncNotificationsException: createErrorAction(`${PREFIX} load notifications failed with exception`),

  // Load replace notifications (user explicitly wants to replace current notificaitons)
  loadReplaceNotifications: createAction(`${PREFIX} load (replace) notifications`),
  loadReplaceNotificationsSuccess: createAction(
    `${PREFIX} load (replace) notifications success`,
    props<NotificationConnectionValObj>(),
  ),
  loadReplaceNotificationsException: createErrorAction(`${PREFIX} load (replace) notifications failed with exception`),

  // Load more notifications
  requestLoadMoreNotifications: createAction(`${PREFIX} request load more notifications if available`),
  loadMoreNotifications: createAction(`${PREFIX} load more notifications`, props<{ cursor: string }>()),
  loadMoreNotificationsSuccess: createAction(
    `${PREFIX} load more notifications success`,
    props<NotificationConnectionValObj>(),
  ),
  loadMoreNotificationsException: createErrorAction(`${PREFIX} load more notifications failed with exception`),

  // Live subscription
  subscribeToNotifications: createAction(`${PREFIX} subscribe to notifications`),
  receiveNotifcationViaSubscription: createAction(
    `${PREFIX} received a notification via subscription`,
    props<{ notification: NotificationUnionType }>(),
  ),
  subscribeToNotificationsSuccess: createAction(`${PREFIX} subscribe to notifications success`),
  subscribeToNotificationsError: createErrorAction(`${PREFIX} subscribe to notifications error`),

  // State hydration
  hydrateState: createAction(`${PREFIX} Hydrate state`),
  hydrateStateSuccess: createAction(`${PREFIX} Hydrate state Success`, props<{ state: DirectNotificationsState }>()),
  hydrateStateNotAvailable: createAction(`${PREFIX} Hydration of state not available`),
  hydrateStateException: createAction(`${PREFIX} Hydration of state failed with exception`),
};
