export enum EmojiPickerCategory {
  FREQUENT = 'frequent',
  PEOPLE = 'people',
  NATURE = 'nature',
  FOODS = 'foods',
  ACTIVITY = 'activity',
  PLACES = 'places',
  OBJECTS = 'objects',
  SYMBOLS = 'symbols',
  FLAGS = 'flags',
}
