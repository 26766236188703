import gql from 'graphql-tag';
import { OrganizationActivityReplyDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { ReactionDtoFragment } from '../core';
import { UserProfilePreviewDtoFragment } from '../user-profile';

export const OrganizationActivityReplyDtoFragment = gql`
  fragment OrganizationActivityReplyDtoFragment on OrganizationActivityReplyDto {
    ...OrganizationActivityReplyDtoNoNesting
    user {
      ...UserProfilePreviewDtoFragment
    }
    reactions {
      ...ReactionDtoFragment
    }
  }
  ${OrganizationActivityReplyDtoNoNestingFragment}
  ${UserProfilePreviewDtoFragment}
  ${ReactionDtoFragment}
`;
