import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';

export function getFragmentNames(fragments?: any[]): string[] {
  if (!fragments || fragments.length === 0) {
    throw Error('No fragments provided!');
  }

  return fragments.map((fragment) => {
    const name = fragment?.definitions[0]?.name?.value;
    if (!name) {
      throw Error(`Could not get name for fragment: ${JSON.stringify(fragment)}`);
    }
    return name;
  });
}

export function importFragments(gqlStr: DocumentNode, fragments: DocumentNode[]): DocumentNode {
  fragments.forEach((fragment) => {
    gqlStr = gql`
      ${gqlStr}

      ${fragment}
    `;
  });

  return gqlStr;
}
