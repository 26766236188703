import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ActiveUserContextRootGuard } from '@t5s/mobile-client/business-logic/active-user-session';
import { BottomSheetBusinessLogicModule } from '@t5s/mobile-client/business-logic/bottom-sheet';
import { RootBusinessLogicModule } from '@t5s/mobile-client/business-logic/root';
import { LoggedInRootPageModule, LOGGED_IN_ROOT_PAGE_ROUTES } from '@t5s/mobile-client/page/logged-in/root';
import { DebugConsolePageModule } from '@t5s/mobile-client/page/modal/debug-console';
import { DeviceMediaPageModule } from '@t5s/mobile-client/page/sheet/device-media';
import { EmojiPickerPageModule } from '@t5s/mobile-client/page/sheet/emoji-picker';
import { ROUTE_FRAGMENT_PUBLIC } from '@t5s/mobile-client/readonly-constant/public';
import { ServiceSplashScreenModule } from '@t5s/mobile-client/service/splash-screen';
import { RootViewModule } from '@t5s/mobile-client/ui/view/root';
import { RootPage } from './root-page.component';

@NgModule({
  declarations: [RootPage],
  imports: [
    RootBusinessLogicModule,
    BottomSheetBusinessLogicModule,
    RootViewModule,
    LoggedInRootPageModule,
    ServiceSplashScreenModule,
    DebugConsolePageModule,
    EmojiPickerPageModule,
    DeviceMediaPageModule,
    PortalModule,
    IonicModule.forRoot({
      mode: 'ios',
      rippleEffect: false,
      scrollAssist: false,
      inputBlurring: false,
      inputShims: false,
      scrollPadding: false,
      hardwareBackButton: true,
      statusTap: true,
      sanitizerEnabled: true,
      persistConfig: false,
      keyboardHeight: 0, // If not 0, Ionic tries to apply keyboard padding to ion-content WTF
      hideCaretOnScroll: false, // If on true, Ionic hides the text cursor of inputs WTF
    }),
    RouterModule.forRoot(
      [
        {
          path: 'performance-lab',
          loadChildren: () => import('@t5s/mobile-client/page/performance-lab').then((m) => m.PerformanceLabPageModule),
        },
        {
          path: ROUTE_FRAGMENT_PUBLIC,
          loadChildren: () => import('@t5s/mobile-client/page/public/root').then((m) => m.PublicRootPageModule),
        },
        ...LOGGED_IN_ROOT_PAGE_ROUTES,
        {
          path: '',
          canActivate: [ActiveUserContextRootGuard],
          loadChildren: () => import('@t5s/mobile-client/page/public/root').then((m) => m.PublicRootPageModule),
        },
        { path: '**', redirectTo: ROUTE_FRAGMENT_PUBLIC },
      ],
      {
        preloadingStrategy: PreloadAllModules,
        onSameUrlNavigation: 'reload',
      },
    ),
  ],
  exports: [RootPage],
})
export class RootPageModule {}
