import gql from 'graphql-tag';
import {
  LastModifiedByFlimValDtoNoNestingFragment,
  WorkflowDefPreviewDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';
import { UserProfilePreviewDtoFragment } from '../../user-profile';

export const LastModifiedByFlimValFragment = gql`
  fragment LastModifiedByFlimVal on LastModifiedByFlimValDto {
    ...LastModifiedByFlimValDtoNoNesting
    lastModifiedByUser {
      ...UserProfilePreviewDtoFragment
    }
    lastModifiedByWorkflowDef {
      ...WorkflowDefPreviewDtoNoNesting
    }
  }
  ${LastModifiedByFlimValDtoNoNestingFragment}
  ${UserProfilePreviewDtoFragment}
  ${WorkflowDefPreviewDtoNoNestingFragment}
`;
