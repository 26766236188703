import gql from 'graphql-tag';
import {
  WorkflowRunCreatedSubscriptionUpdateNoNestingFragment,
  WorkflowRunUpdatedSubscriptionUpdateNoNestingFragment,
} from '../../../generated/graphql-fragments';
import { WorkflowRunLogsAppendedSubscriptionUpdateFragment } from './workflow-run-logs-appended-subscription-update.fragment';

export const WorkflowRunsSubscriptionUpdateFragment = gql`
  fragment WorkflowRunsSubscriptionUpdateFragment on WorkflowRunsSubscriptionUpdate {
    ... on WorkflowRunCreatedSubscriptionUpdate {
      ...WorkflowRunCreatedSubscriptionUpdateNoNesting
    }
    ... on WorkflowRunUpdatedSubscriptionUpdate {
      ...WorkflowRunUpdatedSubscriptionUpdateNoNesting
    }
    ... on WorkflowRunLogsAppendedSubscriptionUpdate {
      ...WorkflowRunLogsAppendedSubscriptionUpdateFragment
    }
  }
  ${WorkflowRunUpdatedSubscriptionUpdateNoNestingFragment}
  ${WorkflowRunCreatedSubscriptionUpdateNoNestingFragment}
  ${WorkflowRunLogsAppendedSubscriptionUpdateFragment}
`;
