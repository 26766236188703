import { Injectable, OnDestroy } from '@angular/core';
import { App, AppState } from '@capacitor/app';
import { DeviceService } from '@t5s/mobile-client/service/device';
import { ReplaySubject } from 'rxjs';
import { PlatformObservable } from '@t5s/mobile-client/provider-token/device';
import { tap } from 'rxjs/operators';

/** https://capacitorjs.com/docs/apis/app#getstate */
@Injectable()
export class ApplicationStateService implements OnDestroy {
  private readonly appState$$ = new ReplaySubject<AppState>(1);

  readonly appState$ = this.appState$$.asObservable();

  constructor(readonly device: DeviceService, readonly platform$: PlatformObservable) {
    device.deviceInfo$.subscribe(({ platform }) => {
      if (platform === 'ios' || platform === 'android') {
        try {
          void App.addListener('appStateChange', (state: AppState) => {
            this.appState$$.next(state);
          });
        } catch (e) {}
      }
    });

    // Ensure app state emits at least once
    try {
      void App.getState().then((state) => this.appState$$.next(state));
    } catch (e) {}
  }

  exitApp() {
    return this.platform$.pipe(
      tap(({ platform }) => {
        if (platform !== 'web') {
          void App.exitApp();
        }
      }),
    );
  }

  ngOnDestroy() {
    return App.removeAllListeners();
  }
}
