import gql from 'graphql-tag';
import {
  SingleUserFlimValDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
} from '../../../../generated/graphql-fragments';

export const SingleUserFlimValFragment = gql`
  fragment BlabItemsOverviewSingleUserFlimVal on SingleUserFlimValDto {
    ...SingleUserFlimValDtoNoNesting
    user {
      id
      email
      primaryName
      jobDescription
      profilePicture {
        ...ThumbnailDtoNoNesting
      }
    }
  }
  ${SingleUserFlimValDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
