export const reminderEnLangClient = {
  detail: {
    descPlaceholder: 'Add details',
    dueAtPlaceholder: 'Add due date',
    itemPlaceholder: 'Add item',
    titlePlaceholder: 'Add title',
  },
  displaySettings: {
    APP: 'Related app',
    DUE_DATE: 'Due date',
    USER_ORDER: 'My order',
  },
  menu: {
    archiveAllCompleted: 'Archive all completed',
    creationDate: 'Creation date',
    dueAt: 'Due date',
    hideArchived: 'Hide archived',
    myOrder: 'My order',
    relatedApp: 'Related app',
    showArchived: 'Show archived',
    sortBy: 'Sort by',
  },
  newReminderModal: {
    descriptionPlaceholder: 'Add details',
    titlePlaceholder: 'New reminder',
  },
  placeholder: {
    subline: `You've finished all your reminders.`,
    title: 'Awesome!',
  },
  sectionHeadlines: {
    archived: `Archived ({{count}})`,
    completed: `Completed ({{count}})`,
  },
  title: `Reminders`,
} as const;
