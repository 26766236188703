import gql from 'graphql-tag';
import { SharedWorkspaceDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const SharedWorkspaceDtoFragment = gql`
  fragment SharedWorkspaceDtoFragment on SharedWorkspaceDto {
    ...SharedWorkspaceDtoNoNesting
    blabDefs {
      id
      blabName
      itemName
      icon
      workspaceId
      position
    }
  }
  ${SharedWorkspaceDtoNoNestingFragment}
`;
