import { ChatConversationChatActivityUnionType } from '@t5s/mobile-client/value-object/chat-conversation';
import { ChatActivityDto, ChatActivityDtoEdge } from '@t5s/shared/gql';
import { replaceWhere } from '@t5s/shared/util/array';

export function upsertActivityIntoChatActivities(
  activityToUpsert: ChatActivityDto,
  chatActivities: ChatActivityDtoEdge[],
) {
  const activityEdgeToUpsert = { node: activityToUpsert, cursor: '' };
  if (chatActivities.find((reply) => reply.node.id === activityToUpsert.id)) {
    chatActivities = replaceWhere(
      chatActivities,
      (reply) => reply.node.id === activityToUpsert.id,
      activityEdgeToUpsert,
    );
  } else {
    chatActivities = [...chatActivities, activityEdgeToUpsert];
  }

  return chatActivities;
}

export function sortChatActivityUnionType(
  activity1: ChatConversationChatActivityUnionType,
  activity2: ChatConversationChatActivityUnionType,
): number {
  return new Date(activity1.createdAt).getTime() - new Date(activity2.createdAt).getTime();
}

export function sortChatActivityEdges(
  { node: activity1 }: ChatActivityDtoEdge,
  { node: activity2 }: ChatActivityDtoEdge,
): number {
  return new Date(activity1.createdAt).getTime() - new Date(activity2.createdAt).getTime();
}
