import gql from 'graphql-tag';
import {
  OrganizationProfileDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const OrganizationProfileFragment = gql`
  fragment OrganizationProfileFragment on OrganizationProfileDto {
    ...OrganizationProfileDtoNoNesting
    logo {
      ...ThumbnailDtoNoNesting
    }
  }
  ${OrganizationProfileDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
