import gql from 'graphql-tag';
import {
  MultiCategoryOptionDefDtoNoNestingFragment,
  MultiCategorySplitByDtoNoNestingFragment,
  MultiCategorySplitBySectionDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const MultiCategorySplitByDtoFragment = gql`
  fragment MultiCategorySplitByDto on MultiCategorySplitByDto {
    ...MultiCategorySplitByDtoNoNesting
    sections {
      ...MultiCategorySplitBySectionDtoNoNesting
      multiCategoryOptionDef {
        ...MultiCategoryOptionDefDtoNoNesting
      }
    }
  }
  ${MultiCategorySplitByDtoNoNestingFragment}
  ${MultiCategorySplitBySectionDtoNoNestingFragment}
  ${MultiCategoryOptionDefDtoNoNestingFragment}
`;
