import gql from 'graphql-tag';
import {
  SingleRelationSplitByDtoNoNestingFragment,
  SingleRelationSplitBySectionDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const SingleRelationSplitByDtoFragment = gql`
  fragment SingleRelationSplitByDto on SingleRelationSplitByDto {
    ...SingleRelationSplitByDtoNoNesting
    sections {
      ...SingleRelationSplitBySectionDtoNoNesting
      blabItem {
        id
        title
      }
    }
  }
  ${SingleRelationSplitByDtoNoNestingFragment}
  ${SingleRelationSplitBySectionDtoNoNestingFragment}
`;
