import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { formatTimeRange, isApplicableI18n, normalizeDate } from '../common';

export function formatCalendarTimeRange(
  i18n: SharedI18n,
  dateLeft: string | Date | undefined,
  dateRight?: string | Date | undefined,
): string | undefined {
  if (!dateLeft || !dateRight || !isApplicableI18n(i18n)) {
    return undefined;
  }

  dateLeft = normalizeDate(dateLeft);
  dateRight = normalizeDate(dateRight);

  return formatTimeRange(i18n, dateLeft, dateRight);
}
