export const replaceWhere = <T>(array: T[], predicateFn: (item: T) => boolean, newElement: T) => {
  const index = array.findIndex(predicateFn);

  if (index < 0) {
    return array;
  }

  const newArray = [...array];
  newArray[index] = newElement;

  return newArray;
};
