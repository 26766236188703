import {
  CreateMultiLinkFlimValEntryNestedCreationInput,
  CreateMultiLinkFlimValInput,
  MultiLinkFlimValDto,
  MultiLinkFlimValEntryDto,
} from '@t5s/shared/gql';

function getMultiLinkCreateNestedEntryInputFromDto(
  multiLinkEntry: MultiLinkFlimValEntryDto,
): CreateMultiLinkFlimValEntryNestedCreationInput | undefined {
  const { position, linkPreview } = multiLinkEntry;

  if (!linkPreview) {
    return undefined;
  }

  return { position, linkPreviewId: linkPreview.id };
}

export function getMultiLinkCreateInputFromDto(multiLinkFlimVal: MultiLinkFlimValDto): CreateMultiLinkFlimValInput {
  const { flimDefId } = multiLinkFlimVal;

  const multiLinkEntryDtos = multiLinkFlimVal.multiLinkEntries ?? [];
  const multiLinkEntries = multiLinkEntryDtos
    .map(getMultiLinkCreateNestedEntryInputFromDto)
    .filter((entry): entry is CreateMultiLinkFlimValEntryNestedCreationInput => entry !== undefined);

  return { flimDefId, multiLinkEntries };
}
