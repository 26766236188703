import gql from 'graphql-tag';
import {
  LastModifiedAtSplitByDtoNoNestingFragment,
  LastModifiedAtSplitBySectionDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const LastModifiedAtSplitByDtoFragment = gql`
  fragment LastModifiedAtSplitByDto on LastModifiedAtSplitByDto {
    ...LastModifiedAtSplitByDtoNoNesting
    sections {
      ...LastModifiedAtSplitBySectionDtoNoNesting
    }
  }
  ${LastModifiedAtSplitByDtoNoNestingFragment}
  ${LastModifiedAtSplitBySectionDtoNoNestingFragment}
`;
