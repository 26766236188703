import gql from 'graphql-tag';
import { ConvertSingleDateFlimToRangeDateFlimResultDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../../flim-def';

export const ConvertSingleDateFlimToRangeDateFlimResultDtoFragment = gql`
  fragment ConvertSingleDateFlimToRangeDateFlimResultDtoFragment on ConvertSingleDateFlimToRangeDateFlimResultDto {
    ...ConvertSingleDateFlimToRangeDateFlimResultDtoNoNesting

    flimDef {
      ...FullFlimDef
    }
  }
  ${ConvertSingleDateFlimToRangeDateFlimResultDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
