import gql from 'graphql-tag';
import { MultiAttachmentFlimValFragment, SingleAttachmentFlimValFragment } from './attachment';
import { CalculationFlimValFragment } from './calculation/calculation-flim-val.fragment';
import { MultiCategoryFlimValFragment, SingleCategoryFlimValFragment } from './category';
import { ChecklistFlimValFragment } from './checklist';
import { CreatedAtFlimValFragment } from './created-at';
import { RangeDateFlimValFragment, SingleDateFlimValFragment } from './date';
import { LastModifiedAtFlimValFragment } from './last-modified-at';
import { LastModifiedByFlimValFragment } from './last-modified-by';
import { MultiLinkFlimValFragment } from './multi-link';
import { MultiEmailFlimValFragment } from './email';
import { MultiPhoneFlimValFragment } from './phone';
import { NumberFlimValFragment } from './number';
import { MultiRelationFlimValFragment, SingleRelationFlimValFragment } from './relation';
import { StatusFlimValFragment } from './status';
import { MultiTextFlimValFragment, SingleTextFlimValFragment } from './text';
import { MultiUserFlimValFragment, SingleUserFlimValFragment } from './user';
import { MultiImageFlimValFragment } from './image';
import { SingleLocationFlimValFragment } from './location';
import { UniqueIdFlimValFragment } from './unique-id';
import { CreatedByFlimValFragment } from './created-by';

export const AllFlimValsFragment = gql`
  fragment AllFlimVals on FlimValDto {
    ... on SingleTextFlimValDto {
      ...SingleTextFlimVal
    }
    ... on MultiTextFlimValDto {
      ...MultiTextFlimVal
    }

    ... on SingleCategoryFlimValDto {
      ...SingleCategoryFlimVal
    }
    ... on MultiCategoryFlimValDto {
      ...MultiCategoryFlimVal
    }

    ... on SingleRelationFlimValDto {
      ...SingleRelationFlimVal
    }
    ... on MultiRelationFlimValDto {
      ...MultiRelationFlimVal
    }

    ... on SingleAttachmentFlimValDto {
      ...SingleAttachmentFlimVal
    }
    ... on MultiAttachmentFlimValDto {
      ...MultiAttachmentFlimVal
    }

    ... on SingleUserFlimValDto {
      ...SingleUserFlimVal
    }
    ... on MultiUserFlimValDto {
      ...MultiUserFlimVal
    }

    ... on SingleDateFlimValDto {
      ...SingleDateFlimVal
    }
    ... on RangeDateFlimValDto {
      ...RangeDateFlimVal
    }

    ... on ChecklistFlimValDto {
      ...ChecklistFlimVal
    }

    ... on MultiLinkFlimValDto {
      ...MultiLinkFlimVal
    }

    ... on CreatedAtFlimValDto {
      ...CreatedAtFlimVal
    }
    ... on CreatedByFlimValDto {
      ...CreatedByFlimVal
    }

    ... on NumberFlimValDto {
      ...NumberFlimVal
    }

    ... on LastModifiedAtFlimValDto {
      ...LastModifiedAtFlimVal
    }
    ... on LastModifiedByFlimValDto {
      ...LastModifiedByFlimVal
    }

    ... on StatusFlimValDto {
      ...StatusFlimVal
    }

    ... on CalculationFlimValDto {
      ...CalculationFlimVal
    }

    ... on MultiEmailFlimValDto {
      ...MultiEmailFlimVal
    }

    ... on MultiPhoneFlimValDto {
      ...MultiPhoneFlimVal
    }

    ... on MultiImageFlimValDto {
      ...MultiImageFlimVal
    }

    ... on SingleLocationFlimValDto {
      ...SingleLocationFlimVal
    }

    ... on UniqueIdFlimValDto {
      ...UniqueIdFlimVal
    }
  }

  ${SingleTextFlimValFragment}
  ${MultiTextFlimValFragment}

  ${SingleCategoryFlimValFragment}
  ${MultiCategoryFlimValFragment}

  ${SingleRelationFlimValFragment}
  ${MultiRelationFlimValFragment}

  ${SingleAttachmentFlimValFragment}
  ${MultiAttachmentFlimValFragment}

  ${SingleUserFlimValFragment}
  ${MultiUserFlimValFragment}

  ${SingleDateFlimValFragment}
  ${RangeDateFlimValFragment}

  ${ChecklistFlimValFragment}

  ${MultiLinkFlimValFragment}

  ${CreatedAtFlimValFragment}
  ${CreatedByFlimValFragment}

  ${NumberFlimValFragment}

  ${LastModifiedAtFlimValFragment}
  ${LastModifiedByFlimValFragment}

  ${StatusFlimValFragment}

  ${CalculationFlimValFragment}

  ${MultiEmailFlimValFragment}

  ${MultiPhoneFlimValFragment}

  ${MultiImageFlimValFragment}

  ${SingleLocationFlimValFragment}

  ${UniqueIdFlimValFragment}
`;
