import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { PushModule } from '@rx-angular/template/push';
import { LayoutDirectiveModule } from '@t5s/mobile-client/directive/layout';
import { LongpressDirectiveModule } from '@t5s/mobile-client/directive/press';
import { SafeAreaDirectiveModule } from '@t5s/mobile-client/directive/safe-area';
import { ScrollAnchorDirectiveModule } from '@t5s/mobile-client/directive/scroll-anchor';
import { TouchActiveDirectiveModule } from '@t5s/mobile-client/directive/touch-active';

@NgModule({
  exports: [
    CommonModule,
    PushModule,
    IonicModule,
    LongpressDirectiveModule,
    TouchActiveDirectiveModule,
    LayoutDirectiveModule,
    SafeAreaDirectiveModule,
    ScrollAnchorDirectiveModule,
  ],
})
export class ComponentCommonModule {}
