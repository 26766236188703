import {
  IOS_SAFE_AREA_BOTTOM_CSS_VARNAME,
  IOS_SAFE_AREA_LEFT_CSS_VARNAME,
  IOS_SAFE_AREA_RIGHT_CSS_VARNAME,
  IOS_SAFE_AREA_TOP_CSS_VARNAME,
} from '@t5s/mobile-client/readonly-constant/safe-area';
import { SafeAreaDimensions } from '@t5s/mobile-client/value-object/safe-area';
import { parseInteger } from '@t5s/shared/util/number';

function getPropertyPxValue(_document: Document, prop: string): number {
  try {
    const propertyPxInteger =
      parseInteger((getComputedStyle(_document.body).getPropertyValue(prop) ?? '').replace(/[^\d]/g, '')) ?? 0;

    return propertyPxInteger;
  } catch (e: unknown) {
    return 0;
  }
}

/** Attempts to read a safe area dimensions in px from DOM. Bottom value will be in [0,1,...,MAX], where MAX = 35
 *
 * This function yielded large values in the past for the `bottom` property (example: 2966666603088379) on certain devices, which led to the app
 * not being usable at all (TDEV-3312).
 */
export function readSafeAreaDimensionsFromDom(_document: Document): SafeAreaDimensions {
  return {
    top: getPropertyPxValue(_document, IOS_SAFE_AREA_TOP_CSS_VARNAME),
    right: getPropertyPxValue(_document, IOS_SAFE_AREA_RIGHT_CSS_VARNAME),
    bottom: Math.min(getPropertyPxValue(_document, IOS_SAFE_AREA_BOTTOM_CSS_VARNAME), 35),
    left: getPropertyPxValue(_document, IOS_SAFE_AREA_LEFT_CSS_VARNAME),
  };
}
