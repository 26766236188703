import { asyncScheduler } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

export const throttleTimeEmitInstantly = <T>(duration: number) =>
  throttleTime<T>(duration, asyncScheduler, { leading: true, trailing: false });

export const throttleTimeWithTrailing = <T>(duration: number) =>
  throttleTime<T>(duration, asyncScheduler, { leading: false, trailing: true });

export const throttleTimeEmitInstantlyWithTrailing = <T>(duration: number) =>
  throttleTime<T>(duration, asyncScheduler, { leading: true, trailing: true });
