import { Injectable, OnDestroy } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { ApplicationStateObservable } from '@t5s/mobile-client/provider-token/state';
import { from, Subject } from 'rxjs';
import { filter, share, switchMap, takeUntil, tap } from 'rxjs/operators';

@Injectable()
export class NetworkService implements OnDestroy {
  private readonly status$$ = new Subject<ConnectionStatus>();
  readonly status$ = this.status$$.asObservable().pipe(share());
  readonly destroy$$ = new Subject<undefined>();

  constructor(appState$: ApplicationStateObservable) {
    void Network.addListener('networkStatusChange', (status) => this.status$$.next(status));

    // Ensure network status at least emits once
    void Network.getStatus()
      .then((status) => this.status$$.next(status))
      .catch(() => {});

    // Check network status whenever the app is brought back to the foreground (Android sometimes falsely shows as offline when brought back to foregeround)
    appState$
      .pipe(
        filter(({ isActive }) => !!isActive),
        switchMap(() => from(Network.getStatus()).pipe(tap((status) => this.status$$.next(status)))),
        takeUntil(this.destroy$$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroy$$.next(undefined);
    return Network.removeAllListeners();
  }
}
