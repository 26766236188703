import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { NavigationSideEffect } from '@t5s/client/util/router';
import { ChatActions } from './chat.actions';

@Injectable()
export class ChatNavigation extends NavigationSideEffect {
  constructor(private readonly store$: Store) {
    super();
  }

  onEnter() {
    setTimeout(() => {
      this.store$.dispatch(ChatActions.loadConversations());
    }, 500);

    setTimeout(() => {
      this.store$.dispatch(ChatActions.subscribeToConversations());
    }, 750);
  }
}
