import { ChatConversationClientModel } from '@t5s/mobile-client/value-object/chat-conversation';
import { ChatActivityUnionType } from '@t5s/shared/gql';
import { TypeGuards } from '@t5s/shared/gql-type-guards/common';
import { replaceWhere } from '@t5s/shared/util/array';

export function getChatConversationAfterChatActivityReactionUpdate(
  chatConversation: ChatConversationClientModel,
  update: ChatActivityUnionType,
): ChatConversationClientModel {
  const { chatActivity } = chatConversation;

  if (!chatActivity) {
    return chatConversation;
  }

  let { chatActivities } = chatActivity;

  if (TypeGuards.isChatActivityDeletedDto(update)) {
    chatActivities = chatActivities.filter((reply) => reply.node.id !== update.id);
    return { ...chatConversation, chatActivity: { ...chatActivity, chatActivities } };
  }

  const activityToUpsert = update as ChatActivityUnionType;
  const activityEdgeToUpsert = { node: activityToUpsert, cursor: '' };
  if (chatActivities.find((reply) => reply.node.id === activityToUpsert.id)) {
    chatActivities = replaceWhere(
      chatActivities,
      (reply) => reply.node.id === activityToUpsert.id,
      activityEdgeToUpsert,
    );
  } else {
    chatActivities = [...chatActivities, activityEdgeToUpsert];
  }

  return { ...chatConversation, chatActivity: { ...chatActivity, chatActivities } };
}
