import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, Output, ViewChild } from '@angular/core';
import { Animation, AnimationController, Gesture, GestureController } from '@ionic/angular';
import {
  ComponentStyle,
  establishStackingContext,
  exactHeight,
  exactWidth,
  firstChild,
  flex1Vertical,
  flexColumn,
  fullWidth,
  nthChild,
  percent,
  px,
  rgba,
  spread,
  translateY,
} from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { SafeAreaDimensionsObservable } from '@t5s/mobile-client/provider-token/safe-area';
import { ViewportDimensionsObservable } from '@t5s/mobile-client/provider-token/viewport-dimensions';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { BottomSheetPosition } from '@t5s/mobile-client/value-object/bottom-sheet';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { BottomSheetState } from './bottom-sheet.component.state';
import {
  BACKDROP_OPACITY,
  BOUNCE_BACK_ANIMATION_MS,
  DEFAULT_DOCKED_HEIGHT,
  FAST_ANIMATION_MS,
  SLOW_ANIMATION_MS,
} from './bottom-sheet.constants';
import {
  getDockedToBottomAnimationPlayTo,
  getDockedToTopAnimationPlayTo,
  getSheetHeightDocked,
  getSheetHeightTop,
  getTopToBottomAnimationPlayTo,
} from './bottom-sheet.utils';

@Component({
  selector: 't5s-bottom-sheet',
  template: `
    <div [class]="containerClass" [style.pointer-events]="(open$ | push) === true ? undefined : 'none'">
      <!-- Backdrop -->
      <div #backdrop [class]="backdropClass" (t5sPressDisableLongpress)="close()"> </div>

      <!-- Sheet -->
      <div #sheet [class]="bodyClass$ | push">
        <!-- Drag handle -->
        <div></div>

        <!-- Content -->
        <div>
          <div #content>
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostSpread],
})
export class BottomSheetComponent extends RxComponent<BottomSheetState> implements AfterViewInit {
  constructor(
    viewport$: ViewportDimensionsObservable,
    safeArea$: SafeAreaDimensionsObservable,
    private readonly elRef: ElementRef<HTMLElement>,
    private readonly animationCtrl: AnimationController,
    private readonly gestureCtrl: GestureController,
  ) {
    super();
    this.set({
      position: BottomSheetPosition.IS_BOTTOM,
      dockedHeightConfig: DEFAULT_DOCKED_HEIGHT,
      topDistance: 50,
      notExpandable: false,
    });

    this.connect(viewport$.pipe(map(({ height }) => ({ viewportHeight: height }))));
    this.connect(safeArea$.pipe(map(({ top }) => ({ safeAreaTop: top }))));

    // Open after dockedHeight has been determined
    this.hold(this.open$$.pipe(switchMap(() => this.select(selectSlice(['dockedHeight']), take(1)))), () => {
      this._open();
    });

    // Destroy objects on close
    this.hold(
      this.select(
        selectSlice(['position']),
        filter(({ position }) => position === BottomSheetPosition.IS_BOTTOM),
      ),
      () => this.destroyObjects(),
    );

    // Set dockedHeight from config
    this.connect(
      this.select(
        selectSlice(['contentEl', 'dockedHeightConfig']),
        filter(({ dockedHeightConfig }) => dockedHeightConfig !== 'fit-content'),
        map(({ dockedHeightConfig }) => ({ dockedHeight: dockedHeightConfig as number })),
      ),
    );

    // Calculate dockedHeight for fit-content
    const contentResized$$ = new Subject<undefined>();
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error, @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const contentResizeObserver = new ResizeObserver(() => contentResized$$.next(undefined));
    this.hold(
      this.select(
        selectSlice(['dockedHeightConfig', 'contentEl']),
        filter(({ dockedHeightConfig }) => dockedHeightConfig === 'fit-content'),
        take(1),
        map(({ contentEl }) => contentResizeObserver.observe(contentEl)),
        switchMap(() =>
          contentResized$$.pipe(
            take(1),
            tap(() => {
              const contentHeight = this.get().contentEl?.clientHeight;

              const dockedHeight = Math.min(
                getSheetHeightTop(this.get()),
                contentHeight ? contentHeight + 17 : DEFAULT_DOCKED_HEIGHT,
              );
              this.set({
                dockedHeight,
              });
            }),
            tap(() => contentResizeObserver.disconnect()),
          ),
        ),
      ),
    );
  }

  @ViewChild('backdrop') backdropRef?: ElementRef<HTMLDivElement>;
  @ViewChild('sheet') sheetRef?: ElementRef<HTMLDivElement>;
  @ViewChild('content') contentRef?: ElementRef<HTMLDivElement>;

  @Input() set dockedHeight(dockedHeightConfig: number | 'fit-content' | Observable<number | 'fit-content'>) {
    this.setProperty('dockedHeightConfig', dockedHeightConfig);
  }

  @Input() set gestureDisabled(gestureDisabled: boolean | Observable<boolean>) {
    this.setProperty('gestureDisabled', gestureDisabled);
  }

  @Input() set notExpandable(notExpandable: boolean | Observable<boolean>) {
    this.setProperty('notExpandable', notExpandable);
  }

  @Output() positionChange: Observable<{ position: BottomSheetPosition }> = this.select(selectSlice(['position']));

  private readonly open$$ = new Subject<undefined>();

  private dockedGesture?: Gesture;
  private topGesture?: Gesture;
  private openAnimation?: Animation;

  private heightTopToBottomAnimation?: Animation;
  private backdropTopToBottomAnimation?: Animation;

  private heightDockedToTopAnimation?: Animation;
  private heightDockedToBottomAnimation?: Animation;
  private backdropDockedToBottomAnimation?: Animation;

  readonly open$ = this.select(
    selectSlice(['position']),
    map(({ position }) => position !== BottomSheetPosition.IS_BOTTOM),
    distinctUntilChanged(),
  );

  readonly containerClass = tss({
    ...spread,
    ...establishStackingContext,
    overflow: 'hidden',
    pointerEvents: 'all',
  });

  readonly backdropClass = tss({
    ...spread,
  });

  readonly bodyClass$ = this.select(
    selectSlice(['viewportHeight', 'safeAreaTop', 'topDistance', 'dockedHeightConfig']),
    map((state) =>
      tss({
        position: 'absolute',
        top: percent(100),
        left: px(0),
        ...fullWidth,
        ...flexColumn,
        ...exactHeight(getSheetHeightTop(state)),
        borderTopLeftRadius: px(35),
        borderTopRightRadius: px(35),
        backgroundColor: ThemeColorVar.lightest,
        overflow: 'hidden',
        ...firstChild({
          flex: 0,
          marginTop: px(6),
          marginBottom: px(6),
          ...exactHeight(5),
          ...exactWidth(35),
          marginLeft: 'auto',
          marginRight: 'auto',
          backgroundColor: ThemeColorVar.light,
          borderRadius: px(2.5),
          pointerEvents: 'none',
        }),
        ...nthChild(2, {
          ...fullWidth,
          ...flex1Vertical,
          ...firstChild({
            ...fullWidth,
            height: state.dockedHeightConfig === 'fit-content' ? 'fit-content' : percent(100),
          }),
        }),
      }),
    ),
  );

  createDockedToTopAnimation() {
    const sheet = this.sheetRef?.nativeElement;
    const state = this.get();

    if (!sheet) {
      return;
    }

    this.heightDockedToTopAnimation = this.animationCtrl
      .create('bottom-sheet-translate-y-top-animation')
      .addElement(sheet)
      .duration(SLOW_ANIMATION_MS)
      .easing('ease-out')
      .keyframes([
        {
          offset: 0,
          transform: translateY(-getSheetHeightDocked(state)),
        },
        {
          offset: 1,
          transform: translateY(-getSheetHeightTop(state)),
        },
      ])
      .onFinish((playTo) => {
        this.set({ position: playTo === 1 ? BottomSheetPosition.IS_TOP : BottomSheetPosition.IS_DOCKED });
        if (playTo === 1) {
          this.createTopGesture();
        }
      });
  }

  createDockedToBottomAnimation() {
    const sheet = this.sheetRef?.nativeElement;
    const backdrop = this.backdropRef?.nativeElement;
    const state = this.get();

    if (!sheet || !backdrop) {
      return;
    }

    this.backdropDockedToBottomAnimation = this.animationCtrl
      .create('bottom-sheet-backdrop-animation')
      .addElement(backdrop)
      .duration(FAST_ANIMATION_MS)
      .fromTo('background', rgba(0, 0, 0, BACKDROP_OPACITY), rgba(0, 0, 0, 0));

    this.heightDockedToBottomAnimation = this.animationCtrl
      .create('bottom-sheet-translate-y-animation')
      .addElement(sheet)
      .duration(FAST_ANIMATION_MS)
      .keyframes([
        {
          offset: 0,
          transform: translateY(-getSheetHeightDocked(state)),
        },
        {
          offset: 1,
          transform: translateY(0),
        },
      ])
      .onFinish((playTo) => {
        this.set({ position: playTo === 1 ? BottomSheetPosition.IS_BOTTOM : BottomSheetPosition.IS_DOCKED });
      });
  }

  createTopToBottomAnimation() {
    const sheet = this.sheetRef?.nativeElement;
    const backdrop = this.backdropRef?.nativeElement;
    const state = this.get();

    if (!sheet || !backdrop) {
      return;
    }

    this.backdropTopToBottomAnimation = this.animationCtrl
      .create('bottom-sheet-backdrop-animation')
      .addElement(backdrop)
      .duration(FAST_ANIMATION_MS)
      .fromTo('background', rgba(0, 0, 0, BACKDROP_OPACITY), rgba(0, 0, 0, 0));

    this.heightTopToBottomAnimation = this.animationCtrl
      .create('bottom-sheet-translate-y-animation')
      .addElement(sheet)
      .duration(FAST_ANIMATION_MS)
      .keyframes([
        {
          offset: 0,
          transform: translateY(-getSheetHeightTop(state)),
        },
        {
          offset: 1,
          transform: translateY(0),
        },
      ])
      .onFinish((playTo) => {
        this.set({ position: playTo === 1 ? BottomSheetPosition.IS_BOTTOM : BottomSheetPosition.IS_TOP });
      });
  }

  createDockedGesture() {
    const sheet = this.sheetRef?.nativeElement;
    const backdrop = this.backdropRef?.nativeElement;
    const state = this.get();

    this.createDockedToTopAnimation();
    this.createDockedToBottomAnimation();

    const heightDockedToBottomAnimation = this.heightDockedToBottomAnimation;
    const backdropDockedToBottomAnimation = this.backdropDockedToBottomAnimation;
    const heightDockedToTopAnimation = this.heightDockedToTopAnimation;

    if (
      !sheet ||
      !backdrop ||
      !heightDockedToBottomAnimation ||
      !backdropDockedToBottomAnimation ||
      !heightDockedToTopAnimation
    ) {
      return;
    }

    let direction: 'up' | 'down';

    this.dockedGesture = this.gestureCtrl.create({
      gestureName: 'bottom-sheet-gesture',
      el: this.elRef.nativeElement,
      blurOnStart: true,
      direction: 'y',
      gesturePriority: 100,
      passive: true,
      maxAngle: 180,
      onWillStart: async ({ deltaY }) => {
        if (deltaY < 0) {
          direction = 'up';
          heightDockedToTopAnimation.progressStart(true, 0);
          this.set({ position: BottomSheetPosition.WILL_BE_TOP });
        } else {
          direction = 'down';
          heightDockedToBottomAnimation.progressStart(true, 0);
          backdropDockedToBottomAnimation.progressStart(true, 0);
          this.set({ position: BottomSheetPosition.WILL_BE_BOTTOM });
        }
      },
      onMove: ({ deltaY }) => {
        if (deltaY < 0) {
          if (direction === 'down') {
            direction = 'up';
            this.set({ position: BottomSheetPosition.WILL_BE_TOP });
            heightDockedToBottomAnimation.stop();
            backdropDockedToBottomAnimation.stop();
            heightDockedToTopAnimation.progressStart(true, 0);
          }

          const progress = Math.abs(deltaY / getSheetHeightTop(state));
          heightDockedToTopAnimation.progressStep(progress);
        } else {
          if (direction === 'up') {
            direction = 'down';
            this.set({ position: BottomSheetPosition.WILL_BE_BOTTOM });
            heightDockedToTopAnimation.stop();
            heightDockedToBottomAnimation.progressStart(true, 0);
            backdropDockedToBottomAnimation.progressStart(true, 0);
          }
          heightDockedToBottomAnimation.progressStep(Math.abs(deltaY / getSheetHeightDocked(state)));
          backdropDockedToBottomAnimation.progressStep(Math.abs(deltaY / (getSheetHeightTop(state) + 100)));
        }
      },
      onEnd: ({ deltaY, velocityY }) => {
        const state = this.get();
        if (deltaY < 0) {
          const playTo = getDockedToTopAnimationPlayTo(state);
          const progress = Math.abs(deltaY / getSheetHeightTop(state));
          if (playTo === 0) {
            heightDockedToTopAnimation
              .easing('ease-in-out')
              .duration(SLOW_ANIMATION_MS)
              .progressStep(progress)
              .progressEnd(playTo, progress);
          } else {
            heightDockedToTopAnimation.duration(SLOW_ANIMATION_MS).progressEnd(playTo, progress);
          }
        } else {
          const playTo = getDockedToBottomAnimationPlayTo(state, { deltaY, velocityY });
          if (playTo === 0) {
            heightDockedToBottomAnimation
              .duration(BOUNCE_BACK_ANIMATION_MS)
              .progressEnd(playTo, Math.abs(deltaY / getSheetHeightDocked(state)));
            backdropDockedToBottomAnimation
              .duration(BOUNCE_BACK_ANIMATION_MS)
              .progressEnd(playTo, Math.abs(deltaY / (getSheetHeightTop(state) + 100)));
          } else {
            heightDockedToBottomAnimation
              .duration(FAST_ANIMATION_MS)
              .progressEnd(playTo, Math.abs(deltaY / getSheetHeightDocked(state)));
            backdropDockedToBottomAnimation
              .duration(FAST_ANIMATION_MS)
              .progressEnd(playTo, Math.abs(deltaY / (getSheetHeightTop(state) + 100)));
          }
        }
      },
    });

    this.topGesture?.enable(false);
    this.dockedGesture.enable(true);
  }

  createTopGesture() {
    this.createTopToBottomAnimation();

    const sheet = this.sheetRef?.nativeElement;
    const backdrop = this.backdropRef?.nativeElement;
    const backdropTopToBottomAnimation = this.backdropTopToBottomAnimation;
    const heightTopToBottomAnimation = this.heightTopToBottomAnimation;

    if (!sheet || !backdrop || !backdropTopToBottomAnimation || !heightTopToBottomAnimation) {
      return;
    }

    this.topGesture = this.gestureCtrl.create({
      gestureName: 'bottom-sheet-top-gesture',
      el: this.elRef.nativeElement,
      blurOnStart: true,
      direction: 'y',
      gesturePriority: 105,
      passive: true,
      maxAngle: 180,
      onWillStart: async () => {
        heightTopToBottomAnimation.progressStart(true, 0);
        backdropTopToBottomAnimation.progressStart(true, 0);
        this.set({ deltaYWhileGestureDisabled: this.get().gestureDisabled ? -1 : 0 });
      },
      onMove: ({ deltaY, velocityY }) => {
        const { gestureDisabled } = this.get();

        if (deltaY <= 0 || gestureDisabled) {
          return;
        }

        this.set({ position: BottomSheetPosition.WILL_BE_BOTTOM });

        let { deltaYWhileGestureDisabled } = this.get();
        if (deltaYWhileGestureDisabled === -1) {
          deltaYWhileGestureDisabled = deltaY + velocityY * 10;
          this.set({ deltaYWhileGestureDisabled });
        }

        const state = this.get();
        const _deltaY = deltaY - (deltaYWhileGestureDisabled ?? 0);
        heightTopToBottomAnimation.progressStep(Math.max(0, _deltaY / getSheetHeightTop(state)));
        backdropTopToBottomAnimation.progressStep(Math.max(0, _deltaY / (getSheetHeightTop(state) + 100)));
      },
      onEnd: ({ deltaY, velocityY }) => {
        const state = this.get();
        const _deltaY = deltaY - (state.deltaYWhileGestureDisabled ?? 0);

        if (_deltaY <= 0 || state.gestureDisabled) {
          this.set({ position: BottomSheetPosition.IS_TOP });
          return;
        }
        const playTo = getTopToBottomAnimationPlayTo(state, { deltaY, velocityY });

        if (playTo === 0) {
          heightTopToBottomAnimation
            .duration(SLOW_ANIMATION_MS)
            .progressEnd(playTo, Math.abs(_deltaY / getSheetHeightTop(state)));
          backdropTopToBottomAnimation
            .duration(SLOW_ANIMATION_MS)
            .progressEnd(playTo, Math.abs(_deltaY / (getSheetHeightTop(state) + 100)));
        } else {
          heightTopToBottomAnimation
            .duration(FAST_ANIMATION_MS)
            .progressEnd(playTo, Math.abs(_deltaY / getSheetHeightTop(state)));
          backdropTopToBottomAnimation
            .duration(FAST_ANIMATION_MS)
            .progressEnd(playTo, Math.abs(_deltaY / (getSheetHeightTop(state) + 100)));
        }
      },
    });

    this.dockedGesture?.enable(false);
    this.topGesture.enable(true);
  }

  open() {
    this.open$$.next(undefined);
  }

  private _open() {
    const { position, dockedHeight } = this.get();
    if (position !== BottomSheetPosition.IS_BOTTOM) {
      return;
    }

    this.set({ position: BottomSheetPosition.WILL_BE_DOCKED });

    const sheet = this.sheetRef?.nativeElement;
    const backdrop = this.backdropRef?.nativeElement;

    if (!sheet || !backdrop) {
      return;
    }

    const backdropAnimation = this.animationCtrl
      .create('bottom-sheet-backdrop-animation')
      .addElement(backdrop)
      .duration(200)
      .fromTo('background', rgba(0, 0, 0, 0), rgba(0, 0, 0, BACKDROP_OPACITY));

    const sheetAnimation = this.animationCtrl
      .create('bottom-sheet-translate-y-animation')
      .addElement(sheet)
      .duration(SLOW_ANIMATION_MS)
      .easing('ease-out')
      .keyframes([
        {
          offset: 0,
          transform: translateY(0),
        },
        {
          offset: 1,
          transform: translateY(-dockedHeight),
        },
      ]);

    this.openAnimation = this.animationCtrl
      .create('bottom-sheet-open-animation')
      .addAnimation([backdropAnimation, sheetAnimation])
      .onFinish(() => {
        this.set({ position: BottomSheetPosition.IS_DOCKED });
        this.createDockedGesture();
      });

    this.openAnimation?.play();
  }

  expand() {
    const { position, notExpandable } = this.get();
    if (notExpandable || position !== BottomSheetPosition.IS_DOCKED) {
      return;
    }

    this.set({ position: BottomSheetPosition.WILL_BE_TOP });
    this.createDockedToTopAnimation();
    this.heightDockedToTopAnimation?.play();
  }

  close() {
    const { position, ...state } = this.get();
    if (
      [
        BottomSheetPosition.IS_BOTTOM,
        BottomSheetPosition.WILL_BE_BOTTOM,
        BottomSheetPosition.WILL_BE_DOCKED,
        BottomSheetPosition.WILL_BE_TOP,
      ].includes(position)
    ) {
      return;
    }

    this.set({ position: BottomSheetPosition.WILL_BE_BOTTOM });

    const sheet = this.sheetRef?.nativeElement;
    const backdrop = this.backdropRef?.nativeElement;

    if (!sheet || !backdrop) {
      return;
    }

    const height =
      position === BottomSheetPosition.IS_DOCKED ? -getSheetHeightDocked(state) : -getSheetHeightTop(state);

    const backdropAnimation = this.animationCtrl
      .create('bottom-sheet-close-backdrop-animation')
      .addElement(backdrop)
      .duration(FAST_ANIMATION_MS)
      .fromTo('background', rgba(0, 0, 0, BACKDROP_OPACITY), rgba(0, 0, 0, 0));

    const sheetAnimation = this.animationCtrl
      .create('bottom-sheet-close-height-animation')
      .addElement(sheet)
      .duration(FAST_ANIMATION_MS)
      .keyframes([
        {
          offset: 0,
          transform: translateY(height),
        },
        {
          offset: 1,
          transform: translateY(0),
        },
      ]);

    void this.animationCtrl
      .create('bottom-sheet-close-animation')
      .addAnimation([backdropAnimation, sheetAnimation])
      .onFinish(() => {
        this.set({ position: BottomSheetPosition.IS_BOTTOM });
      })
      .play();
  }

  destroyObjects() {
    this.dockedGesture?.destroy();
    this.topGesture?.destroy();
    this.openAnimation?.destroy();
    this.heightTopToBottomAnimation?.destroy();
    this.backdropTopToBottomAnimation?.destroy();
    this.heightDockedToTopAnimation?.destroy();
    this.heightDockedToBottomAnimation?.destroy();
    this.backdropDockedToBottomAnimation?.destroy();
  }

  ngAfterViewInit() {
    const contentEl = this.contentRef?.nativeElement;
    if (!contentEl) {
      throw new Error(`Could not find content div viewchild!`);
    }

    this.set({ contentEl });
  }
}
