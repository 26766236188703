import gql from 'graphql-tag';
import {
  WorkflowDefWithLookupDtoNoNestingFragment,
  WorkflowWebhookLastPayloadDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { UserProfilePreviewDtoFragment } from '../user-profile/user-profile-preview-dto.fragment';
import { WorkflowDefLookupDtoFragment } from './workflow-def-lookup-dto.fragment';

export const WorkflowDefWithLookupDtoFragment = gql`
  fragment WorkflowDefWithLookupDtoFragment on WorkflowDefWithLookupDto {
    ...WorkflowDefWithLookupDtoNoNesting
    createdByUser {
      ...UserProfilePreviewDtoFragment
    }
    lastModifiedByUser {
      ...UserProfilePreviewDtoFragment
    }
    lookup {
      ...WorkflowDefLookupDtoFragment
    }
    webhookLastPayload {
      ...WorkflowWebhookLastPayloadDtoNoNesting
    }
  }
  ${WorkflowDefWithLookupDtoNoNestingFragment}
  ${WorkflowWebhookLastPayloadDtoNoNestingFragment}
  ${WorkflowDefLookupDtoFragment}
  ${UserProfilePreviewDtoFragment}
`;
