import { ChangeDetectionStrategy, Component } from '@angular/core';
import { establishStackingContext } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';

@Component({
  selector: 't5s-loading-spinner',
  template: `<ion-spinner [class]="spinnerClass"></ion-spinner> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingSpinnerComponent {
  readonly spinnerClass = tss({
    '--color': ThemeColorVar.darker,
    ...establishStackingContext,
  });
}
