import { NgModule } from '@angular/core';
import { ApplicationInfoObservable } from '@t5s/mobile-client/provider-token/application-info';
import { ApplicationInfoService, ServiceApplicationInfoModule } from '@t5s/mobile-client/service/application-info';
import { map } from 'rxjs/operators';

@NgModule({
  imports: [ServiceApplicationInfoModule],
  providers: [
    {
      provide: ApplicationInfoObservable,
      useFactory: (appInfoService: ApplicationInfoService): ApplicationInfoObservable =>
        appInfoService.getInfo().pipe(map((appInfo) => ({ appInfo }))),
      deps: [ApplicationInfoService],
    },
  ],
})
export class ProviderApplicationInfoModule {}
