import gql from 'graphql-tag';
import {
  CreatedByFlimValDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
  UserProfilePreviewDtoNoNestingFragment,
} from '../../../../generated/graphql-fragments';
import { BlabItemsOverviewWorkflowDefPreviewFragment } from '../blab-items-overview-workflow-def-preview.fragment';

export const CreatedByFlimValFragment = gql`
  fragment BlabItemsOverviewCreatedByFlimVal on CreatedByFlimValDto {
    ...CreatedByFlimValDtoNoNesting
    createdByUser {
      ...UserProfilePreviewDtoNoNesting
      profilePicture {
        ...ThumbnailDtoNoNesting
      }
    }
    createdByWorkflowDef {
      ...BlabItemsOverviewWorkflowDefPreview
    }
  }
  ${CreatedByFlimValDtoNoNestingFragment}
  ${UserProfilePreviewDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
  ${BlabItemsOverviewWorkflowDefPreviewFragment}
`;
