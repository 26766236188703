import { RxDirective } from '@t5s/client/util/rx';
import { Subject } from 'rxjs';

export abstract class Page<T extends object = {}> extends RxDirective<T> {
  private willEnter$$ = new Subject<boolean>();
  protected willEnter$ = this.willEnter$$.asObservable();

  private didEnter$$ = new Subject<boolean>();
  protected didEnter$ = this.didEnter$$.asObservable();

  private willLeave$$ = new Subject<boolean>();
  protected willLeave$ = this.willLeave$$.asObservable();

  private didLeave$$ = new Subject<boolean>();
  protected didLeave$ = this.didLeave$$.asObservable();

  protected ionViewWillEnter() {
    this.willEnter$$.next(true);
  }

  protected ionViewDidEnter() {
    this.didEnter$$.next(true);
  }

  protected ionViewWillLeave() {
    this.willLeave$$.next(true);
  }

  protected ionViewDidLeave() {
    this.didLeave$$.next(true);
  }
}
