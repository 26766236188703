import { createReducer, on } from '@ngrx/store';
import { FilesViewerFileLoadingState } from '@t5s/mobile-client/value-object/files-viewer';
import { FileAttachmentPreviewActions } from './file-attachment-preview.actions';

export const fileAttachmentPreviewStateKey = 'fileAttachmentPreview';

const { PENDING, LOADING, LOADED, EXCEPTION } = FilesViewerFileLoadingState;

export interface FileAttachmentPreviewState {
  openFileLoading: FilesViewerFileLoadingState;
  openFileWithDialogLoading: FilesViewerFileLoadingState;
}

const initialState: FileAttachmentPreviewState = {
  openFileLoading: PENDING,
  openFileWithDialogLoading: PENDING,
};

export const fileAttachmentPreviewReducer = createReducer(
  initialState,
  on(FileAttachmentPreviewActions.reset, () => ({ ...initialState })),
  on(FileAttachmentPreviewActions.openFileAttachmentPreviewModal, () => ({ ...initialState })),

  on(FileAttachmentPreviewActions.openFile, (state) => ({ ...state, openFileLoading: LOADING })),
  on(FileAttachmentPreviewActions.openFileRetrievedFile, (state) => ({ ...state, openFileLoading: LOADED })),
  on(FileAttachmentPreviewActions.openFileException, (state) => ({ ...state, openFileLoading: EXCEPTION })),

  on(FileAttachmentPreviewActions.showOpenFileWith, (state) => ({ ...state, openFileWithDialogLoading: LOADING })),
  on(FileAttachmentPreviewActions.showOpenFileWithSuccess, (state) => ({
    ...state,
    openFileWithDialogLoading: LOADED,
  })),
  on(FileAttachmentPreviewActions.showOpenFileWithException, (state) => ({
    ...state,
    openFileWithDialogLoading: EXCEPTION,
  })),
);
