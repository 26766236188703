import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { DatetimeI18n } from '../../../datetime.i18n';
import { formatDateViaLanguage, isApplicableI18n } from '../common';

export function formatCalendarWeekShort(i18n: SharedI18n, startDate: Date): string | undefined {
  if (!startDate || !isApplicableI18n(i18n)) {
    return undefined;
  }

  const shortFormattedYear = formatDateViaLanguage(startDate, 'yy', i18n);
  const formattedWeek = formatDateViaLanguage(startDate, 'I', i18n);

  return DatetimeI18n.translate(i18n, {
    key: DatetimeI18n.key.week,
    params: { week: `${formattedWeek}`, year: `${shortFormattedYear}` },
  });
}
