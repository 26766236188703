import gql from 'graphql-tag';
import { NotificationBlabItemActivityDtoFragmentV2 } from './blab-item-activity-dto.fragment';

export const BlabItemCreatedActivityFragment = gql`
  fragment NotificationBlabItemCreatedActivityFragmentV2 on BlabItemCreatedActivityDto {
    ...NotificationBlabItemActivityDtoFragmentV2
    creationType
  }
  ${NotificationBlabItemActivityDtoFragmentV2}
`;
