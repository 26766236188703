import gql from 'graphql-tag';
import {
  MultiEmailFlimValDtoNoNestingFragment,
  MultiEmailFlimValEntryDtoNoNestingFragment,
} from '../../../../generated/graphql-fragments';

export const MultiEmailFlimValFragment = gql`
  fragment BlabItemsOverviewMultiEmailFlimVal on MultiEmailFlimValDto {
    ...MultiEmailFlimValDtoNoNesting
    multiEmailEntries {
      ...MultiEmailFlimValEntryDtoNoNesting
    }
  }
  ${MultiEmailFlimValDtoNoNestingFragment}
  ${MultiEmailFlimValEntryDtoNoNestingFragment}
`;
