import { ChatMessageActivityDto, ChatVideoCallActivityDto, UserProfilePreviewDto } from '@t5s/shared/gql';
import { ChatConversationChatActivityUnionType } from './chat-conversation-chat-activity-union-type';

/** A chat activity block groups chat activities by the same user within a time frame. */
export interface ChatActivityBlock {
  activities: ChatMessageActivityDto[];
  lastActivityDate: Date;
  user?: UserProfilePreviewDto;
}

export type ClusterItem =
  | ChatActivityBlock
  | Exclude<ChatConversationChatActivityUnionType, ChatMessageActivityDto | ChatVideoCallActivityDto>;

export type ChatActivityCluster = ClusterItem[];

export function clusterItemIsChatActivityBlock(input: ClusterItem): input is ChatActivityBlock {
  return !!(input as ChatActivityBlock).activities;
}

/** Cluster that groups ChatActivityClusters by day (one for each day). */
export interface ChatActivityDayClusterItem {
  dayStr: string; // used as primary key, as any day can only be in here once per definition
  clusterItems: ChatActivityCluster;
}

export type ChatActivityDayCluster = ChatActivityDayClusterItem[];
