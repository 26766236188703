import gql from 'graphql-tag';
import {
  WorkflowDefNotificationDtoNoNestingFragment,
  WorkflowDefPreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { WorkflowDefNotificationActivityDtoFragment } from './workflow-def-notification-activity-dto.fragment';

export const WorkflowDefNotificationDtoFragment = gql`
  fragment WorkflowDefNotificationDtoFragment on WorkflowDefNotificationDto {
    ...WorkflowDefNotificationDtoNoNesting
    workflowDef {
      ...WorkflowDefPreviewDtoNoNesting
    }
    activities(first: 1) {
      ...WorkflowDefNotificationActivityDtoFragment
    }
  }
  ${WorkflowDefNotificationDtoNoNestingFragment}
  ${WorkflowDefPreviewDtoNoNestingFragment}
  ${WorkflowDefNotificationActivityDtoFragment}
`;
