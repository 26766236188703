import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ComponentStyle, flexCenterVertical, flexColumn, padding, spread } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent } from '@t5s/mobile-client/ui/component/common';
import { map } from 'rxjs/operators';

interface PlaceholderState {}

@Component({
  selector: 't5s-placeholder',
  template: `
    <div [class]="containerClass$ | push">
      <!-- Title -->
      <t5s-text [font]="Font.black23px" [fgColor]="Color.darkest" [centered]="true">
        <ng-content select="[placeholder-title]"></ng-content>
      </t5s-text>

      <t5s-divider [height]="14"></t5s-divider>

      <!-- Body -->
      <t5s-text [font]="Font.regular16px" [fgColor]="Color.darker" [centered]="true">
        <ng-content select="[placeholder-subtitle]"></ng-content>
      </t5s-text>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostSpread],
})
export class PlaceholderComponent extends RxComponent<PlaceholderState> {
  constructor() {
    super();
    this.set({
      bgColor: 'transparent',
    });
  }

  readonly containerClass$ = this.state$.pipe(
    map(() =>
      tss({
        ...spread,
        ...flexColumn,
        ...flexCenterVertical,
        padding: padding(60, 16, 12, 16),
      }),
    ),
  );
}
