import gql from 'graphql-tag';
import {
  ThumbnailDtoNoNestingFragment,
  WorkspaceTemplatePublishInfoDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const WorkspaceTemplatePublishInfoDtoFragment = gql`
  fragment WorkspaceTemplatePublishInfoDtoFragment on WorkspaceTemplatePublishInfoDto {
    ...WorkspaceTemplatePublishInfoDtoNoNesting
    categories

    previewImage {
      ...ThumbnailDtoNoNesting
    }

    titleImage {
      ...ThumbnailDtoNoNesting
    }

    image3 {
      ...ThumbnailDtoNoNesting
    }

    image4 {
      ...ThumbnailDtoNoNesting
    }
  }
  ${WorkspaceTemplatePublishInfoDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
