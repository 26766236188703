import { NgModule } from '@angular/core';
import {
  FeatureAccessLevelBetaDirective,
  FeatureAccessLevelDevDirective,
  FeatureAccessLevelInternalDirective,
} from './directives';

const directives = [
  FeatureAccessLevelDevDirective,
  FeatureAccessLevelInternalDirective,
  FeatureAccessLevelBetaDirective,
];

@NgModule({
  declarations: directives,
  exports: [...directives],
})
export class ClientUtilFeatureAccessLevelModule {}
