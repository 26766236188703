import gql from 'graphql-tag';
import { ConvertMultiCategoryFlimToSingleCategoryFlimResultDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../../flim-def';

export const ConvertMultiCategoryFlimToSingleCategoryFlimResultDtoFragment = gql`
  fragment ConvertMultiCategoryFlimToSingleCategoryFlimResultDtoFragment on ConvertMultiCategoryFlimToSingleCategoryFlimResultDto {
    ...ConvertMultiCategoryFlimToSingleCategoryFlimResultDtoNoNesting

    flimDef {
      ...FullFlimDef
    }
  }
  ${ConvertMultiCategoryFlimToSingleCategoryFlimResultDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
