import gql from 'graphql-tag';
import { GetUserInfoByOrganizationInvitationTokenRejectedAlreadyAcceptedNoNestingFragment } from '../../generated/graphql-fragments';
import { OrganizationInvitationSignupUserInfoDtoFragment } from './organizatoin-invitation-signup-user.fragment';

export const GetUserInfoByOrganizationInvitationTokenResultFragment = gql`
  fragment GetUserInfoByOrganizationInvitationTokenResultFragment on GetUserInfoByOrganizationInvitationTokenResult {
    ... on OrganizationInvitationSignupUserInfoDto {
      ...OrganizationInvitationSignupUserInfoDtoFragment
    }
    ... on GetUserInfoByOrganizationInvitationTokenRejectedAlreadyAccepted {
      ...GetUserInfoByOrganizationInvitationTokenRejectedAlreadyAcceptedNoNesting
    }
  }
  ${OrganizationInvitationSignupUserInfoDtoFragment}
  ${GetUserInfoByOrganizationInvitationTokenRejectedAlreadyAcceptedNoNestingFragment}
`;
