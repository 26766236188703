import gql from 'graphql-tag';
import { ConvertSingleRelationFlimToMultiRelationFlimResultDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../../flim-def';

export const ConvertSingleRelationFlimToMultiRelationFlimResultDtoFragment = gql`
  fragment ConvertSingleRelationFlimToMultiRelationFlimResultDtoFragment on ConvertSingleRelationFlimToMultiRelationFlimResultDto {
    ...ConvertSingleRelationFlimToMultiRelationFlimResultDtoNoNesting

    flimDef {
      ...FullFlimDef
    }
  }
  ${ConvertSingleRelationFlimToMultiRelationFlimResultDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
