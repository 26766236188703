import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { IonNav, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import {
  DebugConsoleActions,
  selectDebugConsoleLogs,
  selectDebugConsoleStorageKeys,
  selectDebugConsoleClientBuildVersion,
} from '@t5s/mobile-client/business-logic/debug-console';
import { PushablePage } from '@t5s/mobile-client/page/common';
import { ClipboardService } from '@t5s/mobile-client/service/clipboard';
import { AuthenticateWithCredentialsInput } from '@t5s/shared/gql';
import { DebugConsoleStorageKeyDetailsPageComponent } from './debug-console-storage-key-details-page.component';

@Component({
  selector: 't5s-debug-console-page',
  template: `
    <ion-content [scrollY]="false">
      <t5s-debug-console-view
        [version]="version$"
        [storageKeys]="storageKeys$"
        [logs]="logs$"
        (closeClick)="closeClick()"
        (storageKeyClick)="storageKeyClick($event)"
        (fcmTokenClick)="fcmTokenClick($event)"
        (userLoginClick)="userLoginClick($event)"
        (performanceLabClick)="performanceLabClick()"
      ></t5s-debug-console-view>
    </ion-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugConsolePageComponent extends PushablePage {
  readonly version$ = this.store$.select(selectDebugConsoleClientBuildVersion);
  readonly storageKeys$ = this.store$.select(selectDebugConsoleStorageKeys);
  readonly logs$ = this.store$.select(selectDebugConsoleLogs);

  constructor(
    private readonly store$: Store,
    private readonly modalController: ModalController,
    private readonly clipboard: ClipboardService,
    readonly ionNav: IonNav,
    private readonly router: Router,
  ) {
    super();

    store$.dispatch(DebugConsoleActions.loadClientBuildVersion());
    store$.dispatch(DebugConsoleActions.readStorageKeys());
  }

  closeClick() {
    void this.modalController.dismiss();
  }

  storageKeyClick({ key }: { key: string }) {
    this.store$.dispatch(DebugConsoleActions.loadStorageKey({ key }));
    void this.ionNav.push(DebugConsoleStorageKeyDetailsPageComponent);
  }

  fcmTokenClick({ fcmToken }: { fcmToken: string }) {
    // eslint-disable-next-line id-blacklist
    this.clipboard.write({ string: fcmToken }).subscribe();
  }

  userLoginClick(input: AuthenticateWithCredentialsInput) {
    this.store$.dispatch(DebugConsoleActions.performUserLogin({ input }));
    void this.modalController.dismiss();
  }

  performanceLabClick() {
    this.modalController
      .dismiss()
      .then(() => this.router.navigate(['/performance-lab']))
      .catch(() => {});
  }
}
