import { createFeatureSelector, createSelector } from '@ngrx/store';
import { blabItemDisplayStateKey, preprocessStateForSerialization, selectEntities } from './blab-item-display.reducer';
import { BlabItemDisplayState } from './blab-item-display.state';

const selectBlabItemDisplayFeatureState = createFeatureSelector<BlabItemDisplayState>(blabItemDisplayStateKey);
export const selectSearchHistoryFeatureStateForPersistance = createSelector(
  selectBlabItemDisplayFeatureState,
  preprocessStateForSerialization,
);

export const selectBlabItemDisplayBlabItemDict = createSelector(selectBlabItemDisplayFeatureState, selectEntities);

export const selectBlabItemDisplayUserSearchResults = createSelector(
  selectBlabItemDisplayFeatureState,
  (state) => state.userSearchResults,
);
