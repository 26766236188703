import gql from 'graphql-tag';
import {
  SubscribeToBlabDefDuplicationJobFailureEventNoNestingFragment,
  SubscribeToBlabDefDuplicationJobSuccessEventNoNestingFragment,
} from '../../generated/graphql-fragments';

export const BlabDefDuplicationSubscriptionEventUnionTypeFragment = gql`
  fragment BlabDefDuplicationSubscriptionEventUnionTypeFragment on SubscribeToBlabDefDuplicationJobResult {
    ... on SubscribeToBlabDefDuplicationJobFailureEvent {
      ...SubscribeToBlabDefDuplicationJobFailureEventNoNesting
    }
    ... on SubscribeToBlabDefDuplicationJobSuccessEvent {
      ...SubscribeToBlabDefDuplicationJobSuccessEventNoNesting
    }
  }
  ${SubscribeToBlabDefDuplicationJobFailureEventNoNestingFragment}
  ${SubscribeToBlabDefDuplicationJobSuccessEventNoNestingFragment}
`;
