import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getCanSafelyNavigate$ } from '@t5s/client/util/router';
import { BlabItemActivityThreadActions } from '@t5s/mobile-client/business-logic/blab-item-activity-thread';
import { BlabItemDisplayActions } from '@t5s/mobile-client/business-logic/blab-item-display';
import { NotificationActions } from '@t5s/mobile-client/business-logic/notification';
import { ROUTE_FRAGMENT_LOGGED_IN, ROUTE_FRAGMENT_NOTIFICATION } from '@t5s/mobile-client/readonly-constant/logged-in';
import { PushNotificationService } from '@t5s/mobile-client/service/push-notification';
import { pushNotificationPayloadFromData } from '@t5s/shared/util/push-notification';
import {
  isBlabItemActivityThreadPushNotificationPayload,
  isBlabItemPushNotificationPayload,
  isSilentRemovePushNotificationPayload,
} from '@t5s/shared/value-object/push-notification';
import { from } from 'rxjs';
import { delay, first, map, mergeMap, switchMap } from 'rxjs/operators';
import { BlabItemNotificationPushActions } from './blab-item-notification-push.actions';

@Injectable()
export class BlabItemNotificationPushEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly pushNotificationService: PushNotificationService,
    private readonly router: Router,
  ) {}

  readonly pushActionReceived$ = createEffect(() =>
    this.pushNotificationService.pushNotificationReceived$.pipe(
      mergeMap(({ data }) => {
        const payload = pushNotificationPayloadFromData(data);

        if (!payload) {
          return [BlabItemNotificationPushActions.unknownPushReceived()];
        }

        if (isBlabItemPushNotificationPayload(payload)) {
          return [BlabItemNotificationPushActions.blabItemPushReceived({ payload })];
        }

        if (isBlabItemActivityThreadPushNotificationPayload(payload)) {
          return [BlabItemNotificationPushActions.blabItemActivityThreadPushReceived({ payload })];
        }

        if (isSilentRemovePushNotificationPayload(payload)) {
          return [BlabItemNotificationPushActions.silentPushReceived()];
        }

        return [];
      }),
    ),
  );

  readonly pushActionPerformed$ = createEffect(() =>
    this.pushNotificationService.pushNotificationActionPerformed$.pipe(
      mergeMap(({ notification }) => {
        const { data } = notification;
        const payload = pushNotificationPayloadFromData(data);

        if (isBlabItemPushNotificationPayload(payload)) {
          return [BlabItemNotificationPushActions.blabItemPushOpened({ payload })];
        }

        if (isBlabItemActivityThreadPushNotificationPayload(payload)) {
          return [BlabItemNotificationPushActions.blabItemActivityThreadPushOpened({ payload })];
        }

        return [];
      }),
    ),
  );

  readonly blabItemPushOpenedNavigate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemNotificationPushActions.blabItemPushOpened),
      switchMap(({ payload }) => {
        const { blabItemId, blabItemActivityId } = payload;

        // check and wait for router to finalize current navigations, this led to bugs in the past so keep it comin'
        const canSafelyProceedWithNavigation$ = getCanSafelyNavigate$(this.router).pipe(first());

        return canSafelyProceedWithNavigation$.pipe(
          switchMap(() =>
            from(this.router.navigate([ROUTE_FRAGMENT_LOGGED_IN, ROUTE_FRAGMENT_NOTIFICATION])).pipe(
              delay(250),
              map(() => NotificationActions.accessBlabItem({ blabItemId, blabItemActivityId })),
            ),
          ),
        );
      }),
    ),
  );

  readonly blabItemPushOpenedLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemNotificationPushActions.blabItemPushOpened),
      map(({ payload }) => {
        const { blabItemId } = payload;
        return BlabItemDisplayActions.loadBlabItemActivities({ blabItemId });
      }),
    ),
  );

  readonly blabItemActivityThreadPushOpenedNavigate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemNotificationPushActions.blabItemActivityThreadPushOpened),
      switchMap(({ payload }) => {
        const { blabItemActivityThreadId, replyId } = payload;

        // check and wait for router to finalize current navigations, this led to bugs in the past so keep it comin'
        const canSafelyProceedWithNavigation$ = getCanSafelyNavigate$(this.router).pipe(first());

        return canSafelyProceedWithNavigation$.pipe(
          switchMap(() =>
            from(this.router.navigate([ROUTE_FRAGMENT_LOGGED_IN, ROUTE_FRAGMENT_NOTIFICATION])).pipe(
              delay(250),
              map(() =>
                NotificationActions.accessBlabItemActivityThread({
                  blabItemActivityId: blabItemActivityThreadId,
                  activityReplyId: replyId,
                }),
              ),
            ),
          ),
        );
      }),
    ),
  );

  readonly blabItemActivityThreadPushOpenedLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemNotificationPushActions.blabItemActivityThreadPushOpened),
      map(({ payload }) => {
        const { blabItemActivityThreadId } = payload;
        return BlabItemActivityThreadActions.loadActivityReplies({ blabItemActivityId: blabItemActivityThreadId });
      }),
    ),
  );

  readonly blabItemOrThreadPushOpenedMarkAsRead$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        BlabItemNotificationPushActions.blabItemPushOpened,
        BlabItemNotificationPushActions.blabItemActivityThreadPushOpened,
      ),
      map(({ payload }) => {
        const { notificationId } = payload;
        return NotificationActions.markNotificationAsRead({ notification: { id: notificationId } });
      }),
    ),
  );
}
