export const TimezoneEnLangClient = {
  Africa__Algiers: '(UTC+01:00) West Central Africa',
  Africa__Cairo: '(UTC+02:00) Cairo',
  Africa__Casablanca: '(UTC+01:00) Casablanca',
  Africa__Harare: '(UTC+02:00) Harare, Pretoria',
  Africa__Monrovia: '(UTC) Monrovia, Reykjavik',
  Africa__Nairobi: '(UTC+03:00) Nairobi',
  Africa__Tripoli: '(UTC+02:00) Tripoli',
  Africa__Windhoek: '(UTC+01:00) Windhoek',
  America__Adak: '(UTC-10:00) Aleutian Islands',
  America__Anchorage: '(UTC-09:00) Alaska',
  America__Asuncion: '(UTC-04:00) Asuncion',
  America__Bahia: '(UTC-03:00) Salvador',
  America__Belize: '(UTC-06:00) Central America',
  America__Bogota: '(UTC-05:00) Bogota, Lima, Quito',
  America__Buenos_Aires: '(UTC-03:00) City of Buenos Aires',
  America__Cancun: '(UTC-05:00) Chetumal',
  America__Caracas: '(UTC-04:00) Caracas',
  America__Cayenne: '(UTC-03:00) Cayenne, Fortaleza',
  America__Chicago: '(UTC-06:00) Central Time (US and Canada)',
  America__Chihuahua: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
  America__Cuiaba: '(UTC-04:00) Cuiaba',
  America__Denver: '(UTC-07:00) Mountain Time (US and Canada), Navajo Nation',
  America__Fortaleza: '(UTC-03:00) Araguaina',
  America__Godthab: '(UTC-03:00) Greenland',
  America__Grand_Turk: '(UTC-04:00) Turks and Caicos',
  America__Halifax: '(UTC-04:00) Atlantic Time (Canada)',
  America__Havana: '(UTC-05:00) Havana',
  America__Indiana__Indianapolis: '(UTC-05:00) Indiana (East)',
  America__Los_Angeles: '(UTC-08:00) Pacific Time (US and Canada)',
  America__Manaus: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
  America__Mexico_City: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
  America__Miquelon: '(UTC-03:00) Saint Pierre and Miquelon',
  America__Montevideo: '(UTC-03:00) Montevideo',
  America__New_York: '(UTC-05:00) Eastern Time (US and Canada)',
  America__Noronha: '(UTC-02:00) Fernando de Noronha',
  America__Phoenix: '(UTC-07:00) Arizona',
  America__Port0au0Prince: '(UTC-05:00) Haiti',
  America__Regina: '(UTC-06:00) Saskatchewan',
  America__Santiago: '(UTC-04:00) Santiago',
  America__Sao_Paulo: '(UTC-03:00) Brasilia',
  America__St_Johns: '(UTC-03:30) Newfoundland',
  America__Tijuana: '(UTC-08:00) Baja California',
  Asia__Almaty: '(UTC+06:00) Astana',
  Asia__Amman: '(UTC+02:00) Amman',
  Asia__Anadyr: '(UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky',
  Asia__Baghdad: '(UTC+03:00) Baghdad',
  Asia__Baku: '(UTC+04:00) Baku',
  Asia__Bangkok: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
  Asia__Barnaul: '(UTC+07:00) Barnaul, Gorno-Altaysk',
  Asia__Beirut: '(UTC+02:00) Beirut',
  Asia__Chita: '(UTC+09:00) Chita',
  Asia__Chongqing: '(UTC+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi',
  Asia__Colombo: '(UTC+05:30) Sri Jayawardenepura',
  Asia__Damascus: '(UTC+02:00) Damascus',
  Asia__Dhaka: '(UTC+06:00) Dhaka',
  Asia__Gaza: '(UTC+02:00) Gaza, Hebron',
  Asia__Hovd: '(UTC+07:00) Hovd',
  Asia__Irkutsk: '(UTC+08:00) Irkutsk',
  Asia__Istanbul: '(UTC+03:00) Istanbul',
  Asia__Jerusalem: '(UTC+02:00) Jerusalem',
  Asia__Kabul: '(UTC+04:30) Kabul',
  Asia__Karachi: '(UTC+05:00) Islamabad, Karachi',
  Asia__Katmandu: '(UTC+05:45) Kathmandu',
  Asia__Kolkata: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
  Asia__Krasnoyarsk: '(UTC+07:00) Krasnoyarsk',
  Asia__Kuala_Lumpur: '(UTC+08:00) Kuala Lumpur, Singapore',
  Asia__Kuwait: '(UTC+03:00) Kuwait, Riyadh',
  Asia__Magadan: '(UTC+11:00) Magadan',
  Asia__Muscat: '(UTC+04:00) Abu Dhabi, Muscat',
  Asia__Novosibirsk: '(UTC+07:00) Novosibirsk',
  Asia__Pyongyang: '(UTC+08:30) Pyongyang',
  Asia__Rangoon: '(UTC+06:30) Yangon (Rangoon)',
  Asia__Sakhalin: '(UTC+11:00) Sakhalin',
  Asia__Seoul: '(UTC+09:00) Seoul',
  Asia__Srednekolymsk: '(UTC+11:00) Chokurdakh',
  Asia__Taipei: '(UTC+08:00) Taipei',
  Asia__Tashkent: '(UTC+05:00) Tashkent, Ashgabat',
  Asia__Tbilisi: '(UTC+04:00) Tbilisi',
  Asia__Tehran: '(UTC+03:30) Tehran',
  Asia__Shanghai: '(UTC+08:00) Shanghai, China',
  Asia__Tokyo: '(UTC+09:00) Osaka, Sapporo, Tokyo',
  Asia__Tomsk: '(UTC+07:00) Tomsk',
  Asia__Ulaanbaatar: '(UTC+08:00) Ulaanbaatar',
  Asia__Vladivostok: '(UTC+10:00) Vladivostok',
  Asia__Yakutsk: '(UTC+09:00) Yakutsk',
  Asia__Yekaterinburg: '(UTC+05:00) Ekaterinburg',
  Asia__Yerevan: '(UTC+04:00) Yerevan',
  Atlantic__Azores: '(UTC-01:00) Azores',
  Atlantic__Cape_Verde: '(UTC-01:00) Cabo Verde Islands',
  Australia__Adelaide: '(UTC+09:30) Adelaide',
  Australia__Brisbane: '(UTC+10:00) Brisbane',
  Australia__Canberra: '(UTC+10:00) Canberra, Melbourne, Sydney',
  Australia__Darwin: '(UTC+09:30) Darwin',
  Australia__Eucla: '(UTC+08:45) Eucla',
  Australia__Hobart: '(UTC+10:00) Hobart',
  Australia__Lord_Howe: '(UTC+10:30) Lord Howe Island',
  Australia__Perth: '(UTC+08:00) Perth',
  chooseATimezone: 'Choose a timezone',
  Europe__Amsterdam: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  Europe__Berlin: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  Europe__Astrakhan: '(UTC+04:00) Astrakhan, Ulyanovsk, Volgograd',
  Europe__Athens: '(UTC+02:00) Athens, Bucharest',
  Europe__Belgrade: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  Europe__Brussels: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
  Europe__Helsinki: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  Europe__Kaliningrad: '(UTC+02:00) Kaliningrad',
  Europe__London: '(UTC) Dublin, Edinburgh, Lisbon, London',
  Europe__Minsk: '(UTC+03:00) Minsk',
  Europe__Moscow: '(UTC+03:00) Moscow, St. Petersburg',
  Europe__Samara: '(UTC+04:00) Izhevsk, Samara',
  Europe__Warsaw: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
  Indian__Mauritius: '(UTC+04:00) Port Louis',
  Pacific__Apia: '(UTC+13:00) Samoa',
  Pacific__Auckland: '(UTC+12:00) Auckland, Wellington',
  Pacific__Bougainville: '(UTC+11:00) Bougainville Island',
  Pacific__Chatham: '(UTC+12:45) Chatham Islands',
  Pacific__Easter: '(UTC-06:00) Easter Island',
  Pacific__Fiji: '(UTC+12:00) Fiji Islands',
  Pacific__Guadalcanal: '(UTC+11:00) Solomon Islands, New Caledonia',
  Pacific__Guam: '(UTC+10:00) Guam, Port Moresby',
  Pacific__Honolulu: '(UTC-10:00) Hawaii',
  Pacific__Kiritimati: '(UTC+14:00) Kiritimati Island',
  Pacific__Marquesas: '(UTC-09:30) Marquesas Islands',
  Pacific__Midway: '(UTC-11:00) Midway Island, American Samoa',
  Pacific__Norfolk: '(UTC+11:00) Norfolk Island',
  Pacific__Tongatapu: "(UTC+13:00) Nuku'alofa",
  searchForATimezone: 'Search for a time zone',
};
