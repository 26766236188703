import { EntityIconSkinTone, EntityIconSkinToneCharcode } from '@t5s/shared/readonly-constant/entity-icon';

export function getEntityIconSkinToneCharcodeValue(
  skinTone: EntityIconSkinTone | undefined | null,
): string | undefined {
  return skinTone ? EntityIconSkinToneCharcode[skinTone] : undefined;
}

export function getEntityIconSkin(
  skins: { unified: string; native: string }[] | undefined | null,
  skinTone?: EntityIconSkinTone | undefined | null,
): string | undefined {
  if (!skins || !skins.length) return undefined;

  const skinToneId = getEntityIconSkinToneCharcodeValue(skinTone);
  if (!skinToneId) return skins[0]?.native;
  return skins.find((skin) => skin.unified.includes(skinToneId))?.native ?? skins[0]?.native;
}
