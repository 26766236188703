import { isGqlClientError, TapeGqlClientError } from '@t5s/shared/gql-services';
import { filter } from 'rxjs/operators';
import { ErrorActionType } from './error-action';

interface GqlClientErrorActionType extends ErrorActionType {
  error: TapeGqlClientError;
}

function isGqlClientErrorAction(action: ErrorActionType): action is GqlClientErrorActionType {
  const { error } = action;
  return isGqlClientError(error);
}

export const ofGqlClientErrorType = () => filter(isGqlClientErrorAction);

const isGqlClientErrorActionWithCode =
  (withCode: string) =>
  (action: ErrorActionType): action is GqlClientErrorActionType => {
    if (!isGqlClientErrorAction(action)) {
      return false;
    }

    const {
      error: { code },
    } = action;

    return code === withCode;
  };

export const ofGqlClientErrorTypeWithCode = (code: string) => filter(isGqlClientErrorActionWithCode(code));
