import { I18nTranslation } from '@t5s/client/i18n/common';
import { Language } from '@t5s/shared/gql';

const languages = {
  [Language.DE]: {
    ACCDB: 'Access Datenbank',
    AI: 'Illustrator',
    CSV: 'CSV',
    DOCX: 'Word Dokument',
    GDOC: 'Docs Dokument',
    GFORM: 'Forms Dokument',
    GSHEET: 'Sheets Tabelle',
    GSLIDES: 'Slides Präsentation',
    HTML: 'HTML',
    INDD: 'InDesign',
    KEY: 'Keynote Präsentation',
    NUMBERS: 'Numbers Tabelle',
    OD: 'OpenDocument',
    PAGES: 'Pages Dokument',
    PDF: 'PDF',
    PPTX: 'PowerPoint Presentation',
    PSD: 'Photoshop',
    RTF: 'RTF',
    TXT: 'Textdatei',
    XLSX: 'Excel Tabelle',
    ZIP: 'Zip',
  },
  [Language.EN]: {
    ACCDB: 'Access Database',
    AI: 'Illustrator',
    CSV: 'CSV',
    DOCX: 'Word Document',
    GDOC: 'Docs Document',
    GFORM: 'Forms Document',
    GSHEET: 'Sheets Spreadsheet',
    GSLIDES: 'Slides Presentation',
    HTML: 'HTML',
    INDD: 'InDesign',
    KEY: 'Keynote Presentation',
    NUMBERS: 'Numbers Spreadsheet',
    OD: 'OpenDocument',
    PAGES: 'Pages Document',
    PDF: 'PDF',
    PPTX: 'PowerPoint Presentation',
    PSD: 'Photoshop',
    RTF: 'RTF',
    TXT: 'Text File',
    XLSX: 'Excel Spreadsheet',
    ZIP: 'Zip',
  },
} as const;

export const FileI18n = new I18nTranslation<typeof languages.EN>(languages);
