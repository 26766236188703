import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { border, exactHeight, exactWidth, fullHeight, fullWidth } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent } from '@t5s/mobile-client/ui/component/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface DividerState {
  width?: number;
  height?: number;
  bgColor: string;
}

@Component({
  selector: 't5s-divider',
  template: `<div [class]="dividerClass$ | push"> </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DividerComponent extends RxComponent<DividerState> {
  constructor() {
    super();
    this.set({
      bgColor: 'transparent',
    });
  }

  @Input() set width(width: number | Observable<number>) {
    this.setProperty('width', width);
  }

  @Input() set height(height: number | Observable<number>) {
    this.setProperty('height', height);
  }

  @Input() set bgColor(bgColor: string | Observable<string>) {
    this.setProperty('bgColor', bgColor);
  }

  readonly dividerClass$ = this.state$.pipe(
    map(({ width, height, bgColor }) => {
      if (height === 1) {
        return tss({
          borderTop: border(1, 'solid', bgColor),
          ...(width ? exactWidth(width) : fullWidth),
          ...(height ? exactHeight(height) : fullHeight),
        });
      }
      return tss({
        backgroundColor: bgColor,
        ...(width ? exactWidth(width) : fullWidth),
        ...(height ? exactHeight(height) : fullHeight),
      });
    }),
  );
}
