import gql from 'graphql-tag';
import { FullUserProfileDtoFragment } from '../user-profile';

export const UserFollowingConnectionFragment = gql`
  fragment UserFollowingConnectionFragment on UserFollowingConnection {
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...FullUserProfileDto
      }
    }
  }
  ${FullUserProfileDtoFragment}
`;
