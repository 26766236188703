import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
import { map } from 'rxjs/operators';
import { ApplicationI18nEffects } from './application-i18n.effect';
import { applicationI18nReducer, applicationI18nStateKey } from './application-i18n.reducer';
import { selectApplicationI18n } from './application-i18n.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(applicationI18nStateKey, applicationI18nReducer),
    EffectsModule.forFeature([ApplicationI18nEffects]),
  ],
  providers: [
    {
      provide: I18nObjectObservable,
      useFactory: (store$: Store) => store$.select(selectApplicationI18n).pipe(map((i18n) => ({ i18n }))),
      deps: [Store],
    },
  ],
})
export class BusinessLogicApplicationI18nModule {}
