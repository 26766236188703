import { enLang } from '@t5s/shared/i18n/common';
import { activeUserEnLangClient } from './active-user/active-user.en';
import { activeUserEntityInfoEnLangClient } from './active-user/entity-info/active-user-entity-info.en';
import { activityEnLangClient } from './activity/activity.en';
import { billingEnLangClient } from './billing/billing.en';
import { blabDefActivityEnLangClient } from './blab-def-activity/blab-def-activity.en';
import { blabItemActivityEnLangClient } from './blab-item-activity/blab-item-activity.en';
import { coreEnLangClient } from './core/core.en';
import { flimDefEnClientLang } from './flim-def/flim-def.en';
import { newsfeedEnLangClient } from './newsfeed/newsfeed.en';
import { reminderEnLangClient } from './reminder/reminder.en';
import { userActivityEnLangClient } from './user-activity/user-activity.en';
import { workspaceActivityEnLangClient } from './workspace-activity/workspace-activity.en';
import { yourContentLangEnClient } from './your-content/your-content.en';
export { color } from '@t5s/shared/ui/color';

export const enLangClient = {
  ...enLang,
  core: coreEnLangClient,
  fm: {
    activeUser: activeUserEnLangClient,
    activeUserEntityInfo: activeUserEntityInfoEnLangClient,
    activity: activityEnLangClient,
    billing: billingEnLangClient,
    blabDefActivity: blabDefActivityEnLangClient,
    blabItemActivity: blabItemActivityEnLangClient,
    chat: {
      conversation: {
        addReactionBtnTooltip: 'Add reaction',
        backBtnTooltip: 'Back',
        callBtnTooltip: 'Start a call',
        closeBtnTooltip: 'Close',
        conversationCreated: `Conversation was created.`,
        groupNameChanged: `{{userName}} changed the group name to {{groupName}}.`,
        groupThumbnailChanged: `{{userName}} changed the group photo.`,
        infoBtnTooltip: 'Info',
        input: {
          placeholder: `Start a new message`,
        },
        messageOnlyAttachments: `{{userName}} added an attachment.`,
        messageOnlyLinkPreview: `{{userName}} sent a link.`,
        moreMenuTooltip: 'More actions',
        searchUser: `Add people to new group`,
        userJoined: `{{userName}} added {{targetUser}}.`,
        userLeft: `{{userName}} left the group.`,
        videoCallStarted: `{{userName}} started a video call.`,
      },
      groupImageUploadBtnTooltip: 'Add photo',
      startANewMessage: 'Start a new message',
      videoActivity: {
        joinBtnLabel: 'Join',
        started: 'Started',
        title: 'Call with {{ primaryName }}',
      },
      videoCall: {
        confirmConnect: {
          confirmButtonLabel: 'Connect',
          message: `To start Microsoft Teams Calls directly from Tape, you need to first connect your Microsoft Account.`,
          title: 'Connect Microsoft account?',
        },
        connectionFailedToast: 'Failed to connect with Microsoft account, please try again.',
        connectionSuccessToast: 'Microsoft Account successfully connected',
      },
    },
    flimDef: flimDefEnClientLang,
    newsfeed: newsfeedEnLangClient,
    reaction: {
      reactors: `{{reactorStr}} and {{finalReactorStr}}`,
      you: 'You',
    },
    reminder: reminderEnLangClient,
    userActivity: userActivityEnLangClient,
    workspaceActivity: workspaceActivityEnLangClient,
    yourContent: yourContentLangEnClient,
  },
} as const;
