import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { fullWidth, padding } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent } from '@t5s/mobile-client/ui/component/common';
import { Observable } from 'rxjs';

interface DeviceMediaMenuItemState {
  label?: string;
}

@Component({
  selector: 't5s-device-media-menu-item',
  template: `
    <ng-container *ngIf="state$ | push as state">
      <button
        [class]="containerClass"
        [t5sTouchActive]="'background: rgba(0, 0, 0, 0.07)'"
        (t5sPressDisableLongpress)="menuItemClick.emit()"
      >
        <t5s-single-line-text [font]="Font.regular16px"> {{ state.label }} </t5s-single-line-text>
      </button>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceMediaMenuItemComponent extends RxComponent<DeviceMediaMenuItemState> {
  @Input() set label(label: string | Observable<string>) {
    this.setProperty('label', label);
  }

  @Output() menuItemClick = new EventEmitter<never>();

  readonly containerClass = tss({
    ...fullWidth,
    padding: padding(12, 16),
  });
}
