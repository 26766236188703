import { NgModule } from '@angular/core';
import { DeviceMediaBusinessLogicModule } from '@t5s/mobile-client/business-logic/device-media';
import { PageCommonModule } from '@t5s/mobile-client/page/common';
import { DeviceMediaViewModule } from '@t5s/mobile-client/ui/view/device-media';
import { DeviceMediaPageComponent } from './device-media-page.component';

@NgModule({
  imports: [PageCommonModule, DeviceMediaViewModule, DeviceMediaBusinessLogicModule],
  declarations: [DeviceMediaPageComponent],
  exports: [DeviceMediaPageComponent],
})
export class DeviceMediaPageModule {}
