import gql from 'graphql-tag';
import { BlabItemDetailDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { FullBlabDefDtoFragment } from '../blab-def';
import { AllFlimValsFragment } from '../flim-val';

export const FullBlabItemDetailFragment = gql`
  fragment FullBlabItemDetail on BlabItemDetailDto {
    ...BlabItemDetailDtoNoNesting
    blabDef {
      ...FullBlabDefDto
    }
    flimVals {
      ...AllFlimVals
    }
  }
  ${FullBlabDefDtoFragment}
  ${AllFlimValsFragment}
  ${BlabItemDetailDtoNoNestingFragment}
`;
