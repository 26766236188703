import { tss, TssStylesObject } from '@t5s/client/util/tss';
import { active } from './emotion.util';

// https://material.angular.io/cdk/drag-drop/overview

/**
 * Corresponds to the cdkDropList container.
 */
export const CDK_DROP_LIST = '.cdk-drop-list';

/**
 * Corresponds to a cdkDrag instance.
 */
export const CDK_DRAG = '.cdk-drag';

/**
 * Class that is added to a disabled cdkDrag.
 */
export const CDK_DRAG_DISABLED = '.cdk-drag-disabled';

/**
 * Class that is added to the host element of the cdkDragHandle directive.
 */
export const CDK_DRAG_HANDLE = '.cdk-drag-handle';

/**
 * This is the element that will be rendered next to the user's cursor as they're dragging an item in a sortable list. By default the element looks exactly like the element that is being dragged.
 */
export const CDK_DRAG_PREVIEW = '.cdk-drag-preview';

/**
 * This is element that will be shown instead of the real element as it's being dragged inside a cdkDropList. By default this will look exactly like the element that is being sorted.
 */
export const CDK_DRAG_PLACEHOLDER = '.cdk-drag-placeholder';

/**
 * A class that is added to cdkDropList while the user is dragging an item.
 */
export const CDK_DROP_LIST_DRAGGING = '.cdk-drop-list-dragging';

/**
 * A class that is added to cdkDropList when it is disabled.
 */
export const CDK_DROP_LIST_DISABLED = '.cdk-drop-list-disabled';

/**
 * A class that is added to cdkDropList when it can receive an item that is being dragged inside a connected drop list.
 */
export const CDK_DROP_LIST_RECEIVING = '.cdk-drop-list-receiving';

/**
 * This class is added to a cdkDrag when the user has stopped dragging.
 */
export const CDK_DRAG_ANIMATING = '.cdk-drag-animating';

export function cdkDropListDragging(css: TssStylesObject = {}) {
  return {
    [CDK_DROP_LIST_DRAGGING]: {
      '> *': {
        pointerEvent: 'none',
      },
      ...css,
    },
  };
}

export function dropListDraggingExceptPlaceholder(css: TssStylesObject) {
  return {
    [`${CDK_DROP_LIST_DRAGGING} :not(${CDK_DRAG_PLACEHOLDER})`]: {
      ...css,
    },
  };
}

export function cdkDragPreview(css: TssStylesObject) {
  return {
    [`~ ${CDK_DRAG_PREVIEW}`]: {
      ...css,
    },
  };
}

export const DropListDraggingAnimation = dropListDraggingExceptPlaceholder({
  transition: 'transform 150ms cubic-bezier(0, 0, 0.2, 1);',
});

export function cdkDragPlaceholder(css: TssStylesObject) {
  return {
    [CDK_DRAG_PLACEHOLDER]: {
      ...css,
    },
  };
}

export const HideDragPlaceholder = cdkDragPlaceholder({ opacity: 0 });

export const dragDropContainerClass = tss({
  ...cdkDropListDragging(),
  ...(DropListDraggingAnimation as any),
});

export const dragDropElementStyles = {
  ...active({
    cursor: 'grabbing',
    boxShadow: 'rgba(101, 119, 134, 0.2) 0px 0px 8px, rgba(101, 119, 134, 0.25) 0px 1px 3px 1px',
  }),
  transition: `box-shadow 200ms cubic-bezier(0, 0, 0.2, 1)`,
  [`~ ${CDK_DRAG_PREVIEW}`]: {
    boxShadow: 'rgba(101, 119, 134, 0.2) 0px 0px 8px, rgba(101, 119, 134, 0.25) 0px 1px 3px 1px',
  },
};
