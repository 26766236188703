import { createAction, props } from '@ngrx/store';
import { ChatPushNotificationPayloadValObj } from '@t5s/shared/value-object/push-notification';

const PREFIX = '[mobile-client-chat-push-notification]';

export const ChatPushNotificationActions = {
  reset: createAction(`${PREFIX} reset`),
  chatPushNotificationOpened: createAction(
    `${PREFIX} chat push notification opened`,
    props<{ payload: ChatPushNotificationPayloadValObj }>(),
  ),
};
