import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { isSameDay } from '@t5s/shared/util/date';
import {
  formatDateViaLanguage,
  formatTime,
  formatTimeRange,
  getDateTimeFormats,
  isApplicableI18n,
  normalizeDate,
} from '../common';

export function formatSingleBadgeDateTime(i18n: SharedI18n, date?: string | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);
  const dateTimeFormats = getDateTimeFormats(i18n);
  const dateFormat = dateTimeFormats.date.fullNonAbbr;
  const timeFormat = dateTimeFormats.time.short;

  const formattedDate = formatDateViaLanguage(date, dateFormat, i18n);
  const formattedTime = formatTime(date, timeFormat, { timeFormat: i18n.timeFormat });

  return `${formattedDate}, ${formattedTime}`;
}

export function formatSingleBadgeDateTimeOmitSameYear(i18n: SharedI18n, date?: string | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);
  const dateTimeFormats = getDateTimeFormats(i18n);

  const timeFormat = dateTimeFormats.time.short;

  const dateFormat: string = dateTimeFormats.date.fullNonAbbr;

  const formattedDate = formatDateViaLanguage(date, dateFormat, i18n);
  const formattedTime = formatTime(date, timeFormat, { timeFormat: i18n.timeFormat });

  return `${formattedDate}, ${formattedTime}`;
}

export function formatBadgeDateTimeRange(
  i18n: SharedI18n,
  dateLeft: string | Date | undefined,
  dateRight: string | Date | undefined,
): string | undefined {
  if (!dateLeft || !dateRight || !isApplicableI18n(i18n)) {
    return undefined;
  }

  dateLeft = normalizeDate(dateLeft);
  dateRight = normalizeDate(dateRight);

  const dateTimeFormats = getDateTimeFormats(i18n);

  if (isSameDay(dateLeft, dateRight)) {
    const formattedDay = formatDateViaLanguage(dateLeft, dateTimeFormats.date.badge, i18n);
    const formattedTime = formatTimeRange(i18n, dateLeft, dateRight);
    return `${formattedDay} · ${formattedTime}`;
  } else {
    const formattedLeft = formatSingleBadgeDateTime(i18n, dateLeft);
    const formattedRight = formatSingleBadgeDateTime(i18n, dateRight);
    return `${formattedLeft} – ${formattedRight}`;
  }
}
