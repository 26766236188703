import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RxComponent } from '@t5s/mobile-client/ui/component/common';

interface PlaceholderLightState {}

@Component({
  selector: 't5s-placeholder-light',
  template: `<t5s-text
    [fgColor]="Color.darker"
    [font]="Font.regular16px"
    [centered]="true"
    [style.padding-left.px]="16"
    [style.padding-top.px]="12"
    [style.padding-right.px]="16"
    [style.padding-bottom.px]="12"
  >
    <ng-content></ng-content>
  </t5s-text>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceholderLightComponent extends RxComponent<PlaceholderLightState> {}
