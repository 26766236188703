import type { ApolloError } from 'apollo-client';
import { ObservableInput } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TapeGqlClientError } from './tape-gql-error.class';

const isApolloError = (err: unknown): err is ApolloError => !!(err as any).graphQLErrors;

/** Catches apollo client errors, rethrows a more formal `TapeGqlClientError` if possible. */
export const catchAndRethrowGqlError = <T, O extends ObservableInput<any>>() =>
  catchError<T, O>((err: unknown) => {
    if (isApolloError(err)) {
      const { graphQLErrors } = err;
      const [gqlError] = graphQLErrors;

      if (!gqlError) {
        throw err;
      }

      const { message, extensions } = gqlError;

      if (!extensions) {
        throw err;
      }

      const { code } = extensions; // TODO: share interface here?

      throw new TapeGqlClientError(code, message);
    }

    throw err;
  });
