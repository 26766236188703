import gql from 'graphql-tag';
import {
  WorkflowHomeTimeIntervalInfoDtoNoNestingFragment,
  WorkflowHomeTimeIntervalWorkflowDefsStatusInfoDtoNoNestingFragment,
  WorkflowHomeTimeIntervalWorkflowRunsStatusInfoDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const WorkflowHomeTimeIntervalInfoDtoFragment = gql`
  fragment WorkflowHomeTimeIntervalInfoDtoFragment on WorkflowHomeTimeIntervalInfoDto {
    ...WorkflowHomeTimeIntervalInfoDtoNoNesting

    workflowRunsStatusInfo {
      ...WorkflowHomeTimeIntervalWorkflowRunsStatusInfoDtoNoNesting
    }

    workflowDefsStatusInfo {
      ...WorkflowHomeTimeIntervalWorkflowDefsStatusInfoDtoNoNesting
    }
  }

  ${WorkflowHomeTimeIntervalInfoDtoNoNestingFragment}

  ${WorkflowHomeTimeIntervalWorkflowRunsStatusInfoDtoNoNestingFragment}
  ${WorkflowHomeTimeIntervalWorkflowDefsStatusInfoDtoNoNestingFragment}
`;
