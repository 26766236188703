import gql from 'graphql-tag';
import {
  CreatedAtSplitByDtoNoNestingFragment,
  CreatedAtSplitBySectionDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const CreatedAtSplitByDtoFragment = gql`
  fragment CreatedAtSplitByDto on CreatedAtSplitByDto {
    ...CreatedAtSplitByDtoNoNesting
    sections {
      ...CreatedAtSplitBySectionDtoNoNesting
    }
  }
  ${CreatedAtSplitByDtoNoNestingFragment}
  ${CreatedAtSplitBySectionDtoNoNestingFragment}
`;
