import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ComponentStyle, eachChild, firstChild, px, spread } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent } from '@t5s/mobile-client/ui/component/common';
import { ViewState } from '@t5s/mobile-client/ui/view/common';

interface RootViewState extends ViewState {}

@Component({
  selector: 't5s-root-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostSpread],
  template: `
    <div [class]="containerClass">
      <!-- Main content -->
      <div>
        <ng-content></ng-content>
      </div>

      <!-- Sheet outlet -->
      <div [class]="sheetOutletClass">
        <ng-content select="[root-sheet-outlet]"></ng-content>
      </div>
    </div>
  `,
})
export class RootViewComponent extends RxComponent<RootViewState> {
  readonly containerClass = tss({
    ...spread,
    position: 'relative',
    ...firstChild({
      ...spread,
    }),
  });

  readonly sheetOutletClass = tss({
    position: 'absolute',
    top: px(0),
    left: px(0),
    ...spread,
    pointerEvents: 'none',
    ...eachChild({
      ...spread,
      position: 'absolute',
      top: px(0),
      left: px(0),
    }),
  });
}
