import { Directive, ElementRef, Input, Output } from '@angular/core';
import { hasElementWithDataAttrInPath, hasInteractiveElemenInPath } from '@t5s/client/util/element';
import { fromTouchmoveEventOutsideThreshold } from '@t5s/mobile-client/util/touch-event';
import { fromEvent, merge, Observable, timer } from 'rxjs';
import { filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { LONGPRESS_DURATION_MS } from './longpress.constants';

const DEFAULT_CLICK_EXCLUDE_DATA_ATTR_KEY = 'data-t5s-default-press-exclude';

@Directive({
  selector: '[t5sDefaultPress]',
  exportAs: 't5sDefaultPress',
})
export class DefaultPressDirective {
  constructor(private readonly elementRef: ElementRef) {
    const touchstart$ = fromEvent<TouchEvent>(elementRef.nativeElement, 'touchstart', { passive: true });
    const touchend$ = fromEvent<TouchEvent>(elementRef.nativeElement, 'touchend', { passive: true });

    this.defaultPress = touchstart$.pipe(
      switchMap((event) =>
        touchend$.pipe(
          filter((event) => this.pressHandle(event)),
          map(() => event),
          takeUntil(
            merge(
              fromTouchmoveEventOutsideThreshold(elementRef.nativeElement, { touches: event.touches }),
              timer(LONGPRESS_DURATION_MS),
            ),
          ),
        ),
      ),
    );
  }

  @Input() disabled!: boolean;
  @Output('t5sDefaultPress') defaultPress: Observable<TouchEvent>;

  pressHandle(event: TouchEvent) {
    // Do not trigger an activity click if the click originated from an interactive element like a button
    if (hasInteractiveElemenInPath(event, { hostElement: this.elementRef.nativeElement })) {
      return false;
    }

    if (
      hasElementWithDataAttrInPath(DEFAULT_CLICK_EXCLUDE_DATA_ATTR_KEY, event, {
        hostElement: this.elementRef.nativeElement,
      })
    ) {
      return false;
    }

    if (this.disabled) {
      return false;
    }

    return true;
  }
}
