import { Provider } from '@angular/core';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { ApolloSubscriptionService } from './apollo-subscription.service';

const subscriptionClientFactory: (service: ApolloSubscriptionService) => SubscriptionClient = (service) =>
  service.client;

export const SUBSCRIPTION_CLIENT_PROVIDER: Provider = {
  provide: SubscriptionClient,
  useFactory: subscriptionClientFactory,
  deps: [ApolloSubscriptionService],
};
