import { ColorV2 as Color } from '@t5s/shared/ui/color';
import { rgba } from './util';

export const Shadow = {
  /** Tape shadow light */
  light: `0px 0px 0px 1px ${rgba(Color.blackGlossy, 0.1)}, 0px 2px 4px ${rgba(Color.blackGlossy, 0.1)}`,

  /** Tape shadow medium */
  medium: `0px 1px 4px ${rgba(Color.blackGlossy, 0.3)}`,

  /** Tape shadow regular */
  regular: `0px 0px 0px 1px ${rgba(Color.blackGlossy, 0.05)}, 0px 3px 6px ${rgba(
    Color.blackGlossy,
    0.1,
  )}, 0px 9px 24px ${rgba(Color.blackGlossy, 0.2)}`,

  /** Tape shadow bold */
  bold: `0px 0px 0px 1px ${rgba(Color.blackGlossy, 0.05)}, 0px 5px 10px ${rgba(
    Color.blackGlossy,
    0.1,
  )}, 0px 15px 40px ${rgba(Color.blackGlossy, 0.2)}`,

  /** Tape button shadow */
  button: `0px 1px 2px ${rgba(Color.blackGlossy, 0.1)}, inset 0px 0px 0px 1px ${rgba(Color.blackGlossy, 0.1)}`,

  /** Tape input shadow */
  input: `inset 0px 0px 0px 1px ${rgba(Color.blackGlossy, 0.1)}`,

  /** Tape avatar shadow */
  avatar: `0px 2px 4px ${rgba(Color.blackGlossy, 0.1)}`,
} as const;
