import gql from 'graphql-tag';
import { UserProfilePreviewDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const UserGroupActivityDtoFragment = gql`
  fragment UserGroupActivityDtoFragment on UserGroupActivityDto {
    id
    type
    createdAt
    authorId
    author {
      ...UserProfilePreviewDtoNoNesting
      profilePicture {
        ...ThumbnailDtoNoNesting
      }
    }
    userGroupId
    authorId
  }

  ${UserProfilePreviewDtoNoNestingFragment}
`;
