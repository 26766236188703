import { Directive, ElementRef } from '@angular/core';
import { SCROLL_ANCHOR_DATA_ATRR } from '@t5s/mobile-client/readonly-constant/scroll-anchor';

@Directive({
  selector: '[t5sScrollAnchor]',
  exportAs: 't5sScrollAnchor',
})
export class ScrollAnchorDirective {
  constructor(elRef: ElementRef<HTMLElement>) {
    elRef.nativeElement.setAttribute(SCROLL_ANCHOR_DATA_ATRR, '');
  }
}
