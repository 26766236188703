import gql from 'graphql-tag';
import { ReminderFragment } from './reminder-dto.fragment';

export const FullReminderConnectionFragment = gql`
  fragment FullReminderFragmentConnection on ReminderConnection {
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...ReminderDtoFragment
      }
    }
  }
  ${ReminderFragment}
`;
