import { Directive, ElementRef, Output } from '@angular/core';
import { RxDirective } from '@t5s/client/util/rx';
import { DeviceObservable } from '@t5s/mobile-client/provider-token/device';
import { fromTouchendEventWithinThreshold } from '@t5s/mobile-client/util/touch-event';
import { fromEvent, merge, Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Directive({
  selector: '[t5sPressDisableLongpress]',
  exportAs: 't5sPressDisableLongpress',
})
export class PressDisableLongpressDirective extends RxDirective {
  constructor(readonly elementRef: ElementRef, readonly device$: DeviceObservable) {
    super();

    const touchstart$ = fromEvent<TouchEvent>(elementRef.nativeElement, 'touchstart', { passive: true });

    // Prevent context menu default for ios/ web (android we do not want the haptic feedback which occurs on prevent-default)
    this.hold(
      device$.pipe(
        filter(({ platform }) => platform !== 'android'),
        switchMap(() => fromEvent<Event>(elementRef.nativeElement, 'contextmenu')),
      ),
      (event) => event.preventDefault(),
    );

    // propagate regular clicks in web version (i.e. important for cypress e2e tests)
    const webClick$ = device$.pipe(
      filter(({ platform, isTouch }) => platform === 'web' && !isTouch),
      switchMap(() => fromEvent<MouseEvent>(elementRef.nativeElement, 'click', { passive: true })),
    );

    // Only register touches where start and end coordinates are sufficiently close to each other
    const touchStartEnd$ = touchstart$.pipe(
      switchMap(({ touches }) => fromTouchendEventWithinThreshold(elementRef.nativeElement, { touches })),
    );

    this.t5sPressDisableLongpress = merge(touchStartEnd$, webClick$);
  }

  @Output() t5sPressDisableLongpress: Observable<TouchEvent | MouseEvent>;
}
