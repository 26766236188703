import gql from 'graphql-tag';
import { ChatActivityDeletedDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { ChatActivityFragment } from './chat-activity-dto.fragment';

export const SingleConversationSubscriptionUpdateFragment = gql`
  fragment SingleConversationSubscriptionUpdateFragment on SingleConversationSubscriptionUpdate {
    ... on ChatActivityDto {
      ...ChatActivity
    }

    ... on ChatActivityDeletedDto {
      ...ChatActivityDeletedDtoNoNesting
    }
  }
  ${ChatActivityFragment}
  ${ChatActivityDeletedDtoNoNestingFragment}
`;
