import gql from 'graphql-tag';
import {
  BlabDefPreviewDtoNoNestingFragment,
  BlabItemPreviewDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
  UserProfilePreviewDtoNoNestingFragment,
  UserTrashDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const UserTrashDtoFragment = gql`
  fragment UserTrashDtoFragment on UserTrashDto {
    ...UserTrashDtoNoNesting
    deletedByUser {
      ...UserProfilePreviewDtoNoNesting
      profilePicture {
        ...ThumbnailDtoNoNesting
      }
    }
    blabItem {
      ...BlabItemPreviewDtoNoNesting
      blabDef {
        ...BlabDefPreviewDtoNoNesting
      }
    }
    blabDef {
      ...BlabDefPreviewDtoNoNesting
    }
  }
  ${UserTrashDtoNoNestingFragment}
  ${BlabItemPreviewDtoNoNestingFragment}
  ${BlabDefPreviewDtoNoNestingFragment}
  ${UserProfilePreviewDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
