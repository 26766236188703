import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ClientApiHttpClient } from '@t5s/client/data-access/api';
import { EntityIconApiService } from '@t5s/shared/service/api-entity-icon';
import { EntityIconAbstractService } from '@t5s/mobile-client/provider-token/entity-icon';
import { EntityIconService } from './entity-icon.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {
      provide: EntityIconApiService,
      useFactory: (http: ClientApiHttpClient) => new EntityIconApiService(http),
      deps: [ClientApiHttpClient],
    },
    {
      provide: EntityIconAbstractService,
      useClass: EntityIconService,
    },
  ],
})
export class EntityIconServiceModule {}
