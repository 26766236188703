import gql from 'graphql-tag';
import { UserProfilePreviewDtoFragment } from '../user-profile';

export const AudioRoomActivityDtoFragment = gql`
  fragment AudioRoomActivityDtoFragment on AudioRoomActivityDto {
    id
    type
    createdAt
    audioRoom {
      id
      name
    }
    author {
      ...UserProfilePreviewDtoFragment
    }
    audioRoomId
    authorId
  }
  ${UserProfilePreviewDtoFragment}
`;
