import gql from 'graphql-tag';
import {
  MultiAttachmentFlimValDtoNoNestingFragment,
  MultiAttachmentFlimValFileReferenceDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';
import { FlimValAttachmentFragment } from '../../flim-val-attachment';

export const MultiAttachmentFlimValFragment = gql`
  fragment MultiAttachmentFlimVal on MultiAttachmentFlimValDto {
    ...MultiAttachmentFlimValDtoNoNesting
    attachmentReferences {
      ...MultiAttachmentFlimValFileReferenceDtoNoNesting
      attachment {
        ...FlimValAttachmentFragment
      }
    }
  }
  ${MultiAttachmentFlimValDtoNoNestingFragment}
  ${FlimValAttachmentFragment}
  ${MultiAttachmentFlimValFileReferenceDtoNoNestingFragment}
`;
