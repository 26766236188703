import gql from 'graphql-tag';
import { FlimDefRevisionPreviewUnionTypeFragment } from '../blab-def-revision-preview/flim-def-revision-preview-union-type.fragment';

export const FlimValRevisionDtoFragment = gql`
  fragment FlimValRevisionDtoFragment on FlimValRevisionDto {
    id
    type
    flimType
    createdAt
    blabItemId
    flimDefId
    flimDef {
      ...FlimDefRevisionPreviewUnionTypeFragment
    }
  }
  ${FlimDefRevisionPreviewUnionTypeFragment}
`;
