import {
  ROUTE_FRAGMENT_ACTIVE_USER,
  ROUTE_FRAGMENT_CHAT,
  ROUTE_FRAGMENT_HOME,
  ROUTE_FRAGMENT_NOTIFICATION,
  ROUTE_FRAGMENT_SEARCH,
} from '@t5s/mobile-client/readonly-constant/logged-in';
import { NavigationBarItem } from '@t5s/mobile-client/value-object/logged-in-root';

export function getOutletRouteFragment(outlet: NavigationBarItem) {
  if (outlet === 'home') {
    return ROUTE_FRAGMENT_HOME;
  }

  if (outlet === 'search') {
    return ROUTE_FRAGMENT_SEARCH;
  }

  if (outlet === 'notifications') {
    return ROUTE_FRAGMENT_NOTIFICATION;
  }

  if (outlet === 'active-user') {
    return ROUTE_FRAGMENT_ACTIVE_USER;
  }

  if (outlet === 'chat') {
    return ROUTE_FRAGMENT_CHAT;
  }

  return undefined;
}
