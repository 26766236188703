export interface IPickStrategies {
  random: (lowerBound: number, upperBound: number) => number;
  partitioning: (lowerBound: number, upperBound: number) => number;
}

type Sequence = number[];

interface INumAlphabet {
  start: number;
  end: number;
}

/**
 * Utility for creating and calculating isolated position strings, which can determine the order of multiple items which each
 * have an isolated position property. An item can be moved inside an ordered set of items by only updating its own isolated position string
 * without having to adjust any other isolated position string. This way, re-ordering operations only affect the item that changed its position
 */
export class IsolatedSequence {
  /**
   * Create a new IsolatedPosition instance
   * @param alphabet define the start and end of the numeric alphabet's of the sequence's values
   * @param pick define the picking strategies
   */
  constructor(private readonly alphabet: INumAlphabet, private readonly pick: IPickStrategies) {
    if (this.alphabet.end - this.alphabet.start < 2) {
      throw Error('Invalid alphabet size!');
    }
  }

  /**
   * Calculate a sequence that lies in between the given lower and upper sequence
   * @param lowerSeq lower boundary sequence
   * @param upperSeq upper boundary sequence (undefined = infinite)
   */
  getBetween(lowerSeq: Sequence | undefined, upperSeq: Sequence | undefined): Sequence {
    let lowerPos = lowerSeq !== undefined ? lowerSeq[0] : this.alphabet.start;
    let upperPos = upperSeq !== undefined ? upperSeq[0] : this.alphabet.end;

    if (lowerPos === undefined && upperPos === undefined) {
      return [];
    }

    // "Fill with zero"
    lowerPos = lowerPos !== undefined ? lowerPos : this.alphabet.start;
    upperPos = upperPos !== undefined ? upperPos : this.alphabet.end;

    if (lowerPos === upperPos) {
      if (!lowerSeq && (upperSeq ?? [])[1] === undefined) {
        return [];
      }

      return [
        lowerPos,
        ...this.getBetween(lowerSeq ? lowerSeq.slice(1) : undefined, upperSeq ? upperSeq.slice(1) : undefined),
      ];
    }

    if (lowerPos + 1 === upperPos) {
      return [
        lowerPos,
        ...this.getBetween(lowerSeq ? lowerSeq.slice(1) : undefined, upperSeq ? [this.alphabet.end] : undefined),
      ];
    }
    return [this.pick.partitioning(lowerPos, upperPos), this.pick.random(this.alphabet.start + 1, this.alphabet.end)];
  }

  pickRandom(n = 1): Sequence {
    return Array.from(new Array(n)).map(() => this.pick.random(this.alphabet.start + 1, this.alphabet.end));
  }
}
