import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchErrorDispatch } from '@t5s/client/util/store';
import {
  LoggedInSubscriptionEndTriggerObservable,
  LoggedInSubscriptionStartTriggerObservable,
} from '@t5s/mobile-client/provider-token/logged-in-subscription-trigger';
import { BrowserService } from '@t5s/mobile-client/service/browser';
import { getLoadMoreInputForConversation } from '@t5s/mobile-client/util/chat-conversation';
import { ConversationUnionType } from '@t5s/shared/gql';
import { GqlChatActivityService, GqlChatConversationService } from '@t5s/shared/gql-services';
import { PaginationSize } from '@t5s/shared/util/pagination';
import { filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { merge } from 'rxjs';
import { debounceTime, filter, first, map, mergeMap, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { ChatConversationActions } from './chat-conversation.actions';
import { selectChatConversationStateDict } from './chat-conversation.selectors';

@Injectable()
export class ChatConversationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly gqlChatConversations: GqlChatConversationService,
    private readonly gqlChatActivity: GqlChatActivityService,
    private readonly subscriptionStartTrigger$: LoggedInSubscriptionStartTriggerObservable,
    private readonly subscriptionEndTrigger$: LoggedInSubscriptionEndTriggerObservable,
    private readonly browserService: BrowserService,
  ) {}

  private readonly PAGE_SIZE = PaginationSize.SMEDIUM;
  private readonly INCREMENTAL_LOAD_PAGE_SIZE = PaginationSize.SMEDIUM;

  readonly loadConversation$ = createEffect(() =>
    this.actions$.pipe(ofType(ChatConversationActions.loadConversation)).pipe(
      mergeMap(({ conversationId }) =>
        this.gqlChatConversations.getConversation({ conversationId }).pipe(
          mergeMap((conversation) => {
            return [
              ChatConversationActions.loadConversationSuccess({
                conversationId,
                conversation: conversation as ConversationUnionType,
              }),
            ];
          }),
          catchErrorDispatch(ChatConversationActions.loadConversationException),
        ),
      ),
    ),
  );

  readonly loadConversationChatActivity$ = createEffect(() =>
    this.actions$.pipe(ofType(ChatConversationActions.loadConversationChatActivity)).pipe(
      mergeMap(({ conversationId }) =>
        this.gqlChatActivity.getChatActivityConnection(this.PAGE_SIZE, { conversationId }).pipe(
          mergeMap((connection) => {
            return [ChatConversationActions.loadConversationChatActivitySuccess({ conversationId, connection })];
          }),
          catchErrorDispatch(ChatConversationActions.loadConversationChatActivityException),
        ),
      ),
    ),
  );

  readonly preloadUnreadConversations$ = createEffect(() =>
    this.actions$.pipe(ofType(ChatConversationActions.preloadUnreadConversations)).pipe(
      switchMap(({ conversationIds }) => {
        const MAX_PRELOAD = 5;
        conversationIds = conversationIds.slice(0, MAX_PRELOAD);

        const loadConversationActions = conversationIds.map((conversationId) =>
          ChatConversationActions.loadConversation({ conversationId }),
        );
        const loadConversationActivityActions = conversationIds.map((conversationId) =>
          ChatConversationActions.loadConversationChatActivity({ conversationId }),
        );

        return [...loadConversationActions, ...loadConversationActivityActions];
      }),
    ),
  );

  readonly preloadReadConversations$ = createEffect(() =>
    this.actions$.pipe(ofType(ChatConversationActions.preloadReadConversations)).pipe(
      debounceTime(500),
      switchMap(({ conversationIds }) => {
        const MAX_PRELOAD = 5;
        conversationIds = conversationIds.slice(0, MAX_PRELOAD);
        // TODO: chunk

        const loadConversationActions = conversationIds.map((conversationId) =>
          ChatConversationActions.loadConversation({ conversationId }),
        );
        const loadConversationActivityActions = conversationIds.map((conversationId) =>
          ChatConversationActions.loadConversationChatActivity({ conversationId }),
        );

        return [...loadConversationActions, ...loadConversationActivityActions];
      }),
    ),
  );

  readonly markConversationAsRead$ = createEffect(() =>
    this.actions$.pipe(ofType(ChatConversationActions.markConversationAsRead)).pipe(
      mergeMap(({ conversationId }) =>
        this.gqlChatConversations.markConversationAsRead({ conversationId }).pipe(
          mergeMap(() => {
            return [
              ChatConversationActions.markConversationAsReadSuccess({
                conversationId,
              }),
            ];
          }),
          catchErrorDispatch(ChatConversationActions.markConversationAsReadException),
        ),
      ),
    ),
  );

  readonly loadMoreConversationChatActivity$ = createEffect(() =>
    this.actions$.pipe(ofType(ChatConversationActions.loadMoreConversationChatActivity)).pipe(
      switchMap(({ conversationId }) =>
        this.store$.select(selectChatConversationStateDict).pipe(
          map((dict) => dict[conversationId]),
          first(),
          filterOnlyPresent(),
        ),
      ),
      map(getLoadMoreInputForConversation),
      filterOnlyPresent(),
      mergeMap(({ cursor, conversationId }) =>
        this.gqlChatActivity.getChatActivityConnection(this.INCREMENTAL_LOAD_PAGE_SIZE, undefined, cursor).pipe(
          mergeMap((connection) => {
            return [ChatConversationActions.loadMoreConversationChatActivitySuccess({ conversationId, connection })];
          }),
          catchErrorDispatch(ChatConversationActions.loadConversationChatActivityException),
        ),
      ),
    ),
  );

  readonly subscribeToConversation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatConversationActions.subscribeToConversation),
      mergeMap(({ conversationId }) => {
        const imperativeUnsubscribe$ = this.actions$.pipe(
          ofType(ChatConversationActions.unsubscribefromConversation),
          filter((action) => action.conversationId === conversationId),
        );

        const shouldSubscribe$ = this.subscriptionStartTrigger$.pipe(startWith(true), debounceTime(100));
        const shouldUnsubscribeTemporarily$ = merge(this.subscriptionEndTrigger$);

        return shouldSubscribe$.pipe(
          switchMap(() =>
            this.gqlChatActivity.subscribeToSingleConversation(conversationId).pipe(
              map((update) => ChatConversationActions.conversationUpdate({ conversationId, update })),
              catchErrorDispatch(ChatConversationActions.conversationSubscriptionException),
              startWith(ChatConversationActions.subscribeToConversationSuccess({ conversationId })),
              takeUntil(shouldUnsubscribeTemporarily$),
            ),
          ),
          takeUntil(imperativeUnsubscribe$),
        );
      }),
    ),
  );

  readonly openUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatConversationActions.openUrl),
        switchMap(({ url }) => this.browserService.open({ url })),
      ),
    { dispatch: false },
  );
}
