import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IntercomService } from '@t5s/mobile-client/service/intercom';
import { mergeMap } from 'rxjs/operators';
import { ChatConversationActions } from './chat-conversation.actions';

@Injectable()
export class ChatConversationMessageAnalyticsEffects {
  constructor(private readonly actions$: Actions, private readonly intercomService: IntercomService) {}

  readonly sendMessageAnalytics$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatConversationActions.sendMessageSuccess),
        mergeMap(() => this.intercomService.logEvent({ name: 'chat-message-create' })),
      ),
    {
      dispatch: false,
    },
  );
}
