import { px } from './units.util';

export const displayBlock = { display: 'block' as const };
export const displayNone = { display: 'none' as const };

export const fullWidth = { width: '100%' } as const;
export const fullHeight = { height: '100%' } as const;

export const spread = {
  ...fullWidth,
  ...fullHeight,
} as const;

export const absoluteSpread = {
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
} as const;

export const displayFlex = {
  display: 'flex',
} as const;

export const flexColumn = {
  ...displayFlex,
  flexDirection: 'column',
} as const;

export const flexRow = {
  ...displayFlex,
  flexDirection: 'row',
} as const;

export const flex1Vertical = {
  flex: 1,
  minHeight: px(0),
} as const;

export const flex1Horizontal = {
  flex: 1,
  minWidth: px(0),
} as const;

export const flexCenterVertical = { ...displayFlex, alignItems: 'center' } as const;
export const flexAlignBtmVertical = { ...displayFlex, alignItems: 'flex-end' } as const;
export const flexCenterHorizontal = { ...displayFlex, justifyContent: 'center' } as const;
export const flexCenter = {
  ...flexCenterHorizontal,
  ...flexCenterVertical,
} as const;
export const flexNoShrink = { flexShrink: 0 };

export const hidden = { display: 'none' } as const;
export const rounded = {
  borderRadius: '50%',
} as const;

export const fitContent = {
  ...displayBlock,
  width: 'fit-content',
  height: 'fit-content',
} as const;

export const border = (size: number | string, type: 'solid' | 'dashed', color: string = 'transparent') =>
  `${typeof size === 'number' ? px(size) : size} ${type} ${color}`;

export const exactHeight = (height: number | undefined) =>
  height !== undefined ? ({ height, minHeight: height, maxHeight: height } as const) : ({} as const);

export const exactWidth = (width: number | undefined) =>
  width !== undefined ? ({ width, minWidth: width, maxWidth: width } as const) : ({} as const);

export const exactSize = (size: number | undefined) =>
  size !== undefined ? ({ ...exactHeight(size), ...exactWidth(size) } as const) : ({} as const);
