import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { ActiveUserIdObservable } from '@t5s/mobile-client/provider-token/active-user';
import { StateHydrationService } from '@t5s/mobile-client/service/state-hydration';
import { filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { ChatStateHydrationPersistance } from './chat-hydration.state';
import { ChatActions } from './chat.actions';
import { chatStateKey } from './chat.reducer';
import { selectChatFeatureStateForPersistance } from './chat.selectors';

@Injectable()
export class ChatHydrationEffects implements OnInitEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly activeUserId$: ActiveUserIdObservable,
    private readonly stateHydrationService: StateHydrationService<ChatStateHydrationPersistance>,
  ) {}

  readonly hydrationPersistanceKey$ = this.activeUserId$.pipe(
    filterOnlyPresent(),
    map((activeUserId) => `${chatStateKey}_${activeUserId}`),
  );

  readonly persistState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ChatActions.hydrateStateSuccess,
          ChatActions.hydrateStateNotAvailable,
          ChatActions.hydrateStateException,
        ),
        switchMap(() => this.store$.select(selectChatFeatureStateForPersistance)),
        concatLatestFrom(() => this.hydrationPersistanceKey$),
        debounceTime(2000),
        switchMap(([state, hydrationPersistanceKey]) =>
          this.stateHydrationService.persistState(hydrationPersistanceKey, state),
        ),
      ),
    { dispatch: false },
  );

  readonly hydrateState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.hydrateState),
      concatLatestFrom(() => this.hydrationPersistanceKey$),
      switchMap(([_, hydrationPersistanceKey]) =>
        this.stateHydrationService.retrieveState(hydrationPersistanceKey).pipe(
          map((state) => {
            if (!state) {
              return ChatActions.hydrateStateNotAvailable();
            }

            return ChatActions.hydrateStateSuccess({ state });
          }),
          catchErrorDispatch(ChatActions.hydrateStateException),
        ),
      ),
    ),
  );

  readonly hydrateStateExceptionClear$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatActions.hydrateStateException),
        concatLatestFrom(() => this.hydrationPersistanceKey$),
        switchMap(([_, hydrationPersistanceKey]) => this.stateHydrationService.clearState(hydrationPersistanceKey)),
      ),
    { dispatch: false },
  );

  ngrxOnInitEffects() {
    return ChatActions.hydrateState();
  }
}
