import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Keyboard } from '@capacitor/keyboard';
import { PlatformObservable } from '@t5s/mobile-client/provider-token/device';
import { ApplicationStateObservable } from '@t5s/mobile-client/provider-token/state';
import { KeyboardPosition, KeyboardState } from '@t5s/mobile-client/value-object/keyboard';
import { BehaviorSubject, defer, from, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable()
export class KeyboardService implements OnDestroy {
  private readonly state$$ = new BehaviorSubject<Partial<KeyboardState>>({
    keyboardPosition: KeyboardPosition.DID_HIDE,
    keyboardHeight: 0,
  });

  readonly state$ = this.state$$.asObservable().pipe(share());
  private readonly hideKeyboardFocusBtn?: HTMLButtonElement;

  constructor(
    readonly platform$: PlatformObservable,
    readonly appState$: ApplicationStateObservable,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {
    platform$.subscribe(({ platform }) => {
      if (platform === 'ios' || platform === 'android') {
        void Keyboard.addListener('keyboardWillShow', (info) =>
          this.state$$.next({ keyboardPosition: KeyboardPosition.WILL_SHOW, ...info }),
        );
        void Keyboard.addListener('keyboardDidShow', (info) =>
          this.state$$.next({ keyboardPosition: KeyboardPosition.DID_SHOW, ...info }),
        );
        void Keyboard.addListener('keyboardWillHide', () =>
          this.state$$.next({ keyboardPosition: KeyboardPosition.WILL_HIDE }),
        );
        void Keyboard.addListener('keyboardDidHide', () =>
          this.state$$.next({ keyboardPosition: KeyboardPosition.DID_HIDE }),
        );
      }
    });

    const { body } = this.document;
    try {
      const btnEL = this.document.createElement('button');
      btnEL.setAttribute('style', 'opacity: 0; width: 0; height: 0');
      body.appendChild(btnEL);

      this.hideKeyboardFocusBtn = btnEL;
    } catch (e: unknown) {}

    // Hide keyboard on app minimize
    this.appState$.subscribe((state) => {
      if (!state.isActive) {
        this.hide();
      }
    });
  }

  show(): Observable<void> {
    return defer(() => from(Keyboard.show()));
  }

  hide(): void {
    this.hideKeyboardFocusBtn?.focus();
  }

  ngOnDestroy() {
    return Keyboard.removeAllListeners();
  }
}
