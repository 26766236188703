import { NgModule } from '@angular/core';
import { ProviderApplicationInfoModule } from '@t5s/mobile-client/provider/application-info';
import { ApplicationStateProviderModule } from '@t5s/mobile-client/provider/application-state';
import { ProviderApplicationUrlModule } from '@t5s/mobile-client/provider/application-url';
import { ProviderHardwareBackButtonModule } from '@t5s/mobile-client/provider/hardware-back-button';
import { LoggedInPollingTriggerProviderModule } from '@t5s/mobile-client/provider/logged-in-polling-trigger';
import { LoggedInRefreshTriggerProviderModule } from '@t5s/mobile-client/provider/logged-in-refresh-trigger';
import { LoggedInSubscriptionTriggerProviderModule } from '@t5s/mobile-client/provider/logged-in-subscription-trigger';
import { NetworkRetryProviderModule } from '@t5s/mobile-client/provider/network-retry';
import { PlatformProviderModule } from '@t5s/mobile-client/provider/platform';
import { PollingTriggerProviderModule } from '@t5s/mobile-client/provider/polling-trigger';
import { RefreshTriggerProviderModule } from '@t5s/mobile-client/provider/refresh-trigger';
import { SubscriptionTriggerProviderModule } from '@t5s/mobile-client/provider/subscription-trigger';
import { ViewportDimensionsProviderModule } from '@t5s/mobile-client/provider/viewport-dimensions';

@NgModule({
  imports: [
    ProviderApplicationUrlModule,
    ApplicationStateProviderModule,
    ProviderHardwareBackButtonModule,
    PlatformProviderModule,
    PollingTriggerProviderModule,
    SubscriptionTriggerProviderModule,
    RefreshTriggerProviderModule,
    ViewportDimensionsProviderModule,
    LoggedInSubscriptionTriggerProviderModule,
    LoggedInRefreshTriggerProviderModule,
    LoggedInPollingTriggerProviderModule,
    NetworkRetryProviderModule,
    ProviderApplicationInfoModule,
  ],
})
export class MobileClientProviderModule {}
