import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { selectSlice } from '@rx-angular/state/selections';
import { FileI18n } from '@t5s/client/i18n/file';
import { Filetype } from '@t5s/client/readonly-constant/file';
import {
  ComponentStyle,
  eachChild,
  establishStackingContext,
  exactHeight,
  flex1Horizontal,
  flex1Vertical,
  flexCenter,
  flexCenterVertical,
  flexColumn,
  flexRow,
  fullWidth,
  padding,
  px,
  rgba,
  spread,
} from '@t5s/client/ui/style/common';
import { getFiletypeFromExtension } from '@t5s/client/util/file';
import { tss } from '@t5s/client/util/tss';
import { iconCloseRounded, iconMoreHorizRounded } from '@t5s/mobile-client/asset';
import { I18nObject } from '@t5s/mobile-client/i18n/common';
import { FileAttachmentPreviewI18n } from '@t5s/mobile-client/i18n/file-attachment-preview';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
import { SafeAreaDimensionsObservable } from '@t5s/mobile-client/provider-token/safe-area';
import { Swiper, swiperClass } from '@t5s/mobile-client/ui/component/swiper';
import { ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { ViewComponent, ViewState } from '@t5s/mobile-client/ui/view/common';
import { getFileThumbnailGraphic } from '@t5s/mobile-client/util/file';
import { FilesViewerFile } from '@t5s/mobile-client/value-object/files-viewer';
import { SafeAreaDimensions } from '@t5s/mobile-client/value-object/safe-area';
import { FlimValAttachmentCreatedViaType, FlimValImageCreatedViaType } from '@t5s/shared/gql';
import { FormatFunctions } from '@t5s/shared/i18n/datetime';
import { filterOnlyFalse, filterOnlyPresent, filterOnlyTrue } from '@t5s/shared/util/rxjs';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

const { formatDeltaDateTime } = FormatFunctions;

interface FileAttachmentPreviewViewState extends ViewState {
  safeArea: SafeAreaDimensions;

  initialActiveFileIndex?: number;
  files?: FilesViewerFile[];
  activeFileIndex: number;

  hideHeader: boolean;

  isLoading?: boolean;
}

const getActiveFileFromState = ({ files, activeFileIndex }: FileAttachmentPreviewViewState) => {
  if (!files || activeFileIndex === undefined) {
    return undefined;
  }

  return files[activeFileIndex];
};

const getSelectedFileType = (selectedFile: FilesViewerFile) => getFiletypeFromExtension(selectedFile?.extension);

const allowToggleHideHeader = (selectedFile?: FilesViewerFile): boolean => {
  if (!selectedFile) {
    return false;
  }

  const filetype = getSelectedFileType(selectedFile);

  return filetype === Filetype.IMAGE || filetype === Filetype.VIDEO;
};

@Component({
  selector: 't5s-file-attachment-preview-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostSpread],
  template: `
    <div data-cy="t5s-mc-file-attachment-preview-view" [class]="containerClass$ | push">
      <!-- Header -->
      <div [class]="headerClass$ | push">
        <!-- close button -->
        <t5s-filled-header-icon-button
          [style.flex]="0"
          [icon]="iconCloseRounded"
          (btnClick)="closeClick.emit()"
        ></t5s-filled-header-icon-button>

        <div [class]="titleContainerClass">
          <t5s-single-line-text
            [style.max-width.%]="100"
            [textOverflow]="'initial'"
            [font]="Font.black18px"
            [fgColor]="Color.lightest"
          >
            {{ activeFileTitle$ | push }}</t5s-single-line-text
          >
          <t5s-single-line-text [style.max-width.%]="100" [font]="Font.regular12px" [fgColor]="Color.lightest">
            {{ activeFileSubtitle$ | push }}</t5s-single-line-text
          >
        </div>

        <!-- more button -->
        <t5s-filled-header-icon-button
          [style.flex]="0"
          [icon]="iconMoreHorizRounded"
          (btnClick)="emitShowOpenWithClick()"
        ></t5s-filled-header-icon-button>
      </div>

      <!-- Body -->
      <div [class]="bodyClass$ | push">
        <div
          [class]="swiperClass"
          [style.width.%]="100"
          [style.height.%]="100"
          (t5sPressDisableLongpress)="toggleHideHeader()"
        >
          <swiper
            [index]="activeFileIndex$ | push"
            [zoom]="true"
            [allowSlideNext]="enableSwiping$ | push"
            [allowSlidePrev]="enableSwiping$ | push"
            [loop]="false"
            [spaceBetween]="40"
            (slideChange)="slideChange($event)"
          >
            <ng-container *ngFor="let file of files$ | push; trackBy: trackById">
              <ng-template swiperSlide>
                <!-- File type switch -->
                <ng-container [ngSwitch]="getSelectedFileType(file)">
                  <!-- IMAGE -->
                  <ng-container *ngSwitchCase="Filetype.IMAGE">
                    <div class="swiper-zoom-container">
                      <img [src]="file.thumbnail?.large || file.viewUrl" />
                    </div>
                  </ng-container>

                  <!-- VIDEO -->
                  <ng-container *ngSwitchCase="Filetype.VIDEO">
                    <div class="swiper-zoom-container">
                      <video
                        #videoPlayer
                        controls
                        muted
                        preload="metadata"
                        [class]="videoClass"
                        (click)="$event.stopPropagation()"
                      >
                        <source [src]="getVideoSource(file)" [type]="file.mimetype" />
                      </video>
                    </div>
                  </ng-container>

                  <!-- REST (no preview) -->
                  <ng-container *ngSwitchDefault>
                    <!-- Loading -->
                    <ng-container *ngIf="isLoading$ | push; else content">
                      <t5s-loading-spinner-spread></t5s-loading-spinner-spread>
                    </ng-container>

                    <!-- content with swiper slides -->
                    <ng-template #content>
                      <div [class]="filePlaceholderClass">
                        <!-- Graphic -->
                        <t5s-graphic [size]="68" [graphic]="getFileThumbnailGraphic(file.extension)"></t5s-graphic>

                        <t5s-divider [height]="8"></t5s-divider>

                        <!-- Label -->
                        <ng-container *ngIf="i18n$ | push as i18n">
                          <t5s-single-line-text [fgColor]="Color.lightest" [font]="Font.medium14px" [lineHeight]="17">
                            {{ getLabel(i18n, file) }}
                          </t5s-single-line-text>
                        </ng-container>

                        <t5s-divider [height]="24"></t5s-divider>

                        <!-- Open button -->
                        <button [class]="openButtonClass" (t5sPressDisableLongpress)="emitOpenClick()">
                          <t5s-single-line-text
                            [centered]="true"
                            [font]="Font.medium14px"
                            [lineHeight]="17"
                            [fgColor]="Color.lightest"
                          >
                            {{ openButtonLabel$ | push }}
                          </t5s-single-line-text>
                        </button>
                      </div>
                    </ng-template>
                  </ng-container>
                </ng-container>
              </ng-template>
            </ng-container>
          </swiper>
        </div>
      </div>
    </div>
  `,
})
export class FileAttachmentPreviewViewComponent
  extends ViewComponent<FileAttachmentPreviewViewState>
  implements AfterViewInit
{
  readonly I18n = FileAttachmentPreviewI18n;
  readonly getFileThumbnailGraphic = getFileThumbnailGraphic;

  readonly swiperClass = swiperClass;

  readonly iconCloseRounded = iconCloseRounded;
  readonly iconMoreHorizRounded = iconMoreHorizRounded;

  readonly Filetype = Filetype;

  readonly getSelectedFileType = getSelectedFileType;

  constructor(readonly i18nObservable$: I18nObjectObservable, readonly safeArea$: SafeAreaDimensionsObservable) {
    super(i18nObservable$);

    this.set({ activeFileIndex: 0, hideHeader: false });

    this.connect(safeArea$.pipe(map((safeArea) => ({ safeArea }))));

    const allowToggleHideHeader$ = this.activeFile$.pipe(map(allowToggleHideHeader), distinctUntilChanged());

    // if active file changes to a file that does not allow header toggle, reset to visible
    this.hold(allowToggleHideHeader$.pipe(filter((allowToggleHeader) => !allowToggleHeader)), () =>
      this.set({ hideHeader: false }),
    );
  }

  ngAfterViewInit() {
    // this needs to be done in after view init, as swiper does not get it otherwise. Lo siento
    const initialActiveFileIndex$ = this.select(
      selectSlice(['initialActiveFileIndex']),
      map(({ initialActiveFileIndex }) => initialActiveFileIndex),
      filterOnlyPresent(),
      distinctUntilChanged(),
    );
    this.hold(initialActiveFileIndex$, (activeFileIndex) => this.set({ activeFileIndex }));
  }

  @Input() set isLoading(isLoading: boolean | Observable<boolean>) {
    this.setProperty('isLoading', isLoading);
  }

  @Input() set files(files: FilesViewerFile[] | Observable<FilesViewerFile[]>) {
    this.setProperty('files', files);
  }

  @Input() set initialActiveFileIndex(initialActiveFileIndex: number | Observable<number>) {
    this.setProperty('initialActiveFileIndex', initialActiveFileIndex);
  }

  @Output() closeClick = new EventEmitter<never>();
  @Output() openClick = new EventEmitter<{ file: FilesViewerFile }>();
  @Output() showOpenWithClick = new EventEmitter<{ file: FilesViewerFile }>();

  private readonly hideHeader$ = this.state$.pipe(
    map(({ hideHeader }) => hideHeader),
    distinctUntilChanged(),
  );

  @Output() headerHide = this.hideHeader$.pipe(filterOnlyTrue());
  @Output() headerShow = this.hideHeader$.pipe(filterOnlyFalse());

  slideChange(swiper: unknown) {
    const { realIndex } = swiper as Swiper;

    this.set({ activeFileIndex: realIndex });
  }

  readonly files$ = this.state$.pipe(map(({ files }) => files));
  readonly activeFileIndex$ = this.state$.pipe(map(({ activeFileIndex }) => activeFileIndex));

  readonly isLoading$ = this.state$.pipe(map(({ isLoading }) => isLoading));
  readonly enableSwiping$ = this.state$.pipe(map(({ isLoading }) => !isLoading));

  readonly activeFile$ = this.state$.pipe(map(getActiveFileFromState));

  readonly activeFileTitle$ = this.activeFile$.pipe(map((file) => file?.name));

  readonly activeFileSubtitle$: Observable<string> = this.state$.pipe(
    map((state) => {
      const activeFile = getActiveFileFromState(state);

      if (!activeFile) {
        return '';
      }

      const { createdAt, author, createdVia } = activeFile;
      const { i18n } = state;

      if (!createdAt || !i18n) {
        return '';
      }

      const timestamp = formatDeltaDateTime(i18n, createdAt);

      // fallback for no author, email or workflow def
      if (!author) {
        if (
          createdVia === FlimValAttachmentCreatedViaType.EMAIL_TO_APP ||
          createdVia === FlimValImageCreatedViaType.EMAIL_TO_APP
        ) {
          return FileAttachmentPreviewI18n.translate(i18n, {
            key: FileAttachmentPreviewI18n.key.authorInboundEmailSubtitle,
            params: { timestamp },
          });
        } else {
          return FileAttachmentPreviewI18n.translate(i18n, {
            key: FileAttachmentPreviewI18n.key.authorAutomationSubtitle,
            params: { timestamp },
          });
        }
      }

      const authorName = author.primaryName;

      return FileAttachmentPreviewI18n.translate(i18n, {
        key: FileAttachmentPreviewI18n.key.authorSubtitle,
        params: { authorName, timestamp },
      });
    }),
  );

  readonly containerClass$ = this.state$.pipe(
    map(({ safeArea: { top } }) =>
      tss({
        ...spread,
        ...flexColumn,
        backgroundColor: 'black',
        ...establishStackingContext,
      }),
    ),
  );

  readonly videoClass = tss({
    ...fullWidth,
    objectFit: 'contain',
    objectPosition: '50% 50%',
  });

  readonly headerClass$ = this.state$.pipe(
    map(({ hideHeader, safeArea: { top } }) =>
      tss({
        position: 'relative',
        padding: padding(6 + top, 16, 6),
        backgroundColor: rgba(15, 15, 15, 0.6),
        opacity: hideHeader ? 0 : 1,
        transition: 'opacity 0.25s ease-in-out',
        flex: 0,
        zIndex: 1,
        ...flexCenterVertical,
        ...flexRow,
      }),
    ),
  );

  readonly titleContainerClass = tss({
    ...flexCenter,
    ...flexColumn,
    ...flex1Horizontal,
    paddingLeft: px(8),
    paddingRight: px(8),
  });

  readonly bodyClass$ = this.state$.pipe(
    map(({ safeArea: { bottom, top } }) =>
      tss({
        ...flex1Vertical,
        position: 'relative',
        backgroundColor: '#15202B',
        marginTop: px(-45),
        paddingBottom: px(bottom),
        paddingTop: px(top),
        zIndex: 0,
      }),
    ),
  );

  readonly filePlaceholderClass = tss({ ...spread, ...flexCenter, ...flexColumn });

  readonly openButtonClass = tss({
    position: 'relative',
    backgroundColor: ThemeColorVar.primary,
    padding: padding(8, 12),
    borderRadius: px(1000),
    ...exactHeight(16 + 8 + 8),
    ...eachChild({
      ...exactHeight(16),
    }),
  });

  readonly openButtonLabel$ = this.state$.pipe(
    map(({ i18n }) => FileAttachmentPreviewI18n.translate(i18n, FileAttachmentPreviewI18n.key.openButtonLabel)),
  );

  readonly headerBtnClass = tss({
    position: 'absolute',
    top: px(13),
    left: px(15),
  });

  emitShowOpenWithClick() {
    const { files = [], activeFileIndex } = this.get();
    const activeFile = files[activeFileIndex];

    if (!activeFile) {
      return;
    }

    this.showOpenWithClick.emit({ file: activeFile });
  }

  emitOpenClick() {
    const { files = [], activeFileIndex } = this.get();
    const activeFile = files[activeFileIndex];

    if (!activeFile) {
      return;
    }

    this.openClick.emit({ file: activeFile });

    return false;
  }

  toggleHideHeader() {
    const state = this.get();

    // check whether header can be toggled for active file
    if (!allowToggleHideHeader(getActiveFileFromState(state))) {
      return;
    }

    const { hideHeader } = state;

    if (hideHeader) {
      this.set({ hideHeader: false });
    } else {
      this.set({ hideHeader: true });
    }
  }

  getVideoSource(selectedFile: FilesViewerFile): string {
    const { viewUrl } = selectedFile;
    return `${viewUrl}#t=0.001`; // this ensures iOS Safari shows a preview
  }

  getLabel(i18n: I18nObject, file: FilesViewerFile) {
    const filetype = getFiletypeFromExtension(file?.extension);
    const label = FileI18n.translate(i18n, (FileI18n.key as any)[filetype]) || file?.extension?.toUpperCase();
    return label;
  }
}
