import gql from 'graphql-tag';
import {
  AudioRoomNotificationDtoNoNestingFragment,
  AudioRoomPreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { AudioRoomNotificationActivityDtoFragment } from './audio-room-notification-activity-dto.fragment';

export const AudioRoomNotificationDtoFragment = gql`
  fragment AudioRoomNotificationDtoFragment on AudioRoomNotificationDto {
    ...AudioRoomNotificationDtoNoNesting
    audioRoom {
      ...AudioRoomPreviewDtoNoNesting
    }
    activities(first: 1) {
      ...AudioRoomNotificationActivityDtoFragment
    }
  }
  ${AudioRoomNotificationDtoNoNestingFragment}
  ${AudioRoomPreviewDtoNoNestingFragment}
  ${AudioRoomNotificationActivityDtoFragment}
`;
