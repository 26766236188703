import gql from 'graphql-tag';
import { ThumbnailDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const UserProfilePreviewDtoOmitOrgFragment = gql`
  fragment UserProfilePreviewDtoOmitOrgFragment on UserProfilePreviewDto {
    id
    email
    primaryName
    secondaryName
    jobDescription
    profilePicture {
      ...ThumbnailDtoNoNesting
    }
  }
  ${ThumbnailDtoNoNestingFragment}
`;
