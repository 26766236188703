import gql from 'graphql-tag';
import { ConvertSingleTextFlimToMultiLinkFlimResultDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../../flim-def';

export const ConvertSingleTextFlimToMultiLinkFlimResultDtoFragment = gql`
  fragment ConvertSingleTextFlimToMultiLinkFlimResultDtoFragment on ConvertSingleTextFlimToMultiLinkFlimResultDto {
    ...ConvertSingleTextFlimToMultiLinkFlimResultDtoNoNesting

    flimDef {
      ...FullFlimDef
    }
  }
  ${ConvertSingleTextFlimToMultiLinkFlimResultDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
