import { ApiVersion } from '@t5s/shared/readonly-constant/api';
import { Observable } from 'rxjs';

/**
 * Shared provider token for an HTTP client that can be used to make calls to the Tape internal API. This API is only used by Tape client applications.
 * This provider token is used by the shared API services and has to be provided by each client application (web, mobile, e2e, etc.) so that the shared services can be used.
 * This way, all consumers have a consistent experience using the API via the shared services instead of having to provide type-safe HTTP calls themselves.
 */
export abstract class ApiHttpClient {
  protected readonly baseUrl: string;
  protected readonly version: ApiVersion;
  protected activeUserId?: number;

  constructor({ baseUrl, version }: { baseUrl: string; version: ApiVersion }) {
    this.baseUrl = baseUrl;
    this.version = version;
  }

  /**
   * Make a POST request to the Tape internal API.
   */
  abstract post<TInput extends {}, TOutput extends {}>({
    path,
    data,
    headers,
  }: {
    path: string;
    data?: TInput;
    headers?: { [header: string]: string | undefined | string[] };
  }): Observable<{ data: TOutput }>;

  /**
   * Upload a file to the Tape internal API.
   */
  abstract upload<TOutput extends {}>({
    path,
    data,
  }: {
    path: string;
    data: FormData;
  }): Observable<{ data?: TOutput; uploadProgress?: number }>;

  /**
   * Set the active user ID for the current session, which is set as an HTTP header on all subsequent API calls by this client.
   * @param userId The ID of the user that should be set as the active user.
   */
  setActiveUserId(activeUserId: number): void {
    this.activeUserId = activeUserId;
  }
}
