import gql from 'graphql-tag';
import { ChangeUserEmailVerificationSessionDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const RequestChangeUserEmailVerificationResultFragment = gql`
  fragment RequestChangeUserEmailVerificationResult on RequestChangeUserEmailVerificationResult {
    ... on ChangeUserEmailVerificationSessionDto {
      ...ChangeUserEmailVerificationSessionDtoNoNesting
    }
    ... on RequestChangeUserEmailVerificationRejectionDto {
      result
    }
  }

  ${ChangeUserEmailVerificationSessionDtoNoNestingFragment}
`;
