import {
  ActiveUserBlabItemInfoDto,
  BlabDefDto,
  BlabItemActivityEdge,
  BlabItemDetailDto,
  FlimDefUnionType,
  FlimValUnionType,
  IncomingRelationFlimDefPreviewDto,
  PageInfo,
} from '@t5s/shared/gql';
import { BlabItemCommentInputAttachmentValobj } from './blab-item-comment-input-attachments.valobj';

export enum BlabItemDisplayLoadingState {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  PRELOADING = 'PRELOADING',
  LOADED = 'LOADED',
  RELOADING = 'RELOADING',
  EXCEPTION = 'EXCEPTION',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
}

interface BlabItemDisplayActiveUserBlabItemInfoState extends ActiveUserBlabItemInfoDto {}

export enum BlabItemDisplayActiveUserBlabItemInfoLoadingState {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  RELOADING = 'RELOADING',
  EXCEPTION = 'EXCEPTION',
}

interface BlabItemDisplayBlabItemActivityState {
  blabItemActivities: BlabItemActivityEdge[];
  pageInfo: PageInfo;
}

export enum BlabItemDisplayBlabItemActivityLoadingState {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  RELOADING = 'RELOADING',
  EXCEPTION = 'EXCEPTION',
}
interface BlabItemDisplayIncomingRelationsState {
  incomingRelationFlimDefs: IncomingRelationFlimDefPreviewDto[];
}

export enum BlabItemDisplayIncomingRelationsLoadingState {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  RELOADING = 'RELOADING',
  EXCEPTION = 'EXCEPTION',
}

export interface BlabItemDisplayBlabItemDetail extends BlabItemDetailDto {
  flimVals?: FlimValUnionType[];
  blabDef: BlabDefDto & { flimDefs: FlimDefUnionType[] };
}

export interface BlabItemDisplayCommentInputState {
  content?: string;
  attachments?: BlabItemCommentInputAttachmentValobj[];
}

export interface BlabItemDisplayClientModel {
  id: number;
  loadingState: BlabItemDisplayLoadingState;

  blabItem?: BlabItemDisplayBlabItemDetail | undefined;

  activeUserBlabItemInfoLoadingState: BlabItemDisplayActiveUserBlabItemInfoLoadingState;
  activeUserBlabItemInfo?: BlabItemDisplayActiveUserBlabItemInfoState;

  blabItemActivityLoadingState: BlabItemDisplayBlabItemActivityLoadingState;
  blabItemActivity?: BlabItemDisplayBlabItemActivityState;

  incomingRelationsLoadingState: BlabItemDisplayIncomingRelationsLoadingState;
  incomingRelations?: BlabItemDisplayIncomingRelationsState;

  commentInput: BlabItemDisplayCommentInputState;
}
