import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ComponentStyle } from '@t5s/client/ui/style/common';
import {
  EmojiPickerActions,
  selectEmojiPickerFrequentlyUsedEmojis,
  selectEmojiPickerSearchMode,
  selectEmojiPickerSearchQuery,
  selectEmojiPickerSearchResults,
} from '@t5s/mobile-client/business-logic/emoji-picker';
import { Page } from '@t5s/mobile-client/page/common';
import { EmojiPickerViewComponent } from '@t5s/mobile-client/ui/view/emoji-picker';
import { EmojiData, EmojiPickerPosition } from '@t5s/mobile-client/value-object/emoji-picker';

@Component({
  selector: 't5s-emoji-picker-page',
  styles: [ComponentStyle.HostSpread],
  template: `<t5s-emoji-picker-view
    [searchQuery]="searchQuery$"
    [searchMode]="searchMode$"
    [searchResults]="searchResults$"
    [frequentlyUsedEmojis]="frequentlyUsedEmojis$"
    (emojiClick)="emojiClick($event)"
    (positionChange)="positionChange($event)"
    (searchQueryChange)="searchQueryChange($event)"
    (searchModeChange)="searchModeChange($event)"
    (clearSearchClick)="clearSearchClick()"
  ></t5s-emoji-picker-view>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiPickerPageComponent extends Page {
  constructor(private readonly store$: Store, actions$: Actions) {
    super();

    // Open
    this.hold(actions$.pipe(ofType(EmojiPickerActions.open)), () => {
      this.emojiPicker?.open();
    });

    // Close
    this.hold(actions$.pipe(ofType(EmojiPickerActions.close)), () => {
      this.emojiPicker?.close();
    });
  }

  @ViewChild(EmojiPickerViewComponent) emojiPicker?: EmojiPickerViewComponent;

  readonly searchQuery$ = this.store$.select(selectEmojiPickerSearchQuery);
  readonly searchMode$ = this.store$.select(selectEmojiPickerSearchMode);
  readonly searchResults$ = this.store$.select(selectEmojiPickerSearchResults);

  readonly frequentlyUsedEmojis$ = this.store$.select(selectEmojiPickerFrequentlyUsedEmojis);

  emojiClick({ id, emoji }: EmojiData) {
    this.store$.dispatch(EmojiPickerActions.picked({ id, emoji }));
  }

  positionChange({ position }: { position: EmojiPickerPosition }) {
    this.store$.dispatch(EmojiPickerActions.setPosition({ position }));
  }

  searchQueryChange({ searchQuery }: { searchQuery: string }) {
    this.store$.dispatch(EmojiPickerActions.setSearchQuery({ searchQuery }));
  }

  searchModeChange({ searchMode }: { searchMode: boolean }) {
    if (searchMode) {
      this.store$.dispatch(EmojiPickerActions.enterSearchMode());
      this.emojiPicker?.expand();
    } else {
      this.store$.dispatch(EmojiPickerActions.leaveSearchMode());
    }
  }

  clearSearchClick() {
    this.store$.dispatch(EmojiPickerActions.setSearchQuery({ searchQuery: '' }));
  }
}
