import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { isSameMonth, isSameYear, isThisYear } from '@t5s/shared/util/date';
import {
  formatDateViaLanguage,
  formatSameMonthDateRangeShowYear,
  formatSameYearDateRangeShowYear,
  getDateTimeFormats,
  isApplicableI18n,
  normalizeDate,
} from '../common';

export function formatBadgeDateRange(
  i18n: SharedI18n,
  dateLeft: string | Date | undefined,
  dateRight: string | Date | undefined,
): string | undefined {
  if (!dateLeft || !dateRight || !isApplicableI18n(i18n)) {
    return undefined;
  }

  dateLeft = normalizeDate(dateLeft);
  dateRight = normalizeDate(dateRight);

  const dateTimeFormats = getDateTimeFormats(i18n);

  if (isThisYear(dateLeft) && isSameYear(dateLeft, dateRight)) {
    // current year, same year
    if (isSameMonth(dateLeft, dateRight)) {
      return formatSameMonthDateRangeShowYear(dateLeft, dateRight, i18n);
    } else {
      return formatSameYearDateRangeShowYear(dateLeft, dateRight, i18n);
    }
  } else if (isSameYear(dateLeft, dateRight)) {
    // another year, same year
    if (isSameMonth(dateLeft, dateRight)) {
      return formatSameMonthDateRangeShowYear(dateLeft, dateRight, i18n);
    } else {
      return formatSameYearDateRangeShowYear(dateLeft, dateRight, i18n);
    }
  } else {
    // two different years
    const dateFormat = dateTimeFormats.date.fullNonAbbr;
    const formattedLeft = formatDateViaLanguage(dateLeft, dateFormat, i18n);
    const formattedRight = formatDateViaLanguage(dateRight, dateFormat, i18n);
    return `${formattedLeft} – ${formattedRight}`;
  }

  throw new Error('Not implemented');
}
