import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { formatDate, formatTime, getDateTimeFormats, isApplicableI18n, normalizeDate } from '../common';

export function formatTableDateTime(i18n: SharedI18n, date?: string | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  const dateTimeFormats = getDateTimeFormats(i18n);

  const dateFormat = dateTimeFormats.date.short;
  const timeFormat = dateTimeFormats.time.normalized;

  const formattedDate = formatDate(date, dateFormat, i18n);
  const formattedTime = formatTime(date, timeFormat, i18n);

  return `${formattedDate} ${formattedTime}`;
}
