import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { USERID_HEADER_NAME } from '@t5s/shared/readonly-constant/authentication';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { ACTIVE_USER_ID } from '../injection-tokens';

@Injectable()
export class UserContextInterceptor implements HttpInterceptor {
  constructor(@Inject(ACTIVE_USER_ID) private readonly activeUserId$: Observable<number>) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.activeUserId$.pipe(
      first(),
      switchMap((activeUserId) => {
        // do not modify request if no active user id present
        if (!activeUserId) {
          return next.handle(req);
        }

        // set header otherwise
        return next.handle(
          req.clone({
            headers: req.headers.append(USERID_HEADER_NAME, `${activeUserId}`),
          }),
        );
      }),
    );
  }
}
