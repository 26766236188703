import gql from 'graphql-tag';
import { ActiveUserMultiRelationSplitBySectionDtoNoNestingFragment } from '../../../../generated/graphql-fragments';

export const ActiveUserMultiRelationSplitBySectionDtoFragment = gql`
  fragment ActiveUserMultiRelationSplitBySectionDto on ActiveUserMultiRelationSplitBySectionDto {
    ...ActiveUserMultiRelationSplitBySectionDtoNoNesting
    blabItem {
      id
      title
      blabDef {
        id
        blabName
        itemName
        icon
        iconv2
        itemIcon
        workspace {
          id
          name
        }
      }
    }
  }
  ${ActiveUserMultiRelationSplitBySectionDtoNoNestingFragment}
`;
