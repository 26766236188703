import { NgModule } from '@angular/core';
import { AssetComponentModule } from '@t5s/mobile-client/ui/component/asset';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { NavigationBarButtonComponent } from './navigation-bar-button.component';
import { NavigationBarComponent } from './navigation-bar.component';

const components = [NavigationBarComponent, NavigationBarButtonComponent];

@NgModule({
  imports: [ComponentCommonModule, AssetComponentModule],
  declarations: components,
  exports: components,
})
export class NavigationBarComponentModule {}
