import gql from 'graphql-tag';
import { SepaDebitPaymentMethodDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const SepaDebitPaymentMethodDtoFragment = gql`
  fragment SepaDebitPaymentMethodDtoFragment on SepaDebitPaymentMethodDto {
    ...SepaDebitPaymentMethodDtoNoNesting
    createdByUser {
      id
      primaryName
    }
  }
  ${SepaDebitPaymentMethodDtoNoNestingFragment}
`;
