import gql from 'graphql-tag';
import { BlabItemActivityDtoFragment } from './blab-item-activity-dto.fragment';

export const BlabItemCreatedActivityFragment = gql`
  fragment BlabItemCreatedActivityFragment on BlabItemCreatedActivityDto {
    ...BlabItemActivityDtoFragment
    creationType
  }
  ${BlabItemActivityDtoFragment}
`;
