import { angularStyles } from './angular';
import { cdkStyles } from './cdk';
import { fontFaceStyles } from './font';
import { reset } from './reset';
import { SelectionStyles } from './selection.styles';

export const globalStyles = `
  ${reset}
  ${SelectionStyles}
  ${angularStyles}
  ${cdkStyles}
  ${fontFaceStyles}
`;
