import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { selectActiveOutlet } from '@t5s/mobile-client/business-logic/logged-in-root';
import { LoggedInRefreshTriggerObservable } from '@t5s/mobile-client/provider-token/logged-in-refresh-trigger';
import {
  LoggedInSubscriptionEndTriggerObservable,
  LoggedInSubscriptionStartTriggerObservable,
} from '@t5s/mobile-client/provider-token/logged-in-subscription-trigger';
import { PushNotificationActions } from '@t5s/mobile-client/business-logic/push-notification';
import { NavigationBarItem } from '@t5s/mobile-client/value-object/logged-in-root';
import { GqlChatConversationService } from '@t5s/shared/gql-services';
import { PaginationSize } from '@t5s/shared/util/pagination';
import { merge } from 'rxjs';
import { debounceTime, filter, map, mergeMap, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { ClientSideLiveSubscriptionException } from '@t5s/client/value-object/live-subscription-context';
import { ActiveUserIdObservable } from '@t5s/mobile-client/provider-token/active-user';
import { ChatActions } from './chat.actions';

const outlet: NavigationBarItem = 'chat';

@Injectable()
export class ChatEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly gqlChatConversations: GqlChatConversationService,
    private readonly subscriptionStartTrigger$: LoggedInSubscriptionStartTriggerObservable,
    private readonly subscriptionEndTrigger$: LoggedInSubscriptionEndTriggerObservable,
    private readonly refreshTrigger$: LoggedInRefreshTriggerObservable,
    private readonly activeUserId$: ActiveUserIdObservable,
  ) {}

  readonly outletActive$ = this.store$
    .select(selectActiveOutlet)
    .pipe(filter((activeOutlet) => activeOutlet === outlet));

  readonly outletInactive$ = this.store$
    .select(selectActiveOutlet)
    .pipe(filter((activeOutlet) => activeOutlet !== outlet));

  private readonly PAGE_SIZE = PaginationSize.PSEUDO_INFINITE;

  readonly loadConversations$ = createEffect(() =>
    this.actions$.pipe(ofType(ChatActions.loadConversations)).pipe(
      switchMap(() =>
        this.gqlChatConversations.getConversationConnection(this.PAGE_SIZE).pipe(
          map(({ edges }) => edges.map((edge) => edge.node)),
          map((conversations) => ChatActions.loadConversationsSuccess({ conversations })),
          catchErrorDispatch(ChatActions.loadConversationsException),
        ),
      ),
    ),
  );

  readonly refreshTriggerLoadConversations$ = createEffect(() =>
    this.refreshTrigger$.pipe(debounceTime(500), map(ChatActions.loadConversations)),
  );

  readonly remotePushReceivedRefreshChatConversations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PushNotificationActions.remotePushNotificationReceivedAppInBackground),
      debounceTime(1000),
      map(ChatActions.loadConversations),
    ),
  );

  readonly subscribeToConversations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.subscribeToConversations),
      mergeMap(() => {
        const imperativeUnsubscribe$ = this.actions$.pipe(ofType(ChatActions.unsubscribefromConversations));

        const shouldSubscribe$ = this.subscriptionStartTrigger$.pipe(startWith(true), debounceTime(100));
        const shouldUnsubscribe$ = merge(imperativeUnsubscribe$, this.subscriptionEndTrigger$);

        return shouldSubscribe$.pipe(
          switchMap(() =>
            this.gqlChatConversations.subscribeToUserConversationPreviews().pipe(
              map((conversation) => ChatActions.conversationsUpdate({ conversation })),
              catchErrorDispatch(ChatActions.conversationsSubscriptionError),
              startWith(ChatActions.subscribeToConversationsSuccess()),
              takeUntil(shouldUnsubscribe$),
            ),
          ),
        );
      }),
    ),
  );

  private readonly REESTABLISHMENT_DEBOUNCE = 3000;

  /** Reestablish subscription in case it errored along the way, after a time debounce. */
  readonly reestablishConversationSubscriptionOnError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatActions.conversationsSubscriptionError),
      filter(({ error }) => !(error instanceof ClientSideLiveSubscriptionException)),
      debounceTime(this.REESTABLISHMENT_DEBOUNCE),
      concatLatestFrom(() => this.activeUserId$),
      filter(([, activeUserId]) => activeUserId !== undefined),
      map(ChatActions.subscribeToConversations),
    ),
  );
}
