import { createAction, props } from '@ngrx/store';
import {
  BlabItemActivityThreadPushNotificationPayloadValObj,
  BlabItemPushNotificationPayloadValObj,
} from '@t5s/shared/value-object/push-notification';

const PREFIX = '[mobile-client-blab-item-notification-push]';

export const BlabItemNotificationPushActions = {
  reset: createAction(`${PREFIX} reset`),
  blabItemPushOpened: createAction(
    `${PREFIX} blabitem push opened by user`,
    props<{ payload: BlabItemPushNotificationPayloadValObj }>(),
  ),
  blabItemActivityThreadPushOpened: createAction(
    `${PREFIX} activity thread push opened by user`,
    props<{ payload: BlabItemActivityThreadPushNotificationPayloadValObj }>(),
  ),

  blabItemPushReceived: createAction(
    `${PREFIX} blabitem push received while app active (or standby)`,
    props<{ payload: BlabItemPushNotificationPayloadValObj }>(),
  ),
  blabItemActivityThreadPushReceived: createAction(
    `${PREFIX} activity thread push received while app active (or standby)`,
    props<{ payload: BlabItemActivityThreadPushNotificationPayloadValObj }>(),
  ),
  silentPushReceived: createAction(`${PREFIX} silent push received while app active (or standby)`),
  unknownPushReceived: createAction(`${PREFIX} unknown push received while app active (or standby)`),
};
