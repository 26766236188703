import { Language } from '@t5s/shared/gql';
import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { formatDateViaLanguage, isApplicableI18n, normalizeDate } from '../common';

export function formatQuarterDateShort(i18n: SharedI18n, date?: string | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  switch (i18n.language) {
    case Language.EN:
      return formatDateViaLanguage(date, `qqq ’yy`, i18n);
    case Language.DE:
      return formatDateViaLanguage(date, `qqq/yy`, i18n);
  }
}
