import { DateFormat, Language } from '@t5s/shared/gql';
import { dateFnsLocales, formatDate } from '@t5s/shared/util/date';

function getDateLocaleFromLanguage({ language }: { language: Language }) {
  if (language === Language.DE) {
    return dateFnsLocales.de;
  }

  return dateFnsLocales.enUS;
}

/** Formats a daterange where dateLeft and dateRight must be inside the same month for the output to make sense*/
export function formatSameMonthDateRangeShowYear(
  dateLeft: Date,
  dateRight: Date,
  i18n: { language: Language; dateFormat: DateFormat },
): string | undefined {
  const locale = getDateLocaleFromLanguage(i18n);
  const { dateFormat } = i18n;

  const year = formatDate(dateLeft, 'yyyy', { locale });

  switch (dateFormat) {
    case DateFormat.SLASH_MM_DD_YYYY: {
      const dayLeft = formatDate(dateLeft, 'd', { locale });
      const dayRight = formatDate(dateRight, 'd', { locale });

      const month = formatDate(dateLeft, 'MMMM', { locale });

      return `${month} ${dayLeft} – ${dayRight}, ${year}`;
    }

    case DateFormat.SLASH_DD_MM_YYYY: {
      const dayLeft = formatDate(dateLeft, 'd', { locale });
      const dayRight = formatDate(dateRight, 'd', { locale });

      const month = formatDate(dateLeft, 'MMMM', { locale });

      return `${month} ${dayLeft} – ${dayRight}, ${year}`;
    }

    case DateFormat.DOT_DD_MM_YYYY: {
      const dayLeft = formatDate(dateLeft, 'd.', { locale });
      const dayRight = formatDate(dateRight, 'd.', { locale });

      const month = formatDate(dateLeft, 'MMMM', { locale });
      return `${dayLeft} – ${dayRight} ${month} ${year}`;
    }
  }
}

/** Formats a daterange where dateLeft and dateRight must be inside the same year (but not the same month) for the output to make sense. */
export function formatSameYearDateRangeShowYear(
  dateLeft: Date,
  dateRight: Date,
  i18n: { language: Language; dateFormat: DateFormat },
): string | undefined {
  const locale = getDateLocaleFromLanguage(i18n);
  const { dateFormat } = i18n;

  const year = formatDate(dateLeft, 'yyyy', { locale });

  switch (dateFormat) {
    case DateFormat.SLASH_MM_DD_YYYY: {
      const dayLeft = formatDate(dateLeft, 'd', { locale });
      const dayRight = formatDate(dateRight, 'd', { locale });

      const monthLeft = formatDate(dateLeft, 'MMMM', { locale });
      const monthRight = formatDate(dateRight, 'MMMM', { locale });

      return `${monthLeft} ${dayLeft} – ${monthRight} ${dayRight}, ${year}`;
    }

    case DateFormat.SLASH_DD_MM_YYYY: {
      const dayLeft = formatDate(dateLeft, 'd', { locale });
      const dayRight = formatDate(dateRight, 'd', { locale });

      const monthLeft = formatDate(dateLeft, 'MMMM', { locale });
      const monthRight = formatDate(dateRight, 'MMMM', { locale });

      return `${monthLeft} ${dayLeft} – ${monthRight} ${dayRight}, ${year}`;
    }

    case DateFormat.DOT_DD_MM_YYYY: {
      const dayLeft = formatDate(dateLeft, 'd.', { locale });
      const dayRight = formatDate(dateRight, 'd.', { locale });
      const monthLeft = formatDate(dateLeft, 'MMMM', { locale });
      const monthRight = formatDate(dateRight, 'MMMM', { locale });

      return `${dayLeft} ${monthLeft} – ${dayRight} ${monthRight} ${year}`;
    }
  }
}
