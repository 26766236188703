import gql from 'graphql-tag';
import {
  MultiPhoneFlimValDtoNoNestingFragment,
  MultiPhoneFlimValEntryDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const MultiPhoneFlimValFragment = gql`
  fragment MultiPhoneFlimVal on MultiPhoneFlimValDto {
    ...MultiPhoneFlimValDtoNoNesting
    multiPhoneEntries {
      ...MultiPhoneFlimValEntryDtoNoNesting
    }
  }
  ${MultiPhoneFlimValDtoNoNestingFragment}
  ${MultiPhoneFlimValEntryDtoNoNestingFragment}
`;
