import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { border, flexCenter, fullWidth, getFontStyle, px } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { font, ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface BottomSheetFullWidthButtonState {
  label?: string;
}

@Component({
  selector: 't5s-bottom-sheet-full-width-button',
  template: `
    <button
      [class]="buttonClass"
      (t5sPressDisableLongpress)="btnClick.emit()"
      [t5sTouchActive]="'background: rgba(0, 0, 0, 0.07)'"
    >
      {{ label$ | push }}
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomSheetFullWidthButtonComponent extends RxComponent<BottomSheetFullWidthButtonState> {
  @Input() set label(label: string | Observable<string>) {
    this.setProperty('label', label);
  }

  @Output() btnClick = new EventEmitter<never>();

  readonly buttonClass = tss({
    ...fullWidth,
    height: px(52),
    color: ThemeColorVar.darkest,
    borderRadius: px(9999),
    ...getFontStyle(font['bold16px']),
    border: border(1, 'solid', ThemeColorVar.dark),
    ...flexCenter,
  });

  readonly label$ = this.select(
    selectSlice(['label']),
    map(({ label }) => label),
  );
}
