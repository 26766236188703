import gql from 'graphql-tag';
import { ReactionDtoFragment } from '../core';
import { UserProfilePreviewDtoFragment } from '../user-profile';
import { WorkspaceBreadcrumbFragment } from '../workspace/workspace-breadcrumb.fragment';
import { WorkspaceActivityReplyDtoFragment } from './workspace-activity-reply-dto.fragment';

/**
 * Fragment containing all common workspace activity properties of the WorkspaceActivityDto interface
 * This fragment is used as a baseline for the activity-type specific fragments
 */
export const WorkspaceActivityDtoFragment = gql`
  fragment WorkspaceActivityDtoFragment on WorkspaceActivityDto {
    id
    type
    createdAt
    author {
      ...UserProfilePreviewDtoFragment
    }
    authorId
    reactions {
      ...ReactionDtoFragment
    }
    numReplies
    replies(first: 2) {
      cursor
      node {
        ...WorkspaceActivityReplyDtoFragment
      }
    }
    workspaceId
    workspace {
      ...WorkspaceBreadcrumbFragment
    }
  }
  ${WorkspaceActivityReplyDtoFragment}
  ${WorkspaceBreadcrumbFragment}
  ${UserProfilePreviewDtoFragment}
  ${ReactionDtoFragment}
`;
