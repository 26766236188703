import { createAction, props } from '@ngrx/store';
import { BottomSheetPosition } from '@t5s/mobile-client/value-object/bottom-sheet';
import { ReactionDto } from '@t5s/shared/gql';

const PREFIX = '[mobile-client-reaction-details]';

export const ReactionDetailsActions = {
  open: createAction(`${PREFIX} open`, props<{ reactions: ReactionDto[] }>()),
  close: createAction(`${PREFIX} close`),
  setPosition: createAction(`${PREFIX} set position`, props<{ position: BottomSheetPosition }>()),
};
