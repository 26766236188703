import gql from 'graphql-tag';
import { ConvertSingleCategoryFlimToStatusFlimResultDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../../flim-def';

export const ConvertSingleCategoryFlimToStatusFlimResultDtoFragment = gql`
  fragment ConvertSingleCategoryFlimToStatusFlimResultDtoFragment on ConvertSingleCategoryFlimToStatusFlimResultDto {
    ...ConvertSingleCategoryFlimToStatusFlimResultDtoNoNesting

    flimDef {
      ...FullFlimDef
    }
  }
  ${ConvertSingleCategoryFlimToStatusFlimResultDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
