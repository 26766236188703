import { createReducer, on } from '@ngrx/store';
import { BottomSheetPosition } from '@t5s/mobile-client/value-object/bottom-sheet';
import { DeviceMediaActions } from './device-media.actions';

export const deviceMediaStateKey = 'deviceMedia';
export interface DeviceMediaState {
  position: BottomSheetPosition;
}

const initialState: DeviceMediaState = {
  position: BottomSheetPosition.IS_BOTTOM,
};

export const deviceMediaReducer = createReducer(
  initialState,
  on(DeviceMediaActions.reset, () => ({ ...initialState })),

  on(DeviceMediaActions.setPosition, (state, { position }) => {
    return { ...state, position };
  }),
);
