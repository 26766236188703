import {
  ActiveBillingSubscriptionDto,
  BillingSubscriptionDto,
  BillingSubscriptionRequiresAction,
  BillingSubscriptionRequiresPayment,
  GetBillingSubscriptionResult,
  NoActiveOrIncompleteBillingSubscriptionDto,
} from '@t5s/shared/gql';
import { isDto } from './dto.type-guard';

export function isNoActiveBillingSubscription(
  candidate?: GetBillingSubscriptionResult,
): candidate is NoActiveOrIncompleteBillingSubscriptionDto {
  return isDto<GetBillingSubscriptionResult>(candidate, 'NoActiveOrIncompleteBillingSubscriptionDto');
}

export function isActiveBillingSubscription(
  candidate?: GetBillingSubscriptionResult,
): candidate is ActiveBillingSubscriptionDto {
  return isDto<GetBillingSubscriptionResult>(candidate, 'ActiveBillingSubscriptionDto');
}

export function billingSubscriptionRequiresAction(
  candidate?: BillingSubscriptionDto,
): candidate is BillingSubscriptionDto & Required<BillingSubscriptionRequiresAction> {
  return !!(candidate as BillingSubscriptionRequiresAction | undefined)?.requiresAction;
}

export function billingSubscriptionRequiresPaymentMethod(
  candidate?: BillingSubscriptionDto,
): candidate is BillingSubscriptionDto & Required<BillingSubscriptionRequiresAction> {
  return !!(candidate as BillingSubscriptionRequiresPayment | undefined)?.requiresPaymentMethod;
}
