import { NgModule } from '@angular/core';
import { ActiveUserIdObservable } from '@t5s/mobile-client/provider-token/active-user';
import {
  LoggedInPollingEndTriggerObservable,
  LoggedInPollingStartTriggerObservable,
} from '@t5s/mobile-client/provider-token/logged-in-polling-trigger';
import {
  PollingEndTriggerObservable,
  PollingStartTriggerObservable,
} from '@t5s/mobile-client/provider-token/polling-trigger';
import { filterOnlyNullish, filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { merge } from 'rxjs';
import { filter, mapTo, withLatestFrom } from 'rxjs/operators';

@NgModule({
  providers: [
    {
      provide: LoggedInPollingStartTriggerObservable,
      useFactory: (
        start$: PollingStartTriggerObservable,
        activeUserId$: ActiveUserIdObservable,
      ): LoggedInPollingStartTriggerObservable => {
        const pollingStart$ = start$.pipe(
          withLatestFrom(activeUserId$),
          filter(([_, activeUserId]) => activeUserId !== undefined),
          mapTo(true),
        );

        return merge(pollingStart$, activeUserId$.pipe(filterOnlyPresent())).pipe(mapTo(true));
      },
      deps: [PollingStartTriggerObservable, ActiveUserIdObservable],
    },
    {
      provide: LoggedInPollingEndTriggerObservable,
      useFactory: (
        end$: PollingEndTriggerObservable,
        activeUserId$: ActiveUserIdObservable,
      ): LoggedInPollingEndTriggerObservable => merge(end$, activeUserId$.pipe(filterOnlyNullish())).pipe(mapTo(false)),
      deps: [PollingEndTriggerObservable, ActiveUserIdObservable],
    },
  ],
})
export class LoggedInPollingTriggerProviderModule {}
