import { IPickStrategies, IsolatedSequence } from './isolated-sequence';

/** Interface for items that can be positioned via an isolated position string */
export interface IsolatedPositionable {
  /** Isolated position string used to order multiple isolated positionable items */
  position: string;
}

interface ICharAlphabet {
  start: string;
  end: string;
}

interface IExtendedCharAlphabet extends ICharAlphabet {
  startHex: string;
  endHex: string;
}

const HEX_BASE = 16;
const MAX_LENGTH = 400;

export class IsolatedPosition {
  private readonly isolatedSequence: IsolatedSequence;
  readonly alphabet: IExtendedCharAlphabet;

  constructor(alphabet: ICharAlphabet, readonly pick: IPickStrategies) {
    this.alphabet = {
      start: alphabet.start,
      startHex: `\\x${alphabet.start.charCodeAt(0).toString(HEX_BASE).toUpperCase()}`,
      end: alphabet.end,
      endHex: `\\x${alphabet.end.charCodeAt(0).toString(HEX_BASE).toUpperCase()}`,
    };

    this.isolatedSequence = new IsolatedSequence(
      { start: this.alphabet.start.charCodeAt(0), end: this.alphabet.end.charCodeAt(0) },
      this.pick,
    );
  }

  private posToSeq(pos: string): number[] {
    return pos.split('').map((char) => char.charCodeAt(0));
  }

  private seqToPos(seq: number[]): string {
    const pos = seq.map((num) => String.fromCharCode(num)).join('');
    // Enforce max-length
    return pos.slice(0, MAX_LENGTH);
  }

  sortCompare<T extends IsolatedPositionable>(item1: T | undefined, item2: T | undefined) {
    const position1 = item1?.position ?? this.alphabet.end;
    const position2 = item2?.position ?? this.alphabet.end;

    if (position1 === position2) {
      return 0;
    }
    return position1 > position2 ? 1 : -1;
  }

  sortItems<T extends IsolatedPositionable>(items: T[]): T[] {
    if (!items) {
      return [];
    }

    return items.sort((item1, item2) => this.sortCompare(item1, item2));
  }

  getBetween({ lowerPosition, upperPosition }: { lowerPosition?: string; upperPosition?: string }): string {
    const lowerSeq = lowerPosition !== undefined ? this.posToSeq(lowerPosition) : undefined;
    const upperSeq = upperPosition !== undefined ? this.posToSeq(upperPosition) : undefined;
    return this.seqToPos(this.isolatedSequence.getBetween(lowerSeq, upperSeq));
  }

  getBetweenItems({
    lowerItem,
    upperItem,
  }: {
    lowerItem?: IsolatedPositionable;
    upperItem?: IsolatedPositionable;
  }): string {
    const lowerPosition: string = lowerItem ? lowerItem.position : this.alphabet.start;
    const upperPosition: string = upperItem ? upperItem.position : this.alphabet.end;

    return this.seqToPos(this.isolatedSequence.getBetween(this.posToSeq(lowerPosition), this.posToSeq(upperPosition)));
  }

  calculateForItem(array: IsolatedPositionable[], index: number): string {
    const pos = this.getBetween({
      lowerPosition: array[index - 1] ? array[index - 1].position : undefined,
      upperPosition: array[index + 1] ? array[index + 1].position : undefined,
    });
    return pos;
  }

  fillPositionForItems<T extends IsolatedPositionable>(array: T[]): T[] {
    for (let i = 0; i < array.length; i++) {
      const el = array[i];
      if (!el.position) {
        el.position = this.getBetweenItems({
          lowerItem: array[i - 1],
          upperItem: array.slice(i + 1).find((el) => el.position), // Find the next bigger item in the array with a position
        });
      }
    }
    return array;
  }

  pickRandom(n = 1): string {
    return this.seqToPos(this.isolatedSequence.pickRandom(n));
  }

  getSlightlyLargerPosition(position: string): string {
    return `${position}${this.alphabet.start}${this.pickRandom(1)}`;
  }

  getOriginalPositionFromSlightlyLargerPosition(slightlyLargerPosition: string): string {
    return slightlyLargerPosition.slice(0, -2);
  }
}
