import {
  IOS_SAFE_AREA_BOTTOM_CSS_VARNAME,
  IOS_SAFE_AREA_LEFT_CSS_VARNAME,
  IOS_SAFE_AREA_RIGHT_CSS_VARNAME,
  IOS_SAFE_AREA_TOP_CSS_VARNAME,
} from '@t5s/mobile-client/readonly-constant/safe-area';

export const iosSafeAreaStyles = `
  :root {
    ${IOS_SAFE_AREA_TOP_CSS_VARNAME}: constant(safe-area-inset-top);
    ${IOS_SAFE_AREA_TOP_CSS_VARNAME}: env(safe-area-inset-top);

    ${IOS_SAFE_AREA_RIGHT_CSS_VARNAME}: constant(safe-area-inset-right);
    ${IOS_SAFE_AREA_RIGHT_CSS_VARNAME}: env(safe-area-inset-right);

    ${IOS_SAFE_AREA_BOTTOM_CSS_VARNAME}: constant(safe-area-inset-bottom);
    ${IOS_SAFE_AREA_BOTTOM_CSS_VARNAME}: env(safe-area-inset-bottom);

    ${IOS_SAFE_AREA_LEFT_CSS_VARNAME}: constant(safe-area-inset-left);
    ${IOS_SAFE_AREA_LEFT_CSS_VARNAME}: env(safe-area-inset-left);
  }
`;
