import { NgModule } from '@angular/core';
import { EmojiModule } from '@t5s/client/util/emoji-mart';
import { AvatarComponentModule } from '@t5s/mobile-client/ui/component/avatar';
import { BottomSheetComponentModule } from '@t5s/mobile-client/ui/component/bottom-sheet';
import { ViewCommonModule } from '@t5s/mobile-client/ui/view/common';
import { ReactionDetailComponent } from './reaction-detail.component';
import { ReactionDetailsViewComponent } from './reaction-details-view.component';

const components = [ReactionDetailsViewComponent, ReactionDetailComponent];

@NgModule({
  imports: [ViewCommonModule, BottomSheetComponentModule, EmojiModule, AvatarComponentModule],
  declarations: components,
  exports: components,
})
export class ReactionDetailsViewModule {}
