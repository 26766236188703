export const BACKDROP_OPACITY = 0.4;

export const SLOW_ANIMATION_MS = 200;
export const FAST_ANIMATION_MS = 100;
export const BOUNCE_BACK_ANIMATION_MS = 900;

export const THRESHOLD_VELOCITY = 0.3;
export const THRESHOLD_DELTA_Y = 50;

export const DEFAULT_DOCKED_HEIGHT = 290;
