import { NgModule } from '@angular/core';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { DividerComponentModule } from '@t5s/mobile-client/ui/component/divider';
import { TextComponentModule } from '@t5s/mobile-client/ui/component/text';
import { PlaceholderLightComponent } from './placeholder-light.component';
import { PlaceholderComponent } from './placeholder.component';

const components = [PlaceholderComponent, PlaceholderLightComponent];

@NgModule({
  imports: [ComponentCommonModule, TextComponentModule, DividerComponentModule],
  declarations: components,
  exports: components,
})
export class PlaceholderComponentModule {}
