import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { GqlUserSearchService } from '@t5s/shared/gql-services';
import { debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { BlabItemDisplayActions } from './blab-item-display.actions';

@Injectable()
export class BlabItemDisplayMentionUserEffects {
  constructor(private readonly actions$: Actions, private readonly userSearchService: GqlUserSearchService) {}

  readonly searchMentionUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.searchMentionUser),
      debounceTime(150),
      switchMap(({ searchQuery, blabItemId }) =>
        this.userSearchService.searchUsersWithBlabItemAccess({ query: searchQuery, blabItemId }).pipe(
          map((userSearchResults) =>
            BlabItemDisplayActions.searchMentionUserSuccess({
              searchQuery,
              userSearchResults: userSearchResults.map(({ user }) => user),
            }),
          ),
          catchErrorDispatch(BlabItemDisplayActions.searchMentionUserException),
          takeUntil(this.actions$.pipe(ofType(BlabItemDisplayActions.clearMentionUserSearchResults))),
        ),
      ),
    ),
  );
}
