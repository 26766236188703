import gql from 'graphql-tag';
import { OrganizationUserDtoNoNestingFragment, ThumbnailDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const OrganizationUserDtoFragment = gql`
  fragment OrganizationUserDtoFragment on OrganizationUserDto {
    ...OrganizationUserDtoNoNesting
    profilePicture {
      ...ThumbnailDtoNoNesting
    }
    invitation {
      id
      status
    }
    profilePicture {
      ...ThumbnailDtoNoNesting
    }
  }
  ${OrganizationUserDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
