import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { ServiceGlobalStyleModule } from '@t5s/client/service/global-style';
import { Theme } from '@t5s/client/ui/style/common';
import { ThemeObservable } from '@t5s/mobile-client/provider-token/theme';
import { fontFamily, mobileClientDefaultTheme } from '@t5s/mobile-client/ui/style/theme';
import { map } from 'rxjs/operators';
import { ApplicationThemeEffects } from './application-theme.effects';
import { applicationThemeReducer, applicationThemeStateKey } from './application-theme.reducer';
import { selectApplicationTheme } from './application-theme.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(applicationThemeStateKey, applicationThemeReducer),
    EffectsModule.forFeature([ApplicationThemeEffects]),
    ServiceGlobalStyleModule,
  ],
  providers: [
    {
      provide: ThemeObservable,
      useFactory: (store$: Store) => store$.select(selectApplicationTheme).pipe(map((theme) => ({ theme }))),
      deps: [Store],
    },
    {
      provide: Theme,
      useValue: {
        ...mobileClientDefaultTheme,
        fontFamily: fontFamily.default,
      } as Theme,
    },
  ],
})
export class BusinessLogicApplicationThemeModule {}
