import gql from 'graphql-tag';
import { ConvertSingleCategoryFlimToSingleTextFlimResultDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../../flim-def';

export const ConvertSingleCategoryFlimToSingleTextFlimResultDtoFragment = gql`
  fragment ConvertSingleCategoryFlimToSingleTextFlimResultDtoFragment on ConvertSingleCategoryFlimToSingleTextFlimResultDto {
    ...ConvertSingleCategoryFlimToSingleTextFlimResultDtoNoNesting

    flimDef {
      ...FullFlimDef
    }
  }
  ${ConvertSingleCategoryFlimToSingleTextFlimResultDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
