import { Timezone } from '@t5s/shared/gql';
import { utcToZonedTime as _utcToZonedTime, zonedTimeToUtc as _zonedTimeToUtc } from 'date-fns-tz';
import { getIsoTimezoneStringFromTimezoneEnum } from './common';

export function utcToZonedTime(baseDate: Date, timezone: Timezone) {
  return _utcToZonedTime(baseDate, getIsoTimezoneStringFromTimezoneEnum(timezone));
}

export function zonedTimeToUtc(baseDate: Date, timezone: Timezone) {
  return _zonedTimeToUtc(baseDate, getIsoTimezoneStringFromTimezoneEnum(timezone));
}
