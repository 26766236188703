import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { NotificationEdgeValObj, NotificationExceptionType } from '@t5s/mobile-client/value-object/notification';
import { idDescSortComparer, strDescSortComparer } from '@t5s/shared/util/sort';
import { MAX_NUM_PERSISTED_NOTIFICATIONS, NotificationsState } from '../common';
import { NotificationActions } from '../notification.actions';
import { AllNotificationsActions } from './all-notifications.actions';

export const allNotificationStateKey = 'allNotifications';
export interface AllNotificationsState extends EntityState<NotificationEdgeValObj>, NotificationsState {}

const selectId = (edge: NotificationEdgeValObj): number => edge.node.id;
const sortComparer = (n1: NotificationEdgeValObj, n2: NotificationEdgeValObj) => {
  if (n1.node.updatedAt !== n2.node.updatedAt) {
    return strDescSortComparer(n1.node.updatedAt, n2.node.updatedAt);
  }

  return idDescSortComparer(n1.node, n2.node);
};

export const preprocessStateForSerialization = (state: AllNotificationsState) => {
  const notifications = selectAll(state).slice(0, MAX_NUM_PERSISTED_NOTIFICATIONS);
  state = adapter.setAll(notifications, state);
  return state;
};

export const adapter = createEntityAdapter<NotificationEdgeValObj>({
  selectId,
  sortComparer,
});

const { selectEntities, selectAll } = adapter.getSelectors();

const initialState: AllNotificationsState = adapter.getInitialState({
  initialized: false,

  isLoading: false,
  isReplaceLoading: false,
  isLoadingMore: false,
});

function getNotificationEdgeById(
  state: AllNotificationsState,
  notificationId: number,
): NotificationEdgeValObj | undefined {
  return selectEntities(state)[notificationId];
}

export const allNotificationsReducer = createReducer(
  initialState,

  on(AllNotificationsActions.hydrateStateSuccess, (state, { state: hydratedState }) => {
    const { entities, ids, pageInfo } = hydratedState;
    return { ...state, entities, ids, hydrated: true, pageInfo };
  }),

  // load sync
  on(AllNotificationsActions.loadSyncNotifications, (state) => ({ ...state, isLoading: true })),
  on(AllNotificationsActions.loadSyncNotificationsException, (state) => ({
    ...state,
    isLoading: false,
    loadSyncException: NotificationExceptionType.UNKNOWN,
  })),
  on(AllNotificationsActions.loadSyncNotificationsSuccess, (state, { edges, pageInfo }) =>
    adapter.upsertMany(
      edges.map((edge) => ({ ...edge, node: { ...edge.node, _read: edge.node.read } })),
      {
        ...state,
        isLoading: false,
        initialized: true,
        hydrated: true,
        pageInfo,
        loadMoreException: undefined,
        loadSyncException: undefined,
        loadReplaceException: undefined,
      },
    ),
  ),

  // load replace
  on(AllNotificationsActions.loadReplaceNotifications, (state) => ({ ...state, isReplaceLoading: true })),
  on(AllNotificationsActions.loadReplaceNotificationsException, (state) => ({
    ...state,
    isReplaceLoading: false,
    loadReplaceException: NotificationExceptionType.UNKNOWN,
  })),
  on(AllNotificationsActions.loadReplaceNotificationsSuccess, (state, { edges, pageInfo }) =>
    adapter.setAll(
      edges.map((edge) => ({ ...edge, node: { ...edge.node, _read: edge.node.read } })),
      {
        ...state,
        isReplaceLoading: false,
        pageInfo,
        loadMoreException: undefined,
        loadSyncException: undefined,
        loadReplaceException: undefined,
      },
    ),
  ),

  // load more
  on(AllNotificationsActions.loadMoreNotifications, (state) => ({ ...state, isLoadingMore: true })),
  on(AllNotificationsActions.loadMoreNotificationsException, (state) => ({
    ...state,
    isLoadingMore: false,
    loadMoreException: NotificationExceptionType.UNKNOWN,
  })),
  on(AllNotificationsActions.loadMoreNotificationsSuccess, (state, { edges, pageInfo }) =>
    adapter.addMany(
      edges.map((edge) => ({ ...edge, node: { ...edge.node, _read: edge.node.read } })),
      {
        ...state,
        isLoadingMore: false,
        pageInfo,
        loadMoreException: undefined,
        loadSyncException: undefined,
        loadReplaceException: undefined,
      },
    ),
  ),

  // Mark as read (optimistically)
  on(NotificationActions.markNotificationAsRead, (state, { notification: { id: notificationId } }) => {
    const edge = getNotificationEdgeById(state, notificationId);
    return edge
      ? adapter.updateOne(
          { id: notificationId, changes: { node: { ...edge.node, read: true, readMutating: true } } },
          state,
        )
      : state;
  }),
  on(NotificationActions.markNotificationAsReadSuccess, (state, { notificationId }) => {
    const edge = getNotificationEdgeById(state, notificationId);
    return edge
      ? adapter.updateOne(
          { id: notificationId, changes: { node: { ...edge.node, _read: true, readMutating: false } } },
          state,
        )
      : state;
  }),
  on(NotificationActions.markNotificationAsReadError, (state, payload) => {
    const { notificationId } = payload as any;
    const edge = getNotificationEdgeById(state, notificationId);
    return edge
      ? adapter.updateOne(
          { id: notificationId, changes: { node: { ...edge.node, read: false, readMutating: false } } },
          state,
        )
      : state;
  }),

  // Mark as unread (optimistically)
  on(NotificationActions.markNotificationAsUnread, (state, { notification: { id: notificationId } }) => {
    const edge = getNotificationEdgeById(state, notificationId);
    return edge
      ? adapter.updateOne(
          { id: notificationId, changes: { node: { ...edge.node, read: false, readMutating: true } } },
          state,
        )
      : state;
  }),
  on(NotificationActions.markNotificationAsUnreadSuccess, (state, { notificationId }) => {
    const edge = getNotificationEdgeById(state, notificationId);
    return edge
      ? adapter.updateOne(
          { id: notificationId, changes: { node: { ...edge.node, _read: false, readMutating: false } } },
          state,
        )
      : state;
  }),
  on(NotificationActions.markNotificationAsUnreadError, (state, payload) => {
    const { notificationId } = payload as any;
    const edge = getNotificationEdgeById(state, notificationId);
    return edge
      ? adapter.updateOne(
          { id: notificationId, changes: { node: { ...edge.node, read: true, readMutating: false } } },
          state,
        )
      : state;
  }),

  // Live subscription
  on(AllNotificationsActions.receiveNotifcationViaSubscription, (state, { notification }) => {
    const edge = getNotificationEdgeById(state, notification.id);

    // only accept live update if there is no current mutation for this notification
    if (edge && !edge.node?.readMutating) {
      return adapter.updateOne(
        { id: notification.id, changes: { node: { ...notification, _read: notification.read } } },
        state,
      );
    }

    return adapter.setOne({ cursor: '', node: { ...notification, _read: notification.read } }, state);
  }),
);
