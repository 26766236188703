import gql from 'graphql-tag';
import {
  MultiUserFlimValDtoNoNestingFragment,
  UserReferenceDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';
import { UserProfilePreviewDtoOmitOrgFragment } from '../../user-profile';

export const MultiUserFlimValFragment = gql`
  fragment MultiUserFlimVal on MultiUserFlimValDto {
    ...MultiUserFlimValDtoNoNesting
    userReferences {
      ...UserReferenceDtoNoNesting
      user {
        ...UserProfilePreviewDtoOmitOrgFragment
      }
    }
  }
  ${MultiUserFlimValDtoNoNestingFragment}
  ${UserProfilePreviewDtoOmitOrgFragment}
  ${UserReferenceDtoNoNestingFragment}
`;
