import {
  DateFormat,
  DecimalFormat,
  FirstWeekday,
  Language,
  TimeFormat,
  Timezone,
  MeasurementSystem,
} from '@t5s/shared/gql';

export class InternationalizationDefaults {
  static readonly Language: Language = Language.EN;
  static readonly Timezone: Timezone = Timezone.Europe__Amsterdam;
  static readonly TimeFormat: TimeFormat = TimeFormat.HOURS_24;
  static readonly DateFormat: DateFormat = DateFormat.DOT_DD_MM_YYYY;
  static readonly FirstWeekday: FirstWeekday = FirstWeekday.MONDAY;
  static readonly DecimalFormat: DecimalFormat = DecimalFormat.DECIMAL_SEPARATOR_DOT;
  static readonly MeasurementSystem: MeasurementSystem = MeasurementSystem.METRIC;
}
