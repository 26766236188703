import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ReactionDetailsState, reactionDetailsStateKey } from './reaction-details.reducer';

const selectReactionDetailsFeatureState = createFeatureSelector<ReactionDetailsState>(reactionDetailsStateKey);

export const selectReactionDetailsPosition = createSelector(
  selectReactionDetailsFeatureState,
  (state) => state.position,
);

export const selectReactionDetailsReactions = createSelector(
  selectReactionDetailsFeatureState,
  (state) => state.reactions,
);
