import gql from 'graphql-tag';
import {
  ChatMessageAttachmentDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const ChatMessageAttachmentFragment = gql`
  fragment ChatMessageAttachmentFragment on ChatMessageAttachmentDto {
    ...ChatMessageAttachmentDtoNoNesting
    thumbnail {
      ...ThumbnailDtoNoNesting
    }
  }
  ${ChatMessageAttachmentDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
