import gql from 'graphql-tag';
import {
  BlabDefDtoNoNestingFragment,
  BlabItemDetailDtoNoNestingFragment,
  WorkspaceDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const BlabItemDetailDtoLightweightPostCreationFragment = gql`
  fragment BlabItemDetailDtoLightweightPostCreationFragment on BlabItemDetailDto {
    ...BlabItemDetailDtoNoNesting
    blabDef {
      ...BlabDefDtoNoNesting
      workspace {
        ...WorkspaceDtoNoNesting
      }
    }
  }
  ${BlabItemDetailDtoNoNestingFragment}
  ${BlabDefDtoNoNestingFragment}
  ${WorkspaceDtoNoNestingFragment}
`;
