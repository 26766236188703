import gql from 'graphql-tag';
import {
  SubscribeToBulkFollowUnfollowBlabItemsSuccessEventNoNestingFragment,
  SubscribeToBulkFollowUnfollowBlabItemsFailureEventNoNestingFragment,
} from '../../generated/graphql-fragments';

export const SubscribeToBulkUnfollowBlabItemsEventUnionTypeFragment = gql`
  fragment BulkUnfollowBlabItemsEventUnionTypeFragment on SubscribeToBulkFollowUnfollowBlabItemsResult {
    ... on SubscribeToBulkFollowUnfollowBlabItemsSuccessEvent {
      ...SubscribeToBulkFollowUnfollowBlabItemsSuccessEventNoNesting
    }
    ... on SubscribeToBulkFollowUnfollowBlabItemsFailureEvent {
      ...SubscribeToBulkFollowUnfollowBlabItemsFailureEventNoNesting
    }
  }
  ${SubscribeToBulkFollowUnfollowBlabItemsSuccessEventNoNestingFragment}
  ${SubscribeToBulkFollowUnfollowBlabItemsFailureEventNoNestingFragment}
`;
