import {
  SearchUsersInput,
  SearchUsersInWorkspaceInput,
  SearchUsersWithBlabItemAccessInput,
  UserSearchResultDto,
} from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import { LightUserSearchResultFragment, UserSearchResultFragment } from '../../fragments/global-search';
import { searchUsers, searchUsersInWorkspace, searchUsersWithBlabItemAccess } from '../../generated/graphql-queries';
import { GqlService } from '../../gql-service';

export class GqlUserSearchService extends GqlService {
  searchUsers(input: SearchUsersInput): Observable<UserSearchResultDto[]> {
    return this.apollo.defaultQuery(searchUsers(input, [UserSearchResultFragment]));
  }

  searchUsersInWorkspace(input: SearchUsersInWorkspaceInput): Observable<UserSearchResultDto[]> {
    return this.apollo.defaultQuery(searchUsersInWorkspace(input, [LightUserSearchResultFragment]));
  }

  searchUsersWithBlabItemAccess(input: SearchUsersWithBlabItemAccessInput): Observable<UserSearchResultDto[]> {
    return this.apollo.defaultQuery(searchUsersWithBlabItemAccess(input, [LightUserSearchResultFragment]));
  }
}
