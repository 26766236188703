import gql from 'graphql-tag';
import { UserPostDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { LinkPreviewDtoFragment } from '../link-preview';
import { UserActivityDtoFragment } from './user-activity-dto.fragment';
import { UserPostAttachmentFragment } from './user-post-attachment.fragment';

export const UserPostActivityFragment = gql`
  fragment UserPostActivityFragment on UserPostActivityDto {
    ...UserActivityDtoFragment
    post {
      ...UserPostDtoNoNesting
    }
    attachments {
      ...UserPostAttachmentFragment
    }
    linkPreview {
      ...LinkPreviewDtoFragment
    }
  }
  ${UserActivityDtoFragment}
  ${UserPostDtoNoNestingFragment}
  ${UserPostAttachmentFragment}
  ${LinkPreviewDtoFragment}
`;
