export function filesFromInputEvent(event: InputEvent): File[] {
  const target = event.target as HTMLInputElement;
  const fileList = target.files;
  // shallow copy before clearing input
  const _files = fileList ? filesFromFileList(fileList) : [];
  const files = [..._files];

  target.value = null as any;

  return files;
}

export function filesFromDragEvent(event: DragEvent): File[] {
  const { dataTransfer } = event;

  if (!dataTransfer) {
    return [];
  }

  const files: File[] = [];
  if (dataTransfer.items) {
    for (let i = 0; i < dataTransfer.items.length; i++) {
      // If dropped items aren't files, reject them
      if (dataTransfer.items[i].kind === 'file') {
        const file = dataTransfer.items[i].getAsFile();
        if (file) {
          files.push(file);
        }
      }
    }

    dataTransfer.items.clear();
  } else {
    const fileList = dataTransfer.files;
    files.push(...filesFromFileList(fileList));

    dataTransfer.clearData();
  }

  return files;
}

function filesFromFileList(fileList: FileList): File[] {
  return Array.from(fileList);
}

export function fileFromBase64Data(base64: string, filename: string, filetype: string): File {
  const rawData = atob(base64);
  const bytes = new Array(rawData.length);
  for (let x = 0; x < rawData.length; x++) {
    bytes[x] = rawData.charCodeAt(x);
  }
  const arr = new Uint8Array(bytes);
  const blob = new Blob([arr], { type: filetype });
  const file = new File([blob], filename, { type: filetype });
  return file;
}
