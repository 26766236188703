import { differenceInSeconds, isSameYear } from '@t5s/shared/util/date';
import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { DatetimeI18n } from '../../../datetime.i18n';
import {
  ApplicableI18n,
  formatDateViaLanguage,
  getDateTimeFormats,
  isApplicableI18n,
  normalizeDate,
  shouldAbbreviateMonth,
} from '../common';

const now = () => new Date(Date.now());

function deltaDateTimeI18n(date: Date, i18n: ApplicableI18n) {
  const deltaSec = differenceInSeconds(now(), date);

  if (deltaSec < 1) {
    return { key: DatetimeI18n.key.now };
  }

  if (deltaSec < 60) {
    return { key: DatetimeI18n.key.secondDelta, params: { delta: `${Math.floor(deltaSec)}` } };
  }

  const deltaMin = deltaSec / 60;

  if (deltaMin < 60) {
    return { key: DatetimeI18n.key.minuteDelta, params: { delta: `${Math.floor(deltaMin)}` } };
  }

  const deltaH = deltaMin / 60;
  if (deltaH < 24) {
    return { key: DatetimeI18n.key.hourDelta, params: { delta: `${Math.floor(deltaH)}` } };
  }

  const dateTimeFormats = getDateTimeFormats(i18n);

  const sameYearFormat = shouldAbbreviateMonth(i18n, date)
    ? dateTimeFormats.date.omitYear
    : dateTimeFormats.date.omitYearNonAbbr;
  const anotherYearFormat = shouldAbbreviateMonth(i18n, date)
    ? dateTimeFormats.date.full
    : dateTimeFormats.date.fullNonAbbr;

  const format = isSameYear(now(), date) ? sameYearFormat : anotherYearFormat;

  return {
    key: DatetimeI18n.key.noop,
    params: { str: formatDateViaLanguage(date, format, i18n) },
  };
}

/** Formats a delta timestamp:
 * 1s ago
 * 3min ago
 * ...
 * Monday, Feb. 23 // same year
 * Dec 31, 2019 // another year
 */
export function formatDeltaDateTime(i18n: SharedI18n, date?: string | number | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  const i18nStr = deltaDateTimeI18n(date, i18n);

  return DatetimeI18n.translate(i18n, i18nStr);
}
