import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  applyBlabItemActivityThreadActivityLiveUpdate,
  applyBlabItemActivityThreadLiveUpdate,
  applyBlabItemActivityThreadReactionUpdate,
  blabItemActivityThreadReplyRemoved,
  getBlabItemActivityThreadReplyInputStateAddAttachments,
  getBlabItemActivityThreadReplyInputStateAttachmentUploadException,
  getBlabItemActivityThreadReplyInputStateAttachmentUploadStart,
  getBlabItemActivityThreadReplyInputStateAttachmentUploadSuccess,
  getBlabItemActivityThreadReplyInputStateRemoveAttachment,
} from '@t5s/mobile-client/util/blab-item-activity-thread';
import {
  BlabItemActivityThreadActivityLoadingState,
  BlabItemActivityThreadRepliesLoadingState,
  BlabItemActivityThreadStateModel,
} from '@t5s/mobile-client/value-object/blab-item-activity-thread';
import { BlabItemActivityUnionType, UserProfilePreviewDto } from '@t5s/shared/gql';
import { BlabItemActivityThreadActions } from './blab-item-activity-thread.actions';

export const blabItemActivityThreadStateKey = 'blabItemActivityThread';

export interface BlabItemActivityThreadState extends EntityState<BlabItemActivityThreadStateModel> {
  userSearchResults?: UserProfilePreviewDto[];
}

const adapter = createEntityAdapter<BlabItemActivityThreadStateModel>({
  selectId: (entity) => entity.blabItemActivityId,
});

export const { selectAll, selectEntities } = adapter.getSelectors();

const initialState: BlabItemActivityThreadState = adapter.getInitialState({});

export const blabItemActivityThreadReducer = createReducer(
  initialState,
  on(BlabItemActivityThreadActions.reset, () => ({ ...initialState })),

  // blab item activity
  on(BlabItemActivityThreadActions.loadBlabItemActivity, (state, { blabItemActivityId }) =>
    adapter.upsertOne(
      {
        blabItemActivityId,
        blabItemActivityLoadingState: BlabItemActivityThreadActivityLoadingState.LOADING,
        activityRepliesLoadingState: BlabItemActivityThreadRepliesLoadingState.INITIAL,
        replyInput: {},
      },
      state,
    ),
  ),
  on(BlabItemActivityThreadActions.reloadBlabItemActivity, (state, { blabItemActivityId }) =>
    adapter.updateOne(
      {
        id: blabItemActivityId,
        changes: {
          blabItemActivityLoadingState: BlabItemActivityThreadActivityLoadingState.RELOADING,
        },
      },
      state,
    ),
  ),
  on(BlabItemActivityThreadActions.loadBlabItemActivitySuccess, (state, { blabItemActivityId, blabItemActivity }) =>
    adapter.updateOne(
      {
        id: blabItemActivityId,
        changes: {
          blabItemActivity,
          blabItemActivityLoadingState: BlabItemActivityThreadActivityLoadingState.LOADED,
        },
      },
      state,
    ),
  ),
  on(BlabItemActivityThreadActions.loadBlabItemActivityException, (state, { blabItemActivityId }) =>
    adapter.updateOne(
      {
        id: blabItemActivityId,
        changes: {
          blabItemActivityLoadingState: BlabItemActivityThreadActivityLoadingState.EXCEPTION,
        },
      },
      state,
    ),
  ),

  // activity replies
  on(BlabItemActivityThreadActions.loadActivityReplies, (state, { blabItemActivityId }) =>
    adapter.updateOne(
      {
        id: blabItemActivityId,
        changes: {
          blabItemActivityId,
          activityRepliesLoadingState: BlabItemActivityThreadRepliesLoadingState.LOADING,
        },
      },
      state,
    ),
  ),
  on(BlabItemActivityThreadActions.reloadBlabItemActivity, (state, { blabItemActivityId }) =>
    adapter.updateOne(
      {
        id: blabItemActivityId,
        changes: {
          activityRepliesLoadingState: BlabItemActivityThreadRepliesLoadingState.RELOADING,
        },
      },
      state,
    ),
  ),
  on(BlabItemActivityThreadActions.loadActivityRepliesSuccess, (state, { blabItemActivityId, edges, pageInfo }) =>
    adapter.updateOne(
      {
        id: blabItemActivityId,
        changes: {
          activityReplies: {
            replies: edges,
            pageInfo,
          },
          activityRepliesLoadingState: BlabItemActivityThreadRepliesLoadingState.LOADED,
        },
      },
      state,
    ),
  ),
  on(BlabItemActivityThreadActions.loadActivityRepliesException, (state, { blabItemActivityId }) =>
    adapter.updateOne(
      {
        id: blabItemActivityId,
        changes: {
          activityRepliesLoadingState: BlabItemActivityThreadRepliesLoadingState.EXCEPTION,
        },
      },
      state,
    ),
  ),

  on(BlabItemActivityThreadActions.blabItemActivityLiveUpdate, (state, { blabItemActivityId, update }) => {
    const blabItemActivityThread = selectEntities(state)[blabItemActivityId];

    if (!blabItemActivityThread) {
      return state;
    }
    return adapter.updateOne(
      {
        id: blabItemActivityId,
        changes: applyBlabItemActivityThreadActivityLiveUpdate(blabItemActivityThread, update),
      },
      state,
    );
  }),

  on(BlabItemActivityThreadActions.blabItemActivityThreadLiveUpdate, (state, { blabItemActivityId, update }) => {
    const blabItemActivityThread = selectEntities(state)[blabItemActivityId];

    if (!blabItemActivityThread) {
      return state;
    }
    return adapter.updateOne(
      {
        id: blabItemActivityId,
        changes: applyBlabItemActivityThreadLiveUpdate(blabItemActivityThread, update),
      },
      state,
    );
  }),

  on(
    BlabItemActivityThreadActions.deleteBlabItemActivityReplySuccess,
    (state, { activityId: blabItemActivityId, replyId }) => {
      const blabItemActivityThread = selectEntities(state)[blabItemActivityId];

      if (!blabItemActivityThread) {
        return state;
      }
      return adapter.updateOne(
        {
          id: blabItemActivityId,
          changes: blabItemActivityThreadReplyRemoved(blabItemActivityThread, replyId),
        },
        state,
      );
    },
  ),

  // BlabItem activity reactions
  on(
    BlabItemActivityThreadActions.addBlabItemActivityReactionSuccess,
    BlabItemActivityThreadActions.removeBlabItemActivityReactionSuccess,
    (state, { activity }) => {
      const blabItemState = selectEntities(state)[activity.id];

      if (!blabItemState) {
        return state;
      }

      const changes = {
        blabItemActivity: activity as BlabItemActivityUnionType,
      };

      return adapter.updateOne({ id: activity.id, changes }, state);
    },
  ),

  // BlabItem activity reply reactions
  on(
    BlabItemActivityThreadActions.addBlabItemActivityReplyReactionSuccess,
    BlabItemActivityThreadActions.removeBlabItemActivityReplyReactionSuccess,
    (state, { activity, reply }) => {
      const blabItemState = selectEntities(state)[activity.id];

      if (!blabItemState) {
        return state;
      }

      const changes = applyBlabItemActivityThreadReactionUpdate(blabItemState, reply);

      return adapter.updateOne({ id: activity.id, changes }, state);
    },
  ),

  // Reply input
  on(BlabItemActivityThreadActions.setReplyInputContent, (state, { activityId, content }) => {
    const blabItemState = selectEntities(state)[activityId];

    if (!blabItemState) {
      return state;
    }

    const { replyInput = {} } = blabItemState;

    return adapter.updateOne(
      {
        id: activityId,
        changes: {
          replyInput: {
            ...replyInput,
            content,
          },
        },
      },
      state,
    );
  }),
  on(BlabItemActivityThreadActions.sendBlabItemActivityReplySuccess, (state, { activityId, reply }) => {
    const blabItemState = selectEntities(state)[activityId];

    if (!blabItemState) {
      return state;
    }

    const { activityReplies } = blabItemState;

    if (!activityReplies) {
      return state;
    }

    let { replies } = activityReplies;

    const existingReply = replies.find((existingReply) => existingReply.node.id === reply.id);

    replies = existingReply ? replies : [...replies, { node: reply, cursor: '' }];

    return adapter.updateOne(
      {
        id: activityId,
        changes: {
          activityReplies: { ...activityReplies, replies },
          replyInput: {
            content: '',
          },
        },
      },
      state,
    );
  }),

  on(BlabItemActivityThreadActions.addAttachmentFiles, (state, { activityId, files }) => {
    const blabItemState = selectEntities(state)[activityId];

    if (!blabItemState) {
      return state;
    }

    const { replyInput = {} } = blabItemState;

    return adapter.updateOne(
      {
        id: activityId,
        changes: {
          replyInput: getBlabItemActivityThreadReplyInputStateAddAttachments(replyInput, files),
        },
      },
      state,
    );
  }),
  on(BlabItemActivityThreadActions.uploadFileException, (state, { activityId, attachment }) => {
    const blabItemState = selectEntities(state)[activityId];

    if (!blabItemState) {
      return state;
    }

    const { replyInput } = blabItemState;

    if (!replyInput) {
      return state;
    }

    return adapter.updateOne(
      {
        id: activityId,
        changes: {
          replyInput: getBlabItemActivityThreadReplyInputStateAttachmentUploadException(replyInput, attachment),
        },
      },
      state,
    );
  }),
  on(BlabItemActivityThreadActions.removeAttachment, (state, { activityId, attachmentId }) => {
    const blabItemState = selectEntities(state)[activityId];

    if (!blabItemState) {
      return state;
    }

    const { replyInput } = blabItemState;

    if (!replyInput) {
      return state;
    }

    return adapter.updateOne(
      {
        id: activityId,
        changes: {
          replyInput: getBlabItemActivityThreadReplyInputStateRemoveAttachment(replyInput, attachmentId),
        },
      },
      state,
    );
  }),
  on(BlabItemActivityThreadActions.uploadFile, (state, { activityId, attachment }) => {
    const blabItemState = selectEntities(state)[activityId];

    if (!blabItemState) {
      return state;
    }

    const { replyInput } = blabItemState;

    if (!replyInput) {
      return state;
    }

    return adapter.updateOne(
      {
        id: activityId,
        changes: {
          replyInput: getBlabItemActivityThreadReplyInputStateAttachmentUploadStart(replyInput, attachment),
        },
      },
      state,
    );
  }),

  on(BlabItemActivityThreadActions.uploadFileSuccess, (state, { activityId, attachment, persistedAttachment }) => {
    const blabItemState = selectEntities(state)[activityId];

    if (!blabItemState) {
      return state;
    }

    const { replyInput } = blabItemState;

    if (!replyInput) {
      return state;
    }

    return adapter.updateOne(
      {
        id: activityId,
        changes: {
          replyInput: getBlabItemActivityThreadReplyInputStateAttachmentUploadSuccess(
            replyInput,
            attachment,
            persistedAttachment,
          ),
        },
      },
      state,
    );
  }),

  // Mention user
  on(BlabItemActivityThreadActions.searchMentionUserSuccess, (state, { userSearchResults }) => ({
    ...state,
    userSearchResults,
  })),
  on(BlabItemActivityThreadActions.clearMentionUserSearchResults, (state) => ({
    ...state,
    userSearchResults: [],
  })),
);
