import { NgModule } from '@angular/core';
import { RuntimeUrlObservable } from '@t5s/client/provider-token/runtime-url';
import { of } from 'rxjs';

@NgModule({
  providers: [
    {
      provide: RuntimeUrlObservable,
      useValue: of({ runtimeUrl: window.location.origin }),
    },
  ],
})
export class RuntimeUrlProviderModule {}
