import {
  ChatActivityBlock,
  ChatActivityCluster,
  ChatActivityDayCluster,
  ChatActivityDayClusterItem,
  ChatConversationChatActivityUnionType,
  ClusterItem,
  clusterItemIsChatActivityBlock,
} from '@t5s/mobile-client/value-object/chat-conversation';
import { ChatActivityType, ChatMessageActivityDto } from '@t5s/shared/gql';
import { groupByFn } from '@t5s/shared/util/array';
import { removeTime } from '@t5s/shared/util/date';

export const clusterTimeout = 5 * 60 * 1000;

function eligableForClustering(activity: ChatConversationChatActivityUnionType): boolean {
  return [ChatActivityType.MESSAGE, ChatActivityType.VIDEO_CALL].includes(activity.type);
}

export function clusterChatActivities(chatActivities: ChatConversationChatActivityUnionType[]): ChatActivityCluster {
  const clusterArray: ChatActivityCluster[] = chatActivities.map((activity) => {
    // wrap applicable chat activities into a ChatActivityBlock
    if (eligableForClustering(activity)) {
      return [
        {
          activities: [activity as ChatMessageActivityDto],
          user: activity.user,
          lastActivityDate: activity.createdAt,
        },
      ];
    }

    // ... return unchanged activity otherwise:
    return [activity];
  });

  return clusterArray.reduce((array, [item]) => {
    const lastBlockItem: ClusterItem | undefined = array[array.length - 1];

    if ((item as ChatConversationChatActivityUnionType).type) {
      // we infer that this is a chat activity, not a chat message block
      const nonMessageChatActivity = item as Exclude<ChatConversationChatActivityUnionType, ChatMessageActivityDto>;
      return [...array, nonMessageChatActivity];
    } else if ((lastBlockItem as ChatConversationChatActivityUnionType)?.type) {
      return [...array, item];
    } else {
      const newMessageBlock = item as ChatActivityBlock;
      if (lastBlockItem && (lastBlockItem as ChatActivityBlock).lastActivityDate) {
        // merge chat message activity blocks
        return [...array, ...mergeConditionally(lastBlockItem as ChatActivityBlock, newMessageBlock)];
      } else {
        return [...array, newMessageBlock];
      }
    }
  }, []);
}

/** Decides whether two message blocks should be merged. */
function mergeConditionally(prevBlock: ChatActivityBlock, nextBlock: ChatActivityBlock): ChatActivityCluster {
  if (shouldMerge(prevBlock, nextBlock)) {
    prevBlock.activities.push(nextBlock.activities[0]);
    prevBlock.lastActivityDate = nextBlock.lastActivityDate;
    return [];
  } else {
    return [nextBlock];
  }
}

/** Decides whether two message blocks should be merged. */
function shouldMerge(prevBlock: ChatActivityBlock, nextBlock: ChatActivityBlock): boolean {
  const differentUser = !!prevBlock.user && prevBlock.user?.id !== nextBlock.user?.id;
  const nextLastActivityDate = nextBlock.lastActivityDate ? new Date(nextBlock.lastActivityDate) : undefined;
  const prevLastActivityDate = prevBlock.lastActivityDate ? new Date(prevBlock.lastActivityDate) : undefined;

  if (!nextLastActivityDate || !prevLastActivityDate) {
    return false; // never merge if these dates are undefined
  }

  const timeoutExceeded = nextLastActivityDate.getTime() - prevLastActivityDate.getTime() >= clusterTimeout;
  return !differentUser && !timeoutExceeded;
}

function getDayFromClusterItem(item: ClusterItem): Date {
  if (clusterItemIsChatActivityBlock(item)) {
    const { lastActivityDate } = item;

    return removeTime(new Date(lastActivityDate));
  } else {
    const { createdAt } = item;

    return removeTime(new Date(createdAt));
  }
}

const getDayStrFromClusterItem = (item: ClusterItem) => getDayFromClusterItem(item).toISOString();

export function getDayClusters(cluster: ChatActivityCluster): ChatActivityDayCluster {
  const dayClusterDict = groupByFn(cluster, getDayStrFromClusterItem);

  return Object.entries(dayClusterDict).map(([dayStr, clusterItems]) => ({ dayStr, clusterItems }));
}

export function trackChatConversationActivityDayClusterItem(
  _: number,
  clusterItem: ChatActivityDayClusterItem,
): string | number {
  return clusterItem.dayStr;
}
