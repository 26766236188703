import gql from 'graphql-tag';
import { BlabItemActivityWithRepliesUnionTypeFragment } from './blab-item-activity-with-replies-union-type.fragment';

export const BlabItemActivitySubscriptionUpdateFragment = gql`
  fragment BlabItemActivitySubscriptionUpdateFragment on BlabItemActivitySubscriptionUpdate {
    ...BlabItemActivityWithRepliesUnionTypeFragment

    ... on BlabItemActivityDeletedDto {
      id
    }
  }
  ${BlabItemActivityWithRepliesUnionTypeFragment}
`;
