import { DateFormat, DecimalFormat, Language, TimeFormat, MeasurementSystem } from '@t5s/shared/gql';

export function deriveI18nSettingsFromLanguage(language: Language): {
  decimalFormat: DecimalFormat;
  dateFormat: DateFormat;
  timeFormat: TimeFormat;
  measurementSystem: MeasurementSystem;
} {
  const decimalFormat =
    language === Language.DE ? DecimalFormat.DECIMAL_SEPARATOR_COMMA : DecimalFormat.DECIMAL_SEPARATOR_DOT;
  const dateFormat = language === Language.DE ? DateFormat.DOT_DD_MM_YYYY : DateFormat.SLASH_MM_DD_YYYY;
  const timeFormat = language === Language.DE ? TimeFormat.HOURS_24 : TimeFormat.HOURS_12;
  const measurementSystem = language === Language.DE ? MeasurementSystem.METRIC : MeasurementSystem.IMPERIAL_US;

  return { decimalFormat, dateFormat, timeFormat, measurementSystem };
}
