import gql from 'graphql-tag';
import { ConvertMultiUserFlimToSingleUserFlimResultDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../../flim-def';

export const ConvertMultiUserFlimToSingleUserFlimResultDtoFragment = gql`
  fragment ConvertMultiUserFlimToSingleUserFlimResultDtoFragment on ConvertMultiUserFlimToSingleUserFlimResultDto {
    ...ConvertMultiUserFlimToSingleUserFlimResultDtoNoNesting

    flimDef {
      ...FullFlimDef
    }
  }
  ${ConvertMultiUserFlimToSingleUserFlimResultDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
