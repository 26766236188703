import { Timezone } from '@t5s/shared/gql';
import { InternationalizationDefaults } from '@t5s/shared/util/i18n';

const isTimezone = (candidate: any): candidate is Timezone => Object.values(Timezone).includes(candidate);

/**
 * Transforms a TimeZone enum value in the form of `Area__Area` into `Area/Area`.
 * "/" and "-" are not valid inside GraphQL enums so we need to encode them with "__" and "0".
 */
export function getIsoTimezoneStringFromTimezoneEnum(timezone: Timezone | undefined): string {
  if (!timezone) {
    timezone = InternationalizationDefaults.Timezone;
  }

  return timezone.replace(/__/g, '/').replace(/0/g, '-'); //
}

/**
 * Transforms a string in the form of `Area/Area` into `Area__Area` ( Timezoneenum value).
 */
export function getTimezoneEnumFromIsoTimezoneStr(timezoneStr: string): Timezone {
  // special case for cities that have been renamed or whatever tf
  if (timezoneStr === 'Asia/Calcutta') {
    timezoneStr = 'Asia/Kolkata';
  }

  const timezoneCandidate = timezoneStr.replace(/\//g, '__').replace(/-/g, '0');

  if (!isTimezone(timezoneCandidate)) {
    throw new Error(`Invalid timezone string, ${timezoneCandidate} is not assignable to Timezone enum.`);
  }

  return timezoneCandidate;
}
