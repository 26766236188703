import { createAction, props } from '@ngrx/store';
import { createErrorAction } from '@t5s/client/util/store';
import { ChatDialogDto, ChatGroupDto, ConversationDto } from '@t5s/shared/gql';
import { ChatStateHydrationPersistance } from './chat-hydration.state';

const PREFIX = '[mobile-client-chat]';

export const ChatActions = {
  reset: createAction(`${PREFIX} reset`),

  loadConversations: createAction(`${PREFIX} Load conversations`),
  loadConversationsSuccess: createAction(
    `${PREFIX} Loaded conversations successfully`,
    props<{ conversations: ConversationDto[] }>(),
  ),
  loadConversationsException: createErrorAction(`${PREFIX} Loading conversations failed with exception`),

  subscribeToConversations: createAction(`${PREFIX} Subscribe to conversations`),
  subscribeToConversationsSuccess: createAction(`${PREFIX} Subscribed to conversations`),
  unsubscribefromConversations: createAction(`${PREFIX} Unsubscribe from conversations`),
  conversationsSubscriptionError: createErrorAction(
    `${PREFIX} conversations subscription errored`,
    props<{ error: any }>(),
  ),
  conversationsUpdate: createAction(
    `${PREFIX} Conversation update received`,
    props<{ conversation: ConversationDto }>(),
  ),

  // State hydration
  hydrateState: createAction(`${PREFIX} Hydrate state`),
  hydrateStateSuccess: createAction(
    `${PREFIX} Hydrate state Success`,
    props<{ state: ChatStateHydrationPersistance }>(),
  ),
  hydrateStateNotAvailable: createAction(`${PREFIX} Hydration of state not available`),
  hydrateStateException: createAction(`${PREFIX} Hydration of state failed with exception`),

  // navigation
  accessConversation: createAction(
    `${PREFIX} Access chat conversation`,
    props<{ conversationId: number; chatActivityId?: number; conversation?: ChatGroupDto | ChatDialogDto }>(),
  ),
};
