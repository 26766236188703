import { createAction, props } from '@ngrx/store';
import { createErrorAction } from '@t5s/client/util/store';
import { NavigationBarItem } from '@t5s/mobile-client/value-object/logged-in-root';
import { UserClientAppBubbleDto } from '@t5s/shared/gql';

const PREFIX = '[mobile-client-logged-in-root]';

export const LoggedInRootActions = {
  reset: createAction(`${PREFIX} reset`),
  init: createAction(`${PREFIX} init`),

  setActiveOutlet: createAction(`${PREFIX} set active outlet`, props<{ active: NavigationBarItem }>()),
  navigateToOutlet: createAction(
    `${PREFIX} navigate to outlet`,
    props<{ outlet: NavigationBarItem; longpress?: boolean }>(),
  ),

  navigateToActiveOutlet: createAction(
    `${PREFIX} navigating to active outlet again`,
    props<{ outlet: NavigationBarItem; longpress?: boolean }>(),
  ),
  navigateToActiveOutletHome: createAction(
    `${PREFIX} navigating to active outlet again (home)`,
    props<{ longpress?: boolean }>(),
  ),
  navigateToActiveOutletNotifications: createAction(
    `${PREFIX} navigating to active outlet again (notifications)`,
    props<{ longpress?: boolean }>(),
  ),
  navigateToActiveOutletSearch: createAction(
    `${PREFIX} navigating to active outlet again (search)`,
    props<{ longpress?: boolean }>(),
  ),
  navigateToActiveOutletActiveUser: createAction(
    `${PREFIX} navigating to active outlet again (active user)`,
    props<{ longpress?: boolean }>(),
  ),
  navigateToActiveOutletChat: createAction(
    `${PREFIX} navigating to active outlet again (chat)`,
    props<{ longpress?: boolean }>(),
  ),

  // Bubbles
  setClientAppBubbleSuccess: createAction(
    `${PREFIX} set client app bubble success`,
    props<{ bubble: UserClientAppBubbleDto }>(),
  ),
  setClientAppBubblesSuccess: createAction(
    `${PREFIX} set client app bubbles success`,
    props<{ bubbles: UserClientAppBubbleDto[] }>(),
  ),
  setClientAppBubblesError: createErrorAction(`${PREFIX} set client app bubbles error`),

  hydrateActiveTab: createAction(`${PREFIX} Hydrate active tab`),
  hydrateActiveTabSuccess: createAction(
    `${PREFIX} Hydrate active tab Success`,
    props<{ hydratedActive: NavigationBarItem }>(),
  ),
  hydrateActiveTabNotAvailable: createAction(`${PREFIX} Hydration of active tab not available`),
  hydrateActiveTabException: createAction(`${PREFIX} Hydration of active tab failed with exception`),

  initSubscribeBubbles: createAction(`${PREFIX} Initialize load/Subscribe to user client app bubbles`),
  refreshBubbles: createAction(`${PREFIX} refresh user client app bubbles`),
  loadSubscribeBubbles: createAction(`${PREFIX} load/Subscribe to user client app bubbles`),
  subscribeBubblesException: createErrorAction(`${PREFIX} Client app bubble subscription errored`),
  syncAppBadgeWithBubbles: createAction(`${PREFIX} sync app badge with user client app bubbles`),
};
