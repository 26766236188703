import { ShareOptions } from '@capacitor/share';
import { createAction, props } from '@ngrx/store';
import { OpenFileOptions } from '@t5s/mobile-client/value-object/file-opener';
import { FilesViewerFile } from '@t5s/mobile-client/value-object/files-viewer';
import { createErrorAction } from '@t5s/client/util/store';

const PREFIX = '[mobile-client-file-attachment-preview]';

export const FileAttachmentPreviewActions = {
  reset: createAction(`${PREFIX} reset`),

  openFileAttachmentPreviewModal: createAction(
    `${PREFIX} open flimval file attachment modal`,
    props<{ attachment: FilesViewerFile; attachments: FilesViewerFile[] }>(),
  ),

  shareFile: createAction(`${PREFIX} share file`, props<{ options: ShareOptions }>()),
  shareFileSuccess: createAction(`${PREFIX} shared file successfully`),

  openFile: createAction(`${PREFIX} open file`, props<{ url: string; options: OpenFileOptions }>()),
  openFileRetrievedFile: createAction(`${PREFIX} retrieved file for opening successfully`),
  openFileSuccess: createAction(`${PREFIX} opened file successfully (dialog has been closed)`),
  openFileException: createErrorAction(`${PREFIX} opening file failed with exception`),

  showOpenFileWith: createAction(
    `${PREFIX} show native "open file with" dialog`,
    props<{ url: string; options: OpenFileOptions }>(),
  ),
  showOpenFileWithSuccess: createAction(`${PREFIX} showed native "open file with" dialog successfully`),
  showOpenFileWithException: createErrorAction(
    `${PREFIX} showing native "open file with" dialog failed with exception`,
  ),
};
