import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[t5sFlexColumn]',
  exportAs: 't5sFlexColumn',
})
export class FlexColumnDirective {
  constructor(el: ElementRef<HTMLElement>) {
    el.nativeElement.style.setProperty('display', 'flex');
    el.nativeElement.style.setProperty('flex-direction', 'column');
  }
}
