import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ServiceShareModule } from '@t5s/mobile-client/service/share';
import { GqlUserClientAppService, SharedApolloClient } from '@t5s/shared/gql-services';
import { LoggedInRootEffects } from './logged-in-root.effects';
import { LoggedInRootRedirectGuard } from './logged-in-root.redirect-guard';
import { loggedInRootReducer, loggedInRootStateKey } from './logged-in-root.reducer';

@NgModule({
  imports: [
    ServiceShareModule,
    StoreModule.forFeature(loggedInRootStateKey, loggedInRootReducer),
    EffectsModule.forFeature([LoggedInRootEffects]),
  ],
  providers: [
    LoggedInRootRedirectGuard,
    {
      provide: GqlUserClientAppService,
      useFactory: (apollo: SharedApolloClient) => new GqlUserClientAppService(apollo),
      deps: [SharedApolloClient],
    },
  ],
})
export class LoggedInRootBusinessLogicModule {}
