import {
  AddBlabItemCommentInput,
  BlabItemCommentActivityDto,
  BlabItemCommentAttachmentDto,
  EditBlabItemCommentInput,
  Upload,
  UploadBlabItemCommentAttachmentInput,
  Void,
} from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import { BlabItemActivityUnionTypeFragment } from '../../fragments/blab-item-activity';
import { BlabItemCommentAttachmentFragment } from '../../fragments/blab-item-comment';
import {
  addBlabItemComment,
  deleteBlabItemComment,
  editBlabItemComment,
  uploadBlabItemCommentAttachment,
} from '../../generated/graphql-mutations';
import { GqlService } from '../../gql-service';

export class GqlBlabItemCommentService extends GqlService {
  addBlabItemComment(input: AddBlabItemCommentInput): Observable<BlabItemCommentActivityDto> {
    return this.apollo.defaultMutate(addBlabItemComment(input, [BlabItemActivityUnionTypeFragment]));
  }

  uploadBlabItemCommentAttachment(
    input: UploadBlabItemCommentAttachmentInput,
    attachment: Upload,
  ): Observable<BlabItemCommentAttachmentDto> {
    return this.apollo.defaultMutateFileUpload(
      uploadBlabItemCommentAttachment(input, attachment, [BlabItemCommentAttachmentFragment]),
    );
  }

  editBlabItemComment(input: EditBlabItemCommentInput): Observable<BlabItemCommentActivityDto> {
    return this.apollo.defaultMutate(editBlabItemComment(input, [BlabItemActivityUnionTypeFragment]));
  }

  deleteBlabItemComment(activityId: number): Observable<Void> {
    return this.apollo.defaultMutate(deleteBlabItemComment({ activityId }));
  }
}
