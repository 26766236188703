import gql from 'graphql-tag';
import { PageInfoNoNestingFragment, WorkflowRunPreviewDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const WorkflowRunsConnectionFragment = gql`
  fragment WorkflowRunsConnectionFragment on WorkflowRunsConnection {
    edges {
      cursor
      node {
        ...WorkflowRunPreviewDtoNoNesting
      }
    }
    pageInfo {
      ...PageInfoNoNesting
    }
  }
  ${WorkflowRunPreviewDtoNoNestingFragment}
  ${PageInfoNoNestingFragment}
`;
