import { SharedI18n } from '@t5s/shared/value-object/i18n';

interface I18nWindow extends Window {
  tape?: {
    i18n?: SharedI18n;
  };
}

/**
 * Set global i18n via window object
 * @param i18n i18n
 */
export function setGlobalI18n(i18n: SharedI18n) {
  const _window = window as I18nWindow;
  if (!_window.tape) {
    _window.tape = {};
  }
  _window.tape.i18n = i18n;
}

/**
 * Get global i18n via window object
 */
export function getGlobalI18n(): SharedI18n {
  const _window = window as I18nWindow;
  return _window.tape?.i18n ?? {};
}
