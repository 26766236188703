import gql from 'graphql-tag';
import { UserProfilePreviewDtoFragment } from '../user-profile/user-profile-preview-dto.fragment';
import { BlabItemCommentActivityFragment } from '../blab-item-comment/blab-item-comment-activity.fragment';
import { BlabItemRevisionActivityFragment } from '../blab-item-revision/blab-item-revison-activity-dto.fragment';
import { BlabItemCreatedActivityFragment } from './blab-item-created-activity.fragment';
import { BlabItemDeletedActivityFragment } from './blab-item-deleted-activity.fragment';
import { BlabItemRestoredActivityFragment } from './blab-item-restored-activity.fragment';

export const BlabItemActivityWithRepliesUnionTypeFragment = gql`
  fragment BlabItemActivityWithRepliesUnionTypeFragment on BlabItemActivityUnionType {
    ... on BlabItemCreatedActivityDto {
      ...BlabItemCreatedActivityFragment
      replies(first: 3) {
        node {
          id
          user {
            ...UserProfilePreviewDtoFragment
          }
        }
      }
    }

    ... on BlabItemDeletedActivityDto {
      ...BlabItemDeletedActivityFragment
      replies(first: 3) {
        node {
          id
          user {
            ...UserProfilePreviewDtoFragment
          }
        }
      }
    }

    ... on BlabItemRestoredActivityDto {
      ...BlabItemRestoredActivityFragment
      replies(first: 3) {
        node {
          id
          user {
            ...UserProfilePreviewDtoFragment
          }
        }
      }
    }

    ... on BlabItemCommentActivityDto {
      ...BlabItemCommentActivityFragment
      replies(first: 3) {
        node {
          id
          user {
            ...UserProfilePreviewDtoFragment
          }
        }
      }
    }

    ... on BlabItemRevisionActivityDto {
      ...BlabItemRevisionActivityFragment
      replies(first: 3) {
        node {
          id
          user {
            ...UserProfilePreviewDtoFragment
          }
        }
      }
    }
  }
  ${BlabItemCreatedActivityFragment}
  ${BlabItemDeletedActivityFragment}
  ${BlabItemRestoredActivityFragment}
  ${BlabItemCommentActivityFragment}
  ${BlabItemRevisionActivityFragment}
  ${UserProfilePreviewDtoFragment}
`;
