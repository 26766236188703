import { NgModule } from '@angular/core';
import { ViewCommonModule } from '@t5s/mobile-client/ui/view/common';
import { BottomSheetComponentModule } from '@t5s/mobile-client/ui/component/bottom-sheet';
import { DeviceMediaViewComponent } from './device-media-view.component';
import { DeviceMediaMenuItemComponent } from './device-media-menu-item.component';

const components = [DeviceMediaViewComponent, DeviceMediaMenuItemComponent];

@NgModule({
  imports: [ViewCommonModule, BottomSheetComponentModule],
  declarations: components,
  exports: components,
})
export class DeviceMediaViewModule {}
