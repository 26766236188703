import { blabItemRevisionActivityLangEnClient } from '../activity/blab-item-revision-activity.en';

export const blabItemActivityEnLangClient = {
  activityPlaceholder: {
    subtitle: 'No comments were found for this record. To view all activities, remove the filter.',
    title: 'No comments found',
  },
  blabItemCreated: 'Created {{title}}',
  blabItemDeleted: 'Deleted {{title}}',
  blabItemRestored: 'Restored {{title}}',
  confirmDeleteBlabItemComment: {
    confirmButtonLabel: `Delete`,
    message: `Are you sure you want to delete this comment? This can’t be undone and replies to this comment will also be removed.`,
    title: `Delete comment?`,
  },
  dropzoneLabelComment: 'Upload to Comment',
  dropzoneLabelReply: 'Upload to Reply',
  header: {
    activityHeadline: `Activity`,
    commentCount: `{{ count }} Comment`,
    commentsCount: `{{ count }} Comments`,
    filterCommentsSubline: 'Filter by: <em>Comments</em>',
    filterUpdatesSubline: 'Filter by: <em>Updates</em>',
    titleMenu: {
      allActivity: 'All Activity',
      onlyComments: 'Comments',
      onlyUpdates: 'Updates',
      title: 'Filter by',
    },
    updateCount: `{{ count }} Update`,
    updatesCount: `{{ count }} Updates`,
  },
  newReply: 'Start a new reply',
  reply: {
    confirmDeleteReply: {
      confirmButtonLabel: `Delete`,
      message: `Are you sure you want to delete this reply? This cannot be undone.`,
      title: `Delete reply?`,
    },
    invalidFileSizeFileAddedToast: 'One or more files exceed the 100 MB limit.',
    tooManyFilesAttachedToast: `You can only upload 4 files at a time.`,
  },
  revertDialog: {
    confirmButtonLabel: `Restore`,
    message: `Are you sure you want to restore the previous value?`,
    title: `Restore previous value?`,
  },
  revision: blabItemRevisionActivityLangEnClient,
} as const;
