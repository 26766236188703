import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafeAreaBottomSpacerDirective } from './safe-area-bottom-spacer.directive';

const directives = [SafeAreaBottomSpacerDirective];

@NgModule({
  imports: [CommonModule],
  declarations: directives,
  exports: directives,
})
export class SafeAreaDirectiveModule {}
