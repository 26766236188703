import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { calc, exactHeight, firstChild, getFontStyle, ms, padding, percent, px } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { IconInterface } from '@t5s/mobile-client/asset';
import { RxComponent } from '@t5s/mobile-client/ui/component/common';
import { font, ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { Observable } from 'rxjs';

interface ExpandableIconButtonState {
  expanded: boolean;

  icon?: IconInterface;
  label: string;
  fgColor: string;
  bgColor: string;
}

const TRANSITION_DURATION_MS = 220;
const BUTTON_ADDITIONAL_HEIGHT = 16;
const BUTTON_ADDITIONAL_WIDTH = 20;

@Component({
  selector: 't5s-expandable-icon-button',
  template: `
    <ng-container *ngIf="state$ | push as state">
      <div [class]="getContainerClass(state)" [t5sTouchActive]="'opacity: 0.9'">
        <!-- Icon -->
        <t5s-icon
          [class]="iconClass"
          [size]="20"
          [icon]="state.icon"
          [fgColor]="state.fgColor"
          [style.visibility]="state.expanded ? 'hidden' : 'visible'"
        ></t5s-icon>

        <!-- Label -->
        <div [class]="labelClass" [style.visibility]="state.expanded ? 'visible' : 'hidden'">
          <span #label>{{ state.label }}</span>
        </div>

        <!-- Button -->
        <button
          [class]="buttonClass"
          (t5sPressDisableLongpress)="handleBtnClick(state)"
          (t5sOutsideClick)="set({ expanded: false })"
        ></button>
      </div>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandableIconButtonComponent extends RxComponent<ExpandableIconButtonState> {
  constructor() {
    super();

    this.set({
      expanded: false,
      bgColor: ThemeColorVar.dark,
      fgColor: ThemeColorVar.lightest,
      label: '',
    });
  }

  @ViewChild('label') labelRef?: ElementRef<HTMLDivElement>;

  @Input() set icon(icon: IconInterface | Observable<IconInterface>) {
    this.setProperty('icon', icon);
  }

  @Input() set label(label: string | Observable<string>) {
    this.setProperty('label', label);
  }

  @Input() set fgColor(fgColor: string | Observable<string>) {
    this.setProperty('fgColor', fgColor);
  }

  @Input() set bgColor(bgColor: string | Observable<string>) {
    this.setProperty('bgColor', bgColor);
  }

  @Output() btnClick = new EventEmitter<never>();

  readonly iconClass = tss({
    position: 'absolute',
    pointerEvents: 'none',
    top: px(0),
    left: px(0),
  });

  readonly labelClass = tss({
    pointerEvents: 'none',
    padding: padding(2, 6),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  });

  readonly buttonClass = tss({
    width: calc(`${percent(100)} + ${px(BUTTON_ADDITIONAL_WIDTH)}`),
    ...exactHeight(20 + BUTTON_ADDITIONAL_HEIGHT),
    position: 'absolute',
    top: px(-BUTTON_ADDITIONAL_HEIGHT / 2),
    left: px(-BUTTON_ADDITIONAL_WIDTH / 2),
  });

  getContainerClass(state: ExpandableIconButtonState) {
    const labelWidth = this.labelRef?.nativeElement.offsetWidth || 100;

    return tss({
      position: 'relative',
      borderRadius: px(20 / 2),
      ...exactHeight(20),
      minWidth: px(20),
      maxWidth: px(state.expanded ? labelWidth + 6 + 6 : 20),
      transitionProperty: 'max-width',
      transitionDuration: ms(TRANSITION_DURATION_MS),
      backgroundColor: state.bgColor,
      ...getFontStyle({ ...font.regular14px, lineHeight: 16 }),
      color: state.fgColor,
      ...firstChild({
        transitionProperty: 'visibility',
        transitionDelay: ms(state.expanded ? 0 : TRANSITION_DURATION_MS),
      }),
    });
  }

  handleBtnClick(state: ExpandableIconButtonState) {
    if (state.expanded) {
      this.btnClick.emit();
    }
    this.set({ expanded: !state.expanded });
  }
}
