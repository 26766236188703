import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ChatConversationActivityEffects } from './chat-conversation-activity.effects';
import { ChatConversationHydrationEffects } from './chat-conversation-hydration.effects';
import { ChatConversationMentionUserEffects } from './chat-conversation-mention-user.effects';
import { ChatConversationMessageAnalyticsEffects } from './chat-conversation-message-analytics.effects';
import { ChatConversationMessageEffects } from './chat-conversation-message.effects';
import { ChatConversationEffects } from './chat-conversation.effects';
import { chatConversationReducer, chatConversationStateKey } from './chat-conversation.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(chatConversationStateKey, chatConversationReducer),
    EffectsModule.forFeature([
      ChatConversationEffects,
      ChatConversationHydrationEffects,
      ChatConversationMessageEffects,
      ChatConversationActivityEffects,
      ChatConversationMentionUserEffects,
      ChatConversationMessageAnalyticsEffects,
    ]),
  ],
})
export class ChatConversationBusinessLogicModule {}
