import gql from 'graphql-tag';
import { WorkspaceInvitationDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { OrganizationInvitationDtoFragment } from '../organization-invitation';
import { FullUserProfileDtoFragment, UserProfilePreviewDtoFragment } from '../user-profile';
import { WorkspaceUserDtoFragment } from '../workspace-user';

export const WorkspaceMembershipCandidateSearchResultDtoFragment = gql`
  fragment WorkspaceMembershipCandidateSearchResultDtoFragment on WorkspaceMembershipCandidateSearchResultDto {
    organizationInvitations {
      ...OrganizationInvitationDtoFragment
    }
    userProfiles {
      ...FullUserProfileDto
    }
    deactivatedUserProfiles {
      ...FullUserProfileDto
    }
    workspaceInvitations {
      ...WorkspaceInvitationDtoNoNesting
    }
    workspaceUsers {
      ...WorkspaceUserDtoFragment
    }
  }
  ${OrganizationInvitationDtoFragment}
  ${UserProfilePreviewDtoFragment}
  ${WorkspaceInvitationDtoNoNestingFragment}
  ${WorkspaceUserDtoFragment}
  ${FullUserProfileDtoFragment}
`;
