import { Injectable } from '@angular/core';

@Injectable()
export class ApplicationRestartService {
  private readonly window = window;

  restart() {
    this.window.location.reload();
  }

  restartWithUrl(url: string) {
    this.window.location.replace(url);
  }
}
