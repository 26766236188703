import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ComponentStyle, flexCenter, spread } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent } from '@t5s/mobile-client/ui/component/common';

interface SpinnerState {}

@Component({
  selector: 't5s-loading-spinner-spread',
  template: `
    <div [class]="containerClass">
      <t5s-loading-spinner></t5s-loading-spinner>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostSpread],
})
export class LoadingSpinnerSpreadComponent extends RxComponent<SpinnerState> {
  readonly containerClass = tss({
    ...spread,
    ...flexCenter,
  });
}
