import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { PushModule } from '@rx-angular/template/push';
import { LetModule } from '@rx-angular/template/let';
import { ClientUtilFeatureAccessLevelModule } from '@t5s/client/util/feature-access-level';
import { LongpressDirectiveModule } from '@t5s/mobile-client/directive/press';
import { AssetComponentModule } from '@t5s/mobile-client/ui/component/asset';
import { ButtonComponentModule } from '@t5s/mobile-client/ui/component/button';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { DelimiterComponentModule } from '@t5s/mobile-client/ui/component/delimiter';
import { DividerComponentModule } from '@t5s/mobile-client/ui/component/divider';
import { InputComponentModule } from '@t5s/mobile-client/ui/component/input';
import { LayoutComponentModule } from '@t5s/mobile-client/ui/component/layout';
import { LinkComponentModule } from '@t5s/mobile-client/ui/component/link';
import { LoadingSpinnerComponentModule } from '@t5s/mobile-client/ui/component/loading-spinner';
import { PlaceholderComponentModule } from '@t5s/mobile-client/ui/component/placeholder';
import { TextComponentModule } from '@t5s/mobile-client/ui/component/text';
import { ScrollContainerComponentModule } from '@t5s/mobile-client/ui/component/scroll-container';
import { StubComponentModule } from '@t5s/mobile-client/ui/component/stub';

@NgModule({
  imports: [ComponentCommonModule],
  exports: [
    ComponentCommonModule,
    CommonModule,
    FormsModule,
    PushModule,
    LetModule,
    IonicModule,
    ClientUtilFeatureAccessLevelModule,
    TextComponentModule,
    DelimiterComponentModule,
    AssetComponentModule,
    InputComponentModule,
    DividerComponentModule,
    ButtonComponentModule,
    LinkComponentModule,
    LoadingSpinnerComponentModule,
    LongpressDirectiveModule,
    PlaceholderComponentModule,
    LayoutComponentModule,
    ScrollContainerComponentModule,
    StubComponentModule,
  ],
})
export class ViewCommonModule {}
