import gql from 'graphql-tag';
import { OrganizationWorkspaceDtoFragment } from './organization-workspace.fragment';

export const OrganizationWorkspacesConnectionFragment = gql`
  fragment OrganizationWorkspacesConnectionFragment on OrganizationWorkspacesConnection {
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...OrganizationWorkspaceDtoFragment
      }
    }
  }
  ${OrganizationWorkspaceDtoFragment}
`;
