/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */

import { tss } from '@t5s/client/util/tss';

export const swiperClass = tss`
.swiper-container {
  width: 100%;
  height: 100%;
}

/* https://github.com/nolimits4web/swiper/issues/3527 */
.swiper-slide {
  -webkit-transform: translate3d(0, 0, 0);
}
`;
