import { Injectable, OnDestroy } from '@angular/core';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { DeviceService } from '@t5s/mobile-client/service/device';
import { Subject } from 'rxjs';

@Injectable()
export class ApplicationUrlService implements OnDestroy {
  private readonly appUrlOpen$$ = new Subject<URLOpenListenerEvent>();

  readonly appUrlOpen$ = this.appUrlOpen$$.asObservable();

  constructor(readonly device: DeviceService) {
    device.deviceInfo$.subscribe(({ platform }) => {
      if (platform === 'ios' || platform === 'android') {
        try {
          void App.addListener('appUrlOpen', (urlOpenEvent) => {
            this.appUrlOpen$$.next(urlOpenEvent);
          });
        } catch (e) {}
      }
    });
  }

  ngOnDestroy() {
    return App.removeAllListeners();
  }
}
