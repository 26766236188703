import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { getFontStyle } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { font, FontSet, ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface FooterLinkState {
  font: FontSet;
  fgColor?: string;
}

@Component({
  selector: 't5s-footer-link',
  template: `
    <a
      [class]="linkClass$ | push"
      [t5sTouchActive]="'opacity: 0.35'"
      (t5sPressDisableLongpress)="linkClick.emit({ event: $event })"
    >
      <ng-content></ng-content>
    </a>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterLinkComponent extends RxComponent<FooterLinkState> {
  constructor() {
    super();
    this.set({
      font: font.regular16px,
      fgColor: ThemeColorVar.primary,
    });
  }

  @Input() set font(font: FontSet | Observable<FontSet>) {
    this.setProperty('font', font);
  }

  @Input() set fgColor(fgColor: string) {
    this.setProperty('fgColor', fgColor);
  }

  @Output() linkClick = new EventEmitter<{ event: Event }>();

  readonly linkClass$ = this.select(selectSlice(['fgColor', 'font'])).pipe(
    map(({ fgColor, font }) =>
      tss({
        ...getFontStyle(font),
        color: fgColor,
        cursor: 'pointer',
      }),
    ),
  );
}
