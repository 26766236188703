import { NgModule } from '@angular/core';
import { EmojiPickerBusinessLogicModule } from '@t5s/mobile-client/business-logic/emoji-picker';
import { PageCommonModule } from '@t5s/mobile-client/page/common';
import { EmojiPickerViewModule } from '@t5s/mobile-client/ui/view/emoji-picker';
import { EmojiPickerPageComponent } from './emoji-picker-page.component';

@NgModule({
  imports: [PageCommonModule, EmojiPickerViewModule, EmojiPickerBusinessLogicModule],
  declarations: [EmojiPickerPageComponent],
  exports: [EmojiPickerPageComponent],
})
export class EmojiPickerPageModule {}
