import gql from 'graphql-tag';
import {
  CalculationScriptMultiTextResultPreviewDtoNoNestingFragment,
  CalculationScriptNumberResultPreviewDtoNoNestingFragment,
  CalculationScriptSingleDateResultPreviewDtoNoNestingFragment,
  GetCalculationScriptResultPreviewRejectionDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const GetCalculationFlimDefScriptResultPreviewResultUnionTypeFragment = gql`
  fragment GetCalculationFlimDefScriptResultPreviewResultUnionType on GetCalculationScriptResultPreviewResultUnionType {
    ... on CalculationScriptMultiTextResultPreviewDto {
      ...CalculationScriptMultiTextResultPreviewDtoNoNesting
    }

    ... on CalculationScriptNumberResultPreviewDto {
      ...CalculationScriptNumberResultPreviewDtoNoNesting
    }

    ... on CalculationScriptSingleDateResultPreviewDto {
      ...CalculationScriptSingleDateResultPreviewDtoNoNesting
    }

    ... on GetCalculationScriptResultPreviewRejectionDto {
      ...GetCalculationScriptResultPreviewRejectionDtoNoNesting
    }
  }
  ${CalculationScriptMultiTextResultPreviewDtoNoNestingFragment}
  ${CalculationScriptNumberResultPreviewDtoNoNestingFragment}
  ${CalculationScriptSingleDateResultPreviewDtoNoNestingFragment}
  ${GetCalculationScriptResultPreviewRejectionDtoNoNestingFragment}
`;
