import gql from 'graphql-tag';
import {
  SubscribeToImportBlabDefDataJobFailureEventNoNestingFragment,
  SubscribeToImportBlabDefDataJobProgressEventNoNestingFragment,
  SubscribeToImportBlabDefDataJobSuccessEventNoNestingFragment,
} from '../../generated/graphql-fragments';

export const BlabDefDataImportSubscriptionEventUnionTypeFragment = gql`
  fragment BlabDefDataImportSubscriptionEventUnionTypeFragment on SubscribeToImportBlabDefDataJobResult {
    ... on SubscribeToImportBlabDefDataJobProgressEvent {
      ...SubscribeToImportBlabDefDataJobProgressEventNoNesting
    }
    ... on SubscribeToImportBlabDefDataJobSuccessEvent {
      ...SubscribeToImportBlabDefDataJobSuccessEventNoNesting
    }
    ... on SubscribeToImportBlabDefDataJobFailureEvent {
      ...SubscribeToImportBlabDefDataJobFailureEventNoNesting
    }
  }
  ${SubscribeToImportBlabDefDataJobProgressEventNoNestingFragment}
  ${SubscribeToImportBlabDefDataJobSuccessEventNoNestingFragment}
  ${SubscribeToImportBlabDefDataJobFailureEventNoNestingFragment}
`;
