import gql from 'graphql-tag';
import { NotificationActivityDtoFragment } from '../notification/notification-activity-dto.fragment';
import { WorkspaceActivityReplyDtoFragment, WorkspaceActivityUnionTypeFragment } from '../workspace-activity';

export const WorkspaceNotificationActivityDtoFragment = gql`
  fragment WorkspaceNotificationActivityDtoFragment on WorkspaceNotificationActivityDto {
    ...NotificationActivityDtoFragment
    activity {
      ...WorkspaceActivityUnionTypeFragment
    }
    activityReply {
      ...WorkspaceActivityReplyDtoFragment
    }
  }
  ${NotificationActivityDtoFragment}
  ${WorkspaceActivityUnionTypeFragment}
  ${WorkspaceActivityReplyDtoFragment}
`;
