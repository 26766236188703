import { css, CSSObject, injectGlobal } from '@emotion/css';

const tssClasses: { [key: string]: number } = {};

// used for debugging by exporting this as `tss`.
const tssDebug = (...args: any[]) => {
  const tssClass = css(...args);

  if (tssClasses[tssClass]) {
    tssClasses[tssClass] = tssClasses[tssClass] + 1;
  } else {
    tssClasses[tssClass] = 1;
  }

  return tssClass;
};

// excuser moir
(window as any).tssClasses = tssClasses;
(window as any).getNumTssClasses = () => Object.keys(tssClasses).length;

/** Dynamically creates a css class given input styles, returns classname as string. */
export const tss = css;

/** Globally inject the provided styles to be available anywhere. */
export const injectGlobalTss = injectGlobal;

export type TssStylesObject<MP = {}> = CSSObject;

// DEV-ONLY: periodically check the number of emotion elements in head; this should never grow unbounded, and should converge
// more than 2k emotion elements in head is a sign of a memory leak, and will cause the browser to slow down significantly over time.
// setInterval(() => {
//   console.log('num emotion elements in head:', document.head.querySelectorAll('style[data-emotion]').length);
// }, 10_000);
