import gql from 'graphql-tag';
import { OrganizationCreatedActivityFragment } from './organization-created-activity.fragment';

export const OrganizationActivityUnionTypeFragment = gql`
  fragment OrganizationActivityUnionTypeFragment on OrganizationActivityUnionType {
    ... on OrganizationCreatedActivityDto {
      ...OrganizationCreatedActivityFragment
    }
  }
  ${OrganizationCreatedActivityFragment}
`;
