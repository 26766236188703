import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getFontStyle, px } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { FontSet, fontWeight, ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const fontWeightMedium = fontWeight.medium;

interface HtmlTextState {
  text?: string;

  font?: FontSet;
  fontSize?: number;
  lineHeight?: number;
  fontWeight?: number;

  fgColor: string;
  maxLines: number;
}

@Component({
  selector: 't5s-html-text',
  template: `<span [class]="textClass$ | push" [innerHTML]="text$ | push"> </span>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HtmlTextComponent extends RxComponent<HtmlTextState> {
  constructor() {
    super();
    this.set({
      fgColor: ThemeColorVar.darkest,
      maxLines: -1,
    });
  }

  @Input() set text(text: string | Observable<string>) {
    this.setProperty('text', text);
  }

  @Input() set font(font: FontSet | Observable<FontSet>) {
    this.setProperty('font', font);
  }

  @Input() set fontSize(fontSize: number | Observable<number>) {
    this.setProperty('fontSize', fontSize);
  }

  @Input() set lineHeight(lineHeight: number | Observable<number>) {
    this.setProperty('lineHeight', lineHeight);
  }

  @Input() set fontWeight(fontWeight: number | Observable<number>) {
    this.setProperty('fontWeight', fontWeight);
  }

  @Input() set fgColor(fgColor: string | Observable<string>) {
    this.setProperty('fgColor', fgColor);
  }

  @Input() set maxLines(maxLines: number | Observable<number>) {
    this.setProperty('maxLines', maxLines);
  }

  readonly text$ = this.select(selectSlice(['text'])).pipe(map(({ text }) => text ?? ''));

  readonly textClass$ = this.state$.pipe(
    map(({ font, fontSize, lineHeight, fontWeight, fgColor, maxLines }) => {
      fontSize = fontSize ?? font?.fontSize ?? 0;
      lineHeight = lineHeight ?? font?.lineHeight ?? 0;
      fontWeight = fontWeight ?? font?.fontWeight ?? 0;

      return tss({
        display: 'inline-block',
        color: fgColor,
        ...getFontStyle({ fontSize, lineHeight, fontWeight }),
        b: {
          fontWeight: fontWeightMedium,
        },
        wordBreak: 'break-word',
        ...(maxLines > 0
          ? {
              display: '-webkit-box',
              WebkitLineClamp: maxLines,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              maxHeight: px(maxLines * lineHeight), // Important for Safari
            }
          : {}),
      });
    }),
  );
}
