import gql from 'graphql-tag';
import { CardPaymentMethodDtoFragment } from './card-payment-method.fragment';
import { SepaDebitPaymentMethodDtoFragment } from './sepa-debit-payment-method.fragment';

export const PaymentMethodUnionTypeFragment = gql`
  fragment PaymentMethodUnionTypeFragment on PaymentMethodUnionType {
    ...CardPaymentMethodDtoFragment
    ...SepaDebitPaymentMethodDtoFragment
  }
  ${CardPaymentMethodDtoFragment}
  ${SepaDebitPaymentMethodDtoFragment}
`;
