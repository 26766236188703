import gql from 'graphql-tag';
import { ConvertSingleCategoryFlimToMultiCategoryFlimResultDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../../flim-def';

export const ConvertSingleCategoryFlimToMultiCategoryFlimResultDtoFragment = gql`
  fragment ConvertSingleCategoryFlimToMultiCategoryFlimResultDtoFragment on ConvertSingleCategoryFlimToMultiCategoryFlimResultDto {
    ...ConvertSingleCategoryFlimToMultiCategoryFlimResultDtoNoNesting

    flimDef {
      ...FullFlimDef
    }
  }
  ${ConvertSingleCategoryFlimToMultiCategoryFlimResultDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
