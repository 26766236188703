import gql from 'graphql-tag';
import { BlabDefPreviewDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const BlabDefSearchResultLightFragment = gql`
  fragment BlabDefSearchResultLight on BlabDefSearchResultDto {
    blabDef {
      ...BlabDefPreviewDtoNoNesting
    }
  }
  ${BlabDefPreviewDtoNoNestingFragment}
`;
