import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[t5sFlexRow]',
  exportAs: 't5sFlexRow',
})
export class FlexRowDirective {
  constructor(el: ElementRef<HTMLElement>) {
    el.nativeElement.style.setProperty('display', 'flex');
    el.nativeElement.style.setProperty('flex-direction', 'row');
  }
}
