import gql from 'graphql-tag';
import {
  JoinAudioRoomResultDtoNoNestingFragment,
  JoinAudioRoomTransportOptionsNoNestingFragment,
} from '../../generated/graphql-fragments';
import { AudioRoomDtoFragment } from './audio-room.dto.fragment';

export const JoinAudioRoomResultDtoFragment = gql`
  fragment JoinAudioRoomResultDtoFragment on JoinAudioRoomResultDto {
    ...JoinAudioRoomResultDtoNoNesting
    transportOptions {
      ...JoinAudioRoomTransportOptionsNoNesting
    }
    audioRoom {
      ...AudioRoomDtoFragment
    }
  }
  ${JoinAudioRoomResultDtoNoNestingFragment}
  ${AudioRoomDtoFragment}
  ${JoinAudioRoomTransportOptionsNoNestingFragment}
`;
