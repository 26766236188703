export const setUserStatusEnLangClient = {
  deleteAfter: `Clear after`,
  inputPlaceholder: `What's your status?`,
  saveBtn: `Save`,
  statusSuggestions: {
    commuting: `Commuting`,
    inMeeting: `In a meeting`,
    remote: `Working remotely`,
    sick: `Out sick`,
    vacation: `Vacationing`,
  },
  title: `Set a status`,
  until: {
    ['THIRTY_MIN']: '30 min',
    ['ONE_HOUR']: '1 hour',
    ['TODAY']: 'Today',
    ['THIS_WEEK']: 'This week',
    custom: 'Custom',
    doNotClear: 'Do not clear',
  } as {
    [key: string]: string;
  },
} as const;
