import { I18nTranslation } from '@t5s/client/i18n/common';
import { Language } from '@t5s/shared/gql';

const languages = {
  [Language.DE]: {
    addedByUserLabel: 'Hinzugefügt von {{primaryName}}',
    addedByAutomationLabel: 'Hinzugefügt von Automatisierung',
    addedByEmailLabel: 'Hinzugefügt von  E-Mail',
    closeTooltip: 'Schließen',
    downloadTooltip: 'Herunterladen',
    prevBtnTooltip: 'Zurück',
    nextBtnTooltip: 'Weiter',
  },
  [Language.EN]: {
    addedByUserLabel: 'Added by {{primaryName}}',
    addedByAutomationLabel: 'Added by Automation',
    addedByEmailLabel: 'Added by Email',
    closeTooltip: 'Close',
    downloadTooltip: 'Download',
    prevBtnTooltip: 'Previous',
    nextBtnTooltip: 'Next',
  },
} as const;

export const FilesViewerI18n = new I18nTranslation<typeof languages.EN>(languages);
