import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PushNotificationState, pushNotificationStateKey } from './push-notification.reducer';

const selectPushNotificationFeatureState = createFeatureSelector<PushNotificationState>(pushNotificationStateKey);

export const selectPushNotificationFcmToken = createSelector(
  selectPushNotificationFeatureState,
  (state) => state.fcmToken,
);

export const selectPushNotificationsReceived = createSelector(
  selectPushNotificationFeatureState,
  (state) => state.notifications,
);
