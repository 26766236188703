import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { ACTIVE_USER_ID } from '@t5s/client/data-access/http/active-user-context';
import { RuntimeFeatureAccessLevelObservable } from '@t5s/client/util/feature-access-level';
import {
  ActiveUserIdObservable,
  ActiveUserSessionCookieObservable,
} from '@t5s/mobile-client/provider-token/active-user';
import { ServiceStateHydrationModule } from '@t5s/mobile-client/service/state-hydration';
import { ServiceStorageModule } from '@t5s/mobile-client/service/storage';
import {
  GqlActiveUserPresenceService,
  GqlActiveUserService,
  GqlSessionService,
  SharedApolloClient,
} from '@t5s/shared/gql-services';
import { distinctUntilChanged } from 'rxjs/operators';
import { ActiveUserContextRootGuard } from './active-user-context-root.guard';
import { ActiveUserContextGuard } from './active-user-context.guard';
import { ActiveUserSessionCookieHttpInterceptor } from './active-user-session-cookie.http-interceptor';
import { ActiveUserSessionHydrationEffects } from './active-user-session-hydration.effects';
import { ActiveUserSessionEffects } from './active-user-session.effects';
import { activeUserSessionReducer, activeUserSessionStateKey } from './active-user-session.reducer';
import {
  selectActiveUserFeatureAccessLevel,
  selectActiveUserId,
  selectActiveUserSessionCookie,
} from './active-user-session.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(activeUserSessionStateKey, activeUserSessionReducer),
    EffectsModule.forFeature([ActiveUserSessionEffects, ActiveUserSessionHydrationEffects]),
    ServiceStorageModule,
    ServiceStateHydrationModule,
  ],
  providers: [
    {
      provide: ActiveUserIdObservable,
      useFactory: (store$: Store): ActiveUserIdObservable =>
        store$.select(selectActiveUserId).pipe(distinctUntilChanged()),
      deps: [Store],
    },
    {
      provide: ActiveUserSessionCookieObservable,
      useFactory: (store$: Store) => store$.select(selectActiveUserSessionCookie),
      deps: [Store],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ActiveUserSessionCookieHttpInterceptor,
      multi: true,
    },
    {
      provide: RuntimeFeatureAccessLevelObservable,
      useFactory: (store$: Store) => store$.select(selectActiveUserFeatureAccessLevel),
      deps: [Store],
    },
    {
      provide: ACTIVE_USER_ID,
      useFactory: (store$: Store) => store$.select(selectActiveUserId).pipe(distinctUntilChanged()),
      deps: [Store],
    },
    ActiveUserContextGuard,
    ActiveUserContextRootGuard,
    ActiveUserSessionCookieHttpInterceptor,
    {
      provide: GqlSessionService,
      useFactory: (apollo: SharedApolloClient) => new GqlSessionService(apollo),
      deps: [SharedApolloClient],
    },
    {
      provide: GqlActiveUserService,
      useFactory: (apollo: SharedApolloClient) => new GqlActiveUserService(apollo),
      deps: [SharedApolloClient],
    },
    {
      provide: GqlActiveUserPresenceService,
      useFactory: (apollo: SharedApolloClient) => new GqlActiveUserPresenceService(apollo),
      deps: [SharedApolloClient],
    },
  ],
})
export class BusinessLogicActiveUserSessionModule {}
