import {
  ChecklistFlimValDto,
  FlimType,
  FlimValDto,
  FlimValUnionType,
  MultiAttachmentFlimValDto,
  MultiEmailFlimValDto,
  MultiImageFlimValDto,
  MultiLinkFlimValDto,
  MultiPhoneFlimValDto,
  SingleLocationFlimValDto,
} from '@t5s/shared/gql';

/** Filters an array of flimValDtos by its flimType. */
export function getFlimValsOfType<T extends FlimValDto>(flimVals: FlimValDto[], flimType: FlimType): T[] {
  return flimVals.filter((flimVal) => flimVal.type === flimType) as T[];
}

export function isChecklistFlimVal(flimVal: FlimValDto): flimVal is ChecklistFlimValDto {
  if (flimVal.type !== FlimType.CHECKLIST) {
    return false;
  }

  return true;
}

export function getChecklistFlimVals(flimVals: FlimValDto[]): ChecklistFlimValDto[] {
  return flimVals.filter(isChecklistFlimVal);
}

export function isMultiLinkFlimVal(flimVal: FlimValDto): flimVal is MultiLinkFlimValDto {
  if (flimVal.type !== FlimType.MULTI_LINK) {
    return false;
  }

  return true;
}

export function getMultiLinkFlimVals(flimVals: FlimValDto[]): MultiLinkFlimValDto[] {
  return flimVals.filter(isMultiLinkFlimVal);
}

export function isMultiAttachmentFlimVal(flimVal: FlimValUnionType): flimVal is MultiAttachmentFlimValDto {
  if (flimVal.type !== FlimType.MULTI_ATTACHMENT) {
    return false;
  }

  return true;
}

export function isMultiEmailFlimVal(flimVal: FlimValDto): flimVal is MultiEmailFlimValDto {
  if (flimVal.type !== FlimType.MULTI_EMAIL) {
    return false;
  }

  return true;
}

export function getMultiEmailFlimVals(flimVals: FlimValDto[]): MultiEmailFlimValDto[] {
  return flimVals.filter(isMultiEmailFlimVal);
}

export function isMultiPhoneFlimVal(flimVal: FlimValDto): flimVal is MultiPhoneFlimValDto {
  if (flimVal.type !== FlimType.MULTI_PHONE) {
    return false;
  }

  return true;
}

export function getMultiPhoneFlimVals(flimVals: FlimValDto[]): MultiPhoneFlimValDto[] {
  return flimVals.filter(isMultiPhoneFlimVal);
}

export function isMultiImageFlimVal(flimVal: FlimValDto): flimVal is MultiImageFlimValDto {
  if (flimVal.type !== FlimType.MULTI_IMAGE) {
    return false;
  }

  return true;
}

export function getMultiImageFlimVals(flimVals: FlimValDto[]): MultiImageFlimValDto[] {
  return flimVals.filter(isMultiImageFlimVal);
}

export function isSingleLocationFlimVal(flimVal: FlimValDto): flimVal is SingleLocationFlimValDto {
  if (flimVal.type !== FlimType.SINGLE_LOCATION) {
    return false;
  }

  return true;
}

export function getSingleLocationFlimVals(flimVals: FlimValDto[]): SingleLocationFlimValDto[] {
  return flimVals.filter(isSingleLocationFlimVal);
}
