import gql from 'graphql-tag';
import { WorkspaceCreatedActivityFragment } from './workspace-created-activity.fragment';
import { WorkspaceUserAddedActivityFragment } from './workspace-user-added-activity.fragment';

export const WorkspaceActivityUnionTypeFragment = gql`
  fragment WorkspaceActivityUnionTypeFragment on WorkspaceActivityUnionType {
    ... on WorkspaceCreatedActivityDto {
      ...WorkspaceCreatedActivityFragment
    }
    ... on WorkspaceUserAddedActivityDto {
      ...WorkspaceUserAddedActivityFragment
    }
  }
  ${WorkspaceCreatedActivityFragment}
  ${WorkspaceUserAddedActivityFragment}
`;
