import { createAction, props } from '@ngrx/store';
import { EmojiPickerPosition, EmojiSearchResult } from '@t5s/mobile-client/value-object/emoji-picker';
import { EmojiPickerState } from './emoij-picker.state';

const PREFIX = '[mobile-client-emoji-picker]';

export const EmojiPickerActions = {
  open: createAction(`${PREFIX} open`),
  close: createAction(`${PREFIX} close`),
  setPosition: createAction(`${PREFIX} set position`, props<{ position: EmojiPickerPosition }>()),
  picked: createAction(`${PREFIX} picked`, props<{ id: string; emoji: string }>()),

  // Search
  enterSearchMode: createAction(`${PREFIX} enter search mode`),
  leaveSearchMode: createAction(`${PREFIX} leave search mode`),
  setSearchQuery: createAction(`${PREFIX} set search query`, props<{ searchQuery: string }>()),
  searchEmojisSuccess: createAction(`${PREFIX} set search results`, props<{ searchResults: EmojiSearchResult[] }>()),

  // State hydration
  hydrateState: createAction(`${PREFIX} Hydrate state`),
  hydrateStateSuccess: createAction(`${PREFIX} Hydrate state Success`, props<{ state: EmojiPickerState }>()),
  hydrateStateNotAvailable: createAction(`${PREFIX} Hydration of state not available`),
  hydrateStateException: createAction(`${PREFIX} Hydration of state failed with exception`),
};
