import gql from 'graphql-tag';
import { BlabItemCommentDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { BlabItemActivityDtoFragment } from '../blab-item-activity';
import { BlabItemCreatedActivityFragment } from './blab-item-created-activity.fragment';
import { BlabItemDeletedActivityFragment } from './blab-item-deleted-activity.fragment';
import { BlabItemInvitationCreatedActivityFragment } from './blab-item-invitation-created-activity.fragment';
import { BlabItemRestoredActivityFragment } from './blab-item-restored-activity.fragment';
import { NotificationBlabItemRevisionActivityFragmentV2 } from './blab-item-revison-activity-dto.fragment';

export const BlabItemActivityUnionTypeFragment = gql`
  fragment NotificationBlabItemActivityUnionTypeFragmentV2 on BlabItemActivityUnionType {
    ... on BlabItemCreatedActivityDto {
      ...NotificationBlabItemCreatedActivityFragmentV2
    }

    ... on BlabItemDeletedActivityDto {
      ...NotificationBlabItemDeletedActivityFragmentV2
    }

    ... on BlabItemRestoredActivityDto {
      ...NotificationBlabItemRestoredActivityFragmentV2
    }

    ... on BlabItemInvitationCreatedActivityDto {
      ...NotificationBlabItemInvitationCreatedActivityFragmentV2
    }

    ... on BlabItemCommentActivityDto {
      ...BlabItemActivityDtoFragment
      comment {
        ...BlabItemCommentDtoNoNesting
      }
    }

    ... on BlabItemRevisionActivityDto {
      ...NotificationBlabItemRevisionActivityFragmentV2
    }
  }
  ${BlabItemCreatedActivityFragment}
  ${BlabItemDeletedActivityFragment}
  ${BlabItemRestoredActivityFragment}
  ${BlabItemInvitationCreatedActivityFragment}
  ${BlabItemActivityDtoFragment}
  ${BlabItemCommentDtoNoNestingFragment}
  ${NotificationBlabItemRevisionActivityFragmentV2}
`;
