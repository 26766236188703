export { isTouchDevice } from '@t5s/client/util/device-info';

export function isPlatformAndroid(platform: 'android' | 'ios' | 'web'): platform is 'android' {
  return platform === 'android';
}

export function isPlatformIos(platform: 'android' | 'ios' | 'web'): platform is 'ios' {
  return platform === 'ios';
}

export function isPlatformWeb(platform: 'android' | 'ios' | 'web'): platform is 'web' {
  return platform === 'web';
}
