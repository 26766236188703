import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FilesViewerFileLoadingState } from '@t5s/mobile-client/value-object/files-viewer';
import { FileAttachmentPreviewState, fileAttachmentPreviewStateKey } from './file-attachment-preview.reducer';

const selectFileAttachemntPreviewFeatureState =
  createFeatureSelector<FileAttachmentPreviewState>(fileAttachmentPreviewStateKey);

export const selectFileAttachemntPreviewLoading = createSelector(
  selectFileAttachemntPreviewFeatureState,
  ({ openFileLoading, openFileWithDialogLoading }) => {
    return (
      openFileLoading === FilesViewerFileLoadingState.LOADING ||
      openFileWithDialogLoading === FilesViewerFileLoadingState.LOADING
    );
  },
);
