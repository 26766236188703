import gql from 'graphql-tag';
import { FullBlabDefDtoFragment } from '../blab-def/blab-def.fragment';

export const GetBlabDefWithIncomingAndOutgoingBlabDefsResultFragment = gql`
  fragment GetBlabDefWithIncomingAndOutgoingBlabDefsResult on GetBlabDefWithIncomingAndOutgoingBlabDefsResultDto {
    blabDef {
      ...FullBlabDefDto
    }
    incomingBlabDefs {
      ...FullBlabDefDto
    }
    outgoingBlabDefs {
      ...FullBlabDefDto
    }
  }
  ${FullBlabDefDtoFragment}
`;
