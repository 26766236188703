import { NgModule } from '@angular/core';
import {} from '@t5s/mobile-client/service/haptics';
import { AssetComponentModule } from '@t5s/mobile-client/ui/component/asset';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { LoadingSpinnerFullWidthComponent } from './loading-spinner-full-width.component';
import { LoadingSpinnerSpreadComponent } from './loading-spinner-spread.component';
import { LoadingSpinnerTopComponent } from './loading-spinner-top.component';
import { LoadingSpinnerComponent } from './loading-spinner.component';

const components = [
  LoadingSpinnerComponent,
  LoadingSpinnerSpreadComponent,
  LoadingSpinnerFullWidthComponent,
  LoadingSpinnerTopComponent,
];

@NgModule({
  imports: [ComponentCommonModule, AssetComponentModule],
  declarations: components,
  exports: components,
})
export class LoadingSpinnerComponentModule {}
