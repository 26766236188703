import gql from 'graphql-tag';
import {
  StatusOptionDefDtoNoNestingFragment,
  StatusSplitByDtoNoNestingFragment,
  StatusSplitBySectionDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const StatusSplitByDtoFragment = gql`
  fragment StatusSplitByDto on StatusSplitByDto {
    ...StatusSplitByDtoNoNesting
    sections {
      ...StatusSplitBySectionDtoNoNesting
      statusOptionDef {
        ...StatusOptionDefDtoNoNesting
      }
    }
  }
  ${StatusSplitByDtoNoNestingFragment}
  ${StatusSplitBySectionDtoNoNestingFragment}
  ${StatusOptionDefDtoNoNestingFragment}
`;
