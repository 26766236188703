import { isPlatformBrowser } from '@angular/common';
import { BrowserWindowRef } from './browser-window-ref';

/* Factory function that returns the native window object. */
export function windowFactory(browserWindowRef: BrowserWindowRef, platformId: Object): Window | undefined {
  if (isPlatformBrowser(platformId)) {
    return browserWindowRef.nativeWindow;
  }

  return undefined;
}
