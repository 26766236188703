import { CdkPortalOutlet, ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RootActions } from '@t5s/mobile-client/business-logic/root';
import { BottomSheetPosition, BottomSheetRef, IBottomSheetPage } from '@t5s/mobile-client/value-object/bottom-sheet';
import { merge, Observable, of } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { PushNotificationActions } from '@t5s/mobile-client/business-logic/push-notification';
import { HardwareBackButtonObservable } from '@t5s/mobile-client/provider-token/hardware-back-button';

@Injectable()
export class BottomSheetController {
  private outlet?: CdkPortalOutlet;

  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly backBtn$: HardwareBackButtonObservable,
  ) {}

  /** Creates a new bottom sheet instance and renders it into DOM. */
  create<T extends IBottomSheetPage>(
    component: ComponentType<T>,
    props: Partial<T> = {},
  ): Observable<BottomSheetRef<T>> {
    const portal = new ComponentPortal<T>(component);

    if (!this.outlet) {
      throw new Error(
        'Component portal for bottom sheets not available. Call registerPortalOutlet before spawning bottom sheets.',
      );
    }

    if (this.outlet.hasAttached()) {
      this.outlet.detach();
    }

    const renderedComponentRef = portal.attach(this.outlet);

    const { instance } = renderedComponentRef;

    // Asssemble destroy method to pass to consumer
    const destroy = () => {
      renderedComponentRef.destroy();
      portal.detach();
    };

    // Assign props to rendered component
    Object.assign(instance, props);

    const { positionChange$ } = instance;

    const positionNotBottom$ = positionChange$.pipe(
      filter(({ position }) => position !== BottomSheetPosition.IS_BOTTOM),
    );

    const positionIsBottom$ = positionChange$.pipe(
      filter(({ position }) => position === BottomSheetPosition.IS_BOTTOM),
    );

    // ensure to set global state based on this bottom sheet's position state
    positionChange$.subscribe(({ position: sheetPosition }) => {
      this.store$.dispatch(RootActions.setSheetPosition({ sheetPosition }));
    });

    // Important to avoid zone; this has to happen AFTER above subscriptions are established to capture all events
    renderedComponentRef.changeDetectorRef.detectChanges();

    const shouldDestroy$ = positionNotBottom$.pipe(switchMap(() => positionIsBottom$));

    shouldDestroy$.subscribe(() => {
      destroy();
    });

    // Certain events should always lead to bottom sheet being closed
    const shouldClose$ = merge(
      this.actions$.pipe(ofType(PushNotificationActions.pushNotificationActionPerformed)),
      this.backBtn$,
    );

    shouldClose$.pipe(takeUntil(shouldDestroy$)).subscribe(() => instance.close());

    return of({ instance, destroy });
  }

  registerPortalOutlet(outlet: CdkPortalOutlet) {
    this.outlet = outlet;
  }
}
