import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { fileFromBase64Data } from '@t5s/client/util/file';
import { DeviceMediaOptions, DeviceMediaType } from '@t5s/mobile-client/value-object/device-media';
import { merge, of } from 'rxjs';
import { first, map, mapTo, switchMap } from 'rxjs/operators';
import { DeviceMediaActions } from './device-media.actions';

export function getPhoto(store$: Store, actions$: Actions, options: DeviceMediaOptions) {
  store$.dispatch(DeviceMediaActions.getPhoto({ options }));

  const picked$ = actions$.pipe(
    ofType(DeviceMediaActions.getPhotoSuccess),
    map(({ photo }) => ({
      files: [fileFromBase64Data(photo.base64String ?? '', `photo.${photo.format}`, `image/${photo.format}`)],
    })),
  );
  const closed$ = actions$.pipe(
    ofType(DeviceMediaActions.getPhotoException, DeviceMediaActions.getPhotoCancel, DeviceMediaActions.getPhotoDenied), // TODO: util for photo to file
    mapTo(undefined),
  );

  return merge(picked$, closed$).pipe(first());
}

export function getCamera(store$: Store, actions$: Actions, options: DeviceMediaOptions) {
  store$.dispatch(DeviceMediaActions.getCamera({ options }));

  const picked$ = actions$.pipe(
    ofType(DeviceMediaActions.getCameraSuccess),
    map(({ camera }) => ({
      files: [fileFromBase64Data(camera.base64String ?? '', `photo.${camera.format}`, `image/${camera.format}`)],
    })), // TODO: util for photo to file
  );
  const closed$ = actions$.pipe(
    ofType(
      DeviceMediaActions.getCameraException,
      DeviceMediaActions.getCameraCancel,
      DeviceMediaActions.getCameraDenied,
    ),
    mapTo(undefined),
  );

  return merge(picked$, closed$).pipe(first());
}

export function getDocument(store$: Store, actions$: Actions, options: { fileTypes?: string[] }) {
  store$.dispatch(DeviceMediaActions.getDocument());

  const picked$ = actions$.pipe(
    ofType(DeviceMediaActions.getDocumentSuccess),
    map(({ document }) => ({ files: document.files })), // TODO:
  );
  const closed$ = actions$.pipe(
    ofType(DeviceMediaActions.getDocumentException, DeviceMediaActions.getDocumentCancel),
    mapTo(undefined),
  );

  return merge(picked$, closed$).pipe(first());
}

export function getDeviceMedia(store$: Store, actions$: Actions, options: DeviceMediaOptions) {
  if (options.type) {
    switch (options.type) {
      case DeviceMediaType.CAMERA:
        return getCamera(store$, actions$, options);

      case DeviceMediaType.PHOTO:
        return getPhoto(store$, actions$, options);

      case DeviceMediaType.DOCUMENT:
        return getDocument(store$, actions$, {});
    }
  }

  store$.dispatch(DeviceMediaActions.open());

  const picked$ = actions$.pipe(
    ofType(DeviceMediaActions.picked),
    map(({ deviceMedia }) => ({ deviceMedia })),
  );
  const closed$ = actions$.pipe(ofType(DeviceMediaActions.close), mapTo(undefined));

  const deviceMedia$ = merge(picked$, closed$).pipe(
    first(),
    switchMap((result) => {
      if (!result) {
        return of(undefined);
      }

      switch (result.deviceMedia) {
        case DeviceMediaType.PHOTO:
          return getPhoto(store$, actions$, options);

        case DeviceMediaType.CAMERA:
          return getCamera(store$, actions$, options);

        case DeviceMediaType.DOCUMENT:
          return getDocument(store$, actions$, {});
      }
    }),
  );

  return deviceMedia$;
}
