import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { ActiveUserIdObservable } from '@t5s/mobile-client/provider-token/active-user';
import { StateHydrationService } from '@t5s/mobile-client/service/state-hydration';
import { filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { debounceTime, first, map, switchMap } from 'rxjs/operators';
import { BlabItemDisplayHydrationState } from './blab-item-display-hydration.state';
import { BlabItemDisplayActions } from './blab-item-display.actions';
import { blabItemDisplayStateKey } from './blab-item-display.reducer';
import { selectSearchHistoryFeatureStateForPersistance } from './blab-item-display.selectors';

@Injectable()
export class BlabItemDisplayHydrationEffects implements OnInitEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly activeUserId$: ActiveUserIdObservable,
    private readonly stateHydrationService: StateHydrationService<BlabItemDisplayHydrationState>,
  ) {}

  readonly hydrationPersistanceKey$ = this.activeUserId$.pipe(
    filterOnlyPresent(),
    map((activeUserId) => `${blabItemDisplayStateKey}_${activeUserId}`),
  );

  readonly hydrationPersistanceKeyAvailable$ = createEffect(() =>
    this.hydrationPersistanceKey$.pipe(first(), map(BlabItemDisplayActions.hydrateState)),
  );

  readonly persistState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          BlabItemDisplayActions.hydrateStateSuccess,
          BlabItemDisplayActions.hydrateStateNotAvailable,
          BlabItemDisplayActions.hydrateStateException,
        ),
        switchMap(() => this.store$.select(selectSearchHistoryFeatureStateForPersistance)),
        concatLatestFrom(() => this.hydrationPersistanceKey$),
        debounceTime(5000),
        switchMap(([state, hydrationPersistanceKey]) =>
          this.stateHydrationService.persistState(hydrationPersistanceKey, state),
        ),
      ),
    { dispatch: false },
  );

  readonly hydrateState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.hydrateState),
      concatLatestFrom(() => this.hydrationPersistanceKey$),
      switchMap(([_, hydrationPersistanceKey]) =>
        this.stateHydrationService.retrieveState(hydrationPersistanceKey).pipe(
          map((state) => {
            if (!state) {
              return BlabItemDisplayActions.hydrateStateNotAvailable();
            }

            return BlabItemDisplayActions.hydrateStateSuccess({ state });
          }),
          catchErrorDispatch(BlabItemDisplayActions.hydrateStateException),
        ),
      ),
    ),
  );

  readonly hydrateStateExceptionClear$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BlabItemDisplayActions.hydrateStateException),
        concatLatestFrom(() => this.hydrationPersistanceKey$),
        switchMap(([_, hydrationPersistanceKey]) => this.stateHydrationService.clearState(hydrationPersistanceKey)),
      ),
    { dispatch: false },
  );

  ngrxOnInitEffects() {
    return BlabItemDisplayActions.hydrateState();
  }
}
