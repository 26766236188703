import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { GqlChatActivityReactionService } from '@t5s/shared/gql-services';
import { map, switchMap } from 'rxjs/operators';
import { ChatConversationActions } from './chat-conversation.actions';

@Injectable()
export class ChatConversationActivityEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly gqlActivityService: GqlChatActivityReactionService,
  ) {}

  readonly addChatActivityReaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatConversationActions.addChatActivityReaction),
      switchMap(({ emoji, activityId }) =>
        this.gqlActivityService.addChatActivityReaction({ emoji, activityId }).pipe(
          map((activity) => ChatConversationActions.addChatActivityReactionSuccess({ activity, emoji })),
          catchErrorDispatch(ChatConversationActions.addChatActivityReactionException),
        ),
      ),
    ),
  );

  readonly removeChatActivityReaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatConversationActions.removeChatActivityReaction),
      switchMap(({ emoji, activityId }) =>
        this.gqlActivityService.removeChatActivityReaction({ emoji, activityId }).pipe(
          map((activity) => ChatConversationActions.removeChatActivityReactionSuccess({ activity, emoji })),
          catchErrorDispatch(ChatConversationActions.removeChatActivityReactionException),
        ),
      ),
    ),
  );
}
