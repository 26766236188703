import gql from 'graphql-tag';
import {
  BlabDefDataImportActivityPreviewDtoNoNestingFragment,
  BlabDefDataImportFileDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { UserProfilePreviewDtoFragment } from '../user-profile/user-profile-preview-dto.fragment';

export const BlabDefDataImportActivityPreviewDtoFragment = gql`
  fragment BlabDefDataImportActivityPreviewDtoFragment on BlabDefDataImportActivityPreviewDto {
    ...BlabDefDataImportActivityPreviewDtoNoNesting
    file {
      ...BlabDefDataImportFileDtoNoNesting
    }
    createdByUser {
      ...UserProfilePreviewDtoFragment
    }
  }

  ${BlabDefDataImportActivityPreviewDtoNoNestingFragment}
  ${BlabDefDataImportFileDtoNoNestingFragment}
  ${UserProfilePreviewDtoFragment}
`;
