import { createReducer, on } from '@ngrx/store';
import { ActiveUserDto, UserSessionDto } from '@t5s/shared/gql';
import { ActiveUserSessionHydrationActions } from './active-user-session-hydration.actions';
import { ActiveUserSessionActions } from './active-user-session.actions';

export const activeUserSessionStateKey = 'activeUserSession';
export interface ActiveUserSessionState {
  cookie?: string;
  cookieLoaded: boolean;

  sessions: UserSessionDto[];
  sessionsLoaded: boolean;

  activeUserId?: number;
  activeUser?: ActiveUserDto;
}

const initialState: ActiveUserSessionState = {
  cookieLoaded: false,
  sessionsLoaded: false,
  sessions: [],
};

export const activeUserSessionReducer = createReducer(
  initialState,
  on(ActiveUserSessionActions.logoutSuccess, () => ({ ...initialState })),
  on(ActiveUserSessionActions.forceLogoutSuccess, () => ({ ...initialState })),

  on(ActiveUserSessionActions.setActiveUserSessions, (state, { sessions }) => ({
    ...state,
    sessions,
  })),
  on(ActiveUserSessionActions.initializeUserSessionsSuccess, (state, { sessions }) => ({
    ...state,
    sessions,
    sessionsLoaded: true,
  })),
  on(ActiveUserSessionActions.initializeUserSessionsError, (state) => ({
    ...state,
    sessionsLoaded: true,
  })),
  on(ActiveUserSessionActions.setActiveUserSessionCookie, (state, { cookie }) => ({ ...state, cookie })),
  on(ActiveUserSessionActions.loadActiveUserSuccess, (state, { activeUser }) => ({ ...state, activeUser })),

  on(ActiveUserSessionHydrationActions.hydrateStateSuccess, (state, { state: hydratedState }) => {
    const { sessions, activeUser, activeUserId, cookie, cookieLoaded, sessionsLoaded } = hydratedState;

    return {
      ...state,
      sessions,
      activeUser,
      activeUserId: activeUserId ?? activeUser?.id,
      cookie,
      cookieLoaded,
      sessionsLoaded,
    };
  }),

  on(ActiveUserSessionActions.loadActiveUserUnauthenticatedException, (state) => initialState),
);
