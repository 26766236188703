import { Language } from '@t5s/shared/gql';

export function shouldAbbreviateMonth({ language }: { language: Language }, date: Date): boolean {
  switch (language) {
    case Language.EN: {
      return true;
    }

    case Language.DE: {
      const month = date.getMonth();
      switch (month) {
        // only for german language, march and may should not be abbreviated
        case 2:
        case 4: {
          return false;
        }

        default: {
          return true;
        }
      }
    }

    default: {
      return true;
    }
  }
}
