import { NgModule } from '@angular/core';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { FlexColumnComponent } from './flex-column.component';
import { FlexRowComponent } from './flex-row.component';

const components = [FlexColumnComponent, FlexRowComponent];

@NgModule({
  imports: [ComponentCommonModule],
  declarations: components,
  exports: components,
})
export class LayoutComponentModule {}
