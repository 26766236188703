import gql from 'graphql-tag';
import { BlabDefPreviewDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../flim-def/full-flim-def.fragment';
import { UserProfilePreviewDtoOmitOrgFragment } from '../user-profile';

export const BlabDefDataImportToExistingBlabDefConfigPreviewLookupDtoFragment = gql`
  fragment BlabDefDataImportToExistingBlabDefConfigPreviewLookupDto on BlabDefDataImportToExistingBlabDefConfigPreviewLookupDto {
    users {
      ...UserProfilePreviewDtoOmitOrgFragment
    }

    blabDefs {
      ...BlabDefPreviewDtoNoNesting
    }

    flimDefs {
      ...FullFlimDef
    }
  }

  ${UserProfilePreviewDtoOmitOrgFragment}
  ${BlabDefPreviewDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
