import { ErrorHandler, Injectable } from '@angular/core';
import { apm, ApmBase, Span, SpanOptions, Transaction, TransactionOptions } from '@elastic/apm-rum';
// @ts-expect-error lacks declaration file
import { afterFrame } from '@elastic/apm-rum-core';
import { apmConfig } from './apm.config';

@Injectable()
export class ApmService extends ErrorHandler {
  private readonly apm: ApmBase = apm;

  constructor() {
    super();
    try {
      this.apm.init(apmConfig);
    } catch (e: unknown) {}
  }

  /** Finish transaction immediately. */
  detectFinishTransaction(transaction: any) {
    try {
      transaction.detectFinish();
    } catch (e: unknown) {}
  }

  /** Finish transaction after the browser paints the new frame ( includes script, layout, style and paint). */
  detectFinishTransactionAfterFrame(transaction: any) {
    afterFrame(() => {
      try {
        transaction.detectFinish();
      } catch (e: unknown) {}
    });
  }

  handleError(error: any) {
    try {
      this.apm.captureError(error?.originalError || error);
    } catch (e: unknown) {}

    super.handleError(error);
  }

  startTransaction(name?: string | null, type?: string | null, options?: TransactionOptions): Transaction | undefined {
    try {
      const transaction = this.apm.startTransaction(name, type, options);
      return transaction;
    } catch (e: unknown) {}

    return undefined;
  }

  startSpan(name?: string | null, type?: string | null, options?: SpanOptions): Span | undefined {
    try {
      return this.apm.startSpan(name, type, options);
    } catch (e: unknown) {}

    return undefined;
  }
}
