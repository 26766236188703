import { ChangeDetectionStrategy, Component, Input, Output, ViewChild } from '@angular/core';
import {
  ComponentStyle,
  flex1Vertical,
  flexCenter,
  flexColumn,
  fullWidth,
  padding,
  spread,
} from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { ReactionDetailsI18n } from '@t5s/mobile-client/i18n/reaction-details';
import { ViewComponent, ViewState } from '@t5s/mobile-client/ui/view/common';
import { BottomSheetComponent } from '@t5s/mobile-client/ui/component/bottom-sheet';
import { BottomSheetPosition } from '@t5s/mobile-client/value-object/bottom-sheet';
import { selectSlice } from '@t5s/mobile-client/ui/component/common';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { ReactionDto } from '@t5s/shared/gql';
import { EMPTY, Observable } from 'rxjs';
import { trackByEmoji } from '@t5s/client/util/track-by';
import { ScrollContainerComponent } from '@t5s/mobile-client/ui/component/scroll-container';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';

export interface ReactionDetailsViewState extends ViewState {
  position: BottomSheetPosition;
  scrolled: boolean;
  reactions?: ReactionDto[];
}

@Component({
  selector: 't5s-reaction-details-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostSpread],
  template: `
    <t5s-bottom-sheet [gestureDisabled]="gestureDisabled$" (positionChange)="set($event)">
      <div [class]="containerClass">
        <!-- Header -->
        <div [class]="headerClass">
          <t5s-single-line-text [font]="Font.black17px" [fgColor]="Color.darkest">
            {{ headerLabel$ | push }}
          </t5s-single-line-text>
        </div>

        <!-- Reactions -->
        <t5s-scroll-container
          [class]="reactionsContainerClass"
          [scrollY]="canScroll$"
          (scrollTop$)="set({ scrolled: ($event || 0) > 0 })"
        >
          <ng-container *ngFor="let reaction of reactions$ | push; trackBy: trackByEmoji">
            <ng-container *ngFor="let user of reaction.users || []; trackBy: trackById">
              <t5s-reaction-detail [emoji]="reaction.emoji" [user]="user"></t5s-reaction-detail>
            </ng-container>
          </ng-container>
        </t5s-scroll-container>
      </div>
    </t5s-bottom-sheet>
  `,
})
export class ReactionDetailsViewComponent extends ViewComponent<ReactionDetailsViewState> {
  readonly I18n = ReactionDetailsI18n;
  readonly trackByEmoji = trackByEmoji;

  constructor(i18n$: I18nObjectObservable) {
    super(i18n$);

    this.hold(
      this.select(
        selectSlice(['position']),
        filter(({ position }) => position === BottomSheetPosition.IS_BOTTOM),
        switchMap(() => this.scrollContainer?.scrollToTop(0) ?? EMPTY),
      ),
    );
  }

  @ViewChild(BottomSheetComponent) sheet?: BottomSheetComponent;
  @ViewChild(ScrollContainerComponent) scrollContainer?: ScrollContainerComponent;

  @Input() set reactions(reactions: ReactionDto[] | Observable<ReactionDto[]>) {
    this.setProperty('reactions', reactions);
  }

  @Output() positionChange: Observable<{ position: BottomSheetPosition }> = this.select(selectSlice(['position']));

  readonly headerLabel$ = this.select(
    selectSlice(['i18n']),
    map(({ i18n }) => this.I18n.translate(i18n, this.I18n.key.header)),
  );

  readonly canScroll$ = this.select(
    selectSlice(['position']),
    map(({ position }) => position === BottomSheetPosition.IS_TOP),
    distinctUntilChanged(),
  );

  readonly reactions$ = this.select(
    selectSlice(['reactions']),
    map(({ reactions }) => reactions),
  );

  readonly gestureDisabled$ = this.select(
    selectSlice(['scrolled']),
    map(({ scrolled }) => scrolled),
    distinctUntilChanged(),
  );

  readonly containerClass = tss({
    ...spread,
    ...flexColumn,
  });

  readonly headerClass = tss({
    flex: 0,
    padding: padding(4, 16, 12),
    ...flexCenter,
  });

  readonly reactionsContainerClass = tss({
    ...flex1Vertical,
    ...fullWidth,
  });

  open() {
    this.sheet?.open();
  }

  expand() {
    this.sheet?.expand();
  }

  close() {
    this.sheet?.close();
  }
}
