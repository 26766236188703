import gql from 'graphql-tag';
import {
  BlabItemActivityReplyAttachmentDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const BlabItemActivityReplyAttachmentFragment = gql`
  fragment BlabItemActivityReplyAttachmentFragment on BlabItemActivityReplyAttachmentDto {
    ...BlabItemActivityReplyAttachmentDtoNoNesting
    thumbnail {
      ...ThumbnailDtoNoNesting
    }
  }
  ${BlabItemActivityReplyAttachmentDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
