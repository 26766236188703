import { KeysResult } from '@capacitor/preferences';
import { createAction, props } from '@ngrx/store';
import { createErrorAction } from '@t5s/client/util/store';
import { ConsoleStatement } from '@t5s/mobile-client/value-object/debug-console';
import {
  AuthenticateWithCredentialsInput,
  AuthenticateWithCredentialsRejectionReason,
  UserSessionDto,
} from '@t5s/shared/gql';

const PREFIX = '[mobile-client-debug-console]';

export const DebugConsoleActions = {
  reset: createAction(`${PREFIX} reset`),
  // Version
  loadClientBuildVersion: createAction(`${PREFIX} load version`),
  loadClientBuildVersionSuccess: createAction(
    `${PREFIX} load version success`,
    props<{ clientBuildVersion: string }>(),
  ),

  // Storage
  readStorageKeys: createAction(`${PREFIX} read storage keys`),
  readStorageKeysSuccess: createAction(`${PREFIX} read storage keys success`, props<KeysResult>()),

  loadStorageKey: createAction(`${PREFIX} load storage key`, props<{ key: string }>()),
  loadStorageKeySuccess: createAction(
    `${PREFIX} load storage key success`,
    props<{ key: string; value: string | undefined }>(),
  ),

  deleteStorageKey: createAction(`${PREFIX} delete storage key`, props<{ key: string }>()),
  deleteStorageKeySuccess: createAction(`${PREFIX} delete storage key success`, props<{ key: string }>()),

  // Logs
  addConsoleStatement: createAction(`${PREFIX} add console statement`, props<{ statement: ConsoleStatement }>()),

  // Login
  performUserLogin: createAction(`${PREFIX} perform user login`, props<{ input: AuthenticateWithCredentialsInput }>()),
  performUserLoginSuccess: createAction(`${PREFIX} perform user login success`, props<{ session: UserSessionDto }>()),
  performUserLoginRejected: createAction(
    `${PREFIX} perform user login rejected`,
    props<{ reason: AuthenticateWithCredentialsRejectionReason }>(),
  ),
  performUserLoginError: createErrorAction(`${PREFIX} perform user login error`),
};
