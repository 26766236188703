import gql from 'graphql-tag';

export const BlabItemsOverviewWorkflowDefPreviewFragment = gql`
  fragment BlabItemsOverviewWorkflowDefPreview on WorkflowDefPreviewDto {
    isDeleted
    name
    id
    blabDefId
  }
`;
