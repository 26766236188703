import gql from 'graphql-tag';
import {
  BlabDefShareContextDtoNoNestingFragment,
  BlabItemShareContextDtoNoNestingFragment,
  ShareContextResultDtoNoNestingFragment,
  ShareContextUserSessionDtoNoNestingFragment,
  WorkspaceShareContextDtoNoNestingFragment,
  OrganizationShareContextDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const ShareContextResultDtoFragment = gql`
  fragment ShareContextResultDtoFragment on ShareContextResultDto {
    ...ShareContextResultDtoNoNesting
    context {
      ... on OrganizationShareContextDto {
        ...OrganizationShareContextDtoNoNesting
        userSessions {
          ...ShareContextUserSessionDtoNoNesting
        }
      }

      ... on WorkspaceShareContextDto {
        ...WorkspaceShareContextDtoNoNesting
        userSessions {
          ...ShareContextUserSessionDtoNoNesting
        }
      }

      ... on BlabDefShareContextDto {
        ...BlabDefShareContextDtoNoNesting
        userSessions {
          ...ShareContextUserSessionDtoNoNesting
        }
      }

      ... on BlabItemShareContextDto {
        ...BlabItemShareContextDtoNoNesting
        userSessions {
          ...ShareContextUserSessionDtoNoNesting
        }
      }
    }
  }
  ${ShareContextResultDtoNoNestingFragment}
  ${ShareContextUserSessionDtoNoNestingFragment}
  ${OrganizationShareContextDtoNoNestingFragment}
  ${WorkspaceShareContextDtoNoNestingFragment}
  ${BlabDefShareContextDtoNoNestingFragment}
  ${BlabItemShareContextDtoNoNestingFragment}
`;
