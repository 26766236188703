import {
  AddSearchHistoryItemBlabDefInput,
  AddSearchHistoryItemBlabItemInput,
  AddSearchHistoryItemQueryInput,
  AddSearchHistoryItemUserInput,
  AddSearchHistoryItemWorkspaceInput,
  SearchHistoryItemResultDto,
  SearchHistoryItemUnionType,
  Void,
} from '@t5s/shared/gql';
import { ApolloQueryOptions } from '@t5s/shared/gql-config';
import { Observable } from 'rxjs';
import { GlobalSearchHistoryItemFragment } from '../../fragments/global-search-history';
import {
  addSearchHistoryItemBlabDef,
  addSearchHistoryItemBlabItem,
  addSearchHistoryItemQuery,
  addSearchHistoryItemUser,
  addSearchHistoryItemWorkspace,
  clearSearchHistory,
  removeSearchHistoryItem,
} from '../../generated/graphql-mutations';
import { getSearchHistory } from '../../generated/graphql-queries';
import { GqlService } from '../../gql-service';

export class GqlGlobalSearchHistoryService extends GqlService {
  private readonly getSearchHistoryQueryName = `getSearchHistory`;

  getSearchHistory(options: ApolloQueryOptions = {}): Observable<SearchHistoryItemUnionType[]> {
    return this.apollo.defaultQuery({
      ...getSearchHistory([GlobalSearchHistoryItemFragment]),
      ...options,
    });
  }

  addSearchHistoryItemQuery(input: AddSearchHistoryItemQueryInput): Observable<SearchHistoryItemUnionType[]> {
    return this.apollo.defaultMutate({
      ...addSearchHistoryItemQuery(input, [GlobalSearchHistoryItemFragment]),
      refetchQueries: [this.getSearchHistoryQueryName],
    });
  }

  addSearchHistoryItemBlabItem(input: AddSearchHistoryItemBlabItemInput): Observable<SearchHistoryItemUnionType[]> {
    return this.apollo.defaultMutate({
      ...addSearchHistoryItemBlabItem(input, [GlobalSearchHistoryItemFragment]),
      refetchQueries: [this.getSearchHistoryQueryName],
    });
  }

  addSearchHistoryItemBlabDef(input: AddSearchHistoryItemBlabDefInput): Observable<SearchHistoryItemUnionType[]> {
    return this.apollo.defaultMutate({
      ...addSearchHistoryItemBlabDef(input, [GlobalSearchHistoryItemFragment]),
      refetchQueries: [this.getSearchHistoryQueryName],
    });
  }

  addSearchHistoryItemUser(input: AddSearchHistoryItemUserInput): Observable<SearchHistoryItemUnionType[]> {
    return this.apollo.defaultMutate({
      ...addSearchHistoryItemUser(input, [GlobalSearchHistoryItemFragment]),
      refetchQueries: [this.getSearchHistoryQueryName],
    });
  }

  addSearchHistoryItemWorkspace(input: AddSearchHistoryItemWorkspaceInput): Observable<SearchHistoryItemUnionType[]> {
    return this.apollo.defaultMutate({
      ...addSearchHistoryItemWorkspace(input, [GlobalSearchHistoryItemFragment]),
      refetchQueries: [this.getSearchHistoryQueryName],
    });
  }

  clearSearchHistory(): Observable<Void> {
    return this.apollo.defaultMutate({
      ...clearSearchHistory(),
      refetchQueries: [this.getSearchHistoryQueryName],
    });
  }

  removeSearchHistoryItem(id: number): Observable<SearchHistoryItemResultDto[]> {
    return this.apollo.defaultMutate({
      ...removeSearchHistoryItem(id, [GlobalSearchHistoryItemFragment]),
      refetchQueries: [this.getSearchHistoryQueryName],
    });
  }
}
