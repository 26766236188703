import gql from 'graphql-tag';
import { CalculationFlimValFragment } from './calculation';
import { MultiAttachmentFlimValFragment, SingleAttachmentFlimValFragment } from './attachment';
import { MultiCategoryFlimValFragment, SingleCategoryFlimValFragment } from './category';
import { ChecklistFlimValFragment } from './checklist';
import { CreatedAtFlimValFragment } from './created-at';
import { RangeDateFlimValFragment, SingleDateFlimValFragment } from './date';
import { LastModifiedAtFlimValFragment } from './last-modified-at';
import { LastModifiedByFlimValFragment } from './last-modified-by';
import { MultiLinkFlimValFragment } from './multi-link';
import { MultiEmailFlimValFragment } from './email';
import { MultiPhoneFlimValFragment } from './phone';
import { NumberFlimValFragment } from './number';
import { MultiRelationFlimValFragment, SingleRelationFlimValFragment } from './relation';
import { StatusFlimValFragment } from './status/status-flim-val.fragment';
import { MultiTextFlimValFragment, SingleTextFlimValFragment } from './text';
import { MultiUserFlimValFragment, SingleUserFlimValFragment } from './user';
import { SingleLocationFlimValFragment } from './location';
import { MultiImageFlimValFragment } from './image';
import { UniqueIdFlimValFragment } from './unique-id';
import { CreatedByFlimValFragment } from './created-by';

export const BlabItemsOverviewFlimValsFragment = gql`
  fragment BlabItemsOverviewFlimValsFragment on FlimValDto {
    ... on SingleTextFlimValDto {
      ...BlabItemsOverviewSingleTextFlimVal
    }
    ... on MultiTextFlimValDto {
      ...BlabItemsOverviewMultiTextFlimVal
    }

    ... on SingleCategoryFlimValDto {
      ...BlabItemsOverviewSingleCategoryFlimVal
    }
    ... on MultiCategoryFlimValDto {
      ...BlabItemsOverviewMultiCategoryFlimVal
    }

    ... on SingleRelationFlimValDto {
      ...BlabItemsOverviewSingleRelationFlimVal
    }
    ... on MultiRelationFlimValDto {
      ...BlabItemsOverviewMultiRelationFlimVal
    }

    ... on SingleAttachmentFlimValDto {
      ...BlabItemsOverviewSingleAttachmentFlimVal
    }
    ... on MultiAttachmentFlimValDto {
      ...BlabItemsOverviewMultiAttachmentFlimVal
    }

    ... on SingleUserFlimValDto {
      ...BlabItemsOverviewSingleUserFlimVal
    }
    ... on MultiUserFlimValDto {
      ...BlabItemsOverviewMultiUserFlimVal
    }

    ... on SingleDateFlimValDto {
      ...BlabItemsOverviewSingleDateFlimVal
    }
    ... on RangeDateFlimValDto {
      ...BlabItemsOverviewRangeDateFlimVal
    }

    ... on ChecklistFlimValDto {
      ...BlabItemsOverviewChecklistFlimVal
    }
    ... on MultiLinkFlimValDto {
      ...BlabItemsOverviewMultiLinkFlimVal
    }
    ... on NumberFlimValDto {
      ...BlabItemsOverviewNumberFlimVal
    }

    ... on CreatedAtFlimValDto {
      ...BlabItemsOverviewCreatedAtFlimVal
    }
    ... on CreatedByFlimValDto {
      ...BlabItemsOverviewCreatedByFlimVal
    }

    ... on LastModifiedAtFlimValDto {
      ...BlabItemsOverviewLastModifiedAtFlimVal
    }
    ... on LastModifiedByFlimValDto {
      ...BlabItemsOverviewLastModifiedByFlimVal
    }
    ... on StatusFlimValDto {
      ...BlabItemsOverviewStatusFlimVal
    }
    ... on CalculationFlimValDto {
      ...BlabItemsOverviewCalculationFlimVal
    }

    ... on MultiEmailFlimValDto {
      ...BlabItemsOverviewMultiEmailFlimVal
    }

    ... on MultiPhoneFlimValDto {
      ...BlabItemsOverviewMultiPhoneFlimVal
    }

    ... on MultiImageFlimValDto {
      ...BlabItemsOverviewMultiImageFlimVal
    }

    ... on SingleLocationFlimValDto {
      ...BlabItemsOverviewSingleLocationFlimVal
    }

    ... on UniqueIdFlimValDto {
      ...BlabItemsOverviewUniqueIdFlimVal
    }
  }

  ${SingleTextFlimValFragment}
  ${MultiTextFlimValFragment}

  ${SingleCategoryFlimValFragment}
  ${MultiCategoryFlimValFragment}

  ${SingleRelationFlimValFragment}
  ${MultiRelationFlimValFragment}

  ${SingleAttachmentFlimValFragment}
  ${MultiAttachmentFlimValFragment}

  ${SingleUserFlimValFragment}
  ${MultiUserFlimValFragment}

  ${SingleDateFlimValFragment}
  ${RangeDateFlimValFragment}

  ${ChecklistFlimValFragment}

  ${MultiLinkFlimValFragment}

  ${CreatedAtFlimValFragment}
  ${CreatedByFlimValFragment}

  ${LastModifiedAtFlimValFragment}
  ${LastModifiedByFlimValFragment}

  ${NumberFlimValFragment}

  ${StatusFlimValFragment}
  ${CalculationFlimValFragment}

  ${MultiEmailFlimValFragment}

  ${MultiPhoneFlimValFragment}

  ${MultiImageFlimValFragment}

  ${SingleLocationFlimValFragment}

  ${UniqueIdFlimValFragment}
`;
