import { PushNotificationPayloadType } from '../push-notification-payload-type.enum';

export interface BlabItemActivityThreadPushNotificationPayloadValObj {
  type: PushNotificationPayloadType.BLAB_ITEM_ACTIVITY_THREAD_PUSH_NOTIFICATION;
  notificationId: number;
  blabItemId: number;
  blabItemActivityThreadId: number;
  replyId: number;
}

export function isBlabItemActivityThreadPushNotificationPayload(
  payload: unknown | undefined | null,
): payload is BlabItemActivityThreadPushNotificationPayloadValObj {
  return (payload as any)?.type === PushNotificationPayloadType.BLAB_ITEM_ACTIVITY_THREAD_PUSH_NOTIFICATION;
}
