import {
  BlabItemCommentInputAttachmentValobj,
  BlabItemDisplayCommentInputState,
} from '@t5s/mobile-client/value-object/blab-item-display';
import { BlabItemCommentAttachmentDto } from '@t5s/shared/gql';
import { Require } from '@t5s/shared/types';
import { getLocalIdSequence } from '@t5s/shared/util/sequence';
import { trimHtmlString } from '@t5s/shared/validation/common';

const sequence = getLocalIdSequence();
const getLocalAttachmentId = () => sequence.next().value;

export function getBlabItemDisplayCommentInputStateAddAttachments(
  commentInputState: BlabItemDisplayCommentInputState,
  files: File[],
): BlabItemDisplayCommentInputState {
  let { attachments = [] } = commentInputState;

  const newAttachments = files.map((file) => ({ file, id: getLocalAttachmentId() }));

  attachments = [...attachments, ...newAttachments];

  return { ...commentInputState, attachments };
}

export function getBlabItemDisplayCommentInputStateAttachmentUploadException(
  commentInputState: BlabItemDisplayCommentInputState,
  attachmentToRemove: BlabItemCommentInputAttachmentValobj,
): BlabItemDisplayCommentInputState {
  let { attachments = [] } = commentInputState;

  attachments = attachments.filter((att) => att.id !== attachmentToRemove.id);

  return { ...commentInputState, attachments };
}

export function getBlabItemDisplayCommentInputStateRemoveAttachment(
  commentInputState: BlabItemDisplayCommentInputState,
  attachmentId: number,
): BlabItemDisplayCommentInputState {
  let { attachments = [] } = commentInputState;

  attachments = attachments.filter((att) => att.id !== attachmentId);

  return { ...commentInputState, attachments };
}

export function getBlabItemDisplayCommentInputStateAttachmentUploadStart(
  commentInputState: BlabItemDisplayCommentInputState,
  attachment: BlabItemCommentInputAttachmentValobj,
): BlabItemDisplayCommentInputState {
  let { attachments = [] } = commentInputState;

  attachments = attachments.map((att) => {
    if (att.id === attachment.id) {
      return { ...att, progress: 0 };
    } else {
      return att;
    }
  });

  return { ...commentInputState, attachments };
}

export function getBlabItemDisplayCommentInputStateAttachmentUploadSuccess(
  commentInputState: BlabItemDisplayCommentInputState,
  attachment: BlabItemCommentInputAttachmentValobj,
  persistedAttachment: BlabItemCommentAttachmentDto,
): BlabItemDisplayCommentInputState {
  let { attachments = [] } = commentInputState;

  attachments = attachments.map((att) => {
    if (att.id === attachment.id) {
      return { ...att, progress: 100, attachment: persistedAttachment };
    } else {
      return att;
    }
  });

  return { ...commentInputState, attachments };
}

export function attachmentUploadedCompletely(
  attachmentWrapper: BlabItemCommentInputAttachmentValobj,
): attachmentWrapper is Require<BlabItemCommentInputAttachmentValobj, 'attachment'> {
  return attachmentWrapper.attachment !== undefined;
}

export function attachmentReadyForUpload(attachmentWrapper: BlabItemCommentInputAttachmentValobj): boolean {
  return attachmentWrapper.attachment === undefined && attachmentWrapper.progress === undefined;
}

export function attachmentCurrentlyUploading(attachmentWrapper: BlabItemCommentInputAttachmentValobj): boolean {
  return attachmentWrapper.attachment === undefined && attachmentWrapper.progress !== undefined;
}

export function blabItemCommentInputValid({ content, attachments }: { content: string; attachments?: any[] }): boolean {
  const trimmedContentLength = trimHtmlString(content ?? '');
  return !trimmedContentLength && !attachments?.length;
}
