import { ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ComponentStyle, exactHeight, exactWidth, fitContent, rotate, variable } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { IconInterface } from '@t5s/mobile-client/asset';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { isObservable, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

interface IconState {
  icon: IconInterface | string;
  width: number;
  height: number;
  fgColor: string;
  bgColor: string;
  rotation: number;
}

type IconData = string;

export const ICON_FG_COLOR = '--t5s-icon-fg-color';

@Component({
  selector: 't5s-icon',
  template: `<div [class]="iconClass$ | push" [innerHTML]="svgHtml$ | push"> </div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostFitContent],
})
export class IconComponent extends RxComponent<IconState> {
  constructor(private readonly sanitizer: DomSanitizer, readonly elRef: ElementRef<HTMLElement>) {
    super();
    this.set({
      fgColor: ThemeColorVar.darkest,
      bgColor: 'transparent',
      rotation: 0,
    });
  }

  @Input() set icon(icon: IconInterface | IconData | Observable<IconInterface> | Observable<IconData>) {
    this.setProperty('icon', icon);
  }

  @Input() set width(width: number | Observable<number>) {
    this.setProperty('width', width);
  }

  @Input() set height(height: number | Observable<number>) {
    this.setProperty('height', height);
  }

  @Input() set fgColor(fgColor: string | Observable<string>) {
    this.setProperty('fgColor', fgColor);
  }

  @Input() set bgColor(bgColor: string | Observable<string>) {
    this.setProperty('bgColor', bgColor);
  }

  @Input() set rotation(rotation: number | Observable<number>) {
    this.setProperty('rotation', rotation);
  }

  @Input() set size(size: number | Observable<number>) {
    if (isObservable(size)) {
      this.connect(size.pipe(map((size) => ({ width: size, height: size } as any))));
    } else {
      this.set({ width: size, height: size });
    }
  }

  readonly iconData$ = this.select(selectSlice(['icon'])).pipe(
    map(({ icon }) => (icon ? (icon as IconInterface)?.data ?? icon : undefined)),
    filterOnlyPresent(),
    distinctUntilChanged(),
  );

  readonly svgHtml$ = this.iconData$.pipe(map((data) => this.sanitizer.bypassSecurityTrustHtml(data)));

  readonly iconClass$ = this.select(selectSlice(['width', 'height', 'fgColor', 'bgColor', 'rotation'])).pipe(
    map(({ width, height, fgColor, bgColor, rotation }) =>
      tss({
        ...fitContent,
        transform: rotation !== 0 ? rotate(rotation) : undefined,
        svg: {
          overflow: 'visible',
          fill: variable(ICON_FG_COLOR, fgColor),
          rect: {
            fill: bgColor,
          },
          circle: {
            fill: bgColor,
          },
          ...exactHeight(height),
          ...exactWidth(width),
        },
      }),
    ),
  );
}
