import gql from 'graphql-tag';
import {
  ActiveUserDtoNoNestingFragment,
  ActiveUserRolesDtoNoNestingFragment,
  ActiveUserStatusDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { ActiveUserOrganizationDtoFragment } from './active-user-organization.fragment';

export const ActiveUserFragment = gql`
  fragment ActiveUserFragment on ActiveUserDto {
    ...ActiveUserDtoNoNesting
    status {
      ...ActiveUserStatusDtoNoNesting
    }
    profilePicture {
      ...ThumbnailDtoNoNesting
    }
    organization {
      ...ActiveUserOrganizationDtoFragment
    }
    roles {
      ...ActiveUserRolesDtoNoNesting
    }
  }
  ${ActiveUserDtoNoNestingFragment}
  ${ActiveUserStatusDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
  ${ActiveUserOrganizationDtoFragment}
  ${ActiveUserRolesDtoNoNestingFragment}
`;
