import { Directive, OnDestroy } from '@angular/core';
import { RxDirective } from '@t5s/client/util/rx';
import { BottomSheetPosition, IBottomSheetPage } from '@t5s/mobile-client/value-object/bottom-sheet';
import { Subject } from 'rxjs';

export interface BottomSheetPageState {}

@Directive()
export abstract class BottomSheetPage<T extends BottomSheetPageState = BottomSheetPageState>
  extends RxDirective<T>
  implements IBottomSheetPage, OnDestroy
{
  abstract open(): void;
  abstract close(): void;

  readonly positionChange$$ = new Subject<{ position: BottomSheetPosition }>();
  readonly positionChange$ = this.positionChange$$.asObservable();

  ngOnDestroy() {
    super.ngOnDestroy();
    this.positionChange$$.complete();
  }
}
