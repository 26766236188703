import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { LiveSubscriptionContextObservable } from '@t5s/client/provider-token/live-subscription-context';
import { getRouterEventStreams } from '@t5s/client/util/router';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { ClientSideLiveSubscriptionException } from '@t5s/client/value-object/live-subscription-context';
import { PushNotificationActions } from '@t5s/mobile-client/business-logic/push-notification';
import { ActiveUserIdObservable } from '@t5s/mobile-client/provider-token/active-user';
import { LoggedInRefreshTriggerObservable } from '@t5s/mobile-client/provider-token/logged-in-refresh-trigger';
import {
  LoggedInSubscriptionEndTriggerObservable,
  LoggedInSubscriptionStartTriggerObservable,
} from '@t5s/mobile-client/provider-token/logged-in-subscription-trigger';
import {
  ROUTE_FRAGMENT_ACTIVE_USER,
  ROUTE_FRAGMENT_CHAT,
  ROUTE_FRAGMENT_HOME,
  ROUTE_FRAGMENT_LOGGED_IN,
  ROUTE_FRAGMENT_NOTIFICATION,
  ROUTE_FRAGMENT_SEARCH,
} from '@t5s/mobile-client/readonly-constant/logged-in';
import { StorageService } from '@t5s/mobile-client/service/storage';
import { isNavigationBarItem } from '@t5s/mobile-client/util/logged-in-root';
import { GqlUserClientAppService } from '@t5s/shared/gql-services';
import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { LoggedInRootActions } from './logged-in-root.actions';
import { selectActiveOutlet } from './logged-in-root.selectors';
import { getOutletRouteFragment } from './logged-in-root.util';

const LOGGED_IN_ROOT_ACTIVE_TAB_STORAGE_KEY = 'loggedInRootActiveTab';

@Injectable()
export class LoggedInRootEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly router: Router,
    private readonly activeUserId$: ActiveUserIdObservable,
    private readonly userClientAppService: GqlUserClientAppService,
    private readonly storageService: StorageService,
    private readonly refreshTrigger$: LoggedInRefreshTriggerObservable,
    private readonly subscriptionStartTrigger$: LoggedInSubscriptionStartTriggerObservable,
    private readonly subscriptionEndTrigger$: LoggedInSubscriptionEndTriggerObservable,
    private readonly subscriptionContext$: LiveSubscriptionContextObservable,
  ) {}

  readonly navigateToOutlet$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoggedInRootActions.navigateToOutlet),
        switchMap(({ outlet }) =>
          this.router.navigate(
            [ROUTE_FRAGMENT_LOGGED_IN, getOutletRouteFragment(outlet)].filter((fragment) => !!fragment),
          ),
        ),
      ),
    { dispatch: false },
  );

  readonly navigateToActiveOutlet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoggedInRootActions.navigateToOutlet),
      concatLatestFrom(() => this.store$.select(selectActiveOutlet)),
      filter(([{ outlet: targetOutlet }, activeOutlet]) => targetOutlet === activeOutlet),
      map(([{ outlet, longpress }]) => LoggedInRootActions.navigateToActiveOutlet({ outlet, longpress })),
    ),
  );

  readonly navigateToActiveOutletHome$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoggedInRootActions.navigateToActiveOutlet),
      filter(({ outlet }) => outlet === 'home'),
      map(({ longpress }) => LoggedInRootActions.navigateToActiveOutletHome({ longpress })),
    ),
  );

  readonly navigateToActiveOutletSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoggedInRootActions.navigateToActiveOutlet),
      filter(({ outlet }) => outlet === 'search'),
      map(({ longpress }) => LoggedInRootActions.navigateToActiveOutletSearch({ longpress })),
    ),
  );

  readonly navigateToActiveOutletNotifications$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoggedInRootActions.navigateToActiveOutlet),
      filter(({ outlet }) => outlet === 'notifications'),
      map(({ longpress }) => LoggedInRootActions.navigateToActiveOutletNotifications({ longpress })),
    ),
  );

  readonly navigateToActiveOutletActiveUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoggedInRootActions.navigateToActiveOutlet),
      filter(({ outlet }) => outlet === 'active-user'),
      map(({ longpress }) => LoggedInRootActions.navigateToActiveOutletActiveUser({ longpress })),
    ),
  );

  readonly navigateToActiveOutletChat$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoggedInRootActions.navigateToActiveOutlet),
      filter(({ outlet }) => outlet === 'chat'),
      map(({ longpress }) => LoggedInRootActions.navigateToActiveOutletChat({ longpress })),
    ),
  );

  readonly activeOutlet$ = createEffect(() =>
    getRouterEventStreams(this.router).navigationEnd$.pipe(
      filter(({ urlAfterRedirects: url }) => url.startsWith(['', ROUTE_FRAGMENT_LOGGED_IN].join('/'))),
      map(({ urlAfterRedirects: url }) => {
        if (url === ['', ROUTE_FRAGMENT_LOGGED_IN, ROUTE_FRAGMENT_HOME].join('/')) {
          return LoggedInRootActions.setActiveOutlet({ active: 'home' });
        }

        if (url === ['', ROUTE_FRAGMENT_LOGGED_IN, ROUTE_FRAGMENT_SEARCH].join('/')) {
          return LoggedInRootActions.setActiveOutlet({ active: 'search' });
        }

        if (url === ['', ROUTE_FRAGMENT_LOGGED_IN, ROUTE_FRAGMENT_NOTIFICATION].join('/')) {
          return LoggedInRootActions.setActiveOutlet({ active: 'notifications' });
        }

        if (url === ['', ROUTE_FRAGMENT_LOGGED_IN, ROUTE_FRAGMENT_ACTIVE_USER].join('/')) {
          return LoggedInRootActions.setActiveOutlet({ active: 'active-user' });
        }

        if (url === ['', ROUTE_FRAGMENT_LOGGED_IN, ROUTE_FRAGMENT_CHAT].join('/')) {
          return LoggedInRootActions.setActiveOutlet({ active: 'chat' });
        }

        return LoggedInRootActions.setActiveOutlet({ active: 'none' });
      }),
    ),
  );

  readonly triggerLoadSubscribeBubbles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoggedInRootActions.init),
      switchMap(() =>
        combineLatest([this.subscriptionStartTrigger$, this.activeUserId$]).pipe(
          filter(([_, activeUserId]) => activeUserId !== undefined),
          map(LoggedInRootActions.loadSubscribeBubbles),
        ),
      ),
    ),
  );

  readonly loadSubscribeBubbles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoggedInRootActions.loadSubscribeBubbles),
      switchMap(() =>
        this.userClientAppService.getUserClientAppBubbles().pipe(
          map((bubbles) => LoggedInRootActions.setClientAppBubblesSuccess({ bubbles })),
          catchErrorDispatch(LoggedInRootActions.setClientAppBubblesError),
        ),
      ),
    ),
  );

  readonly remotePushReceivedRefreshBubbles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PushNotificationActions.remotePushNotificationReceivedAppInBackground),
      debounceTime(1000),
      map(LoggedInRootActions.refreshBubbles),
    ),
  );

  readonly refreshBubbles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoggedInRootActions.refreshBubbles),
      switchMap(() =>
        this.userClientAppService.getUserClientAppBubbles().pipe(
          map((bubbles) => LoggedInRootActions.setClientAppBubblesSuccess({ bubbles })),
          catchErrorDispatch(LoggedInRootActions.setClientAppBubblesError),
        ),
      ),
    ),
  );

  readonly subscribeClientAppBubbles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoggedInRootActions.loadSubscribeBubbles),
      concatLatestFrom(() => this.subscriptionContext$),
      switchMap(() =>
        this.userClientAppService.subscribeToUserClientAppBubbles().pipe(
          map((bubble) => LoggedInRootActions.setClientAppBubbleSuccess({ bubble })),
          catchErrorDispatch(LoggedInRootActions.subscribeBubblesException),
          takeUntil(this.subscriptionEndTrigger$),
        ),
      ),
    ),
  );

  private readonly REESTABLISHMENT_DEBOUNCE = 3000;

  /** Reestablish bubble subscription in case it errored along the way, after a time debounce. */
  readonly reestablishBubbleSubscriptionOnError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoggedInRootActions.subscribeBubblesException),
      filter(({ error }) => !(error instanceof ClientSideLiveSubscriptionException)),
      debounceTime(this.REESTABLISHMENT_DEBOUNCE),
      concatLatestFrom(() => this.activeUserId$),
      filter(([, activeUserId]) => activeUserId !== undefined),
      map(LoggedInRootActions.loadSubscribeBubbles),
    ),
  );

  readonly initHydrateTab$ = createEffect(() =>
    this.actions$.pipe(ofType(LoggedInRootActions.init), map(LoggedInRootActions.hydrateActiveTab)),
  );

  readonly serializeTab$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoggedInRootActions.hydrateActiveTabSuccess, LoggedInRootActions.hydrateActiveTabNotAvailable),
        switchMap(() => this.store$.select(selectActiveOutlet)),
        distinctUntilChanged(),
        switchMap((url) => this.storageService.set({ key: LOGGED_IN_ROOT_ACTIVE_TAB_STORAGE_KEY, value: url })),
      ),
    { dispatch: false },
  );

  readonly hydrateTab$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoggedInRootActions.hydrateActiveTab),
      switchMap(() =>
        this.storageService.get({ key: LOGGED_IN_ROOT_ACTIVE_TAB_STORAGE_KEY }).pipe(
          map((result) => {
            if (!result || !result.value || result.value === 'none') {
              return LoggedInRootActions.hydrateActiveTabNotAvailable();
            }

            const { value } = result;
            // unexpected value may be returned
            if (!isNavigationBarItem(value)) {
              return LoggedInRootActions.hydrateActiveTabException();
            }

            return LoggedInRootActions.hydrateActiveTabSuccess({ hydratedActive: value });
          }),
        ),
      ),
    ),
  );

  readonly exceptionClearTab$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoggedInRootActions.hydrateActiveTabException),
        switchMap(() => this.storageService.remove({ key: LOGGED_IN_ROOT_ACTIVE_TAB_STORAGE_KEY })),
      ),
    { dispatch: false },
  );

  readonly refreshTriggerRefreshBubbles$ = createEffect(() =>
    this.refreshTrigger$.pipe(map(LoggedInRootActions.refreshBubbles)),
  );

  ngrxOnInitEffects(): Action {
    return LoggedInRootActions.init();
  }
}
