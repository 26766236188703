import { Directive, ElementRef, EventEmitter, Input, Optional, Output } from '@angular/core';
import { hasInteractiveElemenInPath } from '@t5s/client/util/element';
import { RxDirective } from '@t5s/client/util/rx';
import { DefaultLongpressDirective, LongpressDirective } from '@t5s/mobile-client/directive/press';
import { fromTouchmoveEventOutsideThreshold } from '@t5s/mobile-client/util/touch-event';
import { filterOnlyTrue } from '@t5s/shared/util/rxjs';
import { EMPTY, fromEvent, merge, Observable, race, timer } from 'rxjs';
import { delay, filter, first, map, mapTo, share, switchMap, tap } from 'rxjs/operators';

interface DefaultTouchActiveState {
  styleActive: string;
  lasAppliedStyle?: string;
}

function getActiveStyleProp(activeStyle: string) {
  const [name, value] = activeStyle.split(':').map((part) => (part || '').trim());
  return name && value ? { name, value } : undefined;
}

const TIMEOUT_MS = 80;

@Directive({
  selector: '[t5sDefaultTouchActive]',
  exportAs: 't5sDefaultTouchActive',
})
export class DefaultTouchActiveDirective extends RxDirective<DefaultTouchActiveState> {
  constructor(
    private readonly elRef: ElementRef<HTMLElement>,
    @Optional() readonly longpressDirective: LongpressDirective,
    @Optional() readonly defaultLongpressDirective: DefaultLongpressDirective,
  ) {
    super();

    const touchstart$ = fromEvent<TouchEvent>(elRef.nativeElement, 'touchstart', { passive: true }).pipe(
      filter((event) => !hasInteractiveElemenInPath(event, { hostElement: this.elRef.nativeElement })),
      share(),
    );
    const touchend$ = fromEvent<TouchEvent>(elRef.nativeElement, 'touchend', { passive: true }).pipe(share());

    this.hold(
      touchstart$.pipe(
        switchMap((event) =>
          race(
            timer(TIMEOUT_MS).pipe(mapTo('timeout')),
            fromTouchmoveEventOutsideThreshold(elRef.nativeElement, { touches: event.touches }).pipe(
              mapTo('touchmove'),
            ),
            touchend$.pipe(mapTo('touchend')),
          ).pipe(
            first(),
            map((result) => {
              if (result === 'timeout') {
                this.addActiveStyle();
                this.defaultTouchActiveChange.emit({ active: true, event });
                return true;
              }

              if (result === 'touchend') {
                this.animateActiveStyle();
                this.defaultTouchActiveChange.emit({ active: true, event });
              }

              return false;
            }),
            filterOnlyTrue(),
            switchMap(() =>
              merge(
                fromTouchmoveEventOutsideThreshold(elRef.nativeElement, { touches: event.touches }),
                longpressDirective ? longpressDirective.t5sLongpress : EMPTY,
                defaultLongpressDirective ? defaultLongpressDirective.t5sDefaultLongpress : EMPTY,
                touchend$,
              ).pipe(
                first(),
                delay(0),
                tap(() => this.removeActiveStyle()),
              ),
            ),
          ),
        ),
      ),
    );
  }

  @Input('t5sDefaultTouchActive') set styleActive(styleActive: string | Observable<string>) {
    this.setProperty('styleActive', styleActive);
  }

  @Output() defaultTouchActiveChange = new EventEmitter<{ event?: TouchEvent; active: boolean }>();

  private addActiveStyle() {
    const { styleActive } = this.get();
    if (!styleActive) {
      return;
    }

    const prop = getActiveStyleProp(styleActive);
    if (prop) {
      this.elRef.nativeElement.style.setProperty(prop.name, prop.value);
    } else {
      this.elRef.nativeElement.classList.add(styleActive);
    }
    this.set({ lasAppliedStyle: styleActive });
  }

  private animateActiveStyle() {
    this.addActiveStyle();
    setTimeout(() => this.removeActiveStyle(), TIMEOUT_MS);
  }

  private removeActiveStyle() {
    this.defaultTouchActiveChange.emit({ active: false });

    const { lasAppliedStyle } = this.get();
    if (!lasAppliedStyle) {
      return;
    }

    const prop = getActiveStyleProp(lasAppliedStyle);
    if (prop) {
      this.elRef.nativeElement.style.removeProperty(prop.name);
    } else {
      this.elRef.nativeElement.classList.remove(lasAppliedStyle);
    }
    this.set({ lasAppliedStyle: undefined });
  }
}
