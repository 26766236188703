import gql from 'graphql-tag';
import { WorkspaceDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const WorkspaceFragment = gql`
  fragment WorkspaceFragment on WorkspaceDto {
    ...WorkspaceDtoNoNesting
    organization {
      id
      slug
      name
    }
    blabDefs {
      id
      slug
      position
      positionV2
      icon
      blabName
      itemName
      workspaceId
    }
  }
  ${WorkspaceDtoNoNestingFragment}
`;
