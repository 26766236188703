import { DeviceInfo } from '@capacitor/device';
import { UserClientAppPlatform } from '@t5s/shared/gql';

type CapacitorPlatform = DeviceInfo['platform'];

export function userClientAppPlatformFromCapacitorPlatform(
  capPlatform: CapacitorPlatform | undefined,
): UserClientAppPlatform {
  switch (capPlatform) {
    case 'ios': {
      return UserClientAppPlatform.IOS;
    }

    case 'android': {
      return UserClientAppPlatform.ANDROID;
    }

    case 'web': {
      return UserClientAppPlatform.WEB;
    }
  }

  return UserClientAppPlatform.WEB;
}

export function capacitorPlatformFromUserClientAppPlatform(platform: UserClientAppPlatform): CapacitorPlatform {
  switch (platform) {
    case UserClientAppPlatform.IOS: {
      return 'ios';
    }

    case UserClientAppPlatform.ANDROID: {
      return 'android';
    }

    case UserClientAppPlatform.WEB: {
      return 'web';
    }
  }
}
