import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ActiveUserSessionState, activeUserSessionStateKey } from './active-user-session.reducer';

export const selectActiveUserSessionFeatureState =
  createFeatureSelector<ActiveUserSessionState>(activeUserSessionStateKey);

/** This defensive selector implies living with broken windows. This will be reworked when multi-user login is possible (org switcher). */
export const selectActiveUserId = createSelector(
  selectActiveUserSessionFeatureState,
  ({ sessions, activeUser, activeUserId }) => activeUserId ?? activeUser?.id ?? (sessions ?? [])[0]?.userId,
);

export const selectActiveSession = createSelector(selectActiveUserSessionFeatureState, ({ activeUserId, sessions }) => {
  if (activeUserId) {
    return (sessions ?? []).find((session) => session.userId === activeUserId);
  }

  return (sessions ?? [])[0];
});

export const selectActiveUserSessionsLoaded = createSelector(
  selectActiveUserSessionFeatureState,
  ({ sessionsLoaded }) => sessionsLoaded,
);

export const selectActiveUserSessionCookie = createSelector(
  selectActiveUserSessionFeatureState,
  ({ cookie }) => cookie,
);

export const selectActiveUserCookieLoaded = createSelector(
  selectActiveUserSessionFeatureState,
  ({ cookieLoaded }) => cookieLoaded,
);

export const selectActiveUserContextEstablished = createSelector(
  selectActiveUserSessionFeatureState,
  selectActiveUserId,
  ({ sessionsLoaded }, activeUserId) => {
    if (activeUserId) {
      return true;
    }

    if (sessionsLoaded) {
      return false;
    }

    return undefined;
  },
);

export const selectActiveUser = createSelector(selectActiveUserSessionFeatureState, ({ activeUser }) => activeUser);

export const selectActiveUserFeatureAccessLevel = createSelector(
  selectActiveUser,
  (activeUser) => activeUser?.featureAccessLevel,
);
