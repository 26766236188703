import { combineLatest, fromEvent, merge, Observable, of } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  first,
  map,
  mapTo,
  scan,
  shareReplay,
  startWith,
  switchMap,
  switchMapTo,
} from 'rxjs/operators';
import { getEventPath } from './event-path.util';

/** Returns a focus observable stream given an element. */
export function elFocus$(hostElement: Element): Observable<boolean> {
  const isFocused = fromEvent(hostElement, 'focus').pipe(mapTo(true));
  const isBlurred = fromEvent(hostElement, 'blur').pipe(mapTo(false));

  const focus$ = merge(isFocused, isBlurred).pipe(distinctUntilChanged(), startWith(false));
  return focus$;
}

/** Returns a hover observable stream given an element. */
export function elHover$(hostElement: Element): Observable<boolean> {
  const mouseenter$ = fromEvent(hostElement, 'mouseenter').pipe(mapTo(true));
  const mouseleave$ = fromEvent(hostElement, 'mouseleave').pipe(mapTo(false));

  const hover$ = merge(mouseenter$, mouseleave$).pipe(startWith(false));
  return hover$;
}

export function elHoverMs$(element: Element, ms: number): Observable<boolean> {
  const drag$ = elHover$(element).pipe(shareReplay(1));
  const dragFalse$ = drag$.pipe(filter((drag) => !drag));

  return merge(
    drag$.pipe(
      switchMap((value) => of(value).pipe(delay(ms))),
      filter((drag) => !!drag),
    ),
    dragFalse$,
  );
}

/** Returns a active observable stream given an element. */
export function elActive$(hostElement: Element, document: Document): Observable<boolean> {
  const mousedown$ = fromEvent(hostElement, 'mousedown').pipe(mapTo(true));
  const _mouseup$ = fromEvent(document, 'mouseup').pipe(mapTo(false));

  const mouseUpAfterDown$ = mousedown$.pipe(switchMapTo(_mouseup$.pipe(first())));

  const active$ = merge(mousedown$, mouseUpAfterDown$).pipe(startWith(false));
  return active$;
}

/** Returns a focus observable stream given an element. */
export function elFocusUntilMouseup$(hostElement: Element, document: Document): Observable<boolean> {
  const isFocused = fromEvent(hostElement, 'focus').pipe(mapTo(true));
  const isBlurred = fromEvent(hostElement, 'blur').pipe(
    switchMapTo(fromEvent(document, 'mouseup').pipe(first())),
    mapTo(false),
  );

  const focus$ = merge(isFocused, isBlurred).pipe(distinctUntilChanged(), startWith(false));
  return focus$;
}

export function elDrag$(element: Element | Document): Observable<boolean> {
  const dragStart$ = fromEvent(element, 'dragenter');
  const dragEnd$ = fromEvent(element, 'dragleave');
  const drop$ = fromEvent(document, 'drop').pipe(mapTo(false));

  const countDragStart$ = dragStart$.pipe(
    mapTo(1),
    scan((acc, curr) => acc + curr, 0),
  );
  const countDragLeave$ = dragEnd$.pipe(
    mapTo(1),
    scan((acc, curr) => acc + curr, 0),
    startWith(0),
  );

  const drag$ = combineLatest([countDragStart$, countDragLeave$]).pipe(map(([d1, d2]) => d1 - d2 !== 0));

  return merge(drag$, drop$).pipe(distinctUntilChanged(), startWith(false));
}

export function elDragMs$(element: Element | Document, ms: number): Observable<boolean> {
  const drag$ = elDrag$(element).pipe(shareReplay(1));
  const dragFalse$ = drag$.pipe(filter((drag) => !drag));

  return merge(
    drag$.pipe(
      switchMap((value) => of(value).pipe(delay(ms))),
      filter((drag) => !!drag),
    ),
    dragFalse$,
  );
}

export function fromKeyboardEvent(
  element: Element | Document,
  forKey: 'Enter' | 'Esc' | 'Escape' | string,
): Observable<KeyboardEvent> {
  return fromEvent<KeyboardEvent>(element, 'keydown').pipe(filter(({ key }) => !forKey || key === forKey));
}

export function keyboardArrowRightEvent(element: Element | Document): Observable<KeyboardEvent> {
  return fromEvent<KeyboardEvent>(element, 'keydown').pipe(filter(({ keyCode }) => keyCode === 39));
}

export function keyboardArrowLeftEvent(element: Element | Document): Observable<KeyboardEvent> {
  return fromEvent<KeyboardEvent>(element, 'keydown').pipe(filter(({ keyCode }) => keyCode === 37));
}

/** Checks whether element is inputtable, i.e. input, textarea or conteneditable. */
export function isInputtableElement(element: EventTarget | Element | undefined | null): boolean {
  if (!element) {
    return false;
  } else if ((element as HTMLElement).isContentEditable) {
    return true;
  }

  const blackList = ['input', 'textarea'];

  return blackList.some((elmt) => elmt === (element as HTMLElement).tagName?.toLowerCase());
}

export function isElementWithId(element: EventTarget | Element | undefined | null, id: string): boolean {
  return !!element && element instanceof HTMLElement && element.id === id;
}

export function isInteractiveElement(element: EventTarget | Element | undefined | null): boolean {
  if ((element as HTMLElement)?.isContentEditable) {
    return true;
  }

  return ['button', 'a', 'input', 'textarea'].includes((element as Element)?.nodeName?.toLowerCase());
}

export function isAnchorElement(element: EventTarget | Element | undefined | null): element is HTMLLinkElement {
  return ['a'].includes((element as Element)?.nodeName?.toLowerCase());
}

export function isSameNode(
  el1: EventTarget | Element | undefined | null,
  el2: EventTarget | Element | undefined | null,
): boolean {
  return !!el1 && el1 instanceof Element && !!el2 && el2 instanceof Element && el1.isSameNode(el2);
}

export function hasInteractiveElemenInPath(
  event: Event | undefined | null,
  { hostElement }: { hostElement?: Element } = {},
): boolean {
  if (!event) {
    return false;
  }

  return (getEventPath(event) || []).some((target) => {
    if (isSameNode(hostElement, target)) {
      return false;
    }
    return isInteractiveElement(target);
  });
}

export function hasInputtableElemenInPath(
  event: Event | undefined | null,
  { hostElement }: { hostElement?: Element } = {},
): boolean {
  if (!event) {
    return false;
  }

  return (getEventPath(event) || []).some((target) => {
    if (isSameNode(hostElement, target)) {
      return false;
    }
    return isInputtableElement(target);
  });
}

export function activeElementIsInputtable(): boolean {
  return (
    document.activeElement instanceof HTMLElement &&
    (document.activeElement.isContentEditable ||
      document.activeElement.tagName === 'TEXTAREA' ||
      document.activeElement.tagName === 'INPUT')
  );
}

export function hasElementInPath(event: Event | undefined | null, element: Element): boolean {
  if (!event) {
    return false;
  }

  return (getEventPath(event) || []).some((target) => isSameNode(element, target));
}

export function hasElementWithDataAttrInPath(
  dataAttrName: string,
  event: Event | undefined | null,
  { hostElement }: { hostElement?: Element } = {},
): boolean {
  if (!event) {
    return false;
  }

  return (getEventPath(event) || []).some((target) => {
    if (isSameNode(hostElement, target)) {
      return false;
    }

    if (typeof (target as Element)?.getAttribute !== 'function') {
      return false;
    }

    return !!(target as Element)?.getAttribute(dataAttrName);
  });
}

export function getAnchorElementInPath(event: Event | undefined | null): HTMLLinkElement | undefined {
  if (!event) {
    return undefined;
  }

  const target = (getEventPath(event) || []).find((target) => {
    return isAnchorElement(target);
  });

  if (isAnchorElement(target)) {
    return target;
  }
  return undefined;
}

export function hasNodeWithIdInPath(event: Event | undefined | null, id: string): boolean {
  if (!event) {
    return false;
  }
  return (getEventPath(event) || []).some((target) => {
    return target instanceof Element && target.id === id;
  });
}

export function hasNodeWithClassNameInPath(event: Event | undefined | null, className: string): boolean {
  if (!event) {
    return false;
  }
  return (getEventPath(event) || []).some((target) => {
    return target instanceof Element && target.classList.contains(className);
  });
}

export function hasNodeWithAttrInPath(event: Event | undefined | null, attributeName: string): boolean {
  if (!event) {
    return false;
  }
  return (getEventPath(event) || []).some((target) => {
    return target instanceof Element && !!target.getAttribute(attributeName);
  });
}

export function hasNodeWithNameInPath(event: Event | undefined | null, nodeName: string | string[]): boolean {
  if (!event) {
    return false;
  }

  const names = (typeof nodeName === 'string' ? [nodeName] : nodeName).map((nodeName) => nodeName.toLowerCase());

  return (getEventPath(event) || []).some((target) => {
    return target instanceof Element && names.includes(target?.nodeName?.toLowerCase());
  });
}

export function hasParentWithDataAttr(dataAttr: string, el: Node | undefined | null): boolean {
  if (!el) {
    return false;
  }

  while (el) {
    if (el instanceof HTMLElement && el.dataset[dataAttr] !== undefined) {
      return true;
    }

    el = el.parentElement;
  }
  return false;
}
