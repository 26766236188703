import { NgIf, NgIfContext } from '@angular/common';
import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy, untilDestroy } from '@t5s/client/util/component-until-destroy';
import { UserFeatureAccessLevel } from '@t5s/shared/gql';
import { canAccessFeatureAccessLevel } from '@t5s/shared/util/feature-access-level';
import { RuntimeFeatureAccessLevelObservable } from '../runtime-feature-access-level.observable';

@UntilDestroy()
@Directive({
  selector: `[featureAccessLevelBeta]`,
})
export class FeatureAccessLevelBetaDirective extends NgIf {
  @Input() featureAccessLevelBeta: any;
  @Input('featureAccessLevelBetaElse') set featureAccessLevelBetaElse(templateRef: TemplateRef<NgIfContext> | null) {
    super.ngIfElse = templateRef;
  }

  constructor(
    readonly featureAccessLevel$: RuntimeFeatureAccessLevelObservable,
    _templateRef: TemplateRef<any>,
    _viewContainer: ViewContainerRef,
  ) {
    super(_viewContainer, _templateRef);

    featureAccessLevel$.pipe(untilDestroy(this)).subscribe((featureAccessLevel) => {
      const canAccess = canAccessFeatureAccessLevel(featureAccessLevel, UserFeatureAccessLevel.BETA);

      this.ngIf = canAccess;
    });
  }
}
