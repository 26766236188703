import gql from 'graphql-tag';
import {
  ChatGroupJoinActivityDtoNoNestingFragment,
  ChatGroupLeaveActivityDtoNoNestingFragment,
  ChatGroupNameChangeActivityDtoNoNestingFragment,
  ChatGroupThumbnailChangeActivityDtoNoNestingFragment,
  ChatMessageActivityDtoNoNestingFragment,
  ChatVideoCallActivityDtoNoNestingFragment,
  ConversationCreateActivityDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
  UserProfilePreviewDtoNoNestingFragment,
  VideoCallDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { ReactionDtoFragment } from '../core';
import { LinkPreviewDtoFragment } from '../link-preview';
import { ChatMessageAttachmentFragment } from './chat-message-attachment.fragment';

export const ChatActivityFragment = gql`
  fragment ChatActivity on ChatActivityDto {
    reactions {
      ...ReactionDtoFragment
    }
    ... on ChatMessageActivityDto {
      ...ChatMessageActivityDtoNoNesting
      user {
        ...UserProfilePreviewDtoNoNesting
        profilePicture {
          ...ThumbnailDtoNoNesting
        }
      }
      attachments {
        ...ChatMessageAttachmentFragment
      }
      linkPreview {
        ...LinkPreviewDtoFragment
      }
    }
    ... on ChatGroupLeaveActivityDto {
      ...ChatGroupLeaveActivityDtoNoNesting
      user {
        ...UserProfilePreviewDtoNoNesting
        profilePicture {
          ...ThumbnailDtoNoNesting
        }
      }
    }
    ... on ChatGroupJoinActivityDto {
      ...ChatGroupJoinActivityDtoNoNesting
      user {
        ...UserProfilePreviewDtoNoNesting
        profilePicture {
          ...ThumbnailDtoNoNesting
        }
      }
      targetUserId
      targetUser {
        ...UserProfilePreviewDtoNoNesting
        profilePicture {
          ...ThumbnailDtoNoNesting
        }
      }
    }
    ... on ConversationCreateActivityDto {
      ...ConversationCreateActivityDtoNoNesting
    }
    ... on ChatGroupNameChangeActivityDto {
      ...ChatGroupNameChangeActivityDtoNoNesting
      user {
        ...UserProfilePreviewDtoNoNesting
      }
    }
    ... on ChatGroupThumbnailChangeActivityDto {
      ...ChatGroupThumbnailChangeActivityDtoNoNesting
      user {
        ...UserProfilePreviewDtoNoNesting
      }
    }
    ... on ChatVideoCallActivityDto {
      ...ChatVideoCallActivityDtoNoNesting
      user {
        ...UserProfilePreviewDtoNoNesting
        profilePicture {
          ...ThumbnailDtoNoNesting
        }
      }
      videoCall {
        ...VideoCallDtoNoNesting
      }
    }
  }
  ${ChatMessageActivityDtoNoNestingFragment}
  ${ChatGroupLeaveActivityDtoNoNestingFragment}
  ${ChatGroupJoinActivityDtoNoNestingFragment}
  ${ConversationCreateActivityDtoNoNestingFragment}
  ${ReactionDtoFragment}
  ${ThumbnailDtoNoNestingFragment}
  ${ChatGroupNameChangeActivityDtoNoNestingFragment}
  ${ChatGroupThumbnailChangeActivityDtoNoNestingFragment}
  ${UserProfilePreviewDtoNoNestingFragment}
  ${ChatMessageAttachmentFragment}
  ${ChatVideoCallActivityDtoNoNestingFragment}
  ${VideoCallDtoNoNestingFragment}
  ${LinkPreviewDtoFragment}
`;
