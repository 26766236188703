import gql from 'graphql-tag';
import {
  BlabDefDataImportFileColumnDtoNoNestingFragment,
  BlabDefDataImportFileDtoNoNestingFragment,
  BlabDefPreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../flim-def/full-flim-def.fragment';
import { UserProfilePreviewDtoOmitOrgFragment } from '../user-profile';
import { BlabDefDataImportFileCellValuesInfoDtoFragment } from './blab-def-data-import-file-cell-values-info-dto.fragment';

export const BlabDefDataImportToExistingBlabDefConfigPreviewDtoFragment = gql`
  fragment BlabDefDataImportToExistingBlabDefConfigPreviewDto on BlabDefDataImportToExistingBlabDefConfigPreviewDto {
    importFileRows
    importFileColumns {
      ...BlabDefDataImportFileColumnDtoNoNesting
      cellValuesInfoSkipFirstRow {
        ...BlabDefDataImportFileCellValuesInfoDtoFragment
      }

      cellValuesInfoWithFirstRow {
        ...BlabDefDataImportFileCellValuesInfoDtoFragment
      }
    }

    importFile {
      ...BlabDefDataImportFileDtoNoNesting
    }

    lookup {
      users {
        ...UserProfilePreviewDtoOmitOrgFragment
      }

      blabDefs {
        ...BlabDefPreviewDtoNoNesting
      }

      flimDefs {
        ...FullFlimDef
      }
    }

    csvValueSeparator
    characterCode
    importFileParsingError
  }

  ${BlabDefDataImportFileDtoNoNestingFragment}
  ${BlabDefDataImportFileColumnDtoNoNestingFragment}
  ${BlabDefDataImportFileCellValuesInfoDtoFragment}
  ${UserProfilePreviewDtoOmitOrgFragment}
  ${BlabDefPreviewDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
