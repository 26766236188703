import { FlimValUnionType } from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import { AllFlimValsFragment } from '../../fragments/flim-val';
import { subscribeToBlabItemData } from '../../generated/graphql-subscriptions';
import { GqlService } from '../../gql-service';

export class GqlBlabItemLiveUpdateService extends GqlService {
  subscribeToBlabItemData(blabItemId: number): Observable<FlimValUnionType> {
    return this.apollo.defaultSubscribe(subscribeToBlabItemData(blabItemId, [AllFlimValsFragment]));
  }
}
