import gql from 'graphql-tag';
import {
  ThumbnailDtoNoNestingFragment,
  UserProfilePreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const AudioRoomAuthorFragment = gql`
  fragment AudioRoomAuthorFragment on UserProfilePreviewDto {
    ...UserProfilePreviewDtoNoNesting
    profilePicture {
      ...ThumbnailDtoNoNesting
    }
  }
  ${UserProfilePreviewDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
