import gql from 'graphql-tag';
import {
  ActiveUserAutoSaveBlabViewDtoNoNestingFragment,
  BlabViewBoardPropertyDtoNoNestingFragment,
  BlabViewColumnDtoNoNestingFragment,
  BlabViewListPropertyDtoNoNestingFragment,
  BlabViewSortDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { BoardColumnFragment } from '../blab-view/board-column.fragment';
import { FlimFilterFragment } from '../blab-view/flim-filter.fragment';
import { FlimStatDefFragment } from '../blab-view/flim-stat-def.fragment';
import { FullFlimDefFragment } from '../flim-def';

export const ActiveUserAutoSaveBlabViewFullFragment = gql`
  fragment ActiveUserAutoSaveBlabViewFull on ActiveUserAutoSaveBlabViewDto {
    ...ActiveUserAutoSaveBlabViewDtoNoNesting
    originBlabView {
      id
      name
      layout
      isPublic
    }
    boardGroupByFlimDef {
      ...FullFlimDef
    }
    sorting {
      ...BlabViewSortDtoNoNesting
    }
    columns {
      ...BlabViewColumnDtoNoNesting
    }
    listProperties {
      ...BlabViewListPropertyDtoNoNesting
    }
    boardProperties {
      ...BlabViewBoardPropertyDtoNoNesting
    }
    flimFilters {
      ...FlimFilterFragment
    }
    boardColumns {
      ...BoardColumnFragment
    }
    flimStatDefs {
      ...FlimStatDefFragment
    }
  }
  ${ActiveUserAutoSaveBlabViewDtoNoNestingFragment}
  ${BlabViewSortDtoNoNestingFragment}
  ${BlabViewListPropertyDtoNoNestingFragment}
  ${BlabViewBoardPropertyDtoNoNestingFragment}
  ${BlabViewColumnDtoNoNestingFragment}
  ${BoardColumnFragment}
  ${FlimFilterFragment}
  ${FlimStatDefFragment}
  ${FullFlimDefFragment}
`;
