import gql from 'graphql-tag';
import { LinkPreviewDtoNoNestingFragment, ThumbnailDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const LinkPreviewDtoFragment = gql`
  fragment LinkPreviewDtoFragment on LinkPreviewDto {
    ...LinkPreviewDtoNoNesting
    previewImage {
      ...ThumbnailDtoNoNesting
    }
  }
  ${LinkPreviewDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
