import gql from 'graphql-tag';
import {
  WorkflowCenterBlabDefDtoNoNestingFragment,
  WorkflowCenterWorkspaceDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const WorkflowCenterWorkspaceDtoFragment = gql`
  fragment WorkflowCenterWorkspaceDtoFragment on WorkflowCenterWorkspaceDto {
    ...WorkflowCenterWorkspaceDtoNoNesting
    blabDefs {
      ...WorkflowCenterBlabDefDtoNoNesting
    }
  }
  ${WorkflowCenterWorkspaceDtoNoNestingFragment}
  ${WorkflowCenterBlabDefDtoNoNestingFragment}
`;
