import gql from 'graphql-tag';
import { PageInfoNoNestingFragment } from '../../generated/graphql-fragments';
import { ChatActivityFragment } from './chat-activity-dto.fragment';

export const ChatActivityConnectionFragment = gql`
  fragment ChatActivityConnection on ChatActivityDtoConnection {
    pageInfo {
      ...PageInfoNoNesting
    }
    edges {
      cursor
      node {
        ...ChatActivity
      }
    }
  }
  ${PageInfoNoNestingFragment}
  ${ChatActivityFragment}
`;
