/* eslint-disable no-control-regex */
// https://emailregex.com/

declare const isValidEmail: unique symbol;

export type ValidEmail = string & {
  [isValidEmail]: true;
};

export abstract class EmailValidation {
  static readonly maxLength = 200;
  // Pattern that searches for an email address inside a string
  static readonly searchPattern =
    /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
      .toString()
      .slice(1, -1);

  // Pattern that matches a single email address followed by n spaces and a non space character
  static readonly follwedBySpaces = `^${EmailValidation.searchPattern}\\s+[^\\s]$`;

  // Exact matching pattern for entire string
  static readonly pattern = `^${EmailValidation.searchPattern}$`;

  static validateEmail(email: string): email is ValidEmail {
    email = email ?? '';
    const [local, domain] = email.split('@');
    if ((local ?? '').length > 64 || (domain ?? '').length > 255) {
      return false;
    }

    return new RegExp(EmailValidation.pattern).test(email);
  }
}
