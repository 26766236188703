export const doNotDisturbEnClientLang = {
  pausedInfinite: `Notifications paused until reactivation`,
  pausedUntil: `Notifications paused until {{formattedDate}}`,
  pausingInfinite: `Pausing notifications until reactivation`,
  pausingUntil: `Pausing notifications until {{formattedDate}}`,
  placeholder: `Do not disturb for`,
  saveBtn: `Save`,
  title: `Do not disturb`,
  until: {
    INFINITE: `Don't clear`,
    ONE_HOUR: '1 hour',
    THIRTY_MIN: '30 min',
    UNTIL_NEXT_WEEK: 'Until next week',
    UNTIL_TOMORROW: 'Until tomorrow',
  },
} as const;
