import gql from 'graphql-tag';
import { ChecklistFlimValDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { ChecklistFlimValEntryFragment } from './checklist-flim-val-entry.fragment';

export const ChecklistFlimValFragment = gql`
  fragment ChecklistFlimVal on ChecklistFlimValDto {
    ...ChecklistFlimValDtoNoNesting
    checklistEntries {
      ...ChecklistFlimValEntryFragment
    }
  }
  ${ChecklistFlimValDtoNoNestingFragment}
  ${ChecklistFlimValEntryFragment}
`;
