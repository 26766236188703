import { createAction, props } from '@ngrx/store';
import { createErrorAction } from '@t5s/client/util/store';
import { NotificationUnionTypeValObj } from '@t5s/mobile-client/value-object/notification';

const PREFIX = '[mobile-client-notification]';

export const NotificationActions = {
  markPushNotificationsAsRead: createAction(
    `${PREFIX} mark notification push notifications as read to clear app badge`,
  ),

  loadSyncNotifications: createAction(`${PREFIX} load notifications`),

  // Mark as read
  markNotificationAsRead: createAction(
    `${PREFIX} mark notification as read`,
    props<{ notification: { id: number } }>(),
  ),
  markNotificationAsReadSuccess: createAction(
    `${PREFIX} mark notification as read success`,
    props<{ notificationId: number }>(),
  ),
  markNotificationAsReadError: createErrorAction(
    `${PREFIX} mark notification as read error`,
    props<{ notification: NotificationUnionTypeValObj }>(),
  ),

  // Mark as unread
  markNotificationAsUnread: createAction(
    `${PREFIX} mark notification as unread`,
    props<{ notification: NotificationUnionTypeValObj }>(),
  ),
  markNotificationAsUnreadSuccess: createAction(
    `${PREFIX} mark notification as unread success`,
    props<{ notificationId: number }>(),
  ),
  markNotificationAsUnreadError: createErrorAction(
    `${PREFIX} mark notification as unread error`,
    props<{ notification: NotificationUnionTypeValObj }>(),
  ),

  // navigation
  accessBlabItem: createAction(
    `${PREFIX} Access blab item`,
    props<{ blabItemId: number; blabItemActivityId?: number }>(),
  ),
  accessBlabItemActivityThread: createAction(
    `${PREFIX} Access blab item activity thread`,
    props<{ blabItemActivityId: number; activityReplyId: number }>(),
  ),
};
