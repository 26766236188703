import { WorkspaceInvitationDto, WorkspaceUserDto } from '@t5s/shared/gql';
import { isDto } from './dto.type-guard';

export function isWorkspaceUserDto(candidate: any): candidate is WorkspaceUserDto {
  return isDto<WorkspaceUserDto>(candidate, 'WorkspaceUserDto');
}

export function isWorkspaceInvitationDto(candidate: any): candidate is WorkspaceInvitationDto {
  return isDto<WorkspaceInvitationDto>(candidate, 'WorkspaceInvitationDto');
}
