import { createAction, props } from '@ngrx/store';
import { createErrorAction } from '@t5s/client/util/store';
import {
  ChatConversationChatActivityUnionType,
  ChatMessageInputAttachmentValobj,
} from '@t5s/mobile-client/value-object/chat-conversation';
import {
  ChatActivityDto,
  ChatActivityDtoConnection,
  ChatMessageActivityDto,
  ChatMessageAttachmentDto,
  ConversationUnionType,
  PostChatMessageInput,
  SingleConversationSubscriptionUpdate,
  UserProfilePreviewDto,
} from '@t5s/shared/gql';
import { ChatConversationHydrationState } from './chat-conversation.hydration-state';

const PREFIX = '[mobile-client-chat-conversation]';

export const ChatConversationActions = {
  reset: createAction(`${PREFIX} reset`),

  setConversation: createAction(
    `${PREFIX} set conversation via context`,
    props<{
      conversationId: number;
      conversation: ConversationUnionType;
    }>(),
  ),

  preloadUnreadConversations: createAction(
    `${PREFIX} Preload unread conversations for instant opening`,
    props<{ conversationIds: number[] }>(),
  ),
  preloadReadConversations: createAction(
    `${PREFIX} Preload read conversations for instant opening`,
    props<{ conversationIds: number[] }>(),
  ),

  loadConversation: createAction(`${PREFIX} Load conversation`, props<{ conversationId: number }>()),
  loadConversationSuccess: createAction(
    `${PREFIX} Loaded conversation successfully`,
    props<{ conversationId: number; conversation: ConversationUnionType }>(),
  ),
  loadConversationException: createErrorAction(
    `${PREFIX} Loading conversation failed with exception`,
    props<{ conversationId: number }>(),
  ),

  loadConversationChatActivity: createAction(
    `${PREFIX} Load conversation chat activity`,
    props<{ conversationId: number }>(),
  ),
  loadConversationChatActivitySuccess: createAction(
    `${PREFIX} Loaded conversation chat activity successfully`,
    props<{ conversationId: number; connection: ChatActivityDtoConnection }>(),
  ),
  loadConversationChatActivityException: createErrorAction(
    `${PREFIX} Loading conversation chat activity failed with exception`,
    props<{ conversationId: number }>(),
  ),
  loadMoreConversationChatActivity: createAction(
    `${PREFIX} Load more conversation chat activity`,
    props<{ conversationId: number }>(),
  ),
  loadMoreConversationChatActivitySuccess: createAction(
    `${PREFIX} Loaded more conversation chat activity successfully`,
    props<{ conversationId: number; connection: ChatActivityDtoConnection }>(),
  ),

  subscribeToConversation: createAction(`${PREFIX} Subscribe to conversation`, props<{ conversationId: number }>()),
  subscribeToConversationSuccess: createAction(
    `${PREFIX} Subscribed to conversation`,
    props<{ conversationId: number }>(),
  ),
  unsubscribefromConversation: createAction(
    `${PREFIX} Unsubscribe from conversation`,
    props<{ conversationId: number }>(),
  ),
  conversationSubscriptionException: createErrorAction(
    `${PREFIX} conversation subscription errored`,
    props<{ error: any; conversationId: number }>(),
  ),
  conversationUpdate: createAction(
    `${PREFIX} Conversation chat activity update received`,
    props<{ conversationId: number; update: SingleConversationSubscriptionUpdate }>(),
  ),

  markConversationAsRead: createAction(`${PREFIX} Mark conversation as read`, props<{ conversationId: number }>()),
  markConversationAsReadSuccess: createAction(
    `${PREFIX} Marked conversation as read successfully`,
    props<{ conversationId: number }>(),
  ),
  markConversationAsReadException: createErrorAction(
    `${PREFIX} Marking conversation as read failed with exception`,
    props<{ conversationId: number }>(),
  ),

  // State hydration
  hydrateState: createAction(`${PREFIX} Hydrate state`),
  hydrateStateSuccess: createAction(
    `${PREFIX} Hydrate state Success`,
    props<{ state: ChatConversationHydrationState }>(),
  ),
  hydrateStateNotAvailable: createAction(`${PREFIX} Hydration of state not available`),
  hydrateStateException: createAction(`${PREFIX} Hydration of state failed with exception`),

  // new message input and send
  setMessageInputContent: createAction(
    `${PREFIX} set comment input content`,
    props<{ conversationId: number; content: string }>(),
  ),
  sendMessage: createAction(
    `${PREFIX} send message`,
    props<{ conversationId: number; content: string; localId: string; activeUser: UserProfilePreviewDto }>(),
  ),
  sendMessageSuccess: createAction(
    `${PREFIX} send message success`,
    props<{ activity: ChatMessageActivityDto; conversationId: number; localId: string }>(),
  ),
  sendMessageException: createAction(
    `${PREFIX} send message exception (will be retried)`,
    props<{ error: any; conversationId: number; localId: string; sendMessageInput: PostChatMessageInput }>(),
  ),
  sendMessageTerminalException: createAction(
    `${PREFIX} send message terminal exception (will not be retried)`,
    props<{ conversationId: number; localId: string; sendMessageInput: PostChatMessageInput }>(),
  ),
  retrySendMessage: createAction(
    `${PREFIX} retry to send a local message`,
    props<{ conversationId: number; localId: string }>(),
  ),
  discardLocalErroredMessage: createAction(
    `${PREFIX} discard a local errored message`,
    props<{ conversationId: number; localId: string }>(),
  ),

  addAttachmentFiles: createAction(
    `${PREFIX} Add comment file attachments`,
    props<{ conversationId: number; files: File[] }>(),
  ),
  removeAttachment: createAction(
    `${PREFIX} Remove file attachment`,
    props<{ conversationId: number; attachmentId: number }>(),
  ),
  invalidFileSizeFileAttached: createAction(
    `${PREFIX} File with invalid file size had been attached by user`,
    props<{ files: File[] }>(),
  ),
  tooManyFileAttached: createAction(`${PREFIX} Too many files had been attached by user`, props<{ files: File[] }>()),

  uploadFile: createAction(
    `${PREFIX} Upload file attachment`,
    props<{ conversationId: number; attachment: ChatMessageInputAttachmentValobj }>(),
  ),
  uploadFileSuccess: createAction(
    `${PREFIX} Uploaded file attachment successfully`,
    props<{
      conversationId: number;
      attachment: ChatMessageInputAttachmentValobj;
      persistedAttachment: ChatMessageAttachmentDto;
    }>(),
  ),
  uploadFileException: createErrorAction(
    `${PREFIX} Uploading file attachment failed`,
    props<{ conversationId: number; attachment: ChatMessageInputAttachmentValobj }>(),
  ),

  // chat activity reaction
  addChatActivityReaction: createAction(
    `${PREFIX} add chat activity reaction`,
    props<{ emoji: string; activityId: number }>(),
  ),
  addChatActivityReactionSuccess: createAction(
    `${PREFIX} add chat activity reaction success`,
    props<{ emoji: string; activity: ChatActivityDto }>(),
  ),
  addChatActivityReactionException: createErrorAction(`${PREFIX} add chat activity reaction exception`),

  removeChatActivityReaction: createAction(
    `${PREFIX} remove chat activity reaction`,
    props<{ emoji: string; activityId: number }>(),
  ),
  removeChatActivityReactionSuccess: createAction(
    `${PREFIX} remove chat activity reaction success`,
    props<{ emoji: string; activity: ChatActivityDto }>(),
  ),
  removeChatActivityReactionException: createErrorAction(`${PREFIX} remove chat activity reaction exception`),

  // delete chat message
  confirmDeleteChatMessage: createAction(
    `${PREFIX} Confirm delete chat message`,
    props<{ conversationId: number; id: number }>(),
  ),
  deleteChatMessage: createAction(`${PREFIX} Delete chat message`, props<{ conversationId: number; id: number }>()),
  deleteChatMessageSuccess: createAction(
    `${PREFIX} Deleted chat message successfully`,
    props<{ conversationId: number; id: number }>(),
  ),
  deleteChatMessageError: createErrorAction(`${PREFIX} Deleting chat message failed`),

  // message actions
  copyMessageContent: createAction(
    `${PREFIX} Copy message content`,
    props<{ activity: ChatConversationChatActivityUnionType }>(),
  ),

  openUrl: createAction(`${PREFIX} Open URL`, props<{ url: string }>()),
  // Mention user
  clearMentionUserSearchResults: createAction(`${PREFIX} clear mention user search results`),
  searchMentionUser: createAction(`${PREFIX} search mention user`, props<{ searchQuery: string }>()),
  searchMentionUserSuccess: createAction(
    `${PREFIX} search mention user success`,
    props<{ searchQuery: string; userSearchResults: UserProfilePreviewDto[] }>(),
  ),
  searchMentionUserException: createErrorAction(`${PREFIX} search mention user`, props<{ searchQuery: string }>()),
};
