export const verticalScroll = {
  overflowY: 'auto',
} as const;

export const verticalNoScroll = {
  overflowY: 'initial',
  overscrollBehavior: 'none',
} as const;

export const transparentBgScrollbarStyles = {
  '::-webkit-scrollbar-track': {
    border: 'none',
    backgroundColor: 'transparent',
  },
  '::-webkit-scrollbar-corner': {
    backgroundColor: 'transparent',
  },
  '::-webkit-scrollbar-thumb': {
    border: 'none',
  },
} as const;

export const hiddenScrollbarStyles = {
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
} as const;
