import { I18nTranslation } from '@t5s/mobile-client/i18n/common';
import { Language } from '@t5s/shared/gql';

const languages = {
  [Language.DE]: {
    header: 'Reaktionen',
    userList: '{{ firstUsers }} und {{ lastUser }}',
    you: 'Du',
  },
  [Language.EN]: {
    header: 'Reactions',
    userList: '{{ firstUsers }} and {{ lastUser }}',
    you: 'You',
  },
} as const;

export const ReactionDetailsI18n = new I18nTranslation<typeof languages.EN>(languages);
