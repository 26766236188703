import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { DeviceObservable } from '@t5s/mobile-client/provider-token/device';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DeviceActions } from './device.actions';

@Injectable()
export class DeviceEffects {
  constructor(private readonly device$: DeviceObservable) {}

  readonly setDeviceInfo$ = createEffect(() =>
    this.device$.pipe(
      map((info) => DeviceActions.setDeviceInfo({ info })),
      catchError(() => of(DeviceActions.setDeviceInfoException())),
    ),
  );
}
