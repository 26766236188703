export const AUTH_COOKIE_NAME = 'auth';
export const USERID_HEADER_NAME = 'uid';
export const ORGANIZATION_ID_HEADER_NAME = 'X-Tape-Organization-Id';

export const DEV_API_AUTH_API_KEY_HEADER_NAME = 'X-Tape-Auth-Api-Key';

export const SHARE_TOKEN_HEADER_NAME = 'X-Tape-Share-Token';
export const SHARE_TOKEN_QUERY_PARAM = 'auth';

export const REQUEST_INSECURE_COOKIE_HEADER_NAME = 'X-Tape-Request-Insecure-Cookie';
export const INSECURE_COOKIE_HEADER_NAME = 'X-Tape-Insecure-Cookie';

export enum SharelinkEntityPrefix {
  BLAB_ITEM = 'rec',
  BLAB_DEF = 'app',
  WORKSPACE = 'wks',
  ORGANIZATION = 'org',
}
