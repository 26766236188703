import gql from 'graphql-tag';
import {
  OrganizationAuthenticationProviderDtoNoNestingFragment,
  OrganizationAuthenticationProviderRejectionDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const OrganizationAuthenticationProviderResultUnionTypeFragment = gql`
  fragment OrganizationAuthenticationProviderResultUnion on OrganizationAuthenticationProviderResultUnion {
    ... on OrganizationAuthenticationProviderDto {
      ...OrganizationAuthenticationProviderDtoNoNesting
    }

    ... on OrganizationAuthenticationProviderRejectionDto {
      ...OrganizationAuthenticationProviderRejectionDtoNoNesting
    }
  }
  ${OrganizationAuthenticationProviderDtoNoNestingFragment}
  ${OrganizationAuthenticationProviderRejectionDtoNoNestingFragment}
`;
