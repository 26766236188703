export { categories as EmojiCategories } from '@t5s/client/util/emoji-mart';

export const NUM_EMOJIS_PER_ROW = 7;
export const EMOJI_PICKER_PADDING_PX = 8;
export const EMOJI_PICKER_EMOJI_MARGIN = 4;

export const MAX_NUM_FREQUENTLY_USED_EMOJIS = NUM_EMOJIS_PER_ROW * 3;
export const MAX_NUM_PERSISTED_FREQUENTLY_USED_EMOJIS = MAX_NUM_FREQUENTLY_USED_EMOJIS;

export const MAX_NUM_EMOJI_SEARCH_RESULTS = 20;

export const DEFAULT_FREQUENTLY_USED_EMOJI_IDS = [
  '+1',
  'pray',
  'clap',
  '100',
  'joy',
  'smile',
  'thinking_face',
  'eyes',
  'wink',
  'heart',
  'v',
  'ok',
  'white_check_mark',
];
