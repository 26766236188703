import { darken, setAlpha } from '@t5s/client/ui/style/common';
import { color } from '@t5s/shared/ui/color';
import { Theme } from './theme.interface';

export const mobileClientDefaultTheme: Theme = {
  color: {
    lightest: color.white,
    lighter: color.gray100,
    mediumlight: color.gray120,
    light: color.gray200,
    light68: setAlpha(color.gray200, 175),
    dark: color.gray400,
    darker: color.gray900,
    darkest: color.black,
    primary: color.blue,
    primary10: setAlpha(color.blue, 25),
    primary20: setAlpha(color.blue, 50),
    primarydark10: darken(color.blue, 0.1),
    primarydark20: darken(color.blue, 0.2),
    danger: color.red,
    danger10: setAlpha(color.red, 25),
    danger20: setAlpha(color.red, 50),
    success: color.green,
    grayBDC1C9: color.gray500,
    gray9197A3: color.gray700,
  },
};
