import { I18nTranslation } from '@t5s/mobile-client/i18n/common';
import { Language } from '@t5s/shared/gql';

const languages = {
  [Language.DE]: {
    category: {
      activity: 'Aktivitäten',
      flags: 'Flaggen',
      foods: 'Essen & Trinken',
      nature: 'Tiere & Natur',
      objects: 'Objekte',
      people: 'Smileys & Leute',
      places: 'Reisen & Orte',
      symbols: 'Symbole',
      frequent: 'Häufig verwendet',
    },
    search: {
      cancelLabel: 'Abbrechen',
      inputPlaceholder: 'Emoji suchen',
      notFoundPlaceholder: 'Keine Emoji-Ergebnisse gefunden',
    },
  },
  [Language.EN]: {
    category: {
      activity: 'Activity',
      flags: 'Flags',
      foods: 'Food & Drink',
      nature: 'Animals & Nature',
      objects: 'Objects',
      people: 'Smileys & People',
      places: 'Travel & Places',
      symbols: 'Symbols',
      frequent: 'Frequently Used',
    },
    search: {
      cancelLabel: 'Cancel',
      inputPlaceholder: 'Search emoji',
      notFoundPlaceholder: 'No emoji-search results found',
    },
  },
} as const;

export const EmojiPickerI18n = new I18nTranslation<typeof languages.EN>(languages);
