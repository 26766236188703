import gql from 'graphql-tag';
import { ConvertSingleAttachmentFlimToMultiAttachmentFlimResultDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../../flim-def';

export const ConvertSingleAttachmentFlimToMultiAttachmentFlimResultDtoFragment = gql`
  fragment ConvertSingleAttachmentFlimToMultiAttachmentFlimResultDtoFragment on ConvertSingleAttachmentFlimToMultiAttachmentFlimResultDto {
    ...ConvertSingleAttachmentFlimToMultiAttachmentFlimResultDtoNoNesting

    flimDef {
      ...FullFlimDef
    }
  }
  ${ConvertSingleAttachmentFlimToMultiAttachmentFlimResultDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
