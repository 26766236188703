import { Provider } from '@angular/core';
import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';

const apolloWebsocketLinkFactory = (subscriptionClient: SubscriptionClient): WebSocketLink => {
  return new WebSocketLink(subscriptionClient);
};

export const WEBSOCKET_LINK_PROVIDER: Provider = {
  provide: WebSocketLink,
  useFactory: apolloWebsocketLinkFactory,
  deps: [SubscriptionClient],
};
