import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { ActiveUserIdObservable } from '@t5s/mobile-client/provider-token/active-user';
import { StateHydrationService } from '@t5s/mobile-client/service/state-hydration';
import { filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { ChatConversationActions } from './chat-conversation.actions';
import { ChatConversationHydrationState } from './chat-conversation.hydration-state';
import { chatConversationStateKey } from './chat-conversation.reducer';
import { selectChatConversationFeatureStateForPersistance } from './chat-conversation.selectors';

@Injectable()
export class ChatConversationHydrationEffects implements OnInitEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly activeUserId$: ActiveUserIdObservable,
    private readonly stateHydrationService: StateHydrationService<ChatConversationHydrationState>,
  ) {}

  private readonly PERSISTANCE_DEBOUNCE = 2_000;

  readonly hydrationPersistanceKey$ = this.activeUserId$.pipe(
    filterOnlyPresent(),
    map((activeUserId) => `${chatConversationStateKey}_${activeUserId}`),
  );

  readonly persistState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ChatConversationActions.hydrateStateSuccess,
          ChatConversationActions.hydrateStateNotAvailable,
          ChatConversationActions.hydrateStateException,
        ),
        switchMap(() => this.store$.select(selectChatConversationFeatureStateForPersistance)),
        concatLatestFrom(() => this.hydrationPersistanceKey$),
        debounceTime(this.PERSISTANCE_DEBOUNCE),
        switchMap(([state, hydrationPersistanceKey]) =>
          this.stateHydrationService.persistState(hydrationPersistanceKey, state),
        ),
      ),
    { dispatch: false },
  );

  readonly hydrateState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatConversationActions.hydrateState),
      concatLatestFrom(() => this.hydrationPersistanceKey$),
      switchMap(([_, hydrationPersistanceKey]) =>
        this.stateHydrationService.retrieveState(hydrationPersistanceKey).pipe(
          map((state) => {
            if (!state) {
              return ChatConversationActions.hydrateStateNotAvailable();
            }

            return ChatConversationActions.hydrateStateSuccess({ state });
          }),
          catchErrorDispatch(ChatConversationActions.hydrateStateException),
        ),
      ),
    ),
  );

  readonly hydrateStateExceptionClear$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatConversationActions.hydrateStateException),
        concatLatestFrom(() => this.hydrationPersistanceKey$),
        switchMap(([_, hydrationPersistanceKey]) => this.stateHydrationService.clearState(hydrationPersistanceKey)),
      ),
    { dispatch: false },
  );

  ngrxOnInitEffects() {
    return ChatConversationActions.hydrateState();
  }
}
