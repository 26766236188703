import { Injectable } from '@angular/core';
import { Device, DeviceInfo } from '@capacitor/device';
import { Observable, defer, from } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { ApmService } from '@t5s/mobile-client/service/apm';

@Injectable()
export class DeviceService {
  constructor(private readonly apmService: ApmService) {}

  readonly deviceInfo$: Observable<DeviceInfo> = from(this.getInfo()).pipe(shareReplay(1));

  private getInfo(): Observable<DeviceInfo> {
    return defer(() => from(this.getInfoSafe()));
  }

  private async getInfoSafe(): Promise<DeviceInfo> {
    try {
      return Device.getInfo();
    } catch (e) {
      // device info could not be retrieved, ensure error is logged
      this.apmService.handleError(e);
      // ...
    }

    // ... proceed with a dummy to ensure the application is at least able to run
    return {
      isVirtual: false,
      manufacturer: 'unknown',
      model: 'unknown',
      osVersion: 'unknown',
      platform: 'web',
      webViewVersion: 'unknown',
      operatingSystem: 'unknown',
    };
  }
}
