type GroupByResult<T> = { [key: number]: T[] } | { [key: number]: T[] };

export function groupBy<T>(arr: T[], key: keyof T): GroupByResult<T> {
  return arr.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {} as any);
}

export function groupByFn<T>(array: T[], keyFn: (item: T) => string | number | undefined): Record<string, T[]> {
  return array.reduce((acc, item) => {
    const key = (keyFn(item) ?? '') + '';
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {} as Record<string, T[]>);
}
