import { ColorV2 as Color } from '@t5s/shared/ui/color';

interface ColorWindow extends Window {
  tape?: {
    color?: typeof Color;
  };
}

/**
 * Set global color via window object
 * @param color color
 */
export function setGlobalColors(color: typeof Color) {
  const _window = window as ColorWindow;
  if (!_window.tape) {
    _window.tape = {};
  }
  _window.tape.color = color;
}

/**
 * Get global color via window object
 */
export function getGlobalColors(): typeof Color {
  const _window = window as ColorWindow;
  return _window.tape?.color ?? Color;
}

export { Color };
