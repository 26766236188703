import gql from 'graphql-tag';
import {
  ChecklistFlimValDtoNoNestingFragment,
  ChecklistFlimValEntryDtoNoNestingFragment,
} from '../../../../generated/graphql-fragments';

export const ChecklistFlimValFragment = gql`
  fragment BlabItemsOverviewChecklistFlimVal on ChecklistFlimValDto {
    ...ChecklistFlimValDtoNoNesting
    checklistEntries {
      ...ChecklistFlimValEntryDtoNoNesting
    }
  }
  ${ChecklistFlimValDtoNoNestingFragment}
  ${ChecklistFlimValEntryDtoNoNestingFragment}
`;
