import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { selectSlice } from '@rx-angular/state/selections';
import {
  FileAttachmentPreviewActions,
  selectFileAttachemntPreviewLoading,
} from '@t5s/mobile-client/business-logic/file-attachment-preview';
import { Page } from '@t5s/mobile-client/page/common';
import { HardwareBackButtonObservable } from '@t5s/mobile-client/provider-token/hardware-back-button';
import { ScreenOrientationService } from '@t5s/mobile-client/service/screen-orientation';
import { StatusBarService } from '@t5s/mobile-client/service/status-bar';
import { FilesViewerFile } from '@t5s/mobile-client/value-object/files-viewer';
import { Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

interface FileAttachmentPreviewPageState {
  files?: FilesViewerFile[];
  initialActiveFileIndex?: number;
}

@Component({
  selector: 't5s-file-attachment-preview-page',
  template: `<t5s-file-attachment-preview-view
    [isLoading]="isLoading$"
    [files]="files$"
    [initialActiveFileIndex]="initialActiveFileIndex$"
    (closeClick)="closeModal()"
    (openClick)="openClick($event)"
    (showOpenWithClick)="showOpenWithClick($event)"
    (headerHide)="headerHide()"
    (headerShow)="headerShow()"
  ></t5s-file-attachment-preview-view>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileAttachmentPreviewPageComponent extends Page<FileAttachmentPreviewPageState> implements OnDestroy {
  constructor(
    private readonly store$: Store,
    private readonly modalController: ModalController,
    private readonly statusBarService: StatusBarService,
    readonly screenOrientationService: ScreenOrientationService,
    readonly hardwareBackButton$: HardwareBackButtonObservable,
  ) {
    super();

    // Hardware back button
    this.hold(hardwareBackButton$, () => {
      void this.closeModal();
    });
  }

  @Input() set files(files: FilesViewerFile[] | Observable<FilesViewerFile[]>) {
    this.setProperty('files', files);
  }

  @Input() set initialActiveFileIndex(initialActiveFileIndex: number | Observable<number>) {
    this.setProperty('initialActiveFileIndex', initialActiveFileIndex);
  }

  readonly isLoading$ = this.store$.select(selectFileAttachemntPreviewLoading).pipe(debounceTime(100));

  readonly files$ = this.select(
    selectSlice(['files']),
    map(({ files }) => files),
  );

  readonly initialActiveFileIndex$ = this.select(
    selectSlice(['initialActiveFileIndex']),
    map(({ initialActiveFileIndex }) => initialActiveFileIndex),
  );

  async closeModal() {
    await this.modalController.dismiss();
  }

  openClick({ file }: { file: FilesViewerFile }) {
    const { name, downloadUrl, mimetype, size } = file;
    this.store$.dispatch(
      FileAttachmentPreviewActions.openFile({ url: downloadUrl, options: { mimetype, filename: name, size } }),
    );
  }

  showOpenWithClick({ file }: { file: FilesViewerFile }) {
    const { name, downloadUrl, mimetype, size } = file;
    this.store$.dispatch(
      FileAttachmentPreviewActions.showOpenFileWith({ url: downloadUrl, options: { mimetype, filename: name, size } }),
    );
  }

  headerHide() {
    this.statusBarService.hide().subscribe();
  }

  headerShow() {
    this.statusBarService.show().subscribe();
  }

  ionViewWillEnter() {
    super.ionViewWillEnter();
    setTimeout(() => this.statusBarService.setStyleDark().subscribe(), 25);
  }

  ionViewWillLeave() {
    super.ionViewWillLeave();
    this.statusBarService.setStyleLight().subscribe();
    this.statusBarService.show().subscribe();
  }

  ionViewDidLeave() {
    super.ionViewDidLeave();
  }
}
