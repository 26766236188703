import { UserSessionDto } from '@t5s/shared/gql';
import { isDto } from './dto.type-guard';

export function isUserSessionDto(candidate: any): candidate is UserSessionDto {
  return isDto<UserSessionDto>(candidate, 'UserSessionDto');
}

export function isUserSessionDtoOrFail(candidate: any): UserSessionDto {
  const result = isUserSessionDto(candidate);

  if (!result) {
    throw new Error(`Candidate ${candidate} is not of type UserSessionDto`);
  }

  return candidate;
}
