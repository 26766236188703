import { SharedI18n } from '@t5s/shared/value-object/i18n';
import {
  formatDateViaLanguage,
  getDateTimeFormats,
  isApplicableI18n,
  normalizeDate,
  shouldAbbreviateMonth,
} from '../common';

export function formatCalendarDate(i18n: SharedI18n, date?: string | number | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  // provide defaults, as time and date format do not matter here
  const { language } = i18n;
  const _i18n = { language };

  const dateTimeFormats = getDateTimeFormats(i18n);

  const shouldAbbreviate = shouldAbbreviateMonth(i18n, date);
  const dateFormat = shouldAbbreviate ? dateTimeFormats.date.calendar : dateTimeFormats.date.calendarMonthNonAbbr;

  return formatDateViaLanguage(date, dateFormat, _i18n);
}
