import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DeviceMediaActions } from '@t5s/mobile-client/business-logic/device-media';
import { Page } from '@t5s/mobile-client/page/common';
import { DeviceMediaViewComponent } from '@t5s/mobile-client/ui/view/device-media';
import { BottomSheetPosition } from '@t5s/mobile-client/value-object/bottom-sheet';
import { DeviceMediaType } from '@t5s/mobile-client/value-object/device-media';

@Component({
  selector: 't5s-device-media-page',
  template: `<t5s-device-media-view
    (positionChange)="positionChange($event)"
    (deviceMediaClick)="deviceMediaClick($event)"
  ></t5s-device-media-view>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceMediaPageComponent extends Page {
  constructor(private readonly store$: Store, private readonly actions$: Actions) {
    super();

    // Open
    this.hold(actions$.pipe(ofType(DeviceMediaActions.open)), () => {
      this.view?.open();
    });

    // Close
    this.hold(actions$.pipe(ofType(DeviceMediaActions.close)), () => {
      this.view?.close();
    });
  }

  @ViewChild(DeviceMediaViewComponent) view?: DeviceMediaViewComponent;

  positionChange({ position }: { position: BottomSheetPosition }) {
    this.store$.dispatch(DeviceMediaActions.setPosition({ position }));
  }

  deviceMediaClick({ type }: { type: DeviceMediaType }) {
    this.store$.dispatch(DeviceMediaActions.picked({ deviceMedia: type }));
  }
}
