import { NgModule } from '@angular/core';
import {
  ApolloSubscriptionService,
  SUBSCRIPTION_CLIENT_PROVIDER,
  WEBSOCKET_LINK_PROVIDER,
} from './apollo-subscriptions';

@NgModule({
  providers: [WEBSOCKET_LINK_PROVIDER, SUBSCRIPTION_CLIENT_PROVIDER, ApolloSubscriptionService],
})
export class ClientDataAccessApolloSubscriptionModule {}
