import gql from 'graphql-tag';
import { UserCreatedActivityFragment } from './user-created-activity.fragment';
import { UserPostActivityFragment } from './user-post-activity.fragment';

export const UserActivityUnionTypeFragment = gql`
  fragment UserActivityUnionTypeFragment on UserActivityUnionType {
    ... on UserCreatedActivityDto {
      ...UserCreatedActivityFragment
    }
    ... on UserPostActivityDto {
      ...UserPostActivityFragment
    }
  }
  ${UserCreatedActivityFragment}
  ${UserPostActivityFragment}
`;
