import { createFeatureSelector, createSelector } from '@ngrx/store';
import { chatConversationStateKey, preprocessStateForSerialization } from './chat-conversation.reducer';
import { ChatConversationState } from './chat-conversation.state';

const selectChatFeatureState = createFeatureSelector<ChatConversationState>(chatConversationStateKey);

export const selectChatConversationFeatureStateForPersistance = createSelector(
  selectChatFeatureState,
  preprocessStateForSerialization,
);

export const selectChatConversationState = createSelector(selectChatFeatureState, (state) => state);

export const selectChatConversationStateDict = createSelector(selectChatFeatureState, (state) => state.entities);

export const selectChatConversationUserSearchResults = createSelector(
  selectChatConversationState,
  (state) => state.userSearchResults,
);
