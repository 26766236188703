import { differenceInDays, isToday, isYesterday } from '@t5s/shared/util/date';
import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { DatetimeI18n } from '../../../datetime.i18n';
import { formatDateViaLanguage, formatTime, getDateTimeFormats, isApplicableI18n, normalizeDate } from '../common';

export function formatChangelogDateTime(
  i18n: SharedI18n,
  date?: string | number | Date,
  opts: { lowerCase?: boolean } = {},
): string | undefined {
  let formattedResult = _formatChangelogDateTime(i18n, date);

  // lowerCase if applicable
  if (formattedResult && opts.lowerCase) {
    formattedResult = lowerCaseFirstLetter(formattedResult);
  }

  return formattedResult;
}

function _formatChangelogDateTime(i18n: SharedI18n, date?: string | number | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  const dateTimeFormats = getDateTimeFormats(i18n);
  const timeFormat = dateTimeFormats.time.short;

  const formattedTime = formatTime(date, timeFormat, i18n);

  const now = new Date(Date.now());

  // (1) date is today
  if (isToday(date)) {
    return DatetimeI18n.translate(i18n, { key: DatetimeI18n.key.todayAt, params: { time: formattedTime } });
  }

  // (2) date is yesterday
  if (isYesterday(date)) {
    return DatetimeI18n.translate(i18n, { key: DatetimeI18n.key.yesterdayAt, params: { time: formattedTime } });
  }

  // (3) date is within the last 7 days

  if (differenceInDays(now, date) <= 7) {
    const weekday = formatDateViaLanguage(date, 'EEEE', i18n);
    return DatetimeI18n.translate(i18n, {
      key: DatetimeI18n.key.lastWeekdayAt,
      params: { time: formattedTime, weekday },
    });
  }

  return formatDateViaLanguage(date, dateTimeFormats.date.short, i18n);
}

function lowerCaseFirstLetter(string: string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}
