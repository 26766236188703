import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  border,
  flexCenterVertical,
  flexRow,
  focus,
  focusWithin,
  fullWidth,
  getFontStyle,
  padding,
  placeholder,
  px,
} from '@t5s/client/ui/style/common';
import { UntilDestroy, untilDestroy } from '@t5s/client/util/component-until-destroy';
import { tss } from '@t5s/client/util/tss';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { font, ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

interface PrefixedInputState {
  fgColor: string;
  placeholder: string;
  value: string;
  prefix: string;
  autofocus: boolean;
}

@UntilDestroy()
@Component({
  selector: 't5s-prefixed-input',
  template: `
    <div [class]="containerClass$ | push">
      <!-- Prefix -->
      <div [class]="prefixContainerClass"> {{ prefix$ | push }} </div>

      <!-- Input -->
      <input
        #input
        [class]="inputClass$ | push"
        [type]="'text'"
        [placeholder]="placeholder$ | push"
        [spellcheck]="false"
        autocomplete="off"
        autocorrect="off"
        [ngModel]="value$ | push"
        (input)="emitValueChange($event)"
        (keydown.enter)="emitEnterPressed($event)"
      />
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrefixedInputComponent extends RxComponent<PrefixedInputState> implements OnInit, AfterViewInit {
  readonly placeholder$ = this.select(selectSlice(['placeholder'])).pipe(map(({ placeholder }) => placeholder));
  readonly value$ = this.select(selectSlice(['value'])).pipe(map(({ value }) => value));
  readonly prefix$ = this.select(selectSlice(['prefix'])).pipe(map(({ prefix }) => prefix));
  readonly autofocus$ = this.select(selectSlice(['autofocus'])).pipe(map(({ autofocus }) => autofocus));

  readonly input$$ = new Subject<HTMLInputElement>();

  constructor() {
    super();
    this.set({
      fgColor: ThemeColorVar.primary,
      placeholder: '',
      value: '',
      prefix: '',
      autofocus: false,
    });

    combineLatest([this.input$$.asObservable(), this.autofocus$.pipe(filter((autofocus) => !!autofocus))])
      .pipe(first(), untilDestroy(this))
      .subscribe(([input]) => {
        input.focus();
      });
  }

  @ViewChild('input') inputRef?: ElementRef<HTMLInputElement>;

  @Input() set fgColor(fgColor: string | Observable<string>) {
    this.setProperty('fgColor', fgColor);
  }

  @Input() set placeholder(placeholder: string | Observable<string>) {
    this.setProperty('placeholder', placeholder);
  }

  @Input() set value(value: string | Observable<string>) {
    this.setProperty('value', value);
  }

  @Input() set prefix(prefix: string | Observable<string>) {
    this.setProperty('prefix', prefix);
  }

  @Input() set autofocus(autofocus: boolean | Observable<boolean>) {
    this.setProperty('autofocus', autofocus);
  }

  @Output() valueChange = new EventEmitter<{ value: string }>();
  @Output() enterPressed = new EventEmitter<{ value: string }>();

  readonly inputClass$ = this.state$.pipe(
    map(() =>
      tss({
        flex: 1,
        minWidth: px(0),
        padding: padding(12, 0),
        ...getFontStyle(font.regular16px),
        ...placeholder({
          color: ThemeColorVar.gray9197A3,
        }),
        ...focus({
          ...placeholder({
            color: ThemeColorVar.grayBDC1C9,
          }),
        }),
      }),
    ),
  );

  readonly containerClass$ = this.select(selectSlice(['fgColor'])).pipe(
    map(({ fgColor }) =>
      tss({
        ...flexRow,
        ...fullWidth,
        ...flexCenterVertical,
        borderBottom: border(1.05, 'solid', ThemeColorVar.dark), // 1.05 to fix android native issues
        overflow: 'hidden',
        ...getFontStyle(font.regular16px),
        ...focusWithin({
          borderColor: fgColor,
        }),
      }),
    ),
  );

  readonly prefixContainerClass = tss({
    flex: 0,
    padding: padding(12, 0),
    marginRight: px(0.5),
  });

  focus() {
    this.inputRef?.nativeElement.focus();
  }

  blur() {
    this.inputRef?.nativeElement.blur();
  }

  emitValueChange(event: Event) {
    const target = event.target as HTMLInputElement | undefined;
    this.valueChange.emit({ value: target?.value ?? '' });
  }

  emitEnterPressed(event: Event) {
    const target = event.target as HTMLInputElement | undefined;
    this.enterPressed.emit({ value: target?.value ?? '' });
  }

  ngOnInit() {
    if (this.inputRef?.nativeElement) {
      this.input$$.next(this.inputRef.nativeElement);
    }
  }

  ngAfterViewInit() {
    if (this.inputRef?.nativeElement) {
      this.input$$.next(this.inputRef.nativeElement);
    }
  }
}
