import gql from 'graphql-tag';
import { FocusEntryUnionTypeFragment } from './focus-entry-union-type.fragment';

export const FocusEntryConnectionFragment = gql`
  fragment FocusEntryConnectionFragment on FocusEntryConnection {
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...FocusEntryUnionTypeFragment
      }
    }
  }
  ${FocusEntryUnionTypeFragment}
`;
