import { themes } from '../themes';
import { px } from '../util';

/**
 * Global scrollbar behavior
 *
 * Prevent scrollbars by default so a developer has to enable them manually.
 */
const SCROLL_BG_COLOR = themes.default.color.grey6;
const SCROLL_BORDER_COLOR = themes.default.color.grey6;
const SCROLL_FG_COLOR = themes.default.color.grey7;
const SCROLL_HOVER_COLOR = themes.default.color.grey8;
const SCROLL_ACTIVE_COLOR = themes.default.color.grey8;
const SCROLLBAR_THICKNESS = px(10);

export const scrollbarStyles = `
  /* Global scrollbar styling */
  *::-webkit-scrollbar {
    width: ${SCROLLBAR_THICKNESS};
    height: ${SCROLLBAR_THICKNESS};
  }
  *::-webkit-scrollbar-track {
    background: ${SCROLL_BG_COLOR};
  }
  *::-webkit-scrollbar-track:vertical {
    border-left: 1px solid ${SCROLL_BORDER_COLOR};
  }
  *::-webkit-scrollbar-track:horizontal {
    border-top: 1px solid ${SCROLL_BORDER_COLOR};
  }
  *::-webkit-scrollbar-corner {
    background-color: ${SCROLL_BG_COLOR};
  }
  *::-webkit-scrollbar-thumb {
    background-color: ${SCROLL_FG_COLOR};
  }
  *::-webkit-scrollbar-thumb:hover {
    background-color: ${SCROLL_HOVER_COLOR};
  }
  *::-webkit-scrollbar-thumb:active {
    background-color: ${SCROLL_ACTIVE_COLOR};
  }
`;

export const scroll = `
  html, body {
    overflow: hidden;
    display: block;
    height: 100%;
    width: 100%;
  }

  html {
    overscroll-behavior: none;
  }

  /* This class can be used to hide the scrollbar*/
  .hide-scrollbar::-webkit-scrollbar {
    display: none;
  }

  ${scrollbarStyles}
`;
