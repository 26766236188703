import { I18nTranslation } from '@t5s/mobile-client/i18n/common';
import { Language } from '@t5s/shared/gql';

const languages = {
  [Language.DE]: {
    authorSubtitle: `Hinzugefügt von {{authorName}} · {{timestamp}}`,
    authorInboundEmailSubtitle: `Hinzugefügt von E-Mail · {{timestamp}}`,
    authorAutomationSubtitle: `Hinzugefügt von Automatisierung · {{timestamp}}`,
    openButtonLabel: `Datei anzeigen`,
  },
  [Language.EN]: {
    authorSubtitle: `Added by {{authorName}} · {{timestamp}}`,
    authorInboundEmailSubtitle: `Added by Email · {{timestamp}}`,
    authorAutomationSubtitle: `Added by Automation · {{timestamp}}`,
    openButtonLabel: `Show file`,
  },
} as const;

export const FileAttachmentPreviewI18n = new I18nTranslation<typeof languages.EN>(languages);
