import gql from 'graphql-tag';
import {
  BlabDefDataImportFileColumnDtoNoNestingFragment,
  BlabDefDataImportFileDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { UserProfilePreviewDtoOmitOrgFragment } from '../user-profile';
import { BlabDefDataImportFileCellValuesInfoDtoFragment } from './blab-def-data-import-file-cell-values-info-dto.fragment';

export const BlabDefDataImportToNewBlabDefConfigPreviewDtoFragment = gql`
  fragment BlabDefDataImportToNewBlabDefConfigPreviewDto on BlabDefDataImportToNewBlabDefConfigPreviewDto {
    importFileRows
    importFileColumns {
      ...BlabDefDataImportFileColumnDtoNoNesting
      cellValuesInfoSkipFirstRow {
        ...BlabDefDataImportFileCellValuesInfoDtoFragment
      }

      cellValuesInfoWithFirstRow {
        ...BlabDefDataImportFileCellValuesInfoDtoFragment
      }
    }

    importFile {
      ...BlabDefDataImportFileDtoNoNesting
    }

    lookup {
      users {
        ...UserProfilePreviewDtoOmitOrgFragment
      }
    }

    csvValueSeparator
    characterCode
    importFileParsingError
  }

  ${BlabDefDataImportFileDtoNoNestingFragment}

  ${BlabDefDataImportFileColumnDtoNoNestingFragment}
  ${BlabDefDataImportFileCellValuesInfoDtoFragment}
  ${UserProfilePreviewDtoOmitOrgFragment}
`;
