import gql from 'graphql-tag';
import { WorkspaceMembershipUnionTypeFragment } from './workspace-membership-union-type.fragment';

export const WorkspaceMembershipConnectionFragment = gql`
  fragment WorkspaceMembershipConnectionFragment on WorkspaceMembershipConnection {
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...WorkspaceMembershipUnionTypeFragment
      }
    }
  }
  ${WorkspaceMembershipUnionTypeFragment}
`;
