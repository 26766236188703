import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { htmlToText } from '@t5s/client/util/html-to-text';
import { BlabItemActivityThreadI18n } from '@t5s/mobile-client/i18n/blab-item-activity-thread';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
import { ClipboardService } from '@t5s/mobile-client/service/clipboard';
import { DialogService } from '@t5s/mobile-client/service/dialog';
import { GqlBlabItemCommentService } from '@t5s/shared/gql-services';
import { filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { BlabItemActivityThreadActions } from './blab-item-activity-thread.actions';

@Injectable()
export class BlabItemActivityThreadHostCommentEffects {
  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly blabItemCommentService: GqlBlabItemCommentService,
    private readonly clipboardService: ClipboardService,
    private readonly i18n$: I18nObjectObservable,
    private readonly dialogService: DialogService,
  ) {}

  readonly copyCommentContent$ = createEffect(
    () =>
      this.actions$.pipe(ofType(BlabItemActivityThreadActions.copyCommentContent)).pipe(
        map(({ activity }) => {
          return htmlToText(activity.comment?.content ?? '');
        }),
        filterOnlyPresent(),
        switchMap((textContent) => this.clipboardService.write({ string: textContent })),
      ),
    { dispatch: false },
  );

  readonly confirmDeleteBlabItemComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.confirmDeleteBlabItemComment),
      concatLatestFrom(() => this.i18n$),
      switchMap(([{ blabItemId, activityId }, { i18n }]) => {
        const message = BlabItemActivityThreadI18n.translate(
          i18n,
          BlabItemActivityThreadI18n.key.confirmDeleteBlabItemCommentDialog.message,
        );
        const title = BlabItemActivityThreadI18n.translate(
          i18n,
          BlabItemActivityThreadI18n.key.confirmDeleteBlabItemCommentDialog.title,
        );
        const cancelButtonTitle = BlabItemActivityThreadI18n.translate(
          i18n,
          BlabItemActivityThreadI18n.key.confirmDeleteBlabItemCommentDialog.cancelButtonTitle,
        );
        const okButtonTitle = BlabItemActivityThreadI18n.translate(
          i18n,
          BlabItemActivityThreadI18n.key.confirmDeleteBlabItemCommentDialog.okButtonTitle,
        );

        return this.dialogService.confirm({ message, title, cancelButtonTitle, okButtonTitle }).pipe(
          filter(({ value }) => value),
          map(() => BlabItemActivityThreadActions.deleteBlabItemComment({ blabItemId, activityId })),
        );
      }),
    ),
  );

  readonly deleteBlabItemComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.deleteBlabItemComment),
      switchMap(({ activityId, blabItemId }) =>
        this.blabItemCommentService
          .deleteBlabItemComment(activityId)
          .pipe(map(() => BlabItemActivityThreadActions.deleteBlabItemCommentSuccess({ activityId, blabItemId }))),
      ),
    ),
  );

  readonly deleteBlabItemCommentUnsubscribeFromActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.deleteBlabItemComment),
      map(BlabItemActivityThreadActions.unsubscribeFromBlabItemActivity),
    ),
  );

  readonly deleteBlabItemCommentUnsubscribeFromThread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.deleteBlabItemComment),
      map(({ activityId }) =>
        BlabItemActivityThreadActions.unsubscribeFromBlabItemActivityThread({ blabItemActivityId: activityId }),
      ),
    ),
  );
}
