import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { boxShadow, flex1Horizontal, getFontStyle, px, textTruncate } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent } from '@t5s/mobile-client/ui/component/common';
import { font, FontSet, ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface SingleLineLinkState {
  font: FontSet;
  fgColor?: string;
  lineHeight?: number;
}

@Component({
  selector: 't5s-single-line-link',
  template: `
    <a
      [class]="linkClass$ | push"
      [t5sTouchActive]="linkClassActive"
      (t5sPressDisableLongpress)="linkClick.emit({ event: $event })"
    >
      <ng-content></ng-content>
    </a>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [':host { display: flex }'],
})
export class SingleLineLinkComponent extends RxComponent<SingleLineLinkState> {
  constructor() {
    super();
    this.set({
      font: font.regular16px,
      fgColor: ThemeColorVar.primary,
    });
  }

  @Input() set font(font: FontSet | Observable<FontSet>) {
    this.setProperty('font', font);
  }

  @Input() set lineHeight(lineHeight: number | Observable<number>) {
    this.setProperty('lineHeight', lineHeight);
  }

  @Input() set fgColor(fgColor: string) {
    this.setProperty('fgColor', fgColor);
  }

  @Output() linkClick = new EventEmitter<{ event: Event }>();

  readonly linkClass$ = this.state$.pipe(
    map(({ fgColor, font, lineHeight }) =>
      tss({
        ...getFontStyle({
          ...font,
          lineHeight: lineHeight ?? font.lineHeight,
        }),
        color: fgColor,
        cursor: 'pointer',
        ...textTruncate,
        ...flex1Horizontal,
      }),
    ),
  );

  readonly linkClassActive = tss({
    backgroundColor: ThemeColorVar.light,
    boxShadow: boxShadow(2, ThemeColorVar.light),
    borderRadius: px(2),
  });
}
