import { FirstWeekday } from '@t5s/shared/gql';
import { getDay, getWeekOfMonth as _getWeekOfMonth } from 'date-fns';

export function getDayInWeek(date: Date, firstWeekday: FirstWeekday = FirstWeekday.SUNDAY): number {
  const day = getDay(date); // returns day based on sunday as first weekday

  switch (firstWeekday) {
    case FirstWeekday.SATURDAY: {
      return (day + 1) % 7;
    }
    case FirstWeekday.SUNDAY: {
      return day;
    }
    case FirstWeekday.MONDAY: {
      return (day + 6) % 7;
    }
  }
}

export function getWeekOfMonth(date: Date, firstWeekday: FirstWeekday = FirstWeekday.SUNDAY): number {
  let weekStartsOn: 0 | 1 | 6;

  switch (firstWeekday) {
    case FirstWeekday.SATURDAY: {
      weekStartsOn = 6;
      break;
    }
    case FirstWeekday.SUNDAY: {
      weekStartsOn = 0;
      break;
    }
    case FirstWeekday.MONDAY: {
      weekStartsOn = 1;
    }
  }
  return _getWeekOfMonth(date, { weekStartsOn }) - 1;
}
