import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ComponentStyle, exactSize, flexCenter, rgba, rounded } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { IconInterface } from '@t5s/mobile-client/asset';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface FilledHeaderIconButtonState {
  icon?: IconInterface | string;
  iconSize: number;
  btnSize: number;
  fgColor: string;
  bgColor: string;
}

@Component({
  selector: 't5s-filled-header-icon-button',
  template: `
    <button [class]="btnClass$ | push" [t5sTouchActive]="'opacity: 0.9'" (t5sPressDisableLongpress)="btnClick.emit()">
      <t5s-icon [icon]="icon$" [size]="iconSize$" [fgColor]="fgColor$"></t5s-icon>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostFitContent],
})
export class FilledHeaderIconButtonComponent extends RxComponent<FilledHeaderIconButtonState> {
  constructor() {
    super();
    this.set({
      bgColor: rgba(0, 0, 0, 0.5),
      fgColor: ThemeColorVar.lightest,
      iconSize: 20,
      btnSize: 32,
    });
  }

  @Input() set icon(icon: IconInterface | string | Observable<IconInterface | string>) {
    this.setProperty('icon', icon);
  }

  @Input() set iconSize(iconSize: number | Observable<number>) {
    this.setProperty('iconSize', iconSize);
  }

  @Input() set bgColor(bgColor: string | Observable<string>) {
    this.setProperty('bgColor', bgColor);
  }

  @Input() set fgColor(fgColor: string | Observable<string>) {
    this.setProperty('fgColor', fgColor);
  }

  @Output() btnClick = new EventEmitter<never>();

  readonly icon$ = this.select(
    selectSlice(['icon']),
    map(({ icon }) => icon),
  );

  readonly iconSize$ = this.select(
    selectSlice(['iconSize']),
    map(({ iconSize }) => iconSize),
  );

  readonly fgColor$ = this.select(
    selectSlice(['fgColor']),
    map(({ fgColor }) => fgColor),
  );

  readonly btnClass$ = this.select(
    selectSlice(['btnSize', 'bgColor']),
    map(({ btnSize, bgColor }) =>
      tss({
        ...rounded,
        ...flexCenter,
        backgroundColor: bgColor,
        ...exactSize(btnSize),
      }),
    ),
  );
}
