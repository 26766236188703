/** Sorts items ascending */
export const idAscSortComparer = (a1: { id: number }, a2: { id: number }) => a1.id - a2.id;

/** Sorts items descending */
export const idDescSortComparer = (a1: { id: number }, a2: { id: number }) => a2.id - a1.id;

export type IdSortComparer = typeof idAscSortComparer;

/** Sorts items ascending */
export const edgeIdAscSortComparer = (a1: { node: { id: number } }, a2: { node: { id: number } }) =>
  a1.node.id - a2.node.id;

/** Sorts items descending */
export const edgeIdDescSortComparer = (a1: { node: { id: number } }, a2: { node: { id: number } }) =>
  a2.node.id - a1.node.id;
