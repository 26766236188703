import { CreateMultiImageFlimValInput, MultiImageFlimValDto, UpsertMultiImageFlimValInput } from '@t5s/shared/gql';

export const getMultiImageUpsertInput = ({
  blabItemId,
  flimDefId,
  imageReferences,
}: MultiImageFlimValDto): UpsertMultiImageFlimValInput => ({
  blabItemId,
  flimDefId,
  imageReferences: (imageReferences || []).map(({ image, position }) => ({
    imageId: image.id,
    position,
  })),
});
export const getMultiImageCreateInputFromDto = ({
  flimDefId,
  imageReferences,
}: MultiImageFlimValDto): CreateMultiImageFlimValInput => ({
  flimDefId,
  imageReferences: (imageReferences || []).map(({ image, position }) => ({
    imageId: image.id,
    position,
  })),
});
