import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { attachmentFileValidForUpload } from '@t5s/client/util/file';
import { htmlToText } from '@t5s/client/util/html-to-text';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { ChatConversationI18n } from '@t5s/mobile-client/i18n/chat-conversation';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
import { ClipboardService } from '@t5s/mobile-client/service/clipboard';
import { DialogService } from '@t5s/mobile-client/service/dialog';
import { ToastService } from '@t5s/mobile-client/service/toast';
import { ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { attachmentReadyForUpload } from '@t5s/mobile-client/util/chat-conversation';
import { isChatConversationLocalMessageActivity } from '@t5s/mobile-client/value-object/chat-conversation';
import { ChatMessageActivityDto, PostChatMessageInput } from '@t5s/shared/gql';
import { GqlChatActivityService } from '@t5s/shared/gql-services';
import { delayedRetry } from '@t5s/shared/util/rxjs';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { ChatConversationActions } from './chat-conversation.actions';
import { selectChatConversationStateDict } from './chat-conversation.selectors';

const NUM_ALLOWED_ATTACHMENTS = 4;

@Injectable()
export class ChatConversationMessageEffects {
  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly chatActivityService: GqlChatActivityService,
    private readonly toastService: ToastService,
    private readonly clipboardService: ClipboardService,
    private readonly i18n$: I18nObjectObservable,
    private readonly dialogService: DialogService,
  ) {}

  readonly sendMessage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatConversationActions.sendMessage, ChatConversationActions.retrySendMessage),
      concatLatestFrom(() => this.store$.select(selectChatConversationStateDict)),
      mergeMap(([{ conversationId, localId }, dict]) => {
        const blabItemState = dict[conversationId];

        if (!blabItemState) {
          return [];
        }

        const { localMessages } = blabItemState;

        const localMessageToSend = localMessages.find((localMessage) => localMessage.localId === localId);

        if (!localMessageToSend) {
          return [];
        }

        const { content, attachments = [] } = localMessageToSend;

        const attachmentIds = attachments.map((att) => att.id);

        const sendMessageInput: PostChatMessageInput = {
          conversationId,
          content: content ?? '',
          attachmentIds,
          clientSideId: localId,
        };

        return this.chatActivityService.postChatMessage(sendMessageInput).pipe(
          map((activity) => ChatConversationActions.sendMessageSuccess({ conversationId, activity, localId })),
          catchError((error) =>
            of(ChatConversationActions.sendMessageException({ conversationId, localId, error, sendMessageInput })),
          ),
        );
      }),
    ),
  );

  readonly sendMessageRetry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatConversationActions.sendMessageException),
      mergeMap(({ conversationId, localId, sendMessageInput }) => {
        const sendMessageRequest$ = this.chatActivityService.postChatMessage(sendMessageInput);

        // retry to send message every sec for 15 sec
        const retriedSending$ = sendMessageRequest$.pipe(delayedRetry(1000, 15));

        // if this observable still errors, the message will be considered terminally failed
        return retriedSending$.pipe(
          map((activity) => ChatConversationActions.sendMessageSuccess({ conversationId, activity, localId })),
          catchError((error) =>
            of(
              ChatConversationActions.sendMessageTerminalException({
                conversationId,
                localId,
                sendMessageInput,
              }),
            ),
          ),
        );
      }),
    ),
  );

  readonly fileAddedTriggerUploads$ = createEffect(() =>
    this.actions$.pipe(ofType(ChatConversationActions.addAttachmentFiles)).pipe(
      mergeMap(({ conversationId }) =>
        this.store$.select(selectChatConversationStateDict).pipe(
          switchMap((dict) => {
            const chatConversationState = dict[conversationId];
            if (!chatConversationState) {
              return [];
            }

            const { messageInput = {} } = chatConversationState;
            const attachmentsReadyForUpload = messageInput.attachments?.filter(attachmentReadyForUpload) ?? [];

            return attachmentsReadyForUpload.map((attachment) =>
              ChatConversationActions.uploadFile({ conversationId, attachment }),
            );
          }),
        ),
      ),
    ),
  );

  readonly invalidFileAdded$ = createEffect(() =>
    this.actions$.pipe(ofType(ChatConversationActions.addAttachmentFiles)).pipe(
      map(({ files }) => files.filter((file) => !attachmentFileValidForUpload(file))),
      filter((invalidFiles) => invalidFiles.length > 0),
      map((files) => ChatConversationActions.invalidFileSizeFileAttached({ files })),
    ),
  );

  readonly tooManyFileAdded$ = createEffect(() =>
    this.actions$.pipe(ofType(ChatConversationActions.addAttachmentFiles)).pipe(
      filter(({ files }) => files.length > NUM_ALLOWED_ATTACHMENTS),
      map(({ files }) => ChatConversationActions.tooManyFileAttached({ files })),
    ),
  );

  readonly uploadAttachmentFile$ = createEffect(() =>
    this.actions$.pipe(ofType(ChatConversationActions.uploadFile)).pipe(
      mergeMap(({ conversationId, attachment }) =>
        this.chatActivityService.uploadChatMessageAttachment({}, attachment.file).pipe(
          map((persistedAttachment) =>
            ChatConversationActions.uploadFileSuccess({ conversationId, attachment, persistedAttachment }),
          ),
          catchErrorDispatch(ChatConversationActions.uploadFileException, { attachment }),
        ),
      ),
    ),
  );

  readonly navigateToRegistration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChatConversationActions.invalidFileSizeFileAttached),
        concatLatestFrom(() => this.i18n$),
        switchMap(([_, { i18n }]) => {
          return this.toastService.show({
            message: ChatConversationI18n.translate(
              i18n,
              ChatConversationI18n.key.messageInput.attachmentInvalidSizeToast,
            ),
            bgColor: ThemeColorVar.danger,
          });
        }),
      ),
    { dispatch: false },
  );

  readonly copyMessageContent$ = createEffect(
    () =>
      this.actions$.pipe(ofType(ChatConversationActions.copyMessageContent)).pipe(
        map(({ activity }) => {
          if (isChatConversationLocalMessageActivity(activity)) {
            return htmlToText(activity.content);
          } else {
            return (activity as ChatMessageActivityDto).unformatted;
          }
        }),
        switchMap((textContent) => this.clipboardService.write({ string: textContent })),
      ),
    { dispatch: false },
  );

  readonly confirmDeleteBlabItemComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChatConversationActions.confirmDeleteChatMessage),
      concatLatestFrom(() => this.i18n$),
      switchMap(([{ conversationId, id }, { i18n }]) => {
        const message = ChatConversationI18n.translate(
          i18n,
          ChatConversationI18n.key.confirmDeleteMessageDialog.message,
        );
        const title = ChatConversationI18n.translate(i18n, ChatConversationI18n.key.confirmDeleteMessageDialog.title);
        const cancelButtonTitle = ChatConversationI18n.translate(
          i18n,
          ChatConversationI18n.key.confirmDeleteMessageDialog.cancelButtonTitle,
        );
        const okButtonTitle = ChatConversationI18n.translate(
          i18n,
          ChatConversationI18n.key.confirmDeleteMessageDialog.okButtonTitle,
        );

        return this.dialogService.confirm({ message, title, cancelButtonTitle, okButtonTitle }).pipe(
          filter(({ value }) => value),
          map(() => ChatConversationActions.deleteChatMessage({ conversationId, id })),
        );
      }),
    ),
  );

  readonly deleteMessage$ = createEffect(() =>
    this.actions$.pipe(ofType(ChatConversationActions.deleteChatMessage)).pipe(
      mergeMap(({ conversationId, id }) =>
        this.chatActivityService.deleteChatMessage({ id }).pipe(
          map(() => ChatConversationActions.deleteChatMessageSuccess({ conversationId, id })),
          catchErrorDispatch(ChatConversationActions.deleteChatMessageError),
        ),
      ),
    ),
  );
}
