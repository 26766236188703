import gql from 'graphql-tag';
import {
  BlabDefPreviewDtoNoNestingFragment,
  BlabItemFocusEntryDtoNoNestingFragment,
  BlabItemFocusPreviewDtoNoNestingFragment,
  StatusOptionDefDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const BlabItemFocusEntryFragment = gql`
  fragment BlabItemFocusEntryFragment on BlabItemFocusEntryDto {
    ...BlabItemFocusEntryDtoNoNesting
    blabItem {
      ...BlabItemFocusPreviewDtoNoNesting
      statusOptionDefs {
        ...StatusOptionDefDtoNoNesting
      }
      blabDef {
        ...BlabDefPreviewDtoNoNesting
        workspace {
          id
          slug
          name
        }
      }
    }
  }
  ${BlabItemFocusPreviewDtoNoNestingFragment}
  ${BlabItemFocusEntryDtoNoNestingFragment}
  ${BlabDefPreviewDtoNoNestingFragment}
  ${StatusOptionDefDtoNoNestingFragment}
`;
