import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Share } from '@capacitor/share';
import { Preferences } from '@capacitor/preferences';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { megaBytesFromBytes } from '@t5s/shared/util/file';
import { FileOpenerOpenFileException, OpenFileOptions } from '@t5s/mobile-client/value-object/file-opener';
import { from, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

const FILE_STORAGE_KEY = 'fileOpenerStoredFile';

const getFileStorageKey = (url: string) => {
  const uniqueIdentifier = url.split('?')[0]; // ensure query params of URL are not included, these may be dynamic
  return `${FILE_STORAGE_KEY}_${uniqueIdentifier}`;
};

function shouldStoreFile({ size }: OpenFileOptions): boolean {
  return megaBytesFromBytes(size) < 20;
}

@Injectable()
export class FileOpenerService {
  constructor(private readonly http: HttpClient, private readonly fileOpener: FileOpener) {}

  /** Opens a file in a dedicated native modal. */
  openFile(
    url: string,
    opts: OpenFileOptions,
  ): { retrievedFile$: Observable<unknown>; openedFileDismissal$: Observable<void> } {
    const { mimetype } = opts;

    const retrievedFile$ = from(this.retrieveFile(url, opts));

    const openedFileDismissal$ = retrievedFile$.pipe(switchMap(({ uri }) => this._openFile(uri, mimetype)));

    return { retrievedFile$, openedFileDismissal$ };
  }

  private _openFile(uri: string, mimetype = '') {
    return from(
      this.fileOpener.open(uri, mimetype).catch((originalError) => {
        throw new FileOpenerOpenFileException(originalError);
      }),
    );
  }

  /** Opens a native "open with" action dialog. */
  showOpenWithDialog(url: string, opts: OpenFileOptions): Observable<unknown> {
    const { mimetype } = opts;

    const retrievedFile$ = from(this.retrieveFile(url, opts));

    return retrievedFile$.pipe(switchMap(({ uri }) => this._showOpenWithDialog(uri, mimetype)));
  }

  private _showOpenWithDialog(uri: string, mimetype = '') {
    return from(Share.share({ url: uri })).pipe(catchError(() => of({}))); // This API throws if user cancels, why would you
  }

  private convertBlobToBase64(blob: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };

      reader.readAsDataURL(blob);
    });
  }

  /** Retrieves a file by either downloading it or restoring it from storage. */
  private async retrieveFile(url: string, opts: OpenFileOptions) {
    let { filename } = opts;

    const restoredFileAsBase64 = await this.restoreFileBase64FromStorage(url);

    let fileAsBase64: string;
    if (restoredFileAsBase64) {
      // file could be restored
      fileAsBase64 = restoredFileAsBase64;
    } else {
      // download and store file
      fileAsBase64 = await this.downloadFileBase64(url);
      const key = getFileStorageKey(url);

      if (shouldStoreFile(opts)) {
        // attempt to store file, do not rethrow errors as this does not impact the base functionality of opening the file
        await Preferences.set({ key, value: fileAsBase64 }).catch((err) => {
          // eslint-disable-next-line no-console
          console.warn(`Storing file with URL ${url} failed with exception ${err}`);
        });
      }
    }

    filename = filename ?? url.substr(url.lastIndexOf('/') + 1);

    const { uri } = await Filesystem.writeFile({ path: filename, data: fileAsBase64, directory: Directory.Documents });

    return { uri, filename, fileAsBase64 };
  }

  /** Restores a file from storage as base64. */
  private async restoreFileBase64FromStorage(url: string) {
    const key = getFileStorageKey(url);
    const availableStorageKeys = await Preferences.keys();

    // check whether file can be restored from storage
    if (availableStorageKeys.keys.includes(key)) {
      const result = await Preferences.get({ key });

      if (result.value) {
        return result.value;
      }
    }

    return undefined;
  }

  /** Downloads a file and returns base64 representation. */
  private async downloadFileBase64(url: string) {
    const resBlob = await this.http.get(url, { responseType: 'blob' }).toPromise();

    if (!resBlob) {
      throw new Error('[downloadFileBase64] resBlob is undefined');
    }

    const base64 = (await this.convertBlobToBase64(resBlob)) as string;

    return base64;
  }
}
