import { Directive, ElementRef } from '@angular/core';
import { px } from '@t5s/client/ui/style/common';

@Directive({
  selector: '[t5sFlexOneHorizontal]',
  exportAs: 't5sFlexOneHorizontal',
})
export class FlexOneHorizontalDirective {
  constructor(el: ElementRef<HTMLElement>) {
    el.nativeElement.style.setProperty('flex', '1');
    el.nativeElement.style.setProperty('min-width', px(0));
  }
}
