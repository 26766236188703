import gql from 'graphql-tag';
import {
  WorkflowCenterWebhookEventDtoNoNestingFragment,
  WorkflowCenterWebhookDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const WorkflowCenterWebhookEventUnionTypeFragment = gql`
  fragment WorkflowCenterWebhookEventUnionTypeFragment on WorkflowCenterWebhookEventUnionType {
    ... on WorkflowCenterWebhookEventDto {
      ...WorkflowCenterWebhookEventDtoNoNesting
      webhook {
        ...WorkflowCenterWebhookDtoNoNesting
      }
    }
  }
  ${WorkflowCenterWebhookEventDtoNoNestingFragment}
  ${WorkflowCenterWebhookDtoNoNestingFragment}
`;
