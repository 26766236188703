import {
  BlabItemActivityReplyInputAttachmentValobj,
  BlabItemActivityThreadReplyInputState,
} from '@t5s/mobile-client/value-object/blab-item-activity-thread';
import { BlabItemCommentAttachmentDto } from '@t5s/shared/gql';
import { Require } from '@t5s/shared/types';
import { getLocalIdSequence } from '@t5s/shared/util/sequence';
import { trimHtmlString } from '@t5s/shared/validation/common';

const sequence = getLocalIdSequence();
const getLocalAttachmentId = () => sequence.next().value;

export function getBlabItemActivityThreadReplyInputStateAddAttachments(
  commentInputState: BlabItemActivityThreadReplyInputState,
  files: File[],
): BlabItemActivityThreadReplyInputState {
  let { attachments = [] } = commentInputState;

  const newAttachments = files.map((file) => ({ file, id: getLocalAttachmentId() }));

  attachments = [...attachments, ...newAttachments];

  return { ...commentInputState, attachments };
}

export function getBlabItemActivityThreadReplyInputStateAttachmentUploadException(
  commentInputState: BlabItemActivityThreadReplyInputState,
  attachmentToRemove: BlabItemActivityReplyInputAttachmentValobj,
): BlabItemActivityThreadReplyInputState {
  let { attachments = [] } = commentInputState;

  attachments = attachments.filter((att) => att.id !== attachmentToRemove.id);

  return { ...commentInputState, attachments };
}

export function getBlabItemActivityThreadReplyInputStateRemoveAttachment(
  commentInputState: BlabItemActivityThreadReplyInputState,
  attachmentId: number,
): BlabItemActivityThreadReplyInputState {
  let { attachments = [] } = commentInputState;

  attachments = attachments.filter((att) => att.id !== attachmentId);

  return { ...commentInputState, attachments };
}

export function getBlabItemActivityThreadReplyInputStateAttachmentUploadStart(
  commentInputState: BlabItemActivityThreadReplyInputState,
  attachment: BlabItemActivityReplyInputAttachmentValobj,
): BlabItemActivityThreadReplyInputState {
  let { attachments = [] } = commentInputState;

  attachments = attachments.map((att) => {
    if (att.id === attachment.id) {
      return { ...att, progress: 0 };
    } else {
      return att;
    }
  });

  return { ...commentInputState, attachments };
}

export function getBlabItemActivityThreadReplyInputStateAttachmentUploadSuccess(
  commentInputState: BlabItemActivityThreadReplyInputState,
  attachment: BlabItemActivityReplyInputAttachmentValobj,
  persistedAttachment: BlabItemCommentAttachmentDto,
): BlabItemActivityThreadReplyInputState {
  let { attachments = [] } = commentInputState;

  attachments = attachments.map((att) => {
    if (att.id === attachment.id) {
      return { ...att, progress: 100, attachment: persistedAttachment };
    } else {
      return att;
    }
  });

  return { ...commentInputState, attachments };
}

export function attachmentUploadedCompletely(
  attachmentWrapper: BlabItemActivityReplyInputAttachmentValobj,
): attachmentWrapper is Require<BlabItemActivityReplyInputAttachmentValobj, 'attachment'> {
  return attachmentWrapper.attachment !== undefined;
}

export function attachmentReadyForUpload(attachmentWrapper: BlabItemActivityReplyInputAttachmentValobj): boolean {
  return attachmentWrapper.attachment === undefined && attachmentWrapper.progress === undefined;
}

export function attachmentCurrentlyUploading(attachmentWrapper: BlabItemActivityReplyInputAttachmentValobj): boolean {
  return attachmentWrapper.attachment === undefined && attachmentWrapper.progress !== undefined;
}

export function blabItemActivityReplyInputValid({
  content,
  attachments,
}: {
  content: string;
  attachments?: any[];
}): boolean {
  const trimmedContentLength = trimHtmlString(content ?? '');
  return !trimmedContentLength && !attachments?.length;
}
