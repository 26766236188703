import gql from 'graphql-tag';
import { ActiveUserCalculationSplitBySectionDtoFragment } from './calculation/calculation-split-by.fragment';
import { ActiveUserMultiCategorySplitBySectionDtoFragment } from './category/multi-category-split-by.fragment';
import { ActiveUserSingleCategorySplitBySectionDtoFragment } from './category/single-category-split-by.fragment';
import { ActiveUserRangeDateSplitBySectionDtoFragment } from './date/range-date-split-by.fragment';
import { ActiveUserSingleDateSplitBySectionDtoFragment } from './date/single-date-split-by.fragment';
import { ActiveUserCreatedAtSplitBySectionDtoFragment } from './modified/last-modified-at-split-by.fragment';
import { ActiveUserMultiRelationSplitBySectionDtoFragment } from './relation/multi-relation-split-by.fragment';
import { ActiveUserSingleRelationSplitBySectionDtoFragment } from './relation/single-relation-split-by.fragment';
import { ActiveUserStatusSplitBySectionDtoFragment } from './status/status-split-by.fragment';
import { ActiveUserMultiUserSplitBySectionDtoFragment } from './user/multi-user-split-by.fragment';
import { ActiveUserSingleUserSplitBySectionDtoFragment } from './user/single-user-split-by.fragment';

export const ActiveUserSplitBySectionUnionTypeFragment = gql`
  fragment ActiveUserSplitBySectionUnionType on ActiveUserSplitBySectionUnionType {
    ... on ActiveUserSingleCategorySplitBySectionDto {
      ...ActiveUserSingleCategorySplitBySectionDto
    }

    ... on ActiveUserMultiCategorySplitBySectionDto {
      ...ActiveUserMultiCategorySplitBySectionDto
    }

    ... on ActiveUserSingleUserSplitBySectionDto {
      ...ActiveUserSingleUserSplitBySectionDto
    }

    ... on ActiveUserMultiUserSplitBySectionDto {
      ...ActiveUserMultiUserSplitBySectionDto
    }

    ... on ActiveUserSingleRelationSplitBySectionDto {
      ...ActiveUserSingleRelationSplitBySectionDto
    }

    ... on ActiveUserMultiRelationSplitBySectionDto {
      ...ActiveUserMultiRelationSplitBySectionDto
    }

    ... on ActiveUserStatusSplitBySectionDto {
      ...ActiveUserStatusSplitBySectionDto
    }

    ... on ActiveUserSingleDateSplitBySectionDto {
      ...ActiveUserSingleDateSplitBySectionDto
    }

    ... on ActiveUserRangeDateSplitBySectionDto {
      ...ActiveUserRangeDateSplitBySectionDto
    }

    ... on ActiveUserCreatedAtSplitBySectionDto {
      ...ActiveUserCreatedAtSplitBySectionDto
    }

    ... on ActiveUserCreatedAtSplitBySectionDto {
      ...ActiveUserCreatedAtSplitBySectionDto
    }

    ... on ActiveUserCalculationSplitBySectionDto {
      ...ActiveUserCalculationSplitBySectionDto
    }
  }
  ${ActiveUserSingleCategorySplitBySectionDtoFragment}
  ${ActiveUserMultiCategorySplitBySectionDtoFragment}
  ${ActiveUserSingleUserSplitBySectionDtoFragment}
  ${ActiveUserMultiUserSplitBySectionDtoFragment}
  ${ActiveUserSingleRelationSplitBySectionDtoFragment}
  ${ActiveUserMultiRelationSplitBySectionDtoFragment}
  ${ActiveUserStatusSplitBySectionDtoFragment}
  ${ActiveUserSingleDateSplitBySectionDtoFragment}
  ${ActiveUserRangeDateSplitBySectionDtoFragment}
  ${ActiveUserCreatedAtSplitBySectionDtoFragment}
  ${ActiveUserCreatedAtSplitBySectionDtoFragment}
  ${ActiveUserCalculationSplitBySectionDtoFragment}
`;
