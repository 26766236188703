import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BuildEnvironment, BUILD_ENV } from '@t5s/mobile-client/env';
import { RootPageModule } from '@t5s/mobile-client/page/root';
import { MobileClientBusinessLogicModule } from './business-logic/business-logic.module';
import { MobileClientDebugModule } from './debug/debug.module';
import { MobileClientLogModule } from './log/log.module';
import { MobileClientProviderModule } from './provider/provider.module';
import { MobileClientRootComponent } from './root.component';
import { MobileClientServiceModule } from './service/service.module';

@NgModule({
  declarations: [MobileClientRootComponent],
  imports: [
    BrowserModule,
    RootPageModule,
    MobileClientBusinessLogicModule,
    MobileClientProviderModule,
    MobileClientServiceModule,
    MobileClientDebugModule,
    MobileClientLogModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: BUILD_ENV === BuildEnvironment.PRD,
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  bootstrap: [MobileClientRootComponent],
})
export class MobileClientRootModule {}
