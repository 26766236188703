import gql from 'graphql-tag';
import { WorkspaceDtoNoNestingFragment, BlabDefDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const AddBlabDefsFromWorkspaceTemplateResultDtoFragment = gql`
  fragment AddBlabDefsFromWorkspaceTemplateResultDtoFragment on AddBlabDefsFromWorkspaceTemplateResultDto {
    workspace {
      ...WorkspaceDtoNoNesting
    }
    addedBlabDefs {
      ...BlabDefDtoNoNesting
    }
  }
  ${WorkspaceDtoNoNestingFragment}
  ${BlabDefDtoNoNestingFragment}
`;
