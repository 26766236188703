import gql from 'graphql-tag';
import { UserProfilePreviewDtoFragment } from '../user-profile';

export const BlabDefActivityDtoFragment = gql`
  fragment BlabDefActivityDtoFragment on BlabDefActivityDto {
    id
    type
    createdAt
    author {
      ...UserProfilePreviewDtoFragment
    }
    authorId
  }
  ${UserProfilePreviewDtoFragment}
`;
