import gql from 'graphql-tag';
import {
  BlabDefDataImportActivityDtoNoNestingFragment,
  BlabDefDataImportFileDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { UserProfilePreviewDtoFragment } from '../user-profile/user-profile-preview-dto.fragment';

export const BlabDefDataImportActivityDtoFragment = gql`
  fragment BlabDefDataImportActivityDtoFragment on BlabDefDataImportActivityDto {
    ...BlabDefDataImportActivityDtoNoNesting
    file {
      ...BlabDefDataImportFileDtoNoNesting
    }
    createdByUser {
      ...UserProfilePreviewDtoFragment
    }
  }

  ${BlabDefDataImportActivityDtoNoNestingFragment}
  ${BlabDefDataImportFileDtoNoNestingFragment}
  ${UserProfilePreviewDtoFragment}
`;
