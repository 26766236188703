import gql from 'graphql-tag';
import { DuplicateBlabDefResultDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { FullBlabDefDtoFragment } from '../blab-def/blab-def.fragment';

export const DuplicateBlabDefResultDtoFragment = gql`
  fragment DuplicateBlabDefResultDto on DuplicateBlabDefResultDto {
    ...DuplicateBlabDefResultDtoNoNesting
    blabDef {
      ...FullBlabDefDto
    }
  }

  ${DuplicateBlabDefResultDtoNoNestingFragment}
  ${FullBlabDefDtoFragment}
`;
