import gql from 'graphql-tag';
import { BlabViewPreviewDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { SplitByUnionTypeFragment } from '../blab-view-split-by';

export const BlabViewPreviewDtoFragment = gql`
  fragment BlabViewPreviewDto on BlabViewPreviewDto {
    ...BlabViewPreviewDtoNoNesting
    splitBy {
      ...SplitByUnionType
    }
  }
  ${BlabViewPreviewDtoNoNestingFragment}
  ${SplitByUnionTypeFragment}
`;
