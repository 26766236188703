import gql from 'graphql-tag';
import {
  WorkflowRunCreatedSubscriptionUpdateNoNestingFragment,
  WorkflowRunUpdatedSubscriptionUpdateNoNestingFragment,
} from '../../generated/graphql-fragments';
import { WorkflowRunLogsAppendedSubscriptionUpdateFragment } from './workflow-runs-subscription/workflow-run-logs-appended-subscription-update.fragment';

export const WorkflowRunsSubscriptionUpdate = gql`
  fragment WorkflowRunsSubscriptionUpdate on WorkflowRunsSubscriptionUpdate {
    ... on WorkflowRunCreatedSubscriptionUpdate {
      ...WorkflowRunCreatedSubscriptionUpdateNoNesting
    }

    ... on WorkflowRunLogsAppendedSubscriptionUpdate {
      ...WorkflowRunLogsAppendedSubscriptionUpdateFragment
    }

    ... on WorkflowRunUpdatedSubscriptionUpdate {
      ...WorkflowRunUpdatedSubscriptionUpdateNoNesting
    }
  }
  ${WorkflowRunCreatedSubscriptionUpdateNoNestingFragment}
  ${WorkflowRunLogsAppendedSubscriptionUpdateFragment}
  ${WorkflowRunUpdatedSubscriptionUpdateNoNestingFragment}
`;
