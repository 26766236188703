import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@t5s/client/provider/window';
import { EntityIconSkinTone } from '@t5s/shared/readonly-constant/entity-icon';
import { EntityIconApiService } from '@t5s/shared/service/api-entity-icon';
import {
  getEntityIconEmojiMetadataUrl,
  getEntityIconGraphicDataUrl,
  getEntityIconGraphicMetadataUrl,
} from '@t5s/shared/util/entity-icon';
import { filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { EntityIconColor } from '@t5s/shared/value-object/entity-icon';
import { EntityIconAbstractService } from '@t5s/mobile-client/provider-token/entity-icon';
import {
  CustomEntityIconPickerUploadEvent,
  CustomEntityIconPickerUploadInput,
  EntityIconGraphicData,
  EntityIconGraphicsData,
  EntityIconPickerEmojiSettings,
  EntityIconPickerEmojisMetadata,
  EntityIconPickerGraphicsMetadata,
  EntityIconPickerGraphicsSettings,
  LoadEntityIconPickerEmojisMetadataInput,
  LoadEntityIconPickerGraphicsMetadataInput,
} from '@t5s/client/value-object/entity-icon';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

const GRAPHICS_SETTINGS_KEY = 'tape-entity-icon-picker-graphics-settings';
const EMOJI_SETTINGS_KEY = 'tape-entity-icon-picker-emoji-settings';

function isEntityIconPickerGraphicsSettings(settings: unknown): settings is EntityIconPickerGraphicsSettings {
  if (!settings || typeof settings !== 'object') return false;
  const { color, askEveryTime, recentGraphics } = settings as EntityIconPickerGraphicsSettings;
  if (color && !Object.values(EntityIconColor).includes(color)) return false;
  if (askEveryTime && typeof askEveryTime !== 'boolean') return false;
  if (recentGraphics && !Array.isArray(recentGraphics)) return false;

  return true;
}

function isEntityIconPickerEmojiSettings(settings: unknown): settings is EntityIconPickerEmojiSettings {
  if (!settings || typeof settings !== 'object') return false;
  const { skinTone, askEveryTime, recentEmojis } = settings as EntityIconPickerEmojiSettings;
  if (skinTone && !Object.values(EntityIconSkinTone).includes(skinTone)) return false;
  if (askEveryTime && typeof askEveryTime !== 'boolean') return false;
  if (recentEmojis && !Array.isArray(recentEmojis)) return false;

  return true;
}

@Injectable({ providedIn: 'root' })
export class EntityIconService implements EntityIconAbstractService {
  private readonly localStorage?: Storage;

  constructor(
    private readonly http: HttpClient,
    @Inject(WINDOW) window: Window | undefined,
    private readonly entityIconService: EntityIconApiService,
  ) {
    this.localStorage = window?.localStorage;
  }

  private loadingGraphicData = false;
  private readonly graphicData$$ = new BehaviorSubject<EntityIconGraphicsData | undefined>(undefined);
  private readonly graphicData$ = this.graphicData$$.asObservable().pipe(filterOnlyPresent());

  private loadingGraphicMetadata = false;
  private readonly graphicMetadata$$ = new BehaviorSubject<EntityIconPickerGraphicsMetadata | undefined>(undefined);
  private readonly graphicMetadata$ = this.graphicMetadata$$.asObservable().pipe(filterOnlyPresent());

  private loadingEmojiMetadata = false;
  private readonly emojiMetadata$$ = new BehaviorSubject<EntityIconPickerEmojisMetadata | undefined>(undefined);
  private readonly emojiMetadata$ = this.emojiMetadata$$.asObservable().pipe(filterOnlyPresent());

  loadGraphicData(): Observable<EntityIconGraphicsData> {
    if (!this.loadingGraphicData) {
      this.loadingGraphicData = true;
      this.http.get<EntityIconGraphicsData>(getEntityIconGraphicDataUrl()).subscribe((data) => {
        this.graphicData$$.next(data);
      });
    }
    return this.graphicData$;
  }

  loadGraphicMetadata(input: LoadEntityIconPickerGraphicsMetadataInput): Observable<EntityIconPickerGraphicsMetadata> {
    if (!this.loadingGraphicMetadata) {
      this.loadingGraphicMetadata = true;
      this.http.get<EntityIconPickerGraphicsMetadata>(getEntityIconGraphicMetadataUrl(input)).subscribe((data) => {
        this.graphicMetadata$$.next(data);
      });
    }
    return this.graphicMetadata$;
  }

  loadEmojiMetadata(input: LoadEntityIconPickerEmojisMetadataInput): Observable<EntityIconPickerEmojisMetadata> {
    if (!this.loadingEmojiMetadata) {
      this.loadingEmojiMetadata = true;
      this.http.get<EntityIconPickerEmojisMetadata>(getEntityIconEmojiMetadataUrl(input)).subscribe((data) => {
        this.emojiMetadata$$.next(data);
      });
    }
    return this.emojiMetadata$;
  }

  getGraphicDataById(id: string): Observable<EntityIconGraphicData | undefined> {
    return this.loadGraphicData().pipe(
      map((data) => {
        const graphicData = data.icons.find((graphic) => graphic.id === id);
        return graphicData;
      }),
    );
  }

  loadGraphicSettings(): EntityIconPickerGraphicsSettings {
    try {
      const settingsStr = this.localStorage?.getItem(GRAPHICS_SETTINGS_KEY);
      const settings = JSON.parse(settingsStr ?? '');
      if (isEntityIconPickerGraphicsSettings(settings)) {
        return settings;
      }
    } catch (err: unknown) {}
    return { recentGraphics: [] };
  }

  saveGraphicSettings(settings: EntityIconPickerGraphicsSettings) {
    try {
      const settingsStr = JSON.stringify(settings);
      this.localStorage?.setItem(GRAPHICS_SETTINGS_KEY, settingsStr);
    } catch (err: unknown) {}
  }

  loadEmojiSettings(): EntityIconPickerEmojiSettings {
    try {
      const settingsStr = this.localStorage?.getItem(EMOJI_SETTINGS_KEY);
      const settings = JSON.parse(settingsStr ?? '');
      if (isEntityIconPickerEmojiSettings(settings)) {
        return settings;
      }
    } catch (err: unknown) {}
    return { recentEmojis: [] };
  }

  saveEmojiSettings(settings: EntityIconPickerEmojiSettings) {
    try {
      const settingsStr = JSON.stringify(settings);
      this.localStorage?.setItem(EMOJI_SETTINGS_KEY, settingsStr);
    } catch (err: unknown) {}
  }

  uploadCustomImage(input: CustomEntityIconPickerUploadInput): Observable<CustomEntityIconPickerUploadEvent> {
    return this.entityIconService.uploadCustomEntityIcon(input.file ? input.file : input);
  }
}
