import { DateFormat, Language, TimeFormat } from '@t5s/shared/gql';
import { ApplicableI18n } from '../applicable-i18n.interface';
import { DateFormats, DateFormatsDict } from './date-formats';
import { TimeFormats, TimeFormatsDict } from './time-formats';

interface DateTimeFormats {
  date: DateFormats;
  time: TimeFormats;
}

const DateTimeFormatsDict: { [key in Language]: { [key in DateFormat]: { [key in TimeFormat]: DateTimeFormats } } } = {
  [Language.EN]: {
    [DateFormat.SLASH_MM_DD_YYYY]: {
      [TimeFormat.HOURS_12]: {
        date: DateFormatsDict[Language.EN][DateFormat.SLASH_MM_DD_YYYY],
        time: TimeFormatsDict[Language.EN][TimeFormat.HOURS_12],
      },
      [TimeFormat.HOURS_24]: {
        date: DateFormatsDict[Language.EN][DateFormat.SLASH_MM_DD_YYYY],
        time: TimeFormatsDict[Language.EN][TimeFormat.HOURS_24],
      },
    },
    [DateFormat.SLASH_DD_MM_YYYY]: {
      [TimeFormat.HOURS_12]: {
        date: DateFormatsDict[Language.EN][DateFormat.SLASH_DD_MM_YYYY],
        time: TimeFormatsDict[Language.EN][TimeFormat.HOURS_12],
      },
      [TimeFormat.HOURS_24]: {
        date: DateFormatsDict[Language.EN][DateFormat.SLASH_DD_MM_YYYY],
        time: TimeFormatsDict[Language.EN][TimeFormat.HOURS_24],
      },
    },
    [DateFormat.DOT_DD_MM_YYYY]: {
      [TimeFormat.HOURS_12]: {
        date: DateFormatsDict[Language.EN][DateFormat.DOT_DD_MM_YYYY],
        time: TimeFormatsDict[Language.EN][TimeFormat.HOURS_12],
      },
      [TimeFormat.HOURS_24]: {
        date: DateFormatsDict[Language.EN][DateFormat.DOT_DD_MM_YYYY],
        time: TimeFormatsDict[Language.EN][TimeFormat.HOURS_24],
      },
    },
  },
  [Language.DE]: {
    [DateFormat.SLASH_MM_DD_YYYY]: {
      [TimeFormat.HOURS_12]: {
        date: DateFormatsDict[Language.DE][DateFormat.SLASH_MM_DD_YYYY],
        time: TimeFormatsDict[Language.DE][TimeFormat.HOURS_12],
      },
      [TimeFormat.HOURS_24]: {
        date: DateFormatsDict[Language.DE][DateFormat.SLASH_MM_DD_YYYY],
        time: TimeFormatsDict[Language.DE][TimeFormat.HOURS_24],
      },
    },
    [DateFormat.SLASH_DD_MM_YYYY]: {
      [TimeFormat.HOURS_12]: {
        date: DateFormatsDict[Language.DE][DateFormat.SLASH_DD_MM_YYYY],
        time: TimeFormatsDict[Language.DE][TimeFormat.HOURS_12],
      },
      [TimeFormat.HOURS_24]: {
        date: DateFormatsDict[Language.DE][DateFormat.SLASH_DD_MM_YYYY],
        time: TimeFormatsDict[Language.DE][TimeFormat.HOURS_24],
      },
    },
    [DateFormat.DOT_DD_MM_YYYY]: {
      [TimeFormat.HOURS_12]: {
        date: DateFormatsDict[Language.DE][DateFormat.DOT_DD_MM_YYYY],
        time: TimeFormatsDict[Language.DE][TimeFormat.HOURS_12],
      },
      [TimeFormat.HOURS_24]: {
        date: DateFormatsDict[Language.DE][DateFormat.DOT_DD_MM_YYYY],
        time: TimeFormatsDict[Language.DE][TimeFormat.HOURS_24],
      },
    },
  },
};

export function getDateTimeFormats({
  language = Language.EN,
  timeFormat = TimeFormat.HOURS_12,
  dateFormat = DateFormat.SLASH_MM_DD_YYYY,
}: Partial<ApplicableI18n>): DateTimeFormats {
  return DateTimeFormatsDict[language][dateFormat][timeFormat];
}
