import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { formatDateViaLanguage, isApplicableI18n, normalizeDate } from '../common';

export function formatCalendarYearDate(i18n: SharedI18n, date?: string | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  return formatDateViaLanguage(date, 'yyyy', i18n);
}
