import {
  AudioRoomNotificationDto,
  BlabDefNotificationDto,
  BlabItemNotificationDto,
  NotificationDto,
  NotificationType,
  NotificationUnionType,
  UserGroupNotificationDto,
  UserNotificationDto,
  WorkflowDefNotificationDto,
  WorkspaceNotificationDto,
} from '@t5s/shared/gql';

export function getNotificationUnionType(notification: NotificationDto): NotificationUnionType | undefined {
  const { type } = notification;

  switch (type) {
    case NotificationType.BLAB_ITEM_NOTIFICATION: {
      return notification.blabItemNotification;
    }

    case NotificationType.USER_NOTIFICATION: {
      return notification.userNotification;
    }

    case NotificationType.WORKSPACE_NOTIFICATION: {
      return notification.workspaceNotification;
    }

    case NotificationType.AUDIO_ROOM_NOTIFICATION: {
      return notification.audioRoomNotification;
    }

    case NotificationType.WORKFLOW_DEF_NOTIFICATION: {
      return notification.workflowDefNotification;
    }

    case NotificationType.BLAB_DEF_NOTIFICATION: {
      return notification.blabDefNotification;
    }

    case NotificationType.USER_GROUP_NOTIFICATION: {
      return notification.userGroupNotification;
    }
  }

  // Note: this function does not throw, but has a nullable API to support outdated versions of this function when new enum
  // values are added. this broke mobile client apps in the past.

  return undefined;
}

export function getNotificationWrapper(notification: NotificationUnionType): NotificationDto {
  const { type } = notification;

  switch (type) {
    case NotificationType.BLAB_ITEM_NOTIFICATION: {
      return { type, blabItemNotification: notification as BlabItemNotificationDto };
    }

    case NotificationType.USER_NOTIFICATION: {
      return { type, userNotification: notification as UserNotificationDto };
    }

    case NotificationType.WORKSPACE_NOTIFICATION: {
      return { type, workspaceNotification: notification as WorkspaceNotificationDto };
    }

    case NotificationType.AUDIO_ROOM_NOTIFICATION: {
      return { type, audioRoomNotification: notification as AudioRoomNotificationDto };
    }

    case NotificationType.WORKFLOW_DEF_NOTIFICATION: {
      return { type, workflowDefNotification: notification as WorkflowDefNotificationDto };
    }

    case NotificationType.BLAB_DEF_NOTIFICATION: {
      return { type, blabDefNotification: notification as BlabDefNotificationDto };
    }

    case NotificationType.USER_GROUP_NOTIFICATION: {
      return { type, userGroupNotification: notification as UserGroupNotificationDto };
    }

    default: {
      throw new Error(`getNotificationWrapper for ${type} not implemented`);
    }
  }
}
