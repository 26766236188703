import { Directive } from '@angular/core';
import { selectSlice } from '@rx-angular/state/selections';
import { RxDirective } from '@t5s/client/util/rx';
import { trackById, trackByPosition } from '@t5s/client/util/track-by';
import { I18nObject } from '@t5s/mobile-client/i18n/common';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
import { font, ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface I18nState {
  i18n: I18nObject;
}

@Directive()
export abstract class RxI18nComponent<T extends I18nState = I18nState> extends RxDirective<T> {
  readonly trackById = trackById;
  readonly trackByPosition = trackByPosition;
  readonly ThemeColorVar = ThemeColorVar;
  readonly Color = ThemeColorVar;
  readonly Font = font;

  readonly i18n$ = this.select(
    selectSlice(['i18n']),
    map(({ i18n }) => i18n),
  );

  constructor(readonly i18n: I18nObjectObservable) {
    super();
    this.connect(i18n as Observable<Partial<T>>);
  }
}
