import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { ComponentStyle } from '@t5s/client/ui/style/common';

@Component({
  selector: 't5s-flex-column',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostSpread],
})
export class FlexColumnComponent {
  constructor(elRef: ElementRef<HTMLElement>) {
    elRef.nativeElement.style.setProperty('display', 'flex');
    elRef.nativeElement.style.setProperty('flex-direction', 'column');
    elRef.nativeElement.style.setProperty('height', '100%');
  }
}
