import gql from 'graphql-tag';
import { ConvertRangeDateFlimToSingleDateFlimResultDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../../flim-def';

export const ConvertRangeDateFlimToSingleDateFlimResultDtoFragment = gql`
  fragment ConvertRangeDateFlimToSingleDateFlimResultDtoFragment on ConvertRangeDateFlimToSingleDateFlimResultDto {
    ...ConvertRangeDateFlimToSingleDateFlimResultDtoNoNesting

    flimDef {
      ...FullFlimDef
    }
  }
  ${ConvertRangeDateFlimToSingleDateFlimResultDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
