import { Injectable } from '@angular/core';
import { PlatformObservable } from '@t5s/mobile-client/provider-token/device';
import { defer, EMPTY, from, Observable, of } from 'rxjs';
import { filter, shareReplay, switchMap } from 'rxjs/operators';

const Badge = {} as any; // TODO: remove

@Injectable()
export class ApplicationBadgeService {
  readonly isSupportedPlatform$ = this.platform$.pipe(
    filter(({ platform }) => platform === 'ios'),
    switchMap(() => of(false)),
    shareReplay(1),
  );

  constructor(private readonly platform$: PlatformObservable) {}

  getBadge(): Observable<{ count: number }> {
    return this.isSupportedPlatform$.pipe(switchMap(() => defer(() => EMPTY)));
  }

  setBadge(count: number) {
    return this.isSupportedPlatform$.pipe(switchMap(() => defer(() => from(EMPTY))));
  }

  increaseBadge() {
    return this.isSupportedPlatform$.pipe(switchMap(() => defer(() => from(EMPTY))));
  }

  decreaseBadge() {
    return this.isSupportedPlatform$.pipe(switchMap(() => defer(() => from(EMPTY))));
  }
}
