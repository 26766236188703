import gql from 'graphql-tag';
import {
  PageInfoNoNestingFragment,
  GlobalWorkflowRunPreviewDtoNoNestingFragment,
  BlabDefPreviewDtoNoNestingFragment,
  WorkflowDefPreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const GlobalWorkflowRunsConnectionFragment = gql`
  fragment GlobalWorkflowRunsConnectionFragment on GlobalWorkflowRunsConnection {
    edges {
      cursor
      node {
        ...GlobalWorkflowRunPreviewDtoNoNesting
        blabDef {
          ...BlabDefPreviewDtoNoNesting
        }
        workflowDef {
          ...WorkflowDefPreviewDtoNoNesting
        }
      }
    }
    pageInfo {
      ...PageInfoNoNesting
    }
  }
  ${GlobalWorkflowRunPreviewDtoNoNestingFragment}
  ${PageInfoNoNestingFragment}
  ${BlabDefPreviewDtoNoNestingFragment}
  ${WorkflowDefPreviewDtoNoNestingFragment}
`;
