import gql from 'graphql-tag';
import {
  ThumbnailDtoNoNestingFragment,
  WorkspaceTemplateDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const WorkspaceTemplateDtoFragment = gql`
  fragment WorkspaceTemplateDtoFragment on WorkspaceTemplateDto {
    ...WorkspaceTemplateDtoNoNesting
    categories

    previewImage {
      ...ThumbnailDtoNoNesting
    }

    titleImage {
      ...ThumbnailDtoNoNesting
    }

    image3 {
      ...ThumbnailDtoNoNesting
    }

    image4 {
      ...ThumbnailDtoNoNesting
    }
  }
  ${WorkspaceTemplateDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
