import gql from 'graphql-tag';
import { BlabItemPreviewDtoNoNestingFragment, ReminderDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const ReminderFragment = gql`
  fragment ReminderDtoFragment on ReminderDto {
    ...ReminderDtoNoNesting
    referencedBlabItem {
      ...BlabItemPreviewDtoNoNesting
      blabDef {
        id
        itemName
        blabName
        icon
        iconv2
        itemIcon
        workspace {
          id
          name
          organization {
            id
            name
          }
        }
      }
    }
  }
  ${ReminderDtoNoNestingFragment}
  ${BlabItemPreviewDtoNoNestingFragment}
`;
