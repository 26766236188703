import gql from 'graphql-tag';
import {
  GetLocationAutocompleteSuggestionsRejectionDtoNoNestingFragment,
  LocationAutocompleteSuggestionDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const GetLocationAutocompleteSuggestionsResultFragment = gql`
  fragment GetLocationAutocompleteSuggestionsResultFragment on GetLocationAutocompleteSuggestionsResultUnionType {
    ... on LocationAutocompleteSuggestionsDto {
      suggestions {
        ...LocationAutocompleteSuggestionDtoNoNesting
      }
    }
    ... on GetLocationAutocompleteSuggestionsRejectionDto {
      ...GetLocationAutocompleteSuggestionsRejectionDtoNoNesting
    }
  }
  ${GetLocationAutocompleteSuggestionsRejectionDtoNoNestingFragment}
  ${LocationAutocompleteSuggestionDtoNoNestingFragment}
`;
