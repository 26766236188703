import { FirstWeekday } from '@t5s/shared/gql';
import {
  addDays,
  addHours,
  addMilliseconds,
  addMinutes,
  addMonths,
  addSeconds,
  addWeeks,
  addYears,
  compareAsc as _compareAsc,
  compareDesc as _compareDesc,
  differenceInDays,
  endOfDay as dateFnsEndOfDay,
  endOfWeek as dateFnsEndOfWeek,
  endOfMonth as dateFnsEndOfMonth,
  endOfYear as dateFnsEndOfYear,
  format,
  formatDistance,
  formatDistanceStrict,
  formatDistanceToNowStrict,
  formatISO,
  formatRelative,
  getDay,
  getWeek,
  getWeeksInMonth,
  isAfter,
  isBefore,
  isEqual as _isEqual,
  isSameDay,
  isSameMonth,
  isSameYear as dateFnsIsSameYear,
  isThisWeek,
  isThisYear,
  isToday,
  isTomorrow,
  isValid,
  isYesterday,
  lastDayOfMonth,
  Locale as DateFnsLocale,
  parseISO,
  setMilliseconds,
  startOfDay as dateFnsStartOfDay,
  startOfYear as dateFnsStartOfYear,
  startOfMonth as dateFnsStartOfMonth,
  startOfTomorrow,
  startOfWeek as dateFnsStartOfWeek,
  startOfISOWeek,
  subDays,
  subHours,
  subMilliseconds,
  subMinutes,
  subMonths,
  subSeconds,
  subWeeks,
  subYears,
  parse,
  setDate,
  setMonth,
  nextMonday,
  nextTuesday,
  nextWednesday,
  nextThursday,
  nextFriday,
  nextSaturday,
  nextSunday,
  isFuture,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
  isSunday,
  isFirstDayOfMonth,
  isLastDayOfMonth,
} from 'date-fns';

import { dateFnsLocales } from './locale';
import { removeTime } from './time.utils';

const formatDate = format;
const formatDateDistance = formatDistance;
const formatDateDistanceStrict = formatDistanceStrict;
const formatDateDistanceToNowStrict = formatDistanceToNowStrict;
const formatDateRelative = formatRelative;

function tomorrowMorning() {
  return addHours(startOfTomorrow(), 8);
}

function isSameYear(dateLeft: Date, dateRight: Date): boolean {
  return dateFnsIsSameYear(dateLeft, dateRight);
}

function startOfDay(date: Date): Date {
  return dateFnsStartOfDay(date);
}

function startOfWeek(date: Date, firstWeekday: FirstWeekday = FirstWeekday.MONDAY): Date {
  let firstWeekdayMapping: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 0;
  switch (firstWeekday) {
    case FirstWeekday.SUNDAY:
      firstWeekdayMapping = 0;
      break;
    case FirstWeekday.SATURDAY:
      firstWeekdayMapping = 6;
      break;
    case FirstWeekday.MONDAY:
      firstWeekdayMapping = 1;
      break;
  }

  return dateFnsStartOfWeek(date, {
    weekStartsOn: firstWeekdayMapping,
  });
}

function startOfMonth(date: Date): Date {
  return dateFnsStartOfMonth(date);
}

function startOfYear(date: Date): Date {
  return dateFnsStartOfYear(date);
}

function endOfDay(date: Date): Date {
  return dateFnsEndOfDay(date);
}

function endOfWeek(date: Date, firstWeekday: FirstWeekday = FirstWeekday.MONDAY): Date {
  let firstWeekdayMapping: 0 | 1 | 2 | 3 | 4 | 5 | 6 = 0;
  switch (firstWeekday) {
    case FirstWeekday.SUNDAY:
      firstWeekdayMapping = 0;
      break;
    case FirstWeekday.SATURDAY:
      firstWeekdayMapping = 6;
      break;
    case FirstWeekday.MONDAY:
      firstWeekdayMapping = 1;
      break;
  }

  return dateFnsEndOfWeek(date, {
    weekStartsOn: firstWeekdayMapping,
  });
}

function endOfMonth(date: Date): Date {
  return dateFnsEndOfMonth(date);
}

function endOfYear(date: Date): Date {
  return dateFnsEndOfYear(date);
}

export function withinTheNextNDays(n: number, date: Date): boolean {
  // normalize dates first
  const now = removeTime(new Date(Date.now()));
  date = removeTime(date);

  const diff = differenceInDays(date, now);
  return 0 <= diff && diff < n;
}

export function withinTheLastNDays(n: number, date: Date): boolean {
  // normalize dates first
  const now = removeTime(new Date(Date.now()));
  date = removeTime(date);

  const diff = differenceInDays(now, date);
  return 0 <= diff && diff < n;
}

export function withinTheNext7Days(date: Date): boolean {
  return withinTheNextNDays(7, date);
}

function startOfNextWeek(date: Date, weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6) {
  return dateFnsStartOfWeek(addWeeks(date, 1), { weekStartsOn });
}

function convertDate(input: Date | string | number | undefined | null): Date | undefined {
  if (input === null || input === undefined) {
    return undefined;
  }

  if (typeof input === 'string' || typeof input === 'number') {
    const date = new Date(input);
    if (!isValid(date)) {
      return undefined;
    }
    return date;
  } else if (input instanceof Date) {
    return input;
  }
  return undefined;
}

function convertDateOrThrow(input: Date | string | number): Date;
function convertDateOrThrow(input: Date | string | number | undefined | null): Date | undefined;
function convertDateOrThrow(input: Date | string | number | undefined | null): Date | undefined {
  if (input === null || input === undefined) {
    return undefined;
  }

  if (typeof input === 'string' || typeof input === 'number') {
    const date = new Date(input);
    if (!isValid(date)) {
      throw new Error(`Cannot convert string to date: ${input}`);
    }
    return date;
  } else if (input instanceof Date) {
    return input;
  }
  throw new Error(`Cannot convert type "${typeof input}" to date! (input: "${input}")`);
}

function isEqual(dateLeft: number | Date | null | undefined, dateRight: number | Date | null | undefined) {
  if (dateLeft === undefined || dateRight === undefined) {
    return dateLeft === dateRight;
  }
  if (dateLeft === null || dateRight === null) {
    return dateLeft === dateRight;
  }
  return _isEqual(dateLeft, dateRight);
}

function compareAsc(dateLeft: string | number | Date, dateRight: string | number | Date) {
  return _compareAsc(convertDateOrThrow(dateLeft), convertDateOrThrow(dateRight));
}

function compareDesc(dateLeft: string | number | Date, dateRight: string | number | Date) {
  return _compareDesc(convertDateOrThrow(dateLeft), convertDateOrThrow(dateRight));
}

function getWeekNumbersOfMonth(options: { date: string | number | Date; weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 }) {
  const date = convertDateOrThrow(options.date);

  // TODO: Ben: choose correct local based on user language/ country/ ???
  const weekNumbers: number[] = [];
  const numWeeksInMonth = getWeeksInMonth(date, { weekStartsOn: options.weekStartsOn, locale: dateFnsLocales.de });
  let indexDate = startOfMonth(date);

  for (let i = 0; i < numWeeksInMonth; i++) {
    weekNumbers.push(getWeek(indexDate, { weekStartsOn: options.weekStartsOn, locale: dateFnsLocales.de }));
    indexDate = addWeeks(indexDate, 1);
  }

  return weekNumbers;
}

function isAfterOrEqual(date: number | Date, dateToCompare: number | Date) {
  return isAfter(date, dateToCompare) || _isEqual(date, dateToCompare);
}

function isBeforeOrEqual(date: number | Date, dateToCompare: number | Date) {
  return isBefore(date, dateToCompare) || _isEqual(date, dateToCompare);
}

export {
  addHours,
  addDays,
  formatDate,
  formatISO,
  formatDateDistance,
  formatDateDistanceStrict,
  formatDateDistanceToNowStrict,
  formatDateRelative,
  isThisWeek,
  isToday,
  isTomorrow,
  isYesterday,
  isSameDay,
  isSameMonth,
  isSameYear,
  isThisYear,
  tomorrowMorning,
  endOfDay,
  endOfWeek,
  endOfMonth,
  endOfYear,
  startOfTomorrow,
  startOfDay,
  startOfWeek,
  startOfYear,
  startOfNextWeek,
  parseISO,
  convertDate,
  convertDateOrThrow,
  addMinutes,
  subDays,
  subYears,
  getDay,
  addWeeks,
  addMonths,
  addYears,
  subHours,
  subMonths,
  subWeeks,
  subMinutes,
  isAfter,
  compareAsc,
  compareDesc,
  isBefore,
  subSeconds,
  addSeconds,
  addMilliseconds,
  setMilliseconds,
  DateFnsLocale,
  isEqual,
  getWeekNumbersOfMonth,
  lastDayOfMonth,
  isAfterOrEqual,
  isBeforeOrEqual,
  subMilliseconds,
  removeTime,
  parse,
  setDate,
  setMonth,
  isValid,
  startOfISOWeek,
  nextMonday,
  nextTuesday,
  nextWednesday,
  nextThursday,
  nextFriday,
  nextSaturday,
  nextSunday,
  isFuture,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
  isSunday,
  isFirstDayOfMonth,
  isLastDayOfMonth,
  startOfMonth,
};
