import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { TimeFormat } from '@t5s/shared/gql';
import { formatTime } from './format-time';
import { getDateTimeFormats } from './formats';
import { isApplicableI18n } from './is-applicable-i18n';
import { normalizeDate } from './normalize-date';

/** Formats a time range, for two dates on the same day. */
export function formatTimeRange(
  i18n: SharedI18n,
  dateLeft: string | Date,
  dateRight: string | Date,
): string | undefined {
  if (!dateLeft || !dateRight || !isApplicableI18n(i18n)) {
    return undefined;
  }

  dateLeft = normalizeDate(dateLeft);
  dateRight = normalizeDate(dateRight);
  const dateTimeFormats = getDateTimeFormats(i18n);

  const { timeFormat } = i18n;
  if (timeFormat === TimeFormat.HOURS_12) {
    // merge times into one if same AM/PM partition and 12h time format
    const hoursLeft = dateLeft.getHours() < 12;
    const hoursRight = dateRight.getHours() < 12;
    if (hoursLeft === hoursRight) {
      const formattedLeft = formatTime(dateLeft, dateTimeFormats.time.standalone, i18n);
      const formattedRight = formatTime(dateRight, dateTimeFormats.time.short, i18n);
      return `${formattedLeft} – ${formattedRight}`;
    } else {
      const formattedLeft = formatTime(dateLeft, dateTimeFormats.time.short, i18n);
      const formattedRight = formatTime(dateRight, dateTimeFormats.time.short, i18n);
      return `${formattedLeft} – ${formattedRight}`;
    }
  } else {
    const formattedLeft = formatTime(dateLeft, dateTimeFormats.time.standalone, i18n);
    const formattedRight = formatTime(dateRight, dateTimeFormats.time.short, i18n);
    return `${formattedLeft} – ${formattedRight}`;
  }
}
