import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { GqlNotificationService, SharedApolloClient } from '@t5s/shared/gql-services';
import { AllNotificationsModule } from './all';
import { DirectNotificationsModule } from './direct';
import { NotificationEffects } from './notification.effects';
import { NotificationNavigation } from './notification.navigation';

@NgModule({
  imports: [AllNotificationsModule, DirectNotificationsModule, EffectsModule.forFeature([NotificationEffects])],
  providers: [
    NotificationNavigation,
    {
      provide: GqlNotificationService,
      useFactory: (apollo: SharedApolloClient) => new GqlNotificationService(apollo),
      deps: [SharedApolloClient],
    },
  ],
})
export class NotificationBusinessLogicModule {}
