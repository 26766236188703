import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { StateHydrationService } from '@t5s/mobile-client/service/state-hydration';
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';
import { ActiveUserSessionHydrationActions } from './active-user-session-hydration.actions';
import { ActiveUserSessionState, activeUserSessionStateKey } from './active-user-session.reducer';
import { selectActiveUserSessionFeatureState } from './active-user-session.selectors';

@Injectable()
export class ActiveUserSessionHydrationEffects {
  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly stateHydrationService: StateHydrationService<ActiveUserSessionState>,
  ) {}

  readonly persistStateIfUserAvailable$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ActiveUserSessionHydrationActions.hydrateStateSuccess,
          ActiveUserSessionHydrationActions.hydrateStateNotAvailable,
          ActiveUserSessionHydrationActions.hydrateStateException,
        ),
        switchMap(() => this.store$.select(selectActiveUserSessionFeatureState)),
        filter(({ activeUser }) => !!activeUser),
        debounceTime(1000),
        switchMap((state) => this.stateHydrationService.persistState(activeUserSessionStateKey, state)),
      ),
    { dispatch: false },
  );

  readonly hydrateState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActiveUserSessionHydrationActions.hydrateState),
      switchMap(() =>
        this.stateHydrationService.retrieveState(activeUserSessionStateKey).pipe(
          map((state) => {
            if (!state) {
              return ActiveUserSessionHydrationActions.hydrateStateNotAvailable();
            }

            return ActiveUserSessionHydrationActions.hydrateStateSuccess({ state });
          }),
          catchErrorDispatch(ActiveUserSessionHydrationActions.hydrateStateException),
        ),
      ),
    ),
  );

  readonly hydrateStateExceptionClear$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ActiveUserSessionHydrationActions.hydrateStateException),
        switchMap(() => this.stateHydrationService.clearState(activeUserSessionStateKey)),
      ),
    { dispatch: false },
  );

  ngrxOnInitEffects(): Action {
    return ActiveUserSessionHydrationActions.hydrateState();
  }
}
