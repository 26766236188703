import { createReducer, on } from '@ngrx/store';
import { I18nObject } from '@t5s/mobile-client/i18n/common';
import { InternationalizationDefaults } from '@t5s/shared/util/i18n';
import { ApplicationI18nActions } from './application-i18n.actions';

export const applicationI18nStateKey = 'applicationI18n';
export interface ApplicationI18nState extends I18nObject {}

const initialState: ApplicationI18nState = {
  language: InternationalizationDefaults.Language,
  timeFormat: InternationalizationDefaults.TimeFormat,
  dateFormat: InternationalizationDefaults.DateFormat,
  firstWeekday: InternationalizationDefaults.FirstWeekday,
  decimalFormat: InternationalizationDefaults.DecimalFormat,
};

export const applicationI18nReducer = createReducer(
  initialState,
  on(ApplicationI18nActions.setApplicationI18n, (state, { type, ...props }) => Object.assign({}, state, props)), // Use Object.assign to not override with undefined values from props
);
