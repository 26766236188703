const IS_VALID_SAFE_KEY_REGEX = /^[a-zA-Z0-9_\-.]+$/;
import { InvalidStorageKeyException } from '@t5s/mobile-client/value-object/storage';

export function validateStorageKey(key: string) {
  const isValidSafeKey = (key: string) => IS_VALID_SAFE_KEY_REGEX.test(key);

  if (!isValidSafeKey(key)) {
    throw new InvalidStorageKeyException({ key });
  }
}

export function serializeValue(value: string): string {
  return btoa(unescape(encodeURIComponent(value)));
}

export function deserializeValue(value: string): string {
  return decodeURIComponent(escape(atob(value)));
}
