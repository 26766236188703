import { IsolatedPosition, IsolatedPositionable } from '@t5s/shared/util/isolated-position';

export const positionSortComparer = (a1: IsolatedPositionable, a2: IsolatedPositionable) =>
  IsolatedPosition.sortCompare(a1, a2);

interface IsolatedPositionEdge {
  node: IsolatedPositionable;
}

export const edgePositionSortComparer = (a1: IsolatedPositionEdge, a2: IsolatedPositionEdge) =>
  positionSortComparer(a1.node, a2.node);
