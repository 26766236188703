import gql from 'graphql-tag';
import { FlimValImageDtoNoNestingFragment, ThumbnailDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const FlimValImageFragment = gql`
  fragment FlimValImageFragment on FlimValImageDto {
    ...FlimValImageDtoNoNesting
    author {
      id
      primaryName
    }
    thumbnail {
      ...ThumbnailDtoNoNesting
    }
  }
  ${FlimValImageDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
