import { NgModule } from '@angular/core';
import { SwiperComponentModule } from '@t5s/mobile-client/ui/component/swiper';
import { ViewCommonModule } from '@t5s/mobile-client/ui/view/common';
import { FileAttachmentPreviewViewComponent } from './file-attachment-preview-view.component';

const components = [FileAttachmentPreviewViewComponent];

@NgModule({
  imports: [ViewCommonModule, SwiperComponentModule],
  declarations: components,
  exports: components,
})
export class FileAttachmentPreviewViewModule {}
