import { ChatActivityUnionType } from '@t5s/shared/gql';
import { clusterItemIsChatActivityBlock, ClusterItem } from './chat-activity-clustering.types';
import {
  ChatConversationLocalMessageActivityStatus,
  ChatConversationLocalMessageActivityValObj,
} from './chat-conversation-local-activity.valobj';

export type ChatConversationChatActivityUnionType = ChatActivityUnionType | ChatConversationLocalMessageActivityValObj;

export function isChatConversationLocalMessageActivity(
  input: ChatConversationChatActivityUnionType,
): input is ChatConversationLocalMessageActivityValObj {
  return !!(input as ChatConversationLocalMessageActivityValObj).localId;
}

function trackChatConversationActivity(_: number, activity: ChatConversationChatActivityUnionType): string {
  if (isChatConversationLocalMessageActivity(activity)) {
    return activity.localId;
  }

  return `${activity.id}`;
}

export function trackChatConversationClusterItem(_: number, clusterItem: ClusterItem) {
  if (clusterItemIsChatActivityBlock(clusterItem)) {
    return clusterItem.activities[clusterItem.activities.length - 1].id; // DO NOT CHANGE OR QUESTION THIS
  }

  return trackChatConversationActivity(_, clusterItem);
}

export function chatConversationActivityCanBeRetried(activity?: ChatConversationChatActivityUnionType) {
  // only local activities can be retried
  if (!activity || !isChatConversationLocalMessageActivity(activity)) {
    return false;
  }

  return activity.status === ChatConversationLocalMessageActivityStatus.ERRORED;
}
