import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { fontWeight } from '@t5s/client/ui/style/common';

@Injectable({ providedIn: 'root' })
export class ClientFontService {
  private readonly fontWeights = Object.values(fontWeight);

  private readonly invisibleContainer: Element;
  constructor(@Inject(DOCUMENT) private readonly document: Document) {
    const { body } = this.document;

    const invisibleContainer = this.document.createElement('div');
    invisibleContainer.setAttribute('style', 'opacity: 0; width: 0; height: 0');
    body.appendChild(invisibleContainer);

    this.invisibleContainer = invisibleContainer;

    this.forcePreloadFonts();
  }

  /** Force browser to load all fonts by applying them to invisible elements. */
  private forcePreloadFonts() {
    // Load "Noto Color Emoji" as this has to be loaded from URL
    // this.addContainerChildSpan(`font-family: "Noto Color Emoji"`);

    // Load iawrite-mono as this has to be loaded from URL
    // Force load italic
    this.addContainerChildSpan(`font-family: iawriter-mono`);
    this.addContainerChildSpan(`font-family: iawriter-mono; font-style: italic`);

    // Force load all font weights
    for (const fontWeight of this.fontWeights) {
      this.addContainerChildSpan(`font-family: iawriter-mono; font-weight: ${fontWeight}`);
      this.addContainerChildSpan(`font-family: iawriter-mono; font-weight: ${fontWeight}; font-style: italic`);
    }
  }

  private addContainerChildSpan(withStyle: string) {
    const fontEl = this.document.createElement('span');
    fontEl.setAttribute('style', withStyle);

    this.invisibleContainer.appendChild(fontEl);
  }
}
