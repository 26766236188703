import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NetworkStatusState, networkStatusStateKey } from './network-status.reducer';

const selectNetworkStatusFeatureState = createFeatureSelector<NetworkStatusState>(networkStatusStateKey);

export const selectNetworkStatus = createSelector(selectNetworkStatusFeatureState, (state) => state.status);
export const selectNetworkConnected = createSelector(
  selectNetworkStatusFeatureState,
  (state) => state.status?.connected,
);
