import { ActiveUserProfileDto, UpdateActiveUserProfileInput, Upload } from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import { ActiveUserProfileFragment } from '../../fragments/active-user';
import { updateActiveUserProfile, updateActiveUserProfilePicture } from '../../generated/graphql-mutations';
import { getActiveUserProfile } from '../../generated/graphql-queries';
import { GqlService } from '../../gql-service';

export class GqlActiveUserProfileService extends GqlService {
  getActiveUserProfile(): Observable<ActiveUserProfileDto> {
    return this.apollo.defaultQuery(getActiveUserProfile([ActiveUserProfileFragment]));
  }

  updateActiveUserProfile(
    input: UpdateActiveUserProfileInput,
    profilePicture?: Upload,
  ): Observable<ActiveUserProfileDto> {
    return this.apollo.defaultMutateFileUpload(
      updateActiveUserProfile(input, profilePicture, [ActiveUserProfileFragment]),
    );
  }

  updateActiveUserProfilePicture(profilePicture: Upload): Observable<ActiveUserProfileDto> {
    return this.apollo.defaultMutateFileUpload(
      updateActiveUserProfilePicture(profilePicture, [ActiveUserProfileFragment]),
    );
  }
}
