import gql from 'graphql-tag';

export const ReactionDtoFragment = gql`
  fragment ReactionDtoFragment on ReactionDto {
    emoji
    users {
      id
      primaryName
      profilePicture {
        ...ThumbnailDtoNoNesting
      }
    }
  }
`;
