import gql from 'graphql-tag';
import { OrganizationSmtpSettingsDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const OrganizationSmtpSettingsDtoFragment = gql`
  fragment OrganizationSmtpSettingsDto on OrganizationSmtpSettingsDto {
    ...OrganizationSmtpSettingsDtoNoNesting
    fallbackSmtpAccountIds
  }
  ${OrganizationSmtpSettingsDtoNoNestingFragment}
`;
