import { Provider } from '@angular/core';
import { HttpLink, HttpLinkHandler, Options } from 'apollo-angular-link-http';
import { Operation } from 'apollo-link';
import { GRAPHQL_ENDPOINT } from '../../injection-tokens';

const httpLinkHandlerFactory = (httpLink: HttpLink, uri: string): HttpLinkHandler => {
  const httpOpts: Options = { uri: ({ operationName }: Operation) => `${uri}/${operationName}` };
  return httpLink.create(httpOpts);
};

export const HTTP_LINK_PROVIDER: Provider = {
  provide: HttpLinkHandler,
  useFactory: httpLinkHandlerFactory,
  deps: [HttpLink, GRAPHQL_ENDPOINT],
};
