import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { RootActions } from '@t5s/mobile-client/business-logic/root';
import { DeviceMediaI18n as I18n } from '@t5s/mobile-client/i18n/device-media';
import { HardwareBackButtonObservable } from '@t5s/mobile-client/provider-token/hardware-back-button';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
import { CameraService } from '@t5s/mobile-client/service/camera';
import { DialogService } from '@t5s/mobile-client/service/dialog';
import { FilePickerService } from '@t5s/mobile-client/service/file-picker';
import { KeyboardService } from '@t5s/mobile-client/service/keyboard';
import { NativeSettingsService } from '@t5s/mobile-client/service/native-settings';
import { delay, filter, map, switchMap, tap } from 'rxjs/operators';
import { DeviceMediaActions } from './device-media.actions';
import { selectDeviceMediaPosition } from './device-media.selectors';

@Injectable()
export class DeviceMediaEffects {
  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly i18n$: I18nObjectObservable,
    private readonly backButton$: HardwareBackButtonObservable,
    private readonly cameraService: CameraService,
    private readonly dialogService: DialogService,
    private readonly filePickerService: FilePickerService,
    private readonly nativeSettingsService: NativeSettingsService,
    private readonly keyboardService: KeyboardService,
  ) {}

  readonly setSheetOpen$ = createEffect(() =>
    this.store$.select(selectDeviceMediaPosition).pipe(
      map((sheetPosition) => {
        return RootActions.setSheetPosition({ sheetPosition });
      }),
    ),
  );

  readonly androidGoBack$ = createEffect(() => this.backButton$.pipe(map(() => DeviceMediaActions.close())));

  // Photo
  readonly getPhoto$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceMediaActions.getPhoto),
      switchMap(({ options }) =>
        this.cameraService.getPhoto(options).pipe(
          map((photo) => {
            if (photo === 'cancel') {
              return DeviceMediaActions.getPhotoCancel();
            } else if (photo === 'denied') {
              return DeviceMediaActions.getPhotoDenied();
            }
            return DeviceMediaActions.getPhotoSuccess({ photo });
          }),
          catchErrorDispatch(DeviceMediaActions.getPhotoException),
        ),
      ),
    ),
  );

  readonly showSettingsDialogOnPhotoDenied$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceMediaActions.getPhotoDenied),
        concatLatestFrom(() => this.i18n$),
        switchMap(([_, { i18n }]) =>
          this.dialogService
            .confirm({
              message: I18n.translate(i18n, I18n.key.permissionDeniedDialog.photo.message),
              title: I18n.translate(i18n, I18n.key.permissionDeniedDialog.photo.title),
              cancelButtonTitle: I18n.translate(i18n, I18n.key.permissionDeniedDialog.photo.cancelButtonTitle),
              okButtonTitle: I18n.translate(i18n, I18n.key.permissionDeniedDialog.photo.okButtonTitle),
            })
            .pipe(
              filter(({ value: confirmed }) => confirmed),
              tap(() => this.keyboardService.hide()),
              delay(200),
              switchMap(() => this.nativeSettingsService.openAppSettings()),
            ),
        ),
      ),
    { dispatch: false },
  );

  // Camera
  readonly getCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceMediaActions.getCamera),
      switchMap(({ options }) =>
        this.cameraService.getCamera(options).pipe(
          map((camera) => {
            if (camera === 'cancel') {
              return DeviceMediaActions.getCameraCancel();
            } else if (camera === 'denied') {
              return DeviceMediaActions.getCameraDenied();
            }
            return DeviceMediaActions.getCameraSuccess({ camera });
          }),
          catchErrorDispatch(DeviceMediaActions.getCameraException),
        ),
      ),
    ),
  );

  readonly showSettingsDialogOnCameraDenied$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceMediaActions.getCameraDenied),
        concatLatestFrom(() => this.i18n$),
        switchMap(([_, { i18n }]) =>
          this.dialogService
            .confirm({
              message: I18n.translate(i18n, I18n.key.permissionDeniedDialog.camera.message),
              title: I18n.translate(i18n, I18n.key.permissionDeniedDialog.camera.title),
              cancelButtonTitle: I18n.translate(i18n, I18n.key.permissionDeniedDialog.camera.cancelButtonTitle),
              okButtonTitle: I18n.translate(i18n, I18n.key.permissionDeniedDialog.camera.okButtonTitle),
            })
            .pipe(
              filter(({ value: confirmed }) => confirmed),
              tap(() => this.keyboardService.hide()),
              delay(200),
              switchMap(() => this.nativeSettingsService.openAppSettings()),
            ),
        ),
      ),
    { dispatch: false },
  );

  // Document
  readonly getDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DeviceMediaActions.getDocument),
      switchMap(() =>
        this.filePickerService.pickFile().pipe(
          map((document) =>
            document ? DeviceMediaActions.getDocumentSuccess({ document }) : DeviceMediaActions.getDocumentCancel(),
          ),
          catchErrorDispatch(DeviceMediaActions.getDocumentException),
        ),
      ),
    ),
  );

  // Keyboard
  readonly closeKeyboard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DeviceMediaActions.getCamera, DeviceMediaActions.getPhoto, DeviceMediaActions.getDocument),
        tap(() => this.keyboardService.hide()),
      ),
    { dispatch: false },
  );
}
