import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  exactSize,
  flex1Horizontal,
  flexCenter,
  flexCenterHorizontal,
  flexColumn,
  flexRow,
  fullWidth,
  padding,
  px,
} from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { ReactionDetailsI18n } from '@t5s/mobile-client/i18n/reaction-details';
import { ActiveUserIdObservable } from '@t5s/mobile-client/provider-token/active-user';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
import { I18nState, RxI18nComponent } from '@t5s/mobile-client/ui/component/common';
import { UserProfileDto } from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface ReactionDetailState extends I18nState {
  emoji?: string;
  user?: UserProfileDto;
  activeUserId: number;
}

@Component({
  selector: 't5s-reaction-detail',
  template: `
    <div [class]="containerClass" [t5sTouchActive]="'background: rgba(0, 0, 0, 0.07)'">
      <ng-container *ngIf="state$ | push as state">
        <!-- Emoji -->
        <div [class]="emojiClass"> {{ state.emoji }} </div>

        <div [class]="mainContentClass">
          <!-- Avatar -->
          <t5s-avatar [size]="37" [user]="state.user" [style.margin-right.px]="12"></t5s-avatar>

          <div [class]="nameClass">
            <!-- Primary name -->
            <t5s-single-line-text [font]="Font.medium16px" [fgColor]="Color.darkest">
              {{ state.user?.primaryName }}
            </t5s-single-line-text>

            <!-- Secondary name -->
            <t5s-single-line-text [font]="Font.regular14px" [fgColor]="Color.darker">
              {{ state.user?.secondaryName }}
            </t5s-single-line-text>
          </div>
        </div>
      </ng-container>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactionDetailComponent extends RxI18nComponent<ReactionDetailState> {
  readonly I18n = ReactionDetailsI18n;

  constructor(i18n$: I18nObjectObservable, activeUserId$: ActiveUserIdObservable) {
    super(i18n$);
    this.connect(activeUserId$.pipe(map((activeUserId) => ({ activeUserId }))));
  }

  @Input() set emoji(emoji: string | Observable<string>) {
    this.setProperty('emoji', emoji);
  }

  @Input() set user(user: UserProfileDto | Observable<UserProfileDto>) {
    this.setProperty('user', user);
  }

  readonly containerClass = tss({
    ...fullWidth,
    padding: padding(10, 16),
    ...flexRow,
  });

  readonly emojiClass = tss({
    ...exactSize(37),
    ...flexCenter,
    fontSize: 28,
    lineHeight: 28,
    marginRight: px(16),
  });

  readonly mainContentClass = tss({
    ...flex1Horizontal,
    ...flexRow,
  });

  readonly nameClass = tss({
    ...flex1Horizontal,
    ...flexCenterHorizontal,
    ...flexColumn,
  });
}
