import { Component, ViewChild } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { PushablePage } from '@t5s/mobile-client/page/common';
import { DebugConsolePageComponent } from './debug-console-page.component';

// OnPush had to be disabled with Angular 14, needs more investigation to be reactivated
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
@Component({
  selector: 't5s-debug-console-root-page',
  template: `<ion-nav #myNav [root]="rootPage" animated="true"></ion-nav>`,
})
export class DebugConsoleRootPageComponent extends PushablePage {
  @ViewChild('myNav') readonly ionNav!: IonNav;

  readonly rootPage = DebugConsolePageComponent;
}
