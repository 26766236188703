import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FontSet, getFontStyle, textTruncate, variable } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent } from '@t5s/mobile-client/ui/component/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface SingleLineTextState {
  font?: FontSet;
  fontSize?: number;
  lineHeight?: number;
  fontWeight?: number;

  centered: boolean;
  fgColor?: string;
  textOverflow?: 'ellipsis' | 'initial';
}

export const SINGLE_LINE_TEXT_FG_COLOR = '--t5s-single-line-text-fg-color';

@Component({
  selector: 't5s-single-line-text',
  template: ` <div [class]="textClass$ | push"> <ng-content></ng-content> </div> `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleLineTextComponent extends RxComponent<SingleLineTextState> {
  constructor() {
    super();
    this.set({
      centered: false,
    });
  }

  @Input() set font(font: FontSet | Observable<FontSet>) {
    this.setProperty('font', font);
  }

  @Input() set fontSize(fontSize: number | Observable<number>) {
    this.setProperty('fontSize', fontSize);
  }

  @Input() set lineHeight(lineHeight: number | Observable<number>) {
    this.setProperty('lineHeight', lineHeight);
  }

  @Input() set fontWeight(fontWeight: number | Observable<number>) {
    this.setProperty('fontWeight', fontWeight);
  }

  @Input() set fgColor(fgColor: string | Observable<string>) {
    this.setProperty('fgColor', fgColor);
  }

  @Input() set centered(centered: boolean | Observable<boolean>) {
    this.setProperty('centered', centered);
  }

  @Input() set textOverflow(textOverflow: 'ellipsis' | 'initial' | Observable<'ellipsis' | 'initial'>) {
    this.setProperty('textOverflow', textOverflow);
  }

  readonly textClass$ = this.state$.pipe(
    map(({ font, fontSize, lineHeight, fontWeight, fgColor, centered, textOverflow }) => {
      fontSize = fontSize ?? font?.fontSize ?? 0;
      lineHeight = lineHeight ?? font?.lineHeight ?? 0;
      fontWeight = fontWeight ?? font?.fontWeight ?? 0;

      return tss({
        ...textTruncate,
        textOverflow: textOverflow ?? 'ellipsis',
        ...getFontStyle({ fontSize, lineHeight, fontWeight }),
        color: variable(SINGLE_LINE_TEXT_FG_COLOR, fgColor ?? undefined),
        ...(centered ? { textAlign: 'center', display: 'block' } : {}),
      });
    }),
  );
}
