import gql from 'graphql-tag';
import {
  ActiveUserCalendarPrivateUrlShareDtoNoNestingFragment,
  ActiveUserCalendarPublicUrlShareDtoNoNestingFragment,
  ActiveUserCalendarStaticSharesDtoNoNestingFragment,
  ActiveUserCalendarSubscriptionDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const ActiveUserCalendarStaticSharesDto = gql`
  fragment ActiveUserCalendarStaticSharesDtoFragment on ActiveUserCalendarStaticSharesDto {
    ...ActiveUserCalendarStaticSharesDtoNoNesting

    privateUrlShare {
      ...ActiveUserCalendarPrivateUrlShareDtoNoNesting
      calendarSubscriptionIds
      calendarSubscriptions {
        ...ActiveUserCalendarSubscriptionDtoNoNesting
      }
    }

    publicUrlShare {
      ...ActiveUserCalendarPublicUrlShareDtoNoNesting
      calendarSubscriptionIds
      calendarSubscriptions {
        ...ActiveUserCalendarSubscriptionDtoNoNesting
      }
    }
  }
  ${ActiveUserCalendarStaticSharesDtoNoNestingFragment}
  ${ActiveUserCalendarPrivateUrlShareDtoNoNestingFragment}
  ${ActiveUserCalendarPublicUrlShareDtoNoNestingFragment}
  ${ActiveUserCalendarSubscriptionDtoNoNestingFragment}
`;
