import { tss, TssStylesObject } from '@t5s/client/util/tss';
import { ThemeColorCssVariableName } from './theme.constants';
import { Theme, ThemeColorObject } from './theme.interface';

export function getCssVariableForThemeColor(key: keyof ThemeColorObject): ThemeColorCssVariableName {
  switch (key) {
    case 'lightest':
      return ThemeColorCssVariableName.LIGHTEST;
    case 'lighter':
      return ThemeColorCssVariableName.LIGHTER;
    case 'mediumlight':
      return ThemeColorCssVariableName.MEDIUMLIGHT;
    case 'light':
      return ThemeColorCssVariableName.LIGHT;
    case 'light68':
      return ThemeColorCssVariableName.LIGHT68;
    case 'dark':
      return ThemeColorCssVariableName.DARK;
    case 'darker':
      return ThemeColorCssVariableName.DARKER;
    case 'darkest':
      return ThemeColorCssVariableName.DARKEST;
    case 'primary':
      return ThemeColorCssVariableName.PRIMARY;
    case 'primary10':
      return ThemeColorCssVariableName.PRIMARY10;
    case 'primary20':
      return ThemeColorCssVariableName.PRIMARY20;
    case 'primarydark10':
      return ThemeColorCssVariableName.PRIMARYDARK10;
    case 'primarydark20':
      return ThemeColorCssVariableName.PRIMARYDARK20;
    case 'danger':
      return ThemeColorCssVariableName.DANGER;
    case 'danger10':
      return ThemeColorCssVariableName.DANGER10;
    case 'danger20':
      return ThemeColorCssVariableName.DANGER20;
    case 'success':
      return ThemeColorCssVariableName.SUCCESS;
    case 'grayBDC1C9':
      return ThemeColorCssVariableName.GRAYBDC1C9;
    case 'gray9197A3':
      return ThemeColorCssVariableName.GRAY9197A3;
  }
}

export function getThemeClass(theme: Theme): string {
  const themeClass: TssStylesObject = {
    color: theme.color.darkest,
  };

  for (const color of Object.entries(theme.color)) {
    const [key, value] = color;
    const varname = getCssVariableForThemeColor(key as keyof ThemeColorObject);
    themeClass[varname] = value;
  }

  return tss(themeClass);
}
