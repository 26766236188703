import { Injectable } from '@angular/core';
import {
  AnimationOptions,
  BackgroundColorOptions,
  SetOverlaysWebViewOptions,
  StatusBar,
  Style,
  StyleOptions,
} from '@capacitor/status-bar';
import { PlatformObservable } from '@t5s/mobile-client/provider-token/device';
import { defer, EMPTY, from, merge } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { color } from '@t5s/shared/ui/color';

@Injectable()
export class StatusBarService {
  constructor(private readonly platform$: PlatformObservable) {}

  private setStyle(options: StyleOptions) {
    return this.platform$.pipe(
      switchMap(({ platform }) => (platform !== 'web' ? defer(() => from(StatusBar.setStyle(options))) : EMPTY)),
    );
  }

  setColorDark() {
    return this.setStyle({ style: Style.Light });
  }

  setColorLight() {
    return this.setStyle({ style: Style.Dark });
  }

  setStyleLight() {
    return merge(this.setStyle({ style: Style.Light }), this.setBackgroundColor({ color: color.white }));
  }

  setStyleDark() {
    return merge(this.setStyle({ style: Style.Dark }), this.setBackgroundColor({ color: color.black }));
  }

  /**
  setStyleDefault() {
    return this.setStyle({ style: Style.Default });
  }
  */

  setBackgroundColor(options: BackgroundColorOptions) {
    return this.platform$.pipe(
      switchMap(({ platform }) =>
        platform === 'android' ? defer(() => from(StatusBar.setBackgroundColor(options))) : EMPTY,
      ),
    );
  }

  setOverlaysWebView(options: SetOverlaysWebViewOptions) {
    return this.platform$.pipe(
      switchMap(({ platform }) =>
        platform === 'android' ? defer(() => from(StatusBar.setOverlaysWebView(options))) : EMPTY,
      ),
    );
  }

  show(options?: AnimationOptions) {
    return this.platform$.pipe(
      switchMap(({ platform }) => (platform === 'web' ? EMPTY : defer(() => from(StatusBar.show(options))))),
    );
  }

  hide(options?: AnimationOptions) {
    return this.platform$.pipe(
      switchMap(({ platform }) => (platform === 'web' ? EMPTY : defer(() => from(StatusBar.hide(options))))),
    );
  }

  getInfo() {
    return this.platform$.pipe(
      switchMap(({ platform }) => (platform === 'web' ? EMPTY : defer(() => from(StatusBar.getInfo())))),
    );
  }
}
