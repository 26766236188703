import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { BrowserService } from '@t5s/mobile-client/service/browser';
import {
  GqlBlabItemActivityReactionService,
  GqlBlabItemActivityReplyService,
  GqlBlabItemActivityService,
} from '@t5s/shared/gql-services';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { BlabItemActivityThreadActions } from './blab-item-activity-thread.actions';

const REPLIES_PAGE_SIZE = 50;

@Injectable()
export class BlabItemActivityThreadEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly gqlBlabItemActivityService: GqlBlabItemActivityService,
    private readonly qqlBlabItemActivityReactionService: GqlBlabItemActivityReactionService,
    private readonly gqlBlabItemActivityRelpyService: GqlBlabItemActivityReplyService,
    private readonly browserService: BrowserService,
  ) {}

  readonly loadBlabItemActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.loadBlabItemActivity),
      mergeMap(({ blabItemActivityId }) =>
        this.gqlBlabItemActivityService.getSingleBlabItemActivity({ id: blabItemActivityId }).pipe(
          map((blabItemActivity) =>
            BlabItemActivityThreadActions.loadBlabItemActivitySuccess({ blabItemActivityId, blabItemActivity }),
          ),
          catchErrorDispatch(BlabItemActivityThreadActions.loadBlabItemActivityException, { blabItemActivityId }),
        ),
      ),
    ),
  );

  readonly reloadBlabItemActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.reloadBlabItemActivity),
      mergeMap(({ blabItemActivityId }) =>
        this.gqlBlabItemActivityService.getSingleBlabItemActivity({ id: blabItemActivityId }).pipe(
          map((blabItemActivity) =>
            BlabItemActivityThreadActions.loadBlabItemActivitySuccess({ blabItemActivityId, blabItemActivity }),
          ),
          catchErrorDispatch(BlabItemActivityThreadActions.loadBlabItemActivityException, { blabItemActivityId }),
        ),
      ),
    ),
  );

  readonly loadActivityReplies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.loadActivityReplies),
      mergeMap(({ blabItemActivityId }) =>
        this.gqlBlabItemActivityRelpyService
          .getBlabItemActivityReplyConnection(REPLIES_PAGE_SIZE, { activityId: blabItemActivityId })
          .pipe(
            map(({ pageInfo, edges }) =>
              BlabItemActivityThreadActions.loadActivityRepliesSuccess({ blabItemActivityId, pageInfo, edges }),
            ),
            catchErrorDispatch(BlabItemActivityThreadActions.loadActivityRepliesException, { blabItemActivityId }),
          ),
      ),
    ),
  );

  readonly reloadActivityReplies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.reloadActivityReplies),
      mergeMap(({ blabItemActivityId }) =>
        this.gqlBlabItemActivityRelpyService
          .getBlabItemActivityReplyConnection(REPLIES_PAGE_SIZE, { activityId: blabItemActivityId })
          .pipe(
            map(({ pageInfo, edges }) =>
              BlabItemActivityThreadActions.loadActivityRepliesSuccess({ blabItemActivityId, pageInfo, edges }),
            ),
            catchErrorDispatch(BlabItemActivityThreadActions.loadActivityRepliesException),
          ),
      ),
    ),
  );

  readonly openUrl$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BlabItemActivityThreadActions.openUrl),
        switchMap(({ url }) => this.browserService.open({ url })),
      ),
    { dispatch: false },
  );

  // BlabItem activity reaction
  readonly addBlabItemActivityReaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.addBlabItemActivityReaction),
      switchMap(({ emoji, activityId }) =>
        this.qqlBlabItemActivityReactionService.addBlabItemActivityReaction({ emoji, activityId }).pipe(
          map((activity) => BlabItemActivityThreadActions.addBlabItemActivityReactionSuccess({ activity, emoji })),
          catchErrorDispatch(BlabItemActivityThreadActions.addBlabItemActivityReactionException),
        ),
      ),
    ),
  );

  readonly removeBlabItemActivityReaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.removeBlabItemActivityReaction),
      switchMap(({ emoji, activityId }) =>
        this.qqlBlabItemActivityReactionService.removeBlabItemActivityReaction({ emoji, activityId }).pipe(
          map((activity) => BlabItemActivityThreadActions.removeBlabItemActivityReactionSuccess({ activity, emoji })),
          catchErrorDispatch(BlabItemActivityThreadActions.removeBlabItemActivityReactionException),
        ),
      ),
    ),
  );

  // BlabItem activity reply reaction
  readonly addBlabItemActivityReplyReaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.addBlabItemActivityReplyReaction),
      switchMap(({ emoji, replyId, activity }) =>
        this.gqlBlabItemActivityRelpyService.addBlabItemActivityReplyReaction({ emoji, activityReplyId: replyId }).pipe(
          map((reply) =>
            BlabItemActivityThreadActions.addBlabItemActivityReplyReactionSuccess({ activity, emoji, reply }),
          ),
          catchErrorDispatch(BlabItemActivityThreadActions.addBlabItemActivityReplyReactionException),
        ),
      ),
    ),
  );

  readonly removeBlabItemActivityReplyReaction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.removeBlabItemActivityReplyReaction),
      switchMap(({ emoji, replyId, activity }) =>
        this.gqlBlabItemActivityRelpyService
          .removeBlabItemActivityReplyReaction({ emoji, activityReplyId: replyId })
          .pipe(
            map((reply) =>
              BlabItemActivityThreadActions.removeBlabItemActivityReplyReactionSuccess({ activity, emoji, reply }),
            ),
            catchErrorDispatch(BlabItemActivityThreadActions.removeBlabItemActivityReplyReactionException),
          ),
      ),
    ),
  );
}
