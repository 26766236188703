import gql from 'graphql-tag';
import {
  ThumbnailDtoNoNestingFragment,
  WorkspaceInvitationDtoNoNestingFragment,
  WorkspaceUserDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const WorkspaceMembershipUnionTypeFragment = gql`
  fragment WorkspaceMembershipUnionTypeFragment on WorkspaceMembershipUnionType {
    ... on WorkspaceUserDto {
      ...WorkspaceUserDtoNoNesting
      profilePicture {
        ...ThumbnailDtoNoNesting
      }
    }
    ... on WorkspaceInvitationDto {
      ...WorkspaceInvitationDtoNoNesting
    }
  }
  ${WorkspaceUserDtoNoNestingFragment}
  ${WorkspaceInvitationDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
