import gql from 'graphql-tag';
import {
  SubscribeToExportBlabDefDataJobFailureEventNoNestingFragment,
  SubscribeToExportBlabDefDataJobProgressEventNoNestingFragment,
  SubscribeToExportBlabDefDataJobSuccessEventNoNestingFragment,
} from '../../generated/graphql-fragments';

export const BlabDefDataExportSubscriptionEventUnionTypeFragment = gql`
  fragment BlabDefDataExportSubscriptionEventUnionTypeFragment on SubscribeToExportBlabDefDataJobResult {
    ... on SubscribeToExportBlabDefDataJobProgressEvent {
      ...SubscribeToExportBlabDefDataJobProgressEventNoNesting
    }
    ... on SubscribeToExportBlabDefDataJobSuccessEvent {
      ...SubscribeToExportBlabDefDataJobSuccessEventNoNesting
    }
    ... on SubscribeToExportBlabDefDataJobFailureEvent {
      ...SubscribeToExportBlabDefDataJobFailureEventNoNesting
    }
  }
  ${SubscribeToExportBlabDefDataJobProgressEventNoNestingFragment}
  ${SubscribeToExportBlabDefDataJobSuccessEventNoNestingFragment}
  ${SubscribeToExportBlabDefDataJobFailureEventNoNestingFragment}
`;
