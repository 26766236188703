import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { ActiveUserIdObservable } from '@t5s/mobile-client/provider-token/active-user';
import { StateHydrationService } from '@t5s/mobile-client/service/state-hydration';
import { safeAreaDimensionsValid } from '@t5s/mobile-client/util/safe-area';
import { debounceTime, filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { SafeAreaStateHydrationPersistance } from './safe-area-hydration.state';
import { SafeAreaActions } from './safe-area.actions';
import { safeAreaStateKey } from './safe-area.reducer';
import { selectSafeAreaFeatureStateForPersistance } from './safe-area.selectors';

@Injectable()
export class SafeAreaHydrationEffects implements OnInitEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly activeUserId$: ActiveUserIdObservable,
    private readonly stateHydrationService: StateHydrationService<SafeAreaStateHydrationPersistance>,
  ) {}

  readonly persistValidDimensionsState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          SafeAreaActions.hydrateStateSuccess,
          SafeAreaActions.hydrateStateNotAvailable,
          SafeAreaActions.hydrateStateException,
        ),
        switchMap(() => this.store$.select(selectSafeAreaFeatureStateForPersistance)),
        filter((dimensions) => safeAreaDimensionsValid(dimensions)),
        debounceTime(500),
        mergeMap((state) => this.stateHydrationService.persistState(safeAreaStateKey, state)),
      ),
    { dispatch: false },
  );

  readonly hydrateState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SafeAreaActions.hydrateState),
      switchMap(() =>
        this.stateHydrationService.retrieveState(safeAreaStateKey).pipe(
          map((state) => {
            if (!state) {
              return SafeAreaActions.hydrateStateNotAvailable();
            }

            return SafeAreaActions.hydrateStateSuccess({ state });
          }),
          catchErrorDispatch(SafeAreaActions.hydrateStateException),
        ),
      ),
    ),
  );

  readonly hydrateStateExceptionClear$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SafeAreaActions.hydrateStateException),
        switchMap(() => this.stateHydrationService.clearState(safeAreaStateKey)),
      ),
    { dispatch: false },
  );

  ngrxOnInitEffects() {
    return SafeAreaActions.hydrateState();
  }
}
