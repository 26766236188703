import { ChatActivityDtoEdge, ConversationType, ConversationUnionType, PageInfo } from '@t5s/shared/gql';
import { ChatConversationLocalMessageActivityValObj } from './chat-conversation-local-activity.valobj';
import { ChatMessageInputAttachmentValobj } from './chat-message-input-attachment.valobj';

export enum ChatConversationLoadingState {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  EXCEPTION = 'EXCEPTION',
}

interface ChatConversationChatActivityState {
  chatActivities: ChatActivityDtoEdge[];
  pageInfo: PageInfo;
}

export enum ChatActivityLoadingState {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  RELOADING = 'RELOADING',
  EXCEPTION = 'EXCEPTION',
}

export interface ChatMessageInputState {
  content?: string;
  attachments?: ChatMessageInputAttachmentValobj[];
}

export interface ChatConversationClientModel {
  conversationId: number;

  loadingState: ChatConversationLoadingState;

  conversationType?: ConversationType;
  conversation?: ConversationUnionType;

  chatActivityLoadingState: ChatActivityLoadingState;
  chatActivity?: ChatConversationChatActivityState;

  messageInput: ChatMessageInputState;

  localMessages: ChatConversationLocalMessageActivityValObj[];
}
