/* eslint-disable no-bitwise */
import { ColorWeight } from '../types';
import { px } from './units.util';

function getRgbaFromHex(hex: string) {
  const [r, g, b, a] = hex.substr(1).match(/.{2}/g) as string[];
  return [r || 'FF', g || 'FF', b || 'FF', a || 'FF'];
}

export function getHexWithoutAlphaFromRgbaStr(rgbaStr: string) {
  const rgba = rgbaStr
    .replace(/rgba?\(|\)/g, '')
    .split(',')
    .map((val) => parseInt(val, 10));
  return getHexColorWithoutAlpha(rgba[0], rgba[1], rgba[2]);
}

function getHexFromInput(input: string | number): string {
  if (typeof input === 'number') {
    return Number(input).toString(16).padStart(2, '0').toUpperCase();
  }

  return input;
}

function getRgbaIntFromHex(hex: string) {
  const [r, g, b, a] = getRgbaFromHex(hex);
  return [parseInt(r, 16) ?? 0, parseInt(g, 16) ?? 0, parseInt(b, 16) ?? 0, parseInt(a, 16) ?? 0];
}

export function getHexColor(r: string | number, g: string | number, b: string | number, a: string | number = 'FF') {
  r = getHexFromInput(r);
  g = getHexFromInput(g);
  b = getHexFromInput(b);
  a = getHexFromInput(a);
  return `#${r}${g}${b}${a}`;
}

export function getHexColorWithoutAlpha(r: string | number, g: string | number, b: string | number) {
  r = getHexFromInput(r);
  g = getHexFromInput(g);
  b = getHexFromInput(b);
  return `#${r}${g}${b}`;
}

export function getColorWeight(hex: string): ColorWeight {
  const [r, g, b] = getRgbaIntFromHex(hex);
  const sum = r + g + b;
  const threshold = 255;

  if (sum < threshold) {
    return ColorWeight.DARK;
  }

  return ColorWeight.LIGHT;
}

export function setAlpha(hex: string, alpha: string | number): string {
  const [r, g, b] = getRgbaFromHex(hex);
  const a = getHexFromInput(alpha);
  return getHexColor(r, g, b, a);
}

export function darken(hex: string, percent: number): string {
  const [r, g, b, a] = getRgbaIntFromHex(hex);

  // We need to calculate the percentage of the distance the color value has towards the minimum (black = 0)
  return rgba(r - r * percent, g - g * percent, b - b * percent, a);
}

export function lighten(hex: string, percent: number): string {
  const [r, g, b, a] = getRgbaIntFromHex(hex);

  // We need to calculate the percentage of the distance the color value has towards the maximum (white = 255)
  return rgba(r + (255 - r) * percent, g + (255 - g) * percent, b + (255 - b) * percent, a);
}

export const rgb = (red: number, green: number, blue: number) => `rgb(${red}, ${green}, ${blue})`;

export function rgba(rgbaHex: string, alpha: number): string;
export function rgba(red: number, green: number, blue: number, alpha: number): string;
export function rgba(param1: number | string, param2: number, param3?: number, param4?: number): string {
  if (typeof param1 === 'string') {
    const [r, g, b] = getRgbaIntFromHex(param1);
    return `rgba(${r}, ${g}, ${b}, ${param2})`;
  }

  return `rgba(${param1}, ${param2}, ${param3}, ${param4})`;
}

export function blur(str: string | number) {
  return `blur(${typeof str === 'number' ? px(str) : str})`;
}
