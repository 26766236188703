import gql from 'graphql-tag';
import {
  MultiImageFlimValDtoNoNestingFragment,
  MultiImageFlimValFileReferenceDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';
import { FlimValImageFragment } from '../../flim-val-image';

export const MultiImageFlimValFragment = gql`
  fragment MultiImageFlimVal on MultiImageFlimValDto {
    ...MultiImageFlimValDtoNoNesting
    imageReferences {
      ...MultiImageFlimValFileReferenceDtoNoNesting
      image {
        ...FlimValImageFragment
      }
    }
  }
  ${MultiImageFlimValDtoNoNestingFragment}
  ${FlimValImageFragment}
  ${MultiImageFlimValFileReferenceDtoNoNestingFragment}
`;
