import gql from 'graphql-tag';
import { PageInfoFragment } from '../core/page-info.fragment';
import { UserActivityReplyDtoFragment } from './user-activity-reply-dto.fragment';

export const UserActivityReplyConnectionFragment = gql`
  fragment UserActivityReplyConnectionFragment on UserActivityReplyConnection {
    edges {
      cursor
      node {
        ...UserActivityReplyDtoFragment
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
  ${UserActivityReplyDtoFragment}
  ${PageInfoFragment}
`;
