import gql from 'graphql-tag';
import {
  BlabDefPreviewDtoNoNestingFragment,
  BlabItemPreviewDtoNoNestingFragment,
  WorkspacePreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const BlabItemSearchResultLightFragment = gql`
  fragment BlabItemSearchResultLight on BlabItemSearchResultDto {
    blabItem {
      ...BlabItemPreviewDtoNoNesting
      blabDef {
        ...BlabDefPreviewDtoNoNesting
        workspace {
          ...WorkspacePreviewDtoNoNesting
        }
      }
    }
  }
  ${BlabItemPreviewDtoNoNestingFragment}
  ${BlabDefPreviewDtoNoNestingFragment}
  ${WorkspacePreviewDtoNoNestingFragment}
`;
