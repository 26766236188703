import { Directive, ElementRef, Output } from '@angular/core';
import { hasInteractiveElemenInPath } from '@t5s/client/util/element';
import { RxDirective } from '@t5s/client/util/rx';
import { PlatformObservable } from '@t5s/mobile-client/provider-token/device';
import { HapticsService } from '@t5s/mobile-client/service/haptics';
import { fromTouchmoveEventOutsideThreshold } from '@t5s/mobile-client/util/touch-event';
import { fromEvent, merge, Observable, timer } from 'rxjs';
import { filter, map, share, switchMap, takeUntil } from 'rxjs/operators';
import { LONGPRESS_DURATION_MS } from './longpress.constants';

@Directive({
  selector: '[t5sDefaultLongpress]',
  exportAs: 't5sDefaultLongpress',
})
export class DefaultLongpressDirective extends RxDirective {
  constructor(
    readonly elementRef: ElementRef,
    readonly haptic: HapticsService,
    readonly platform$: PlatformObservable,
  ) {
    super();

    const touchstart$ = fromEvent<TouchEvent>(elementRef.nativeElement, 'touchstart', { passive: true }).pipe(
      filter((event) => !hasInteractiveElemenInPath(event, { hostElement: elementRef.nativeElement })),
    );

    const touchend$ = fromEvent<TouchEvent>(elementRef.nativeElement, 'touchend', { passive: true });

    // Prevent contextmenu default for Web
    this.hold(
      this.platform$.pipe(
        filter(({ platform }) => platform === 'web'),
        switchMap(() => fromEvent<Event>(elementRef.nativeElement, 'contextmenu')),
      ),
      (event) => event.preventDefault(),
    );

    this.t5sDefaultLongpress = touchstart$
      .pipe(
        switchMap((event) =>
          timer(LONGPRESS_DURATION_MS).pipe(
            map(() => event),
            // Haptic feedback
            switchMap((event) => this.haptic.longpressImpact().pipe(map(() => event))),
            takeUntil(
              merge(
                ...([
                  touchend$,
                  fromTouchmoveEventOutsideThreshold(elementRef.nativeElement, { touches: event.touches }),
                ].filter((obs) => !!obs) as Observable<never>[]),
              ),
            ),
          ),
        ),
      )
      .pipe(share());
  }

  @Output() t5sDefaultLongpress: Observable<TouchEvent>;
}
