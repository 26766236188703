import gql from 'graphql-tag';
import {
  BlabDefPreviewDtoNoNestingFragment,
  WorkspacePreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const BlabDefSearchResultFragment = gql`
  fragment BlabDefSearchResult on BlabDefSearchResultDto {
    blabDef {
      ...BlabDefPreviewDtoNoNesting
      workspace {
        ...WorkspacePreviewDtoNoNesting
      }
    }
  }
  ${BlabDefPreviewDtoNoNestingFragment}
  ${WorkspacePreviewDtoNoNestingFragment}
`;
