import gql from 'graphql-tag';
import { UserGroupNotificationDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { UserGroupNotificationActivityDtoFragment } from './user-group-notification-activity-dto.fragment';

export const UserGroupNotificationDtoFragment = gql`
  fragment UserGroupNotificationDtoFragment on UserGroupNotificationDto {
    ...UserGroupNotificationDtoNoNesting
    userGroupId
    activities(first: 1) {
      ...UserGroupNotificationActivityDtoFragment
    }
  }
  ${UserGroupNotificationDtoNoNestingFragment}
  ${UserGroupNotificationActivityDtoFragment}
`;
