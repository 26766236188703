import gql from 'graphql-tag';
import { SingleRelationFlimValDtoNoNestingFragment } from '../../../generated/graphql-fragments';

export const SingleRelationFlimValFragment = gql`
  fragment SingleRelationFlimVal on SingleRelationFlimValDto {
    ...SingleRelationFlimValDtoNoNesting
    referencedBlabItem {
      id
      title
      blabDefId
      blabDef {
        id
        icon
        iconv2
        itemIcon
        itemName
        blabName
        workspace {
          id
          name
        }
      }
      blabDefId
    }
  }
  ${SingleRelationFlimValDtoNoNestingFragment}
`;
