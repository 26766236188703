import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ComponentStyle,
  displayFlex,
  eachChild,
  exactHeight,
  firstChild,
  flexColumn,
  fullWidth,
  ms,
  px,
  spread,
  translateY,
} from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { NetworkStatusI18n } from '@t5s/mobile-client/i18n/network-status';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
import { NetworkStatusObservable } from '@t5s/mobile-client/provider-token/network-status';
import { SafeAreaDimensionsObservable } from '@t5s/mobile-client/provider-token/safe-area';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { font, ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { NavigationBarItem } from '@t5s/mobile-client/value-object/logged-in-root';
import { UserClientAppBubbleDto } from '@t5s/shared/gql';
import { filterOnlyFalse, filterOnlyTrue } from '@t5s/shared/util/rxjs';
import { combineLatest, merge, Observable, of, race, timer } from 'rxjs';
import { delay, map, mapTo, switchMap } from 'rxjs/operators';

interface LoggedInRootState {
  active: NavigationBarItem;
  bubbles: UserClientAppBubbleDto[];
}

@Component({
  selector: 't5s-logged-in-root-view',
  template: `
    <!-- Content -->
    <div [class]="containerClass">
      <!-- Main -->
      <div [class]="mainClass">
        <ng-content></ng-content>
      </div>

      <!-- Navigation bar -->
      <t5s-navigation-bar
        [active]="active$"
        [bubbles]="bubbles$"
        (navigationBarItemClick)="navigationBarItemClick.emit($event)"
        (navigationBarItemLongpress)="navigationBarItemLongpress.emit($event)"
      ></t5s-navigation-bar>

      <!-- Footer -->
      <div [class]="footerClass$ | push">
        <!-- Network status -->
        <div
          data-cy="t5s-mc-network-status-offline-indicator"
          [class]="networkStatusClass$ | push"
          [style.visibility]="networkStatusVisible$ | push"
        >
          <div>
            <ng-container *ngIf="i18n$ | push as i18n">
              <t5s-single-line-text
                [centered]="true"
                [fgColor]="ThemeColorVar.lightest"
                [font]="Font.regular14px"
                [lineHeight]="16"
              >
                {{ NetworkStatusI18n.translate(i18n.i18n, NetworkStatusI18n.key.offline) }}
              </t5s-single-line-text>
              <t5s-keyboard-animated-spacer [bottomDistance]="bottomDistance$"></t5s-keyboard-animated-spacer>
            </ng-container>
          </div>
        </div>
      </div>
    </div>

    <!-- Sheet outlet -->
    <div [class]="sheetOutletClass">
      <ng-content select="[logged-in-root-sheet-outlet]"></ng-content>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostSpreadPositionRelative],
})
export class LoggedInRootViewComponent extends RxComponent<LoggedInRootState> {
  readonly NetworkStatusI18n = NetworkStatusI18n;
  readonly ThemeColorVar = ThemeColorVar;
  readonly Font = font;

  constructor(
    readonly i18n$: I18nObjectObservable,
    private readonly networkStatus$: NetworkStatusObservable,
    private readonly safeArea$: SafeAreaDimensionsObservable,
  ) {
    super();
    this.set({
      active: 'none',
      bubbles: [],
    });
  }

  @Input() set active(active: NavigationBarItem | Observable<NavigationBarItem>) {
    this.setProperty('active', active);
  }

  @Input() set bubbles(bubbles: UserClientAppBubbleDto[] | Observable<UserClientAppBubbleDto[]>) {
    this.setProperty('bubbles', bubbles);
  }

  @Output() navigationBarItemClick = new EventEmitter<{ item: NavigationBarItem }>();
  @Output() navigationBarItemLongpress = new EventEmitter<{ item: NavigationBarItem }>();

  readonly active$ = this.select(selectSlice(['active'])).pipe(map(({ active }) => active));
  readonly bubbles$ = this.select(selectSlice(['bubbles'])).pipe(map(({ bubbles }) => bubbles));
  readonly bottomDistance$ = this.safeArea$.pipe(map(({ bottom }) => bottom));

  readonly isConnected$ = this.networkStatus$.pipe(
    map((status) => status === undefined || status?.connected === true),
    switchMap((isConnected) => {
      if (isConnected) {
        return of(isConnected);
      } else {
        return of(isConnected).pipe(delay(500));
      }
    }),
  );

  readonly isOnline$ = this.isConnected$.pipe(filterOnlyTrue());
  readonly isOffline$ = this.isConnected$.pipe(filterOnlyFalse());

  readonly networkStatusVisible$ = merge(
    this.isOffline$,
    this.isOnline$.pipe(switchMap(() => race(timer(400).pipe(mapTo(true)), this.isOffline$))),
  ).pipe(map((isConnected) => (isConnected ? 'hidden' : 'visible')));

  readonly containerClass = tss({
    ...spread,
    ...flexColumn,
    backgroundColor: ThemeColorVar.lightest,
  });

  readonly mainClass = tss({
    flex: 1,
    minHeight: px(0),
    position: 'relative',
  });

  readonly footerClass$ = this.safeArea$.pipe(
    map(({ bottom }) =>
      tss({
        ...displayFlex,
        flex: 0,
        height: 'fit-content',
        minHeight: px(bottom),
        flexBasis: 'auto',
      }),
    ),
  );

  readonly networkStatusClass$ = combineLatest([this.isConnected$, this.safeArea$]).pipe(
    map(([isConnected, { bottom }]) => {
      return tss({
        ...fullWidth,
        alignSelf: 'flex-end',
        transitionDuration: ms(400),
        ...exactHeight(isConnected ? 0 : 16 + 9 + 9 + bottom),
        backgroundColor: ThemeColorVar.darkest,
        ...firstChild({
          position: 'absolute',
          transitionDuration: ms(400),
          overflow: 'hidden',
          transform: translateY(isConnected ? +16 + 9 + 9 : -bottom),
          bottom: px(0),
          left: px(0),
          ...fullWidth,
          padding: px(9),
          backgroundColor: ThemeColorVar.darkest,
        }),
      });
    }),
  );

  readonly sheetOutletClass = tss({
    position: 'absolute',
    top: px(0),
    left: px(0),
    ...spread,
    pointerEvents: 'none',
    ...eachChild({
      ...spread,
      position: 'absolute',
      top: px(0),
      left: px(0),
    }),
  });
}
