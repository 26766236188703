import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ServiceBrowserModule } from '@t5s/mobile-client/service/browser';
import { ServiceToastModule } from '@t5s/mobile-client/service/toast';
import { ExceptionToastEffects } from './exception-toast.effects';

@NgModule({
  imports: [EffectsModule.forFeature([ExceptionToastEffects]), ServiceBrowserModule, ServiceToastModule],
})
export class BusinessLogicExceptionToastModule {}
