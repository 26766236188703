import { Routes } from '@angular/router';
import { ActiveUserContextGuard } from '@t5s/mobile-client/business-logic/active-user-session';
import { LoggedInRootRedirectGuard } from '@t5s/mobile-client/business-logic/logged-in-root';
import {
  ROUTE_FRAGMENT_ACTIVE_USER,
  ROUTE_FRAGMENT_CHAT,
  ROUTE_FRAGMENT_HOME,
  ROUTE_FRAGMENT_LOGGED_IN,
  ROUTE_FRAGMENT_NOTIFICATION,
  ROUTE_FRAGMENT_SEARCH,
} from '@t5s/mobile-client/readonly-constant/logged-in';
import { LoggedInRootPagePageComponent } from './logged-in-root-page.component';

export const LOGGED_IN_ROOT_PAGE_ROUTES: Routes = [
  {
    path: ROUTE_FRAGMENT_LOGGED_IN,
    component: LoggedInRootPagePageComponent,
    canActivate: [LoggedInRootRedirectGuard],
  },
  {
    path: ROUTE_FRAGMENT_LOGGED_IN,
    component: LoggedInRootPagePageComponent,
    children: [
      {
        path: ROUTE_FRAGMENT_HOME,
        loadChildren: () => import('@t5s/mobile-client/page/logged-in/home').then((m) => m.HomePageModule),
      },
      {
        path: ROUTE_FRAGMENT_SEARCH,
        loadChildren: () => import('@t5s/mobile-client/page/logged-in/search').then((m) => m.SearchPageModule),
      },
      {
        path: ROUTE_FRAGMENT_NOTIFICATION,
        loadChildren: () =>
          import('@t5s/mobile-client/page/logged-in/notification').then((m) => m.NotificationPageModule),
      },
      {
        path: ROUTE_FRAGMENT_ACTIVE_USER,
        loadChildren: () => import('@t5s/mobile-client/page/logged-in/active-user').then((m) => m.ActiveUserPageModule),
      },
      {
        path: ROUTE_FRAGMENT_CHAT,
        loadChildren: () => import('@t5s/mobile-client/page/logged-in/chat').then((m) => m.ChatPageModule),
      },
    ],
    canActivate: [ActiveUserContextGuard],
  },
];
