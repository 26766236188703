import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BottomSheetPosition } from '@t5s/mobile-client/value-object/bottom-sheet';
import { RootState, rootStateKey } from './root.reducer';

const selectRootFeatureState = createFeatureSelector<RootState>(rootStateKey);

export const selectModalOpen = createSelector(selectRootFeatureState, (state) => state.modalOpen);
export const selectSheetPosition = createSelector(selectRootFeatureState, (state) => state.sheetPosition);

export const selectSheetOpen = createSelector(
  selectRootFeatureState,
  (state) => state.sheetPosition !== BottomSheetPosition.IS_BOTTOM,
);
export const selectOverlayOpen = createSelector(
  selectModalOpen,
  selectSheetOpen,
  (modalOpen, sheetOpen) => modalOpen || sheetOpen,
);
