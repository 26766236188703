import { AddDeviceRegistrationTokenInput, AddWebPushSubscriptionInput, VapidKey, Void } from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import { addDeviceRegistrationToken, addWebPushSubscription } from '../../generated/graphql-mutations';
import { getServerVapidPubkey } from '../../generated/graphql-queries';
import { GqlService } from '../../gql-service';

export class GqlPushNotificationSetupService extends GqlService {
  getServerVapidPubkey(): Observable<VapidKey> {
    return this.apollo.defaultQuery(getServerVapidPubkey());
  }

  addWebPushSubscription(input: AddWebPushSubscriptionInput): Observable<Void> {
    return this.apollo.defaultMutate(addWebPushSubscription(input));
  }

  addDeviceRegistrationToken(input: AddDeviceRegistrationTokenInput): Observable<Void> {
    return this.apollo.defaultMutate(addDeviceRegistrationToken(input));
  }
}
