import { NgModule } from '@angular/core';
import { ReactionDetailsBusinessLogicModule } from '@t5s/mobile-client/business-logic/reaction-details';
import { PageCommonModule } from '@t5s/mobile-client/page/common';
import { ReactionDetailsViewModule } from '@t5s/mobile-client/ui/view/reaction-details';
import { ReactionDetailsPageComponent } from './reaction-details-page.component';

@NgModule({
  imports: [PageCommonModule, ReactionDetailsViewModule, ReactionDetailsBusinessLogicModule],
  declarations: [ReactionDetailsPageComponent],
  exports: [ReactionDetailsPageComponent],
})
export class ReactionDetailsPageModule {}
