import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  border,
  ComponentStyle,
  flexCenter,
  flexColumn,
  getFontStyle,
  padding,
  px,
  spread,
  textSelect,
} from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
import { SafeAreaDimensionsObservable } from '@t5s/mobile-client/provider-token/safe-area';
import { ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { ViewComponent, ViewState } from '@t5s/mobile-client/ui/view/common';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface DebugConsoleStorageKeyDetailsViewState extends ViewState {
  top: number;
  bottom: number;
  key: string;
  value: string;
}

@Component({
  selector: 't5s-debug-console-storage-key-details-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostSpread],
  template: `
    <ng-container *ngIf="state$ | push as state">
      <div [class]="containerClass$ | push">
        <!-- Header -->
        <div [class]="headerClass">
          <t5s-text [font]="Font.black18px"> Storage Key Details </t5s-text>

          <t5s-link [class]="backBtnClass" [font]="Font.regular16px" (linkClick)="backClick.emit()"> Back </t5s-link>

          <t5s-link
            [class]="deleteBtnClass"
            [fgColor]="Color.danger"
            [font]="Font.regular16px"
            (linkClick)="deleteClick.emit({ key: state.key })"
          >
            Delete
          </t5s-link>
        </div>
        <t5s-divider [height]="1" [bgColor]="Color.light"></t5s-divider>

        <div [class]="bodyClass$ | push">
          <t5s-text [class]="segmentHeaderClass" [font]="Font.black18px">{{ state.key }}</t5s-text>

          <div [class]="segmentBodyClass">
            <pre>{{ getValue(state) }}</pre>
          </div>

          <t5s-divider [height]="13"></t5s-divider>
        </div>
      </div>
    </ng-container>
  `,
})
export class DebugConsoleStorageKeyDetailsViewComponent extends ViewComponent<DebugConsoleStorageKeyDetailsViewState> {
  constructor(readonly i18nObservable$: I18nObjectObservable, readonly safeArea$: SafeAreaDimensionsObservable) {
    super(i18nObservable$);

    this.connect(safeArea$);
  }

  @Input() set key(key: string | Observable<string>) {
    this.setProperty('key', key);
  }

  @Input() set value(value: string | Observable<string>) {
    this.setProperty('value', value);
  }

  @Output() backClick = new EventEmitter<never>();
  @Output() deleteClick = new EventEmitter<{ key: string }>();

  readonly containerClass$ = this.state$.pipe(
    map(({ top }) =>
      tss({
        ...spread,
        ...flexColumn,
        paddingTop: px(top),
        backgroundColor: ThemeColorVar.lightest,
      }),
    ),
  );

  readonly headerClass = tss({
    ...flexCenter,
    position: 'relative',
    padding: padding(11, 15, 11, 15),
    flex: 0,
  });

  readonly bodyClass$ = this.state$.pipe(
    map(({ bottom }) =>
      tss({
        overflowX: 'auto',
        overflowY: 'auto',
        ...textSelect,
        backgroundColor: ThemeColorVar.lighter,
        flex: 1,
        minHeight: px(0),
        paddingBottom: px(bottom),
        ...flexColumn,
      }),
    ),
  );

  readonly backBtnClass = tss({
    position: 'absolute',
    top: px(13),
    left: px(15),
  });

  readonly deleteBtnClass = tss({
    position: 'absolute',
    top: px(13),
    right: px(15),
  });

  readonly segmentClass = tss({
    ...spread,
    backgroundColor: ThemeColorVar.lightest,
    borderTop: border(1, 'solid', ThemeColorVar.light),
    borderBottom: border(1, 'solid', ThemeColorVar.light),
    marginBottom: px(13),
    minWidth: 'fit-content',
    minHeight: 'fit-content',
  });

  readonly segmentHeaderClass = tss({
    backgroundColor: ThemeColorVar.lightest,
    padding: padding(9, 13),
    borderBottom: border(1, 'solid', ThemeColorVar.light),
    position: 'sticky',
    top: px(0),
    left: px(0),
  });

  readonly segmentBodyClass = tss({
    backgroundColor: ThemeColorVar.lightest,
    padding: padding(9, 13),
    ...getFontStyle(this.Font.regular14px),
    fontFamily: 'monospace ',
    minHeight: 'fit-content',
    minWidth: 'fit-content',
    borderBottom: border(1, 'solid', ThemeColorVar.light),
  });

  getValue(state: DebugConsoleStorageKeyDetailsViewState) {
    const { value } = state;
    try {
      const json = JSON.stringify(JSON.parse(value), null, 2);
      return json;
    } catch (err: unknown) {
      return value;
    }
  }
}
