import { Language } from '@t5s/shared/gql';

export function parseBrowserLanguage(languageCandidate?: string | Language): Language | undefined {
  if (!languageCandidate) {
    return undefined;
  }

  const language: 'en' | 'de' | Language | unknown = languageCandidate.split('-')[0].toLowerCase();

  switch (language) {
    case 'en':
      return Language.EN;
    case 'de':
      return Language.DE;
    default:
      return undefined;
  }
}

export function parseBrowserLanguageOrFail(languageCandidate?: string): Language {
  const language = parseBrowserLanguage(languageCandidate);

  if (!language) {
    throw new Error(`Cannot parse language from input ${String(languageCandidate)}`);
  }

  return language;
}

export function parseBrowserLanguageFallbackDefault(languageCandidate?: string): Language {
  return parseBrowserLanguage(languageCandidate) ?? Language.EN;
}
