import { NgModule } from '@angular/core';
import {
  ClientDataAccessApolloGqlModule,
  ClientDataAccessApolloSubscriptionModule,
  GRAPHQL_ENDPOINT,
} from '@t5s/client/data-access/gql';
import { ClientDataAccessHttpUserContextInterceptorModule } from '@t5s/client/data-access/http/active-user-context';
import { LiveSubscriptionContextObservable } from '@t5s/client/provider-token/live-subscription-context';
import { RUNTIME_URL } from '@t5s/client/provider/runtime-url';
import { WEB_VIEW_LOCALHOST } from '@t5s/mobile-client/env';
import {
  ActiveUserIdObservable,
  ActiveUserSessionCookieObservable,
} from '@t5s/mobile-client/provider-token/active-user';
import { ServiceApmModule } from '@t5s/mobile-client/service/apm';
import { ServiceApplicationBadgeModule } from '@t5s/mobile-client/service/application-badge';
import { ServiceApplicationRestartModule } from '@t5s/mobile-client/service/application-restart';
import { ServiceApplicationStateModule } from '@t5s/mobile-client/service/application-state';
import { ServiceApplicationUrlModule } from '@t5s/mobile-client/service/application-url';
import { ServiceBrowserModule } from '@t5s/mobile-client/service/browser';
import { ServiceClipboardModule } from '@t5s/mobile-client/service/clipboard';
import { ServiceDeviceModule } from '@t5s/mobile-client/service/device';
import { ServiceDialogModule } from '@t5s/mobile-client/service/dialog';
import { ServiceFilesystemModule } from '@t5s/mobile-client/service/filesystem';
import { ServiceHapticsModule } from '@t5s/mobile-client/service/haptics';
import { ServiceIntercomModule } from '@t5s/mobile-client/service/intercom';
import { ServiceKeyboardModule } from '@t5s/mobile-client/service/keyboard';
import { ServiceNetworkModule } from '@t5s/mobile-client/service/network';
import { ServicePushNotificationModule } from '@t5s/mobile-client/service/push-notification';
import { ServiceScreenOrientationModule } from '@t5s/mobile-client/service/screen-orientation';
import { ServiceShareModule } from '@t5s/mobile-client/service/share';
import { ServiceSplashScreenModule } from '@t5s/mobile-client/service/splash-screen';
import { ServiceStateHydrationModule } from '@t5s/mobile-client/service/state-hydration';
import { ServiceStatusBarModule } from '@t5s/mobile-client/service/status-bar';
import { ServiceStorageModule } from '@t5s/mobile-client/service/storage';
import { ServiceToastModule } from '@t5s/mobile-client/service/toast';
import { INSECURE_COOKIE_HEADER_NAME, USERID_HEADER_NAME } from '@t5s/shared/readonly-constant/authentication';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntityIconServiceModule } from '@t5s/mobile-client/service/entity-icon';

/** All service modules imported here will make their provided services singletons. */
@NgModule({
  imports: [
    ClientDataAccessApolloGqlModule,
    ClientDataAccessHttpUserContextInterceptorModule,
    ClientDataAccessApolloSubscriptionModule,
    // ClientDataAccessHttpUtcOffsetModule,

    ServiceApmModule,
    ServiceApplicationBadgeModule,
    ServiceApplicationRestartModule,
    ServiceApplicationStateModule,
    ServiceApplicationUrlModule,
    ServiceBrowserModule,
    ServiceClipboardModule,
    ServiceDeviceModule,
    ServiceDialogModule,
    ServiceFilesystemModule,
    ServiceHapticsModule,
    ServiceKeyboardModule,
    ServiceNetworkModule,
    ServicePushNotificationModule,
    ServiceScreenOrientationModule,
    ServiceShareModule,
    ServiceSplashScreenModule,
    ServiceStateHydrationModule,
    ServiceStatusBarModule,
    ServiceStorageModule,
    ServiceToastModule,
    ServiceIntercomModule,
    EntityIconServiceModule,
  ],
  providers: [
    {
      provide: GRAPHQL_ENDPOINT,
      useFactory: (url: string): string => {
        if (WEB_VIEW_LOCALHOST) {
          return 'https://mobile.tapeapp.com/graphql';
        }
        return `${url}/graphql`;
      },
      deps: [RUNTIME_URL],
    },
    {
      provide: LiveSubscriptionContextObservable,
      useFactory: (
        activeUserId$: ActiveUserIdObservable,
        activeUserSessionCookie$: ActiveUserSessionCookieObservable,
      ) =>
        combineLatest([activeUserId$, activeUserSessionCookie$]).pipe(
          map(([activeUserId, cookie]) => {
            if (!activeUserId || !cookie) {
              return undefined;
            }

            return {
              [USERID_HEADER_NAME]: activeUserId,
              [INSECURE_COOKIE_HEADER_NAME]: cookie,
            };
          }),
        ),
      deps: [ActiveUserIdObservable, ActiveUserSessionCookieObservable],
    },
  ],
})
export class MobileClientServiceModule {}
