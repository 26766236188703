import { Injectable } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
import { defer, from, Observable } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Injectable()
export class BrowserService {
  constructor(private readonly i18n$: I18nObjectObservable) {}

  open({ url }: { url: string }): Observable<void> {
    return this.i18n$.pipe(
      first(),
      switchMap(({ i18n: { language } }) => {
        const newUrl = new URL(url);
        if (language) {
          newUrl.searchParams.append('lang', language.toLowerCase());
        }

        return defer(() =>
          from(Browser.open({ url: newUrl.toString(), presentationStyle: 'fullscreen', windowName: 'Tape' })),
        );
      }),
    );
  }
}
