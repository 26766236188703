import { LocationAutocompleteSuggestionsDto, LocationDetailsDto } from '@t5s/shared/gql';
import { isDto } from './dto.type-guard';

export function isLocationAutocompleteSuggestionsDto(candidate?: any): candidate is LocationAutocompleteSuggestionsDto {
  return isDto<LocationAutocompleteSuggestionsDto>(candidate, 'LocationAutocompleteSuggestionsDto');
}

export function isLocationDetailsDto(candidate?: any): candidate is LocationDetailsDto {
  return isDto<LocationDetailsDto>(candidate, 'LocationDetailsDto');
}
