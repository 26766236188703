import { NgModule } from '@angular/core';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { HttpClientModule } from '@angular/common/http';
import { FileOpenerService } from './file-opener.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [FileOpener, FileOpenerService],
})
export class ServiceFileOpenerModule {}
