import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ServiceBrowserModule } from '@t5s/mobile-client/service/browser';
import { BlabItemActivityThreadHostCommentEffects } from './blab-item-activity-thread-host-comment.effects';
import { BlabItemActivityThreadLiveSubscriptionEffects } from './blab-item-activity-thread-live-subscription.effects';
import { BlabItemActivityThreadMentionUserEffects } from './blab-item-activity-thread-mention-user.effects';
import { BlabItemActivityThreadReplyEffects } from './blab-item-activity-thread-reply.effects';
import { BlabItemActivityThreadEffects } from './blab-item-activity-thread.effects';
import { blabItemActivityThreadReducer, blabItemActivityThreadStateKey } from './blab-item-activity-thread.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(blabItemActivityThreadStateKey, blabItemActivityThreadReducer),
    EffectsModule.forFeature([
      BlabItemActivityThreadEffects,
      BlabItemActivityThreadLiveSubscriptionEffects,

      BlabItemActivityThreadReplyEffects,
      BlabItemActivityThreadHostCommentEffects,

      BlabItemActivityThreadMentionUserEffects,
    ]),
    ServiceBrowserModule,
  ],
})
export class BlabItemActivityThreadBusinessLogicModule {}
