import gql from 'graphql-tag';
import {
  ChatActivityClientApplicationBrowserNotificationDtoNoNestingFragment,
  NotificationClientApplicationBrowserNotificationDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const ClientApplicationBrowserNotificationUnionTypeFragment = gql`
  fragment ClientApplicationBrowserNotificationUnionType on ClientApplicationBrowserNotificationUnionType {
    ... on ChatActivityClientApplicationBrowserNotificationDto {
      ...ChatActivityClientApplicationBrowserNotificationDtoNoNesting
    }
    ... on NotificationClientApplicationBrowserNotificationDto {
      ...NotificationClientApplicationBrowserNotificationDtoNoNesting
    }
  }
  ${ChatActivityClientApplicationBrowserNotificationDtoNoNestingFragment}
  ${NotificationClientApplicationBrowserNotificationDtoNoNestingFragment}
`;
