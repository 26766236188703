import gql from 'graphql-tag';
import { MultiRelationFlimValDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { BlabItemReferenceFragment } from './blab-item-reference.fragment';

export const MultiRelationFlimValFragment = gql`
  fragment MultiRelationFlimVal on MultiRelationFlimValDto {
    ...MultiRelationFlimValDtoNoNesting
    blabItemReferences {
      ...BlabItemReference
    }
  }
  ${MultiRelationFlimValDtoNoNestingFragment}
  ${BlabItemReferenceFragment}
`;
