import gql from 'graphql-tag';
import {
  BlabItemCommentAttachmentDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const BlabItemCommentAttachmentFragment = gql`
  fragment BlabItemCommentAttachmentFragment on BlabItemCommentAttachmentDto {
    ...BlabItemCommentAttachmentDtoNoNesting
    thumbnail {
      ...ThumbnailDtoNoNesting
    }
  }
  ${BlabItemCommentAttachmentDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
