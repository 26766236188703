import gql from 'graphql-tag';
import { BlabItemReferenceDtoNoNestingFragment } from '../../../../generated/graphql-fragments';

export const BlabItemReferenceFragment = gql`
  fragment BlabItemsOverviewBlabItemReference on BlabItemReferenceDto {
    ...BlabItemReferenceDtoNoNesting
    blabItem {
      id
      title
      blabDefId
      blabDef {
        id
        icon
        iconv2
        itemIcon
        itemName
        blabName
      }
    }
  }
  ${BlabItemReferenceDtoNoNestingFragment}
`;
