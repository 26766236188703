import gql from 'graphql-tag';
import {
  AudioRoomParticipantDetailDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
  UserProfilePreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const AudioRoomDetailParticipantDtoFragment = gql`
  fragment AudioRoomDetailParticipantDtoFragment on AudioRoomParticipantDetailDto {
    ...AudioRoomParticipantDetailDtoNoNesting
    user {
      ...UserProfilePreviewDtoNoNesting
      profilePicture {
        ...ThumbnailDtoNoNesting
      }
    }
  }
  ${AudioRoomParticipantDetailDtoNoNestingFragment}
  ${UserProfilePreviewDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
