import gql from 'graphql-tag';
import { UserSessionDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { FullUserProfileDtoFragment } from '../user-profile';

export const UserSessionFragment = gql`
  fragment UserSessionFragment on UserSessionDto {
    ...UserSessionDtoNoNesting
    user {
      ...FullUserProfileDto
    }
  }
  ${UserSessionDtoNoNestingFragment}
  ${FullUserProfileDtoFragment}
`;
