import gql from 'graphql-tag';
import { PageInfoFragment } from '../core';
import { OrganizationActivityReplyDtoFragment } from './organization-activity-reply-dto.fragment';

export const OrganizationActivityReplyConnectionFragment = gql`
  fragment FullOrganizationActivityReplyConnection on OrganizationActivityReplyConnection {
    edges {
      cursor
      node {
        ...OrganizationActivityReplyDtoFragment
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
  ${OrganizationActivityReplyDtoFragment}
  ${PageInfoFragment}
`;
