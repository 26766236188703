import { NgModule } from '@angular/core';
import { AssetComponentModule } from '@t5s/mobile-client/ui/component/asset';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { AvatarComponent } from './avatar.component';

const components = [AvatarComponent];

@NgModule({
  imports: [ComponentCommonModule, AssetComponentModule],
  declarations: components,
  exports: components,
})
export class AvatarComponentModule {}
