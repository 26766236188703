import { DOCUMENT } from '@angular/common';
import { NgModule } from '@angular/core';
import { BackButtonEvent } from '@ionic/core';
import { isInputtableElement } from '@t5s/client/util/element';
import { HardwareBackButtonObservable } from '@t5s/mobile-client/provider-token/hardware-back-button';
import { fromEvent, merge } from 'rxjs';
import { filter, share, tap } from 'rxjs/operators';

const BACK_BUTTON_KEYBOARD_SHORTCUT = 'b';

@NgModule({
  providers: [
    {
      provide: HardwareBackButtonObservable,
      useFactory: (document: Document): HardwareBackButtonObservable => {
        // Hardware back button event e.g., from Android back button
        const ionBack = fromEvent<BackButtonEvent>(document, 'ionBackButton', { passive: true }).pipe(
          tap((event) => event.detail.register(99999, () => {})),
        );

        // For debugging purposes in browser mainly
        const backShortcut = fromEvent<KeyboardEvent>(document, 'keyup', { passive: true }).pipe(
          filter(({ key }) => key === BACK_BUTTON_KEYBOARD_SHORTCUT),
          filter(({ target }) => !isInputtableElement(target)),
        );

        return merge(ionBack, backShortcut).pipe(share());
      },

      deps: [DOCUMENT],
    },
  ],
})
export class ProviderHardwareBackButtonModule {}
