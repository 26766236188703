import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexColumnDirective } from './flex-column.directive';
import { FlexOneHorizontalDirective } from './flex-one-horizontal.directive';
import { FlexOneVerticalDirective } from './flex-one-vertical.directive';
import { FlexRowDirective } from './flex-row.directive';

const directives = [FlexColumnDirective, FlexRowDirective, FlexOneHorizontalDirective, FlexOneVerticalDirective];

@NgModule({
  imports: [CommonModule],
  declarations: directives,
  exports: directives,
})
export class LayoutDirectiveModule {}
