/**
 * Determine whether the current device is a touch device.
 * This means that the device has a touch screen and no mouse.
 * This cannot be determined with 100% certainty.
 * Try leaning on not touch if unsure.
 * The check is based on the following sources:
 *  - window.matchMedia('(pointer: coarse)').matches
 *  - window.ontouchstart
 *  - navigator.maxTouchPoints
 *
 * https://stackoverflow.com/questions/7838680/detecting-that-the-browser-has-no-mouse-and-is-touch-only
 * https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
 */
export function isTouchDevice(): boolean {
  try {
    if (typeof window === 'undefined') return false;

    // Check matchMedia
    let pointerCoarse = false;
    if (typeof window?.matchMedia === 'function') {
      pointerCoarse = !!window?.matchMedia('(pointer: coarse)')?.matches;
    }

    // Check ontouchstart
    const touchStart = typeof window?.ontouchstart !== 'undefined';

    // Check maxTouchPoints
    let maxTouchPoints = 0;
    if (typeof navigator?.maxTouchPoints === 'number') {
      maxTouchPoints = navigator?.maxTouchPoints;
    }

    return pointerCoarse && touchStart && maxTouchPoints > 0;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('isTouchDevice errored: ' + err);
  }

  return false;
}

/**
 * Determine whether the current device is a mobile device.
 * Try leaning towards not mobile if unsure.
 * This is determined by checking the user agent exposed via:
 *  - "navigator.userAgent"
 *  - "navigator.userAgentData"
 *  - "navigator.vendor"
 *  - "navigator.platform".
 */
export function isMobileDevice(): boolean {
  try {
    if (typeof window === 'undefined') return false;
    if (typeof navigator === 'undefined') return false;

    // Check userAgentData.mobile, respect only true-values
    if (
      typeof (navigator as any)?.userAgentData?.mobile === 'boolean' &&
      (navigator as any)?.userAgentData?.mobile === true
    ) {
      return true;
    }

    // Check userAgent
    const userAgent = navigator?.userAgent?.toLowerCase();
    if (userAgent?.includes('mobi')) return true;
    if (userAgent?.includes('android')) return true;
    if (userAgent?.includes('iphone')) return true;
    if (userAgent?.includes('ipad')) return true;
    if (userAgent?.includes('ipod')) return true;
    if (userAgent?.includes('blackberry')) return true;
    if (userAgent?.includes('windows phone')) return true;
    if (userAgent?.includes('iemobile')) return true;

    if (userAgent?.includes('macintel')) return false;
    if (userAgent?.includes('macintosh')) return false;
    if (userAgent?.includes('mac os')) return false;
    if (userAgent?.includes('windows')) return false;
    if (userAgent?.includes('linux')) return false;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn('isMobileDevice errored: ' + err);
  }

  return false;
}

export function isPlatformAndroid(platform: 'android' | 'ios' | 'web'): platform is 'android' {
  return platform === 'android';
}

export function isPlatformIos(platform: 'android' | 'ios' | 'web'): platform is 'ios' {
  return platform === 'ios';
}

export function isPlatformWeb(platform: 'android' | 'ios' | 'web'): platform is 'web' {
  return platform === 'web';
}
