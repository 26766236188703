import {
  CreditInvoiceItemDto,
  DiscountInvoiceItemDto,
  InvoiceItemUnionType,
  LicenseInvoiceItemDto,
  ProrationInvoiceItemDto,
  TaxInvoiceItemDto,
} from '@t5s/shared/gql';
import { isDto } from './dto.type-guard';

export function isTaxInvoiceItemDto(candidate?: any): candidate is TaxInvoiceItemDto {
  return isDto<InvoiceItemUnionType>(candidate, 'TaxInvoiceItemDto');
}

export function isLicenseInvoiceItemDto(candidate?: any): candidate is LicenseInvoiceItemDto {
  return isDto<InvoiceItemUnionType>(candidate, 'LicenseInvoiceItemDto');
}

export function isProrationInvoiceItemDto(candidate?: any): candidate is ProrationInvoiceItemDto {
  return isDto<InvoiceItemUnionType>(candidate, 'ProrationInvoiceItemDto');
}

export function isDiscountInvoiceItemDto(candidate?: any): candidate is DiscountInvoiceItemDto {
  return isDto<InvoiceItemUnionType>(candidate, 'DiscountInvoiceItemDto');
}

export function isCreditInvoiceItemDto(candidate?: any): candidate is CreditInvoiceItemDto {
  return isDto<InvoiceItemUnionType>(candidate, 'CreditInvoiceItemDto');
}
