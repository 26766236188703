import { TssStylesObject } from '@t5s/client/util/tss';
import { fontFamily } from '../constants';
import { NOTOUCHDEVICE, TOUCHDEVICE } from './css-selector.util';

export function placeholder(css: TssStylesObject) {
  return {
    '::placeholder': { fontFamily: fontFamily.default, ...css },
    '::-moz-placeholder': {
      fontFamily: fontFamily.default,
      opacity: 1,
      ...css,
    },
  } as any;
}

export function hover(css: TssStylesObject) {
  return {
    [NOTOUCHDEVICE]: {
      ':hover': { ...css } as any,
    },
  };
}

export function touchDevice(css: TssStylesObject) {
  return {
    [TOUCHDEVICE]: { ...css } as any,
  };
}

export function notouchDevice(css: TssStylesObject) {
  return {
    [NOTOUCHDEVICE]: { ...css } as any,
  };
}

export function active(css: TssStylesObject) {
  return {
    ':active': { ...css } as any,
  };
}

export function focus(css: TssStylesObject) {
  return {
    ':focus': { ...css } as any,
  };
}

export function focusWithin(css: TssStylesObject) {
  return {
    ':focus-within': { ...css } as any,
  };
}

export function focusVisible(css: TssStylesObject) {
  return {
    ':focus-visible': { ...css } as any,
  };
}

export function visited(css: TssStylesObject) {
  return {
    ':visited': { ...css } as any,
  };
}

export function firstChild(css: TssStylesObject) {
  return {
    '> :first-child': { ...css } as any,
  };
}

export function notFirstChild(css: TssStylesObject) {
  return {
    '> :not(:first-child)': { ...css } as any,
  };
}

export function lastChild(css: TssStylesObject) {
  return {
    '> :last-child': { ...css } as any,
  };
}

export function notLastChild(css: TssStylesObject) {
  return {
    '> :not(:last-child)': { ...css } as any,
  };
}

export function nthChild(nth: number | string, css: TssStylesObject) {
  return {
    [`> :nth-child(${nth})`]: { ...css } as any,
  };
}

export function eachChild(css: TssStylesObject) {
  return {
    '> *': { ...css } as any,
  };
}

export function oddChild(css: TssStylesObject) {
  return nthChild('odd', css);
}

export function evenChild(css: TssStylesObject) {
  return nthChild('even', css);
}

export function notEmpty(css: TssStylesObject) {
  return {
    '> :not(:empty)': { ...css } as any,
  };
}

export function disabled(css: TssStylesObject) {
  return {
    ':disabled': { ...css } as any,
  };
}

export function before(css: TssStylesObject) {
  return {
    '::before': {
      content: '""',
      ...css,
    } as any,
  };
}

export function after(css: TssStylesObject) {
  return {
    '::after': {
      content: '""',
      ...css,
    } as any,
  };
}

export function empty(css: TssStylesObject) {
  return {
    '::empty': {
      ...css,
    } as any,
  };
}
