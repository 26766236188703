import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { parseBrowserLanguageFallbackDefault } from '@t5s/client/util/browser-language';
import { map } from 'rxjs/operators';
import { ApplicationI18nActions } from './application-i18n.actions';

@Injectable()
export class ApplicationI18nEffects implements OnInitEffects {
  constructor(private readonly actions$: Actions) {}

  readonly initApplicationI18n$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApplicationI18nActions.initApplicationI18n),
      map(() => {
        const language = parseBrowserLanguageFallbackDefault(navigator.language);
        return ApplicationI18nActions.setApplicationI18n({ language });
      }),
    ),
  );

  ngrxOnInitEffects(): Action {
    // Initialize application i18n from browser environment on startup
    return ApplicationI18nActions.initApplicationI18n();
  }
}
