import { Optional, Provider } from '@angular/core';
import { HttpLinkHandler } from 'apollo-angular-link-http';
import { ApolloLink, split } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';

/** Creates an apollo link, uses split websocket link if available. */
const apolloLinkFactory = (httpLinkHandler: HttpLinkHandler, websocketLinkHandler?: WebSocketLink): ApolloLink => {
  if (!websocketLinkHandler) {
    return httpLinkHandler;
  }
  return split(
    ({ query }) => {
      const def = getMainDefinition(query);
      return def.kind === 'OperationDefinition' && def.operation === 'subscription';
    },
    websocketLinkHandler,
    httpLinkHandler,
  );
};

export const APOLLO_LINK_PROVIDER: Provider = {
  provide: ApolloLink,
  useFactory: apolloLinkFactory,
  deps: [HttpLinkHandler, [new Optional(), WebSocketLink]],
};
