/** The maximum size in Megabyte a file may have to be uploaded as an attachment in Tape. */
export const MAX_ATTACHMENT_FILE_SIZE_MB = 100;

export const IMAGE_FILE_ATTACHMENT_MIMETYPES = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'image/tiff',
  'image/svg',
  'image/bmp',
  'image/webp',
];
