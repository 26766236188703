import gql from 'graphql-tag';
import {
  SingleCategoryOptionDefDtoNoNestingFragment,
  SingleCategorySplitByDtoNoNestingFragment,
  SingleCategorySplitBySectionDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const SingleCategorySplitByDtoFragment = gql`
  fragment SingleCategorySplitByDto on SingleCategorySplitByDto {
    ...SingleCategorySplitByDtoNoNesting
    sections {
      ...SingleCategorySplitBySectionDtoNoNesting
      singleCategoryOptionDef {
        ...SingleCategoryOptionDefDtoNoNesting
      }
    }
  }
  ${SingleCategorySplitByDtoNoNestingFragment}
  ${SingleCategorySplitBySectionDtoNoNestingFragment}
  ${SingleCategoryOptionDefDtoNoNestingFragment}
`;
