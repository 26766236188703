import { NgModule } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { UrlObservable } from '@t5s/mobile-client/provider-token/url';
import { distinctUntilChanged, map } from 'rxjs/operators';

@NgModule({
  imports: [RouterModule],
  providers: [
    {
      provide: UrlObservable,
      useFactory: (router: Router): UrlObservable =>
        router.events.pipe(
          map(() => router.url),
          distinctUntilChanged(),
        ),
      deps: [Router],
    },
  ],
})
export class ProviderApplicationUrlModule {}
