import {
  CreateMultiEmailFlimValEntryNestedCreationInput,
  CreateMultiEmailFlimValInput,
  MultiEmailFlimValDto,
  MultiEmailFlimValEntryDto,
} from '@t5s/shared/gql';

function getMultiEmailCreateNestedEntryInputFromDto(
  multiEmailEntry: MultiEmailFlimValEntryDto,
): CreateMultiEmailFlimValEntryNestedCreationInput | undefined {
  const { position, emailAddress, emailAddressType } = multiEmailEntry;

  if (!emailAddress || !emailAddressType) {
    return undefined;
  }

  return { position, emailAddress, emailAddressType };
}

export function getMultiEmailCreateInputFromDto(multiEmailFlimVal: MultiEmailFlimValDto): CreateMultiEmailFlimValInput {
  const { flimDefId } = multiEmailFlimVal;

  const multiEmailEntryDtos = multiEmailFlimVal.multiEmailEntries ?? [];
  const multiEmailEntries = multiEmailEntryDtos
    .map(getMultiEmailCreateNestedEntryInputFromDto)
    .filter((entry): entry is CreateMultiEmailFlimValEntryNestedCreationInput => entry !== undefined);

  return { flimDefId, multiEmailEntries };
}
