import gql from 'graphql-tag';
import { CalendarEventsDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { CalendarEventUnionTypeFragment } from './calendar-event-union-type.fragment';

export const CalendarEventsDtoFragment = gql`
  fragment CalendarEventsDtoFragment on CalendarEventsDto {
    ...CalendarEventsDtoNoNesting
    events {
      ...CalendarEventUnionType
    }
  }
  ${CalendarEventsDtoNoNestingFragment}
  ${CalendarEventUnionTypeFragment}
`;
