import gql from 'graphql-tag';
import { BlabItemCommentDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { BlabItemActivityDtoFragment } from '../blab-item-activity/blab-item-activity-dto.fragment';
import { LinkPreviewDtoFragment } from '../link-preview';
import { BlabItemCommentAttachmentFragment } from './blab-item-comment-attachment.fragment';

export const BlabItemCommentActivityFragment = gql`
  fragment BlabItemCommentActivityFragment on BlabItemCommentActivityDto {
    ...BlabItemActivityDtoFragment
    comment {
      ...BlabItemCommentDtoNoNesting
    }
    attachments {
      ...BlabItemCommentAttachmentFragment
    }
    linkPreview {
      ...LinkPreviewDtoFragment
    }
    clientSideId
  }
  ${BlabItemActivityDtoFragment}
  ${BlabItemCommentDtoNoNestingFragment}
  ${BlabItemCommentAttachmentFragment}
  ${LinkPreviewDtoFragment}
`;
