import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  ROUTE_FRAGMENT_ORGANIZATION_SIGNIN_SLUG,
  ROUTE_FRAGMENT_PUBLIC,
} from '@t5s/mobile-client/readonly-constant/public';
import { filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { first, map } from 'rxjs/operators';
import { ActiveUserSessionActions } from './active-user-session.actions';
import { selectActiveUserContextEstablished } from './active-user-session.selectors';

@Injectable()
export class ActiveUserContextGuard implements CanActivate {
  constructor(private readonly store$: Store, private readonly router: Router) {}

  canActivate() {
    this.store$.dispatch(ActiveUserSessionActions.initialize());

    return this.store$.select(selectActiveUserContextEstablished).pipe(
      filterOnlyPresent(),
      first(),
      map((ctxEstablished) => {
        return ctxEstablished
          ? true
          : this.router.createUrlTree([ROUTE_FRAGMENT_PUBLIC, ROUTE_FRAGMENT_ORGANIZATION_SIGNIN_SLUG]);
      }),
    );
  }
}
