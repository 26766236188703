import { NgModule } from '@angular/core';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { TextComponentModule } from '@t5s/mobile-client/ui/component/text';
import { StubItemComponent } from './stub-item.component';
import { StubItemsListComponent } from './stub-items-list.component';
import { StubTextComponent } from './stub-text.component';

const components = [StubItemComponent, StubItemsListComponent, StubTextComponent];

@NgModule({
  imports: [ComponentCommonModule, TextComponentModule],
  declarations: components,
  exports: components,
})
export class StubComponentModule {}
