import gql from 'graphql-tag';
import { BlabItemCommentActivityFragment } from '../blab-item-comment/blab-item-comment-activity.fragment';
import { BlabItemRevisionActivityFragment } from '../blab-item-revision/blab-item-revison-activity-dto.fragment';
import { BlabItemCreatedActivityFragment } from './blab-item-created-activity.fragment';
import { BlabItemDeletedActivityFragment } from './blab-item-deleted-activity.fragment';
import { BlabItemRestoredActivityFragment } from './blab-item-restored-activity.fragment';
import { BlabItemInvitationCreatedActivityFragment } from './blab-item-invitation-created-activity.fragment';

export const BlabItemActivityUnionTypeFragment = gql`
  fragment BlabItemActivityUnionTypeFragment on BlabItemActivityUnionType {
    ... on BlabItemCreatedActivityDto {
      ...BlabItemCreatedActivityFragment
    }

    ... on BlabItemDeletedActivityDto {
      ...BlabItemDeletedActivityFragment
    }

    ... on BlabItemRestoredActivityDto {
      ...BlabItemRestoredActivityFragment
    }

    ... on BlabItemCommentActivityDto {
      ...BlabItemCommentActivityFragment
    }

    ... on BlabItemRevisionActivityDto {
      ...BlabItemRevisionActivityFragment
    }
    ... on BlabItemInvitationCreatedActivityDto {
      ...BlabItemInvitationCreatedActivityFragment
    }
  }
  ${BlabItemCreatedActivityFragment}
  ${BlabItemDeletedActivityFragment}
  ${BlabItemRestoredActivityFragment}
  ${BlabItemCommentActivityFragment}
  ${BlabItemRevisionActivityFragment}
  ${BlabItemInvitationCreatedActivityFragment}
`;
