import {
  SubscribeToUpdateBlabItemsFlimValFailureEvent,
  SubscribeToUpdateBlabItemsFlimValSuccessEvent,
} from '@t5s/shared/gql';
import { isDto } from './dto.type-guard';

export function isSubscribeToUpdateBlabItemsFlimValSuccessEvent(
  candidate?: any,
): candidate is SubscribeToUpdateBlabItemsFlimValSuccessEvent {
  return isDto<SubscribeToUpdateBlabItemsFlimValSuccessEvent>(
    candidate,
    'SubscribeToUpdateBlabItemsFlimValSuccessEvent',
  );
}

export function isSubscribeToUpdateBlabItemsFlimValFailureEvent(
  candidate?: any,
): candidate is SubscribeToUpdateBlabItemsFlimValFailureEvent {
  return isDto<SubscribeToUpdateBlabItemsFlimValFailureEvent>(
    candidate,
    'SubscribeToUpdateBlabItemsFlimValFailureEvent',
  );
}
