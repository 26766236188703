import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PlatformObservable } from '@t5s/mobile-client/provider-token/device';
import { readSafeAreaDimensionsFromDom, safeAreaDimensionsValid } from '@t5s/mobile-client/util/safe-area';
import { filterOnlyTrue } from '@t5s/shared/util/rxjs';
import { timer } from 'rxjs';
import { filter, first, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { SafeAreaActions } from './safe-area.actions';
import { selectSafeAreaHasValidDimensions, selectSafeAreaHydrationAttempted } from './safe-area.selectors';

const MAX_NUM_RETRIES = 50;
const SAFE_AREA_POLLING_INTERVAL_MS = 200;

const DEFENSIVE_MAX_NUM_RETRIES = 4;
const DEFENSIVE_SAFE_AREA_POLLING_INTERVAL_MS = 5000;

@Injectable()
export class SafeAreaEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store,
    private readonly platform$: PlatformObservable,
    @Inject(DOCUMENT) private readonly document: Document,
  ) {}

  readonly onIOsPlatformStartDetectSafeAreaDimensions$ = createEffect(() =>
    this.platform$.pipe(
      filter(({ platform }) => platform === 'ios'),
      switchMap(() => this.store$.select(selectSafeAreaHydrationAttempted)), // wait for hydration to be performed
      map(SafeAreaActions.startDetectSafeAreaDimensions),
    ),
  );

  readonly startCheckDetectIosSafeArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SafeAreaActions.startDetectSafeAreaDimensions),
      concatLatestFrom(() => this.store$.select(selectSafeAreaHasValidDimensions)),
      map(([_, hasValidSafeAreaDimensions]) => {
        // on app launch, safe area dimensions may have been rehydrated. defensive detection should be performed
        if (hasValidSafeAreaDimensions) {
          return SafeAreaActions.defensiveDetectSafeAreaDimensions();
        }

        // ... aggressive detection otherwise:
        return SafeAreaActions.detectSafeAreaDimensions();
      }),
    ),
  );

  readonly detectIosSafeArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SafeAreaActions.detectSafeAreaDimensions),
      switchMap(() => {
        /** Terminate detection once valid safe area has been detected. */
        const hasDetectedValidDimensions$ = this.store$.select(selectSafeAreaHasValidDimensions).pipe(filterOnlyTrue());

        return timer(0, SAFE_AREA_POLLING_INTERVAL_MS).pipe(
          take(MAX_NUM_RETRIES),
          map(() => readSafeAreaDimensionsFromDom(this.document)),
          filter(safeAreaDimensionsValid),
          first(),
          map(SafeAreaActions.setSafeAreaDimensions),
          takeUntil(hasDetectedValidDimensions$),
        );
      }),
    ),
  );

  readonly defensiveDetectIosSafeArea$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SafeAreaActions.defensiveDetectSafeAreaDimensions),
      switchMap(() => {
        // defensive detection in case invalid safe are dimensions had been hydrated on app launch
        return timer(DEFENSIVE_SAFE_AREA_POLLING_INTERVAL_MS, DEFENSIVE_SAFE_AREA_POLLING_INTERVAL_MS).pipe(
          take(DEFENSIVE_MAX_NUM_RETRIES),
          map(() => readSafeAreaDimensionsFromDom(this.document)),
          filter(safeAreaDimensionsValid),
          map(SafeAreaActions.setSafeAreaDimensions),
        );
      }),
    ),
  );
}
