import gql from 'graphql-tag';
import {
  WorkflowDefPreviewDtoNoNestingFragment,
  WorkflowDefRevisionDtoNoNestingFragment,
  WorkflowRunDetailDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { WorkflowRunLogEventUnionFragment } from './workflow-run-log-event-union.fragment';

export const WorkflowRunDetailDtoFragment = gql`
  fragment WorkflowRunDetailDtoFragment on WorkflowRunDetailDto {
    ...WorkflowRunDetailDtoNoNesting

    workflowDefRevision {
      ...WorkflowDefRevisionDtoNoNesting
    }

    triggeredByWorkflowDef {
      ...WorkflowDefPreviewDtoNoNesting
      blabDef {
        ...BlabDefPreviewDtoNoNesting
        workspace {
          ...WorkspacePreviewDtoNoNesting
        }
      }
    }

    logs {
      ...WorkflowRunLogEventUnionFragment
    }
  }

  ${WorkflowRunDetailDtoNoNestingFragment}
  ${WorkflowDefPreviewDtoNoNestingFragment}
  ${WorkflowRunLogEventUnionFragment}
  ${WorkflowDefRevisionDtoNoNestingFragment}
`;
