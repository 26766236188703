import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ServiceStateHydrationModule } from '@t5s/mobile-client/service/state-hydration';
import { DirectNotificationsEffects } from './direct-notifications.effects';
import { directNotificationsReducer, directNotificationStateKey } from './direct-notifications.reducer';

@NgModule({
  imports: [
    ServiceStateHydrationModule,
    StoreModule.forFeature(directNotificationStateKey, directNotificationsReducer),
    EffectsModule.forFeature([DirectNotificationsEffects]),
  ],
})
export class DirectNotificationsModule {}
