import {
  AttachmentReferenceCreateInput,
  AttachmentReferenceUpsertInput,
  CreateBlabItemInput,
  CreateMultiAttachmentFlimValInput,
  CreateMultiImageFlimValInput,
  CreateNumberFlimValInput,
  CreateSingleAttachmentFlimValInput,
  CreateSingleTextFlimValInput,
  DateTime,
  FlimType,
  FlimValUnionType,
  ImageReferenceCreateInput,
  MultiAttachmentFlimDefDto,
  MultiImageFlimDefDto,
  MultiTextFlimDefDto,
  MultiUserFlimDefDto,
  NumberFlimDefDto,
  RangeDateFlimDefDto,
  SingleAttachmentFlimDefDto,
  SingleDateFlimDefDto,
  SingleLocationFlimDefDto,
  SingleTextFlimDefDto,
  SingleUserFlimDefDto,
  UpsertFlimValInputUnionType,
  UpsertMultiAttachmentFlimValInput,
  UpsertMultiTextFlimValInput,
  UpsertMultiUserFlimValInput,
  UpsertNumberFlimValInput,
  UpsertRangeDateFlimValInput,
  UpsertSingleDateFlimValInput,
  UpsertSingleLocationFlimValInput,
  UpsertSingleTextFlimValInput,
  UpsertSingleUserFlimValInput,
  UserReferenceUpsertInput,
} from '@t5s/shared/gql';
import { getCreateBlabItemInput } from './create-blab-item-input';

export class FlimValUtils {
  static readonly getCreateBlabItemInput = getCreateBlabItemInput;

  //SingleLocationFlimValUpsertInput is not derivable from FlimValUnionType, therefre the locationId is applied as an optional Param
  static getUpsertInputFromDto(flimVal: FlimValUnionType): UpsertFlimValInputUnionType {
    const { blabItemId, flimDefId } = flimVal;
    switch (flimVal.type) {
      case FlimType.SINGLE_TEXT: {
        const singleTextFlimVal = flimVal;
        return { blabItemId, flimDefId, value: singleTextFlimVal.value };
      }

      case FlimType.MULTI_TEXT: {
        const multiTextFlimVal = flimVal;
        return { blabItemId, flimDefId, value: multiTextFlimVal.value };
      }

      case FlimType.SINGLE_CATEGORY: {
        const { categoryOptionDefId } = flimVal;
        return { blabItemId, flimDefId, categoryOptionDefId };
      }

      case FlimType.MULTI_CATEGORY: {
        const { categoryOptionDefIds } = flimVal;
        return { blabItemId, flimDefId, categoryOptionDefIds };
      }

      case FlimType.SINGLE_RELATION: {
        const { referencedBlabItemId } = flimVal;
        return { blabItemId, flimDefId, referencedBlabItemId };
      }

      case FlimType.MULTI_RELATION: {
        const { blabItemReferences } = flimVal;
        return {
          blabItemId,
          flimDefId,
          blabItemReferences: (blabItemReferences || []).map(({ blabItem, position }) => ({
            blabItemId: blabItem.id,
            position,
          })),
        };
      }

      case FlimType.SINGLE_ATTACHMENT: {
        const { attachmentId } = flimVal;
        return {
          blabItemId,
          flimDefId,
          attachmentId,
        };
      }

      case FlimType.MULTI_ATTACHMENT: {
        const { attachmentReferences } = flimVal;
        return {
          blabItemId,
          flimDefId,
          attachmentReferences: (attachmentReferences || []).map(({ attachment, position }) => ({
            attachmentId: attachment.id,
            position,
          })),
        };
      }

      case FlimType.MULTI_IMAGE: {
        const { imageReferences } = flimVal;
        return {
          blabItemId,
          flimDefId,
          imageReferences: (imageReferences || []).map(({ image, position }) => ({
            imageId: image.id,
            position,
          })),
        };
      }

      case FlimType.SINGLE_USER: {
        const { userId } = flimVal;
        return {
          blabItemId,
          flimDefId,
          userId,
        };
      }

      case FlimType.MULTI_USER: {
        const { userReferences } = flimVal;
        return {
          blabItemId,
          flimDefId,
          userReferences: (userReferences || []).map(({ user, position }) => ({
            userId: user.id,
            position,
          })),
        };
      }

      case FlimType.SINGLE_DATE: {
        const { date, hasTime } = flimVal;
        return { blabItemId, flimDefId, date, hasTime };
      }

      case FlimType.RANGE_DATE: {
        const { startDate, endDate, hasTime } = flimVal;
        if (!endDate) {
          return { blabItemId, flimDefId, startDate, hasTime };
        } else {
          return { blabItemId, flimDefId, startDate, hasTime, endDate };
        }
      }

      case FlimType.NUMBER: {
        const numberFlimVal = flimVal;
        return { blabItemId, flimDefId, decimal: numberFlimVal.decimal };
      }

      case FlimType.STATUS: {
        const statusFlimVal = flimVal;
        return { blabItemId, flimDefId, statusOptionDefId: statusFlimVal.statusOptionDefId };
      }

      case FlimType.SINGLE_LOCATION: {
        const singleLocationFlimVal = flimVal;
        const { mapEmbedUrl, locationId, hideMapEmbed, originalFormattedAddress } = singleLocationFlimVal;

        return {
          blabItemId,
          flimDefId,
          city: singleLocationFlimVal.city,
          country: singleLocationFlimVal.country,
          postalCode: singleLocationFlimVal.postalCode,
          state: singleLocationFlimVal.state,
          streetAddress: singleLocationFlimVal.streetAddress ? singleLocationFlimVal.streetAddress : undefined,
          originalFormattedAddress,
          hideMapEmbed: hideMapEmbed || !mapEmbedUrl,
          locationId: locationId ?? undefined,
        } as UpsertSingleLocationFlimValInput;
      }
      default: {
        throw new Error(`getUpsertInputFromDto not implemented for flimtype ${flimVal.type}`);
      }
    }
  }

  static getCreateSingleAttachmentFlimValInput(
    attachmentId: number,
    flimDef: SingleAttachmentFlimDefDto,
  ): Partial<CreateBlabItemInput> {
    const flimValsInput: CreateSingleAttachmentFlimValInput = {
      flimDefId: flimDef.id,
      attachmentId,
    };
    return {
      singleAttachmentFlimVals: [flimValsInput],
    };
  }

  static getCreateMultiAttachmentFlimValInput(
    attachmentReferences: AttachmentReferenceCreateInput[],
    flimDef: MultiAttachmentFlimDefDto,
  ): Partial<CreateBlabItemInput> {
    const flimValsInput: CreateMultiAttachmentFlimValInput = {
      flimDefId: flimDef.id,
      attachmentReferences,
    };

    return {
      multiAttachmentFlimVals: [flimValsInput],
    };
  }

  static getCreateMultiImageFlimValInput(
    imageReferences: ImageReferenceCreateInput[],
    flimDef: MultiImageFlimDefDto,
  ): Partial<CreateBlabItemInput> {
    const flimValsInput: CreateMultiImageFlimValInput = {
      flimDefId: flimDef.id,
      imageReferences,
    };

    return {
      multiImageFlimVals: [flimValsInput],
    };
  }

  static getUpsertMultiAttachmentFlimValInput(
    blabItemId: number,
    attachmentReferences: AttachmentReferenceUpsertInput[],
    flimDef: MultiAttachmentFlimDefDto,
  ): UpsertMultiAttachmentFlimValInput {
    return {
      blabItemId,
      flimDefId: flimDef.id,
      attachmentReferences,
    };
  }

  static getCreateSingleTextFlimValInput(value: string, flimDef: SingleTextFlimDefDto): Partial<CreateBlabItemInput> {
    const flimValsInput: CreateSingleTextFlimValInput = {
      flimDefId: flimDef.id,
      value,
    };
    return {
      singleTextFlimVals: [flimValsInput],
    };
  }

  static getCreateNumberFlimValInput(decimal: number, flimDef: NumberFlimDefDto): Partial<CreateBlabItemInput> {
    const flimValsInput: CreateNumberFlimValInput = {
      flimDefId: flimDef.id,
      decimal,
    };
    return {
      numberFlimVals: [flimValsInput],
    };
  }

  static getUpsertSingleTextFlimValInput(
    value: string | undefined,
    flimDef: SingleTextFlimDefDto,
    blabItemId: number,
  ): UpsertSingleTextFlimValInput {
    const flimValsInput: UpsertSingleTextFlimValInput = {
      blabItemId,
      flimDefId: flimDef.id,
      value,
    };
    return flimValsInput;
  }

  static getUpsertNumberFlimValInput(
    decimal: number | undefined,
    flimDef: NumberFlimDefDto,
    blabItemId: number,
  ): UpsertNumberFlimValInput {
    const flimValsInput: UpsertNumberFlimValInput = {
      blabItemId,
      flimDefId: flimDef.id,
      decimal,
    };
    return flimValsInput;
  }

  static getUpsertSingleDateFlimValInput(
    flimDef: SingleDateFlimDefDto,
    blabItemId: number,
    date?: DateTime,
    hasTime?: boolean,
  ): UpsertSingleDateFlimValInput {
    const flimValsInput: UpsertSingleDateFlimValInput = {
      blabItemId,
      flimDefId: flimDef.id,
      date,
      hasTime,
    };
    return flimValsInput;
  }

  static getUpsertRangeDateFlimValInput(
    flimDef: RangeDateFlimDefDto,
    blabItemId: number,
    startDate: DateTime | undefined,
    endDate: DateTime | undefined,
    hasTime: boolean,
  ): UpsertRangeDateFlimValInput {
    const flimValsInput: UpsertRangeDateFlimValInput = {
      blabItemId,
      flimDefId: flimDef.id,
      endDate,
      startDate,
      hasTime,
    };
    return flimValsInput;
  }

  static getUpsertSingleUserFlimValInput(
    userId: number | undefined,
    flimDef: SingleUserFlimDefDto,
    blabItemId: number,
  ): UpsertSingleUserFlimValInput {
    const flimValsInput: UpsertSingleUserFlimValInput = {
      blabItemId,
      flimDefId: flimDef.id,
      userId,
    };
    return flimValsInput;
  }

  static getUpsertMultiTextFlimValInput(
    value: string | undefined,
    flimDef: MultiTextFlimDefDto,
    blabItemId: number,
  ): UpsertMultiTextFlimValInput {
    const flimValsInput: UpsertMultiTextFlimValInput = {
      blabItemId,
      flimDefId: flimDef.id,
      value,
    };
    return flimValsInput;
  }

  static getUpsertMultiUserFlimValInput(
    userReferences: UserReferenceUpsertInput[] | undefined,
    flimDef: MultiUserFlimDefDto,
    blabItemId: number,
  ): UpsertMultiUserFlimValInput {
    const flimValsInput: UpsertMultiUserFlimValInput = {
      blabItemId,
      flimDefId: flimDef.id,
      userReferences,
    };
    return flimValsInput;
  }

  static getUpsertSingleLocationFlimValInput({
    blabItemId,
    flimDef,
    city,
    country,
    hideMapEmbed,
    locationId,
    originalFormattedAddress,
    postalCode,
    state,
    streetAddress,
  }: {
    flimDef: SingleLocationFlimDefDto;
    blabItemId: number;
    city?: string;
    country?: string;
    postalCode?: string;
    state?: string;
    streetAddress?: string;
    hideMapEmbed?: boolean;
    locationId?: string;
    originalFormattedAddress?: string;
  }): UpsertSingleLocationFlimValInput {
    const flimValsInput: UpsertSingleLocationFlimValInput = {
      blabItemId,
      flimDefId: flimDef.id,
      city,
      country,
      postalCode,
      state,
      streetAddress: streetAddress ? streetAddress : undefined,
      hideMapEmbed,
      locationId,
      originalFormattedAddress,
    };
    return flimValsInput;
  }
}
