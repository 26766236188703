import gql from 'graphql-tag';
import { UserSessionFragment } from '../user-session';

export const UserAuthenticationResultFragment = gql`
  fragment UserAuthenticationResultFragment on AuthenticateWithCredentialsResult {
    ... on UserSessionDto {
      ...UserSessionFragment
    }
    ... on AuthenticateWithCredentialsRejectionDto {
      reason
    }
  }
  ${UserSessionFragment}
`;
