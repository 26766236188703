import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {
  DeviceObservable,
  PlatformAndroidObservable,
  PlatformIosObservable,
  PlatformObservable,
  PlatformWebObservable,
} from '@t5s/mobile-client/provider-token/device';
import { ViewportDimensionsObservable } from '@t5s/mobile-client/provider-token/viewport-dimensions';
import { DeviceService, ServiceDeviceModule } from '@t5s/mobile-client/service/device';
import { isPlatformAndroid, isPlatformIos, isPlatformWeb, isTouchDevice } from '@t5s/mobile-client/util/device';
import { distinctUntilChangedSerialize } from '@t5s/shared/util/rxjs';
import { debounceTime, filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { DeviceEffects } from './device.effects';
import { deviceReducer, deviceStateKey } from './device.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(deviceStateKey, deviceReducer),
    EffectsModule.forFeature([DeviceEffects]),
    ServiceDeviceModule,
  ],
  providers: [
    {
      provide: DeviceObservable,
      useFactory: (device: DeviceService, viewportDimensions$: ViewportDimensionsObservable): DeviceObservable =>
        device.deviceInfo$.pipe(
          switchMap((info) =>
            viewportDimensions$.pipe(
              debounceTime(250),
              map(() => ({
                ...info,
                isTouch: isTouchDevice(),
              })),
            ),
          ),
          distinctUntilChangedSerialize(),
          shareReplay(1),
        ),
      deps: [DeviceService, ViewportDimensionsObservable],
    },
    {
      provide: PlatformObservable,
      useFactory: (device$: DeviceObservable): PlatformObservable =>
        device$.pipe(
          map(({ platform }) => ({ platform })),
          distinctUntilChangedSerialize(),
          shareReplay(1),
        ),
      deps: [DeviceObservable],
    },
    {
      provide: PlatformAndroidObservable,
      useFactory: (platform$: PlatformObservable): PlatformAndroidObservable =>
        platform$.pipe(
          map(({ platform }) => ({ platform })),
          filter(({ platform }) => isPlatformAndroid(platform)),
          distinctUntilChangedSerialize(),
          shareReplay(1),
        ),
      deps: [DeviceObservable],
    },
    {
      provide: PlatformIosObservable,
      useFactory: (platform$: PlatformObservable): PlatformIosObservable =>
        platform$.pipe(
          map(({ platform }) => ({ platform })),
          filter(({ platform }) => isPlatformIos(platform)),
          distinctUntilChangedSerialize(),
          shareReplay(1),
        ),
      deps: [DeviceObservable],
    },
    {
      provide: PlatformWebObservable,
      useFactory: (platform$: PlatformObservable): PlatformWebObservable =>
        platform$.pipe(
          map(({ platform }) => ({ platform })),
          filter(({ platform }) => isPlatformWeb(platform)),
          distinctUntilChangedSerialize(),
          shareReplay(1),
        ),
      deps: [DeviceObservable],
    },
  ],
})
export class BusinessLogicDeviceModule {}
