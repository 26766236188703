import { Inject, Injectable } from '@angular/core';
import { LiveSubscriptionContextObservable } from '@t5s/client/provider-token/live-subscription-context';
import {
  LiveSubscriptionContext,
  LiveSubscriptionContextMissingException,
} from '@t5s/client/value-object/live-subscription-context';
import { ClientOptions, Middleware, SubscriptionClient } from 'subscriptions-transport-ws';
import { GRAPHQL_ENDPOINT } from '../injection-tokens';

@Injectable()
export class ApolloSubscriptionService {
  constructor(@Inject(GRAPHQL_ENDPOINT) gqlUri: string, context$: LiveSubscriptionContextObservable) {
    const wsUri = this.getWsUriFromGqlUri(gqlUri);

    this.client = this.createSubscriptionClient(wsUri);
    this.client.use([this.contextMiddleware]);

    context$.subscribe((context) => {
      this.context = context;
    });
  }

  readonly client: SubscriptionClient;
  private context: LiveSubscriptionContext | undefined = undefined;

  private readonly contextMiddleware: Middleware = {
    applyMiddleware: (options, next) => {
      // currently, all live subscriptions are authenticated. Thereby, without proper context the subscription will fail.
      if (!this.context) {
        throw new LiveSubscriptionContextMissingException(
          'Unable to set live subscription context. Ensure that subscriptions are only initiated once context is available.',
        );
      }

      options.context = this.context;

      return next();
    },
  };

  private getWsUriFromGqlUri(gqlUri: string): string {
    return gqlUri.replace('http', 'ws').replace('4200', '3000').replace('4201', '3000');
  }

  /** Creates a dynamic `SubsriptionClient` instance using a qualified websocket URI, e.g. `ws://localhost:1234`. */
  private createSubscriptionClient(wsUri: string) {
    const options: ClientOptions = {
      lazy: true,
      reconnect: true,
      inactivityTimeout: 0, // do not disconnect on inactivity (no active subscriptions)
    };
    return new SubscriptionClient(wsUri, options);
  }
}
