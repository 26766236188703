import { ActionCreatorProps, createAction, NotAllowedCheck, props } from '@ngrx/store';
import { ERROR_PREFIX } from './error-prefix';

export interface ErrorPayload {
  error: unknown;
}

export const ERROR_ACTION = createAction(`${ERROR_PREFIX} Generic unhandled application error`, props<ErrorPayload>());

type ErrorActionCreator = typeof ERROR_ACTION;
export type ErrorActionType = ReturnType<typeof ERROR_ACTION>;

export const createErrorAction = <T extends string, P extends {}>(
  name: T,
  _props: ActionCreatorProps<P> = props() as any,
) =>
  createAction<string, P & ErrorPayload>(
    `${ERROR_PREFIX}${name as string}`,
    _props as ActionCreatorProps<P & ErrorPayload> & NotAllowedCheck<P & ErrorPayload>,
  );
