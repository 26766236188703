import { Directive, ElementRef } from '@angular/core';
import { px } from '@t5s/client/ui/style/common';

@Directive({
  selector: '[t5sFlexOneVertical]',
  exportAs: 't5sFlexOneVertical',
})
export class FlexOneVerticalDirective {
  constructor(el: ElementRef<HTMLElement>) {
    el.nativeElement.style.setProperty('flex', '1');
    el.nativeElement.style.setProperty('min-height', px(0));
  }
}
