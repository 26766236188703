import gql from 'graphql-tag';
import { UserNotificationDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { FullUserProfileDtoFragment } from '../user-profile';
import { UserNotificationActivityDtoFragment } from './user-notification-activity-dto.fragment';

export const UserNotificationDtoFragment = gql`
  fragment UserNotificationDtoFragment on UserNotificationDto {
    ...UserNotificationDtoNoNesting
    user {
      ...FullUserProfileDto
    }
    activities(first: 1) {
      ...UserNotificationActivityDtoFragment
    }
  }
  ${UserNotificationDtoNoNestingFragment}
  ${UserNotificationActivityDtoFragment}
  ${FullUserProfileDtoFragment}
`;
