import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { UntilDestroy } from '@t5s/client/util/component-until-destroy';
import { NetworkService } from '@t5s/mobile-client/service/network';
import { map } from 'rxjs/operators';
import { NetworkStatusActions } from './network-status.actions';

@UntilDestroy()
@Injectable()
export class NetworkStatusEffects {
  constructor(private readonly network: NetworkService) {}

  readonly setNetworkStatus$ = createEffect(() =>
    this.network.status$.pipe(map((status) => NetworkStatusActions.setNetworkStatus({ status }))),
  );
}
