import { px } from './units.util';

/**
 * Fix the bottom spacing that appears when an element contains an image
 * In theory, line-height: 0 is the only property that matters to fix this problem
 * We set the other properties as a security in case other browsers have issues
 */
export const noSpaceImageContainer = {
  lineHeight: px(0),
  display: 'flex',
  padding: px(0),
  margin: px(0),
} as const;
