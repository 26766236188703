import {
  ChecklistFlimValDto,
  CreateFlimValInputUnionType,
  CreateSingleLocationFlimValInput,
  FlimType,
  FlimValDto,
  MultiAttachmentFlimValDto,
  MultiCategoryFlimValDto,
  MultiImageFlimValDto,
  MultiRelationFlimValDto,
  MultiTextFlimValDto,
  MultiUserFlimValDto,
  NumberFlimValDto,
  RangeDateFlimValDto,
  SingleAttachmentFlimValDto,
  SingleCategoryFlimValDto,
  SingleDateFlimValDto,
  SingleLocationFlimValDto,
  SingleRelationFlimValDto,
  SingleTextFlimValDto,
  SingleUserFlimValDto,
  StatusFlimValDto,
} from '@t5s/shared/gql';
import { getFlimValsOfType } from './flim-val-of-type';

/** Creates an array of upsertInputs from flimValDtos. */
export function getUpsertFlimValInputsOfType<T extends CreateFlimValInputUnionType>(
  flimValDtos: FlimValDto[],
  flimType: FlimType,
): T[] {
  switch (flimType) {
    case FlimType.SINGLE_TEXT: {
      const textFlimDefDtos = getFlimValsOfType<SingleTextFlimValDto>(flimValDtos, flimType);
      return textFlimDefDtos.map(({ value, flimDefId }: SingleTextFlimValDto) => ({
        value,
        flimDefId,
      })) as T[];
    }
    case FlimType.MULTI_TEXT: {
      const textFlimDefDtos = getFlimValsOfType<MultiTextFlimValDto>(flimValDtos, flimType);
      return textFlimDefDtos.map(({ value, flimDefId }: MultiTextFlimValDto) => ({
        value,
        flimDefId,
      })) as T[];
    }

    case FlimType.SINGLE_CATEGORY: {
      const categoryFlimValDtos = getFlimValsOfType<SingleCategoryFlimValDto>(flimValDtos, flimType);
      return categoryFlimValDtos.map(({ categoryOptionDefId, flimDefId }: SingleCategoryFlimValDto) => ({
        categoryOptionDefId,
        flimDefId,
      })) as T[];
    }

    case FlimType.MULTI_CATEGORY: {
      const categoryFlimValDtos = getFlimValsOfType<MultiCategoryFlimValDto>(flimValDtos, flimType);
      return categoryFlimValDtos.map(({ categoryOptionDefIds, flimDefId }: MultiCategoryFlimValDto) => ({
        categoryOptionDefIds,
        flimDefId,
      })) as T[];
    }

    case FlimType.SINGLE_RELATION: {
      const singleRelationFlimValDtos = getFlimValsOfType<SingleRelationFlimValDto>(flimValDtos, flimType);
      return singleRelationFlimValDtos.map(({ flimDefId, referencedBlabItemId }: SingleRelationFlimValDto) => ({
        flimDefId,
        referencedBlabItemId,
      })) as T[];
    }
    case FlimType.MULTI_RELATION: {
      const multiRelationFlimValDtos = getFlimValsOfType<MultiRelationFlimValDto>(flimValDtos, flimType);
      return multiRelationFlimValDtos.map(({ blabItemReferences, flimDefId }: MultiRelationFlimValDto) => ({
        flimDefId,
        blabItemReferences: (blabItemReferences || []).map(({ blabItem, position }) => ({
          blabItemId: blabItem.id,
          position,
        })),
      })) as T[];
    }

    case FlimType.SINGLE_ATTACHMENT: {
      const singleAttachmentFlimValDtos = getFlimValsOfType<SingleAttachmentFlimValDto>(flimValDtos, flimType);
      return singleAttachmentFlimValDtos.map(({ flimDefId, attachmentId }: SingleAttachmentFlimValDto) => ({
        flimDefId,
        attachmentId,
      })) as T[];
    }

    case FlimType.MULTI_ATTACHMENT: {
      const multiAttachmentFlimValDtos = getFlimValsOfType<MultiAttachmentFlimValDto>(flimValDtos, flimType);
      return multiAttachmentFlimValDtos.map(({ flimDefId, attachmentReferences }: MultiAttachmentFlimValDto) => ({
        flimDefId,
        attachmentReferences: (attachmentReferences || []).map(({ attachment, position }) => ({
          attachmentId: attachment.id,
          position,
        })),
      })) as T[];
    }

    case FlimType.MULTI_IMAGE: {
      const multiImageFlimValDtos = getFlimValsOfType<MultiImageFlimValDto>(flimValDtos, flimType);
      return multiImageFlimValDtos.map(({ flimDefId, imageReferences }: MultiImageFlimValDto) => ({
        flimDefId,
        imageReferences: (imageReferences || []).map(({ image, position }) => ({
          imageId: image.id,
          position,
        })),
      })) as T[];
    }

    case FlimType.SINGLE_USER: {
      const singleUserFlimValDtos = getFlimValsOfType<SingleUserFlimValDto>(flimValDtos, flimType);
      return singleUserFlimValDtos.map(({ flimDefId, userId }: SingleUserFlimValDto) => ({
        flimDefId,
        userId,
      })) as T[];
    }

    case FlimType.MULTI_USER: {
      const multiUserFlimValDtos = getFlimValsOfType<MultiUserFlimValDto>(flimValDtos, flimType);
      return multiUserFlimValDtos.map(({ flimDefId, userReferences }: MultiUserFlimValDto) => ({
        flimDefId,
        userReferences: (userReferences || []).map(({ user, position }) => ({
          userId: user.id,
          position,
        })),
      })) as T[];
    }

    case FlimType.SINGLE_DATE: {
      const singleDateFlimValDtos = getFlimValsOfType<SingleDateFlimValDto>(flimValDtos, flimType);
      return singleDateFlimValDtos.map(({ flimDefId, date, hasTime }: SingleDateFlimValDto) => ({
        flimDefId,
        date,
        hasTime,
      })) as T[];
    }

    case FlimType.RANGE_DATE: {
      const rangeDateFlimValDtos = getFlimValsOfType<RangeDateFlimValDto>(flimValDtos, flimType);
      return rangeDateFlimValDtos.map(({ flimDefId, startDate, endDate, hasTime }: RangeDateFlimValDto) => ({
        flimDefId,
        startDate,
        endDate,
        hasTime,
      })) as T[];
    }

    case FlimType.CHECKLIST: {
      const checklistFlimValDtos = getFlimValsOfType<ChecklistFlimValDto>(flimValDtos, flimType);
      return checklistFlimValDtos.map(({ flimDefId, checklistEntries }: ChecklistFlimValDto) => ({
        flimDefId,
        // TODO:
      })) as T[];
    }

    case FlimType.NUMBER: {
      const numberFlimValDtos = getFlimValsOfType<NumberFlimValDto>(flimValDtos, flimType);
      return numberFlimValDtos.map(({ flimDefId, decimal }: NumberFlimValDto) => ({
        flimDefId,
        decimal,
      })) as T[];
    }

    case FlimType.STATUS: {
      const statusFlimValDtos = getFlimValsOfType<StatusFlimValDto>(flimValDtos, flimType);
      return statusFlimValDtos.map(({ statusOptionDefId, flimDefId }: StatusFlimValDto) => ({
        statusOptionDefId,
        flimDefId,
      })) as T[];
    }

    case FlimType.SINGLE_LOCATION: {
      const statusFlimValDtos = getFlimValsOfType<SingleLocationFlimValDto>(flimValDtos, flimType);
      return statusFlimValDtos.map(
        ({
          flimDefId,
          city,
          country,
          originalFormattedAddress,
          postalCode,
          state,
          streetAddress,
        }: SingleLocationFlimValDto): CreateSingleLocationFlimValInput => ({
          flimDefId,
          city,
          country,
          originalFormattedAddress,
          postalCode,
          state,
          streetAddress,
        }),
      ) as T[];
    }

    default:
      throw new Error(`FlimType ${flimType} not implemented for getUpsertFlimValInputsOfType!`);
  }
}
