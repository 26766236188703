import { createAction, props } from '@ngrx/store';
import { SafeAreaDimensions } from '@t5s/mobile-client/value-object/safe-area';
import { SafeAreaStateHydrationPersistance } from './safe-area-hydration.state';

const PREFIX = '[mobile-client-safe-area]';

export const SafeAreaActions = {
  startDetectSafeAreaDimensions: createAction(`${PREFIX} start to detect safe area dimensions`),
  detectSafeAreaDimensions: createAction(`${PREFIX} detect safe area dimensions`),
  defensiveDetectSafeAreaDimensions: createAction(`${PREFIX} defensively detect safe area dimensions`),

  setSafeAreaDimensions: createAction(`${PREFIX} set safe area dimensions`, props<SafeAreaDimensions>()),

  // State hydration
  hydrateState: createAction(`${PREFIX} Hydrate state`),
  hydrateStateSuccess: createAction(
    `${PREFIX} Hydrate state Success`,
    props<{ state: SafeAreaStateHydrationPersistance }>(),
  ),
  hydrateStateNotAvailable: createAction(`${PREFIX} Hydration of state not available`),
  hydrateStateException: createAction(`${PREFIX} Hydration of state failed with exception`),
};
