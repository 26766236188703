import gql from 'graphql-tag';
import {
  ActiveUserBlabDefInfoDtoNoNestingFragment,
  ActiveUserSplitByCompletedDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { ActiveUserBlabDefInfoActiveBlabViewUnionTypeFragment } from './active-user-blab-def-info-active-blab-view-union-type.fragment';
import { ActiveUserSplitBySectionUnionTypeFragment } from './split-by-section/active-user-split-by-section-union-type.fragment';

export const ActiveUserBlabDefInfoFragment = gql`
  fragment ActiveUserBlabDefInfo on ActiveUserBlabDefInfoDto {
    ...ActiveUserBlabDefInfoDtoNoNesting
    activeBlabView {
      ...ActiveUserBlabDefInfoActiveBlabViewUnionType
    }
    activeBlabViewSplitBySection {
      ...ActiveUserSplitBySectionUnionType
    }
    activeBlabViewSplitByCompleted {
      ...ActiveUserSplitByCompletedDtoNoNesting
    }
  }
  ${ActiveUserBlabDefInfoDtoNoNestingFragment}
  ${ActiveUserBlabDefInfoActiveBlabViewUnionTypeFragment}
  ${ActiveUserSplitBySectionUnionTypeFragment}
  ${ActiveUserSplitByCompletedDtoNoNestingFragment}
`;
