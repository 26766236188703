import { FontSet } from '../constants';
import { px } from './units.util';

/**
 * Get the corresponding CSS propoerties for a given font-set
 * @param fontSet fontSet to translate into CSS properties
 */
export function getFontStyle(fontSet?: FontSet | undefined | null) {
  if (!fontSet) {
    return {};
  }

  return {
    fontSize: px(fontSet.fontSize),
    lineHeight: px(fontSet.lineHeight),
    fontWeight: fontSet.fontWeight,
  } as const;
}
