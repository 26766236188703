import { createFeatureSelector, createSelector } from '@ngrx/store';
import { lastCursorFromNotifications } from '../common';
import { filterSupportedNotifications } from '../common/notification-supported.util';
import {
  adapter,
  AllNotificationsState,
  allNotificationStateKey,
  preprocessStateForSerialization,
} from './all-notifications.reducer';

const selectAllNotificationsFeatureState = createFeatureSelector<AllNotificationsState>(allNotificationStateKey);

export const selectAllNotificationsFeatureStateForPersistance = createSelector(
  selectAllNotificationsFeatureState,
  preprocessStateForSerialization,
);

const { selectAll } = adapter.getSelectors();
export const _selectAllNotifications = createSelector(selectAllNotificationsFeatureState, selectAll);
export const selectAllNotifications = createSelector(_selectAllNotifications, filterSupportedNotifications);

export const selectAllNotificationsInitialized = createSelector(
  selectAllNotificationsFeatureState,
  ({ initialized }) => initialized,
);

export const selectAllNotificationsLoading = createSelector(
  selectAllNotificationsFeatureState,
  ({ isLoading, hydrated }) => isLoading && !hydrated,
);

export const selectAllNotificationsReloading = createSelector(
  selectAllNotificationsFeatureState,
  ({ isReplaceLoading }) => isReplaceLoading,
);

export const selectAllNotificationsReloadingException = createSelector(
  selectAllNotificationsFeatureState,
  ({ loadReplaceException }) => loadReplaceException,
);

export const selectAllNotificationsLoadingMore = createSelector(
  selectAllNotificationsFeatureState,
  ({ isLoadingMore }) => isLoadingMore,
);

const selectHasNextPage = createSelector(selectAllNotificationsFeatureState, ({ pageInfo }) => pageInfo?.hasNextPage);

const selectNotificationCursor = createSelector(_selectAllNotifications, lastCursorFromNotifications);

export const selectLoadMoreInput = createSelector(
  selectHasNextPage,
  selectNotificationCursor,
  (hasNextPage, cursor): { cursor: string } | undefined => {
    if (!hasNextPage) {
      return undefined;
    }

    if (!cursor) {
      return undefined;
    }

    return { cursor };
  },
);
