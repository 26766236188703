import { createAction, props } from '@ngrx/store';
import { BottomSheetPosition } from '@t5s/mobile-client/value-object/bottom-sheet';

const PREFIX = '[mobile-client-root]';

export const RootActions = {
  setModalOpen: createAction(`${PREFIX} set modal open`, props<{ modalOpen: boolean }>()),

  setSheetPosition: createAction(`${PREFIX} set sheet open`, props<{ sheetPosition: BottomSheetPosition }>()),
};
