import { createAction, props } from '@ngrx/store';
import { createErrorAction } from '@t5s/client/util/store';
import { BlabItemActivityReplyInputAttachmentValobj } from '@t5s/mobile-client/value-object/blab-item-activity-thread';
import {
  BlabItemActivityDto,
  BlabItemActivityReplyAttachmentDto,
  BlabItemActivityReplyDto,
  BlabItemActivityReplyEdge,
  BlabItemActivityReplySubscriptionUpdate,
  BlabItemActivitySubscriptionUpdate,
  BlabItemActivityUnionType,
  BlabItemCommentActivityDto,
  PageInfo,
  UserProfilePreviewDto,
} from '@t5s/shared/gql';

const PREFIX = '[mobile-client-blab-item-activity-thread]';

export const BlabItemActivityThreadActions = {
  reset: createAction(`${PREFIX} reset`),

  // blabItem activity
  loadBlabItemActivity: createAction(
    `${PREFIX} load thread blab item activity`,
    props<{ blabItemActivityId: number }>(),
  ),
  reloadBlabItemActivity: createAction(
    `${PREFIX} reload thread blab item activity`,
    props<{ blabItemActivityId: number }>(),
  ),
  loadBlabItemActivitySuccess: createAction(
    `${PREFIX} loaded thread blab item activity successfully`,
    props<{ blabItemActivityId: number; blabItemActivity: BlabItemActivityUnionType }>(),
  ),
  loadBlabItemActivityException: createErrorAction(
    `${PREFIX} loading thread blab item activity failed with exception`,
    props<{ blabItemActivityId: number }>(),
  ),

  // activity replies
  loadActivityReplies: createAction(
    `${PREFIX} load thread blab item activity replies`,
    props<{ blabItemActivityId: number }>(),
  ),
  reloadActivityReplies: createAction(
    `${PREFIX} reload thread blab item activity replies`,
    props<{ blabItemActivityId: number }>(),
  ),
  loadActivityRepliesSuccess: createAction(
    `${PREFIX} loaded thread blab item activity replies successfully`,
    props<{ blabItemActivityId: number; edges: BlabItemActivityReplyEdge[]; pageInfo: PageInfo }>(),
  ),
  loadActivityRepliesException: createErrorAction(
    `${PREFIX} loading thread blab item activity replies failed with exception`,
    props<{ blabItemActivityId: number }>(),
  ),

  // blab item activity live subscription (thread host activity)
  subscribeToBlabItemActivity: createAction(
    `${PREFIX} Subscribe to blabItem activity`,
    props<{ blabItemId: number; blabItemActivityId: number }>(),
  ),
  subscribeToBlabItemActivitySuccess: createAction(
    `${PREFIX} Blab item activity live update subscription established`,
    props<{ blabItemId: number }>(),
  ),
  subscribeToBlabItemActivityException: createErrorAction(`${PREFIX} Subscribing to blabItem activity failed`),
  unsubscribeFromBlabItemActivity: createAction(
    `${PREFIX} Unsubscribe from blabItem activity`,
    props<{ blabItemId: number }>(),
  ),
  blabItemActivityLiveUpdate: createAction(
    `${PREFIX} BlabItem activity update received`,
    props<{ blabItemActivityId: number; update: BlabItemActivitySubscriptionUpdate }>(),
  ),
  blabItemActivityDeletedLiveUpdate: createAction(
    `${PREFIX} BlabItem activity update received (thread host activity was deleted)`,
    props<{ activityId: number }>(),
  ),

  // blab item activity replies live subscription
  subscribeToBlabItemActivityThread: createAction(
    `${PREFIX} Subscribe to blabItem activity thread`,
    props<{ blabItemActivityId: number }>(),
  ),
  subscribeToBlabItemActivityThreadSuccess: createAction(
    `${PREFIX} Blab item activity thread live update subscription established`,
    props<{ blabItemActivityId: number }>(),
  ),
  subscribeToBlabItemActivityThreadException: createErrorAction(
    `${PREFIX} Subscribing to blabItem activity thread failed`,
  ),
  unsubscribeFromBlabItemActivityThread: createAction(
    `${PREFIX} Unsubscribe from blabItem activity thread`,
    props<{ blabItemActivityId: number }>(),
  ),
  blabItemActivityThreadLiveUpdate: createAction(
    `${PREFIX} BlabItem activity thread update received`,
    props<{ blabItemActivityId: number; update: BlabItemActivityReplySubscriptionUpdate }>(),
  ),

  // BlabItem activity reaction
  addBlabItemActivityReaction: createAction(
    `${PREFIX} add blab item activity reaction`,
    props<{ emoji: string; activityId: number }>(),
  ),
  addBlabItemActivityReactionSuccess: createAction(
    `${PREFIX} add blab item activity reaction success`,
    props<{ emoji: string; activity: BlabItemActivityDto }>(),
  ),
  addBlabItemActivityReactionException: createErrorAction(`${PREFIX} add blab item activity reaction exception`),

  removeBlabItemActivityReaction: createAction(
    `${PREFIX} remove blab item activity reaction`,
    props<{ emoji: string; activityId: number }>(),
  ),
  removeBlabItemActivityReactionSuccess: createAction(
    `${PREFIX} remove blab item activity reaction success`,
    props<{ emoji: string; activity: BlabItemActivityDto }>(),
  ),
  removeBlabItemActivityReactionException: createErrorAction(`${PREFIX} remove blab item activity reaction exception`),

  // BlabItem activity reply reaction
  addBlabItemActivityReplyReaction: createAction(
    `${PREFIX} add blab item activity reply reaction`,
    props<{ emoji: string; replyId: number; activity: BlabItemActivityUnionType }>(),
  ),
  addBlabItemActivityReplyReactionSuccess: createAction(
    `${PREFIX} add blab item activity reply reaction success`,
    props<{ emoji: string; reply: BlabItemActivityReplyDto; activity: BlabItemActivityUnionType }>(),
  ),
  addBlabItemActivityReplyReactionException: createErrorAction(
    `${PREFIX} add blab item activity reply reaction exception`,
  ),

  removeBlabItemActivityReplyReaction: createAction(
    `${PREFIX} remove blab item activity reply reaction`,
    props<{ emoji: string; replyId: number; activity: BlabItemActivityUnionType }>(),
  ),
  removeBlabItemActivityReplyReactionSuccess: createAction(
    `${PREFIX} remove blab item activity reply reaction success`,
    props<{ emoji: string; reply: BlabItemActivityReplyDto; activity: BlabItemActivityUnionType }>(),
  ),
  removeBlabItemActivityReplyReactionException: createErrorAction(
    `${PREFIX} remove blab item activity reply reaction exception`,
  ),

  // Reply input
  setReplyInputContent: createAction(
    `${PREFIX} set reply input content`,
    props<{ activityId: number; content: string }>(),
  ),
  sendBlabItemActivityReply: createAction(
    `${PREFIX} send blab item activity reply`,
    props<{ activityId: number; content: string }>(),
  ),
  sendBlabItemActivityReplySuccess: createAction(
    `${PREFIX} send blab item activity reply success`,
    props<{ reply: BlabItemActivityReplyDto; activityId: number; content: string }>(),
  ),
  sendBlabItemActivityReplyException: createErrorAction(
    `${PREFIX} send blab item activity reply exception`,
    props<{ activityId: number; content: string }>(),
  ),
  addAttachmentFiles: createAction(
    `${PREFIX} Add comment file attachments`,
    props<{ activityId: number; files: File[] }>(),
  ),
  removeAttachment: createAction(
    `${PREFIX} Remove file attachment`,
    props<{ activityId: number; attachmentId: number }>(),
  ),
  invalidFileSizeFileAttached: createAction(
    `${PREFIX} File with invalid file size had been attached by user`,
    props<{ files: File[] }>(),
  ),
  tooManyFileAttached: createAction(`${PREFIX} Too many files had been attached by user`, props<{ files: File[] }>()),

  uploadFile: createAction(
    `${PREFIX} Upload file attachment`,
    props<{ activityId: number; attachment: BlabItemActivityReplyInputAttachmentValobj }>(),
  ),
  uploadFileSuccess: createAction(
    `${PREFIX} Uploaded file attachment successfully`,
    props<{
      activityId: number;
      attachment: BlabItemActivityReplyInputAttachmentValobj;
      persistedAttachment: BlabItemActivityReplyAttachmentDto;
    }>(),
  ),
  uploadFileException: createErrorAction(
    `${PREFIX} Uploading file attachment failed`,
    props<{ activityId: number; attachment: BlabItemActivityReplyInputAttachmentValobj }>(),
  ),

  // Mention user
  clearMentionUserSearchResults: createAction(`${PREFIX} clear mention user search results`),
  searchMentionUser: createAction(`${PREFIX} search mention user`, props<{ searchQuery: string }>()),
  searchMentionUserSuccess: createAction(
    `${PREFIX} search mention user success`,
    props<{ searchQuery: string; userSearchResults: UserProfilePreviewDto[] }>(),
  ),
  searchMentionUserException: createErrorAction(`${PREFIX} search mention user`, props<{ searchQuery: string }>()),

  // navigation
  openUrl: createAction(`${PREFIX} Open URL`, props<{ url: string }>()),
  accessBlabItem: createAction(`${PREFIX} Access blab item`, props<{ blabItemId: number }>()),

  // reply deletion
  confirmDeleteBlabItemActivityReply: createAction(
    `${PREFIX} confirm delete blab item activity reply`,
    props<{ blabItemId: number; activityId: number; replyId: number }>(),
  ),
  deleteBlabItemActivityReply: createAction(
    `${PREFIX} delete blab item activity reply`,
    props<{ blabItemId: number; activityId: number; replyId: number }>(),
  ),
  deleteBlabItemActivityReplySuccess: createAction(
    `${PREFIX} delete blab item activity reply success`,
    props<{ blabItemId: number; activityId: number; replyId: number }>(),
  ),
  deleteBlabItemActivityReplyException: createErrorAction(
    `${PREFIX} delete blab item activity reply exception`,
    props<{ blabItemId: number; activityId: number; replyId: number }>(),
  ),

  // comment deletion (comment is thread host activity)
  confirmDeleteBlabItemComment: createAction(
    `${PREFIX} confirm delete blab item comment`,
    props<{ blabItemId: number; activityId: number }>(),
  ),
  deleteBlabItemComment: createAction(
    `${PREFIX} delete blab item comment`,
    props<{ blabItemId: number; activityId: number }>(),
  ),
  deleteBlabItemCommentSuccess: createAction(
    `${PREFIX} delete blab item comment success`,
    props<{ blabItemId: number; activityId: number }>(),
  ),
  deleteBlabItemCommentException: createErrorAction(
    `${PREFIX} delete blab item comment exception`,
    props<{ blabItemId: number; activityId: number }>(),
  ),

  copyReplyContent: createAction(`${PREFIX} Copy reply content`, props<{ activityReply: BlabItemActivityReplyDto }>()),
  copyCommentContent: createAction(`${PREFIX} Copy comment content`, props<{ activity: BlabItemCommentActivityDto }>()),

  focusActivityThreadReplyInput: createAction(
    `${PREFIX} Focus activity thread reply input`,
    props<{ blabItemActivityId: number }>(),
  ),
};
