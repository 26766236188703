import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReactionDetailsEffects } from './reaction-details.effects';
import { reactionDetailsReducer, reactionDetailsStateKey } from './reaction-details.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(reactionDetailsStateKey, reactionDetailsReducer),
    EffectsModule.forFeature([ReactionDetailsEffects]),
  ],
})
export class ReactionDetailsBusinessLogicModule {}
