import gql from 'graphql-tag';
import { ThumbnailDtoNoNestingFragment, UserStatusDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { FullUserProfileDtoFragment } from '../user-profile';

export const AllBlabItemFollowersFragment = gql`
  fragment AllBlabItemFollowers on BlabItemFollowersConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        ...FullUserProfileDto
      }
    }
  }
  ${FullUserProfileDtoFragment}
  ${UserStatusDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
