import gql from 'graphql-tag';
import { BlabDefDataImportFileCellValuesInfoDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const BlabDefDataImportFileCellValuesInfoDtoFragment = gql`
  fragment BlabDefDataImportFileCellValuesInfoDtoFragment on BlabDefDataImportFileCellValuesInfoDto {
    ...BlabDefDataImportFileCellValuesInfoDtoNoNesting
    uniqueSingleOptionValues
    uniqueMultiOptionValues
  }

  ${BlabDefDataImportFileCellValuesInfoDtoNoNestingFragment}
`;
