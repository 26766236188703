import { createFeatureSelector, createSelector } from '@ngrx/store';
import { safeAreaDimensionsValid } from '@t5s/mobile-client/util/safe-area';
import { HydrationState } from '@t5s/mobile-client/value-object/hydration';
import { safeAreaStateKey } from './safe-area.reducer';
import { SafeAreaState } from './safe-area.state';

const selectSafeAreaFeatureState = createFeatureSelector<SafeAreaState>(safeAreaStateKey);
export const selectSafeAreaFeatureStateForPersistance = selectSafeAreaFeatureState;

export const selectSafeAreaDimensions = createSelector(selectSafeAreaFeatureState, (state) => state);
export const selectSafeAreaHasValidDimensions = createSelector(selectSafeAreaFeatureState, (dimensions) =>
  safeAreaDimensionsValid(dimensions),
);

export const selectSafeAreaHydrationAttempted = createSelector(
  selectSafeAreaFeatureState,
  ({ hydrationState }) => hydrationState !== HydrationState.PENDING,
);
