import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ServiceCameraModule } from '@t5s/mobile-client/service/camera';
import { ServiceDialogModule } from '@t5s/mobile-client/service/dialog';
import { ServiceFilePickerModule } from '@t5s/mobile-client/service/file-picker';
import { ServiceKeyboardModule } from '@t5s/mobile-client/service/keyboard';
import { ServiceNativeSettingsModule } from '@t5s/mobile-client/service/native-settings';
import { DeviceMediaEffects } from './device-media.effects';
import { deviceMediaReducer, deviceMediaStateKey } from './device-media.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(deviceMediaStateKey, deviceMediaReducer),
    EffectsModule.forFeature([DeviceMediaEffects]),
    ServiceCameraModule,
    ServiceFilePickerModule,
    ServiceDialogModule,
    ServiceNativeSettingsModule,
    ServiceKeyboardModule,
  ],
})
export class DeviceMediaBusinessLogicModule {}
