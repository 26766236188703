import { FlimType } from '@t5s/shared/gql';

export const flimDefEnClientLang = {
  chooseAFlimType: 'Add field',
  changeChooseAFlimType: 'Change field type',
  closeBtnTooltip: 'Close',
  deleteFlimDef: 'Delete field',
  duplicateFlimDef: 'Duplicate field',
  editBtnTooltip: 'Edit field',
  flimDefName: 'Field',
  hiddenIfEmptyTag: 'Hidden if empty',
  alwaysHiddenTag: 'Always hidden',
  settingsBtnTooltip: 'Options',
  moveFlimDefTooltip: 'Move',
  [FlimType.SINGLE_TEXT]: {
    label: 'Single line text',
  },
  [FlimType.MULTI_TEXT]: {
    label: 'Multiple line text',
  },
  [FlimType.SINGLE_CATEGORY]: {
    label: 'Single select',
    layout: {
      LIST: 'List',
      INLINE: 'Inline',
      DROPDOWN: 'Dropdown list',
    },
  },
  [FlimType.MULTI_CATEGORY]: {
    label: 'Multiple select',
    layout: {
      LIST: 'List',
      INLINE: 'Inline',
      DROPDOWN: 'Dropdown list',
    },
  },
  [FlimType.SINGLE_RELATION]: {
    label: 'Relation',
  },
  [FlimType.MULTI_RELATION]: {
    label: 'Relation',
  },
  [FlimType.SINGLE_USER]: {
    label: 'Member',
    showInFocusTag: 'Show in focus',
  },
  [FlimType.MULTI_USER]: {
    label: 'Member',
    showInFocusTag: 'Show in focus',
  },
  [FlimType.SINGLE_ATTACHMENT]: {
    label: 'Files',
  },
  [FlimType.MULTI_ATTACHMENT]: {
    label: 'Files',
  },
  [FlimType.SINGLE_DATE]: {
    label: 'Date',
    isDueDateOfStatusFieldTag: 'Deadline for status',
    showInCalendarTag: 'Show in calendar',
  },
  [FlimType.RANGE_DATE]: {
    label: 'Date',
    isDueDateOfStatusFieldTag: 'Deadline for status',
    showInCalendarTag: 'Show in calendar',
  },
  [FlimType.CHECKLIST]: {
    label: 'Checklist',
    showInFocusTag: 'Show in focus',
  },
  [FlimType.MULTI_LINK]: {
    label: 'Link',
  },
  [FlimType.CREATED_BY]: {
    label: 'Created by',
  },
  [FlimType.CREATED_AT]: {
    label: 'Created on',
  },
  [FlimType.LAST_MODIFIED_BY]: {
    label: 'Last edited by',
  },
  [FlimType.LAST_MODIFIED_AT]: {
    label: 'Last edited on',
  },
  [FlimType.NUMBER]: {
    label: 'Number',
  },
  [FlimType.STATUS]: {
    defaultCompleteOptionLabel: 'Completed',
    defaultIncompleteOptionLabel: 'Incomplete',
    label: 'Status',
    layout: {
      LIST: 'List',
      INLINE: 'Inline',
      DROPDOWN: 'Dropdown list',
      PROCESS: 'Process',
    },
  },
  [FlimType.CALCULATION]: {
    label: 'Calculation',
  },
  [FlimType.MULTI_EMAIL]: {
    label: 'Email',
  },
  [FlimType.MULTI_PHONE]: {
    label: 'Phone',
  },
  [FlimType.SINGLE_LOCATION]: {
    label: 'Location',
  },
  [FlimType.MULTI_IMAGE]: {
    label: 'Image',
  },
  [FlimType.UNIQUE_ID]: {
    label: 'Unique ID',
  },
};
