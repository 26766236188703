import { createReducer, on } from '@ngrx/store';
import { HydrationState } from '@t5s/mobile-client/value-object/hydration';
import { SafeAreaActions } from './safe-area.actions';
import { SafeAreaState } from './safe-area.state';

export const safeAreaStateKey = 'safeArea';

const initialState: SafeAreaState = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,

  hydrationState: HydrationState.PENDING,
};

export const safeAreaReducer = createReducer(
  initialState,
  on(SafeAreaActions.setSafeAreaDimensions, (state, { top, right, left, bottom }) => ({
    ...state,
    top,
    right,
    left,
    bottom,
  })),

  on(SafeAreaActions.hydrateStateSuccess, (state, { state: hydratedState }) => {
    const { top, bottom, right, left } = hydratedState;
    return { ...state, top, bottom, right, left, hydrationState: HydrationState.HYDRATED };
  }),
  on(SafeAreaActions.hydrateStateNotAvailable, (state) => {
    return { ...state, hydrationState: HydrationState.UNAVAILABLE };
  }),
  on(SafeAreaActions.hydrateStateException, (state) => {
    return { ...state, hydrationState: HydrationState.EXCEPTION };
  }),
);
