import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RootActions } from '@t5s/mobile-client/business-logic/root';
import { HardwareBackButtonObservable } from '@t5s/mobile-client/provider-token/hardware-back-button';
import { map } from 'rxjs/operators';
import { ReactionDetailsActions } from './reaction-details.actions';
import { selectReactionDetailsPosition } from './reaction-details.selectors';

@Injectable()
export class ReactionDetailsEffects {
  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly backButton$: HardwareBackButtonObservable,
  ) {}

  readonly setSheetOpen$ = createEffect(() =>
    this.store$.select(selectReactionDetailsPosition).pipe(
      map((sheetPosition) => {
        return RootActions.setSheetPosition({ sheetPosition });
      }),
    ),
  );

  readonly androidGoBack$ = createEffect(() => this.backButton$.pipe(map(() => ReactionDetailsActions.close())));
}
