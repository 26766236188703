import gql from 'graphql-tag';
import { ActiveUserExploreSuggestionDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { ActiveUserExploreBlabItemPreviewDtoFragment } from '../your-content';

export const ActiveUserExploreSuggestionDtoFragment = gql`
  fragment ActiveUserExploreSuggestionDtoFragment on ActiveUserExploreSuggestionDto {
    ...ActiveUserExploreSuggestionDtoNoNesting
    blabItem {
      ...ActiveUserExploreBlabItemPreviewDtoFragment
    }
  }
  ${ActiveUserExploreSuggestionDtoNoNestingFragment}
  ${ActiveUserExploreBlabItemPreviewDtoFragment}
`;
