import gql from 'graphql-tag';
import { UserTrashDtoFragment } from './active-user-trash-dto.fragments';

export const UserTrashConnectionFragment = gql`
  fragment UserTrashConnectionFragment on UserTrashConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        ...UserTrashDtoFragment
      }
    }
  }
  ${UserTrashDtoFragment}
`;
