import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ChatConversationsLoadingState } from '@t5s/mobile-client/value-object/chat';
import { chatStateKey, selectAll } from './chat.reducer';
import { ChatState } from './chat.state';

const selectChatFeatureState = createFeatureSelector<ChatState>(chatStateKey);

export const selectChatFeatureStateForPersistance = selectChatFeatureState;

export const selectChatConversations = createSelector(selectChatFeatureState, selectAll);

export const selectChatConversationsIsLoading = createSelector(selectChatFeatureState, ({ loadingState }) => {
  return loadingState === ChatConversationsLoadingState.LOADING;
});

export const selectChatConversationsLoadingState = createSelector(
  selectChatFeatureState,
  ({ loadingState }) => loadingState,
);
