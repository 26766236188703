import gql from 'graphql-tag';
import { ReportActiveUserAudioRoomPresenceDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { AudioRoomDetailDtoFragment } from './audio-room-detail.dto.fragment';

export const ReportActiveUserAudioRoomPresenceDtoFragment = gql`
  fragment ReportActiveUserAudioRoomPresenceDtoFragment on ReportActiveUserAudioRoomPresenceDto {
    ...ReportActiveUserAudioRoomPresenceDtoNoNesting
    audioRoom {
      ...AudioRoomDetailDtoFragment
    }
  }
  ${ReportActiveUserAudioRoomPresenceDtoNoNestingFragment}
  ${AudioRoomDetailDtoFragment}
`;
