import { NotificationEdgeValObj } from '@t5s/mobile-client/value-object/notification';
import { NotificationType } from '@t5s/shared/gql';

/** Filter notifications that are actually supported in UI and can properly displayed. */
export function filterSupportedNotifications(notifications: NotificationEdgeValObj[]): NotificationEdgeValObj[] {
  return notifications.filter(isNotificationSupported);
}

const supportedNotificationTypes: NotificationType[] = [
  NotificationType.AUDIO_ROOM_NOTIFICATION,
  NotificationType.BLAB_DEF_NOTIFICATION,
  NotificationType.BLAB_ITEM_NOTIFICATION,
  NotificationType.USER_NOTIFICATION,
  NotificationType.WORKSPACE_NOTIFICATION,
  NotificationType.WORKFLOW_DEF_NOTIFICATION,
  NotificationType.USER_GROUP_NOTIFICATION,
];

function isNotificationSupported(notification?: NotificationEdgeValObj): boolean {
  if (!notification) {
    return false;
  }

  const { node } = notification;

  if (!node) {
    return false;
  }

  return supportedNotificationTypes.includes(node.type);
}
