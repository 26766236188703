import gql from 'graphql-tag';
import { FullFlimDefFragment } from '../flim-def';
import { SharedBlabDefDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { BlabViewPreviewDtoFragment } from '../blab-view';

export const SharedBlabDefDtoFragment = gql`
  fragment SharedBlabDefDtoFragment on SharedBlabDefDto {
    ...SharedBlabDefDtoNoNesting
    flimDefs {
      ...FullFlimDef
    }
    blabViews {
      ...BlabViewPreviewDto
    }
  }
  ${SharedBlabDefDtoNoNestingFragment}
  ${BlabViewPreviewDtoFragment}
  ${FullFlimDefFragment}
`;
