import { Injectable } from '@angular/core';
import { PlatformObservable } from '@t5s/mobile-client/provider-token/device';
import { AndroidOptions, AndroidSettings, IOSOptions, IOSSettings, NativeSettings } from 'capacitor-native-settings';
import { defer, from, Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

@Injectable()
export class NativeSettingsService {
  constructor(private readonly platform$: PlatformObservable) {}

  openAppSettings() {
    return this.platform$.pipe(
      first(),
      switchMap(({ platform }) => {
        switch (platform) {
          case 'android':
            return this.openAndroid({ option: AndroidSettings.ApplicationDetails });

          case 'ios':
            return this.openIOS({ option: IOSSettings.App });

          default:
            return of(undefined);
        }
      }),
    );
  }

  openAndroid(option: AndroidOptions): Observable<{
    status: boolean;
  }> {
    return defer(() => from(NativeSettings.openAndroid(option)));
  }

  openIOS(option: IOSOptions): Observable<{
    status: boolean;
  }> {
    return defer(() => from(NativeSettings.openIOS(option)));
  }
}
