import { ConversationDto } from '@t5s/shared/gql';

export interface ChatConversationPreviewValObj extends ConversationDto {}

export enum ChatConversationsLoadingState {
  INITIAL = 'INITIAL',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  EXCEPTION = 'LOADED',
}
