import gql from 'graphql-tag';
import {
  GetLocationDetailsRejectionDtoNoNestingFragment,
  LocationDetailsDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const GetLocationDetailsResultFragment = gql`
  fragment GetLocationDetailsResultFragment on GetLocationDetailsResultUnionType {
    ... on LocationDetailsDto {
      ...LocationDetailsDtoNoNesting
    }
    ... on GetLocationDetailsRejectionDto {
      ...GetLocationDetailsRejectionDtoNoNesting
    }
  }
  ${GetLocationDetailsRejectionDtoNoNestingFragment}
  ${LocationDetailsDtoNoNestingFragment}
`;
