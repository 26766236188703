import { Directive } from '@angular/core';
import { Color, getGlobalColors, rgba } from '@t5s/client/ui/style/common';
import { RxDirective } from '@t5s/client/util/rx';
import { trackById, trackByIndex, trackByPosition } from '@t5s/client/util/track-by';
import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { getGlobalI18n } from '@t5s/mobile-client/i18n/common';

@Directive()
export abstract class UiComponent<T extends object = {}> extends RxDirective<T> {
  readonly i18n: SharedI18n = getGlobalI18n();
  readonly Color: typeof Color = getGlobalColors();

  readonly rgba = rgba;

  readonly trackById = trackById;
  readonly trackByIndex = trackByIndex;
  readonly trackByPosition = trackByPosition;
}
