import gql from 'graphql-tag';
import { PageInfoFragment } from '../core/page-info.fragment';
import { WorkspaceActivityUnionTypeFragment } from './workspace-activity-union-type.fragment';

export const WorkspaceActivityConnectionFragment = gql`
  fragment WorkspaceActivityConnectionFragment on WorkspaceActivityConnection {
    edges {
      cursor
      node {
        ...WorkspaceActivityUnionTypeFragment
      }
    }
    pageInfo {
      ...PageInfoFragment
    }
  }
  ${WorkspaceActivityUnionTypeFragment}
  ${PageInfoFragment}
`;
