import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { attachmentFileValidForUpload } from '@t5s/client/util/file';
import { htmlToText } from '@t5s/client/util/html-to-text';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { BlabItemDisplayI18n } from '@t5s/mobile-client/i18n/blab-item-display';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
import { ClipboardService } from '@t5s/mobile-client/service/clipboard';
import { DialogService } from '@t5s/mobile-client/service/dialog';
import { ToastService } from '@t5s/mobile-client/service/toast';
import { ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { attachmentReadyForUpload, attachmentUploadedCompletely } from '@t5s/mobile-client/util/blab-item-display';
import { GqlBlabItemCommentService } from '@t5s/shared/gql-services';
import { filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { filter, map, mergeMap, switchMap } from 'rxjs/operators';
import { BlabItemDisplayActions } from './blab-item-display.actions';
import { selectBlabItemDisplayBlabItemDict } from './blab-item-display.selectors';

const NUM_ALLOWED_ATTACHMENTS = 4;

@Injectable()
export class BlabItemActivityCommentEffects {
  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly blabItemCommentService: GqlBlabItemCommentService,
    private readonly toastService: ToastService,
    private readonly i18n$: I18nObjectObservable,
    private readonly dialogService: DialogService,
    private readonly clipboardService: ClipboardService,
  ) {}

  readonly sendBlabItemComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.sendBlabItemComment),
      concatLatestFrom(() => this.store$.select(selectBlabItemDisplayBlabItemDict)),
      mergeMap(([{ blabItemId }, dict]) => {
        const blabItemState = dict[blabItemId];

        if (!blabItemState) {
          return [];
        }

        const { commentInput = {} } = blabItemState;
        const { content = '' } = commentInput;
        const uploadedAttachments = commentInput.attachments?.filter(attachmentUploadedCompletely) ?? [];
        const attachmentIds = uploadedAttachments.map((att) => att.attachment.id);

        return this.blabItemCommentService
          .addBlabItemComment({
            blabItemId,
            content,
            attachmentIds,
          })
          .pipe(
            map((activity) => BlabItemDisplayActions.sendBlabItemCommentSuccess({ blabItemId, activity, content })),
            catchErrorDispatch(BlabItemDisplayActions.sendBlabItemCommentException),
          );
      }),
    ),
  );

  readonly fileAddedTriggerUploads$ = createEffect(() =>
    this.actions$.pipe(ofType(BlabItemDisplayActions.addAttachmentFiles)).pipe(
      mergeMap(({ blabItemId }) =>
        this.store$.select(selectBlabItemDisplayBlabItemDict).pipe(
          switchMap((dict) => {
            const blabItemState = dict[blabItemId];
            if (!blabItemState) {
              return [];
            }

            const { commentInput = {} } = blabItemState;
            const attachmentsReadyForUpload = commentInput.attachments?.filter(attachmentReadyForUpload) ?? [];

            return attachmentsReadyForUpload.map((attachment) =>
              BlabItemDisplayActions.uploadFile({ blabItemId, attachment }),
            );
          }),
        ),
      ),
    ),
  );

  readonly invalidFileAdded$ = createEffect(() =>
    this.actions$.pipe(ofType(BlabItemDisplayActions.addAttachmentFiles)).pipe(
      map(({ files }) => files.filter((file) => !attachmentFileValidForUpload(file))),
      filter((invalidFiles) => invalidFiles.length > 0),
      map((files) => BlabItemDisplayActions.invalidFileSizeFileAttached({ files })),
    ),
  );

  readonly tooManyFileAdded$ = createEffect(() =>
    this.actions$.pipe(ofType(BlabItemDisplayActions.addAttachmentFiles)).pipe(
      filter(({ files }) => files.length > NUM_ALLOWED_ATTACHMENTS),
      map(({ files }) => BlabItemDisplayActions.tooManyFileAttached({ files })),
    ),
  );

  readonly uploadAttachmentFile$ = createEffect(() =>
    this.actions$.pipe(ofType(BlabItemDisplayActions.uploadFile)).pipe(
      mergeMap(({ blabItemId, attachment }) =>
        this.blabItemCommentService.uploadBlabItemCommentAttachment({}, attachment.file).pipe(
          map((persistedAttachment) =>
            BlabItemDisplayActions.uploadFileSuccess({ blabItemId, attachment, persistedAttachment }),
          ),
          catchErrorDispatch(BlabItemDisplayActions.uploadFileException, { attachment }),
        ),
      ),
    ),
  );

  readonly navigateToRegistration$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BlabItemDisplayActions.invalidFileSizeFileAttached),
        concatLatestFrom(() => this.i18n$),
        switchMap(([_, { i18n }]) => {
          return this.toastService.show({
            message: BlabItemDisplayI18n.translate(
              i18n,
              BlabItemDisplayI18n.key.commentInput.attachmentInvalidSizeToast,
            ),
            bgColor: ThemeColorVar.danger,
          });
        }),
      ),
    { dispatch: false },
  );

  readonly confirmDeleteBlabItemComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.confirmDeleteBlabItemComment),
      concatLatestFrom(() => this.i18n$),
      switchMap(([{ blabItemId, activityId }, { i18n }]) => {
        const message = BlabItemDisplayI18n.translate(
          i18n,
          BlabItemDisplayI18n.key.confirmDeleteBlabItemCommentDialog.message,
        );
        const title = BlabItemDisplayI18n.translate(
          i18n,
          BlabItemDisplayI18n.key.confirmDeleteBlabItemCommentDialog.title,
        );
        const cancelButtonTitle = BlabItemDisplayI18n.translate(
          i18n,
          BlabItemDisplayI18n.key.confirmDeleteBlabItemCommentDialog.cancelButtonTitle,
        );
        const okButtonTitle = BlabItemDisplayI18n.translate(
          i18n,
          BlabItemDisplayI18n.key.confirmDeleteBlabItemCommentDialog.okButtonTitle,
        );

        return this.dialogService.confirm({ message, title, cancelButtonTitle, okButtonTitle }).pipe(
          filter(({ value }) => value),
          map(() => BlabItemDisplayActions.deleteBlabItemComment({ blabItemId, activityId })),
        );
      }),
    ),
  );

  readonly deleteBlabItemComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemDisplayActions.deleteBlabItemComment),
      switchMap(({ activityId, blabItemId }) =>
        this.blabItemCommentService
          .deleteBlabItemComment(activityId)
          .pipe(map(() => BlabItemDisplayActions.deleteBlabItemCommentSuccess({ activityId, blabItemId }))),
      ),
    ),
  );

  readonly copyCommentContent$ = createEffect(
    () =>
      this.actions$.pipe(ofType(BlabItemDisplayActions.copyCommentContent)).pipe(
        map(({ activity }) => {
          return htmlToText(activity.comment?.content ?? '');
        }),
        filterOnlyPresent(),
        switchMap((textContent) => this.clipboardService.write({ string: textContent })),
      ),
    { dispatch: false },
  );
}
