import gql from 'graphql-tag';
import { ConvertMultiAttachmentFlimToSingleAttachmentFlimResultDtoNoNestingFragment } from '../../../generated/graphql-fragments';
import { FullFlimDefFragment } from '../../flim-def';

export const ConvertMultiAttachmentFlimToSingleAttachmentFlimResultDtoFragment = gql`
  fragment ConvertMultiAttachmentFlimToSingleAttachmentFlimResultDtoFragment on ConvertMultiAttachmentFlimToSingleAttachmentFlimResultDto {
    ...ConvertMultiAttachmentFlimToSingleAttachmentFlimResultDtoNoNesting

    flimDef {
      ...FullFlimDef
    }
  }
  ${ConvertMultiAttachmentFlimToSingleAttachmentFlimResultDtoNoNestingFragment}
  ${FullFlimDefFragment}
`;
