import { ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { px } from '@t5s/client/ui/style/common';
import { RxDirective } from '@t5s/client/util/rx';
import { SafeAreaDimensionsObservable } from '@t5s/mobile-client/provider-token/safe-area';

@Component({
  selector: 't5s-bottom-sheet-bottom-spacer',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomSheetBottomSpacerComponent extends RxDirective {
  constructor({ nativeElement }: ElementRef<HTMLElement>, safeArea$: SafeAreaDimensionsObservable) {
    super();
    this.hold(safeArea$, ({ bottom }) => {
      const height = Math.max(bottom, 42);
      nativeElement.style.setProperty('height', px(height));
      nativeElement.style.setProperty('min-height', px(height));
      nativeElement.style.setProperty('max-height', px(height));
    });
  }
}
