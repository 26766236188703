import { TapeGqlClientError } from './tape-gql-error.class';

export function isGqlClientError(error: unknown): error is TapeGqlClientError {
  return error instanceof TapeGqlClientError;
}

export const isGqlClientErrorWithCode = (withCode: string) => (error: unknown) => {
  if (!isGqlClientError(error)) {
    return false;
  }

  return error.code === withCode;
};
