import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ComponentStyle, flexColumn, spread } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { DeviceMediaI18n } from '@t5s/mobile-client/i18n/device-media';
import { BottomSheetComponent } from '@t5s/mobile-client/ui/component/bottom-sheet';
import { ViewComponent, ViewState } from '@t5s/mobile-client/ui/view/common';
import { BottomSheetPosition } from '@t5s/mobile-client/value-object/bottom-sheet';
import { DeviceMediaType } from '@t5s/mobile-client/value-object/device-media';

export interface DeviceMediaViewState extends ViewState {}

@Component({
  selector: 't5s-device-media-view',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostSpread],
  template: `
    <t5s-bottom-sheet
      [dockedHeight]="'fit-content'"
      [notExpandable]="true"
      (positionChange)="positionChange.emit($event)"
    >
      <div [class]="containerClass">
        <ng-container *ngIf="i18n$ | push as i18n">
          <!-- Camera -->
          <t5s-device-media-menu-item
            [label]="I18n.translate(i18n, I18n.key.menu.camera)"
            (menuItemClick)="deviceMediaClick.emit({ type: DeviceMediaType.CAMERA })"
          ></t5s-device-media-menu-item>

          <!-- Photo -->
          <t5s-device-media-menu-item
            [label]="I18n.translate(i18n, I18n.key.menu.photo)"
            (menuItemClick)="deviceMediaClick.emit({ type: DeviceMediaType.PHOTO })"
          ></t5s-device-media-menu-item>

          <!-- Document -->
          <t5s-device-media-menu-item
            [label]="I18n.translate(i18n, I18n.key.menu.document)"
            (menuItemClick)="deviceMediaClick.emit({ type: DeviceMediaType.DOCUMENT })"
          ></t5s-device-media-menu-item>
        </ng-container>
      </div>

      <div t5sSafeAreaBottomSpacer></div>
    </t5s-bottom-sheet>
  `,
})
export class DeviceMediaViewComponent extends ViewComponent<DeviceMediaViewState> {
  readonly I18n = DeviceMediaI18n;
  readonly DeviceMediaType = DeviceMediaType;

  readonly containerClass = tss({
    ...spread,
    ...flexColumn,
  });

  @ViewChild(BottomSheetComponent) sheet?: BottomSheetComponent;

  @Output() positionChange = new EventEmitter<{ position: BottomSheetPosition }>();
  @Output() deviceMediaClick = new EventEmitter<{ type: DeviceMediaType }>();

  open() {
    this.sheet?.open();
  }

  close() {
    this.sheet?.close();
  }
}
