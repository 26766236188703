import {
  CalculationScriptMultiTextResultPreviewDto,
  CalculationScriptNumberResultPreviewDto,
  CalculationScriptSingleDateResultPreviewDto,
  GetCalculationScriptResultPreviewRejectionDto,
} from '@t5s/shared/gql';
import { isDto } from './dto.type-guard';

export function isGetCalculationScriptResultPreviewRejectionDto(
  candidate: any,
): candidate is GetCalculationScriptResultPreviewRejectionDto {
  return isDto<GetCalculationScriptResultPreviewRejectionDto>(
    candidate,
    'GetCalculationScriptResultPreviewRejectionDto',
  );
}

export function isCalculationScriptMultiTextResultPreviewDto(
  candidate: any,
): candidate is CalculationScriptMultiTextResultPreviewDto {
  return isDto<CalculationScriptMultiTextResultPreviewDto>(candidate, 'CalculationScriptMultiTextResultPreviewDto');
}

export function isCalculationScriptNumberResultPreviewDto(
  candidate: any,
): candidate is CalculationScriptNumberResultPreviewDto {
  return isDto<CalculationScriptNumberResultPreviewDto>(candidate, 'CalculationScriptNumberResultPreviewDto');
}

export function isCalculationScriptSingleDateResultPreviewDto(
  candidate: any,
): candidate is CalculationScriptSingleDateResultPreviewDto {
  return isDto<CalculationScriptSingleDateResultPreviewDto>(candidate, 'CalculationScriptSingleDateResultPreviewDto');
}
