import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchErrorDispatch } from '@t5s/client/util/store';
import {
  LoggedInSubscriptionEndTriggerObservable,
  LoggedInSubscriptionStartTriggerObservable,
} from '@t5s/mobile-client/provider-token/logged-in-subscription-trigger';
import { GqlBlabItemActivityReplyService, GqlBlabItemActivityService } from '@t5s/shared/gql-services';
import { TypeGuards } from '@t5s/shared/gql-type-guards/common';
import { merge } from 'rxjs';
import { debounceTime, filter, map, mergeMap, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { BlabItemActivityThreadActions } from './blab-item-activity-thread.actions';

@Injectable()
export class BlabItemActivityThreadLiveSubscriptionEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly gqlBlabItemActivityService: GqlBlabItemActivityService,
    private readonly gqlBlabItemActivityReplyService: GqlBlabItemActivityReplyService,
    private readonly subscriptionStartTrigger$: LoggedInSubscriptionStartTriggerObservable,
    private readonly subscriptionEndTrigger$: LoggedInSubscriptionEndTriggerObservable,
  ) {}

  readonly subscribeToBlabItemActivityForThread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.subscribeToBlabItemActivity),
      mergeMap(({ blabItemId, blabItemActivityId }) => {
        const imperativeUnsubscribe$ = this.actions$.pipe(
          ofType(BlabItemActivityThreadActions.unsubscribeFromBlabItemActivity),
          filter((action) => action.blabItemId === blabItemId),
        );

        const shouldSubscribe$ = this.subscriptionStartTrigger$.pipe(startWith(true), debounceTime(100));
        const shouldUnsubscribe$ = merge(imperativeUnsubscribe$, this.subscriptionEndTrigger$);

        return shouldSubscribe$.pipe(
          switchMap(() =>
            this.gqlBlabItemActivityService.subscribeToBlabItemActivity(blabItemId).pipe(
              map((update) => BlabItemActivityThreadActions.blabItemActivityLiveUpdate({ update, blabItemActivityId })),
              catchErrorDispatch(BlabItemActivityThreadActions.subscribeToBlabItemActivityException),
              startWith(BlabItemActivityThreadActions.subscribeToBlabItemActivitySuccess({ blabItemId })),
              takeUntil(shouldUnsubscribe$),
            ),
          ),
        );
      }),
    ),
  );

  readonly subscribeToBlabItemActivityThread$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.subscribeToBlabItemActivityThread),
      mergeMap(({ blabItemActivityId }) => {
        const imperativeUnsubscribe$ = this.actions$.pipe(
          ofType(BlabItemActivityThreadActions.unsubscribeFromBlabItemActivityThread),
          filter((action) => action.blabItemActivityId === blabItemActivityId),
        );

        const shouldSubscribe$ = this.subscriptionStartTrigger$.pipe(startWith(true), debounceTime(100));
        const shouldUnsubscribe$ = merge(imperativeUnsubscribe$, this.subscriptionEndTrigger$);

        return shouldSubscribe$.pipe(
          switchMap(() =>
            this.gqlBlabItemActivityReplyService.subscribeToBlabItemActivityReplies(blabItemActivityId).pipe(
              map((update) =>
                BlabItemActivityThreadActions.blabItemActivityThreadLiveUpdate({ update, blabItemActivityId }),
              ),
              catchErrorDispatch(BlabItemActivityThreadActions.subscribeToBlabItemActivityThreadException),
              startWith(BlabItemActivityThreadActions.subscribeToBlabItemActivityThreadSuccess({ blabItemActivityId })),
              takeUntil(shouldUnsubscribe$),
            ),
          ),
        );
      }),
    ),
  );

  readonly blabItemActivityDeletedLiveUpdate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BlabItemActivityThreadActions.blabItemActivityLiveUpdate),
      map(({ update }) => update),
      filter(TypeGuards.isBlabItemActivityDeletedDto),
      map(({ id }) => BlabItemActivityThreadActions.blabItemActivityDeletedLiveUpdate({ activityId: id })),
    ),
  );
}
