import { htmlToTextTrimmed } from '@t5s/client/util/html-to-text';
import {
  ChatActivityDayCluster,
  ChatConversationChatActivityUnionType,
  ChatConversationClientModel,
  ChatConversationLocalMessageActivityStatus,
  ChatConversationLocalMessageActivityValObj,
  ChatMessageInputAttachmentValobj,
  ChatMessageInputState,
} from '@t5s/mobile-client/value-object/chat-conversation';
import {
  ChatActivityType,
  ChatMessageActivityDto,
  ChatMessageAttachmentDto,
  UserProfileDto,
  UserProfilePreviewDto,
} from '@t5s/shared/gql';
import { Require } from '@t5s/shared/types';
import { getLocalIdSequence } from '@t5s/shared/util/sequence';
import { clusterChatActivities, getDayClusters } from './chat-activity-clustering';
import { sortChatActivityUnionType, upsertActivityIntoChatActivities } from './chat-activity.utils';

const sequence = getLocalIdSequence();
const getLocalAttachmentId = () => sequence.next().value;

export function getChatMessageInputStateAddAttachments(
  chatNewMessageState: ChatMessageInputState,
  files: File[],
): ChatMessageInputState {
  let { attachments = [] } = chatNewMessageState;

  const newAttachments = files.map((file) => ({ file, id: getLocalAttachmentId() }));

  attachments = [...attachments, ...newAttachments];

  return { ...chatNewMessageState, attachments };
}

export function getChatMessageInputStateAttachmentUploadException(
  chatNewMessageState: ChatMessageInputState,
  attachmentToRemove: ChatMessageInputAttachmentValobj,
): ChatMessageInputState {
  let { attachments = [] } = chatNewMessageState;

  attachments = attachments.filter((att) => att.id !== attachmentToRemove.id);

  return { ...chatNewMessageState, attachments };
}

export function getChatMessageInputStateRemoveAttachment(
  chatNewMessageState: ChatMessageInputState,
  attachmentId: number,
): ChatMessageInputState {
  let { attachments = [] } = chatNewMessageState;

  attachments = attachments.filter((att) => att.id !== attachmentId);

  return { ...chatNewMessageState, attachments };
}

export function getChatMessageInputStateAttachmentUploadStart(
  chatNewMessageState: ChatMessageInputState,
  attachment: ChatMessageInputAttachmentValobj,
): ChatMessageInputState {
  let { attachments = [] } = chatNewMessageState;

  attachments = attachments.map((att) => {
    if (att.id === attachment.id) {
      return { ...att, progress: 0 };
    } else {
      return att;
    }
  });

  return { ...chatNewMessageState, attachments };
}

export function getChatMessageInputStateAttachmentUploadSuccess(
  chatNewMessageState: ChatMessageInputState,
  attachment: ChatMessageInputAttachmentValobj,
  persistedAttachment: ChatMessageAttachmentDto,
): ChatMessageInputState {
  let { attachments = [] } = chatNewMessageState;

  attachments = attachments.map((att) => {
    if (att.id === attachment.id) {
      return { ...att, progress: 100, attachment: persistedAttachment };
    } else {
      return att;
    }
  });

  return { ...chatNewMessageState, attachments };
}

export function attachmentUploadedCompletely(
  attachmentWrapper: ChatMessageInputAttachmentValobj,
): attachmentWrapper is Require<ChatMessageInputAttachmentValobj, 'attachment'> {
  return attachmentWrapper.attachment !== undefined;
}

export function attachmentReadyForUpload(attachmentWrapper: ChatMessageInputAttachmentValobj): boolean {
  return attachmentWrapper.attachment === undefined && attachmentWrapper.progress === undefined;
}

export function attachmentCurrentlyUploading(attachmentWrapper: ChatMessageInputAttachmentValobj): boolean {
  return attachmentWrapper.attachment === undefined && attachmentWrapper.progress !== undefined;
}

export function chatMessageInputInvalid({ content, attachments }: { content: string; attachments?: any[] }): boolean {
  const trimmedContentLength = htmlToTextTrimmed(content ?? '');
  return !trimmedContentLength && !attachments?.length;
}

export function getChatConversationAddLocalChatActivityUponSend(
  chatConversation: ChatConversationClientModel,
  { content, localId, activeUser }: { content: string; localId: string; activeUser: UserProfilePreviewDto },
): ChatConversationClientModel {
  const { messageInput = {}, conversationId } = chatConversation;
  let { localMessages } = chatConversation;
  const { attachments = [] } = messageInput;

  const persistedAttachments = attachments.filter(attachmentUploadedCompletely).map(({ attachment }) => attachment);

  const newLocalMessage: ChatConversationLocalMessageActivityValObj = {
    content,
    localId,
    attachments: persistedAttachments,
    conversationId,
    type: ChatActivityType.MESSAGE,
    createdAt: new Date(),
    status: ChatConversationLocalMessageActivityStatus.PENDING,
    user: activeUser as UserProfileDto,
    userId: activeUser.id,
  };

  localMessages = [...localMessages, newLocalMessage];

  return { ...chatConversation, messageInput: { content: '', attachments: [] }, localMessages };
}

export function getChatConversationReplaceLocalChatActivityUponSendSuccess(
  chatConversation: ChatConversationClientModel,
  { localId }: { localId: string },
  newPersistedActivity: ChatMessageActivityDto,
): ChatConversationClientModel {
  const { messageInput = {}, conversationId, chatActivity } = chatConversation;
  let { localMessages } = chatConversation;
  const { attachments = [] } = messageInput;

  if (!chatActivity) {
    return chatConversation;
  }

  let { chatActivities } = chatActivity;

  // add new persisted activity
  chatActivities = upsertActivityIntoChatActivities(newPersistedActivity, chatActivities);

  // remove respective local activity
  localMessages = localMessages.filter((localMessage) => localMessage.localId !== localId);

  return { ...chatConversation, chatActivity: { ...chatActivity, chatActivities }, localMessages };
}

export function getChatConversationSetLocalChatActivityTerminalException(
  chatConversation: ChatConversationClientModel,
  { localId }: { localId: string },
): ChatConversationClientModel {
  const { messageInput = {}, conversationId, chatActivity } = chatConversation;
  let { localMessages } = chatConversation;

  if (!chatActivity) {
    return chatConversation;
  }

  let failedLocalMessage = localMessages.find((localMessage) => localMessage.localId === localId);

  if (!failedLocalMessage) {
    return chatConversation;
  }

  // set respective local message as terminally failed
  localMessages = localMessages.filter((localMessage) => localMessage.localId !== localId);
  failedLocalMessage = { ...failedLocalMessage, status: ChatConversationLocalMessageActivityStatus.ERRORED };
  localMessages = [...localMessages, failedLocalMessage];

  return { ...chatConversation, localMessages };
}

export function getChatConversationRetrySendSetLocalChatActivity(
  chatConversation: ChatConversationClientModel,
  { localId }: { localId: string },
): ChatConversationClientModel {
  const { messageInput = {}, conversationId, chatActivity } = chatConversation;
  let { localMessages } = chatConversation;

  if (!chatActivity) {
    return chatConversation;
  }

  let retryingLocalMessage = localMessages.find((localMessage) => localMessage.localId === localId);

  if (!retryingLocalMessage) {
    return chatConversation;
  }

  // set respective local message as pending again, as it is being retried to send
  localMessages = localMessages.filter((localMessage) => localMessage.localId !== localId);
  retryingLocalMessage = { ...retryingLocalMessage, status: ChatConversationLocalMessageActivityStatus.RETRYING };
  localMessages = [...localMessages, retryingLocalMessage];

  return { ...chatConversation, localMessages };
}

export function getChatConversationDiscardLocalChatActivity(
  chatConversation: ChatConversationClientModel,
  { localId }: { localId: string },
): ChatConversationClientModel {
  const { chatActivity } = chatConversation;
  let { localMessages } = chatConversation;

  if (!chatActivity) {
    return chatConversation;
  }

  localMessages = localMessages.filter((localMessage) => localMessage.localId !== localId);

  return { ...chatConversation, localMessages };
}

export function getChatConversationAfterChatMessageDeleted(
  chatConversation: ChatConversationClientModel,
  { id }: { id: number },
): ChatConversationClientModel {
  const { chatActivity } = chatConversation;

  if (!chatActivity) {
    return chatConversation;
  }

  let { chatActivities } = chatActivity;

  chatActivities = chatActivities.filter((reply) => reply.node.id !== id);
  return { ...chatConversation, chatActivity: { ...chatActivity, chatActivities } };
}

function getAllChatActivitiesFromConversation(
  chatConversation: Pick<ChatConversationClientModel, 'chatActivity' | 'localMessages'>,
): ChatConversationChatActivityUnionType[] {
  const { chatActivity, localMessages } = chatConversation;

  if (!chatActivity) {
    return [];
  }

  const { chatActivities = [] } = chatActivity;
  const chatActivityNodes = chatActivities.map(({ node }) => node);

  const allChatActivities = [...chatActivityNodes, ...localMessages];

  return allChatActivities.sort(sortChatActivityUnionType);
}

export function getAllChatActivitiesDayClusterFromConversation(
  chatConversation: Pick<ChatConversationClientModel, 'chatActivity' | 'localMessages'>,
): ChatActivityDayCluster {
  const activities = getAllChatActivitiesFromConversation(chatConversation);

  return getDayClusters(clusterChatActivities(activities));
}
