import gql from 'graphql-tag';
import {
  UpdateWorkflowCenterWebhookResultDtoNoNestingFragment,
  WorkflowCenterWebhookDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const UpdateWorkflowCenterWebhookResultDtoFragment = gql`
  fragment UpdateWorkflowCenterWebhookResultDtoFragment on UpdateWorkflowCenterWebhookResultDto {
    ...UpdateWorkflowCenterWebhookResultDtoNoNesting
    webhook {
      ...WorkflowCenterWebhookDtoNoNesting
    }
  }
  ${UpdateWorkflowCenterWebhookResultDtoNoNestingFragment}
  ${WorkflowCenterWebhookDtoNoNestingFragment}
`;
