import { SharedI18n } from '@t5s/shared/value-object/i18n';
import {
  formatDateViaLanguage,
  getDateTimeFormats,
  isApplicableI18n,
  normalizeDate,
  shouldAbbreviateMonth,
} from '../common';

export function formatExactDayDate(i18n: SharedI18n, date?: string | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  const dateTimeFormats = getDateTimeFormats(i18n);

  const shouldAbbreviate = shouldAbbreviateMonth(i18n, date);

  const dateFormat = shouldAbbreviate ? dateTimeFormats.date.full : dateTimeFormats.date.fullNonAbbr;
  return formatDateViaLanguage(date, dateFormat, i18n);
}
