import gql from 'graphql-tag';
import {
  ThumbnailDtoNoNestingFragment,
  UserProfileDtoNoNestingFragment,
  UserStatusDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const FindMyOrgsFullUserProfileDtoFragment = gql`
  fragment FindMyOrgsFullUserProfileDto on UserProfileDto {
    ...UserProfileDtoNoNesting
    profilePicture {
      ...ThumbnailDtoNoNesting
    }
    organization {
      id
      name
      slug
      logo {
        ...ThumbnailDtoNoNesting
      }
    }
    status {
      ...UserStatusDtoNoNesting
    }
  }
  ${UserProfileDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
  ${UserStatusDtoNoNestingFragment}
`;
