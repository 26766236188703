import gql from 'graphql-tag';
import {
  ActiveUserExploreBlabItemPreviewDtoNoNestingFragment,
  BlabDefPreviewDtoNoNestingFragment,
  WorkspacePreviewDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';
import { UserProfilePreviewDtoFragment } from '../../user-profile';

export const ActiveUserExploreBlabItemPreviewDtoFragment = gql`
  fragment ActiveUserExploreBlabItemPreviewDtoFragment on ActiveUserExploreBlabItemPreviewDto {
    ...ActiveUserExploreBlabItemPreviewDtoNoNesting
    author {
      ...UserProfilePreviewDtoFragment
    }
    blabDef {
      ...BlabDefPreviewDtoNoNesting
      workspace {
        ...WorkspacePreviewDtoNoNesting
      }
    }
  }
  ${ActiveUserExploreBlabItemPreviewDtoNoNestingFragment}
  ${BlabDefPreviewDtoNoNestingFragment}
  ${WorkspacePreviewDtoNoNestingFragment}
  ${UserProfilePreviewDtoFragment}
`;
