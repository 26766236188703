import { Directive } from '@angular/core';
import { RxDirective } from '@t5s/client/util/rx';
import { trackById, trackByPosition } from '@t5s/client/util/track-by';
import { I18nObject } from '@t5s/mobile-client/i18n/common';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
import { selectSlice } from '@t5s/mobile-client/ui/component/common';
import { font, ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface ViewState {
  i18n?: I18nObject;
}

@Directive()
export abstract class ViewComponent<T extends ViewState = ViewState> extends RxDirective<T> {
  readonly trackById = trackById;
  readonly trackByPosition = trackByPosition;
  readonly Font = font;
  readonly Color = ThemeColorVar;

  readonly i18n$ = this.select(selectSlice(['i18n'])).pipe(map(({ i18n }) => i18n));

  constructor(readonly i18nObservable$: I18nObjectObservable) {
    super();

    if (i18nObservable$) {
      this.connect(i18nObservable$ as Observable<Partial<T>>);
    }
  }
}
