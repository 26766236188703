import {
  ChatActivityDtoConnection,
  ChatMessageActivityDto,
  ChatMessageAttachmentDto,
  DeleteChatMessageInput,
  GetChatActivitiesInput,
  PostChatMessageInput,
  SingleConversationSubscriptionUpdate,
  Upload,
  UploadChatMessageAttachmentInput,
  Void,
} from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import {
  ChatActivityConnectionFragment,
  ChatActivityFragment,
  ChatMessageAttachmentFragment,
  SingleConversationSubscriptionUpdateFragment,
} from '../../fragments/chat';
import { deleteChatMessage, postChatMessage, uploadChatMessageAttachment } from '../../generated/graphql-mutations';
import { getChatActivities } from '../../generated/graphql-queries';
import { subscribeToSingleConversation } from '../../generated/graphql-subscriptions';
import { GqlService } from '../../gql-service';

export class GqlChatActivityService extends GqlService {
  getChatActivityConnection(
    first: number,
    input?: GetChatActivitiesInput,
    after?: string,
  ): Observable<ChatActivityDtoConnection> {
    return this.apollo.defaultQuery({
      ...getChatActivities(first, input, after, [ChatActivityConnectionFragment]),
    });
  }

  watchChatActivityConnection(
    first: number,
    input?: GetChatActivitiesInput,
    after?: string,
  ): Observable<ChatActivityDtoConnection> {
    return this.apollo.defaultWatchQuery({
      ...getChatActivities(first, input, after, [ChatActivityConnectionFragment]),
    });
  }

  postChatMessage(input: PostChatMessageInput): Observable<ChatMessageActivityDto> {
    return this.apollo.defaultMutate(postChatMessage(input, [ChatActivityFragment]));
  }

  uploadChatMessageAttachment(
    input: UploadChatMessageAttachmentInput,
    attachment: Upload,
  ): Observable<ChatMessageAttachmentDto> {
    return this.apollo.defaultMutateFileUpload(
      uploadChatMessageAttachment(input, attachment, [ChatMessageAttachmentFragment]),
    );
  }

  deleteChatMessage(input: DeleteChatMessageInput): Observable<Void> {
    return this.apollo.defaultMutate(deleteChatMessage(input));
  }

  subscribeToSingleConversation(conversationId: number): Observable<SingleConversationSubscriptionUpdate> {
    return this.apollo.defaultSubscribe(
      subscribeToSingleConversation(conversationId, [SingleConversationSubscriptionUpdateFragment]),
    );
  }
}
