export enum EntityIconSkinTone {
  LIGHT = 'light',
  MEDIUM_LIGHT = 'medium_light',
  MEDIUM = 'medium',
  MEDIUM_DARK = 'medium_dark',
  DARK = 'dark',
}

export const EntityIconSkinToneCharcode = {
  [EntityIconSkinTone.LIGHT]: '1f3fb',
  [EntityIconSkinTone.MEDIUM_LIGHT]: '1f3fc',
  [EntityIconSkinTone.MEDIUM]: '1f3fd',
  [EntityIconSkinTone.MEDIUM_DARK]: '1f3fe',
  [EntityIconSkinTone.DARK]: '1f3ff',
} as const;
