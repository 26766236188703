import { I18nKey } from '@t5s/shared/i18n/common';
import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { getQuarter } from 'date-fns';
import { DatetimeI18n } from '../../../datetime.i18n';
import { isApplicableI18n, normalizeDate } from '../common';

export function formatQuarterDateLong(i18n: SharedI18n, date?: string | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  const quarter = getQuarter(date);

  const year = date.getFullYear();

  let key: I18nKey;
  switch (quarter) {
    case 1:
      key = DatetimeI18n.key.quartersLongWithYear.Q1;
      break;

    case 2:
      key = DatetimeI18n.key.quartersLongWithYear.Q2;
      break;

    case 3:
      key = DatetimeI18n.key.quartersLongWithYear.Q3;
      break;

    case 4:
      key = DatetimeI18n.key.quartersLongWithYear.Q4;
      break;

    default: {
      throw new Error(`Invalid quarter: ${quarter}`);
    }
  }

  return DatetimeI18n.translate(i18n, {
    key,
    params: { year: `${year}` },
  });
}
