import gql from 'graphql-tag';
import {
  BlabDefDeveloperInfoDtoNoNestingFragment,
  BlabDefWebhookDtoNoNestingFragment,
  FlimDefDeveloperInfoDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const BlabDefDeveloperInfoDtoFragment = gql`
  fragment BlabDefDeveloperInfoDtoFragment on BlabDefDeveloperInfoDto {
    ...BlabDefDeveloperInfoDtoNoNesting
    flimDefDeveloperInfos {
      ...FlimDefDeveloperInfoDtoNoNesting
    }
    blabDefWebhooks {
      ...BlabDefWebhookDtoNoNesting
    }
  }
  ${BlabDefDeveloperInfoDtoNoNestingFragment}
  ${FlimDefDeveloperInfoDtoNoNestingFragment}
  ${BlabDefWebhookDtoNoNestingFragment}
`;
