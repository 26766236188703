import {
  ChecklistFlimValDto,
  ChecklistFlimValEntryDto,
  CreateChecklistFlimValEntryNestedCreationInput,
  CreateChecklistFlimValInput,
} from '@t5s/shared/gql';

function getChecklistCreateNestedEntryInputFromDto(
  checklistEntry: ChecklistFlimValEntryDto,
): CreateChecklistFlimValEntryNestedCreationInput {
  const { title, description, position, dueAt, dueAtHasTime, assigneeUserId, completed } = checklistEntry;

  if (assigneeUserId === null) {
    return { title, description, position, dueAt, dueAtHasTime, completed };
  }
  return { title, description, position, dueAt, dueAtHasTime, assigneeUserId, completed };
}

export function getChecklistCreateInputFromDto(checklistFlimVal: ChecklistFlimValDto): CreateChecklistFlimValInput {
  const { flimDefId } = checklistFlimVal;

  const checklistEntryDtos = checklistFlimVal.checklistEntries ?? [];
  const checklistEntries = checklistEntryDtos.map(getChecklistCreateNestedEntryInputFromDto);

  return { flimDefId, checklistEntries };
}
