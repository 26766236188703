export enum EntityIconColor {
  BLUE = 'blue',
  BROWN = 'brown',
  GRAY = 'gray',
  GREEN = 'green',
  LIGHTGRAY = 'lightgray',
  ORANGE = 'orange',
  PINK = 'pink',
  PURPLE = 'purple',
  RED = 'red',
  YELLOW = 'yellow',
}

export const EntityIconColorHex = {
  [EntityIconColor.BLUE]: '#337EA9',
  [EntityIconColor.BROWN]: '#9F6B53',
  [EntityIconColor.GRAY]: '#55534E',
  [EntityIconColor.GREEN]: '#448361',
  [EntityIconColor.LIGHTGRAY]: '#A6A299',
  [EntityIconColor.ORANGE]: '#D9730D',
  [EntityIconColor.PINK]: '#C14C8A',
  [EntityIconColor.PURPLE]: '#9065B0',
  [EntityIconColor.RED]: '#D44C47',
  [EntityIconColor.YELLOW]: '#CB912F',
} as const;

export const EntityIconBgColorHex = {
  /** rgb(227, 226, 224) */
  [EntityIconColor.GRAY]: '#E3E2E0',
  /** rgb(227, 226, 224) */
  [EntityIconColor.LIGHTGRAY]: '#E3E2E0',
  /**  rgb(238, 224, 218) */
  [EntityIconColor.BROWN]: '#EEE0DA',
  /** rgb(253, 236, 200) */
  [EntityIconColor.YELLOW]: '#FDECC8',
  /** rgb(250, 222, 201) */
  [EntityIconColor.ORANGE]: '#FADEC9',
  /** rgb(219, 237, 219) */
  [EntityIconColor.GREEN]: '#DBEDDB',
  /** rgb(211, 229, 239) */
  [EntityIconColor.BLUE]: '#D3E5EF',
  /** rgb(232, 222, 238) */
  [EntityIconColor.PURPLE]: '#E8DEEE',
  /** rgb(245, 224, 233) */
  [EntityIconColor.PINK]: '#F5E0E9',
  /** rgb(255, 226, 221) */
  [EntityIconColor.RED]: '#FFE2DD',
} as const;
