import gql from 'graphql-tag';
import {
  SingleDateSplitByDtoNoNestingFragment,
  SingleDateSplitBySectionDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const SingleDateSplitByDtoFragment = gql`
  fragment SingleDateSplitByDto on SingleDateSplitByDto {
    ...SingleDateSplitByDtoNoNesting
    sections {
      ...SingleDateSplitBySectionDtoNoNesting
    }
  }
  ${SingleDateSplitByDtoNoNestingFragment}
  ${SingleDateSplitBySectionDtoNoNestingFragment}
`;
