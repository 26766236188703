import gql from 'graphql-tag';
import { ActiveUserExploreSuggestionDtoFragment } from './active-user-explore-suggestion.fragment';

export const GetActiveUserExploreSuggestionsForCategoryConnectionFragment = gql`
  fragment GetActiveUserExploreSuggestionsForCategoryConnectionFragment on GetActiveUserExploreSuggestionsForCategoryConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        ...ActiveUserExploreSuggestionDtoFragment
      }
    }
  }
  ${ActiveUserExploreSuggestionDtoFragment}
`;
