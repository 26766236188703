import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { exactHeight, exactWidth, flexCenter } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { IconInterface } from '@t5s/mobile-client/asset';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { isObservable, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface HeaderIconButtonState {
  icon?: IconInterface | string;
  iconSize: number;
  btnWidth: number;
  btnHeight: number;
  fgColor: string;
}

@Component({
  selector: 't5s-header-icon-button',
  template: `
    <button [class]="btnClass$ | push" [t5sTouchActive]="'opacity: 0.35'" (t5sPressDisableLongpress)="btnClick.emit()">
      <t5s-icon [icon]="icon$" [size]="iconSize$" [fgColor]="fgColor$"></t5s-icon>
    </button>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderIconButtonComponent extends RxComponent<HeaderIconButtonState> {
  constructor() {
    super();
    this.set({
      fgColor: ThemeColorVar.primary,
      iconSize: 26,
      btnWidth: 26,
      btnHeight: 26,
    });
  }

  @Input() set icon(icon: IconInterface | string | Observable<IconInterface | string>) {
    this.setProperty('icon', icon);
  }

  @Input() set iconSize(iconSize: number | Observable<number>) {
    this.setProperty('iconSize', iconSize);
  }

  @Input() set btnWidth(btnWidth: number | Observable<number>) {
    this.setProperty('btnWidth', btnWidth);
  }

  @Input() set btnHeight(btnHeight: number | Observable<number>) {
    this.setProperty('btnHeight', btnHeight);
  }

  @Input() set btnSize(btnSize: number | Observable<number>) {
    if (isObservable(btnSize)) {
      this.connect(btnSize.pipe(map((btnSize) => ({ btnWidth: btnSize, btnHeight: btnSize }))));
    } else {
      this.set({ btnWidth: btnSize, btnHeight: btnSize });
    }
  }

  @Input() set fgColor(fgColor: string | Observable<string>) {
    this.setProperty('fgColor', fgColor);
  }

  @Output() btnClick = new EventEmitter<never>();

  readonly icon$ = this.select(
    selectSlice(['icon']),
    map(({ icon }) => icon),
  );

  readonly iconSize$ = this.select(
    selectSlice(['iconSize']),
    map(({ iconSize }) => iconSize),
  );

  readonly fgColor$ = this.select(
    selectSlice(['fgColor']),
    map(({ fgColor }) => fgColor),
  );

  readonly btnClass$ = this.select(
    selectSlice(['btnWidth', 'btnHeight']),
    map(({ btnWidth, btnHeight }) =>
      tss({
        ...exactWidth(btnWidth),
        ...exactHeight(btnHeight),
        ...flexCenter,
      }),
    ),
  );
}
