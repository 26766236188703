import { MAX_ATTACHMENT_FILE_SIZE_MB } from '@t5s/shared/readonly-constant/file-attachment';
import { bytesFromMegaBytes } from '@t5s/shared/util/file';

const MAX_FILE_SIZE = bytesFromMegaBytes(MAX_ATTACHMENT_FILE_SIZE_MB);

export function attachmentFileValidForUpload(file: File): boolean {
  return file.size <= MAX_FILE_SIZE;
}

export function imageFileValidForUpload(file: File): boolean {
  return file.size <= MAX_FILE_SIZE;
}
