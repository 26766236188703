import gql from 'graphql-tag';
import { OrganizationActivityDtoFragment } from './organization-activity-dto.fragment';

export const OrganizationActivityConnectionFragment = gql`
  fragment OrganizationActivityConnectionFragment on OrganizationActivityConnection {
    edges {
      cursor
      node {
        ...OrganizationActivityDtoFragment
      }
    }
  }
  ${OrganizationActivityDtoFragment}
`;
