import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ServiceClipboardModule } from '@t5s/mobile-client/service/clipboard';
import { ServiceStorageModule } from '@t5s/mobile-client/service/storage';
import { GqlLoginService, SharedApolloClient } from '@t5s/shared/gql-services';
import { DebugConsoleEffects } from './debug-console.effects';
import { debugConsoleReducer, debugConsoleStateKey } from './debug-console.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(debugConsoleStateKey, debugConsoleReducer),
    EffectsModule.forFeature([DebugConsoleEffects]),
    ServiceStorageModule,
    ServiceClipboardModule,
  ],
  providers: [
    {
      provide: GqlLoginService,
      useFactory: (apollo: SharedApolloClient) => new GqlLoginService(apollo),
      deps: [SharedApolloClient],
    },
  ],
})
export class DebugConsoleBusinessLogicModule {}
