export enum ApolloFetchPolicy {
  /** Does not write or read from/to the cache. This is the default. */
  NO_CACHE = 'no-cache',

  /** @depreacted - Writes to the cache, never reads from it. */
  NETWORK_ONLY = 'network-only',

  /** @deprecated  Writes to the cache, returns cached result if available, executes and returns a network result otherwise. Use only for pure queries. */
  CACHE = 'cache-first',
}
