import gql from 'graphql-tag';
import {
  WorkflowCenterWorkflowActionPreviewNoNestingFragment,
  WorkflowCenterWorkflowPreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { UserProfilePreviewDtoFragment } from '../user-profile';

export const WorkflowCenterWorkflowPreviewDtoFragment = gql`
  fragment WorkflowCenterWorkflowPreviewDtoFragment on WorkflowCenterWorkflowPreviewDto {
    ...WorkflowCenterWorkflowPreviewDtoNoNesting
    createdByUser {
      ...UserProfilePreviewDtoFragment
    }
    lastModifiedByUser {
      ...UserProfilePreviewDtoFragment
    }
    actions {
      ...WorkflowCenterWorkflowActionPreviewNoNesting
    }
  }
  ${UserProfilePreviewDtoFragment}
  ${WorkflowCenterWorkflowPreviewDtoNoNestingFragment}
  ${WorkflowCenterWorkflowActionPreviewNoNestingFragment}
`;
