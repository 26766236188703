import { CdkScrollableModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { ScrollContainerComponent } from './scroll-container.component';

const components = [ScrollContainerComponent];

@NgModule({
  imports: [ComponentCommonModule, CdkScrollableModule],
  declarations: components,
  exports: components,
})
export class ScrollContainerComponentModule {}
