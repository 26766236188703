import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getFontStyle, px, variable } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent } from '@t5s/mobile-client/ui/component/common';
import { FontSet } from '@t5s/mobile-client/ui/style/theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface TextState {
  font?: FontSet;
  fontSize?: number;
  lineHeight?: number;
  fontWeight?: number;

  centered: boolean;
  fgColor?: string;
  maxLines?: number;
}

export const TEXT_FG_COLOR = '--t5s-text-fg-color';

@Component({
  selector: 't5s-text',
  template: `<span [class]="textClass$ | push"> <ng-content></ng-content> </span>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextComponent extends RxComponent<TextState> {
  constructor() {
    super();
    this.set({
      centered: false,
    });
  }

  @Input() set font(font: FontSet | Observable<FontSet>) {
    this.setProperty('font', font);
  }

  @Input() set fontSize(fontSize: number | Observable<number>) {
    this.setProperty('fontSize', fontSize);
  }

  @Input() set lineHeight(lineHeight: number | Observable<number>) {
    this.setProperty('lineHeight', lineHeight);
  }

  @Input() set fontWeight(fontWeight: number | Observable<number>) {
    this.setProperty('fontWeight', fontWeight);
  }

  @Input() set fgColor(fgColor: string | Observable<string>) {
    this.setProperty('fgColor', fgColor);
  }

  @Input() set centered(centered: boolean | Observable<boolean>) {
    this.setProperty('centered', centered);
  }

  @Input() set maxLines(maxLines: number | Observable<number>) {
    this.setProperty('maxLines', maxLines);
  }

  readonly textClass$ = this.state$.pipe(
    map(({ font, fontSize, lineHeight, fontWeight, fgColor, centered, maxLines }) => {
      fontSize = fontSize ?? font?.fontSize ?? 0;
      lineHeight = lineHeight ?? font?.lineHeight ?? 0;
      fontWeight = fontWeight ?? font?.fontWeight ?? 0;

      return tss({
        display: 'inline-block',
        ...getFontStyle({ fontSize, lineHeight, fontWeight }),
        color: variable(TEXT_FG_COLOR, fgColor ?? undefined),
        wordBreak: 'break-word',
        ...(centered ? { textAlign: 'center', display: 'block' } : {}),
        ...(maxLines && maxLines > 0
          ? {
              display: '-webkit-box',
              WebkitLineClamp: maxLines,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              maxHeight: px(maxLines * lineHeight), // Important for Safari
            }
          : {}),
      });
    }),
  );
}
