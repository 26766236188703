import gql from 'graphql-tag';
import { WorkflowDefActivityDtoFragment } from '../workflow-def-activity/workflow-def-activity-dto.fragment';
import { FullUserProfileDtoFragment } from '../user-profile';

export const WorkflowDefNotificationActivityDtoFragment = gql`
  fragment WorkflowDefNotificationActivityDtoFragment on WorkflowDefNotificationActivityDto {
    direct
    notificationId
    activity {
      ...WorkflowDefActivityDtoFragment
    }
  }
  ${FullUserProfileDtoFragment}
  ${WorkflowDefActivityDtoFragment}
`;
