import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  BlabItemActivityThreadState,
  blabItemActivityThreadStateKey,
  selectEntities,
} from './blab-item-activity-thread.reducer';

const selectBlabItemActivityThreadFeatureState =
  createFeatureSelector<BlabItemActivityThreadState>(blabItemActivityThreadStateKey);

export const selectBlabItemActivityThreadDict = createSelector(selectBlabItemActivityThreadFeatureState, (state) =>
  selectEntities(state),
);

export const selectBlabItemActivityThreadUserSearchResults = createSelector(
  selectBlabItemActivityThreadFeatureState,
  (state) => state.userSearchResults,
);
