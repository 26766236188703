import gql from 'graphql-tag';
import {
  ThumbnailDtoNoNestingFragment,
  UserProfileDtoNoNestingFragment,
  UserStatusDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const FullUserProfileDtoFragment = gql`
  fragment FullUserProfileDto on UserProfileDto {
    ...UserProfileDtoNoNesting
    profilePicture {
      ...ThumbnailDtoNoNesting
    }
    organization {
      id
      slug
    }
    status {
      ...UserStatusDtoNoNesting
    }
  }
  ${UserProfileDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
  ${UserStatusDtoNoNestingFragment}
`;
