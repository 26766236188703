import { Filetype } from '@t5s/client/readonly-constant/file';
import {
  graphicFileAccdb,
  graphicFileAi,
  graphicFileAudio,
  graphicFileCsv,
  graphicFileDocx,
  graphicFileFallback,
  graphicFileGdoc,
  graphicFileGform,
  graphicFileGsheet,
  graphicFileGslides,
  graphicFileHtml,
  graphicFileImage,
  graphicFileIndd,
  graphicFileKey,
  graphicFileNumbers,
  graphicFileOd,
  graphicFilePages,
  graphicFilePdf,
  graphicFilePptx,
  graphicFilePsd,
  graphicFileRtf,
  graphicFileTxt,
  graphicFileVideo,
  graphicFileXlsx,
  graphicFileZip,
} from '@t5s/mobile-client/asset';

export const fileTypeInfo = [
  { type: Filetype.ACCDB, graphic: graphicFileAccdb },
  { type: Filetype.AI, graphic: graphicFileAi },
  { type: Filetype.AUDIO, graphic: graphicFileAudio },
  { type: Filetype.CSV, graphic: graphicFileCsv },
  { type: Filetype.DOCX, graphic: graphicFileDocx },
  { type: Filetype.FALLBACK, graphic: graphicFileFallback },
  { type: Filetype.GDOC, graphic: graphicFileGdoc },
  { type: Filetype.GFORM, graphic: graphicFileGform },
  { type: Filetype.GSHEET, graphic: graphicFileGsheet },
  { type: Filetype.GSLIDES, graphic: graphicFileGslides },
  { type: Filetype.HTML, graphic: graphicFileHtml },
  { type: Filetype.IMAGE, graphic: graphicFileImage },
  { type: Filetype.INDD, graphic: graphicFileIndd },
  { type: Filetype.KEY, graphic: graphicFileKey },
  { type: Filetype.NUMBERS, graphic: graphicFileNumbers },
  { type: Filetype.OD, graphic: graphicFileOd },
  { type: Filetype.PAGES, graphic: graphicFilePages },
  { type: Filetype.PDF, graphic: graphicFilePdf },
  { type: Filetype.PPTX, graphic: graphicFilePptx },
  { type: Filetype.PSD, graphic: graphicFilePsd },
  { type: Filetype.RTF, graphic: graphicFileRtf },
  { type: Filetype.TXT, graphic: graphicFileTxt },
  { type: Filetype.VIDEO, graphic: graphicFileVideo },
  { type: Filetype.XLSX, graphic: graphicFileXlsx },
  { type: Filetype.ZIP, graphic: graphicFileZip },
];
