import { NgModule } from '@angular/core';
import { KeyboardComponentModule } from '@t5s/mobile-client/ui/component/keyboard';
import { NavigationBarComponentModule } from '@t5s/mobile-client/ui/component/navigation-bar';
import { ViewCommonModule } from '@t5s/mobile-client/ui/view/common';
import { LoggedInRootViewComponent } from './logged-in-root-view.component';

const components = [LoggedInRootViewComponent];

@NgModule({
  imports: [ViewCommonModule, NavigationBarComponentModule, KeyboardComponentModule],
  declarations: components,
  exports: components,
})
export class LoggedInRootViewModule {}
