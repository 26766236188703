import { ConnectionStatus } from '@capacitor/network';
import { createReducer, on } from '@ngrx/store';
import { NetworkStatusActions } from './network-status.actions';

export const networkStatusStateKey = 'networkStatus';
export interface NetworkStatusState {
  status?: ConnectionStatus;
}

const initialState: NetworkStatusState = {};

export const networkStatusReducer = createReducer(
  initialState,
  on(NetworkStatusActions.setNetworkStatus, (state, { status }) => ({
    ...state,
    status,
  })),
);
