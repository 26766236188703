import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  blur,
  border,
  ComponentStyle,
  exactHeight,
  exactSize,
  flexCenter,
  flexCenterHorizontal,
  flexCenterVertical,
  flexRow,
  fullWidth,
  oddChild,
  padding,
  px,
  rgba,
  spread,
} from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import {
  iconCarOutlined,
  iconEcoOutlined,
  iconEmojiEmotionsOutlined,
  iconEmojiObjectsOutlined,
  iconFavoriteOutlined,
  iconFlagOutlined,
  iconLunchDining,
  iconScheduleOutlined,
  iconSportsSoccerOutlined,
} from '@t5s/mobile-client/asset';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { EmojiPickerCategory } from '@t5s/mobile-client/value-object/emoji-picker';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface EmojiPickerCategoryNavigationBarState {
  selected?: EmojiPickerCategory;
}

@Component({
  selector: 't5s-emoji-picker-category-navigation-bar',
  template: `
    <div [class]="wrapperClass">
      <div [class]="containerClass">
        <ng-container *ngIf="selected$ | push as selected">
          <ng-container *ngFor="let category of EmojiPickerCategories; trackBy: trackById; let last = last">
            <button
              (t5sPressDisableLongpress)="categoryClick.emit({ category: category.id })"
              [style.background-color]="category.id === selected ? Color.lightest : undefined"
            >
              <t5s-icon
                [size]="22"
                [fgColor]="category.id === selected ? Color.primary : Color.darker"
                [icon]="category.icon"
              ></t5s-icon>
            </button>

            <!-- Divider -->
            <ng-container *ngIf="!last">
              <div [class]="dividerClass"></div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [ComponentStyle.HostFullWidth],
})
export class EmojiPickerCategoryNavigationBarComponent extends RxComponent<EmojiPickerCategoryNavigationBarState> {
  readonly EmojiPickerCategories = [
    { id: EmojiPickerCategory.FREQUENT, icon: iconScheduleOutlined },
    { id: EmojiPickerCategory.PEOPLE, icon: iconEmojiEmotionsOutlined },
    { id: EmojiPickerCategory.NATURE, icon: iconEcoOutlined },
    { id: EmojiPickerCategory.FOODS, icon: iconLunchDining },
    { id: EmojiPickerCategory.ACTIVITY, icon: iconSportsSoccerOutlined },
    { id: EmojiPickerCategory.PLACES, icon: iconCarOutlined },
    { id: EmojiPickerCategory.OBJECTS, icon: iconEmojiObjectsOutlined },
    { id: EmojiPickerCategory.SYMBOLS, icon: iconFavoriteOutlined },
    { id: EmojiPickerCategory.FLAGS, icon: iconFlagOutlined },
  ];

  @Input() set selected(selected: EmojiPickerCategory | Observable<EmojiPickerCategory>) {
    this.setProperty('selected', selected);
  }

  @Output() categoryClick = new EventEmitter<{ category: EmojiPickerCategory }>();
  readonly selected$ = this.select(
    selectSlice(['selected']),
    map(({ selected }) => selected),
  );

  readonly wrapperClass = tss({
    ...fullWidth,
    padding: padding(0, 27),
    ...exactHeight(32),
    ...flexCenterHorizontal,
  });

  readonly containerClass = tss({
    ...spread,
    borderRadius: px(8),
    backgroundColor: rgba(222, 222, 223, 0.6),
    backdropFilter: blur(20),
    padding: padding(0, 2),
    ...flexRow,
    ...flexCenterVertical,
    minWidth: 'max-content',
    justifyContent: 'space-between',
    ...oddChild({
      ...exactSize(28),
      ...flexCenter,
      borderRadius: px(6),
    }),
  });

  readonly dividerClass = tss({
    ...exactHeight(17),
    borderRadius: px(1),
    borderLeft: border(1, 'solid', rgba(0, 0, 0, 0.07)),
    marginLeft: px(2),
    marginRight: px(2),
  });
}
