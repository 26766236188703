import gql from 'graphql-tag';
import { BlabItemsOverviewFlimValsFragment } from './flim-val';

export const BlabItemViewDtoWithTitleFragment = gql`
  fragment BlabItemViewDtoWithTitleFragment on BlabItemViewDto {
    id
    title
    blabDefId
    createdAt
    isTemplate
    viewPosition
    numComments
    flimVals {
      ...BlabItemsOverviewFlimValsFragment
    }
  }
  ${BlabItemsOverviewFlimValsFragment}
`;

export const AllBlabItemsWithTitleFragment = gql`
  fragment AllBlabItemsWithTitle on BlabItemsConnection {
    pageInfo {
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        ...BlabItemViewDtoWithTitleFragment
      }
    }
  }
  ${BlabItemViewDtoWithTitleFragment}
`;
