import { I18nTranslation } from '@t5s/mobile-client/i18n/common';
import { Language } from '@t5s/shared/gql';

const languages = {
  [Language.DE]: {
    messageInput: {
      attachmentInvalidSizeToast: 'Eine oder mehrere Dateien überschreiten das Limit von 100 MB.',
      placeholder: 'Nachricht schreiben',
      sendBtnLabel: 'Senden',
    },
    confirmDeleteMessageDialog: {
      title: 'Nachricht löschen?',
      message: 'Bist du sicher, dass du diese Nachricht löschen möchtest? Dies kann nicht rückgängig gemacht werden.',
      cancelButtonTitle: 'Abbrechen',
      okButtonTitle: 'Löschen',
    },
    messageActivityOptionsBottomSheet: {
      cancelBtnLabel: 'Abbrechen',
      deleteMessageBtnLabel: 'Nachricht löschen',
      copyTextBtnLabel: 'Text kopieren',
      retryMessageBtnLabel: 'Erneut senden',
    },
  },
  [Language.EN]: {
    messageInput: {
      attachmentInvalidSizeToast: 'One or more files exceed the 100 MB limit.',
      placeholder: 'Start a message',
      sendBtnLabel: 'Send',
    },
    confirmDeleteMessageDialog: {
      title: 'Delete message?',
      message: 'Are you sure you want to delete this message? This cannot be undone.',
      cancelButtonTitle: 'Cancel',
      okButtonTitle: 'Delete',
    },
    messageActivityOptionsBottomSheet: {
      cancelBtnLabel: 'Cancel',
      deleteMessageBtnLabel: 'Delete message',
      copyTextBtnLabel: 'Copy text',
      retryMessageBtnLabel: 'Try again',
    },
  },
} as const;

export const ChatConversationI18n = new I18nTranslation<typeof languages.EN>(languages);
