import {
  ActiveUserExploreSuggestionDto,
  GetActiveUserExploreSuggestionsForCategoryConnection,
  GetActiveUserExploreSuggestionsForCategoryInput,
  GetActiveUserExploreSuggestionsInput,
} from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import {
  ActiveUserExploreSuggestionDtoFragment,
  GetActiveUserExploreSuggestionsForCategoryConnectionFragment,
} from '../../fragments/active-user-explore';
import {
  getActiveUserExploreSuggestions,
  getActiveUserExploreSuggestionsForCategory,
} from '../../generated/graphql-queries';
import { GqlService } from '../../gql-service';

export class GqlActiveUserExploreService extends GqlService {
  getActiveUserExploreSuggestions(
    input: GetActiveUserExploreSuggestionsInput,
  ): Observable<ActiveUserExploreSuggestionDto[]> {
    return this.apollo.defaultQuery(getActiveUserExploreSuggestions(input, [ActiveUserExploreSuggestionDtoFragment]));
  }

  getActiveUserExploreSuggestionsForCategory(
    first: number,
    input?: GetActiveUserExploreSuggestionsForCategoryInput,
    after?: string,
  ): Observable<GetActiveUserExploreSuggestionsForCategoryConnection> {
    return this.apollo.defaultQuery(
      getActiveUserExploreSuggestionsForCategory(first, input, after, [
        GetActiveUserExploreSuggestionsForCategoryConnectionFragment,
      ]),
    );
  }
}
