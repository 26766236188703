export function parseInteger(candidate: unknown): number | undefined {
  if (typeof candidate === 'object' || candidate === null || candidate === undefined) {
    return undefined;
  }

  if (typeof candidate === 'string' || typeof candidate === 'number') {
    // Make sure only numbers are inside the string
    if (!/^[0-9]+$/.test(`${candidate}`)) {
      return undefined;
    }

    const numberCandidate = parseInt(`${candidate}`, 10);
    if (isNaN(numberCandidate) || !isFinite(numberCandidate)) {
      return undefined;
    }

    return numberCandidate;
  }

  return undefined;
}

/** V2 that also parsed negative integers. */
export function parseIntegerV2(candidate: unknown): number | undefined {
  if (typeof candidate === 'object' || candidate === null || candidate === undefined) {
    return undefined;
  }

  if (typeof candidate === 'string' || typeof candidate === 'number') {
    // Make sure only numbers are inside the string
    if (!/^[\-]?[0-9]+$/.test(`${candidate}`)) {
      return undefined;
    }

    const numberCandidate = parseInt(`${candidate}`, 10);
    if (isNaN(numberCandidate) || !isFinite(numberCandidate)) {
      return undefined;
    }

    return numberCandidate;
  }

  return undefined;
}

export function parseIntegerOrThrow(candidate: unknown): number {
  const result = parseInteger(candidate);

  if (result === undefined || result === null) {
    throw new Error(`Cannot parse integer from ${String(candidate)}`);
  }

  return result;
}
