import gql from 'graphql-tag';
import {
  AudioRoomParticipantDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
  UserProfilePreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const AudioRoomParticipantDtoFragment = gql`
  fragment AudioRoomParticipantDtoFragment on AudioRoomParticipantDto {
    ...AudioRoomParticipantDtoNoNesting
    user {
      ...UserProfilePreviewDtoNoNesting
      profilePicture {
        ...ThumbnailDtoNoNesting
      }
    }
  }
  ${AudioRoomParticipantDtoNoNestingFragment}
  ${UserProfilePreviewDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
