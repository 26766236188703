import { Action } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { ErrorActionType } from './error-action';
import { ERROR_PREFIX } from './error-prefix';

function isErrorAction(action: Action): action is ErrorActionType {
  return action.type.includes(ERROR_PREFIX);
}

export const ofErrorType = () => filter(isErrorAction);
