import { SetUserClientAppMetaDataInput, UserClientAppBubbleDto, Void } from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import { setUserClientAppMetaData } from '../../generated/graphql-mutations';
import { getUserClientAppBubbles } from '../../generated/graphql-queries';
import { subscribeToUserClientAppBubbles } from '../../generated/graphql-subscriptions';
import { GqlService } from '../../gql-service';

export class GqlUserClientAppService extends GqlService {
  getUserClientAppBubbles(): Observable<UserClientAppBubbleDto[]> {
    return this.apollo.defaultQuery(getUserClientAppBubbles());
  }

  setUserClientAppMetaData(input: SetUserClientAppMetaDataInput): Observable<Void> {
    return this.apollo.defaultMutate(setUserClientAppMetaData(input));
  }

  subscribeToUserClientAppBubbles(): Observable<UserClientAppBubbleDto> {
    return this.apollo.defaultSubscribe(subscribeToUserClientAppBubbles());
  }
}
