import { NgModule } from '@angular/core';
import { ApolloModule } from 'apollo-angular';
import { ApolloOptionsModule } from './apollo-options';
import { SHARED_APOLLO_CLIENT_PROVIDER } from './providers';

@NgModule({
  imports: [ApolloModule, ApolloOptionsModule],
  providers: [SHARED_APOLLO_CLIENT_PROVIDER],
})
export class ClientDataAccessApolloGqlModule {}
