import { UserFeatureAccessLevel } from '@t5s/shared/gql';

const { DEV, INTERNAL, BETA, STABLE } = UserFeatureAccessLevel;

export function canAccessFeatureAccessLevel(
  actualLevel: UserFeatureAccessLevel,
  requiredLevel: UserFeatureAccessLevel,
): boolean {
  switch (actualLevel) {
    case STABLE: {
      return requiredLevel === STABLE;
    }

    case BETA: {
      return requiredLevel === STABLE || requiredLevel === BETA;
    }

    case INTERNAL: {
      return requiredLevel === STABLE || requiredLevel === BETA || requiredLevel === INTERNAL;
    }

    case DEV: {
      return requiredLevel === STABLE || requiredLevel === BETA || requiredLevel === INTERNAL || requiredLevel === DEV;
    }
  }
}
