import { HttpClient, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiHttpClient } from '@t5s/shared/provider-token/api-http-client';
import { ApiVersion } from '@t5s/shared/readonly-constant/api';
import { isNotNullish } from '@t5s/shared/util/object';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ClientApiHttpClient extends ApiHttpClient {
  constructor(private readonly http: HttpClient) {
    super({ baseUrl: '', version: ApiVersion.V1 });
  }

  post<TInput extends {}, TOutput extends {}>({
    path,
    data,
    ...input
  }: {
    path: string;
    data?: TInput;
    headers?: { [header: string]: string | undefined | string[] };
  }): Observable<{ data: TOutput }> {
    const headers: { [key: string]: string | string[] } = {};
    Object.entries(input.headers ?? {}).forEach(([key, value]) => {
      if (value !== undefined) {
        headers[key] = value;
      }
    });

    return this.http.post<TOutput>(`${this.version}/${path}`, data, { headers }).pipe(map((data) => ({ data })));
  }

  upload<TOutput>({
    path,
    data,
  }: {
    path: string;
    data: FormData;
  }): Observable<{ data?: TOutput; uploadProgress?: number }> {
    return this.http.post<TOutput>(`${this.version}/${path}`, data, { reportProgress: true, observe: 'events' }).pipe(
      map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
          case HttpEventType.DownloadProgress: {
            return event.total ? { uploadProgress: (event.loaded / event.total) * 100 } : { uploadProgress: 0 };
          }

          case HttpEventType.Response: {
            return event.body !== null ? { data: event.body } : undefined;
          }
        }
        return undefined;
      }),
      filter(isNotNullish),
    );
  }

  setAuthHeader() {}
}
