import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ServiceKeyboardModule } from '@t5s/mobile-client/service/keyboard';
import { ServiceStateHydrationModule } from '@t5s/mobile-client/service/state-hydration';
import { EmojiPickerEffects } from './emoji-picker.effects';
import { emojiPickerReducer, emojiPickerStateKey } from './emoji-picker.reducer';

@NgModule({
  imports: [
    StoreModule.forFeature(emojiPickerStateKey, emojiPickerReducer),
    EffectsModule.forFeature([EmojiPickerEffects]),
    ServiceKeyboardModule,
    ServiceStateHydrationModule,
  ],
})
export class EmojiPickerBusinessLogicModule {}
