import gql from 'graphql-tag';
import { NotificationDtoFragmentV2 } from './notification-dto-v2.fragment';

export const NotificationConnectionFragmentV2 = gql`
  fragment NotificationConnectionFragmentV2 on NotificationConnection {
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...NotificationDtoFragmentV2
      }
    }
  }
  ${NotificationDtoFragmentV2}
`;
