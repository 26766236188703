import { createReducer, on } from '@ngrx/store';
import { ConsoleStatement } from '@t5s/mobile-client/value-object/debug-console';
import { DebugConsoleActions } from './debug-console.actions';

export const debugConsoleStateKey = 'debugConsole';
export interface DebugConsoleState {
  clientBuildVersion?: string;

  storageKeys: string[];
  selectedStorageKey?: string | undefined;
  selectedStorageKeyValue?: string | undefined;
  logs: ConsoleStatement[];
}

const initialState: DebugConsoleState = {
  storageKeys: [],
  logs: [],
};

export const debugConsoleReducer = createReducer(
  initialState,
  on(DebugConsoleActions.reset, () => ({ ...initialState })),

  // Version
  on(DebugConsoleActions.loadClientBuildVersionSuccess, (state, { clientBuildVersion }) => ({
    ...state,
    clientBuildVersion,
  })),

  // Storage
  on(DebugConsoleActions.readStorageKeysSuccess, (state, { keys }) => ({ ...state, storageKeys: keys })),
  on(DebugConsoleActions.loadStorageKey, (state, { key }) => ({ ...state, selectedStorageKey: key })),
  on(DebugConsoleActions.loadStorageKeySuccess, (state, { key, value }) => ({
    ...state,
    selectedStorageKey: key,
    selectedStorageKeyValue: value,
  })),
  on(DebugConsoleActions.deleteStorageKey, ({ storageKeys, ...state }, { key }) => ({
    ...state,
    storageKeys: storageKeys.filter((k) => k !== key),
  })),

  // Logs
  on(DebugConsoleActions.addConsoleStatement, ({ logs, ...state }, { statement }) => ({
    ...state,
    logs: logs.concat(statement),
  })),
);
