import gql from 'graphql-tag';
import { BlabViewDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { FlimFilterFragment } from './flim-filter.fragment';

export const BlabViewFiltersOnlyFragment = gql`
  fragment BlabViewFiltersOnly on BlabViewDto {
    ...BlabViewDtoNoNesting
    flimFilters {
      ...FlimFilterFragment
    }
  }
  ${BlabViewDtoNoNestingFragment}
  ${FlimFilterFragment}
`;
