import { BlabItemActivityThreadStateModel } from '@t5s/mobile-client/value-object/blab-item-activity-thread';

/**
 *  Properly handles a reply subscription update and returns the mutated blab item activity thread state.
 */
export function blabItemActivityThreadReplyRemoved(
  blabItemActivityThread: BlabItemActivityThreadStateModel,
  removedReplyId: number,
): BlabItemActivityThreadStateModel {
  const { activityReplies } = blabItemActivityThread;

  if (!activityReplies) {
    return blabItemActivityThread;
  }

  let { replies } = activityReplies;

  replies = replies.filter((reply) => reply.node.id !== removedReplyId);
  return { ...blabItemActivityThread, activityReplies: { ...activityReplies, replies } };
}
