import gql from 'graphql-tag';
import { NewsfeedActivityDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { BlabItemActivityReplyDtoFragment } from '../blab-item-activity';
import { BlabItemActivityUnionTypeFragment } from '../blab-item-activity/blab-item-activity-union-type.fragment';
import { OrganizationActivityUnionTypeFragment } from '../organization-activity';
import { UserActivityUnionTypeFragment } from '../user-activity/user-activity-union-type.fragment';
import { WorkspaceActivityUnionTypeFragment } from '../workspace-activity/workspace-activity-union-type.fragment';

export const FullChronologicalNewsfeedConnectionFragment = gql`
  fragment FullChronologicalNewsfeedConnection on ChronologicalNewsfeedConnection {
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...NewsfeedActivityDtoNoNesting
        blabItemActivity {
          ...BlabItemActivityUnionTypeFragment
          ... on BlabItemActivityDto {
            replies(first: 50) {
              cursor
              node {
                ...BlabItemActivityReplyDtoFragment
              }
            }
          }
        }
        workspaceActivity {
          ...WorkspaceActivityUnionTypeFragment
        }
        organizationActivity {
          ...OrganizationActivityUnionTypeFragment
        }
        userActivity {
          ...UserActivityUnionTypeFragment
        }
      }
    }
  }
  ${NewsfeedActivityDtoNoNestingFragment}

  ${BlabItemActivityUnionTypeFragment}
  ${WorkspaceActivityUnionTypeFragment}
  ${OrganizationActivityUnionTypeFragment}
  ${UserActivityUnionTypeFragment}
  ${BlabItemActivityReplyDtoFragment}
`;
