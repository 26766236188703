export interface StringFilter {
  pattern?: string | string[] | RegExp | RegExp[];
  startsWith?: string;
  endsWith?: string;
  whitelist?: string[];
  blacklist?: string[];
}

export function filterString(str: string, { pattern, whitelist, blacklist, startsWith, endsWith }: StringFilter = {}) {
  if (whitelist && !whitelist.includes(str)) {
    return false;
  }

  if (blacklist && blacklist.includes(str)) {
    return false;
  }

  if (startsWith && !str.startsWith(startsWith)) {
    return false;
  }

  if (endsWith && !str.endsWith(endsWith)) {
    return false;
  }

  if (
    Array.isArray(pattern) &&
    !(pattern as unknown as RegExp[]).reduce((res, reg) => res && new RegExp(reg).test(str), true)
  ) {
    return false;
  }

  if (!Array.isArray(pattern) && pattern && !new RegExp(pattern).test(str)) {
    return false;
  }

  return true;
}

export function filterStringList(list: string[], filter: StringFilter = {}) {
  return list.filter((str) => filterString(str, filter));
}

export function isNotNullish<T>(el: T | null | undefined): el is T {
  return el !== null && el !== undefined;
}

export function isNullish<T>(value?: T | null | undefined): value is null | undefined {
  return value == null || value === undefined;
}
