import { NgModule } from '@angular/core';
import { PlatformObservable } from '@t5s/mobile-client/provider-token/platform';
import { DeviceService, ServiceDeviceModule } from '@t5s/mobile-client/service/device';
import { userClientAppPlatformFromCapacitorPlatform } from '@t5s/shared/util/platform';
import { map } from 'rxjs/operators';

@NgModule({
  imports: [ServiceDeviceModule],
  providers: [
    {
      provide: PlatformObservable,
      useFactory: (service: DeviceService): PlatformObservable =>
        service.deviceInfo$.pipe(
          map((device) => device.platform),
          map(userClientAppPlatformFromCapacitorPlatform),
        ),
      deps: [DeviceService],
    },
  ],
})
export class PlatformProviderModule {}
