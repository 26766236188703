export function normalizeDate(date: string | number | Date): Date {
  if (!date) {
    throw new Error(`Invalid date input: ${date}`);
  }

  if (!(date instanceof Date)) {
    return new Date(date);
  }

  return date;
}
