
  /* eslint-disable */
  import { getFragmentNames, importFragments } from '../utils';
  import { DocumentNode } from 'graphql';
  import gql from 'graphql-tag';
  import {
    ActiveUserBlabItemInfoDtoNoNestingFragment,
  ActiveUserChangesSubscriptionEventNoNestingFragment,
  AudioRoomDtoNoNestingFragment,
  AudioRoomPreviewDtoNoNestingFragment,
  GlobalWorkflowRunsSubscriptionUpdateDtoNoNestingFragment,
  ManualRunsCompletedDtoNoNestingFragment,
  NewsfeedActivityDtoNoNestingFragment,
  NotificationDtoNoNestingFragment,
  OrganizationActivityReplyDtoNoNestingFragment,
  OrganizationChangesSubscriptionEventNoNestingFragment,
  OrganizationWorkflowStatusDtoNoNestingFragment,
  UserActivityReplyDtoNoNestingFragment,
  UserClientAppBubbleDtoNoNestingFragment,
  UserClientApplicationChangesSubscriptionEventNoNestingFragment,
  UserStatusDtoNoNestingFragment
  } from './graphql-fragments'
  import {
    GetActiveUserBlabItemInfoInput,
  GetNotificationsInput,
  SubscribeToActiveUserChangesInput,
  SubscribeToAudioRoomInput,
  SubscribeToAudioRoomPreviewsInput,
  SubscribeToBlabDefDeveloperInfoInput,
  SubscribeToBulkFollowUnfollowBlabItemsInput,
  SubscribeToDuplicateBlabDefProgressInput,
  SubscribeToDuplicateWorkspaceProgressInput,
  SubscribeToExportBlabDefDataProgressInput,
  SubscribeToFocusEntriesInput,
  SubscribeToImportBlabDefDataProgressInput,
  SubscribeToManualRunsCompletionInput,
  SubscribeToOauthIntegrationAuthenticationStatusInput,
  SubscribeToOrganizationChangesInput,
  SubscribeToUpdateBlabItemsFlimValInput,
  SubscribeToUserClientApplicationChangesInput,
  SubscribeToUserStatusesInput,
  SubscribeToWorkflowCenterWebhooksInput,
  SubscribeToWorkflowRunsOfWorkflowDefInput
  } from '@t5s/shared/gql'

  
export function subscribeToActiveUserChanges(input: SubscribeToActiveUserChangesInput, fragments: DocumentNode[]= [ActiveUserChangesSubscriptionEventNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToActiveUserChanges($input: SubscribeToActiveUserChangesInput!) {
        subscribeToActiveUserChanges (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToActiveUserBlabItemInfo(input: GetActiveUserBlabItemInfoInput, fragments: DocumentNode[]= [ActiveUserBlabItemInfoDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToActiveUserBlabItemInfo($input: GetActiveUserBlabItemInfoInput!) {
        subscribeToActiveUserBlabItemInfo (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToAudioRoom(input: SubscribeToAudioRoomInput, fragments: DocumentNode[]= [AudioRoomDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToAudioRoom($input: SubscribeToAudioRoomInput!) {
        subscribeToAudioRoom (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToAudioRoomPreviews(input: SubscribeToAudioRoomPreviewsInput, fragments: DocumentNode[]= [AudioRoomPreviewDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToAudioRoomPreviews($input: SubscribeToAudioRoomPreviewsInput!) {
        subscribeToAudioRoomPreviews (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToExportBlabDefDataProgress(input: SubscribeToExportBlabDefDataProgressInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToExportBlabDefDataProgress($input: SubscribeToExportBlabDefDataProgressInput!) {
        subscribeToExportBlabDefDataProgress (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToImportBlabDefDataProgress(input: SubscribeToImportBlabDefDataProgressInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToImportBlabDefDataProgress($input: SubscribeToImportBlabDefDataProgressInput!) {
        subscribeToImportBlabDefDataProgress (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToBlabDefDeveloperInfo(input: SubscribeToBlabDefDeveloperInfoInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToBlabDefDeveloperInfo($input: SubscribeToBlabDefDeveloperInfoInput!) {
        subscribeToBlabDefDeveloperInfo (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToDuplicateBlabDefProgress(input: SubscribeToDuplicateBlabDefProgressInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToDuplicateBlabDefProgress($input: SubscribeToDuplicateBlabDefProgressInput!) {
        subscribeToDuplicateBlabDefProgress (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToUpdateBlabItemsFlimValProgress(input: SubscribeToUpdateBlabItemsFlimValInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToUpdateBlabItemsFlimValProgress($input: SubscribeToUpdateBlabItemsFlimValInput!) {
        subscribeToUpdateBlabItemsFlimValProgress (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToBlabItemActivity(blabItemId: number, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToBlabItemActivity($blabItemId: Int!) {
        subscribeToBlabItemActivity (blabItemId: $blabItemId) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { blabItemId }
  }
}

export function subscribeToBlabItemActivityReplies(activityId: number, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToBlabItemActivityReplies($activityId: Int!) {
        subscribeToBlabItemActivityReplies (activityId: $activityId) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { activityId }
  }
}

export function subscribeToBulkFollowUnfollowBlabItemsProgress(input: SubscribeToBulkFollowUnfollowBlabItemsInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToBulkFollowUnfollowBlabItemsProgress($input: SubscribeToBulkFollowUnfollowBlabItemsInput!) {
        subscribeToBulkFollowUnfollowBlabItemsProgress (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToBlabItemData(blabItemId: number, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToBlabItemData($blabItemId: Int!) {
        subscribeToBlabItemData (blabItemId: $blabItemId) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { blabItemId }
  }
}

export function subscribeToClientApplicationBrowserNotifications(fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToClientApplicationBrowserNotifications {
        subscribeToClientApplicationBrowserNotifications  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function subscribeToNewsfeedActivity(fragments: DocumentNode[]= [NewsfeedActivityDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToNewsfeedActivity {
        subscribeToNewsfeedActivity  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function subscribeToUserNotifications(input: GetNotificationsInput, fragments: DocumentNode[]= [NotificationDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToUserNotifications($input: GetNotificationsInput!) {
        subscribeToUserNotifications (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToOauthIntegrationAuthenticationStatus(input: SubscribeToOauthIntegrationAuthenticationStatusInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToOauthIntegrationAuthenticationStatus($input: SubscribeToOauthIntegrationAuthenticationStatusInput!) {
        subscribeToOauthIntegrationAuthenticationStatus (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToOrganizationChanges(input: SubscribeToOrganizationChangesInput, fragments: DocumentNode[]= [OrganizationChangesSubscriptionEventNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToOrganizationChanges($input: SubscribeToOrganizationChangesInput!) {
        subscribeToOrganizationChanges (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToOrganizationActivity(organizationId: number, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToOrganizationActivity($organizationId: Int!) {
        subscribeToOrganizationActivity (organizationId: $organizationId) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { organizationId }
  }
}

export function subscribeToOrganizationActivityReplies(activityId: number, fragments: DocumentNode[]= [OrganizationActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToOrganizationActivityReplies($activityId: Int!) {
        subscribeToOrganizationActivityReplies (activityId: $activityId) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { activityId }
  }
}

export function subscribeToReminders(fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToReminders {
        subscribeToReminders  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function subscribeToUserActivity(userId: number, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToUserActivity($userId: Int!) {
        subscribeToUserActivity (userId: $userId) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { userId }
  }
}

export function subscribeToUserActivityReplies(activityId: number, fragments: DocumentNode[]= [UserActivityReplyDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToUserActivityReplies($activityId: Int!) {
        subscribeToUserActivityReplies (activityId: $activityId) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { activityId }
  }
}

export function subscribeToUserClientAppBubbles(fragments: DocumentNode[]= [UserClientAppBubbleDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToUserClientAppBubbles {
        subscribeToUserClientAppBubbles  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function subscribeToUserClientApplicationChanges(input: SubscribeToUserClientApplicationChangesInput, fragments: DocumentNode[]= [UserClientApplicationChangesSubscriptionEventNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToUserClientApplicationChanges($input: SubscribeToUserClientApplicationChangesInput!) {
        subscribeToUserClientApplicationChanges (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToUserStatuses(input: SubscribeToUserStatusesInput, fragments: DocumentNode[]= [UserStatusDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToUserStatuses($input: SubscribeToUserStatusesInput!) {
        subscribeToUserStatuses (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToOrganizationWorkflowStatus(fragments: DocumentNode[]= [OrganizationWorkflowStatusDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToOrganizationWorkflowStatus {
        subscribeToOrganizationWorkflowStatus  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function subscribeToGlobalWorkflowRuns(fragments: DocumentNode[]= [GlobalWorkflowRunsSubscriptionUpdateDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToGlobalWorkflowRuns {
        subscribeToGlobalWorkflowRuns  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function subscribeToManualRunsCompletion(input?: SubscribeToManualRunsCompletionInput, fragments: DocumentNode[]= [ManualRunsCompletedDtoNoNestingFragment], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToManualRunsCompletion($input: SubscribeToManualRunsCompletionInput) {
        subscribeToManualRunsCompletion (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToWorkflowRunsOfWorkflowDef(input?: SubscribeToWorkflowRunsOfWorkflowDefInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToWorkflowRunsOfWorkflowDef($input: SubscribeToWorkflowRunsOfWorkflowDefInput) {
        subscribeToWorkflowRunsOfWorkflowDef (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToWorkflowCenterWebhooks(input: SubscribeToWorkflowCenterWebhooksInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToWorkflowCenterWebhooks($input: SubscribeToWorkflowCenterWebhooksInput!) {
        subscribeToWorkflowCenterWebhooks (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToDuplicateWorkspaceProgress(input: SubscribeToDuplicateWorkspaceProgressInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToDuplicateWorkspaceProgress($input: SubscribeToDuplicateWorkspaceProgressInput!) {
        subscribeToDuplicateWorkspaceProgress (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

export function subscribeToUserConversationPreviews(fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToUserConversationPreviews {
        subscribeToUserConversationPreviews  {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: {  }
  }
}

export function subscribeToSingleConversation(conversationId: number, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToSingleConversation($conversationId: Int!) {
        subscribeToSingleConversation (conversationId: $conversationId) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { conversationId }
  }
}

export function subscribeToFocusEntries(input: SubscribeToFocusEntriesInput, fragments: DocumentNode[]= [], imports: DocumentNode[] = []) {
  let func = gql`
      subscription subscribeToFocusEntries($input: SubscribeToFocusEntriesInput!) {
        subscribeToFocusEntries (input: $input) {
          ...${getFragmentNames(fragments).join('\n...')}
        }
      }
    `
  func = importFragments(func, [...fragments, ...imports])
  return {
    query: func,
    variables: { input }
  }
}

  