import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { eachChild, exactHeight, flexRow } from '@t5s/client/ui/style/common';
import { RuntimeFeatureAccessLevelObservable } from '@t5s/client/util/feature-access-level';
import { tss } from '@t5s/client/util/tss';
import {
  iconHomeTabBarActive,
  iconHomeTabBarInactive,
  iconMessagesTabBarActive,
  iconMessagesTabBarInactive,
  iconNotificationsTabBarActive,
  iconNotificationsTabBarInactive,
  iconSearchTabBarActive,
  iconSearchTabBarInactive,
  iconUserTabBarActive,
  iconUserTabBarInactive,
} from '@t5s/mobile-client/asset';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { NavigationBarItem } from '@t5s/mobile-client/value-object/logged-in-root';
import { UserClientAppBubbleDto, UserClientAppBubbleType, UserFeatureAccessLevel } from '@t5s/shared/gql';
import { canAccessFeatureAccessLevel } from '@t5s/shared/util/feature-access-level';
import { filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

interface NavigationBarState {
  active?: NavigationBarItem;
  bubbles?: UserClientAppBubbleDto[];
}

@Component({
  selector: 't5s-navigation-bar',
  template: `
    <div [class]="containerClass">
      <!-- Home (hidden currently) -->
      <!-- <t5s-navigation-bar-button
        data-cy="t5s-mc-navigation-bar-home-btn"
        [icon]="iconHomeTabBarInactive"
        [iconActive]="iconHomeTabBarActive"
        [active]="homeActive$"
        (btnClick)="navigationBarItemClick.emit({ item: 'home' })"
        (btnLongpress)="navigationBarItemLongpress.emit({ item: 'home' })"
      >
      </t5s-navigation-bar-button> -->

      <!-- Search -->
      <t5s-navigation-bar-button
        data-cy="t5s-mc-navigation-bar-search-btn"
        [icon]="iconSearchTabBarInactive"
        [iconActive]="iconSearchTabBarActive"
        [active]="searchActive$"
        (btnClick)="navigationBarItemClick.emit({ item: 'search' })"
        (btnLongpress)="navigationBarItemLongpress.emit({ item: 'search' })"
      >
      </t5s-navigation-bar-button>

      <!-- Notifications -->
      <t5s-navigation-bar-button
        data-cy="t5s-mc-navigation-bar-notifications-btn"
        [icon]="iconNotificationsTabBarInactive"
        [iconActive]="iconNotificationsTabBarActive"
        [active]="notificationsActive$"
        [badgeAmount]="numUnreadNotifications$"
        (btnClick)="navigationBarItemClick.emit({ item: 'notifications' })"
        (btnLongpress)="navigationBarItemLongpress.emit({ item: 'notifications' })"
      >
      </t5s-navigation-bar-button>

      <!-- Chat -->
      <t5s-navigation-bar-button
        data-cy="t5s-mc-navigation-bar-chat-btn"
        [icon]="iconMessagesTabBarInactive"
        [iconActive]="iconMessagesTabBarActive"
        [active]="chatActive$"
        [badgeAmount]="numUnreadChatConversations$"
        (btnClick)="navigationBarItemClick.emit({ item: 'chat' })"
        (btnLongpress)="navigationBarItemLongpress.emit({ item: 'chat' })"
      >
      </t5s-navigation-bar-button>

      <!-- Active User -->
      <t5s-navigation-bar-button
        data-cy="t5s-mc-navigation-bar-active-user-btn"
        [icon]="iconUserTabBarInactive"
        [iconActive]="iconUserTabBarActive"
        [active]="activeUserActive$"
        (btnClick)="navigationBarItemClick.emit({ item: 'active-user' })"
        (btnLongpress)="navigationBarItemLongpress.emit({ item: 'active-user' })"
      >
      </t5s-navigation-bar-button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationBarComponent extends RxComponent<NavigationBarState> {
  readonly iconHomeTabBarInactive = iconHomeTabBarInactive;
  readonly iconHomeTabBarActive = iconHomeTabBarActive;

  readonly iconSearchTabBarInactive = iconSearchTabBarInactive;
  readonly iconSearchTabBarActive = iconSearchTabBarActive;

  readonly iconNotificationsTabBarInactive = iconNotificationsTabBarInactive;
  readonly iconNotificationsTabBarActive = iconNotificationsTabBarActive;

  readonly iconUserTabBarInactive = iconUserTabBarInactive;
  readonly iconUserTabBarActive = iconUserTabBarActive;

  readonly iconMessagesTabBarInactive = iconMessagesTabBarInactive;
  readonly iconMessagesTabBarActive = iconMessagesTabBarActive;

  constructor(private readonly featureAccessLevel$: RuntimeFeatureAccessLevelObservable) {
    super();
  }

  readonly showFeatureAccessLevelBeta$ = this.featureAccessLevel$.pipe(
    map((actualLevel) => canAccessFeatureAccessLevel(actualLevel, UserFeatureAccessLevel.BETA)),
  );

  readonly homeActive$ = this.select(selectSlice(['active'])).pipe(map(({ active }) => active === 'home'));
  readonly searchActive$ = this.select(selectSlice(['active'])).pipe(map(({ active }) => active === 'search'));
  readonly activeUserActive$ = this.select(selectSlice(['active'])).pipe(map(({ active }) => active === 'active-user'));
  readonly chatActive$ = this.select(selectSlice(['active'])).pipe(map(({ active }) => active === 'chat'));
  readonly notificationsActive$ = this.select(selectSlice(['active'])).pipe(
    map(({ active }) => active === 'notifications'),
  );

  readonly numUnreadNotifications$ = this.select(selectSlice(['bubbles'])).pipe(
    map(({ bubbles }) => (bubbles ?? []).find(({ type }) => type === UserClientAppBubbleType.NOTIFICATION)),
    filterOnlyPresent(),
    map(({ amount }) => amount),
    startWith(0),
  );

  readonly numUnreadChatConversations$ = this.select(selectSlice(['bubbles'])).pipe(
    map(({ bubbles }) => (bubbles ?? []).find(({ type }) => type === UserClientAppBubbleType.CHAT)),
    filterOnlyPresent(),
    map(({ amount }) => amount),
    startWith(0),
  );

  @Input() set active(active: NavigationBarItem | Observable<NavigationBarItem>) {
    this.setProperty('active', active);
  }

  @Input() set bubbles(bubbles: UserClientAppBubbleDto[] | Observable<UserClientAppBubbleDto[]>) {
    this.setProperty('bubbles', bubbles);
  }

  @Output() navigationBarItemClick = new EventEmitter<{ item: NavigationBarItem }>();
  @Output() navigationBarItemLongpress = new EventEmitter<{ item: NavigationBarItem }>();

  readonly containerClass = tss({
    ...flexRow,
    ...exactHeight(50),
    ...eachChild({
      flex: 1,
    }),
  });
}
