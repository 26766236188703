import { ReportActiveUserPresenceInput, Void } from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import { reportActiveUserPresence } from '../../generated/graphql-mutations';
import { GqlService } from '../../gql-service';

export class GqlActiveUserPresenceService extends GqlService {
  reportActiveUserPresence(input: ReportActiveUserPresenceInput = {}): Observable<Void> {
    return this.apollo.defaultMutate(reportActiveUserPresence(input));
  }
}
