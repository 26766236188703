import {
  AddBlabItemActivityReactionInput,
  BlabItemActivityDto,
  RemoveBlabItemActivityReactionInput,
} from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import { BlabItemActivityWithRepliesUnionTypeFragment } from '../../fragments/blab-item-activity';
import { addBlabItemActivityReaction, removeBlabItemActivityReaction } from '../../generated/graphql-mutations';
import { GqlService } from '../../gql-service';

export class GqlBlabItemActivityReactionService extends GqlService {
  addBlabItemActivityReaction(input: AddBlabItemActivityReactionInput): Observable<BlabItemActivityDto> {
    return this.apollo.defaultMutate(
      addBlabItemActivityReaction(input, [BlabItemActivityWithRepliesUnionTypeFragment]),
    );
  }

  removeBlabItemActivityReaction(input: RemoveBlabItemActivityReactionInput): Observable<BlabItemActivityDto> {
    return this.apollo.defaultMutate(
      removeBlabItemActivityReaction(input, [BlabItemActivityWithRepliesUnionTypeFragment]),
    );
  }
}
