import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EmojiPickerState } from './emoij-picker.state';
import { emojiPickerStateKey, preprocessStateForSerialization, selectIds } from './emoji-picker.reducer';

const selectEmojiPickerFeatureState = createFeatureSelector<EmojiPickerState>(emojiPickerStateKey);

export const selectEmojiPickerPosition = createSelector(selectEmojiPickerFeatureState, (state) => state.position);
export const selectEmojiPickerFrequentlyUsedEmojis = createSelector(
  selectEmojiPickerFeatureState,
  (state) => selectIds(state) as string[],
);

// Search
export const selectEmojiPickerSearchQuery = createSelector(selectEmojiPickerFeatureState, (state) => state.searchQuery);
export const selectEmojiPickerSearchMode = createSelector(selectEmojiPickerFeatureState, (state) => state.searchMode);
export const selectEmojiPickerSearchResults = createSelector(
  selectEmojiPickerFeatureState,
  (state) => state.searchResults,
);

// State hydration
export const selectEmojiPickerFeatureStateForPersistance = createSelector(
  selectEmojiPickerFeatureState,
  preprocessStateForSerialization,
);
