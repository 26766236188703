import gql from 'graphql-tag';
import { MultiCategoryFlimValDtoNoNestingFragment } from '../../../generated/graphql-fragments';

export const MultiCategoryFlimValFragment = gql`
  fragment MultiCategoryFlimVal on MultiCategoryFlimValDto {
    ...MultiCategoryFlimValDtoNoNesting
    categoryOptionDefIds
  }
  ${MultiCategoryFlimValDtoNoNestingFragment}
`;
