import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { px } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { defer, from } from 'rxjs';
import { switchMap } from 'rxjs/operators';

export interface ToastOptions {
  message: string;
  durationMs?: number;
  bgColor?: ThemeColorVar;
  fgColor?: ThemeColorVar;
}

@Injectable()
export class ToastService {
  constructor(private readonly toastController: ToastController) {}

  show(opts: ToastOptions) {
    return from(
      defer(() =>
        this.toastController.create({
          message: opts.message,
          duration: opts.durationMs ?? 700,
          animated: true,
          position: 'bottom',
          keyboardClose: false,
          mode: 'ios',
          translucent: false,
          buttons: [],
          cssClass: tss({
            top: px(0),
            '--background': opts.bgColor ?? ThemeColorVar.primary,
            '--color': opts.fgColor ?? ThemeColorVar.lightest,
            '--border-radius': px(10),
            '--height': px(42),
          }),
        }),
      ),
    ).pipe(switchMap((toast) => toast.present()));
  }
}
