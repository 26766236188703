import gql from 'graphql-tag';
import { WorkspaceShareSettingsDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { WorkspaceMembershipUnionTypeFragment } from '../workspace-membership';

export const WorkspaceShareSettingsDtoFragment = gql`
  fragment WorkspaceShareSettingsDtoFragment on WorkspaceShareSettingsDto {
    ...WorkspaceShareSettingsDtoNoNesting
    workspaceMembers {
      ...WorkspaceMembershipUnionTypeFragment
    }
  }
  ${WorkspaceShareSettingsDtoNoNestingFragment}
  ${WorkspaceMembershipUnionTypeFragment}
`;
