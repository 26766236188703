import { ApiService } from '@t5s/shared/service/api';
import {
  UploadCustomEntityIconInputDto,
  UploadCustomEntityIconResultDto,
  UploadCustomEntityIconUploadFieldName,
} from '@t5s/shared/value-object/api-entity-icon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class EntityIconApiService extends ApiService {
  /**
   * Upload a custom entity icon. The upload can be specified via a file input or a data URL.
   * @param input The input data. Contains an optional URL to download the file from. If not specified, the file input is used.
   */
  uploadCustomEntityIcon(input: UploadCustomEntityIconInputDto | File): Observable<UploadCustomEntityIconResultDto> {
    if (input instanceof File) {
      const formData = new FormData();
      formData.append(UploadCustomEntityIconUploadFieldName, input);
      return this.http
        .upload<UploadCustomEntityIconResultDto>({
          path: 'uploadCustomEntityIcon',
          data: formData,
        })
        .pipe(map(({ data, uploadProgress }) => data ?? { uploadProgress }));
    } else {
      return this.http
        .post<UploadCustomEntityIconInputDto, UploadCustomEntityIconResultDto>({
          path: 'uploadCustomEntityIcon',
          data: input,
        })
        .pipe(map(({ data }) => data));
    }
  }
}
