import {
  ChatDialogDto,
  ChatGroupDto,
  ConversationDto,
  ConversationDtoConnection,
  ConversationSearchResultUnionType,
  GetConversationInput,
  GetConversationsInput,
  MarkConversationAsReadInput,
  MuteConversationInput,
  UnmuteConversationInput,
  Void,
} from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import {
  ChatConversationConnectionFragment,
  ChatConversationFragment,
  ChatGroupSearchResultFragment,
} from '../../fragments/chat';
import { UserSearchResultFragment } from '../../fragments/global-search';
import { markConversationAsRead, muteConversation, unmuteConversation } from '../../generated/graphql-mutations';
import { getConversation, getConversations, searchExistingAndNewConversations } from '../../generated/graphql-queries';
import { subscribeToUserConversationPreviews } from '../../generated/graphql-subscriptions';
import { GqlService } from '../../gql-service';

export class GqlChatConversationService extends GqlService {
  getConversation(input: GetConversationInput): Observable<ChatDialogDto | ChatGroupDto> {
    return this.apollo.defaultQuery(getConversation(input, [ChatConversationFragment]));
  }

  getConversationConnection(
    first: number,
    input?: GetConversationsInput,
    after?: string,
  ): Observable<ConversationDtoConnection> {
    return this.apollo.defaultQuery(getConversations(first, input, after, [ChatConversationConnectionFragment]));
  }

  searchExistingAndNewConversations(query: string): Observable<ConversationSearchResultUnionType[]> {
    return this.apollo.defaultQuery(
      searchExistingAndNewConversations({ query }, [UserSearchResultFragment, ChatGroupSearchResultFragment]),
    );
  }

  subscribeToUserConversationPreviews(): Observable<ConversationDto> {
    return this.apollo.defaultSubscribe(subscribeToUserConversationPreviews([ChatConversationFragment]));
  }

  markConversationAsRead(input: MarkConversationAsReadInput): Observable<Void> {
    return this.apollo.defaultMutate(markConversationAsRead(input));
  }

  muteConversation(input: MuteConversationInput): Observable<Void> {
    return this.apollo.defaultMutate(muteConversation(input));
  }

  unmuteConversation(input: UnmuteConversationInput): Observable<Void> {
    return this.apollo.defaultMutate(unmuteConversation(input));
  }
}
