import { ChangeDetectionStrategy, Component } from '@angular/core';
import { IonNav } from '@ionic/angular';
import { Store } from '@ngrx/store';
import {
  DebugConsoleActions,
  selectDebugConsoleSelectedStorageKey,
  selectDebugConsoleSelectedStorageKeyValue,
} from '@t5s/mobile-client/business-logic/debug-console';
import { PushablePage } from '@t5s/mobile-client/page/common';

@Component({
  selector: 't5s-debug-console-storage-key-details-page',
  template: `
    <ion-content [scrollY]="false">
      <t5s-debug-console-storage-key-details-view
        [key]="key$"
        [value]="value$"
        (backClick)="backClick()"
        (deleteClick)="deleteClick($event)"
      ></t5s-debug-console-storage-key-details-view>
    </ion-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DebugConsoleStorageKeyDetailsPageComponent extends PushablePage {
  readonly key$ = this.store$.select(selectDebugConsoleSelectedStorageKey);
  readonly value$ = this.store$.select(selectDebugConsoleSelectedStorageKeyValue);

  constructor(private readonly store$: Store, readonly ionNav: IonNav) {
    super();
  }

  backClick() {
    void this.popPage();
  }

  deleteClick({ key }: { key: string }) {
    this.store$.dispatch(DebugConsoleActions.deleteStorageKey({ key }));
    void this.popPage();
  }
}
