import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { isThisYear } from '@t5s/shared/util/date';
import { formatDateViaLanguage, getDateTimeFormats, isApplicableI18n, normalizeDate } from '../common';

export function formatBadgeDateOmitYear(i18n: SharedI18n, date?: string | number | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  // provide defaults, as time and date format do not matter here
  const { language } = i18n;
  const _i18n = { language };

  const dateTimeFormats = getDateTimeFormats(i18n);

  let dateFormat: string;
  if (isThisYear(date)) {
    dateFormat = dateTimeFormats.date.badgeOmitYear;
  } else {
    dateFormat = dateTimeFormats.date.badge;
  }

  return formatDateViaLanguage(date, dateFormat, _i18n);
}
