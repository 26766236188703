import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { formatTime, getDateTimeFormats, isApplicableI18n, normalizeDate } from '../common';

export function formatNormalizedTime(i18n: SharedI18n, date?: string | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  const dateFormats = getDateTimeFormats(i18n);

  const format = dateFormats.time.normalized;

  return formatTime(date, format, i18n);
}
