import gql from 'graphql-tag';
import {
  ActiveUserProfileDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const ActiveUserProfileFragment = gql`
  fragment ActiveUserProfileFragment on ActiveUserProfileDto {
    ...ActiveUserProfileDtoNoNesting
    profilePicture {
      ...ThumbnailDtoNoNesting
    }
  }
  ${ActiveUserProfileDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
