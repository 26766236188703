import gql from 'graphql-tag';

export const FlimStatDefFragment = gql`
  fragment FlimStatDefFragment on FlimStatDefDto {
    id
    blabViewId
    flimDefId
    type
    ... on SingleTextFlimStatDefDto {
      singleTextFlimStatType
      flimDefId
    }
    ... on MultiTextFlimStatDefDto {
      multiTextFlimStatType
      flimDefId
    }

    ... on SingleCategoryFlimStatDefDto {
      singleCategoryFlimStatType
      flimDefId
    }
    ... on MultiCategoryFlimStatDefDto {
      multiCategoryFlimStatType
      flimDefId
    }

    ... on SingleUserFlimStatDefDto {
      singleUserFlimStatType
      flimDefId
    }
    ... on MultiUserFlimStatDefDto {
      multiUserFlimStatType
      flimDefId
    }
    ... on SingleAttachmentFlimStatDefDto {
      singleAttachmentFlimStatType
      flimDefId
    }
    ... on MultiAttachmentFlimStatDefDto {
      multiAttachmentFlimStatType
      flimDefId
    }

    ... on SingleRelationFlimStatDefDto {
      singleRelationFlimStatType
      flimDefId
    }
    ... on MultiRelationFlimStatDefDto {
      multiRelationFlimStatType
      flimDefId
    }

    ... on SingleDateFlimStatDefDto {
      singleDateFlimStatType
      flimDefId
    }
    ... on RangeDateFlimStatDefDto {
      rangeDateFlimStatType
      flimDefId
    }
    ... on MultiLinkFlimStatDefDto {
      multiLinkFlimStatType
      flimDefId
    }
    ... on ChecklistFlimStatDefDto {
      checklistFlimStatType
      flimDefId
    }
    ... on CreatedAtFlimStatDefDto {
      createdAtFlimStatType
      flimDefId
    }
    ... on CreatedByFlimStatDefDto {
      createdByFlimStatType
      flimDefId
    }
    ... on LastModifiedAtFlimStatDefDto {
      lastModifiedAtFlimStatType
      flimDefId
    }
    ... on LastModifiedByFlimStatDefDto {
      lastModifiedByFlimStatType
      flimDefId
    }
    ... on NumberFlimStatDefDto {
      numberFlimStatType
      flimDefId
    }
    ... on StatusFlimStatDefDto {
      statusFlimStatType
      flimDefId
    }
    ... on CalculationFlimStatDefDto {
      calculationFlimStatType
      flimDefId
    }
    ... on MultiEmailFlimStatDefDto {
      multiEmailFlimStatType
      flimDefId
    }
    ... on MultiPhoneFlimStatDefDto {
      multiPhoneFlimStatType
      flimDefId
    }
    ... on MultiImageFlimStatDefDto {
      multiImageFlimStatType
      flimDefId
    }
    ... on SingleLocationFlimStatDefDto {
      singleLocationFlimStatType
      flimDefId
    }
    ... on UniqueIdFlimStatDefDto {
      uniqueIdFlimStatType
      flimDefId
    }
  }
`;
