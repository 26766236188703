import { ActivatedRouteSnapshot, CanActivate, CanDeactivate } from '@angular/router';
import { isObservable, Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

export abstract class NavigationSideEffect implements CanActivate, CanDeactivate<any> {
  protected onEnter(params: ActivatedRouteSnapshot): void | Observable<void> {}

  protected onLeave(): void | Observable<void> {}

  /**
   * DO NOT OVERRIDE
   */
  canActivate(params: ActivatedRouteSnapshot) {
    const onEnter = this.onEnter(params);
    if (isObservable(onEnter)) {
      return onEnter.pipe(mapTo(true));
    }

    return true;
  }

  /**
   * DO NOT OVERRIDE
   */
  canDeactivate() {
    const onLeave = this.onLeave();
    if (isObservable(onLeave)) {
      return onLeave.pipe(mapTo(true));
    }

    return true;
  }
}
