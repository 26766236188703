import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { isThisYear, isToday, isYesterday, withinTheLastNDays } from '@t5s/shared/util/date';
import { formatDateViaLanguage, getDateTimeFormats, isApplicableI18n, normalizeDate } from '../common';
import { DatetimeI18n } from '../../../datetime.i18n';

export function formatChatDateHeadline(i18n: SharedI18n, date?: string | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  // provide defaults, as time and date format do not matter here
  const { language } = i18n;
  const _i18n = { language };

  const dateTimeFormats = getDateTimeFormats(i18n);

  let dateFormat: string;

  // check whether it's today
  if (isToday(date)) {
    return DatetimeI18n.translate(i18n, DatetimeI18n.key.today);
  }

  // check whether it's yesterday
  if (isYesterday(date)) {
    return DatetimeI18n.translate(i18n, DatetimeI18n.key.yesterday);
  }

  // check whether this date is within the last seven days
  if (withinTheLastNDays(7, date)) {
    return formatDateViaLanguage(date, dateTimeFormats.date.weekday, _i18n);
  }

  if (isThisYear(date)) {
    dateFormat = dateTimeFormats.date.badgeOmitYear;
  } else {
    dateFormat = dateTimeFormats.date.fullNonAbbr;
  }

  return formatDateViaLanguage(date, dateFormat, _i18n);
}
