import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ServiceFileOpenerModule } from '@t5s/mobile-client/service/file-opener';
import { FileAttachmentPreviewEffects } from './file-attachment-preview.effects';
import { fileAttachmentPreviewReducer, fileAttachmentPreviewStateKey } from './file-attachment-preview.reducer';

@NgModule({
  imports: [
    ServiceFileOpenerModule,
    StoreModule.forFeature(fileAttachmentPreviewStateKey, fileAttachmentPreviewReducer),
    EffectsModule.forFeature([FileAttachmentPreviewEffects]),
  ],
})
export class FileAttachmentPreviewBusinessLogicModule {}
