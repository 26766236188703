import { NgModule } from '@angular/core';
import { ActiveUserIdObservable } from '@t5s/mobile-client/provider-token/active-user';
import {
  LoggedInSubscriptionEndTriggerObservable,
  LoggedInSubscriptionStartTriggerObservable,
} from '@t5s/mobile-client/provider-token/logged-in-subscription-trigger';
import {
  SubscriptionEndTriggerObservable,
  SubscriptionStartTriggerObservable,
} from '@t5s/mobile-client/provider-token/subscription-trigger';
import { filterOnlyNullish, filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { merge } from 'rxjs';
import { filter, mapTo, withLatestFrom } from 'rxjs/operators';

@NgModule({
  providers: [
    {
      provide: LoggedInSubscriptionStartTriggerObservable,
      useFactory: (
        start$: SubscriptionStartTriggerObservable,
        activeUserId$: ActiveUserIdObservable,
      ): LoggedInSubscriptionStartTriggerObservable => {
        const subscriptionStart$ = start$.pipe(
          withLatestFrom(activeUserId$),
          filter(([_, activeUserId]) => activeUserId !== undefined),
          mapTo(true),
        );

        return merge(subscriptionStart$, activeUserId$.pipe(filterOnlyPresent())).pipe(mapTo(true));
      },
      deps: [SubscriptionStartTriggerObservable, ActiveUserIdObservable],
    },
    {
      provide: LoggedInSubscriptionEndTriggerObservable,
      useFactory: (
        end$: SubscriptionEndTriggerObservable,
        activeUserId$: ActiveUserIdObservable,
      ): LoggedInSubscriptionEndTriggerObservable =>
        merge(end$, activeUserId$.pipe(filterOnlyNullish())).pipe(mapTo(false)),
      deps: [SubscriptionEndTriggerObservable, ActiveUserIdObservable],
    },
  ],
})
export class LoggedInSubscriptionTriggerProviderModule {}
