import gql from 'graphql-tag';
import { AudioRoomPreviewDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { AudioRoomAuthorFragment } from './audio-room-author.fragment';
import { AudioRoomParticipantDtoFragment } from './audio-room-participant.dto.fragment';

export const AudioRoomPreviewDtoFragment = gql`
  fragment AudioRoomPreviewDtoFragment on AudioRoomPreviewDto {
    ...AudioRoomPreviewDtoNoNesting
    participants {
      ...AudioRoomParticipantDtoFragment
    }
    author {
      ...AudioRoomAuthorFragment
    }
  }
  ${AudioRoomPreviewDtoNoNestingFragment}
  ${AudioRoomParticipantDtoFragment}
  ${AudioRoomAuthorFragment}
`;
