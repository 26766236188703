import gql from 'graphql-tag';
import {
  FlimValAttachmentDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const FlimValAttachmentFragment = gql`
  fragment FlimValAttachmentFragment on FlimValAttachmentDto {
    ...FlimValAttachmentDtoNoNesting
    author {
      id
      primaryName
    }
    thumbnail {
      ...ThumbnailDtoNoNesting
    }
  }
  ${FlimValAttachmentDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
