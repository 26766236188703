import gql from 'graphql-tag';
import {
  StatusFlimDefDtoNoNestingFragment,
  StatusOptionDefDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const StatusFlimDefDtoFragment = gql`
  fragment StatusFlimDefDtoFragment on StatusFlimDefDto {
    ...StatusFlimDefDtoNoNesting
    statusOptionDefs {
      ...StatusOptionDefDtoNoNesting
    }
  }
  ${StatusFlimDefDtoNoNestingFragment}
  ${StatusOptionDefDtoNoNestingFragment}
`;
