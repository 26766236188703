import gql from 'graphql-tag';
import {
  MultiUserFlimValDtoNoNestingFragment,
  ThumbnailDtoNoNestingFragment,
  UserReferenceDtoNoNestingFragment,
} from '../../../../generated/graphql-fragments';

export const MultiUserFlimValFragment = gql`
  fragment BlabItemsOverviewMultiUserFlimVal on MultiUserFlimValDto {
    ...MultiUserFlimValDtoNoNesting
    userReferences {
      ...UserReferenceDtoNoNesting
      user {
        id
        email
        primaryName
        jobDescription
        profilePicture {
          ...ThumbnailDtoNoNesting
        }
      }
    }
  }
  ${MultiUserFlimValDtoNoNestingFragment}
  ${UserReferenceDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
