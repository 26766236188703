import gql from 'graphql-tag';
import { BlabDefInvitationCreatedActivityFragment } from './blab-def-invitation-created-activity.fragment';

export const NotificationBlabDefActivityUnionTypeFragment = gql`
  fragment NotificationBlabDefActivityUnionTypeFragment on BlabDefActivityUnionType {
    ... on BlabDefInvitationCreatedActivityDto {
      ...BlabDefInvitationCreatedActivityFragment
    }
  }
  ${BlabDefInvitationCreatedActivityFragment}
`;
