/* eslint-disable @typescript-eslint/no-floating-promises */
import { Injectable, OnDestroy } from '@angular/core';
import {
  ActionPerformed,
  DeliveredNotifications,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { PlatformObservable } from '@t5s/mobile-client/provider-token/device';
import { defer, from, ReplaySubject, Subject } from 'rxjs';
import { filter, share, shareReplay, switchMap } from 'rxjs/operators';
@Injectable()
export class PushNotificationService implements OnDestroy {
  private readonly registration$$ = new ReplaySubject<Token>(1);
  private readonly registrationError$$ = new Subject<unknown>();

  private readonly pushNotificationReceived$$ = new Subject<PushNotificationSchema>();
  private readonly remotePushNotificationReceived$$ = new Subject<undefined>();
  private readonly pushNotificationActionPerformed$$ = new Subject<ActionPerformed>();

  readonly registration$ = this.registration$$.asObservable().pipe(share());
  readonly registrationError$ = this.registrationError$$.asObservable().pipe(share());

  readonly pushNotificationReceived$ = this.pushNotificationReceived$$.asObservable().pipe(share());
  readonly remotePushNotificationReceived$ = this.remotePushNotificationReceived$$.asObservable().pipe(share());
  readonly pushNotificationActionPerformed$ = this.pushNotificationActionPerformed$$.asObservable().pipe(share());

  readonly isSupportedPlatform$ = this.platform$.pipe(
    filter(({ platform }) => platform === 'android' || platform === 'ios'),
    shareReplay(1),
  );

  readonly pushNotifciationPluginReady$ = this.isSupportedPlatform$.pipe(
    switchMap(() => this.registration$$.asObservable()),
  );

  constructor(private readonly platform$: PlatformObservable) {
    this.isSupportedPlatform$.subscribe(() => {
      PushNotifications.addListener('registration', (token) => this.registration$$.next(token));
      PushNotifications.addListener('registrationError', (error) => this.registrationError$$.next(error));

      PushNotifications.addListener('pushNotificationReceived', (pushNotification) =>
        this.pushNotificationReceived$$.next(pushNotification),
      );
      PushNotifications.addListener('pushNotificationActionPerformed', (actionPerformed) =>
        this.pushNotificationActionPerformed$$.next(actionPerformed),
      );

      // this event is dispatched by out native iOS app, it will only be available on iOS. DO not rename this.
      window.addEventListener('remoteNotificationReceived', () => {
        this.remotePushNotificationReceived$$.next(undefined);
      });
    });
  }

  checkPermissions() {
    return this.isSupportedPlatform$.pipe(switchMap(() => defer(() => from(PushNotifications.checkPermissions()))));
  }

  requestPermissions() {
    return this.isSupportedPlatform$.pipe(switchMap(() => defer(() => from(PushNotifications.requestPermissions()))));
  }

  register() {
    return this.isSupportedPlatform$.pipe(switchMap(() => defer(() => from(PushNotifications.register()))));
  }

  getDeliveredNotifications() {
    return this.pushNotifciationPluginReady$.pipe(
      switchMap(() => defer(() => from(PushNotifications.getDeliveredNotifications()))),
    );
  }

  removeDeliveredNotifications(deliveredNotifications: DeliveredNotifications) {
    return this.pushNotifciationPluginReady$.pipe(
      switchMap(() => defer(() => from(PushNotifications.removeDeliveredNotifications(deliveredNotifications)))),
    );
  }

  removeAllDeliveredNotifications() {
    return this.pushNotifciationPluginReady$.pipe(
      switchMap(() => defer(() => from(PushNotifications.removeAllDeliveredNotifications()))),
    );
  }

  ngOnDestroy() {
    return PushNotifications.removeAllListeners();
  }
}
