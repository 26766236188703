import { createReducer, on } from '@ngrx/store';
import { PushNotificationSchema } from '@t5s/mobile-client/value-object/push-notification';
import { PushNotificationActions } from './push-notification.actions';

export const pushNotificationStateKey = 'pushNotification';
export interface PushNotificationState {
  fcmToken?: string;
  notifications: PushNotificationSchema[];
}

const initialState: PushNotificationState = {
  notifications: [],
};

export const pushNotificationReducer = createReducer(
  initialState,
  on(PushNotificationActions.reset, () => ({ ...initialState })),
  on(PushNotificationActions.registerSuccess, (state, { fcmToken }) => ({ ...state, fcmToken })),
  on(PushNotificationActions.pushNotificationReceived, ({ notifications, ...state }, { notification }) => {
    return {
      ...state,
      notifications: notifications.concat(notification),
    };
  }),
);
