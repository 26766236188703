import gql from 'graphql-tag';
import {
  ActiveUserStatusSplitBySectionDtoNoNestingFragment,
  StatusOptionDefDtoNoNestingFragment,
} from '../../../../generated/graphql-fragments';

export const ActiveUserStatusSplitBySectionDtoFragment = gql`
  fragment ActiveUserStatusSplitBySectionDto on ActiveUserStatusSplitBySectionDto {
    ...ActiveUserStatusSplitBySectionDtoNoNesting
    statusOptionDef {
      ...StatusOptionDefDtoNoNesting
    }
  }
  ${ActiveUserStatusSplitBySectionDtoNoNestingFragment}
  ${StatusOptionDefDtoNoNestingFragment}
`;
