import gql from 'graphql-tag';

export const ActiveUserDevPortalContextFragment = gql`
  fragment ActiveUserDevPortalContextFragment on ActiveUserDevPortalContextDto {
    userId
    apiKey
    user {
      id
      primaryName
    }
  }
`;
