import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OutsideClickDirective } from './outside-click.directive';

const directives = [OutsideClickDirective];

@NgModule({
  imports: [CommonModule],
  declarations: directives,
  exports: directives,
})
export class OutsideClickDirectiveModule {}
