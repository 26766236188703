export function trimHtmlString(html: string | null | undefined): string {
  if (!html) {
    return '';
  }

  return html
    .trim()
    .replace(/^((\<p\>((\<br\>)|\s)*\<\/p\>)|\s)+/g, '') // Trim start
    .replace(/((\<p\>((\<br\>)|\s)*\<\/p\>)|\s)+$/g, '') // Trim end
    .trim();
}
