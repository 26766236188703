import { NgModule } from '@angular/core';
import { PageCommonModule } from '@t5s/mobile-client/page/common';
import { DebugConsoleViewModule } from '@t5s/mobile-client/ui/view/debug-console';
import { DebugConsoleBusinessLogicModule } from '@t5s/mobile-client/business-logic/debug-console';
import { DebugConsolePageComponent } from './debug-console-page.component';
import { DebugConsoleRootPageComponent } from './debug-console-page-root.component';
import { DebugConsoleStorageKeyDetailsPageComponent } from './debug-console-storage-key-details-page.component';

@NgModule({
  imports: [PageCommonModule, DebugConsoleBusinessLogicModule, DebugConsoleViewModule],
  declarations: [DebugConsolePageComponent, DebugConsoleRootPageComponent, DebugConsoleStorageKeyDetailsPageComponent],
})
export class DebugConsolePageModule {}
