import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ApplicationUrlService } from '@t5s/mobile-client/service/application-url';
import { tap } from 'rxjs/operators';
import { ApplicationUrlActions } from './application-url.actions';

@Injectable()
export class ApplicationUrlEffects {
  constructor(private readonly actions$: Actions, private readonly applicationUrlService: ApplicationUrlService) {}

  readonly EffectName$ = createEffect(() => this.actions$.pipe(ofType(ApplicationUrlActions.reset)), {
    dispatch: false,
  });

  readonly appUrlOpen$ = createEffect(
    // eslint-disable-next-line no-console
    () => this.applicationUrlService.appUrlOpen$.pipe(tap(({ url }) => console.log(url))),
    {
      dispatch: false,
    },
  );
}
