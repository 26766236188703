import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { border, exactSize, flexCenter, rounded } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { Icon } from '@t5s/mobile-client/asset';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface IconToggleButtonState {
  icon: Icon;
  iconActive?: Icon;
  iconSize: number;
  btnSize: number;

  active: boolean;
}

@Component({
  selector: 't5s-icon-toggle-button',
  template: `
    <ng-container *ngIf="state$ | push as state">
      <button
        [class]="btnClass$ | push"
        [t5sTouchActive]="state.active ? 'opacity: 0.9' : 'background: ' + Color.primary20"
        (t5sPressDisableLongpress)="btnClick.emit({ active: !state.active })"
      >
        <!-- Icon -->
        <t5s-icon
          [size]="state.iconSize"
          [icon]="state.active ? state.iconActive || state.icon : state.icon"
          [fgColor]="state.active ? Color.lightest : Color.primary"
        ></t5s-icon>
      </button>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconToggleButtonComponent extends RxComponent<IconToggleButtonState> {
  @Input() set icon(icon: Icon | Observable<Icon>) {
    this.setProperty('icon', icon);
  }

  @Input() set iconActive(iconActive: Icon | Observable<Icon>) {
    this.setProperty('iconActive', iconActive);
  }

  @Input() set iconSize(iconSize: number | Observable<number>) {
    this.setProperty('iconSize', iconSize);
  }

  @Input() set btnSize(btnSize: number | Observable<number>) {
    this.setProperty('btnSize', btnSize);
  }

  @Input() set active(active: boolean | Observable<boolean>) {
    this.setProperty('active', active);
  }

  @Output() btnClick = new EventEmitter<{ active: boolean }>();

  readonly btnClass$ = this.select(
    selectSlice(['btnSize', 'active']),
    map(({ btnSize, active }) =>
      tss({
        ...exactSize(btnSize),
        ...rounded,
        border: border(1, 'solid', ThemeColorVar.primary),
        backgroundColor: active ? ThemeColorVar.primary : ThemeColorVar.lightest,
        ...flexCenter,
      }),
    ),
  );
}
