import { createAction, props } from '@ngrx/store';
import { ActiveUserSessionState } from './active-user-session.reducer';

const PREFIX = '[mobile-client-active-user-session]';

export const ActiveUserSessionHydrationActions = {
  hydrateState: createAction(`${PREFIX} Hydrate state`),
  hydrateStateSuccess: createAction(`${PREFIX} Hydrate state Success`, props<{ state: ActiveUserSessionState }>()),
  hydrateStateNotAvailable: createAction(`${PREFIX} Hydration of state not available`),
  hydrateStateException: createAction(`${PREFIX} Hydration of state failed with exception`),
};
