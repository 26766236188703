import gql from 'graphql-tag';
import {
  SubscribeToUpdateBlabItemsFlimValSuccessEventNoNestingFragment,
  SubscribeToUpdateBlabItemsFlimValFailureEventNoNestingFragment,
} from '../../generated/graphql-fragments';

export const BlabItemsFlimValUpdateSubscriptionEventUnionTypeFragment = gql`
  fragment BlabItemsFlimValUpdateSubscriptionEventUnionTypeFragment on SubscribeToUpdateBlabItemsFlimValJobResult {
    ... on SubscribeToUpdateBlabItemsFlimValSuccessEvent {
      ...SubscribeToUpdateBlabItemsFlimValSuccessEventNoNesting
    }
    ... on SubscribeToUpdateBlabItemsFlimValFailureEvent {
      ...SubscribeToUpdateBlabItemsFlimValFailureEventNoNesting
    }
  }
  ${SubscribeToUpdateBlabItemsFlimValSuccessEventNoNestingFragment}
  ${SubscribeToUpdateBlabItemsFlimValFailureEventNoNestingFragment}
`;
