import gql from 'graphql-tag';
import { ImportBlabDefDataToNewBlabDefResultDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { BlabDefPreviewDtoFragment } from '../blab-def/blab-def-preview.fragment';

export const ImportBlabDefDataToNewBlabDefResultDtoFragment = gql`
  fragment ImportBlabDefDataToNewBlabDefResultDto on ImportBlabDefDataToNewBlabDefResultDto {
    ...ImportBlabDefDataToNewBlabDefResultDtoNoNesting
    blabDef {
      ...BlabDefPreviewDtoFragment
    }
  }

  ${ImportBlabDefDataToNewBlabDefResultDtoNoNestingFragment}
  ${BlabDefPreviewDtoFragment}
`;
