import { isGqlClientErrorWithCode } from '@t5s/shared/gql-services';

const isGqlClientErrorBlabItemNoPermissionException = isGqlClientErrorWithCode('BlabItemNoPermissionException');
const isGqlClientErrorWorkspaceNoPermissionException = isGqlClientErrorWithCode('WorkspaceNoPermissionException');
const isGqlClientErrorBlabItemDeletedException = isGqlClientErrorWithCode('BlabItemDeletedException');
const isGqlClientErrorBlabDefDeletedException = isGqlClientErrorWithCode('BlabDefDeletedException');

export function isGqlClientErrorItemUnavailable(error: any) {
  return (
    isGqlClientErrorBlabItemNoPermissionException(error) ||
    isGqlClientErrorWorkspaceNoPermissionException(error) ||
    isGqlClientErrorBlabItemDeletedException(error) ||
    isGqlClientErrorBlabDefDeletedException(error)
  );
}
