import { createReducer, on } from '@ngrx/store';
import { NavigationBarItem } from '@t5s/mobile-client/value-object/logged-in-root';
import { UserClientAppBubbleDto } from '@t5s/shared/gql';
import { LoggedInRootActions } from './logged-in-root.actions';

export const loggedInRootStateKey = 'loggedInRoot';
export interface LoggedInRootState {
  active: NavigationBarItem;
  hydratedActive?: NavigationBarItem | null;
  bubbles: UserClientAppBubbleDto[];
}

const initialState: LoggedInRootState = {
  active: 'none',
  bubbles: [],
};

export const loggedInRootReducer = createReducer(
  initialState,
  on(LoggedInRootActions.reset, () => ({ ...initialState })),
  on(LoggedInRootActions.setActiveOutlet, (state, { active }) => ({ ...state, active })),

  on(LoggedInRootActions.hydrateActiveTabSuccess, (state, { hydratedActive }) => ({ ...state, hydratedActive })),
  on(LoggedInRootActions.hydrateActiveTabNotAvailable, LoggedInRootActions.hydrateActiveTabException, (state) => ({
    ...state,
    hydratedActive: null,
  })),

  // Bubbles
  on(LoggedInRootActions.setClientAppBubblesSuccess, (state, { bubbles }) => ({ ...state, bubbles })),
  on(LoggedInRootActions.setClientAppBubbleSuccess, (state, { bubble }) => ({
    ...state,
    bubbles: [bubble, ...state.bubbles],
  })),
);
