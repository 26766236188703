import { Injectable } from '@angular/core';
import {
  AppendFileOptions,
  CopyOptions,
  CopyResult,
  DeleteFileOptions,
  Filesystem,
  GetUriOptions,
  GetUriResult,
  MkdirOptions,
  PermissionStatus,
  ReadFileOptions,
  ReadFileResult,
  ReaddirOptions,
  ReaddirResult,
  RenameOptions,
  RmdirOptions,
  StatOptions,
  StatResult,
  WriteFileOptions,
  WriteFileResult,
} from '@capacitor/filesystem';
import { Observable, defer, from } from 'rxjs';

@Injectable()
export class FilesystemService {
  readFile(options: ReadFileOptions): Observable<ReadFileResult> {
    return defer(() => from(Filesystem.readFile(options)));
  }

  writeFile(options: WriteFileOptions): Observable<WriteFileResult> {
    return defer(() => from(Filesystem.writeFile(options)));
  }

  appendFile(options: AppendFileOptions): Observable<void> {
    return defer(() => from(Filesystem.appendFile(options)));
  }

  deleteFile(options: DeleteFileOptions): Observable<void> {
    return defer(() => from(Filesystem.deleteFile(options)));
  }

  mkdir(options: MkdirOptions): Observable<void> {
    return defer(() => from(Filesystem.mkdir(options)));
  }

  rmdir(options: RmdirOptions): Observable<void> {
    return defer(() => from(Filesystem.rmdir(options)));
  }

  readdir(options: ReaddirOptions): Observable<ReaddirResult> {
    return defer(() => from(Filesystem.readdir(options)));
  }

  getUri(options: GetUriOptions): Observable<GetUriResult> {
    return defer(() => from(Filesystem.getUri(options)));
  }

  stat(options: StatOptions): Observable<StatResult> {
    return defer(() => from(Filesystem.stat(options)));
  }

  rename(options: RenameOptions): Observable<void> {
    return defer(() => from(Filesystem.rename(options)));
  }

  copy(options: CopyOptions): Observable<CopyResult> {
    return defer(() => from(Filesystem.copy(options)));
  }

  checkPermissions(): Observable<PermissionStatus> {
    return defer(() => from(Filesystem.checkPermissions()));
  }

  requestPermissions(): Observable<PermissionStatus> {
    return defer(() => from(Filesystem.requestPermissions()));
  }
}
