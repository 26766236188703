import { UserSessionDto, RemoveUserSessionOptionsInput } from '@t5s/shared/gql';
import { Observable } from 'rxjs';
import { UserSessionFragment } from '../../fragments/user-session';
import { removeUserSession } from '../../generated/graphql-mutations';
import { getUserSessions } from '../../generated/graphql-queries';
import { GqlService } from '../../gql-service';

export class GqlSessionService extends GqlService {
  /** Performs a lightweight and faster getUserSessions query w/o nested properties. */
  checkUserSessions(): Observable<UserSessionDto[]> {
    return this.apollo.defaultQuery(getUserSessions());
  }

  getUserSessions(): Observable<UserSessionDto[]> {
    return this.apollo.defaultQuery(getUserSessions([UserSessionFragment]));
  }

  removeUserSession(userId: number, input?: RemoveUserSessionOptionsInput): Observable<UserSessionDto[]> {
    return this.apollo.defaultMutate(removeUserSession(userId, input));
  }
}
