import { I18nTranslation } from '@t5s/mobile-client/i18n/common';
import { Language } from '@t5s/shared/gql';

const languages = {
  [Language.DE]: {
    header: {
      title: 'Thread',
    },
    reply: 'Antwort',
    replyInput: {
      placeholder: 'Antwort schreiben',
      sendBtnLabel: 'Senden',
    },
    activityReplyOptionsBottomSheet: {
      cancelBtnLabel: 'Abbrechen',
      copyTextBtnLabel: 'Text kopieren',
      deleteReplyBtnLabel: 'Antwort löschen',
      replyInThreadBtnLabel: 'In Thread antworten ',
    },
    confirmDeleteActivityReplyDialog: {
      title: 'Antwort löschen?',
      message: 'Dies kann nicht rückgängig gemacht werden.',
      cancelButtonTitle: 'Abbrechen',
      okButtonTitle: 'Löschen',
    },
    confirmDeleteBlabItemCommentDialog: {
      title: 'Kommentar löschen?',
      message:
        'Dies kann nicht rückgängig gemacht werden und Antworten auf diesen Kommentar werden ebenfalls entfernt.',
      cancelButtonTitle: 'Abbrechen',
      okButtonTitle: 'Löschen',
    },
    networkExceptionPlaceholder: {
      title: 'Thread kann im Moment nicht geladen werden',
      subtitle: 'Bitte versuche es später erneut.',
    },
  },
  [Language.EN]: {
    header: {
      title: 'Thread',
    },
    reply: 'Reply',
    replyInput: {
      placeholder: 'Start a reply',
      sendBtnLabel: 'Send',
    },
    activityReplyOptionsBottomSheet: {
      cancelBtnLabel: 'Cancel',
      copyTextBtnLabel: 'Copy Text',
      deleteReplyBtnLabel: 'Delete Reply',
      replyInThreadBtnLabel: 'Reply in Thread',
    },
    confirmDeleteActivityReplyDialog: {
      title: 'Delete reply?',
      message: 'Are you sure you want to delete this reply? This can’t be undone.',
      cancelButtonTitle: 'Cancel',
      okButtonTitle: 'Delete',
    },
    confirmDeleteBlabItemCommentDialog: {
      title: 'Delete comment?',
      message:
        'Are you sure you want to delete this comment? This can’t be undone and replies to this comment will also be removed.',
      cancelButtonTitle: 'Cancel',
      okButtonTitle: 'Delete',
    },
    networkExceptionPlaceholder: {
      title: 'Thread isn’t loading right now',
      subtitle: 'Please try again later.',
    },
  },
} as const;

export const BlabItemActivityThreadI18n = new I18nTranslation<typeof languages.EN>(languages);
