import {
  graphicUserAvatarA,
  graphicUserAvatarB,
  graphicUserAvatarC,
  graphicUserAvatarD,
  graphicUserAvatarE,
  graphicUserAvatarF,
  graphicUserAvatarG,
  graphicUserAvatarH,
  graphicUserAvatarI,
  graphicUserAvatarJ,
  graphicUserAvatarK,
  graphicUserAvatarL,
  graphicUserAvatarM,
  graphicUserAvatarN,
  graphicUserAvatarO,
  graphicUserAvatarP,
  graphicUserAvatarQ,
  graphicUserAvatarR,
  graphicUserAvatarS,
  graphicUserAvatarT,
  graphicUserAvatarU,
  graphicUserAvatarV,
  graphicUserAvatarW,
  graphicUserAvatarX,
  graphicUserAvatarY,
  graphicUserAvatarZ,
} from '@t5s/mobile-client/asset';
import { UserProfileDto, UserProfilePreviewDto } from '@t5s/shared/gql';

const avatarFallbackGraphic = {
  A: graphicUserAvatarA,
  B: graphicUserAvatarB,
  C: graphicUserAvatarC,
  D: graphicUserAvatarD,
  E: graphicUserAvatarE,
  F: graphicUserAvatarF,
  G: graphicUserAvatarG,
  H: graphicUserAvatarH,
  I: graphicUserAvatarI,
  J: graphicUserAvatarJ,
  K: graphicUserAvatarK,
  L: graphicUserAvatarL,
  M: graphicUserAvatarM,
  N: graphicUserAvatarN,
  O: graphicUserAvatarO,
  P: graphicUserAvatarP,
  Q: graphicUserAvatarQ,
  R: graphicUserAvatarR,
  S: graphicUserAvatarS,
  T: graphicUserAvatarT,
  U: graphicUserAvatarU,
  V: graphicUserAvatarV,
  W: graphicUserAvatarW,
  X: graphicUserAvatarX,
  Y: graphicUserAvatarY,
  Z: graphicUserAvatarZ,
};

export function getFallbackGraphic(user: UserProfileDto | UserProfilePreviewDto) {
  const fallbackLetter = getFallbackLetter(user);
  return avatarFallbackGraphic[fallbackLetter ?? 'A'];
}

function getFallbackLetter(
  user: UserProfileDto | UserProfilePreviewDto,
):
  | undefined
  | 'A'
  | 'B'
  | 'C'
  | 'D'
  | 'E'
  | 'F'
  | 'G'
  | 'H'
  | 'I'
  | 'J'
  | 'K'
  | 'L'
  | 'M'
  | 'N'
  | 'O'
  | 'P'
  | 'Q'
  | 'R'
  | 'S'
  | 'T'
  | 'U'
  | 'V'
  | 'W'
  | 'X'
  | 'Y'
  | 'Z' {
  if (!user) {
    return undefined;
  }
  const { primaryName, secondaryName, name, shortname, email } = user as any;

  const content = `${primaryName ?? ''}${secondaryName ?? ''}${shortname ?? ''}${name ?? ''}${email ?? ''}`
    .trim()
    .toUpperCase();
  const letters = content.replace(/[^A-Z]/g, '');

  return (letters[0] ?? content[0]) as unknown as any;
}
