import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { Language } from '@t5s/shared/gql';
import { formatDateViaLanguage, formatTime, getDateTimeFormats, isApplicableI18n, normalizeDate } from '../common';

export function formatCalendarPreviewDateTime(i18n: SharedI18n, date?: string | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  const dateFormats = getDateTimeFormats(i18n);

  const time = formatTime(date, dateFormats.time.short, i18n);
  const day = formatDateViaLanguage(date, dateFormats.date.weekdayAbbr, i18n);

  const { language } = i18n;

  if (language === Language.DE) {
    return `${day} ${time}`;
  }

  return `${day}, ${time}`;
}
