import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { isNetworkError } from '@t5s/client/util/error';
import { RuntimeFeatureAccessLevelObservable } from '@t5s/client/util/feature-access-level';
import { ofErrorType } from '@t5s/client/util/store';
import { ExceptionToastI18n } from '@t5s/mobile-client/i18n/exception-toast';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
import { ToastService } from '@t5s/mobile-client/service/toast';
import { ThemeColorVar } from '@t5s/mobile-client/ui/style/theme';
import { UserFeatureAccessLevel } from '@t5s/shared/gql';
import { EMPTY } from 'rxjs';
import { filter, switchMap, withLatestFrom } from 'rxjs/operators';
@Injectable()
export class ExceptionToastEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly toastService: ToastService,
    private readonly i18n$: I18nObjectObservable,
    private readonly accessLevel$: RuntimeFeatureAccessLevelObservable,
  ) {}

  readonly navigateToRegistration$ = createEffect(
    () =>
      // Only exception toasts for DEV
      this.accessLevel$.pipe(
        filter((level) => level === UserFeatureAccessLevel.DEV),
        switchMap(() =>
          this.actions$.pipe(
            ofErrorType(),
            withLatestFrom(this.i18n$),
            switchMap(([{ error }, { i18n }]) => {
              if (isNetworkError(error)) {
                return EMPTY;
              }

              return this.toastService.show({
                message: ExceptionToastI18n.translate(i18n, ExceptionToastI18n.key.genericException),
                bgColor: ThemeColorVar.danger,
              });
            }),
          ),
        ),
      ),
    { dispatch: false },
  );
}
