import {
  ChatActivityLoadingState,
  ChatConversationClientModel,
  ChatConversationLoadingState,
} from '@t5s/mobile-client/value-object/chat-conversation';
import { ChatActivityDtoConnection } from '@t5s/shared/gql';
import { sortChatActivityEdges } from './chat-activity.utils';

function dedupEdges<T extends { node: { id: number } }>(edges: T[]): T[] {
  return edges.filter((activity, index) => {
    return edges.findIndex((a) => a.node.id === activity.node.id) === index;
  });
}

export function getLoadMoreInputForConversation(
  conversation: ChatConversationClientModel,
): { cursor: string; conversationId: number } | undefined {
  const { conversationId, chatActivity } = conversation;

  if (!chatActivity) {
    return undefined;
  }

  const {
    pageInfo: { hasNextPage },
    chatActivities = [],
  } = chatActivity;

  if (!hasNextPage) {
    return undefined;
  }

  const sortedChatActivities = [...chatActivities].sort(sortChatActivityEdges);

  if (!sortedChatActivities.length) {
    return undefined;
  }

  const [{ cursor }] = sortedChatActivities;

  return { cursor, conversationId };
}

export function getChatConversationAfterChatConversationActivityLoadSuccess(
  chatConversation: ChatConversationClientModel,
  { edges, pageInfo }: ChatActivityDtoConnection,
): ChatConversationClientModel {
  const { chatActivity } = chatConversation;

  if (!chatActivity) {
    return {
      ...chatConversation,
      chatActivity: { chatActivities: edges, pageInfo },
      loadingState: ChatConversationLoadingState.LOADED,
      chatActivityLoadingState: ChatActivityLoadingState.LOADED,
    };
  }

  let { chatActivities } = chatActivity;
  chatActivities = [...edges, ...chatActivities];
  chatActivities = dedupEdges(chatActivities);

  return {
    ...chatConversation,
    chatActivity: { ...chatActivity, chatActivities, pageInfo },
    loadingState: ChatConversationLoadingState.LOADED,
    chatActivityLoadingState: ChatActivityLoadingState.LOADED,
  };
}

export function getChatConversationAfterChatConversationActivityLoadMoreSuccess(
  chatConversation: ChatConversationClientModel,
  { edges, pageInfo }: ChatActivityDtoConnection,
): ChatConversationClientModel {
  const { chatActivity } = chatConversation;

  if (!chatActivity) {
    return chatConversation;
  }

  let { chatActivities } = chatActivity;
  chatActivities = [...edges, ...chatActivities];
  chatActivities = dedupEdges(chatActivities);

  return {
    ...chatConversation,
    chatActivity: { ...chatActivity, chatActivities, pageInfo },
    loadingState: ChatConversationLoadingState.LOADED,
    chatActivityLoadingState: ChatActivityLoadingState.LOADED,
  };
}
