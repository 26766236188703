import gql from 'graphql-tag';
import {
  ChecklistFlimValEntryFocusPreviewDtoNoNestingFragment,
  ChecklistFocusEntryDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const ChecklistFocusEntryFragment = gql`
  fragment ChecklistFocusEntryFragment on ChecklistFocusEntryDto {
    ...ChecklistFocusEntryDtoNoNesting
    checklistEntry {
      ...ChecklistFlimValEntryFocusPreviewDtoNoNesting
      blabItem {
        id
        title
        blabDef {
          id
          icon
          iconv2
          itemIcon
          blabName
          itemName
        }
      }
    }
  }
  ${ChecklistFocusEntryDtoNoNestingFragment}
  ${ChecklistFlimValEntryFocusPreviewDtoNoNestingFragment}
`;
