import gql from 'graphql-tag';
import { OrganizationAuthenticationProviderDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const OrganizationAuthenticationProviderDtoFragment = gql`
  fragment OrganizationAuthenticationProviderDto on OrganizationAuthenticationProviderDto {
    ...OrganizationAuthenticationProviderDtoNoNesting
    fallbackSmtpAccountIds
  }
  ${OrganizationAuthenticationProviderDtoNoNestingFragment}
`;
