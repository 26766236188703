import gql from 'graphql-tag';
import {
  ThumbnailDtoNoNestingFragment,
  UserProfilePreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const FlimFilterFragment = gql`
  fragment FlimFilterFragment on FlimFilterDto {
    id
    blabViewId
    flimDefId
    type
    position
    derivedFromSplitBy

    ... on SingleTextFlimFilterDto {
      searchText
      singleTextMatchType
    }

    ... on MultiTextFlimFilterDto {
      searchText
      multiTextMatchType
    }

    ... on SingleCategoryFlimFilterDto {
      categoryOptionDefIds
      singleCategoryMatchType
    }

    ... on MultiCategoryFlimFilterDto {
      categoryOptionDefIds
      multiCategoryMatchType
    }

    ... on SingleRelationFlimFilterDto {
      blabItemReferences {
        id
        title
        blabDef {
          id
          blabName
          itemName
          icon
          iconv2
          itemIcon
          workspace {
            id
            name
          }
        }
      }
      singleRelationMatchType
    }
    ... on MultiRelationFlimFilterDto {
      blabItemReferences {
        id
        title
        blabDef {
          id
          blabName
          itemName
          icon
          iconv2
          itemIcon
          workspace {
            id
            name
          }
        }
      }
      multiRelationMatchType
    }

    ... on SingleUserFlimFilterDto {
      referencedUsers {
        ...UserProfilePreviewDtoNoNesting
        profilePicture {
          ...ThumbnailDtoNoNesting
        }
      }
      singleUserMatchType
      includeActiveUser
      includeActiveUserIndex
    }

    ... on CalculationFlimFilterDto {
      searchText
      calculationMatchType
      decimal
      calculationMatchType
      calculationRelativeDate
      calculationRelativeDateRange
      numDays
      numWeeks
      numMonths
      exactDate
      weekday
      quarterOfYear
      monthOfYear
      exactEndDate
    }

    ... on MultiUserFlimFilterDto {
      referencedUsers {
        ...UserProfilePreviewDtoNoNesting
        profilePicture {
          ...ThumbnailDtoNoNesting
        }
      }
      multiUserMatchType
      includeActiveUser
      includeActiveUserIndex
    }

    ... on SingleAttachmentFlimFilterDto {
      searchText
      singleAttachmentMatchType
    }

    ... on MultiAttachmentFlimFilterDto {
      searchText
      multiAttachmentMatchType
    }

    ... on SingleDateFlimFilterDto {
      singleDateMatchType
      exactDate
      weekday
      quarterOfYear
      monthOfYear
      exactEndDate
      numDays
      numWeeks
      numMonths
      singleDateRelativeDate
      singleDateRelativeDateRange
    }

    ... on RangeDateFlimFilterDto {
      rangeDateMatchType
      exactDate
      numDays
      numWeeks
      numMonths
      rangeDateRelativeDate
      rangeDateRelativeDateRange
      weekday
      quarterOfYear
      monthOfYear
      exactEndDate
    }

    ... on NumberFlimFilterDto {
      numberMatchType
      decimal
    }

    ... on StatusFlimFilterDto {
      statusOptionDefIds
      statusMatchType
    }

    ... on MultiEmailFlimFilterDto {
      searchText
      multiEmailMatchType
    }

    ... on MultiPhoneFlimFilterDto {
      searchText
      multiPhoneMatchType
    }

    ... on MultiLinkFlimFilterDto {
      searchText
      multiLinkMatchType
    }

    ... on MultiLinkFlimFilterDto {
      searchText
      multiLinkMatchType
    }

    ... on ChecklistFlimFilterDto {
      searchText
      checklistMatchType
      checklistEntryProperty
      searchText
      date
      completed
      includeActiveUser
      includeActiveUserIndex
      referencedUserIds
      referencedUsers {
        ...UserProfilePreviewDtoNoNesting
      }
      numDays
      numWeeks
      numMonths
      checklistDueDateRelativeDateType
      checklistDueDateRelativeDateRangeType
    }

    ... on MultiImageFlimFilterDto {
      searchText
      multiImageMatchType
    }

    ... on CreatedByFlimFilterDto {
      referencedUsers {
        ...UserProfilePreviewDtoNoNesting
        profilePicture {
          ...ThumbnailDtoNoNesting
        }
      }
      createdByMatchType
      includeActiveUser
      includeActiveUserIndex
    }
    ... on LastModifiedByFlimFilterDto {
      referencedUsers {
        ...UserProfilePreviewDtoNoNesting
        profilePicture {
          ...ThumbnailDtoNoNesting
        }
      }
      lastModifiedByMatchType
      includeActiveUser
      includeActiveUserIndex
    }

    ... on CreatedAtFlimFilterDto {
      createdAtMatchType
      exactDate
      numDays
      numWeeks
      numMonths
      weekday
      quarterOfYear
      monthOfYear
      exactEndDate
      createdAtRelativeDate
      createdAtRelativeDateRange
    }

    ... on LastModifiedAtFlimFilterDto {
      lastModifiedAtMatchType
      exactDate
      numDays
      numWeeks
      numMonths
      weekday
      quarterOfYear
      monthOfYear
      exactEndDate
      lastModifiedAtRelativeDate
      lastModifiedAtRelativeDateRange
    }

    ... on UniqueIdFlimFilterDto {
      decimal
      uniqueIdMatchType
    }

    ... on SingleLocationFlimFilterDto {
      searchText
      singleLocationMatchType
    }
  }
  ${UserProfilePreviewDtoNoNestingFragment}
  ${ThumbnailDtoNoNestingFragment}
`;
