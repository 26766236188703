import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { flexCenterVertical, flexRow, fullWidth, padding } from '@t5s/client/ui/style/common';
import { tss } from '@t5s/client/util/tss';
import { RxComponent, selectSlice } from '@t5s/mobile-client/ui/component/common';
import { EmojiSearchResult } from '@t5s/mobile-client/value-object/emoji-picker';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface EmojiPickerSearchResultState {
  searchResult?: EmojiSearchResult;
}

@Component({
  selector: 't5s-emoji-picker-search-result',
  template: `
    <ng-container *ngIf="searchResult$ | push as searchResult">
      <button
        [class]="containerClass"
        [t5sTouchActive]="'background: rgba(0, 0, 0, 0.07)'"
        (t5sPressDisableLongpress)="emojiClick.emit({ id: searchResult.id })"
      >
        <!-- Emoji -->
        <ngx-emoji [emoji]="searchResult.id" [isNative]="true" [size]="24"></ngx-emoji>

        <!-- Colons -->
        <t5s-single-line-text
          [style.min-width.px]="0"
          [style.flex]="1"
          [style.margin-left.px]="16"
          [font]="Font.regular19px"
          [fgColor]="Color.darkest"
        >
          {{ searchResult.colons }}
        </t5s-single-line-text>
      </button>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmojiPickerSearchResultComponent extends RxComponent<EmojiPickerSearchResultState> {
  @Input() set searchResult(searchResult: EmojiSearchResult | Observable<EmojiSearchResult>) {
    this.setProperty('searchResult', searchResult);
  }

  @Output() emojiClick = new EventEmitter<{ id: string }>();

  readonly searchResult$ = this.select(
    selectSlice(['searchResult']),
    map(({ searchResult }) => searchResult),
  );

  readonly containerClass = tss({
    ...fullWidth,
    padding: padding(16, 16),
    ...flexRow,
    ...flexCenterVertical,
  });
}
