import gql from 'graphql-tag';
import {
  UserProfilePreviewDtoNoNestingFragment,
  WorkflowDefPreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const NotificationBlabItemActivityDtoFragmentV2 = gql`
  fragment NotificationBlabItemActivityDtoFragmentV2 on BlabItemActivityDto {
    id
    type
    createdAt
    author {
      ...UserProfilePreviewDtoNoNesting
      profilePicture {
        ...ThumbnailDtoNoNesting
      }
    }
    authorId
    authorWorkflowDef {
      ...WorkflowDefPreviewDtoNoNesting
    }
    authorWorkflowDefId
    numReplies
    blabItemId
  }
  ${UserProfilePreviewDtoNoNestingFragment}
  ${WorkflowDefPreviewDtoNoNestingFragment}
`;
