import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ComponentStyle } from '@t5s/client/ui/style/common';

@Component({
  selector: 't5s-mobile-client-root',
  template: `<t5s-root-page></t5s-root-page>`,
  styles: [ComponentStyle.HostSpread],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileClientRootComponent {}
