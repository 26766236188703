import { createReducer, on } from '@ngrx/store';
import { mobileClientDefaultTheme, Theme } from '@t5s/mobile-client/ui/style/theme';
import { ApplicationThemeActions } from './application-theme.actions';

export const applicationThemeStateKey = 'applicationTheme';
export interface ApplicationThemeState {
  theme: Theme;
}

const initialState: ApplicationThemeState = { theme: mobileClientDefaultTheme };

export const applicationThemeReducer = createReducer(
  initialState,
  on(ApplicationThemeActions.setApplicationTheme, (state, props) => Object.assign({}, state, props)),
);
