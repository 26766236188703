import gql from 'graphql-tag';
import {
  OrganizationProfileDtoNoNestingFragment,
  WorkspacePreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const WorkspaceSearchResultFragment = gql`
  fragment WorkspaceSearchResult on WorkspaceSearchResultDto {
    workspace {
      ...WorkspacePreviewDtoNoNesting
      organization {
        ...OrganizationProfileDtoNoNesting
      }
    }
  }
  ${WorkspacePreviewDtoNoNestingFragment}
  ${OrganizationProfileDtoNoNestingFragment}
`;
