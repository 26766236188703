import gql from 'graphql-tag';
import { CardPaymentMethodDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const CardPaymentMethodDtoFragment = gql`
  fragment CardPaymentMethodDtoFragment on CardPaymentMethodDto {
    ...CardPaymentMethodDtoNoNesting
    createdByUser {
      id
      primaryName
    }
  }
  ${CardPaymentMethodDtoNoNestingFragment}
`;
