import gql from 'graphql-tag';
import { NotificationActivityDtoFragment } from '../notification/notification-activity-dto.fragment';
import { UserActivityReplyDtoFragment, UserActivityUnionTypeFragment } from '../user-activity';

export const UserNotificationActivityDtoFragment = gql`
  fragment UserNotificationActivityDtoFragment on UserNotificationActivityDto {
    ...NotificationActivityDtoFragment
    activity {
      ...UserActivityUnionTypeFragment
    }
    activityReply {
      ...UserActivityReplyDtoFragment
    }
  }
  ${NotificationActivityDtoFragment}
  ${UserActivityUnionTypeFragment}
  ${UserActivityReplyDtoFragment}
`;
