import { createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  getBlabItemAfterActiveUserBlabItemInfoLiveUpdate,
  getBlabItemAfterBlabItemActivityLiveUpdate,
  getBlabItemAfterBlabItemActivityReactionUpdate,
  getBlabItemAfterLiveUpdate,
  getBlabItemDisplayCommentInputStateAddAttachments,
  getBlabItemDisplayCommentInputStateAttachmentUploadException,
  getBlabItemDisplayCommentInputStateAttachmentUploadStart,
  getBlabItemDisplayCommentInputStateAttachmentUploadSuccess,
  getBlabItemDisplayCommentInputStateRemoveAttachment,
} from '@t5s/mobile-client/util/blab-item-display';
import {
  BlabItemDisplayActiveUserBlabItemInfoLoadingState,
  BlabItemDisplayBlabItemActivityLoadingState,
  BlabItemDisplayClientModel,
  BlabItemDisplayIncomingRelationsLoadingState,
  BlabItemDisplayLoadingState,
} from '@t5s/mobile-client/value-object/blab-item-display';
import { BlabItemActivityUnionType } from '@t5s/shared/gql';
import { BlabItemDisplayActions } from './blab-item-display.actions';
import { BlabItemDisplayState } from './blab-item-display.state';

export const blabItemDisplayStateKey = 'blabItemDisplay';

const adapter = createEntityAdapter<BlabItemDisplayClientModel>();

export const { selectAll, selectEntities } = adapter.getSelectors();

const initialState: BlabItemDisplayState = adapter.getInitialState({});

const MAX_NUM_PERSISTED_BLAB_ITEMS = 500;

export const preprocessStateForSerialization = (state: BlabItemDisplayState) => {
  const blabItems = selectAll(state)
    .filter((blabItem) => blabItem.loadingState === BlabItemDisplayLoadingState.LOADED)
    .slice(0, MAX_NUM_PERSISTED_BLAB_ITEMS);
  state = adapter.setAll(blabItems, state);
  return state;
};

export const blabItemDisplayReducer = createReducer(
  initialState,
  on(BlabItemDisplayActions.reset, () => ({ ...initialState })),

  on(BlabItemDisplayActions.hydrateStateSuccess, (state, { state: hydratedState }) => {
    return { ...state, ...hydratedState };
  }),

  on(BlabItemDisplayActions.markBlabItemForLoading, (state, { blabItemId }) =>
    adapter.upsertOne(
      {
        id: blabItemId,
        loadingState: BlabItemDisplayLoadingState.LOADING,
        activeUserBlabItemInfoLoadingState: BlabItemDisplayActiveUserBlabItemInfoLoadingState.INITIAL,
        blabItemActivityLoadingState: BlabItemDisplayBlabItemActivityLoadingState.INITIAL,
        incomingRelationsLoadingState: BlabItemDisplayIncomingRelationsLoadingState.INITIAL,
        commentInput: {},
      },
      state,
    ),
  ),

  on(BlabItemDisplayActions.loadBlabItem, (state, { blabItemId }) =>
    adapter.addOne(
      {
        id: blabItemId,
        loadingState: BlabItemDisplayLoadingState.LOADING,
        activeUserBlabItemInfoLoadingState: BlabItemDisplayActiveUserBlabItemInfoLoadingState.INITIAL,
        blabItemActivityLoadingState: BlabItemDisplayBlabItemActivityLoadingState.INITIAL,
        incomingRelationsLoadingState: BlabItemDisplayIncomingRelationsLoadingState.INITIAL,
        commentInput: {},
      },
      state,
    ),
  ),
  on(BlabItemDisplayActions.loadBlabItemSuccess, (state, { blabItemId, blabItem }) =>
    adapter.updateOne(
      { id: blabItemId, changes: { blabItem, loadingState: BlabItemDisplayLoadingState.LOADED } },
      state,
    ),
  ),
  on(BlabItemDisplayActions.loadBlabItemException, (state, { blabItemId }) =>
    adapter.updateOne({ id: blabItemId, changes: { loadingState: BlabItemDisplayLoadingState.EXCEPTION } }, state),
  ),
  on(BlabItemDisplayActions.loadBlabItemRejectedNotAvailable, (state, { blabItemId }) =>
    adapter.updateOne({ id: blabItemId, changes: { loadingState: BlabItemDisplayLoadingState.NOT_AVAILABLE } }, state),
  ),

  on(BlabItemDisplayActions.reloadBlabItemTitleSuccess, (state, { blabItemId, blabItem: { title } }) => {
    const blabItemState = selectEntities(state)[blabItemId];

    if (!blabItemState) {
      return state;
    }

    const { blabItem } = blabItemState;

    if (!blabItem) {
      return state;
    }

    return adapter.updateOne({ id: blabItemId, changes: { blabItem: { ...blabItem, title } } }, state);
  }),

  on(BlabItemDisplayActions.preloadBlabItemsWithActivity, (state, { blabItemIds }) =>
    adapter.addMany(
      blabItemIds.map((blabItemId) => ({
        id: blabItemId,
        loadingState: BlabItemDisplayLoadingState.PRELOADING,
        activeUserBlabItemInfoLoadingState: BlabItemDisplayActiveUserBlabItemInfoLoadingState.INITIAL,
        blabItemActivityLoadingState: BlabItemDisplayBlabItemActivityLoadingState.INITIAL,
        incomingRelationsLoadingState: BlabItemDisplayIncomingRelationsLoadingState.INITIAL,
        commentInput: {},
      })),
      state,
    ),
  ),
  on(
    BlabItemDisplayActions.preloadBlabItemWithActivitySuccess,
    (state, { blabItemId, blabItem, blabItemActivity: { edges, pageInfo }, activeUserBlabItemInfo }) =>
      adapter.updateOne(
        {
          id: blabItemId,
          changes: {
            blabItem,
            loadingState: BlabItemDisplayLoadingState.LOADED,
            blabItemActivity: { blabItemActivities: edges, pageInfo },
            activeUserBlabItemInfo,
          },
        },
        state,
      ),
  ),

  on(BlabItemDisplayActions.loadBlabItemActivities, (state, { blabItemId }) =>
    adapter.updateOne(
      {
        id: blabItemId,
        changes: { blabItemActivityLoadingState: BlabItemDisplayBlabItemActivityLoadingState.LOADING },
      },
      state,
    ),
  ),
  on(BlabItemDisplayActions.loadBlabItemActivitiesSuccess, (state, { blabItemId, blabItemActivities, pageInfo }) =>
    adapter.updateOne(
      {
        id: blabItemId,
        changes: {
          blabItemActivityLoadingState: BlabItemDisplayBlabItemActivityLoadingState.LOADED,
          blabItemActivity: { blabItemActivities, pageInfo },
        },
      },
      state,
    ),
  ),
  on(BlabItemDisplayActions.loadBlabItemActivitiesException, (state, { blabItemId }) =>
    adapter.updateOne(
      {
        id: blabItemId,
        changes: { blabItemActivityLoadingState: BlabItemDisplayBlabItemActivityLoadingState.EXCEPTION },
      },
      state,
    ),
  ),

  on(BlabItemDisplayActions.loadIncomingRelationFlimDefs, (state, { blabItemId }) =>
    adapter.updateOne(
      {
        id: blabItemId,
        changes: { incomingRelationsLoadingState: BlabItemDisplayIncomingRelationsLoadingState.LOADING },
      },
      state,
    ),
  ),
  on(BlabItemDisplayActions.loadIncomingRelationFlimDefsSuccess, (state, { blabItemId, incomingRelationFlimDefs }) =>
    adapter.updateOne(
      {
        id: blabItemId,
        changes: {
          incomingRelationsLoadingState: BlabItemDisplayIncomingRelationsLoadingState.LOADED,
          incomingRelations: { incomingRelationFlimDefs },
        },
      },
      state,
    ),
  ),
  on(BlabItemDisplayActions.loadIncomingRelationFlimDefsException, (state, { blabItemId }) =>
    adapter.updateOne(
      {
        id: blabItemId,
        changes: { incomingRelationsLoadingState: BlabItemDisplayIncomingRelationsLoadingState.EXCEPTION },
      },
      state,
    ),
  ),

  on(BlabItemDisplayActions.loadActiveUserBlabItemInfo, (state, { blabItemId }) =>
    adapter.updateOne(
      {
        id: blabItemId,
        changes: { activeUserBlabItemInfoLoadingState: BlabItemDisplayActiveUserBlabItemInfoLoadingState.LOADED },
      },
      state,
    ),
  ),
  on(BlabItemDisplayActions.loadActiveUserBlabItemInfoSuccess, (state, { blabItemId, activeUserBlabItemInfo }) =>
    adapter.updateOne(
      {
        id: blabItemId,
        changes: {
          activeUserBlabItemInfoLoadingState: BlabItemDisplayActiveUserBlabItemInfoLoadingState.LOADED,
          activeUserBlabItemInfo,
        },
      },
      state,
    ),
  ),
  on(BlabItemDisplayActions.loadActiveUserBlabItemInfoException, (state, { blabItemId }) =>
    adapter.updateOne(
      {
        id: blabItemId,
        changes: { activeUserBlabItemInfoLoadingState: BlabItemDisplayActiveUserBlabItemInfoLoadingState.EXCEPTION },
      },
      state,
    ),
  ),

  on(BlabItemDisplayActions.blabItemLiveUpdate, (state, { blabItemId, flimVal }) => {
    const blabItemState = selectEntities(state)[blabItemId];

    if (!blabItemState) {
      return state;
    }

    const { blabItem } = blabItemState;

    if (!blabItem) {
      return state;
    }

    const updatedBlabItem = getBlabItemAfterLiveUpdate(blabItem, flimVal);
    return adapter.updateOne({ id: blabItemId, changes: { blabItem: updatedBlabItem } }, state);
  }),

  on(BlabItemDisplayActions.blabItemActivityLiveUpdate, (state, { blabItemId, update }) => {
    const blabItemState = selectEntities(state)[blabItemId];

    if (!blabItemState) {
      return state;
    }

    const changes = getBlabItemAfterBlabItemActivityLiveUpdate(blabItemState, update);

    return adapter.updateOne({ id: blabItemId, changes }, state);
  }),

  on(BlabItemDisplayActions.activeUserBlabItemInfoLiveUpdate, (state, { blabItemId, blabItemInfo }) => {
    const blabItemState = selectEntities(state)[blabItemId];

    if (!blabItemState) {
      return state;
    }

    const changes = getBlabItemAfterActiveUserBlabItemInfoLiveUpdate(blabItemState, blabItemInfo);

    return adapter.updateOne({ id: blabItemId, changes }, state);
  }),

  on(BlabItemDisplayActions.followBlabItem, (state, { blabItemId }) => {
    const blabItemState = selectEntities(state)[blabItemId];
    if (!blabItemState) {
      return state;
    }

    const { activeUserBlabItemInfo } = blabItemState;

    if (!activeUserBlabItemInfo) {
      return state;
    }

    return adapter.updateOne(
      { id: blabItemId, changes: { activeUserBlabItemInfo: { ...activeUserBlabItemInfo, isFollowing: true } } },
      state,
    );
  }),
  on(BlabItemDisplayActions.unfollowBlabItem, (state, { blabItemId }) => {
    const blabItemState = selectEntities(state)[blabItemId];
    if (!blabItemState) {
      return state;
    }

    const { activeUserBlabItemInfo } = blabItemState;

    if (!activeUserBlabItemInfo) {
      return state;
    }

    return adapter.updateOne(
      { id: blabItemId, changes: { activeUserBlabItemInfo: { ...activeUserBlabItemInfo, isFollowing: false } } },
      state,
    );
  }),

  // BlabItem activity reactions
  on(
    BlabItemDisplayActions.addBlabItemActivityReactionSuccess,
    BlabItemDisplayActions.removeBlabItemActivityReactionSuccess,
    (state, { activity }) => {
      const blabItemState = selectEntities(state)[activity.blabItemId];

      if (!blabItemState) {
        return state;
      }

      const changes = getBlabItemAfterBlabItemActivityReactionUpdate(
        blabItemState,
        activity as BlabItemActivityUnionType,
      );

      return adapter.updateOne({ id: activity.blabItemId, changes }, state);
    },
  ),

  // Comment input
  on(BlabItemDisplayActions.setCommentInputContent, (state, { blabItemId, content }) => {
    const blabItemState = selectEntities(state)[blabItemId];

    if (!blabItemState) {
      return state;
    }

    const { commentInput = {} } = blabItemState;

    return adapter.updateOne(
      {
        id: blabItemId,
        changes: {
          commentInput: {
            ...commentInput,
            content,
          },
        },
      },
      state,
    );
  }),
  on(BlabItemDisplayActions.sendBlabItemCommentSuccess, (state, { blabItemId }) => {
    return adapter.updateOne(
      {
        id: blabItemId,
        changes: {
          commentInput: {
            content: '',
          },
        },
      },
      state,
    );
  }),

  on(BlabItemDisplayActions.addAttachmentFiles, (state, { blabItemId, files }) => {
    const blabItemState = selectEntities(state)[blabItemId];

    if (!blabItemState) {
      return state;
    }

    const { commentInput = {} } = blabItemState;

    return adapter.updateOne(
      {
        id: blabItemId,
        changes: {
          commentInput: getBlabItemDisplayCommentInputStateAddAttachments(commentInput, files),
        },
      },
      state,
    );
  }),
  on(BlabItemDisplayActions.uploadFileException, (state, { blabItemId, attachment }) => {
    const blabItemState = selectEntities(state)[blabItemId];

    if (!blabItemState) {
      return state;
    }

    const { commentInput } = blabItemState;

    if (!commentInput) {
      return state;
    }

    return adapter.updateOne(
      {
        id: blabItemId,
        changes: {
          commentInput: getBlabItemDisplayCommentInputStateAttachmentUploadException(commentInput, attachment),
        },
      },
      state,
    );
  }),
  on(BlabItemDisplayActions.removeAttachment, (state, { blabItemId, attachmentId }) => {
    const blabItemState = selectEntities(state)[blabItemId];

    if (!blabItemState) {
      return state;
    }

    const { commentInput } = blabItemState;

    if (!commentInput) {
      return state;
    }

    return adapter.updateOne(
      {
        id: blabItemId,
        changes: {
          commentInput: getBlabItemDisplayCommentInputStateRemoveAttachment(commentInput, attachmentId),
        },
      },
      state,
    );
  }),
  on(BlabItemDisplayActions.uploadFile, (state, { blabItemId, attachment }) => {
    const blabItemState = selectEntities(state)[blabItemId];

    if (!blabItemState) {
      return state;
    }

    const { commentInput } = blabItemState;

    if (!commentInput) {
      return state;
    }

    return adapter.updateOne(
      {
        id: blabItemId,
        changes: {
          commentInput: getBlabItemDisplayCommentInputStateAttachmentUploadStart(commentInput, attachment),
        },
      },
      state,
    );
  }),

  on(BlabItemDisplayActions.uploadFileSuccess, (state, { blabItemId, attachment, persistedAttachment }) => {
    const blabItemState = selectEntities(state)[blabItemId];

    if (!blabItemState) {
      return state;
    }

    const { commentInput } = blabItemState;

    if (!commentInput) {
      return state;
    }

    return adapter.updateOne(
      {
        id: blabItemId,
        changes: {
          commentInput: getBlabItemDisplayCommentInputStateAttachmentUploadSuccess(
            commentInput,
            attachment,
            persistedAttachment,
          ),
        },
      },
      state,
    );
  }),

  // Mention user
  on(BlabItemDisplayActions.searchMentionUserSuccess, (state, { userSearchResults }) => ({
    ...state,
    userSearchResults,
  })),
  on(BlabItemDisplayActions.clearMentionUserSearchResults, (state) => ({
    ...state,
    userSearchResults: [],
  })),
);
