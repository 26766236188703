import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RxComponent } from '@t5s/mobile-client/ui/component/common';
import { Observable } from 'rxjs';

interface StubItemsListState {
  numItems: number;
}

@Component({
  selector: 't5s-stub-items-list',
  template: `
    <ng-container *ngIf="state$ | push as state">
      <ng-container *ngFor="let _ of getArray(state.numItems)">
        <t5s-stub-item></t5s-stub-item>
      </ng-container>
    </ng-container>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StubItemsListComponent extends RxComponent<StubItemsListState> {
  constructor() {
    super();
    this.set({ numItems: 0 });
  }

  @Input() set numItems(numItems: number | Observable<number>) {
    this.setProperty('numItems', numItems);
  }

  getArray(numItems: number) {
    return Array.from(new Array(numItems));
  }
}
