import { ChatActivityDto, ChatMessageActivityDto } from '@t5s/shared/gql';
import { isDto } from './dto.type-guard';

export function isChatActivityDto(candidate: any): candidate is ChatActivityDto {
  return isDto<ChatActivityDto>(candidate, 'ChatActivityDto');
}

export function isChatMessageActivityDto(candidate: any): candidate is ChatMessageActivityDto {
  return isDto<ChatMessageActivityDto>(candidate, 'ChatMessageActivityDto');
}
