import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { SafeAreaDimensionsObservable } from '@t5s/mobile-client/provider-token/safe-area';
import { ServiceDeviceModule } from '@t5s/mobile-client/service/device';
import { ServiceKeyboardModule } from '@t5s/mobile-client/service/keyboard';
import { SafeAreaHydrationEffects } from './safe-area-hydration.effects';
import { SafeAreaEffects } from './safe-area.effects';
import { safeAreaReducer, safeAreaStateKey } from './safe-area.reducer';
import { selectSafeAreaDimensions } from './safe-area.selectors';

@NgModule({
  imports: [
    ServiceDeviceModule,
    ServiceKeyboardModule,
    EffectsModule.forFeature([SafeAreaEffects, SafeAreaHydrationEffects]),

    StoreModule.forFeature(safeAreaStateKey, safeAreaReducer),
  ],
  providers: [
    {
      provide: SafeAreaDimensionsObservable,
      useFactory: (store$: Store) => store$.select(selectSafeAreaDimensions),
      deps: [Store],
    },
  ],
})
export class SafeAreaBusinessLogicModule {}
