import gql from 'graphql-tag';
import { ReactionDtoFragment } from '../core';
import { OrganizationBreadcrumbFragment } from '../organization/organization-breadcrumb.fragment';
import { UserProfilePreviewDtoFragment } from '../user-profile';
import { OrganizationActivityReplyDtoFragment } from './organization-activity-reply-dto.fragment';

/**
 * Fragment containing all common organization activity properties of the OrganizationActivityDto interface
 * This fragment is used as a baseline for the activity-type specific fragments
 */
export const OrganizationActivityDtoFragment = gql`
  fragment OrganizationActivityDtoFragment on OrganizationActivityDto {
    id
    type
    createdAt
    author {
      ...UserProfilePreviewDtoFragment
    }
    authorId
    reactions {
      ...ReactionDtoFragment
    }
    numReplies
    replies(first: 2) {
      cursor
      node {
        ...OrganizationActivityReplyDtoFragment
      }
    }
    organizationId
    organization {
      ...OrganizationBreadcrumbFragment
    }
  }
  ${OrganizationActivityReplyDtoFragment}
  ${OrganizationBreadcrumbFragment}
  ${UserProfilePreviewDtoFragment}
  ${ReactionDtoFragment}
`;
