import gql from 'graphql-tag';
import {
  UserNotificationDtoNoNestingFragment,
  WorkspaceNotificationDtoNoNestingFragment,
} from '../../generated/graphql-fragments';
import { FullUserProfileDtoFragment } from '../user-profile';
import { WorkspaceNotificationActivityDtoFragment } from './workspace-notification-activity-dto.fragment';

export const WorkspaceNotificationDtoFragment = gql`
  fragment WorkspaceNotificationDtoFragment on WorkspaceNotificationDto {
    ...WorkspaceNotificationDtoNoNesting
    userId
    user {
      ...FullUserProfileDto
    }
    activities(first: 1) {
      ...WorkspaceNotificationActivityDtoFragment
    }
    workspace {
      id
      name
      slug
      organization {
        id
        slug
      }
    }
  }
  ${UserNotificationDtoNoNestingFragment}
  ${WorkspaceNotificationActivityDtoFragment}
  ${FullUserProfileDtoFragment}
  ${WorkspaceNotificationDtoNoNestingFragment}
`;
