import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { DeviceService } from '@t5s/mobile-client/service/device';
import { ScreenOrientationService } from '@t5s/mobile-client/service/screen-orientation';
import { filter, tap } from 'rxjs/operators';

@Injectable()
export class ScreenOrientationEffects {
  constructor(
    private readonly deviceService: DeviceService,
    readonly screenOrientationService: ScreenOrientationService,
  ) {}

  readonly lockInPortraitMode$ = createEffect(
    () =>
      this.deviceService.deviceInfo$.pipe(
        filter(({ platform }) => platform === 'android' || platform === 'ios'),
        tap(() => this.screenOrientationService.lock('portrait')),
      ),
    { dispatch: false },
  );
}
