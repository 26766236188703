import gql from 'graphql-tag';
import {
  MultiUserSplitByDtoNoNestingFragment,
  MultiUserSplitBySectionDtoNoNestingFragment,
} from '../../../generated/graphql-fragments';

export const MultiUserSplitByDtoFragment = gql`
  fragment MultiUserSplitByDto on MultiUserSplitByDto {
    ...MultiUserSplitByDtoNoNesting
    sections {
      ...MultiUserSplitBySectionDtoNoNesting
      user {
        id
        primaryName
        profilePicture {
          id
          small
        }
      }
    }
  }
  ${MultiUserSplitByDtoNoNestingFragment}
  ${MultiUserSplitBySectionDtoNoNestingFragment}
`;
