import gql from 'graphql-tag';
import {
  SubscribeToWorkspaceDuplicationJobFailureEventNoNestingFragment,
  SubscribeToWorkspaceDuplicationJobSuccessEventNoNestingFragment,
} from '../../generated/graphql-fragments';

export const WorkspaceDuplicationSubscriptionEventUnionTypeFragment = gql`
  fragment WorkspaceDuplicationSubscriptionEventUnionTypeFragment on SubscribeToWorkspaceDuplicationJobResult {
    ... on SubscribeToWorkspaceDuplicationJobFailureEvent {
      ...SubscribeToWorkspaceDuplicationJobFailureEventNoNesting
    }
    ... on SubscribeToWorkspaceDuplicationJobSuccessEvent {
      ...SubscribeToWorkspaceDuplicationJobSuccessEventNoNesting
    }
  }
  ${SubscribeToWorkspaceDuplicationJobFailureEventNoNestingFragment}
  ${SubscribeToWorkspaceDuplicationJobSuccessEventNoNestingFragment}
`;
