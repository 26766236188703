import { DOCUMENT } from '@angular/common';
import { NgModule } from '@angular/core';
import { ViewportDimensionsObservable } from '@t5s/mobile-client/provider-token/viewport-dimensions';
import { throttleTimeEmitInstantlyWithTrailing } from '@t5s/shared/util/rxjs';
import { fromEvent, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';

@NgModule({
  providers: [
    {
      provide: ViewportDimensionsObservable,
      useFactory: (_document: Document) => {
        const _window = _document.defaultView;
        if (!_window) {
          return { height: 0, width: 0 }; // TODO: check this fallback
        }

        const { innerHeight: height, innerWidth: width } = _window;

        try {
          return fromEvent(_window, 'resize', { passive: true }).pipe(
            throttleTimeEmitInstantlyWithTrailing(100),
            map(() => {
              const { innerHeight: height, innerWidth: width } = _window;
              return { width, height };
            }),
            startWith({ width, height }),
            distinctUntilChanged(({ height: h1, width: w1 }, { height: h2, width: w2 }) => h1 === h2 && w1 === w2),
            shareReplay(1),
          );
        } catch (e) {
          return of({ height: 0, width: 0 }); // TODO: check this fallback
        }
      },
      deps: [DOCUMENT],
    },
  ],
})
export class ViewportDimensionsProviderModule {}
