import gql from 'graphql-tag';
import { WorkspaceBreadcrumbFragment } from '../workspace/workspace-breadcrumb.fragment';

export const BlabDefBreadcrumbFragment = gql`
  fragment BlabDefBreadcrumbFragment on BlabDefPreviewDto {
    id
    blabName
    itemName
    icon
    iconv2
    itemIcon
    workspace {
      ...WorkspaceBreadcrumbFragment
    }
  }
  ${WorkspaceBreadcrumbFragment}
`;
