import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { formatDateNoLocale, normalizeDate } from '../common';

/** Used for CSV and XLSX exports. */
export function formatExportDate(i18n: SharedI18n, date: string | Date | undefined): string | undefined {
  if (!date) {
    return undefined;
  }

  date = normalizeDate(date);

  const formattedDate = formatDateNoLocale(date, 'yyyy-MM-dd');

  return `${formattedDate}`;
}
