import {
  ActivatedRouteSnapshot,
  ActivationStart,
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { filterOnlyFalse } from '@t5s/shared/util/rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';

export function getRouterEventStreams(router: Router) {
  const isNavigationStart = (event: Event): event is NavigationStart => event instanceof NavigationStart;
  const isNavigationEnd = (event: Event): event is NavigationEnd => event instanceof NavigationEnd;
  const isNavigationCancel = (event: Event): event is NavigationCancel => event instanceof NavigationCancel;
  const isNavigationError = (event: Event): event is NavigationError => event instanceof NavigationError;
  const isActivationStart = (event: Event): event is ActivationStart => event instanceof ActivationStart;

  const navigationStart$ = router.events.pipe(filter(isNavigationStart));
  const navigationEnd$ = router.events.pipe(filter(isNavigationEnd));
  const navigationCancel$ = router.events.pipe(filter(isNavigationCancel));
  const navigationError$ = router.events.pipe(filter(isNavigationError));
  const activationStart$ = router.events.pipe(filter(isActivationStart));

  return {
    navigationStart$,
    navigationEnd$,
    navigationCancel$,
    navigationError$,
    activationStart$,
  };
}

export const isPrimaryOutlet: (snapShot: ActivatedRouteSnapshot) => boolean = (snapshot) =>
  snapshot.outlet === 'primary';

/** Observable stream that emits whether the router is currently navigating. */
function getRouterIsNavigating(router: Router) {
  const routerIsNavigating$ = router.events.pipe(
    map(() => !!router.getCurrentNavigation()),
    startWith(!!router.getCurrentNavigation()),
  );

  return routerIsNavigating$;
}

/** Await router ongoing navigations to ensure that it is safe to navigate w/o issues. Wait defensively. */
export function getCanSafelyNavigate$(router: Router) {
  const canSafelyNavigate$ = getRouterIsNavigating(router).pipe(debounceTime(500), filterOnlyFalse());

  return canSafelyNavigate$;
}
