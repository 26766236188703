import gql from 'graphql-tag';
import { ActiveUserBlabDefInfoDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { ActiveUserAutoSaveBlabViewFullFragment } from '../active-user-auto-save-blab-view';
import { BlabViewFullFragment } from '../blab-view/blab-view.fragments';

export const ActiveUserBlabDefInfoActiveBlabViewUnionTypeFragment = gql`
  fragment ActiveUserBlabDefInfoActiveBlabViewUnionType on ActiveUserBlabDefInfoActiveBlabViewUnionType {
    ... on BlabViewDto {
      ...BlabViewFull
    }
    ... on ActiveUserAutoSaveBlabViewDto {
      ...ActiveUserAutoSaveBlabViewFull
    }
  }
  ${ActiveUserBlabDefInfoDtoNoNestingFragment}
  ${BlabViewFullFragment}
  ${ActiveUserAutoSaveBlabViewFullFragment}
`;
