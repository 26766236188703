import gql from 'graphql-tag';
import { PageInfoNoNestingFragment } from '../../generated/graphql-fragments';
import { UserProfilePreviewDtoFragment } from '../user-profile';

export const FullChatGroupUsersConnectionFragment = gql`
  fragment FullChatGroupUsersConnection on ChatGroupUsersConnection {
    pageInfo {
      ...PageInfoNoNesting
    }
    edges {
      cursor
      node {
        ...UserProfilePreviewDtoFragment
      }
    }
  }
  ${UserProfilePreviewDtoFragment}
  ${PageInfoNoNestingFragment}
`;
