import gql from 'graphql-tag';
import { WorkflowDefPreviewDtoNoNestingFragment } from '../../generated/graphql-fragments';

export const WorkflowDefActivityDtoFragment = gql`
  fragment WorkflowDefActivityDtoFragment on WorkflowDefActivityDto {
    id
    type
    createdAt
    workflowDef {
      ...WorkflowDefPreviewDtoNoNesting
    }
    authorId
    workflowDefId
    authorId
  }
  ${WorkflowDefPreviewDtoNoNestingFragment}
`;
