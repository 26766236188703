import gql from 'graphql-tag';
import { BlabItemReminderFragment } from './blab-item-reminder.fragment';

export const FullBlabItemReminderConnectionFragment = gql`
  fragment FullBlabItemReminderConnection on ReminderConnection {
    pageInfo {
      hasPreviousPage
      hasNextPage
    }
    edges {
      cursor
      node {
        ...BlabItemReminder
      }
    }
  }
  ${BlabItemReminderFragment}
`;
