import gql from 'graphql-tag';
import { BlabItemStatsResultDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { FlimStatResultUnionTypeFragment } from './flim-stat-result/flim-stat-result-union-type.fragment';

export const BlabItemStatsResultFragment = gql`
  fragment BlabItemStatsResult on BlabItemStatsResultDto {
    ...BlabItemStatsResultDtoNoNesting
    flimStats {
      ...FlimStatResultUnionType
    }
  }
  ${BlabItemStatsResultDtoNoNestingFragment}
  ${FlimStatResultUnionTypeFragment}
`;
