import { NgModule } from '@angular/core';
import { OutsideClickDirectiveModule } from '@t5s/mobile-client/directive/outside-click';
import { AssetComponentModule } from '@t5s/mobile-client/ui/component/asset';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { ExpandableIconButtonComponent } from './expandable-icon-button.component';
import { FilledHeaderIconButtonComponent } from './filled-header-icon-button.component';
import { FullWidthTextButtonComponent } from './full-width-text-button.component';
import { HeaderIconButtonComponent } from './header-icon-button.component';
import { IconButtonComponent } from './icon-button.component';
import { IconToggleButtonComponent } from './icon-toggle-button.component';
import { TextButtonComponent } from './text-button.component';

const components = [
  TextButtonComponent,
  FullWidthTextButtonComponent,
  IconButtonComponent,
  HeaderIconButtonComponent,
  ExpandableIconButtonComponent,
  FilledHeaderIconButtonComponent,
  IconToggleButtonComponent,
];

@NgModule({
  imports: [ComponentCommonModule, AssetComponentModule, OutsideClickDirectiveModule],
  declarations: components,
  exports: components,
})
export class ButtonComponentModule {}
