/* eslint-disable @typescript-eslint/no-misused-promises */
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RxDirective } from '@t5s/client/util/rx';
import { FileAttachmentPreviewActions } from '@t5s/mobile-client/business-logic/file-attachment-preview';
import { KeyboardActions } from '@t5s/mobile-client/business-logic/keyboard';
import {
  LoggedInRootActions,
  selectActiveOutlet,
  selectClientAppBubbles,
} from '@t5s/mobile-client/business-logic/logged-in-root';
import { PushNotificationActions } from '@t5s/mobile-client/business-logic/push-notification';
import { RootActions } from '@t5s/mobile-client/business-logic/root';
import { FileAttachmentPreviewPageComponent } from '@t5s/mobile-client/page/modal/file-attachment-preview';
import { PlatformObservable } from '@t5s/mobile-client/provider-token/device';
import { NavigationBarItem } from '@t5s/mobile-client/value-object/logged-in-root';
import { from } from 'rxjs';
import { share, takeUntil } from 'rxjs/operators';

@Component({
  selector: 't5s-logged-in-root-page',
  template: `
    <t5s-logged-in-root-view
      [active]="active$"
      [bubbles]="bubbles$"
      (navigationBarItemClick)="navigationBarItemClick($event)"
      (navigationBarItemLongpress)="navigationBarItemLongpress($event)"
    >
      <ion-tabs></ion-tabs>

      <ng-container logged-in-root-sheet-outlet>
        <t5s-reaction-details-page></t5s-reaction-details-page>
      </ng-container>
    </t5s-logged-in-root-view>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoggedInRootPagePageComponent extends RxDirective<{ platform: 'ios' | 'android' | 'web' }> {
  constructor(
    private readonly store$: Store,
    readonly actions$: Actions,
    readonly modalController: ModalController,
    platform$: PlatformObservable,
  ) {
    super();

    this.connect(platform$);

    // Access file attachment preview modal
    this.hold(
      actions$.pipe(ofType(FileAttachmentPreviewActions.openFileAttachmentPreviewModal)),
      async ({ attachment, attachments }) => {
        let initialActiveFileIndex = attachments.findIndex((att) => att.id === attachment.id);

        if (initialActiveFileIndex === -1) {
          initialActiveFileIndex = 0;
        }

        const { platform } = this.get();
        const modal = await this.modalController.create({
          component: FileAttachmentPreviewPageComponent,
          componentProps: { files: attachments, initialActiveFileIndex },
          swipeToClose: true,
          animated: true, //platform !== 'android',
          mode: platform === 'android' ? 'md' : 'ios',
        });

        this.store$.dispatch(KeyboardActions.hide());

        await modal.present();
        this.store$.dispatch(RootActions.setModalOpen({ modalOpen: true }));

        const modalDismissed$ = from(modal.onWillDismiss()).pipe(share());

        const shouldCloseModal$ = this.actions$.pipe(ofType(PushNotificationActions.pushNotificationActionPerformed));

        shouldCloseModal$.pipe(takeUntil(modalDismissed$)).subscribe(() => modal.dismiss());
        modalDismissed$.subscribe(() => this.store$.dispatch(RootActions.setModalOpen({ modalOpen: false })));
      },
    );
  }

  readonly active$ = this.store$.select(selectActiveOutlet);
  readonly bubbles$ = this.store$.select(selectClientAppBubbles);

  navigationBarItemClick({ item }: { item: NavigationBarItem }) {
    this.store$.dispatch(LoggedInRootActions.navigateToOutlet({ outlet: item }));
  }

  navigationBarItemLongpress({ item }: { item: NavigationBarItem }) {
    this.store$.dispatch(LoggedInRootActions.navigateToOutlet({ outlet: item, longpress: true }));
  }
}
