import gql from 'graphql-tag';
import {
  PageInfoNoNestingFragment,
  GlobalWorkflowRunPreviewDtoNoNestingFragment,
} from '../../generated/graphql-fragments';

export const GlobalWorkflowRunsConnectionLightFragment = gql`
  fragment GlobalWorkflowRunsConnectionLightFragment on GlobalWorkflowRunsConnection {
    edges {
      cursor
      node {
        ...GlobalWorkflowRunPreviewDtoNoNesting
      }
    }
    pageInfo {
      ...PageInfoNoNesting
    }
  }
  ${GlobalWorkflowRunPreviewDtoNoNestingFragment}
  ${PageInfoNoNestingFragment}
`;
