import { NgModule } from '@angular/core';
import { ComponentCommonModule } from '@t5s/mobile-client/ui/component/common';
import { HtmlTextComponent } from './html-text.component';
import { SingleLineTextComponent } from './single-line-text.component';
import { TextComponent } from './text.component';

const components = [TextComponent, SingleLineTextComponent, HtmlTextComponent];

@NgModule({
  imports: [ComponentCommonModule],
  declarations: components,
  exports: components,
})
export class TextComponentModule {}
