import gql from 'graphql-tag';
import { PageInfoNoNestingFragment } from '../../generated/graphql-fragments';
import { ChatConversationFragment } from './chat-conversation-dto.fragment';

export const ChatConversationConnectionFragment = gql`
  fragment ChatConversationConnection on ConversationDtoConnection {
    pageInfo {
      ...PageInfoNoNesting
    }
    edges {
      cursor
      node {
        ...Conversation
      }
    }
  }
  ${PageInfoNoNestingFragment}
  ${ChatConversationFragment}
`;
