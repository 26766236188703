import {
  BlabItemDetailDto,
  BlabItemsConnection,
  BlabItemSearchResultDto,
  BlabItemViewDto,
  CreateBlabItemInput,
  DeleteBlabItemsInfo,
  DeleteBlabItemsInput,
  GetBlabItemsForBlabDefInput,
  SearchBlabItemsInput,
  UpdateBlabItemViewPositionInput,
  Void,
} from '@t5s/shared/gql';
import { ApolloQueryOptions } from '@t5s/shared/gql-config';
import { Observable } from 'rxjs';
import {
  BlabItemDetailDtoLightweightPostCreationFragment,
  FullBlabItemDetailFragment,
} from '../../fragments/blab-item-detail';
import { BlabItemSearchResultFragment } from '../../fragments/blab-item-search';
import { AllBlabItemsWithTitleFragment } from '../../fragments/blab-items-overview';
import {
  createBlabItem,
  deleteBlabItem,
  deleteBlabItems,
  updateBlabItemViewPosition,
} from '../../generated/graphql-mutations';
import { getBlabItem, getBlabItemsForBlabDef, searchBlabItems } from '../../generated/graphql-queries';
import { GqlService } from '../../gql-service';

export class GqlBlabItemService extends GqlService {
  searchBlabItems(input: SearchBlabItemsInput): Observable<BlabItemSearchResultDto[]> {
    return this.apollo.defaultQuery(searchBlabItems(input, [BlabItemSearchResultFragment]));
  }

  getBlabItem(id: number, options: ApolloQueryOptions = {}): Observable<BlabItemDetailDto> {
    return this.apollo.defaultQuery({
      ...getBlabItem(id, [FullBlabItemDetailFragment]),
      ...options,
    });
  }

  getBlabItemNoNesting(id: number): Observable<BlabItemDetailDto> {
    return this.apollo.defaultQuery(getBlabItem(id));
  }

  watchBlabItem(id: number): Observable<BlabItemDetailDto> {
    return this.apollo.defaultWatchQuery(getBlabItem(id, [FullBlabItemDetailFragment]));
  }

  deleteBlabItem(blabItemId: number): Observable<Void> {
    return this.apollo.defaultMutate(deleteBlabItem({ blabItemId }));
  }

  deleteBlabItems(input: DeleteBlabItemsInput): Observable<DeleteBlabItemsInfo> {
    return this.apollo.defaultMutate(deleteBlabItems(input));
  }

  createBlabItem(input: CreateBlabItemInput): Observable<BlabItemDetailDto> {
    return this.apollo.defaultMutate(createBlabItem(input, [FullBlabItemDetailFragment]));
  }

  createBlabItemOmitResponse(input: CreateBlabItemInput): Observable<BlabItemDetailDto> {
    return this.apollo.defaultMutate(createBlabItem(input, [BlabItemDetailDtoLightweightPostCreationFragment]));
  }

  updateBlabItemViewPosition(input: UpdateBlabItemViewPositionInput): Observable<BlabItemViewDto> {
    return this.apollo.defaultMutate(updateBlabItemViewPosition(input));
  }

  getBlabItemConnectionForBlabDef(
    first: number,
    input?: GetBlabItemsForBlabDefInput,
    after?: string,
  ): Observable<BlabItemsConnection> {
    return this.apollo.defaultQuery({
      ...getBlabItemsForBlabDef(first, input, after, [AllBlabItemsWithTitleFragment]),
    });
  }

  getBlabItemConnectionWithTitlesForBlabDef(
    first: number,
    input: GetBlabItemsForBlabDefInput,
    after?: string,
  ): Observable<BlabItemsConnection> {
    return this.apollo.defaultQuery(getBlabItemsForBlabDef(first, input, after, [AllBlabItemsWithTitleFragment]));
  }
}
